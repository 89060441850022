import { cloneDeep } from 'lodash';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    storeAllSensorsFetched,
    storeExtendedDataFromCSVSources,
    storeNotEqualSerailNumbers,
    storePossibleToCloseModal,
} from '../../components/modals/batchDownload/batchDownloadReducer';
import { sensorTypes } from '../../constants/sensorTypes';
import getBrickstreamSerialNumberForTable from '../batchDownloadHelper/brickstream/getBrickstreamSerialNumberForTable';
import getDilaxSerialNumberForTable from '../batchDownloadHelper/dilax/getDilaxSerialNumberForTable';
import getHikvisionSerialNumberForTable from '../batchDownloadHelper/hikvision/getHikvisionSerialNumberForTable';
import getRstatSerialNumberForTable from '../batchDownloadHelper/rstat/getRstatSerialNumberForTable';
import getVivotekSerialNumberForTable from '../batchDownloadHelper/vivotek/getVivotekSerialNumberForTable';
import getXovisSerialNumberForTable from '../batchDownloadHelper/xovis/getXovisSerialNumberForTable';
import getTdSerialNumberForTable from '../batchDownloadHelper/td/getTdSerialNumberForTable';
import getMegacountSerialNumberForTable from '../batchDownloadHelper/megacount/getMegacountSerialNumberForTable';

/**
 * Какстомный хук для получения серийников для пакетной загрузки
 */
const useSerialNumberForBatchUpload = () => {
    const { initialDatafromCSVString, extendedDataFromCSVSourcesById } =
        useSelector((state) => state.batchDownloadReducer);
    const dispatch = useDispatch();

    useEffect(() => {
        let promises = [];
        dispatch(storeAllSensorsFetched(false));
        if (initialDatafromCSVString && extendedDataFromCSVSourcesById) {
            dispatch(storePossibleToCloseModal(false));
            Object.keys(extendedDataFromCSVSourcesById).forEach(async (id) => {
                const element = extendedDataFromCSVSourcesById[id];
                switch (element.type) {
                    case sensorTypes.XOVIS:
                        promises.push(
                            getXovisSerialNumberForTable({
                                singleDataPackage: element,
                            }),
                        );
                        break;
                    case sensorTypes.BRICKSTREAM:
                        promises.push(
                            getBrickstreamSerialNumberForTable({
                                singleDataPackage: element,
                            }),
                        );
                        break;
                    case sensorTypes.VIVOTEK:
                        promises.push(
                            getVivotekSerialNumberForTable({
                                singleDataPackage: element,
                            }),
                        );
                        break;
                    case sensorTypes.HIKVISION:
                        promises.push(
                            getHikvisionSerialNumberForTable({
                                singleDataPackage: element,
                            }),
                        );
                        break;

                    case sensorTypes.RSTAT:
                        promises.push(
                            getRstatSerialNumberForTable({
                                singleDataPackage: element,
                            }),
                        );
                        break;
                    case sensorTypes.DILAX:
                        promises.push(
                            getDilaxSerialNumberForTable({
                                singleDataPackage: element,
                            }),
                        );
                        break;

                    case sensorTypes.TD:
                        promises.push(
                            getTdSerialNumberForTable({
                                singleDataPackage: element,
                            }),
                        );
                        break;

                    case sensorTypes.MEGACOUNT:
                        promises.push(
                            getMegacountSerialNumberForTable({
                                singleDataPackage: element,
                            }),
                        );
                        break;

                    default:
                        promises.push(
                            new Promise((resolve) =>
                                resolve({
                                    id: element.id,
                                    error: 'Invalid sensor type',
                                }),
                            ),
                        );
                        break;
                }
            });
        }
        if (promises.length > 0) {
            Promise.all(promises).then((data) => {
                const extendedBoundlesCopy = cloneDeep(
                    extendedDataFromCSVSourcesById,
                );
                dispatch(storePossibleToCloseModal(true));
                data.forEach((element) => {
                    if (!element.error) {
                        const newValue = {
                            ...extendedBoundlesCopy[element.id],
                            sensorDataFetching: false,
                            serialNumberFromSensor: element.serialNumber,
                        };
                        if (element.notEqualSerialNumber) {
                            dispatch(
                                storeNotEqualSerailNumbers(cloneDeep(newValue)),
                            );
                        }
                        extendedBoundlesCopy[element.id] = newValue;
                    } else {
                        extendedBoundlesCopy[element.id] = {
                            ...extendedBoundlesCopy[element.id],
                            sensorDataFetching: false,
                            sensorDataFetchingError:
                                element.error.message ||
                                element.error ||
                                'Fetch error',
                        };
                    }
                });
                dispatch(storeAllSensorsFetched(true));
                dispatch(storeExtendedDataFromCSVSources(extendedBoundlesCopy));
            });
        }
        // eslint-disable-next-line
    }, [initialDatafromCSVString]);
};

export default useSerialNumberForBatchUpload;
