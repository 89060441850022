import { cloneDeep } from 'lodash';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import columnSortHandler from '../../../utils/detailsListHelpers/columnSortHandler';
import { dateIntervalsColumns } from '../columns/dateIntervalColumns';
import { screensByDateColumns } from '../columns/screensByDateColumns';
import {
    storeColumns,
    storeColumnsToHide,
    storeColumnToSort,
    storeFilteredItemsForScreensList,
    updateExtendedSensors,
} from '../screenshotsPageReducer';

/**
 * Хук для работы с колонками
 */
const useColumnsForList = () => {
    const {
        showScreensByDate,
        extendedSensorsById,
        extendedSensors,
        screensDateFrom,
        screensDateTo,
        columnsToHide,
        columnToSort,
        columns,
        filteredItemsForScreensList,
    } = useSelector((state) => state.screenshotsPageReducer);
    const dispatch = useDispatch();

    useEffect(() => {
        if (columnToSort) {
            const allowedKeys = ['serial_number', 'sensorAddress', 'sensor_type', 'screensPassWays'];
            if (!allowedKeys.includes(columnToSort.key)) return;

            const { newItems, newColumns } = columnSortHandler({
                allColumns: columns,
                column: columnToSort,
                listItems: filteredItemsForScreensList,
            });

            dispatch(storeColumns(newColumns));
            dispatch(storeFilteredItemsForScreensList(newItems));
        }
        // eslint-disable-next-line
    }, [columnToSort]);

    useEffect(() => {
        const newColumns = showScreensByDate ? cloneDeep(screensByDateColumns) : cloneDeep(dateIntervalsColumns);
        columnsToHide.forEach((key) => {
            const index = newColumns.findIndex((element) => element.key === key);
            if (index >= 0) {
                newColumns.splice(index, 1);
            }
        });

        dispatch(storeColumns(newColumns));
        // eslint-disable-next-line
    }, [columnsToHide]);

    useEffect(() => {
        dispatch(storeColumnsToHide([]));
        dispatch(storeColumnToSort(null));
        if (showScreensByDate) {
            const exnededCopy = cloneDeep(extendedSensorsById);
            extendedSensors.forEach((element) => {
                const sensor = cloneDeep(element);
                const screensByDateFrom = element.screensInfo.filter((element) =>
                    element.sensor_info.date_from.includes(screensDateFrom),
                );
                const screensByDateTo = element.screensInfo.filter((element) =>
                    element.sensor_info.date_from.includes(screensDateTo),
                );

                const mostRecentScreenByDateFrom =
                    screensByDateFrom.length > 0 &&
                    screensByDateFrom.reduce((mostRecent, item) => {
                        return item.sensor_info.date_from > mostRecent.sensor_info.date_from ? item : mostRecent;
                    }, screensByDateFrom[0]);

                const mostRecentScreenByDateTo =
                    screensByDateTo.length > 0 &&
                    screensByDateTo.reduce((mostRecent, item) => {
                        return item.sensor_info.date_from > mostRecent.sensor_info.date_from ? item : mostRecent;
                    }, screensByDateTo[0]);

                if (mostRecentScreenByDateFrom) sensor.screenInfoByDateFrom = mostRecentScreenByDateFrom;

                if (mostRecentScreenByDateTo) sensor.screenInfoByDateTo = mostRecentScreenByDateTo;

                exnededCopy[sensor.id] = sensor;
            });
            dispatch(updateExtendedSensors(exnededCopy));
            dispatch(storeColumns(screensByDateColumns));
        } else {
            dispatch(storeColumns(dateIntervalsColumns));
        }

        // eslint-disable-next-line
    }, [showScreensByDate]);
};

export default useColumnsForList;
