import { Icon } from '@fluentui/react';
import styled from 'styled-components/macro';

export const OptionWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid black;
    margin-top: 5px;
    padding: 5px;
`;
export const IconsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    flex: 0 0 25%;
`;

export const CustomOptionIcon = styled(Icon)`
    font-size: 18px;
    margin-left: 5px;
    cursor: pointer;
`;

export const OptionTitle = styled.div`
    font-weight: 500;
`;

export const OptionWindowTitle = styled.div`
    color: #0078d4;
`;

export const CalloutContentWrapper = styled.div``;

export const PlansListItemWrapper = styled.div`
    padding: 10px;
    box-sizing: border-box;
    border-bottom: 1px solid grey;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    :hover {
        background: #edebe9;
    }
`;
export const PlansListItem = styled.div`
    margin-left: 10px;
    display: flex;
    align-items: center;
    :first-child {
        margin-left: 0px;
    }
`;
