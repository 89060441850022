import { Icon } from '@fluentui/react';
import styled from 'styled-components/macro';

export const AccordionTitleWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 16px 0;
`;

export const AccordionTitle = styled.h3`
    margin: 0;
    cursor: pointer;
`;

export const AccordionBody = styled.div`
    display: block;
    position: relative;
    padding: 0;
    margin: 0;
    height: ${(p) => (p.open ? p.bodyHeight + 'px' : 0)};
    overflow: hidden;
    transition: height 0.3s;
`;

export const StyledIcon = styled(Icon)`
    transition: transform 0.3s;
    transform: ${(p) => (p.open ? 'rotate(-180deg)' : 'rotate(0)')};
`;
