import { DefaultButton, Spinner, SpinnerSize, TooltipHost } from '@fluentui/react';
import { useContext, useRef } from 'react';
import { ColumnWrapper } from '../../instalationPointsList.styles';
import {
    CustomTextArea,
    StyledIcon,
    stylesForButton,
    stylesForToolTip,
    UpdateCellWrapper,
} from './hikvisionUpdateConfiguration.styles';
import getNewHikvisionConfiguration from '../../../../../../../../utils/hikvisionHelpers/getNewHikvisionConfiguration';
import { InstallationPointsContext } from '../../../../../../installationPoints';
import { useTranslation } from 'react-i18next';
import { hikvisionProgressMessages } from '../../../../../../../../utils/hikvisionHelpers/hikvisionProgressMessages';

/**
 * Компонент для отрисовки колонки для обновления конфигурации датчика для HIKVISION
 * @param {string} accessKey ключ для выгрузки
 * @param {object} selectedSensor объект выбранного сенсора
 */
const HikvisionUpdateConfiguration = ({ uploadKey, selectedSensor, availableBundlesByIPointId, iPoint }) => {
    const { updateSensorConfigurationThunkTrans, sensorUploadUrlsBySensorType } = useContext(InstallationPointsContext);
    const copyRef = useRef();
    const { t } = useTranslation();

    const uploadUrl = sensorUploadUrlsBySensorType[selectedSensor.sensor_type]?.uploadUrl;
    const CORRECT_URL = `${uploadUrl?.protocol}://${uploadUrl?.host}/fpc/v1/${selectedSensor.sensor_type}/${uploadKey}`;

    const copyHandler = () => {
        copyRef.current.value = CORRECT_URL;
        copyRef.current.focus();
        copyRef.current.select();
        try {
            document.execCommand('copy');
        } catch (error) {
            console.log(error);
        }
    };

    const updateHandler = () => {
        let newConfiguration;
        if (
            selectedSensor.hikvisionDataUploadError === hikvisionProgressMessages.secondStepErrorWithNoUrl &&
            selectedSensor.hikvisionEmptyDataUploadId
        ) {
            newConfiguration = getNewHikvisionConfiguration({
                newUpload: true,
                hikvisionEmptyDataUploadId: selectedSensor.hikvisionEmptyDataUploadId,
                port: selectedSensor.port,
                uploadKey,
                uploadUrl,
            });
        } else {
            newConfiguration = getNewHikvisionConfiguration({
                hikvisionDataUploadBody: selectedSensor.hikvisionDataUploadBody,
                hikvisionDataUploadFullUrl: selectedSensor.hikvisionDataUploadFullUrl,
                hikVisionDataUploadHost: selectedSensor.hikVisionDataUploadHost,
                hikvisionDataUploadUrl: selectedSensor.hikvisionDataUploadUrl,
                uploadKey,
                uploadUrl,
            });
        }

        updateSensorConfigurationThunkTrans({
            data: newConfiguration,
            sensorType: selectedSensor.sensor_type,
            iPointId: iPoint.id,
            availableBundlesByIPointId,
            sensorId: selectedSensor.id,
        });
    };

    return (
        <ColumnWrapper>
            <UpdateCellWrapper>
                <CustomTextArea ref={copyRef} />
                {availableBundlesByIPointId[iPoint?.id]?.updateConfigurationFetching ? (
                    <Spinner size={SpinnerSize.large} />
                ) : uploadKey ? (
                    !availableBundlesByIPointId[iPoint?.id]?.thirdStepError ? (
                        <>
                            <TooltipHost content={CORRECT_URL}>
                                <DefaultButton styles={stylesForButton} onClick={copyHandler}>
                                    {t('Copy link')}
                                </DefaultButton>
                            </TooltipHost>
                        </>
                    ) : availableBundlesByIPointId[iPoint?.id]?.hikvisionEmptyDataUploadId ? (
                        <>
                            <DefaultButton styles={stylesForButton} onClick={updateHandler}>
                                {t('Update')}
                            </DefaultButton>
                            <TooltipHost styles={stylesForToolTip} content={CORRECT_URL}>
                                <StyledIcon onClick={copyHandler} iconName="Copy" />
                            </TooltipHost>
                        </>
                    ) : (
                        <>
                            <TooltipHost content={CORRECT_URL}>
                                <DefaultButton styles={stylesForButton} onClick={copyHandler}>
                                    {t('Copy link')}
                                </DefaultButton>
                            </TooltipHost>
                            {!availableBundlesByIPointId[iPoint?.id]?.firstStepError &&
                                availableBundlesByIPointId[iPoint?.id]?.isFetched && (
                                    <TooltipHost styles={stylesForToolTip} content={t('No free url for update')}>
                                        <StyledIcon iconName="Info" />
                                    </TooltipHost>
                                )}
                        </>
                    )
                ) : (
                    'No access key'
                )}
            </UpdateCellWrapper>
        </ColumnWrapper>
    );
};
export default HikvisionUpdateConfiguration;
