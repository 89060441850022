import { useContext, useMemo, useRef } from 'react';
import { InstallationPointsContext } from '../../../../../../installationPoints';
import { useTranslation } from 'react-i18next';
import { ColumnWrapper } from '../../instalationPointsList.styles';
import { DefaultButton, Spinner, SpinnerSize, TooltipHost } from '@fluentui/react';
import { isNull } from 'lodash';
import { CustomTextArea, StyledIcon, stylesForButton, stylesForToolTip } from './tdUpdateConfiguration.styles';
import getDataForReportServerUpdate from '../../../../../../../../utils/tdSensorHelpers/getDataForReportServerUpdate';

/**
 * Компонент для отрисовки колонки с обновление для TD
 * @param {object} selectedSensor объект выбранного сенсора
 * @param {object} iPoint объект точки установки
 * @param {string} accessKey ключ для выгрузки
 * @param {object} availableBundlesByIPointId объект связок, где ключ это id связки
 */
const TdUpdateConfiguration = ({ iPoint, selectedSensor, uploadKey, availableBundlesByIPointId }) => {
    const { updateSensorConfigurationThunkTrans, sensorUploadUrlsBySensorType } = useContext(InstallationPointsContext);
    const copyRef = useRef();
    const { t } = useTranslation();

    const bundle = useMemo(() => {
        if (availableBundlesByIPointId?.[iPoint?.id]) {
            return availableBundlesByIPointId[iPoint.id];
        }
        return null;
    }, [availableBundlesByIPointId, iPoint]);

    const uploadUrl = sensorUploadUrlsBySensorType[selectedSensor.sensor_type]?.uploadUrl;
    const CORRECT_URL = `${uploadUrl?.protocol}://${uploadUrl?.host}/fpc/v1/${selectedSensor.sensor_type}/${uploadKey}`;

    const copyHandler = () => {
        const copyValue = CORRECT_URL;
        if (copyRef?.current) {
            copyRef.current.value = copyValue;
            copyRef.current.focus();
            copyRef.current.select();
        }
        try {
            document.execCommand('copy');
        } catch (error) {
            console.log(error);
        }
    };

    const updateHandler = () => {
        const newConfiguration = getDataForReportServerUpdate(
            bundle?.tdData?.serviceConfig?.availableServerIdToUpdate,
            bundle?.tdData?.serviceConfig?.rawServiceConfig,
            uploadKey,
            uploadUrl,
        );

        if (newConfiguration) {
            updateSensorConfigurationThunkTrans({
                sensorType: selectedSensor.sensor_type,
                sensorId: selectedSensor.id,
                availableBundlesByIPointId,
                data: newConfiguration,
                iPointId: iPoint.id,
            });
        }
    };

    if (!uploadKey) {
        return (
            <ColumnWrapper>
                <>{t('No access key')}</>
            </ColumnWrapper>
        );
    }

    if (bundle?.updateConfigurationFetching) {
        return (
            <ColumnWrapper>
                <Spinner size={SpinnerSize.large} />
            </ColumnWrapper>
        );
    }

    if (!bundle?.firstStepError && bundle?.thirdStepError && bundle?.tdData?.serviceConfig?.availableServerIdToUpdate) {
        return (
            <ColumnWrapper>
                <CustomTextArea ref={copyRef} />
                <DefaultButton styles={stylesForButton} onClick={updateHandler}>
                    {t('Update')}
                </DefaultButton>
                <TooltipHost styles={stylesForToolTip} content={CORRECT_URL}>
                    <StyledIcon onClick={copyHandler} iconName="Copy" />
                </TooltipHost>
            </ColumnWrapper>
        );
    }

    if (isNull(bundle?.tdData?.serviceConfig?.availableServerIdToUpdate)) {
        return (
            <ColumnWrapper>
                <CustomTextArea ref={copyRef} />
                <TooltipHost content={CORRECT_URL}>
                    <DefaultButton styles={stylesForButton} onClick={copyHandler}>
                        {t('Copy link')}
                    </DefaultButton>
                </TooltipHost>
                <TooltipHost styles={stylesForToolTip} content={t('No free url for update')}>
                    <StyledIcon iconName="Info" />
                </TooltipHost>
            </ColumnWrapper>
        );
    }

    return (
        <ColumnWrapper>
            <CustomTextArea ref={copyRef} />
            <TooltipHost content={CORRECT_URL}>
                <DefaultButton styles={stylesForButton} onClick={copyHandler}>
                    {t('Copy link')}
                </DefaultButton>
            </TooltipHost>
        </ColumnWrapper>
    );
};

export default TdUpdateConfiguration;
