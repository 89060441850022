export const iPointsListColumns = [
    {
        key: 'marker',
        name: 'Marker',
        fieldName: 'marker',
        isResizable: true,
        minWidth: 200,
        maxWidth: 500,
        isSorted: true,
        isSortedDescending: false,
    },
    {
        key: 'sensorAddress',
        name: 'Sensor address',
        fieldName: 'sensorAddress',
        isResizable: true,
        minWidth: 100,
        maxWidth: 100,
    },
    {
        key: 'sensorRelations',
        name: 'Sensor Relations',
        minWidth: 150,
        maxWidth: 150,
    },

    {
        key: 'sensorCountLines',
        name: 'Sensor count lines',
        fieldName: 'sensorCountLines',
        isResizable: true,
        minWidth: 60,
        maxWidth: 60,
    },
    {
        key: 'numberOfLines',
        name: '№ of lines',
        fieldName: 'numberOfLines',
        isResizable: true,
        minWidth: 60,
        maxWidth: 100,
    },

    {
        key: 'boundle',
        name: 'Boundle (id / type)',
        fieldName: 'boundle',
        isResizable: true,
        minWidth: 210,
        maxWidth: 210,
    },
    {
        key: 'progress',
        name: 'Progress',
        fieldName: 'progress',
        isResizable: true,
        minWidth: 100,
        maxWidth: 200,
    },

    {
        key: 'sensorMAC',
        name: 'MAC',
        fieldName: 'sensorMAC',
        isResizable: true,
        minWidth: 100,
        maxWidth: 100,
    },
    {
        key: 'type',
        name: 'Sensor type',
        fieldName: 'type',
        isResizable: true,
        minWidth: 100,
        maxWidth: 100,
    },
    {
        key: 'dependencies',
        name: 'Dependencies',
        fieldName: 'dependencies',
        isResizable: true,
        minWidth: 150,
        maxWidth: 150,
    },
    // {
    //     key: 'testDataPush',
    //     name: 'Test data push',
    //     fieldName: 'testDataPush',
    //     isResizable: true,
    //     minWidth: 100,
    //     maxWidth: 200,
    // },
    {
        key: 'update',
        name: 'Update',
        fieldName: 'update',
        isResizable: true,
        minWidth: 200,
        maxWidth: 350,
    },
];
