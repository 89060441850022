/**
 * Функция для получения серийного номера из html документа
 * @param {string} htmlString html строка, которая содержит информацию о мак адресе
 * @returns мак адрес (серийный номер)
 */
const getRstatMac = (htmlString) => {
    const oParser = new DOMParser();
    const xmlResponse = oParser.parseFromString(htmlString, 'text/html');
    const systemTitlesTr = xmlResponse.querySelectorAll(
        '.sys_inf > tbody > tr'
    );
    let serialNumber = '';

    systemTitlesTr.forEach((tr) => {
        const tds = tr.querySelectorAll('td');
        if (tds[0].textContent.includes('MAC'))
            serialNumber = tds[1].textContent;
    });

    return serialNumber;
};

export default getRstatMac;
