import axios from 'axios';
import { getHeaders } from '../../getHeaders';

/**
 * Функция для поста  сенсора из пакетной загрузки
 * @param {object} body данные, которые нужно создать на бэке
 * @param {object} element элемент пакетной загрузки
 * @param {string} token токен авторизации
 * @param {object} storeUrls объект со всеми урдами
 * @returns промис для создания сенсора
 */
const postVivotekSensorForBatchDownload = (options) => {
    const { body, element, token, storeUrls } = options;

    return axios
        .post(storeUrls.POST_VIVOTEK_SENSOR.url, body, {
            timeout: 10000,
            headers: getHeaders(token),
        })
        .then((res) => res.data)
        .then((res) => ({ frontId: element.id, ...res }))
        .catch((error) => ({ frontId: element.id, error }));
};

export default postVivotekSensorForBatchDownload;
