import { cloneDeep } from 'lodash';
import { sensorTypes } from '../../../constants/sensorTypes';
import portionUploadingOrder from './portionUploadingOrder';
import portionDataPost from '../../../utils/portionDataPost';
import hikvisionUploadDataHandler from './hikvision/hikvisionUploadDataHandler';
import { DateTime } from 'luxon';
import { generateMegacountUploadPayload } from './megacount/uploadMegacountData';

/**
 * Составляет цикл из итераций по переданному периуду, обрабаьтывает получившиеся промисы и возвращает новый объект
 * @param {number} dateToInMillis date to в милисекундах
 * @param {number} dateFromInMillis date from в милисекундах
 * @param {number} portionSize размер порции для выгрузки с датчика
 * @param {array} selectedSensorsCopy массив выбранных сенсоров
 * @param {object} sensorsForManagementById объект сенсоров, гдю ключ это id сенсора из csv (генерируется на фронте)
 * @param {number} timeout таймаут
 * @returns новый объект с обработанными сенсорами
 */
const portionSensorDataUpload = async (options) => {
    const { dateToInMillis, dateFromInMillis, portionSize, selectedSensorsCopy, sensorsForManagementById, timeout } =
        options;
    const interationsCount = Math.ceil((dateToInMillis - dateFromInMillis) / portionSize);
    let dateFromInMillisCopy = dateFromInMillis;
    let sensorsForManagementByIdCopy = cloneDeep(sensorsForManagementById);
    const dataForGetHikvisionUploadData = [];
    const dataForPost = [];
    const PORTION_SIZE = 10;

    for (let i = 0; i < interationsCount; i++) {
        if (dateFromInMillisCopy + portionSize >= dateToInMillis) {
            const result = await portionUploadingOrder({
                selectedSensorsCopy,
                sensorsForManagementById: sensorsForManagementByIdCopy,
                dateToInMillis: dateToInMillis,
                dateFromInMillis: dateFromInMillisCopy,
                timeout,
            });
            sensorsForManagementByIdCopy = result;
        } else {
            const result = await portionUploadingOrder({
                selectedSensorsCopy,
                sensorsForManagementById: sensorsForManagementByIdCopy,
                dateToInMillis: dateFromInMillisCopy + portionSize,
                dateFromInMillis: dateFromInMillisCopy,
                timeout,
            });
            sensorsForManagementByIdCopy = result;
        }
        dateFromInMillisCopy += portionSize;
    }

    /** Обработка тех датчиков, у которых нет возможности порционной загрузки */
    selectedSensorsCopy.forEach((element) => {
        switch (element.type) {
            case sensorTypes.HIKVISION: {
                const sensor = sensorsForManagementByIdCopy[element.id];
                if (sensor.errors.length === 0) {
                    if (sensor.layers?.passWays?.length) {
                        dataForGetHikvisionUploadData.push({
                            sensor,
                            dateFromInMillis,
                            dateToInMillis,
                            timeout,
                        });
                    } else {
                        sensorsForManagementByIdCopy[sensor.id] = {
                            ...sensor,
                            errors: [...sensor.errors, 'No count lines'],
                        };
                    }
                } else {
                    sensorsForManagementByIdCopy[sensor.id] = { ...sensor };
                }
                break;
            }

            case sensorTypes.MEGACOUNT: {
                const sensor = sensorsForManagementByIdCopy[element.id];
                if (sensor.layers.passWays.length) {
                    const postDataElement = {
                        url: `http://${sensor.ip}:${sensor.port}/upload`,
                        dataForPost: generateMegacountUploadPayload({
                            companyUploadServer: sensor.megacountData?.companyUploadServer,
                            accountSettings: sensor.megacountData?.accountSettings,
                            dateFrom: DateTime.fromMillis(dateFromInMillis).toISO(),
                            dateTo: DateTime.fromMillis(dateToInMillis).toISO(),
                        }),
                        initialData: sensor,
                        user: sensor.user || 'admin',
                        pass: sensor.pass,
                        extraArgs: {
                            dateInterval: {
                                dateFrom: dateFromInMillis,
                                dateTo: dateToInMillis,
                            },
                        },
                    };
                    dataForPost.push(postDataElement);
                } else {
                    sensorsForManagementByIdCopy[sensor.id] = {
                        ...sensor,
                        errors: [...sensor.errors, 'No count lines'],
                    };
                }
                break;
            }

            default:
                break;
        }
    });

    const hikvisionGetUploadDataRes = await hikvisionUploadDataHandler(dataForGetHikvisionUploadData);

    hikvisionGetUploadDataRes.forEach((element) => {
        const sensor = cloneDeep(element.initialData);
        if (!element.error) {
            dataForPost.push(element);
        } else {
            sensor.orderCount += 1 + sensorsForManagementByIdCopy[sensor.id].orderCount;
            sensor.failedIntervals = [element.extraArgs.dateInterval].concat(
                sensorsForManagementByIdCopy[sensor.id].failedIntervals,
            );
            sensorsForManagementByIdCopy[sensor.id] = {
                ...sensor,
                errors: [...sensor.errors, element.error],
            };
        }
    });

    const postResult = await portionDataPost({
        portionSize: PORTION_SIZE,
        dataArray: dataForPost,
        timeout,
        isAuth: true,
    });

    postResult.forEach((element) => {
        const sensor = cloneDeep(element.initialData);
        sensor.dataFetching = false;
        if (!element.error) {
            switch (sensor.type) {
                case sensorTypes.HIKVISION: {
                    sensor.orderCount += 1 + sensorsForManagementByIdCopy[sensor.id].orderCount;
                    sensor.successOrderCount += 1 + sensorsForManagementByIdCopy[sensor.id].successOrderCount;
                    sensor.failedIntervals = cloneDeep(sensorsForManagementByIdCopy[sensor.id].failedIntervals);
                    sensorsForManagementByIdCopy[sensor.id] = {
                        ...sensor,
                    };
                    break;
                }

                case sensorTypes.MEGACOUNT: {
                    sensor.orderCount = 1;
                    sensor.successOrderCount = 1;
                    sensorsForManagementByIdCopy[sensor.id] = {
                        ...sensor,
                    };
                    break;
                }

                default:
                    break;
            }
        } else {
            switch (sensor.type) {
                case sensorTypes.MEGACOUNT: {
                    sensor.orderCount = 1;
                    sensorsForManagementByIdCopy[sensor.id] = {
                        ...sensor,
                        failedIntervals: [element.extraArgs.dateInterval],
                        errors: [...sensor.errors, element.error],
                    };
                    break;
                }

                default: {
                    sensor.orderCount += 1 + sensorsForManagementByIdCopy[sensor.id].orderCount;
                    sensor.successOrderCount = sensorsForManagementByIdCopy[sensor.id].successOrderCount;
                    sensor.failedIntervals = [element.extraArgs.dateInterval].concat(
                        sensorsForManagementByIdCopy[sensor.id].failedIntervals,
                    );
                    sensorsForManagementByIdCopy[sensor.id] = {
                        ...sensor,
                        errors: [...sensor.errors, element.error],
                    };
                    break;
                }
            }
        }
    });

    return sensorsForManagementByIdCopy;
};

export default portionSensorDataUpload;
