import axios from 'axios';

/**
 * Функция для получения инфомации о линиях посчета с датчика
 * @param {object} sensor объект сенсора
 * @param {number} timeout таймаут
 * @returns инфомацию о линиях подсчета или ошибку
 */
const getVivotekCountLinesInfo = (sensor, timeout) => {
    return axios
        .get(`http://${sensor.ip}:${sensor.port}/VCA/Config/RE`, {
            timeout,
            auth: {
                username: sensor.user,
                password: sensor.pass,
            },
        })
        .then((response) => response.data)
        .then((data) => ({ layers: data, sensor: { ...sensor } }))
        .catch((error) => ({ error: error.message, sensor: { ...sensor } }));
};

export default getVivotekCountLinesInfo;
