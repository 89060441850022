import { cloneDeep } from 'lodash';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { reexportTaskStatus } from '../../../constants/reexportTaskStatus';
import { CreateProgressLogThunk, toggleProgressLog } from '../sensorsManagementReducer';

/**
 * Хук обработки и создания лога для датчиков, которые успешно отправили свои данные
 */
const useReexportTasksLog = () => {
    const { sensorsForManagementById, extendedPlTasks, progressLog } = useSelector(
        (state) => state.sensorsManegementReducer,
    );
    const dispatch = useDispatch();

    useEffect(() => {
        if (progressLog && Object.keys(sensorsForManagementById).length > 0) {
            const dataForUpload = {};
            Object.keys(extendedPlTasks).forEach((key) => {
                const plTask = extendedPlTasks[key];

                plTask.sensor_tasks.forEach((sensorTask) => {
                    const sensorTaskCopy = cloneDeep(sensorTask);
                    const sensor = sensorsForManagementById[sensorTask.id];
                    delete sensorTaskCopy.id;
                    let payload = [];

                    if (sensor) {
                        if (plTask.processed_tasks) {
                            payload = cloneDeep(plTask.processed_tasks);
                            const taskIndex = plTask.processed_tasks.findIndex(
                                (element) =>
                                    element.date_from === sensorTaskCopy.date_from &&
                                    element.date_to === sensorTaskCopy.date_to &&
                                    element.ip === sensorTaskCopy.ip &&
                                    element.port === sensorTaskCopy.port,
                            );
                            payload.splice(taskIndex, 1);
                        }
                        //

                        sensorTaskCopy['error_log'] = sensor.errors;
                        if (sensor.orderCount > 0 && sensor.orderCount === sensor.successOrderCount) {
                            sensorTaskCopy['status'] = reexportTaskStatus.success;
                        } else {
                            sensorTaskCopy['status'] = reexportTaskStatus.failure;
                        }

                        payload.push(sensorTaskCopy);

                        if (dataForUpload[`${plTask.date}_${plTask.pl_id}`]) {
                            if (plTask.processed_tasks) {
                                dataForUpload[`${plTask.date}_${plTask.pl_id}`].payload = payload;
                            } else {
                                dataForUpload[`${plTask.date}_${plTask.pl_id}`].payload.push(...payload);
                            }
                        } else {
                            dataForUpload[`${plTask.date}_${plTask.pl_id}`] = {
                                logUrl: plTask.uploading_progress_log_url,
                                payload,
                            };
                        }
                    }
                });
            });

            dispatch(CreateProgressLogThunk(dataForUpload));
            dispatch(toggleProgressLog(false));
        }

        // eslint-disable-next-line
    }, [progressLog, sensorsForManagementById]);
};

export default useReexportTasksLog;
