import { xovisGetRequest } from '../../../../../api/xovis/xovis.api';

const getXovisViewImageMetadataV5 = async (args) => {
    const { sensor, isMultisensor, timeout } = args;

    const viewImage = await xovisGetRequest({
        url: `http://${sensor.ip}:${sensor.port}/api/v5/${
            isMultisensor ? 'multisensor' : 'singlesensor'
        }/view/images/metadata`,
        username: sensor.username,
        password: sensor.password,
        timeout,
    });

    if (!viewImage.error && viewImage.image_metadata) {
        return viewImage;
    }

    return { error: 'Ann error accrued while getting view image metadata' };
};

export default getXovisViewImageMetadataV5;
