import { getCompanyUploadUrlPart } from '../../constants/constants';

/**
 * Вспомогательная функция для получения информации для прогресс бара свзяки с датчика HIKVISION
 * @param {string} hikvisionDataUploadInfo информация о всех выгрузках с сенсора HIKVISION
 */
const getInfoForHikvisionBoundle = (options) => {
    const { hikvisionDataUploadInfo, countingInfo } = options;
    const oParser = new DOMParser();
    const oSerializer = new XMLSerializer();
    let hikvisionDataUploadUrl = null;
    let hikvisionDataUploadId = null;
    let hikvisionDataUploadBody = null;
    let hikVisionDataUploadHost = null;
    let hikvisionDataUploadFullUrl = null;
    let hikvisionEmptyDataUploadId = null;
    let dataUploadInterval = '';

    const xmlDataUploadInfo = oParser.parseFromString(
        hikvisionDataUploadInfo,
        'text/xml',
    );
    const xmlCountingInfo = oParser.parseFromString(countingInfo, 'text/xml');
    const dataUploadArray = xmlDataUploadInfo.getElementsByTagName(
        'HttpHostNotification',
    );
    dataUploadInterval =
        xmlCountingInfo.querySelector('dataUploadCycle')?.textContent || '';

    for (let dataUploadElement of dataUploadArray) {
        let dataUploadElementId;
        let dataUploadElementUrl;

        let dataUploadBody = oSerializer.serializeToString(dataUploadElement);

        for (let tag of dataUploadElement.childNodes) {
            if (tag.tagName === 'url') {
                dataUploadElementUrl = tag.textContent;
                if (tag.textContent === '0.0.0.0' || tag.textContent === '/')
                    hikvisionEmptyDataUploadId = dataUploadElementId;
            }
            if (tag.tagName === 'id') dataUploadElementId = tag.textContent;
            if (tag.tagName === 'ipAddress') {
                if (tag.textContent === '0.0.0.0')
                    hikvisionEmptyDataUploadId = dataUploadElementId;
            }

            if (tag.tagName === 'hostName') {
                if (tag.textContent.includes(getCompanyUploadUrlPart())) {
                    hikVisionDataUploadHost = tag.textContent;
                    hikvisionDataUploadUrl = dataUploadElementUrl;
                    hikvisionDataUploadFullUrl =
                        tag.textContent + dataUploadElementUrl;
                    hikvisionDataUploadId = dataUploadElementId;
                    hikvisionDataUploadBody = dataUploadBody;
                    hikvisionEmptyDataUploadId = dataUploadElementId;
                }
            }
        }
    }

    return {
        hikvisionDataUploadUrl,
        hikvisionDataUploadId,
        hikvisionDataUploadBody,
        hikVisionDataUploadHost,
        hikvisionDataUploadFullUrl,
        hikvisionEmptyDataUploadId,
        dataUploadInterval,
    };
};

export default getInfoForHikvisionBoundle;
