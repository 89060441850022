import { Spinner, SpinnerSize } from '@fluentui/react';
import ErrorsIndication from './errorsIndication';
import { StyledIcon, MainStatusWrapper, StatusText } from './onlineStatus.styles';

/**
 * Компонент для отображения статуса при проверки онлайна сенсора
 * @param {object} sensor объект сенсора
 */
const OnlineStatus = ({ sensor }) => {
    const STATUS_100 = 3;
    const STATUS_60 = 2;
    const STATUS_30 = 1;
    const STATUS_0 = 0;
    if (sensor.dataFetching) {
        return <Spinner size={SpinnerSize.large} />;
    }

    if (sensor.orderCount === 0) return null;

    const errors = <ErrorsIndication errors={sensor.errors} />;

    switch (sensor.successOrderCount) {
        case STATUS_100:
            return (
                <>
                    <MainStatusWrapper>
                        <StyledIcon iconName='CheckboxCompositeReversed' color='green' />
                        <StatusText>Success</StatusText>
                    </MainStatusWrapper>

                    {errors}
                </>
            );
        case STATUS_60:
            return (
                <>
                    <MainStatusWrapper>
                        <StyledIcon iconName='WarningSolid' color='#fce803' />
                        <StatusText>Not all requests are successful</StatusText>
                    </MainStatusWrapper>

                    {errors}
                </>
            );
        case STATUS_30:
            return (
                <>
                    <MainStatusWrapper>
                        <StyledIcon iconName='WarningSolid' color='#c48e06' />
                        <StatusText>Not all requests are successful</StatusText>
                    </MainStatusWrapper>

                    {errors}
                </>
            );
        case STATUS_0:
            return (
                <>
                    <MainStatusWrapper>
                        <StyledIcon iconName='BoxMultiplySolid' color='red' />
                        <StatusText>Error</StatusText>
                    </MainStatusWrapper>

                    {errors}
                </>
            );

        default:
            return null;
    }
};

export default OnlineStatus;
