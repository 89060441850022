import axios from 'axios';
import { tdGetRequest } from '../../../../api/td/td.api';
import getTdFlowZoneBySlug from './getTdFlowZoneBySlug';

/**
 * Функция для получения информации о зонах входы и выхода
 * @param {string} ip IP датчика
 * @param {number} port Порт датчика
 * @returns Promise с данными о зонах входы и выхода
 */
const getTdFlowStatistics = async (args) => {
    const { ip, port } = args;

    const flowStatistics = await tdGetRequest({
        url: `http://${ip}:${port}/passenger_flow_statistics`,
    });

    if (!flowStatistics.error) {
        const oParser = new DOMParser();
        const flowStatisticsHTML = oParser.parseFromString(
            flowStatistics,
            'text/html',
        );

        const flowAreas = flowStatisticsHTML.querySelectorAll(
            '#zone_list_body > tr',
        );

        if (flowAreas?.length) {
            const promises = [];
            flowAreas.forEach((area) => {
                const isActive = area.querySelector('.light-green');
                if (isActive) {
                    /** Поиск slug для зоны, чтобы получить координаты и тд */
                    const zoneSlug = area
                        .querySelector('a')
                        ?.href?.split('=')?.[1];

                    if (zoneSlug) {
                        promises.push(
                            getTdFlowZoneBySlug({
                                slug: zoneSlug,
                                port,
                                ip,
                            }),
                        );
                    }
                }
            });

            const data = await axios.all(promises).then((responses) => {
                const pass_ways = [];
                responses.forEach((response) => {
                    if (!response.error) {
                        pass_ways.push(...response);
                    }
                });
                return pass_ways;
            });

            return data;
        }

        return {
            error: 'Unable to get sensor flow statistics data',
        };
    }

    return {
        error: 'Unable to get sensor flow statistics data',
    };
};

export default getTdFlowStatistics;
