import { useSelector } from 'react-redux';
import SinglePassWay from './components/singlePassWay';

/**
 * Обертка для отображения все проходов
 * @param {number} stageScale
 */
const PassWays = ({ stageScale }) => {
    const { passWaysOnCurrentFloor, showPassWayLabel } = useSelector((state) => state.passWaysReducer);
    const { mainLocationsByFloor, selectedFloor } = useSelector((state) => state.instalationPointsReducer);
    const passWaysArray = passWaysOnCurrentFloor.map((element) => (
        <SinglePassWay
            key={element.id}
            showPassWayLabel={showPassWayLabel}
            coordinates={element.passLine.coordinates}
            passMargin={element.passMargin}
            planScale={mainLocationsByFloor ? mainLocationsByFloor[selectedFloor].scale : 1}
            stageScale={stageScale}
            marker={element.marker}
        />
    ));

    return <>{passWaysArray}</>;
};

export default PassWays;
