/**
 * Функция для проверки соответсвия линий подсчета в мапсервисе с линиями подсчета на датчике
 * @param {object} passPoints объект точек для "усиков" сенсора
 * @param {array} passWays массив всех проходов с датчика (линий подсчета)
 */
const compareOfPassWays = (passPoints, passWays) => {
    const successComparisons = [];

    const passWaysByMarker = passWays.reduce((acc, value) => {
        acc[value.marker] = { ...value };
        return acc;
    }, {});

    Object.keys(passPoints).forEach((point) => {
        if (passWaysByMarker[passPoints[point].lineName]) successComparisons.push('success');
    });

    if (successComparisons.length === Object.keys(passPoints).length) return true;
    return false;
};

export default compareOfPassWays;
