import { xovisGetRequest } from '../../../../../api/xovis/xovis.api';

/**
 * Получение скриншота с датчика
 * @param {object} sensor  объект сенсора
 * @param {boolean} isMultisensor флаг мульти или нет
 * @param {number} timeout timeout
 * @returns объект с ошибкой или скриншот
 */
const getXovisScreenV5 = async (args) => {
    const { sensor, timeout, isMultisensor } = args;

    const response = await xovisGetRequest({
        url: `http://${sensor.ip}:${sensor.port}/api/v5/${
            isMultisensor ? 'multisensor' : 'singlesensor'
        }/scene/images/live.jpg`,
        username: sensor.username,
        password: sensor.password,
        timeout,
        responseType: 'blob',
    }).then(
        (blob) =>
            new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = () => {
                    if (reader.result.includes('text/xml')) {
                        return resolve({
                            error: `timeout of ${timeout}ms exceeded`,
                        });
                    }
                    return resolve({
                        screen: reader.result,
                    });
                };
                reader.onerror = () =>
                    resolve({
                        error: 'Get screen error',
                    });
            }),
    );

    if (!response.error) {
        return response;
    }

    return { error: 'Ann error accrued while getting screen' };
};

export default getXovisScreenV5;
