import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { storeTemporaryToken } from '../sensorsManagementReducer';

/**
 * Хук для получения временного токена из урла
 */
const useTemporaryToken = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        let temporaryToken = '';
        if (location.search.includes('token')) {
            location.search.split('?').forEach((query) => {
                if (query.includes('token')) {
                    temporaryToken = query.split('=')[1];
                }
            });
        }

        dispatch(storeTemporaryToken(temporaryToken));
        // eslint-disable-next-line
    }, [location]);
};

export default useTemporaryToken;
