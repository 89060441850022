import { MEGACOUNT_DEFAULT_CANVAS_DIMENSIONS } from '../../../../constants/megacountSensor';
import getImageDimensions from '../getImageDimensions';

/**
 * Получение скриншота и скейла по
 * @param {string} ip IP датчика
 * @param {number} port Порт датчика
 * @returns Promise результатом которого является объект со скрином и скейлом по координатам или объект с ошибкой
 */
const getMegacountScreen = async (args) => {
    const { ip, port } = args;

    const connection = new WebSocket(`ws://${ip}:${port}`);

    const wsConnected = new Promise((resolve, reject) => {
        connection.onopen = () => {
            resolve(connection);
        };
        connection.onerror = (error) => {
            reject(error);
        };
    });

    try {
        const connectedWebSocket = await wsConnected;
        connectedWebSocket.send(
            JSON.stringify({
                image: {
                    get: ['main'],
                },
            }),
        );

        const response = await new Promise((resolve) => {
            connectedWebSocket.addEventListener('message', (event) => {
                const message = JSON.parse(event.data);
                resolve(message);
            });
        });
        connectedWebSocket.close();

        if (response?.image?.main) {
            const { width, height } = await getImageDimensions(
                response?.image?.main,
            );
            return {
                screen: response?.image?.main,
                screenScale: {
                    x: width / MEGACOUNT_DEFAULT_CANVAS_DIMENSIONS.width,
                    y: height / MEGACOUNT_DEFAULT_CANVAS_DIMENSIONS.height,
                },
            };
        }
    } catch (error) {
        return {
            error: 'An error accrued while getting Megacount screen',
        };
    }
    return {
        error: 'An error accrued while getting Megacount screen',
    };
};

export default getMegacountScreen;
