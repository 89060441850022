import styled from 'styled-components/macro';

export const ListWrapper = styled.div`
    position: relative;
    margin-top: 10px;
    flex-grow: 1;
    .ms-DetailsHeader {
        padding: 0px;
    }
`;
