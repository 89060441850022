/**
 * Функция создания объекта урлов
 * @param {storeHosts} urls объект со всеми хостами
 * @returns объект урлов
 */
const createUrlsObject = (storeHosts) => {
    const urls = {
        // ADMIN SERVICE
        AUTH_URL: {
            url: `https://${storeHosts['core/admin-service']?.url}/sso/ajax-token`,
        },
        SIGN_OUT: {
            url: `https://${storeHosts['core/admin-service']?.url}/admin/logout/`,
        },
        PROJECT_LOCATIONS_URL: {
            url: `https://${storeHosts['core/admin-service']?.url}/api/project-locations`,
        },
        PROJECT_URL: {
            url: `https://${storeHosts['core/admin-service']?.url}/api/projects/`,
        },
        INSTALATION_POINTS: {
            url: `https://${storeHosts['core/admin-service']?.url}/api/pc/installation-points/`,
        },
        GET_SENSORS: {
            url: `https://${storeHosts['core/admin-service']?.url}/api/pc/sensors/`,
        },
        POST_XOVIS_SENSOR: {
            url: `https://${storeHosts['core/admin-service']?.url}/api/pc/xovis-sensors/`,
        },
        SENSORS_TO_IPOINTS: {
            url: `https://${storeHosts['core/admin-service']?.url}/api/pc/sensor-2-installation-point/`,
        },
        POST_BRICKSTREAM_SENSOR: {
            url: `https://${storeHosts['core/admin-service']?.url}/api/pc/brickstream-sensors/`,
        },
        POST_RSTAT_SENSOR: {
            url: `https://${storeHosts['core/admin-service']?.url}/api/pc/rstat-sensors/`,
        },
        POST_DILAX_SENSOR: {
            url: `https://${storeHosts['core/admin-service']?.url}/api/pc/dilax-sensors/`,
        },
        POST_TD_SENSOR: {
            url: `https://${storeHosts['core/admin-service']?.url}/api/pc/td-sensors/`,
        },
        POST_VIVOTEK_SENSOR: {
            url: `https://${storeHosts['core/admin-service']?.url}/api/pc/vivotek-sensors/`,
        },
        POST_HIKVISION_SENSOR: {
            url: `https://${storeHosts['core/admin-service']?.url}/api/pc/hikvision-sensors/`,
        },
        POST_MEGACOUNT_SENSOR: {
            url: `https://${storeHosts['core/admin-service']?.url}/api/pc/megacount-sensors/`,
        },
        PATCH_XOVIS_SENSOR: {
            url: `https://${storeHosts['core/admin-service']?.url}/api/pc/xovis-sensors/`,
        },
        PATCH_BRICKSTREAM_SENSOR: {
            url: `https://${storeHosts['core/admin-service']?.url}/api/pc/brickstream-sensors/`,
        },
        PATCH_VIVOTEK_SENSOR: {
            url: `https://${storeHosts['core/admin-service']?.url}/api/pc/vivotek-sensors/`,
        },
        PATCH_HIKVISION_SENSOR: {
            url: `https://${storeHosts['core/admin-service']?.url}/api/pc/hikvision-sensors/`,
        },
        PATCH_RSTAT_SENSOR: {
            url: `https://${storeHosts['core/admin-service']?.url}/api/pc/rstat-sensors/`,
        },
        PATCH_DILAX_SENSOR: {
            url: `https://${storeHosts['core/admin-service']?.url}/api/pc/dilax-sensors/`,
        },
        PATCH_TD_SENSOR: {
            url: `https://${storeHosts['core/admin-service']?.url}/api/pc/td-sensors/`,
        },
        PATCH_MEGACOUNT_SENSOR: {
            url: `https://${storeHosts['core/admin-service']?.url}/api/pc/megacount-sensors/`,
        },
        GET_XOVIS_SENSOR: {
            url: `https://${storeHosts['core/admin-service']?.url}/api/pc/xovis-sensors/`,
        },
        GET_BRICKSTREAM_SENSOR: {
            url: `https://${storeHosts['core/admin-service']?.url}/api/pc/brickstream-sensors/`,
        },
        GET_VIVOTEK_SENSOR: {
            url: `https://${storeHosts['core/admin-service']?.url}/api/pc/vivotek-sensors/`,
        },
        GET_HIKVISION_SENSOR: {
            url: `https://${storeHosts['core/admin-service']?.url}/api/pc/hikvision-sensors/`,
        },
        GET_RSTAT_SENSOR: {
            url: `https://${storeHosts['core/admin-service']?.url}/api/pc/rstat-sensors/`,
        },
        GET_DILAX_SENSOR: {
            url: `https://${storeHosts['core/admin-service']?.url}/api/pc/dilax-sensors/`,
        },
        GET_TD_SENSOR: {
            url: `https://${storeHosts['core/admin-service']?.url}/api/pc/td-sensors/`,
        },
        GET_MEGACOUNT_SENSOR: {
            url: `https://${storeHosts['core/admin-service']?.url}/api/pc/megacount-sensors/`,
        },
        // STRUCTURE SERVICE
        CURRENT_URL: {
            url: `https://${storeHosts['core/structure-service']?.url}/core-current/`,
        },
        GET_RELATIONS_URL: {
            url: `https://${storeHosts['core/structure-service']?.url}/core/v1/pl-relations`,
        },
        GET_PL_STRUCTURE: {
            url: `https://${storeHosts['core/structure-service']?.url}/core/v1/pl-structure`,
        },
        GET_PL_CACHED_STRUCTURE: {
            url: `https://${storeHosts['core/structure-service']?.url}/structure-service/v1/cached_structure/`,
        },
        // MAP-SERVICE SERVICE
        LOCATION_INFO_URL: {
            url: `https://${storeHosts['core/map-service-back']?.url}/api/plans`,
        },
        LAYERS_URL: {
            url: `https://${storeHosts['core/map-service-back']?.url}/api/layers`,
        },
        // OTHER URLS
        POST_SCREENSHOT_FOR_HISTORY: {
            url: 'https://functions.yandexcloud.net/d4ea3daci4guovfsglvh',
        },
        GET_SCREENSHOTS_FOR_HISTORY: {
            url: 'https://functions.yandexcloud.net/d4ea3daci4guovfsglvh',
        },
        LAMBDA_CALLER: {
            url: 'https://lambda-caller.focustech.xyz',
        },
    };

    return urls;
};

export default createUrlsObject;
