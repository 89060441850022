import convertIpToNumber from './convertIpToNumber';
import findErrorsNumber from './findErrorsNumber';

/**
 * Функция для сортировки элементов списка
 * @param {array} items массив элементов списка
 * @param {string} columnKey ключ колонки
 * @param {boolean} isSortedDescending сортировка по убыванию или по возрастанию
 * @returns отсортированный массив элементов для списка
 *
 */
const copyAndSortListItems = (args) => {
    const { items, columnKey, isSortedDescending, boundles, sensorsForManagementById, slavesByIp } = args;

    switch (columnKey) {
        case 'boundle':
            if (boundles && Object.keys(boundles).length > 0) {
                return items.slice(0).sort((a, b) => {
                    const aSensorId = boundles[a.id] ? boundles[a.id].id : false;
                    const bSensorId = boundles[b.id] ? boundles[b.id].id : false;
                    return (isSortedDescending ? aSensorId < bSensorId : aSensorId > bSensorId) ? 1 : -1;
                });
            } else {
                return items.slice(0);
            }

        case 'progress':
            if (boundles && Object.keys(boundles).length > 0) {
                return items.slice(0).sort((a, b) => {
                    const aErrorsCount = findErrorsNumber(boundles[a.id]);
                    const bErrorsCount = findErrorsNumber(boundles[b.id]);
                    return (isSortedDescending ? aErrorsCount < bErrorsCount : aErrorsCount > bErrorsCount) ? 1 : -1;
                });
            } else {
                return items.slice(0);
            }

        case 'connectStatus':
            if (sensorsForManagementById && Object.keys(sensorsForManagementById).length > 0) {
                return items.slice(0).sort((a, b) => {
                    const aPercentage = sensorsForManagementById[a.id]
                        ? sensorsForManagementById[a.id].orderCount > 0
                            ? (sensorsForManagementById[a.id].successOrderCount /
                                  sensorsForManagementById[a.id].orderCount) *
                              100
                            : -2
                        : -1;
                    const bPercentage = sensorsForManagementById[b.id]
                        ? sensorsForManagementById[b.id].orderCount > 0
                            ? (sensorsForManagementById[b.id].successOrderCount /
                                  sensorsForManagementById[b.id].orderCount) *
                              100
                            : -2
                        : -1;
                    return (isSortedDescending ? aPercentage < bPercentage : aPercentage > bPercentage) ? 1 : -1;
                });
            } else {
                return items.slice(0);
            }

        case 'sensorIp':
            if (boundles && Object.keys(boundles).length > 0) {
                return items.slice(0).sort((a, b) => {
                    const aIp = boundles[a.id] ? convertIpToNumber(boundles[a.id].ip) : false;
                    const bIp = boundles[b.id] ? convertIpToNumber(boundles[b.id].ip) : false;
                    return (isSortedDescending ? aIp < bIp : aIp > bIp) ? 1 : -1;
                });
            } else {
                return items.slice(0).sort((a, b) => {
                    if (a.ip && b.ip) {
                        const aIp = convertIpToNumber(a.ip);
                        const bIp = convertIpToNumber(b.ip);
                        return (isSortedDescending ? aIp < bIp : aIp > bIp) ? 1 : -1;
                    } else {
                        return 1;
                    }
                });
            }
        case 'sensorPort':
            if (boundles && Object.keys(boundles).length > 0) {
                return items.slice(0).sort((a, b) => {
                    const aPort = boundles[a.id] ? Number(boundles[a.id].port) : false;
                    const bPort = boundles[b.id] ? Number(boundles[b.id].port) : false;
                    return (isSortedDescending ? aPort < bPort : aPort > bPort) ? 1 : -1;
                });
            } else {
                return items.slice(0);
            }
        case 'sensorAddress':
            if (boundles && Object.keys(boundles).length > 0) {
                return items.slice(0).sort((a, b) => {
                    const aAddr = boundles[a.id] ? convertIpToNumber(boundles[a.id].ip) : false;
                    const bAddr = boundles[b.id] ? convertIpToNumber(boundles[b.id].ip) : false;
                    return (isSortedDescending ? aAddr < bAddr : aAddr > bAddr) ? 1 : -1;
                });
            } else {
                try {
                    return items.slice(0).sort((a, b) => {
                        const aAddr = convertIpToNumber(a.ip);
                        const bAddr = convertIpToNumber(b.ip);
                        return (isSortedDescending ? aAddr < bAddr : aAddr > bAddr) ? 1 : -1;
                    });
                } catch (_) {
                    return items.slice(0);
                }
            }

        case 'sensorMAC':
            if (boundles && Object.keys(boundles).length > 0) {
                return items.slice(0).sort((a, b) => {
                    const aMAC = boundles[a.id] ? boundles[a.id].serial_number : false;
                    const bMAC = boundles[b.id] ? boundles[b.id].serial_number : false;
                    return (isSortedDescending ? aMAC < bMAC : aMAC > bMAC) ? 1 : -1;
                });
            } else {
                return items.slice(0);
            }

        case 'type':
            if (boundles && Object.keys(boundles).length > 0) {
                return items.slice(0).sort((a, b) => {
                    const aMulti = boundles[a.id] ? (boundles[a.id].isMultiSensor ? 2 : 1) : false;
                    const bMulti = boundles[b.id] ? (boundles[b.id].isMultiSensor ? 2 : 1) : false;
                    const aSlave = slavesByIp[boundles[a.id]?.ip] ? true : false;
                    const bSlave = slavesByIp[boundles[b.id]?.ip] ? true : false;
                    return (isSortedDescending ? aMulti < bMulti : aSlave > bSlave) ? 1 : -1;
                });
            } else {
                return items.slice(0);
            }
        case 'online':
            return items.slice(0).sort((a, b) => {
                return (
                    isSortedDescending ? !a.firstStepError < !b.firstStepError : !a.firstStepError > !b.firstStepError
                )
                    ? 1
                    : -1;
            });

        case 'percentage':
            if (sensorsForManagementById && Object.keys(sensorsForManagementById).length > 0) {
                return items.slice(0).sort((a, b) => {
                    const aPercentage = sensorsForManagementById[a.id]
                        ? sensorsForManagementById[a.id].orderCount > 0
                            ? (sensorsForManagementById[a.id].successOrderCount /
                                  sensorsForManagementById[a.id].orderCount) *
                              100
                            : -2
                        : -1;
                    const bPercentage = sensorsForManagementById[b.id]
                        ? sensorsForManagementById[b.id].orderCount > 0
                            ? (sensorsForManagementById[b.id].successOrderCount /
                                  sensorsForManagementById[b.id].orderCount) *
                              100
                            : -2
                        : -1;
                    return (isSortedDescending ? aPercentage < bPercentage : aPercentage > bPercentage) ? 1 : -1;
                });
            } else {
                return items.slice(0);
            }
        case 'dataUploadStatuStatus':
            if (sensorsForManagementById && Object.keys(sensorsForManagementById).length > 0) {
                return items.slice(0).sort((a, b) => {
                    let aStatus = -4;
                    if (sensorsForManagementById[a.id]) {
                        if (a.orderCount >= 1 && a.orderCount === a.successOrderCount) {
                            aStatus = 100;
                        } else if (a.orderCount >= 1 && a.orderCount !== a.successOrderCount) {
                            aStatus = -1;
                        } else if (a.layers.passWays?.length === 0) {
                            aStatus = -2;
                        } else {
                            aStatus = -3;
                        }
                    }

                    let bStatus = -4;
                    if (sensorsForManagementById[b.id]) {
                        if (b.orderCount >= 1 && b.orderCount === b.successOrderCount) {
                            bStatus = 100;
                        } else if (b.orderCount >= 1 && b.orderCount !== b.successOrderCount) {
                            bStatus = -1;
                        } else if (b.layers.passWays?.length === 0) {
                            bStatus = -2;
                        } else {
                            bStatus = -3;
                        }
                    }

                    return (isSortedDescending ? aStatus < bStatus : aStatus > bStatus) ? 1 : -1;
                });
            } else {
                return items.slice(0);
            }
        case 'screenStatus': {
            if (sensorsForManagementById && Object.keys(sensorsForManagementById).length > 0) {
                return items.slice(0).sort((a, b) => {
                    const aStatus = a.errors.length > 0 ? -2 : a.screen ? 1 : -1;
                    const bStatus = b.errors.length > 0 ? -2 : b.screen ? 1 : -1;
                    return (isSortedDescending ? aStatus < bStatus : aStatus > bStatus) ? 1 : -1;
                });
            } else {
                return items.slice(0);
            }
        }

        case 'managementSType': {
            if (sensorsForManagementById && Object.keys(sensorsForManagementById).length > 0) {
                return items.slice(0).sort((a, b) => {
                    const aType = a.type;
                    const bType = b.type;
                    return (isSortedDescending ? aType < bType : aType > bType) ? 1 : -1;
                });
            } else {
                return items.slice(0);
            }
        }

        case 'screensPassWays': {
            return items.slice(0).sort((a, b) => {
                const aMarker = a.passWays?.reduce((acc, value) => {
                    acc += value.passway_marker;
                    return acc;
                }, '');

                const bMarker = b.passWays?.reduce((acc, value) => {
                    acc += value.passway_marker;
                    return acc;
                }, '');
                return (isSortedDescending ? aMarker < bMarker : aMarker > bMarker) ? 1 : -1;
            });
        }
        default:
            return items
                .slice(0)
                .sort((a, b) =>
                    (isSortedDescending ? a[columnKey] < b[columnKey] : a[columnKey] > b[columnKey]) ? 1 : -1,
                );
    }
};

export default copyAndSortListItems;
