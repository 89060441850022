import React, { FC } from 'react';
import { Stack } from '@fluentui/react';
import { topStackStyles } from './StackHorizontal.styles';
import { IStackHorizontalProps } from './StackHorizontal.interfaces';

/**
 * Компонента для отображения контейнера с горизонтальным позиционированием вложенных компонент.
 */
const StackHorizontal: FC<IStackHorizontalProps> = ({ stackAlign = 'center', ...props }) => {
    const content = Array.isArray(props.children) ? (
        props.children.map((child: React.ReactNode, i: number) => {
            return (
                <Stack.Item align={stackAlign} key={`Stack.Item__${i}`}>
                    {child}
                </Stack.Item>
            );
        })
    ) : (
        <Stack.Item align='center'>{props.children}</Stack.Item>
    );

    return (
        <Stack horizontal {...topStackStyles}>
            {content}
        </Stack>
    );
};

export default StackHorizontal;
