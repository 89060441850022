import { TooltipHost } from '@fluentui/react';
import { useContext, useMemo } from 'react';
import { FloorSidePanelContext } from '../floorsSidePanel';
import { Floor, FloorWrapper } from './floorsButtons.styles';

/**
 * Компонент для отрисовки кнопок с этажами
 */
const FloorsButtons = () => {
    const { locationInfo, selectedFloor, setSelectedFloorTrans, setCurrentFloorInfoTrans } =
        useContext(FloorSidePanelContext);

    const floorHandler = (floor) => () => {
        if (floor !== selectedFloor) {
            setSelectedFloorTrans(floor);
            const locationInfoObject = locationInfo.find((element) => element.floor === floor);
            const newCurrentFloorInfo = {
                id: locationInfoObject.id,
                image: locationInfoObject.image,
                scale: locationInfoObject.scale,
                plan2geo: locationInfoObject.plan2geo,
            };
            setCurrentFloorInfoTrans(newCurrentFloorInfo);
        }
    };

    const buttonsArray = useMemo(() => {
        return locationInfo
            .slice()
            .sort((a, b) => Number(a.floor) - Number(b.floor))
            .map((element) => {
                const isActiveFloor = element.floor === selectedFloor;
                return (
                    <FloorWrapper key={element.id - Math.random()}>
                        <TooltipHost content={`Floor: ${element.floor}`}>
                            <Floor
                                onClick={floorHandler(element.floor)}
                                isActiveFloor={isActiveFloor}
                                key={element.id - Math.random()}
                            >
                                {element.floor}
                            </Floor>
                        </TooltipHost>
                    </FloorWrapper>
                );
            });
        // eslint-disable-next-line
    }, [locationInfo, selectedFloor]);
    return <>{buttonsArray}</>;
};
export default FloorsButtons;
