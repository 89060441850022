import { DefaultButton, TextField } from '@fluentui/react';
import { Icon } from '@fluentui/react/lib/Icon';
import styled from 'styled-components/macro';

export const ColumnWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
`;

export const ActiveIcon = styled(Icon)`
    font-size: 20px;
    color: ${(props) => props.newColor};
`;

export const CustomButton = styled(DefaultButton)`
    margin-right: 10px;
`;

export const SensorTitle = styled.div`
    font-size: 16px;
    font-weight: 600;
    margin-top: 5px;
`;

export const StyledFilter = styled(TextField)`
    margin-left: 10px;
    width: 200px;
`;

export const serialNumberErrorRow = {
    root: {
        cursor: 'pointer',
        minHeight: '50px',
        display: 'flex',
        fontSize: '14px',
        alignItems: 'center',
        backgroundColor: '#ffadad',
    },
};

export const SensorsHeaderWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const ListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

export const AddSensorsWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-top: 10px;
`;
