import {
    DatePicker,
    DefaultButton,
    DetailsList,
    DetailsRow,
    MaskedTextField,
    Spinner,
    SpinnerSize,
    TooltipHost,
} from '@fluentui/react';
import { DateTime } from 'luxon';
import { memo, useEffect } from 'react';
import { useState } from 'react';
import CreateSensorAndPointBoundle from '../createSensorAndPointBoundle/createSensorAndPointBoundle';
import ModalCreator from '../modalCreator';
import { columnsForList, columnsForBoundlesList } from './columnsForList';
import {
    BoundleColumnWrapper,
    CustomSensorsTextField,
    MainIcon,
    MessageWrapper,
    SensorsColumnModalTitle,
    CustomButton,
    BoundlesWrapper,
    StyledIcon,
    styledToolTip,
    ListTitle,
    DatesWrapper,
    stylesForTimeField,
} from './boundleInfoModal.styled';
import { useDispatch } from 'react-redux';
import {
    resetDateToAndTimeTo,
    storeBoundlesForSelectedIPoint,
    storeFreeSensorsForBoundleCreate,
    storeSensorForBoundleCreate,
    updateExtendedBoundle,
} from './boundleInfoModalReducer';
import { useSelector } from 'react-redux';
import {
    deleteSensorAndPointBoundle,
    updateSensorAndPointBoundle,
} from '../../instalationPoints/installationPointsReducer';
import { cloneDeep } from 'lodash';

/**
 * Компонент для отображения модального окна с истрией связок и с возможностьб создать новую связку
 */
const BoundleInfoModal = memo(() => {
    const {
        extendedBoundlesForSelectedIPointById,
        boundlesForSelectedIPointById,
        freeSensorsForBoundleCreate,
        sensorForBoundleCreate,
    } = useSelector((state) => state.boundleInfoModalReducer);
    const { sensorsAndPointsBounles, availableBundlesByIPointId, selectedLocationTimeZone, iPointForBoundleInfoModal } =
        useSelector((state) => state.instalationPointsReducer);
    const { sensors, sensorsById } = useSelector((state) => state.countingSensorsReducer);
    const { successNotification } = useSelector((state) => state.generalReducer);
    const [filter, setFilter] = useState('');
    const [open, setOpen] = useState(false);
    const [filteredSensors, setFilteredSensors] = useState([]);
    const dispatch = useDispatch();
    const timeRegExp = /(?:[01]\d|2[0-3]):(?:[0-5]\d):(?:[0-5]\d)/;

    useEffect(() => {
        if (selectedLocationTimeZone && iPointForBoundleInfoModal) {
            dispatch(
                storeBoundlesForSelectedIPoint({
                    boundles: sensorsAndPointsBounles,
                    iPointId: iPointForBoundleInfoModal.id,
                    timezone: selectedLocationTimeZone,
                })
            );
        }
        // eslint-disable-next-line
    }, [sensorsAndPointsBounles, iPointForBoundleInfoModal, selectedLocationTimeZone]);

    useEffect(() => {
        dispatch(storeFreeSensorsForBoundleCreate({ sensors, availableBundlesByIPointId }));
        // eslint-disable-next-line
    }, [sensors, availableBundlesByIPointId]);

    useEffect(() => {
        setFilteredSensors(cloneDeep(freeSensorsForBoundleCreate));
    }, [freeSensorsForBoundleCreate]);

    useEffect(() => {
        if (sensorForBoundleCreate) handleOpen(true);
    }, [sensorForBoundleCreate]);

    useEffect(() => {
        if (successNotification?.show) handleClose();
    }, [successNotification]);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const filterHandler = (_, newValue) => {
        setFilter(newValue);
        if (newValue) {
            setFilteredSensors(
                freeSensorsForBoundleCreate.filter(
                    (element) =>
                        element.ip.includes(newValue) ||
                        element.port.toString().includes(newValue) ||
                        element.sensor_name.toLowerCase().includes(newValue) ||
                        element.sensor_type.toLowerCase().includes(newValue)
                )
            );
        } else {
            setFilteredSensors(cloneDeep(freeSensorsForBoundleCreate));
        }
    };

    const dateHandler = (id, date, dateType) => {
        const newDate = date ? DateTime.fromISO(date.toISOString()).toFormat('yyyy-MM-dd') : null;
        dispatch(updateExtendedBoundle({ boundleId: id, value: newDate, key: dateType }));
    };

    const timeHandler = (id, timeType) => (_, time) => {
        dispatch(updateExtendedBoundle({ boundleId: id, value: time, key: timeType }));
    };

    const setToNullHandler = (id) => () => {
        dispatch(resetDateToAndTimeTo({ boundleId: id }));
    };

    const timeFieldValidator = (id, timeType) => (value) => {
        if (!timeRegExp.test(value)) {
            dispatch(updateExtendedBoundle({ boundleId: id, value: true, key: timeType }));
            return 'Wrong format';
        } else {
            dispatch(updateExtendedBoundle({ boundleId: id, value: false, key: timeType }));
            return undefined;
        }
    };

    const updateHandler = (boundle) => () => {
        if (!boundle.time_to_error && !boundle.time_from_error) {
            const body = {
                date_from: boundle.date_from,
                time_from: boundle.time_from,
                date_to: boundle.date_to,
                time_to: boundle.time_to,
            };

            dispatch(
                updateSensorAndPointBoundle({
                    body,
                    boundleId: boundle.id,
                    currentBoundle: boundlesForSelectedIPointById[boundle.id],
                })
            );
        }
    };

    const removeHandler = (boundleId) => () => {
        dispatch(deleteSensorAndPointBoundle({ boundleId }));
    };

    const onRowClickHandler = (sensor) => () => {
        dispatch(storeSensorForBoundleCreate(sensor));
        //setSelectedSensor({ id: sensor.id, ip: sensor.ip, port: sensor.port, sensorName: sensor.sensor_name });
    };

    const renderRowHandler = (props) => {
        return (
            <div onClick={onRowClickHandler(props.item)}>
                <DetailsRow {...props} />
            </div>
        );
    };

    const renderItemColumn = (item, _, column) => {
        const fieldContent = item[column.fieldName];

        let main = false;

        if (
            availableBundlesByIPointId[iPointForBoundleInfoModal.id]?.boundleId === item.id &&
            availableBundlesByIPointId[iPointForBoundleInfoModal.id]?.current
        )
            main = true;

        switch (column.key) {
            case 'currentBoundle':
                return (
                    <>
                        {main ? (
                            <BoundleColumnWrapper>
                                <MainIcon iconName='BoxCheckmarkSolid' newColor='green' />
                            </BoundleColumnWrapper>
                        ) : null}
                    </>
                );

            case 'iPointMarker':
                return <BoundleColumnWrapper>{iPointForBoundleInfoModal.marker}</BoundleColumnWrapper>;
            case 'sensorType':
                return <BoundleColumnWrapper>{sensorsById[item.sensor_id]?.sensor_type}</BoundleColumnWrapper>;
            case 'serialNumber':
                return <BoundleColumnWrapper>{sensorsById[item.sensor_id]?.serial_number}</BoundleColumnWrapper>;
            case 'sensorId':
                return <BoundleColumnWrapper>{sensorsById[item.sensor_id]?.id}</BoundleColumnWrapper>;
            case 'sensorUrl':
                return (
                    <BoundleColumnWrapper>
                        {sensorsById[item.sensor_id]?.ip}:{sensorsById[item.sensor_id]?.port}
                    </BoundleColumnWrapper>
                );
            case 'date_from':
                return (
                    <BoundleColumnWrapper>
                        <DatesWrapper>
                            <DatePicker
                                value={new Date(item.date_from)}
                                onSelectDate={(date) => dateHandler(item.id, date, 'date_from')}
                                formatDate={(date) => DateTime.fromISO(date.toISOString()).toFormat('yyyy-MM-dd')}
                                placeholder='Select a date...'
                            />
                            <MaskedTextField
                                validateOnLoad={false}
                                validateOnFocusOut={true}
                                onGetErrorMessage={timeFieldValidator(item.id, 'time_from_error')}
                                onChange={timeHandler(item.id, 'time_from')}
                                styles={stylesForTimeField}
                                mask='99:99:99'
                                value={item.time_from}
                            />
                        </DatesWrapper>
                    </BoundleColumnWrapper>
                );
            case 'date_to':
                return (
                    <BoundleColumnWrapper>
                        <DatesWrapper>
                            <DatePicker
                                value={item.date_to ? new Date(item.date_to) : undefined}
                                onSelectDate={(date) => dateHandler(item.id, date, 'date_to')}
                                formatDate={(date) => DateTime.fromISO(date.toISOString()).toFormat('yyyy-MM-dd')}
                                placeholder='Select a date...'
                            />

                            <MaskedTextField
                                validateOnLoad={false}
                                validateOnFocusOut={true}
                                onGetErrorMessage={timeFieldValidator(item.id, 'time_to_error')}
                                value={item.time_to}
                                onChange={timeHandler(item.id, 'time_to')}
                                disabled={!item.date_to}
                                mask='99:99:99'
                                styles={stylesForTimeField}
                            />

                            <TooltipHost styles={styledToolTip} content={'Set to null'}>
                                <StyledIcon iconName={'RemoveEvent'} onClick={setToNullHandler(item.id)} />
                            </TooltipHost>
                        </DatesWrapper>
                    </BoundleColumnWrapper>
                );

            case 'update':
                return (
                    <BoundleColumnWrapper>
                        {item.updateBoundleError ? (
                            <div>{item.updateBoundleError}</div>
                        ) : item.updateBoundleFetching ? (
                            <Spinner size={SpinnerSize.large} />
                        ) : (
                            <DefaultButton
                                disabled={item.time_from_error || item.time_to_error}
                                onClick={updateHandler(item)}
                            >
                                Update
                            </DefaultButton>
                        )}
                    </BoundleColumnWrapper>
                );

            case 'remove':
                return (
                    <BoundleColumnWrapper>
                        {item.removeBoundleError ? (
                            <div>{item.removeBoundleError}</div>
                        ) : item.removeBoundleFetching ? (
                            <Spinner size={SpinnerSize.large} />
                        ) : (
                            <CustomButton onClick={removeHandler(item.id)}>Remove</CustomButton>
                        )}
                    </BoundleColumnWrapper>
                );

            default:
                return <BoundleColumnWrapper>{fieldContent}</BoundleColumnWrapper>;
        }
    };

    return (
        <>
            <ModalCreator isModalOpen={open} closeModal={handleClose}>
                <CreateSensorAndPointBoundle closeModal={handleClose} />
            </ModalCreator>
            <SensorsColumnModalTitle>Boundle info</SensorsColumnModalTitle>
            {Object.keys(extendedBoundlesForSelectedIPointById).length === 0 ? (
                <MessageWrapper>No boundles available</MessageWrapper>
            ) : (
                <ListTitle>Available boundles:</ListTitle>
            )}
            {Object.keys(extendedBoundlesForSelectedIPointById).length > 0 && (
                <BoundlesWrapper>
                    <DetailsList
                        items={Object.keys(extendedBoundlesForSelectedIPointById).map(
                            (element) => extendedBoundlesForSelectedIPointById[element]
                        )}
                        selectionMode={0}
                        columns={columnsForBoundlesList}
                        onRenderItemColumn={renderItemColumn}
                    />
                </BoundlesWrapper>
            )}
            <CustomSensorsTextField value={filter} onChange={filterHandler} placeholder='Filter' />
            {filteredSensors.length === 0 ? (
                <MessageWrapper>No another sensors available</MessageWrapper>
            ) : (
                <ListTitle>Available sensors:</ListTitle>
            )}
            {filteredSensors.length > 0 && (
                <BoundlesWrapper>
                    <DetailsList
                        items={filteredSensors}
                        onRenderRow={renderRowHandler}
                        columns={columnsForList}
                        selectionMode={0}
                    />
                </BoundlesWrapper>
            )}
        </>
    );
});

export default BoundleInfoModal;
