import { createContext, memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getInitialData, storeSelectedProjectId } from './Projects.reducer';
import ProjectsList from './components/ProjectsList/ProjectsList';
import { ProjectsWrapper } from './Projects.styles';

/**
 * Компонент для отрисовки проектов
 */
export const ProjectsContext = createContext({});
const Projects = memo(() => {
    const dispatch = useDispatch();
    const { token, storeUrls } = useSelector((state) => state.generalReducer);
    const { projects } = useSelector((state) => state.projectsReducer);

    useEffect(() => {
        dispatch(getInitialData());
        // eslint-disable-next-line
    }, [token, storeUrls]);

    const storeSelectedProjectIdTrans = (id) => dispatch(storeSelectedProjectId(id));

    const contextValue = {
        storeSelectedProjectIdTrans,
        projects,
    };

    return (
        <ProjectsContext.Provider value={contextValue}>
            <ProjectsWrapper>{projects.length ? <ProjectsList /> : null}</ProjectsWrapper>
        </ProjectsContext.Provider>
    );
});
export default Projects;
