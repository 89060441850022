import axios from 'axios';
import getXovisConnectionsV5 from './getXovisConnectionsV5';
import getXovisAgentsV5 from './getXovisAgentsV5';
import getXovisSceneGeometriesV5 from './getXovisSceneGeometriesV5';
import getXovisMultisensorInfoV5 from './getXovisMultisensorInfoV5';
import getXovisSceneImageMetadataV5 from './getXovisSceneImageMetadataV5';
import getXovisViewImageMetadataV5 from './getXovisViewImageMetadataV5';
import getXovisSceneMasksV5 from './getXovisSceneMasksV5';
import getXovisViewMasksV5 from './getXovisViewMasksV5';
import getXovisLayersV5 from './getXovisLayersV5';

/**
 * Получение данных для датчика
 * @param {object} sensor объект сенсора
 * @param {number} timeout timeout
 * @returns объект с ошибкой или объект с данными
 */
const getXovisDataV5 = async (args) => {
    const AGENT_TYPE = 'LOGICS';
    const { sensor, timeout } = args;
    const multisensorInfo = await getXovisMultisensorInfoV5({ sensor, timeout });

    if (multisensorInfo.error) {
        return { error: 'Ann error accrued while getting data' };
    }

    const connections = getXovisConnectionsV5({ sensor, timeout, isMultisensor: multisensorInfo.isMultiSensor });
    const agents = getXovisAgentsV5({ sensor, timeout, isMultisensor: multisensorInfo.isMultiSensor });

    const singlesensorSceneGeometries = getXovisSceneGeometriesV5({
        sensor,
        timeout,
        isMultisensor: false,
    });
    const multisensorSceneGeometries = getXovisSceneGeometriesV5({
        sensor,
        timeout,
        isMultisensor: true,
    });

    const singlesensorSceneImageMetadata = getXovisSceneImageMetadataV5({
        sensor,
        isMultisensor: false,
        timeout,
    });

    const multisensorSceneImageMetadata = getXovisSceneImageMetadataV5({
        sensor,
        isMultisensor: true,
        timeout,
    });

    const singlesensorViewImageMetadata = getXovisViewImageMetadataV5({
        sensor,
        isMultisensor: false,
        timeout,
    });

    const singlesensorSceneMasks = getXovisSceneMasksV5({
        sensor,
        isMultisensor: false,
        timeout,
    });
    const multisensorSceneMasks = getXovisSceneMasksV5({
        sensor,
        isMultisensor: true,
        timeout,
    });

    const singlesensorViewMasks = getXovisViewMasksV5({
        sensor,
        timeout,
    });

    return axios
        .all([
            connections,
            agents,
            singlesensorSceneImageMetadata,
            multisensorSceneImageMetadata,
            singlesensorViewImageMetadata,
            singlesensorSceneGeometries,
            multisensorSceneGeometries,
            singlesensorSceneMasks,
            multisensorSceneMasks,
            singlesensorViewMasks,
        ])
        .then(
            axios.spread(
                (
                    connections,
                    agents,
                    singlesensorSceneImageMetadata,
                    multisensorSceneImageMetadata,
                    singlesensorViewImageMetadata,
                    singlesensorSceneGeometries,
                    multisensorSceneGeometries,
                    singlesensorSceneMasks,
                    multisensorSceneMasks,
                    singlesensorViewMasks,
                ) => {
                    const result = {
                        connections: [],
                        companyConnection: null,
                        agents: [],
                        companyAgent: null,
                        isMultiSensor: multisensorInfo.isMultiSensor,
                        layers: {},
                        multiSensorLayers: {},
                    };

                    if (!connections.error) {
                        result.connections = connections.connections;
                        result.companyConnection = connections.companyConnection;
                    }

                    if (!agents.error) {
                        const companyAgent =
                            agents.agents.find(
                                (agent) =>
                                    result.companyConnection &&
                                    result.companyConnection.id === agent.connection &&
                                    agent.type === AGENT_TYPE,
                            ) || null;
                        result.agents = agents.agents;
                        result.companyAgent = companyAgent;
                    }

                    const layers = getXovisLayersV5({
                        singlesensorSceneImageMetadata,
                        multisensorSceneImageMetadata,
                        singlesensorViewImageMetadata,
                        singlesensorSceneGeometries,
                        multisensorSceneGeometries,
                        singlesensorSceneMasks,
                        multisensorSceneMasks,
                        singlesensorViewMasks,
                    });

                    result.layers = layers.layers;
                    result.multiSensorLayers = layers.multiSensorLayers;

                    return result;
                },
            ),
        )
        .catch((_) => {
            return { error: 'Ann error accrued while getting data' };
        });
};

export default getXovisDataV5;
