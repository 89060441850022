import getTdNetworkConfig from '../../../components/countingSensors/tools/td/getTdNetworkConfig';

/**
 * Вспомогательная функция для получения серийного номера от сенсора, при пакетной загрузке данных
 * @param {object} singleDataPackage элемент из csv файла или csv строки
 * @returns object с ошибкой или с сериным номером и флагом о различии серийников
 */
const getTdSerialNumberForTable = async (options) => {
    const { singleDataPackage } = options;
    let notEqualSerialNumber = false;
    let serialNumber = '';

    const networkConfig = await getTdNetworkConfig({
        ip: singleDataPackage.ip,
        port: singleDataPackage.port,
    });

    if (!networkConfig.error) {
        serialNumber = networkConfig.deviceMac;

        if (
            singleDataPackage.serial_number &&
            serialNumber.toLowerCase() !==
                singleDataPackage.serial_number.toLowerCase()
        )
            notEqualSerialNumber = true;

        return {
            id: singleDataPackage.id,
            serialNumber,
            notEqualSerialNumber,
        };
    }
    return { id: singleDataPackage.id, error: networkConfig.error };
};

export default getTdSerialNumberForTable;
