import styled from 'styled-components/macro';

export const ChangeUrlTitle = styled.div`
    font-size: 16px;
    font-weight: 600;
`;

export const ButtonWrapper = styled.div`
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;
