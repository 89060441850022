import { MessageBar } from '@fluentui/react';
import styled from 'styled-components/macro';

export const SerialNumberWarningTitle = styled.div`
    font-size: 16px;
    text-align: center;
    font-weight: 600;
    width: 1000px;
`;

export const StyledMessageBar = styled(MessageBar)`
    margin-top: 15px;
`;

export const SerialNumber = styled.div`
    margin-top: 8px;
    span {
        font-weight: 600;
    }
`;

export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;
