import axios from 'axios';
import { megacountProgressMessages } from '../../../../utils/megacountHelpers/megacountProgressMessages';
import getMegacountNetworkSettings from './getMegacountNetworkSettings';
import getMegacountUploadServers from './getMegacountUploadServers';
import getMegacountDetection from './getMegacountDetection';

/**
 * Promise. Функция для получения информация о датчике megacount
 * @param {object} sensor объект сенсора
 * @returns возвращает обновленный объект сенсора с дополнительными полями
 */
const getMegacountSensorData = (sensor) => {
    const errorResponse = {
        ...sensor,
        isConfigurationFetching: false,
        firstStepError: megacountProgressMessages.firstStepError,
    };

    const networkSettings = getMegacountNetworkSettings({
        ip: sensor.ip,
        port: sensor.port,
        username: sensor.username,
        password: sensor.password,
    });

    const uploadServers = getMegacountUploadServers({
        ip: sensor.ip,
        port: sensor.port,
        username: sensor.username,
        password: sensor.password,
    });

    const detection = getMegacountDetection({
        ip: sensor.ip,
        port: sensor.port,
        username: sensor.username,
        password: sensor.password,
    });

    return axios
        .all([networkSettings, uploadServers, detection])
        .then(
            axios.spread((networkSettings, uploadServers, detection) => {
                let firstStepError = '';

                let megacountData = {
                    networkSettings: {},
                    uploadServers: [],
                    companyUploadServer: null,
                    sensors: [],
                    layers: {
                        exceptions: [],
                        floors: [],
                        masks: [],
                        passWays: [],
                        zones: [],
                    },
                };

                if (!networkSettings.error) {
                    megacountData = { ...megacountData, networkSettings };
                } else {
                    firstStepError = megacountProgressMessages.firstStepError;
                }

                if (!uploadServers.error) {
                    megacountData = { ...megacountData, ...uploadServers };
                }

                if (!detection.error) {
                    megacountData = {
                        ...megacountData,
                        layers: {
                            ...megacountData.layers,
                            ...detection.layers,
                        },
                        sensors: detection.sensors,
                    };
                }

                return {
                    ...sensor,
                    isConfigurationFetching: false,
                    firstStepError,
                    megacountData,
                };
            }),
        )
        .catch((_) => {
            return { ...errorResponse };
        });
};

export default getMegacountSensorData;
