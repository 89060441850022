import { tdGetRequest } from './td.api';

/**
 * Получения данных сети датчика (Device IP, Subnet Mask, Gateway IP, DNS Server IP, Device MAC, Local Web Server Port)
 * @param {string} ip IP датчика
 * @param {number} port Порт датчика
 * @param {number} timeout Таймаут на запрос в миллисекундах
 * @returns Promise результатом которого является объект с данными о настойках сети или объект с ошибкой
 */
const getTdNetworkConfig = async (args) => {
    const { ip, port, timeout } = args;

    const config = await tdGetRequest({
        url: `http://${ip}:${port}/network_config`,
        timeout,
    });

    if (!config.error) {
        const oParser = new DOMParser();
        const networkConfigHTML = oParser.parseFromString(config, 'text/html');
        const deviceMac = networkConfigHTML.querySelector('#mac')?.value;
        const deviceIp = networkConfigHTML.querySelector('#ip')?.value;
        const subnetMask = networkConfigHTML.querySelector('#netmask')?.value;
        const gatewayIp = networkConfigHTML.querySelector('#gateway')?.value;
        const dnsServerIp =
            networkConfigHTML.querySelector('#dnsserver')?.value;
        const dhsp = networkConfigHTML.querySelector('#dhcp')?.checked;
        const localWebServerPort =
            networkConfigHTML.querySelector('#httpport')?.value;

        return {
            localWebServerPort,
            dnsServerIp,
            subnetMask,
            gatewayIp,
            deviceMac,
            deviceIp,
            dhsp,
        };
    }

    return {
        error: 'Unable to get sensor network config',
    };
};

export default getTdNetworkConfig;
