/**
 * Функция для получения информации о всех линиях подсчета
 * @param {string} config конфигурация сенсора
 * @param {object} multiSensorInfo информация о мультисенсоре
 * @returns массив со всеми линиями подсчета
 */
const getCountLinesInfo = (args) => {
    const { config, multiSensorInfo = null } = args;
    const finalArray = [];
    const oParser = new DOMParser();
    const xmlResponse = oParser.parseFromString(config, 'text/xml');
    const cntlineArray = xmlResponse.querySelectorAll('cntline');
    const multiSensorData = xmlResponse.querySelector('settings > multisensor')?.innerHTML;

    for (let element of cntlineArray) {
        const attributes = element.attributes;
        const marker = attributes.name.textContent;
        if (multiSensorInfo) {
            if (
                multiSensorData &&
                multiSensorData.includes(marker) &&
                multiSensorInfo.scaleX &&
                multiSensorInfo.scaleY &&
                multiSensorInfo.xStartCoordinate &&
                multiSensorInfo.yStartCoordinate
            ) {
                const result = getPassWay(marker, attributes, multiSensorInfo);
                finalArray.push(result);
            }
        } else {
            if (!multiSensorData?.includes(marker)) {
                const result = getPassWay(marker, attributes);
                finalArray.push(result);
            }
        }
    }
    return finalArray;
};

export default getCountLinesInfo;

const getPassWay = (marker, attributes, multiSensorInfo = null) => {
    let counter = 1;
    const passWay = [];
    while (attributes[`x${counter}`] && attributes[`y${counter}`]) {
        let xCoordinate = +attributes[`x${counter}`].textContent;
        let yCoordinate = +attributes[`y${counter}`].textContent;

        if (multiSensorInfo) {
            xCoordinate = xCoordinate / multiSensorInfo.scaleX + multiSensorInfo.xStartCoordinate;
            yCoordinate = yCoordinate / multiSensorInfo.scaleY + multiSensorInfo.yStartCoordinate;
        }

        passWay.push([xCoordinate, yCoordinate]);
        counter++;
    }
    return { marker, coordinates: passWay };
};
