import { createSlice } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';
import { SENSOR_CLASSES_BY_TYPE, sensorTypes } from '../../constants/sensorTypes';
import idGenerator from '../../utils/idGenerator';
import csvDataConsolidation from './tools/csvDataConsolidation';
import defaultUploadingOrder from './tools/defaultUploadingOrder';
import {
    portionDropdownKeys,
    sensorsManagementMode,
    sensorsManagementModeKeys,
    portionDropdownOptions,
} from './tools/dropdownOptions';
import portionHandler from './tools/portionHandler';
import refreshSelectedSensors from './tools/refreshSelectedSensors';
import { showErrorNotification } from '../../generalReducer';
import getSensorsConfig from './tools/getSensorsConfig';
import getScreensFromSensors from './tools/screenshotMode/getScreensFromSensors';
import getSensorsSerialNumber from './tools/getSensorsSerialNumber';
import processingSensorsRefresh from './tools/processingSensorsRefresh';
import processFailedIntervals from './tools/processFailedIntervals';
import postScreensToLambda from './tools/screenshotMode/postScreensToLambda';
import generateDataForScreenPost from './tools/screenshotMode/generateDataForScreenPost';
import changeCoordinatesByScale from './tools/screenshotMode/changeCoordinatesByScale';
import { putRequest, postRequest } from '../../api/api';
import { yandexLambdaUrls } from '../../constants/yandexLambdaUrls';
import parseCSV from './tools/parseCSV';
import { reexportTaskStatus } from './../../constants/reexportTaskStatus';
import connectionTestHandler from './tools/connectionMode/connectionTestHandler';
import { DefaultCompanyInfo } from '../../shared/company-info';

const initialState = {
    sensorsForManagement: [],
    sensorsForManagementById: {},
    parseErrors: [],
    selectedSensors: [],
    portionType: portionDropdownOptions[1],
    uploadingOrderFetching: false,
    dataUploadFinished: false,
    selectedMode: sensorsManagementMode[0],
    columnForSort: null,
    columnsForList: [],
    timeout: 5,
    isCSVDataIncludesDateFields: false,
    dateTo: undefined,
    dateFrom: undefined,
    timeTo: '00:15:00',
    timeFrom: '00:00:00',
    timeFieldsError: false,
    temporaryToken: '',
    reexportTasksDateFrom: DateTime.now().minus({ days: 1 }).toFormat('yyyy-MM-dd'),
    reexportTasksDateTo: DateTime.now().toFormat('yyyy-MM-dd'),
    reexportFetching: false,
    reexportTasks: [],
    reexportTasksError: '',
    progressLog: false,
    plTasks: [],
    extendedPlTasks: [],
    reexportProcessedTasks: [],
    tasksByPl_Date: {},
    tasksToProcess: [],
};

const sensorsManegementReducer = createSlice({
    name: 'sensorsManegementReducer',
    initialState,
    reducers: {
        // Запись сенсоров из csv источников
        storeSensorsForManagement: (state, action) => {
            const plTasks = cloneDeep(state.plTasks);

            const extendedArray = action.payload.map((element) => {
                const id = idGenerator();
                Object.keys(plTasks).forEach((key) => {
                    const tasks = plTasks[key].sensor_tasks;
                    tasks.forEach((task, i) => {
                        if (
                            task.ip === element.ip &&
                            task.port === element.port &&
                            task.type === element.type &&
                            task.date_from === element.date_from &&
                            task.date_to === element.date_to
                        ) {
                            plTasks[key].sensor_tasks[i] = {
                                ...task,
                                id,
                            };
                        }
                    });
                });

                return {
                    ...element,
                    id,
                    errors: [],
                    orderCount: 0,
                    successOrderCount: 0,
                    dataFetching: false,
                    serialNumber: '',
                    screen: '',
                    layers: {},
                    isMultiSensor: false,
                    successScreenPost: false,
                    hikvisionData: null,
                    failedIntervals: [],
                    vivotekDataUploadUrl: '',
                    brickstreamUrlNumber: null,
                    screenScale: { x: 1, y: 1 },
                    xovisData: {},
                };
            });

            const sensorsById = extendedArray.reduce((acc, value) => {
                acc[value.id] = value;
                return acc;
            }, {});

            state.extendedPlTasks = plTasks;
            state.sensorsForManagement = extendedArray;
            state.sensorsForManagementById = sensorsById;
        },

        // Запись в стейт временного токена из квери параметров
        storeTemporaryToken: (state, action) => {
            state.temporaryToken = action.payload;
        },

        // Изменение флага для загрузки прогресс лога
        toggleProgressLog: (state, action) => {
            state.progressLog = action.payload;
        },

        // Запсиь информации об ошибках при парсинге csv
        storeParseErrors: (state, action) => {
            state.parseErrors = action.payload;
        },

        // Запись информации о выбранных сенсорах
        storeSelectedSensors: (state, action) => {
            state.selectedSensors = action.payload;
        },

        // Запись типа порции для запроса к сенсорам
        storePortionType: (state, action) => {
            state.portionType = action.payload;
        },

        // Изменение флага о заказе выгрузке по выбранным датчикам
        toggleUploadingOrderFetching: (state, action) => {
            state.uploadingOrderFetching = action.payload;
        },

        // Обновление всех сенсоров
        updateSensorsForManagement: (state, action) => {
            state.sensorsForManagementById = action.payload;
        },

        // Обновление одного датчика
        updateSensorForManagementById: (state, action) => {
            const { id, data } = action.payload;
            if (state.sensorsForManagementById[id]) {
                state.sensorsForManagementById[id] = data;
            }
        },

        // Изменение флага, отвечающего за конец выгрузки данных с датчиков
        toggleDataUploadFinished: (state, action) => {
            state.dataUploadFinished = action.payload;
        },

        // Изменение вида действия с сенсорами
        storeSelectedMode: (state, action) => {
            state.selectedMode = action.payload;
        },

        // Запись колонки для сортировки
        storeColumnForSort: (state, action) => {
            state.columnForSort = action.payload;
        },

        // Запись колонок для списка
        storeColumnsForList: (state, action) => {
            state.columnsForList = action.payload;
        },

        // Запись таймаута
        storeTimeout: (state, action) => {
            state.timeout = action.payload;
        },

        // Запись dateFrom
        storeDateFrom: (state, action) => {
            state.dateFrom = action.payload;
        },

        // Запись dateTo
        storeDateTo: (state, action) => {
            state.dateTo = action.payload;
        },

        // Запись dateTo
        storeTimeTo: (state, action) => {
            state.timeTo = action.payload;
        },

        // Запись dateFrom
        storeTimeFrom: (state, action) => {
            state.timeFrom = action.payload;
        },

        // Изменение флаага наличия даты в CSV файле
        toggleCSVDataIncludesDateFields: (state, action) => {
            state.isCSVDataIncludesDateFields = action.payload;
        },

        // Изменение флага полей ввода времени
        toggleTimeFieldsError: (state, action) => {
            state.timeFieldsError = action.payload;
        },

        // Изменение флага загрузки задчач выгрузки с датчиков
        toggleReexportFetching: (state, action) => {
            state.reexportFetching = action.payload;
        },

        // Запись в стейт задач по выгрузке с датчиков
        storeReexportTasks: (state, action) => {
            const reexportTasks = [];
            const tasksToProcess = [];
            const processedTasks = [];
            action.payload.forEach((pl_task) => {
                reexportTasks.push(...pl_task.sensor_tasks);
                if (pl_task.processed_tasks) {
                    pl_task.processed_tasks.forEach((processedTask) => {
                        processedTasks.push(processedTask);

                        if (processedTask.status === reexportTaskStatus.failure) {
                            const failedTask = cloneDeep(processedTask);
                            delete failedTask.status;
                            delete failedTask.error_log;
                            tasksToProcess.push({
                                ...failedTask,
                                plId: +pl_task.pl_id,
                            });
                        }
                    });
                } else {
                    pl_task.sensor_tasks.forEach((task) => {
                        tasksToProcess.push({ ...task, plId: +pl_task.pl_id });
                    });
                }
            });

            const tasksByPl_Date = action.payload.reduce((acc, value) => {
                acc[`${value.pl_id}_${value.date}`] = value;
                return acc;
            }, {});

            state.tasksToProcess = tasksToProcess;
            state.tasksByPl_Date = tasksByPl_Date;
            state.reexportProcessedTasks = processedTasks;
            state.plTasks = action.payload;
            state.reexportTasks = reexportTasks;
        },

        // Запись в стейт ошибки при получении задач на выгрузку с датчиков
        storeReexportTasksError: (state, action) => {
            state.reexportTasksError = action.payload;
        },

        // Запись в стейт reexport date_from
        storeReexportTasksDateFrom: (state, action) => {
            state.reexportTasksDateFrom = action.payload;
        },

        // Запись в стейт reexport date_to
        storeReexportTasksDateTo: (state, action) => {
            state.reexportTasksDateTo = action.payload;
        },

        // Обнуление стейта
        resetSensorsManagementReducer: () => initialState,
    },
});

export const {
    storeSensorsForManagement,
    storeParseErrors,
    storeSelectedSensors,
    storePortionType,
    resetSensorsManagementReducer,
    toggleUploadingOrderFetching,
    updateSensorsForManagement,
    toggleDataUploadFinished,
    storeSelectedMode,
    storeColumnForSort,
    storeColumnsForList,
    storeTimeout,
    toggleCSVDataIncludesDateFields,
    storeDateFrom,
    storeDateTo,
    storeTimeTo,
    storeTimeFrom,
    toggleTimeFieldsError,
    storeTemporaryToken,
    toggleReexportFetching,
    storeReexportTasks,
    storeReexportTasksError,
    storeReexportTasksDateFrom,
    storeReexportTasksDateTo,
    toggleProgressLog,
    updateSensorForManagementById,
} = sensorsManegementReducer.actions;

/**
 * Thunk. Служит для парса CSV данных
 * @param {file} CSVFile csv файл
 * @param {string} CSVString csv строка
 */
export const parseCSVDataThunk = (CSVFile, CSVString) => async (dispatch, getState) => {
    const INIT_CSV_STRING = 'ip;port;type;pass;user;date_from;date_to';
    const { tasksToProcess } = getState().sensorsManegementReducer;
    dispatch(storeParseErrors([]));
    dispatch(toggleDataUploadFinished(false));
    let dataFromParsedCSVFile = null;
    let dataFromParsedCSVString = null;
    let dataFromParsedCSVJson = null;
    let errors = [];
    let dateFields = false;

    try {
        if (CSVFile) {
            const result = await parseCSV(CSVFile);
            dateFields = result.dateFields;
            errors = errors.concat(result.errors);
            dataFromParsedCSVFile = result.parsedData;
        }

        if (CSVString.replace(INIT_CSV_STRING, '')) {
            const result = await parseCSV(CSVString);
            dateFields = result.dateFields;
            errors = errors.concat(result.errors);
            dataFromParsedCSVString = result.parsedData;
        }

        if (tasksToProcess.length > 0) {
            dateFields = true;
            dataFromParsedCSVJson = cloneDeep(tasksToProcess);
        }

        const combinedData = csvDataConsolidation(
            dataFromParsedCSVFile,
            dataFromParsedCSVString,
            dataFromParsedCSVJson,
            errors,
            dateFields,
        );

        dispatch(toggleCSVDataIncludesDateFields(dateFields));
        dispatch(storeParseErrors(errors));
        dispatch(storeSensorsForManagement(combinedData));
    } catch (error) {
        console.log('>>>>sensors management parse error', error);
    }
};

/**
 * Thunk. Обработчки кнопки старт. От сюда, в зависимости от мода
 */
export const startSensorsManagementThunk = () => (dispatch, getState) => {
    const { selectedMode, timeFieldsError, dateFrom, dateTo, isCSVDataIncludesDateFields } =
        getState().sensorsManegementReducer;
    switch (selectedMode.key) {
        case sensorsManagementModeKeys.CONNECTION_TEST:
            dispatch(connectionTestThunk());
            break;

        case sensorsManagementModeKeys.DATA_UPLOAD:
            if (timeFieldsError) {
                dispatch(
                    showErrorNotification({
                        show: true,
                        message: 'Time fields error',
                    }),
                );
            } else if (!isCSVDataIncludesDateFields && (!dateTo || !dateFrom)) {
                dispatch(
                    showErrorNotification({
                        show: true,
                        message: 'Datetime interval error',
                    }),
                );
            } else {
                dispatch(uploadingOrderThunk());
            }

            break;

        case sensorsManagementModeKeys.SCREENS_UPLOAD:
            dispatch(uploadScreenshotsThunk());
            break;

        default:
            break;
    }
};

/**
 * Thunk. Пакетная выгрузка данных со всех выбранных датчиков
 */
export const uploadingOrderThunk = () => async (dispatch, getState) => {
    dispatch(toggleUploadingOrderFetching(true));
    const {
        portionType,
        selectedSensors,
        sensorsForManagementById,
        dateTo,
        dateFrom,
        timeTo,
        timeFrom,
        isCSVDataIncludesDateFields,
        timeout,
    } = getState().sensorsManegementReducer;
    const { copy, selectedCopy } = refreshSelectedSensors({
        selectedSensors,
        sensorsForManagementById,
    });
    const selectedSensorsCopy = cloneDeep(selectedCopy);
    const timeoutInMillis = timeout * 1000;
    const datetimeTo = `${dateTo}T${timeTo}`;
    const datetimeFrom = `${dateFrom}T${timeFrom}`;
    const dateToInMillis = DateTime.fromISO(datetimeTo).toMillis();
    const dateFromInMillis = DateTime.fromISO(datetimeFrom).toMillis();
    dispatch(updateSensorsForManagement(copy));
    const PORTION_SIZE = 10;

    const selectedSensorsCopyByIpPort = selectedSensorsCopy
        .filter((sensor) => !SENSOR_CLASSES_BY_TYPE[sensor.type])
        .reduce((acc, value) => {
            if (acc[`${value.ip}:${value.port}_${value.type}`]) {
                acc[`${value.ip}:${value.port}_${value.type}`].push(value);
            } else {
                acc[`${value.ip}:${value.port}_${value.type}`] = [value];
            }
            return acc;
        }, {});

    const sensorsWithConfig = await getSensorsConfig({
        sensorsForManagementById: copy,
        timeout: timeoutInMillis,
        selectedSensorsCopyByIpPort,
    });
    let sensorsForManagementByIdCopy = cloneDeep(sensorsWithConfig);

    // Обработка запроса по порциям (1 час, 1 день, 15 минут, переданный интервал)
    switch (portionType.key) {
        case portionDropdownKeys.FIFTEEN_MINUTES: {
            const FIFTEEN_MINUTES_IN_MILLIS = 900000;
            const data = await portionHandler({
                isCSVDataIncludesDateFields,
                sensorsForManagementByIdCopy,
                portionSize: FIFTEEN_MINUTES_IN_MILLIS,
                selectedSensorsCopyByIpPort,
                dateToInMillis,
                dateFromInMillis,
                selectedSensorsCopy: selectedSensorsCopy.filter((sensor) => !SENSOR_CLASSES_BY_TYPE[sensor.type]),
                timeout: timeoutInMillis,
            });

            sensorsForManagementByIdCopy = data;
            break;
        }
        case portionDropdownKeys.DAY_PORTION: {
            const ONE_DAY_IN_MILLIS = 86400000;
            const data = await portionHandler({
                isCSVDataIncludesDateFields,
                sensorsForManagementByIdCopy,
                portionSize: ONE_DAY_IN_MILLIS,
                selectedSensorsCopyByIpPort,
                dateToInMillis,
                dateFromInMillis,
                selectedSensorsCopy: selectedSensorsCopy.filter((sensor) => !SENSOR_CLASSES_BY_TYPE[sensor.type]),
                timeout: timeoutInMillis,
            });

            sensorsForManagementByIdCopy = data;
            break;
        }
        case portionDropdownKeys.HOUR_PORTION: {
            const ONE_HOUR_IN_MILLIS = 3600000;
            const data = await portionHandler({
                isCSVDataIncludesDateFields,
                sensorsForManagementByIdCopy,
                portionSize: ONE_HOUR_IN_MILLIS,
                selectedSensorsCopyByIpPort,
                dateToInMillis,
                dateFromInMillis,
                selectedSensorsCopy: selectedSensorsCopy.filter((sensor) => !SENSOR_CLASSES_BY_TYPE[sensor.type]),
                timeout: timeoutInMillis,
            });

            sensorsForManagementByIdCopy = data;
            break;
        }

        default: {
            const data = await defaultUploadingOrder({
                selectedSensorsCopy: selectedSensorsCopy.filter((sensor) => !SENSOR_CLASSES_BY_TYPE[sensor.type]),
                sensorsForManagementById: sensorsForManagementByIdCopy,
                dateToInMillis,
                dateFromInMillis,
                isCSVDataIncludesDateFields,
                timeout: timeoutInMillis,
            });

            sensorsForManagementByIdCopy = data;
            break;
        }
    }

    dispatch(updateSensorsForManagement(sensorsForManagementByIdCopy));

    const companyInfo = new DefaultCompanyInfo();

    const subarray = []; //массив в который будет выведен результат.
    for (let i = 0; i < Math.ceil(selectedSensorsCopy.length / PORTION_SIZE); i++) {
        subarray[i] = selectedSensorsCopy.slice(i * PORTION_SIZE, i * PORTION_SIZE + PORTION_SIZE);
    }

    for (let array of subarray) {
        const promises = [];

        array.forEach((sensor) => {
            if (SENSOR_CLASSES_BY_TYPE[sensor.type]) {
                const instance = new SENSOR_CLASSES_BY_TYPE[sensor.type](
                    sensor.ip,
                    sensor.port,
                    sensor.type,
                    sensor.user,
                    sensor.pass,
                    companyInfo,
                    timeout,
                );
                let dateTimeFrom = `${dateFrom}T${timeFrom}`;
                let dateTimeTo = `${dateTo}T${timeTo}`;
                if (isCSVDataIncludesDateFields) {
                    dateTimeFrom = sensor.date_from;
                    dateTimeTo = sensor.date_to;
                }
                promises.push(
                    instance.resendData(dateTimeFrom, dateTimeTo).then((response) => {
                        dispatch(
                            updateSensorForManagementById({
                                id: sensor.id,
                                data: { ...sensor, ...response, dataFetching: false },
                            }),
                        );
                    }),
                );
            }
        });
        await Promise.allSettled(promises);
    }

    dispatch(toggleDataUploadFinished(true));
    dispatch(toggleUploadingOrderFetching(false));
    dispatch(toggleProgressLog(true));
};

/**
 * Thunk. Для получения скриншотов с выбранных датчиков и отправки данных на лямбду
 */
export const uploadScreenshotsThunk = () => async (dispatch, getState) => {
    const { selectedSensors, sensorsForManagementById, timeout } = getState().sensorsManegementReducer;

    const timeoutInMillis = timeout * 1000;
    const { copy, selectedCopy } = refreshSelectedSensors({
        selectedSensors,
        sensorsForManagementById,
    });
    dispatch(updateSensorsForManagement(copy));
    const PORTION_SIZE = 10;

    const selectedSensorsCopyByIpPort = selectedCopy
        .filter((sensor) => !SENSOR_CLASSES_BY_TYPE[sensor.type])
        .reduce((acc, value) => {
            if (acc[`${value.ip}:${value.port}_${value.type}`]) {
                acc[`${value.ip}:${value.port}_${value.type}`].push(value);
            } else {
                acc[`${value.ip}:${value.port}_${value.type}`] = [value];
            }
            return acc;
        }, {});

    const sensorsWithConfig = await getSensorsConfig({
        sensorsForManagementById: copy,
        timeout: timeoutInMillis,
        selectedSensorsCopyByIpPort,
    });

    const sensorsWithSerialNumber = await getSensorsSerialNumber({
        sensorsForManagementById: sensorsWithConfig,
        selectedSensors: selectedCopy.filter((sensor) => !SENSOR_CLASSES_BY_TYPE[sensor.type]),
        timeout: timeoutInMillis,
    });

    const sensorsWithScreen = await getScreensFromSensors({
        selectedSensors: selectedCopy.filter((sensor) => !SENSOR_CLASSES_BY_TYPE[sensor.type]),
        sensorsForManagementById: sensorsWithSerialNumber,
        timeout: timeoutInMillis,
    });

    const dataForPost = [];
    selectedCopy
        .filter((sensor) => !SENSOR_CLASSES_BY_TYPE[sensor.type])
        .forEach((element) => {
            const sensor = sensorsWithScreen[element.id];
            if (sensor.errors.length === 0) {
                switch (sensor.type) {
                    case sensorTypes.XOVIS: {
                        const data = generateDataForScreenPost(sensor);
                        dataForPost.push(...data);
                        break;
                    }

                    case sensorTypes.VIVOTEK: {
                        const sensorWithScaledLayers = changeCoordinatesByScale(sensor);
                        const data = generateDataForScreenPost(sensorWithScaledLayers);
                        dataForPost.push(...data);
                        break;
                    }

                    case sensorTypes.HIKVISION: {
                        const sensorWithScaledLayers = changeCoordinatesByScale(sensor);
                        const data = generateDataForScreenPost(sensorWithScaledLayers);
                        dataForPost.push(...data);
                        break;
                    }

                    case sensorTypes.TD: {
                        const data = generateDataForScreenPost(sensor);
                        dataForPost.push(...data);
                        break;
                    }

                    case sensorTypes.MEGACOUNT: {
                        const sensorWithScaledLayers = changeCoordinatesByScale(sensor);
                        const data = generateDataForScreenPost(sensorWithScaledLayers);
                        dataForPost.push(...data);
                        break;
                    }

                    default:
                        console.log('Invalid type');
                        break;
                }
            }
        });

    const sensorsAfterPost = await postScreensToLambda({
        sensorsForManagementById: sensorsWithScreen,
        dataForPost,
        timeout: timeoutInMillis,
    });

    const sensorsForManagementByIdCopy = cloneDeep(sensorsAfterPost);

    dispatch(updateSensorsForManagement(sensorsForManagementByIdCopy));

    const companyInfo = new DefaultCompanyInfo();

    const subarray = []; //массив в который будет выведен результат.
    for (let i = 0; i < Math.ceil(selectedCopy.length / PORTION_SIZE); i++) {
        subarray[i] = selectedCopy.slice(i * PORTION_SIZE, i * PORTION_SIZE + PORTION_SIZE);
    }

    for (let array of subarray) {
        const promises = [];

        array.forEach((sensor) => {
            if (SENSOR_CLASSES_BY_TYPE[sensor.type]) {
                const instance = new SENSOR_CLASSES_BY_TYPE[sensor.type](
                    sensor.ip,
                    sensor.port,
                    sensor.type,
                    sensor.user,
                    sensor.pass,
                    companyInfo,
                    timeout,
                );

                promises.push(
                    instance.sendScreen('https://functions.yandexcloud.net/d4e9fbbs6uacir2i83ki').then((response) => {
                        dispatch(
                            updateSensorForManagementById({
                                id: sensor.id,
                                data: {
                                    ...sensor,
                                    ...response,
                                    dataFetching: false,
                                    successScreenPost: !response.errors.length,
                                },
                            }),
                        );
                    }),
                );
            }
        });
        await Promise.allSettled(promises);
    }
};

/**
 * Thunk. Для обработки зафейленых интервалов у выбранных сенсоров
 */
export const processFailedIntervalsThunk = () => async (dispatch, getState) => {
    const { selectedSensors, sensorsForManagementById, timeout } = getState().sensorsManegementReducer;
    const timeoutInMillis = timeout * 1000;
    const refreshedSensors = processingSensorsRefresh({
        selectedSensors,
        sensorsForManagementById,
    });
    dispatch(updateSensorsForManagement(refreshedSensors));

    const result = await processFailedIntervals({
        sensorsForManagementById: refreshedSensors,
        selectedSensors,
        timeout: timeoutInMillis,
    });

    dispatch(updateSensorsForManagement(result));
    dispatch(toggleProgressLog(true));
};

/**
 * Thunk. Для проверки сенсора (онлайн он или нет)
 */
export const connectionTestThunk = () => async (dispatch, getState) => {
    const PORTION_SIZE = 10;
    const { timeout, selectedSensors, sensorsForManagementById } = getState().sensorsManegementReducer;
    const { selectedCopy, copy } = refreshSelectedSensors({
        selectedSensors,
        sensorsForManagementById,
    });
    dispatch(updateSensorsForManagement(copy));

    const companyInfo = new DefaultCompanyInfo();

    const subarray = []; //массив в который будет выведен результат.
    for (let i = 0; i < Math.ceil(selectedCopy.length / PORTION_SIZE); i++) {
        subarray[i] = selectedCopy.slice(i * PORTION_SIZE, i * PORTION_SIZE + PORTION_SIZE);
    }

    for (let array of subarray) {
        const promises = [];
        array.forEach((sensor) => {
            if (SENSOR_CLASSES_BY_TYPE[sensor.type]) {
                const instance = new SENSOR_CLASSES_BY_TYPE[sensor.type](
                    sensor.ip,
                    sensor.port,
                    sensor.type,
                    sensor.user,
                    sensor.pass,
                    companyInfo,
                    timeout,
                );

                promises.push(
                    instance.connectionTest().then((response) => {
                        dispatch(
                            updateSensorForManagementById({
                                id: sensor.id,
                                data: { ...sensor, ...response, dataFetching: false },
                            }),
                        );
                    }),
                );
            }
            promises.push(
                connectionTestHandler({
                    sensor,
                    timeout: timeout * 1000,
                }).then((response) => {
                    dispatch(
                        updateSensorForManagementById({
                            id: sensor.id,
                            data: { ...sensor, ...response, dataFetching: false },
                        }),
                    );
                }),
            );
        });

        await Promise.allSettled(promises);
    }
};

/**
 * Thunk. Получает массив задач для выгрузки с датчиков
 */
export const reciveReexportTasksThunk = () => async (dispatch, getState) => {
    const { reexportTasksDateFrom, reexportTasksDateTo, temporaryToken } = getState().sensorsManegementReducer;
    dispatch(storeReexportTasksError(''));

    dispatch(toggleReexportFetching(true));
    const payload = JSON.stringify({
        date_from: reexportTasksDateFrom,
        date_to: reexportTasksDateTo,
        x_token: temporaryToken,
    });
    const response = await postRequest(`${yandexLambdaUrls['fpc-dm-aggregate-recovery-exports-tasks']}`, '', payload);
    dispatch(toggleReexportFetching(false));

    if (!response.error) {
        dispatch(storeReexportTasks(response));
    } else {
        dispatch(storeReexportTasksError(response.error.response.data.error_message || 'Get reexport tasks error'));
    }
};

/**
 * Функция для создание в бакете прогресс логов, по датчикамЮ которые успешно отправили данные
 * @param {object} progressLog объект с данными, где ключ это дата+pl_id
 */
export const CreateProgressLogThunk = (progressLog) => async (dispatch) => {
    const PORTION_SIZE = 5;

    const subarray = []; //массив в который будет выведен результат.
    for (let i = 0; i < Math.ceil(Object.keys(progressLog).length / PORTION_SIZE); i++) {
        subarray[i] = Object.keys(progressLog).slice(i * PORTION_SIZE, i * PORTION_SIZE + PORTION_SIZE);
    }

    for (let array of subarray) {
        const promises = [];
        array.forEach((key) => {
            const item = progressLog[key];
            promises.push(putRequest(item.logUrl, '', JSON.stringify(item.payload)));
        });

        await Promise.all(promises).then((responses) => {
            responses.forEach(() => {});
        });
    }
};
export default sensorsManegementReducer.reducer;
