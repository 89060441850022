import { sensorTypes } from '../../../../constants/sensorTypes';
import { brickstreamDataUploadCorrectValues } from '../../../../utils/brickstreamHelpers/brickstreamDataUploadCorrectValues';
import { brickstreamProgressMessages } from '../../../../utils/brickstreamHelpers/brickstreamProgressMessages';
import compareOfPassWays from '../../../../utils/sensorsAndBoundlesHelpers/compareOfPassWays';

/**
 * Вспомогательная функция для создания связки с сенсоров BRICKSTREAm
 * @param {object} iPoint объект точки установки
 * @param {object} sensor объект сенсора
 * @param {object} currentBoundle объект текущей связки
 */
const setBoundleForBrickstreamHelper = (options) => {
    const { iPoint, sensor, currentBoundle, accessKey, uploadUrl } = options;

    let thirdStepError = '';
    let secondStepError = '';

    if (sensor.isFetched && !sensor.sensorData?.errors?.length && !sensor.firstStepError) {
        const CORRECT_URL = `${uploadUrl?.protocol}://${uploadUrl?.host}/fpc/v1/${sensorTypes.BRICKSTREAM}/${accessKey}`;
        const CORRECT_PORT = uploadUrl?.port ?? '';
        const sensorUrl = `https://${sensor.sensorData?.cfg?.dataPushServers?.companyDataPushServer?.host}${sensor.sensorData?.cfg?.dataPushServers?.companyDataPushServer?.url}`;
        const sensorPort = sensor.sensorData?.cfg?.dataPushServers?.companyDataPushServer?.port;

        if (sensorUrl !== CORRECT_URL || Number(sensorPort) !== Number(CORRECT_PORT)) {
            thirdStepError = brickstreamProgressMessages.thirdStepErrorWithNoMatchUrls;
        }

        if (
            !sensor.sensorData?.cfg?.dataPushServers?.companyDataPushServer ||
            sensor.sensorData?.cfg?.dataPushServers?.companyDataPushServer.pushGranularity !==
                brickstreamDataUploadCorrectValues.dataUploadGranularity
        ) {
            thirdStepError = brickstreamProgressMessages.thirdStepInvalidDataUploadGranularity;
        }
        if (
            !sensor.sensorData?.cfg?.dataPushServers?.companyDataPushServer ||
            sensor.sensorData?.cfg?.dataPushServers?.companyDataPushServer.pushInterval !==
                brickstreamDataUploadCorrectValues.dataUploadInterval
        ) {
            thirdStepError = brickstreamProgressMessages.thirdStepInvalidDataUploadInterval;
        }

        if (
            !sensor.sensorData?.cfg?.dataPushServers?.companyDataPushServer &&
            !sensor.sensorData?.cfg?.dataPushServers?.dataPushServers.some((server) => server.isEmpty)
        ) {
            thirdStepError = 'No available servers';
        }
        if (!sensor.sensorData?.cfg?.layers?.passWays?.length) {
            const compareResult = compareOfPassWays(iPoint.passPoints, sensor.sensorData?.cfg?.layers?.passWays ?? []);
            if (compareResult) {
                secondStepError = '';
            } else {
                secondStepError = brickstreamProgressMessages.secondStepError;
            }
        } else {
            secondStepError = brickstreamProgressMessages.secondStepError;
        }
    } else {
        thirdStepError = brickstreamProgressMessages.firstStepError;
        secondStepError = brickstreamProgressMessages.secondStepError;
    }
    return {
        iPointId: iPoint.id,
        value: {
            ...sensor,
            isFetching: sensor.isConfigurationFetching,
            current: true,
            boundleId: currentBoundle.id,
            secondStepError,
            thirdStepError,
        },
    };
};

export default setBoundleForBrickstreamHelper;
