import styled from 'styled-components/macro';

export const Floor = styled.div`
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid #0078d4;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-weight: 700;
    background-color: ${(props) => (props.isActiveFloor ? '#0078d4' : 'white')};
    color: ${(props) => (props.isActiveFloor ? 'white' : '#201f1e')};
    :hover {
        background-color: #0078d4;
        color: #ffff;
    }
`;

export const FloorWrapper = styled.div`
    margin-top: 10px;
`;
