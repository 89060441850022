import { DefaultButton, TextField } from '@fluentui/react';
import { useContext } from 'react';
import { useState } from 'react';
import MaskedIpInput from '../createSensorModal/components/maskedIpInput';
import { ModalContext } from '../modalCreator';
import { UpdateSensorModalTitle, FieldWrapper, ButtonWrapper, StyledPort } from './updateSensorModal.styles';

/**
 * Модальное окно для обновления сенсора
 * @param {object} sensorToUpdate сенсор, который нужно обновить
 * @param {function} closeUpdateSensorModal функция для закртия модального окна
 */
const UpdateSensorModal = ({ sensorToUpdate, closeUpdateSensorModal }) => {
    const { updateSensorThunkTrans } = useContext(ModalContext);
    const [ip, setIp] = useState(sensorToUpdate.ip);
    const [ipError, setIpError] = useState(false);
    const [port, setPort] = useState(sensorToUpdate.port);
    const [portError, setPortError] = useState(false);
    const [login, setLogin] = useState(sensorToUpdate.username);
    const [password, setPassword] = useState(sensorToUpdate.password);
    const [passwordError, setPasswordError] = useState(false);

    const ipHandler = (e) => {
        setIpError(false);
        setIp(e.target.value);
    };

    const portHandler = (_, newValue) => {
        setPortError(false);
        setPort(newValue);
    };

    const loginHandler = (_, newValue) => setLogin(newValue);

    const passwordHandler = (_, newValue) => {
        setPasswordError(false);
        setPassword(newValue);
    };

    const updateHandler = () => {
        if (ip && port && password) {
            updateSensorThunkTrans(ip, port, sensorToUpdate.id, password, login, sensorToUpdate.sensor_type);
            closeUpdateSensorModal();
        } else {
            if (!ip) setIpError(true);
            if (!port) setPortError(true);
            if (!password) setPasswordError(true);
        }
    };

    return (
        <>
            <UpdateSensorModalTitle>Update sensor</UpdateSensorModalTitle>
            <FieldWrapper>
                <MaskedIpInput inputError={ipError} inputValue={ip} setInputValue={ipHandler} label='Ip' />
                <StyledPort
                    errorMessage={portError ? 'Required' : null}
                    onChange={portHandler}
                    value={port}
                    label='Port'
                />
            </FieldWrapper>

            <TextField value={login} onChange={loginHandler} label='Login (optional)' title='Login' />
            <TextField
                value={password}
                errorMessage={passwordError ? 'This field is required' : null}
                onChange={passwordHandler}
                label='Password'
                type='password'
                title='Password'
            />
            <ButtonWrapper>
                <DefaultButton onClick={updateHandler}>Update</DefaultButton>
            </ButtonWrapper>
        </>
    );
};

export default UpdateSensorModal;
