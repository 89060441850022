import { tdGetRequest } from '../../../../api/td/td.api';
import { getCompanyUploadUrlPart } from '../../../../constants/constants';
import getTdServerUploadSettings from './getTdServerUploadSettings';

/**
 * Функция для получения конфигурации сервисов датчика (Конфигурация выгрузки)
 * @param {string} ip IP датчика
 * @param {number} port PORT датчика
 * @param {number} timeout Таймаут на запрос в миллисекундах
 * @returns Promise c информацией о сервисах или объект с ошибкой
 */
const getTdServiceConfig = async (args) => {
    const { ip, port, timeout } = args;

    const config = await tdGetRequest({
        url: `http://${ip}:${port}/service_config`,
        timeout,
    });

    if (!config.error) {
        const oParser = new DOMParser();
        const serviceConfigHTML = oParser.parseFromString(config, 'text/html');
        const reportServersById = {
            1: {
                id: 1,
                ...getReportServerData(serviceConfigHTML),
            },
            2: {
                id: 2,
                ...getReportServerData(serviceConfigHTML, '2'),
            },
        };

        const companyServerId =
            Object.values(reportServersById).find((server) =>
                server.reportServerAddress.includes(getCompanyUploadUrlPart()),
            )?.id || null;

        let companyServerUploadSettings = {};

        if (companyServerId) {
            const settings = await getTdServerUploadSettings({
                serverId: companyServerId,
                port,
                ip,
            });

            if (!settings.error) {
                companyServerUploadSettings = settings;
            }
        }
        return {
            reportServersById,
            companyServerId,
            companyServerUploadSettings,
        };
    }

    return {
        error: 'Unable to get sensor service config',
    };
};

export default getTdServiceConfig;

/**
 * Функция для получения данных о сервере выгрузки
 * @param {string} serviceConfigHTML Данные конфигурации, полученные от датчика
 * @param {string} serverNumber номер сервера выгрузки (может принимать значения '' и '2')
 */
const getReportServerData = (serviceConfigHTML, serverNumber = '') => {
    const reportServerAddress = serviceConfigHTML.querySelector(
        `#serverip${serverNumber}`,
    )?.value;
    const reportServerPort = serviceConfigHTML.querySelector(
        `#serverport${serverNumber}`,
    )?.value;
    const reportServerDirectory = serviceConfigHTML.querySelector(
        `#serverpath${serverNumber}`,
    )?.value;
    const isActive = serviceConfigHTML.querySelector(`#DoubleIP`)?.checked;

    return {
        reportServerAddress,
        reportServerPort,
        reportServerDirectory,
        isActive: serverNumber === '2' ? Boolean(isActive) : true,
    };
};
