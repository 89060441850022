import { vivotekDataUploadCorrectValues } from '../../../../utils/vivotekHelpers/vivotekDataUploadCorrectValues';
import { vivotekProgressMessages } from '../../../../utils/vivotekHelpers/vivotekProgressMessages';
/**
 * Вспомогательная функция для создания связки с сенсоров BRICKSTREAm
 * @param {object} iPoint объект точки установки
 * @param {object} sensor объект сенсора
 * @param {object} currentBoundle объект текущей связки
 * @param {string} accessKey ключ для выгрузки данных
 * @param {string} accessKey актуальная выгрузка для датчика
 */
const setBoundleForVivotekHelper = (options) => {
    const { iPoint, sensor, currentBoundle, accessKey, uploadUrl } = options;

    let vivotekCountLinesError = vivotekProgressMessages.firstStepError;
    let thirdStepError = vivotekProgressMessages.firstStepError;

    if (!sensor.firstStepError) {
        if (!sensor.vivotekDataUploadUrl) {
            thirdStepError = vivotekProgressMessages.thirdStepErrorWithNoUrl;
        } else {
            const CORRECT_URL = `${uploadUrl.protocol}://${uploadUrl.host}/fpc/v1/${sensor.sensor_type}/${accessKey}`;
            if (`https://${sensor.vivotekDataUploadUrl}` !== CORRECT_URL) {
                thirdStepError = vivotekProgressMessages.thirdStepErrorWithNoMatchUrls;
            } else {
                thirdStepError = '';
            }
        }

        if (!sensor.dataUploadInterval) {
            thirdStepError = vivotekProgressMessages.thirdStepNoDataUplaodInterval;
        } else if (sensor.dataUploadInterval !== vivotekDataUploadCorrectValues.dataUploadInterval) {
            thirdStepError = vivotekProgressMessages.thirdStepInvalidDataUploadInterval;
        }

        if (!sensor.dataUploadGranularity) {
            thirdStepError = vivotekProgressMessages.thirdStepNoDataUplaodGranularity;
        } else if (sensor.dataUploadGranularity !== vivotekDataUploadCorrectValues.dataUploadGranularity) {
            thirdStepError = vivotekProgressMessages.thirdStepInvalidDataUploadGranularity;
        }

        let success = [];

        if (sensor.vivotekCountLinesByLineName && Object.keys(sensor.vivotekCountLinesByLineName).length > 0) {
            Object.keys(iPoint.passPoints).forEach((point) => {
                if (sensor.vivotekCountLinesByLineName[iPoint.passPoints[point].lineName]) success.push('succes');
            });

            if (success.length === Object.keys(iPoint.passPoints).length) {
                vivotekCountLinesError = '';
            } else {
                vivotekCountLinesError = vivotekProgressMessages.secondStepError;
            }
        } else {
            vivotekCountLinesError = vivotekProgressMessages.secondStepError;
        }
    }

    return {
        iPointId: iPoint.id,
        value: {
            ...sensor,
            isFetching: sensor.isConfigurationFetching,
            current: true,
            boundleId: currentBoundle.id,
            testDataPushFetching: false,
            thirdStepError,
            vivotekCountLinesError,
        },
    };
};

export default setBoundleForVivotekHelper;
