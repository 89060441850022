import { dilaxProgressMessages } from '../../../../utils/dilaxHelpers/dilaxProgressMessages';

/**
 * Promise. Функция для получения дополнительной информация о дптчике rstat
 * @param {object} sensor объект сенсора
 * @returns возвращает обновленный объект сенсора с дополнительными полями
 */
const getDilaxSensorData = (sensor) => {
    const errorResponse = {
        ...sensor,
        isConfigurationFetching: false,
        firstStepError: dilaxProgressMessages.firstStepError,
    };

    return errorResponse;
};

export default getDilaxSensorData;
