import { useState } from 'react';
import { Star } from 'react-konva';
import scaleTransform from '../../../utils/locationDisplay/scaleTransform';
import IntersectionsLabel from './intersectionsLabel';

const zeroLabel = { show: false, text: '', x: 0, y: 0 };

/**
 * Компонент для отрисовки звездочки, которая показывает, где ус пересекает проход
 * @param {number} stageScale скейл конваса
 * @param {object} intersectionInfo объект с информацией о конкретном пересечении
 * @param {function} changeOrder функция для изменения порядка массива пересечений, для корректного тображения
 */
const SingleIntersection = ({ stageScale, intersectionInfo, changeOrder }) => {
    const [label, setLabel] = useState(zeroLabel);

    const onMouseEnter = () => {
        changeOrder(intersectionInfo.id);
        const x = intersectionInfo.coords[0];
        const y = intersectionInfo.coords[1];
        const text = `line_name: ${intersectionInfo.info.lineName}\ni_point_marker: ${intersectionInfo.info.pccMarker}\npass_way_marker: ${intersectionInfo.info.passWayMarker}\npass_way_name: ${intersectionInfo.info.passWayName}`;
        setLabel({ show: true, text, x, y });
    };
    const onMouseLeave = () => {
        setLabel({ ...zeroLabel });
    };

    return (
        <>
            <Star
                x={intersectionInfo.coords[0]}
                y={intersectionInfo.coords[1]}
                numPoints={8}
                innerRadius={scaleTransform(5, stageScale)}
                outerRadius={scaleTransform(10, stageScale)}
                fill={'blue'}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            />
            {label.show && <IntersectionsLabel x={label.x} y={label.y} text={label.text} stageScale={stageScale} />}
        </>
    );
};

export default SingleIntersection;
