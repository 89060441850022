import { IconButton } from '@fluentui/react';
import styled from 'styled-components/macro';

export const CustomIconButton = styled(IconButton)`
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 10;
    ::after {
        display: none;
    }
`;

export const ContentWrapper = styled.div`
    padding: 12px;
    height: 100%;
    border-top: ${(props) => (props.borderTop ? props.borderTop : '4px solid rgb(0, 120, 212)')};
    position: relative;
`;

export const Title = styled.div`
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px;
`;
