import { cloneDeep } from 'lodash';

/**
 * Функция фильтрации списка объектов.
 * @param arr Входящий массив объектов.
 * @param fieldNames Поля объекта, по которому фильтровать.
 * @param matchText Текст который должен содержаться в поле fieldName
 * @returns Возвращает отфильтрованный массив объектов.
 */
export const filterListOfObjects = <T extends object>(
    arr: Array<T>,
    fieldNames: string[],
    matchText: string,
): Array<T> => {
    return matchText === ''
        ? cloneDeep(arr)
        : cloneDeep(arr).filter((item) => {
              let flag = false;
              fieldNames.forEach((name) => {
                  if (!flag) {
                      if (typeof item[name as keyof typeof item] !== 'object') {
                          flag = String(item[name as keyof typeof item])
                              .toLowerCase()
                              .includes(matchText.toLowerCase());
                      } else {
                          flag = JSON.stringify(item[name as keyof typeof item])
                              .toLowerCase()
                              .includes(matchText.toLowerCase());
                      }
                  }
              });
              return flag;
          });
};
