import { xovisGetRequest } from '../../../../../api/xovis/xovis.api';

/**
 * получение масок
 * @param {object} sensor объект датчика
 * @param {number} timeout timeout
 * @param {boolean} isMultisensor флаг мультисенсор или нет
 * @returns объект с ошибкой или данные
 *
 */
const getXovisSceneMasksV5 = async (args) => {
    const { sensor, timeout, isMultisensor } = args;

    const sceneMasks = await xovisGetRequest({
        url: `http://${sensor.ip}:${sensor.port}/api/v5/${
            isMultisensor ? 'multisensor' : 'singlesensor'
        }/scene/masks`,
        timeout,
        username: sensor.username,
        password: sensor.password,
    });

    if (!sceneMasks.error) {
        return sceneMasks.scene_masks;
    }

    return {
        error: 'Ann error accrued while getting scene masks',
    };
};

export default getXovisSceneMasksV5;
