import { hikvisionNewDataUploadBody } from '../../constants/hikvisionNewDataUploadBody';

/**
 * Функция для получения новой конфигурации для датчика HIKVISION
 * @param {string} hikvisionDataUploadFullUrl полный урл для выгрузки с датчика
 * @param {string} hikVisionDataUploadHost хост для выгрузки с датчика
 * @param {string} hikvisionDataUploadUrl урл для выгрузки с датчика
 * @returns новую конфигурацию
 */
const getNewHikvisionConfiguration = (options) => {
    const {
        hikvisionDataUploadFullUrl,
        hikVisionDataUploadHost,
        hikvisionDataUploadUrl,
        newUpload = false,
        hikvisionEmptyDataUploadId,
        uploadKey,
        hikvisionDataUploadBody,
        port,
        uploadUrl,
    } = options;

    if (newUpload) {
        const newConfiguration = hikvisionNewDataUploadBody
            .replace('<id></id>', `<id>${hikvisionEmptyDataUploadId}</id>`)
            .replace('<hostName></hostName>', `<hostName>${uploadUrl?.host}</hostName>`)
            .replace('<url></url>', `<url>/fpc/v1/hikvision/${uploadKey}</url>`)
            .replace('<portNo></portNo>', `<portNo>${port}</portNo>`);
        return newConfiguration;
    }

    if (hikvisionDataUploadBody) {
        if (hikvisionDataUploadFullUrl) {
            const newConfiguration = hikvisionDataUploadBody
                .replace(`<hostName>${hikVisionDataUploadHost}</hostName>`, `<hostName>${uploadUrl?.host}</hostName>`)
                .replace(`<url>${hikvisionDataUploadUrl}</url>`, `<url>/fpc/v1/hikvision/${uploadKey}</url>`);
            return newConfiguration;
        }
    } else {
        return 'No data to post';
    }
};

export default getNewHikvisionConfiguration;
