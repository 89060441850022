import { megacountPostRequest } from '../../../../api/megacount/megacount.api';

/**
 * Получения данных сети датчика (mac, ip итд)
 * @param {string} ip IP датчика
 * @param {number} port Порт датчика
 * @param {string} username логин датчика
 * @param {string} password пароль датчика датчика
 * @param {number} timeout timeout на запрос
 * @returns Promise результатом которого является объект с данными о настойках сети или объект с ошибкой
 */
const getMegacountNetworkSettings = async (args) => {
    const { ip, port, username, password, timeout } = args;

    const networkSettings = await megacountPostRequest({
        url: `http://${ip}:${port}/settings`,
        payload: {
            settings: {
                get: ['NetworkSettings'],
            },
        },
        username,
        password,
        timeout,
    });

    if (!networkSettings.error) {
        return networkSettings?.settings?.get?.NetworkSettings || {};
    } else {
        return {
            error: 'Unable to get sensor network settings',
        };
    }
};

export default getMegacountNetworkSettings;
