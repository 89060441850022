import axios from 'axios';

/**
 * Функция для получения данных от датчика rstat (POST)
 * @param {string} url урл на который нужно сделать запрос
 * @param {any} payload тело запроса
 * @returns ответ от датчика или объект с ошибкой
 */
export const rstatPostRequest = async (args) => {
    const { url, payload } = args;
    try {
        const response = await axios.post(url, payload, { timeout: 15000 }).then((response) => response.data);
        return response;
    } catch (error) {
        return {
            error: error.response?.data || 'Something went wrong',
        };
    }
};
