import generateMegacountUploadUpdatePayload from '../../../../utils/megacountHelpers/generateMegacountUploadUpdatePayload';
import { megacountProgressMessages } from '../../../../utils/megacountHelpers/megacountProgressMessages';
import { updateExtendedSelectedIPoint } from '../../../instalationPoints/installationPointsReducer';
import updateMegacountUploadServer from './updateMegacountUploadServer';

/**
 * Функция для работы с состоянием конкретной связки, получением новой выгрузки
 * на датчике и обновлением выгрузки для датчика
 * @param {*} dispatch функция для обновления
 * @param {*} bundle текущая актуальная связь точки установки и датчика
 * @param {*} iPointId ID точки установки
 * @param {*} accessKey ключ доступа для локации / проекта
 * @param {*} sensorsById объект расширенных данных о датчиках
 * @param {*} uploadUrl объект с информацией по выгрузке
 * @returns promise
 */
const processMegacountBatchServerUpdate = async (args) => {
    const { dispatch, bundle, iPointId, accessKey, sensorsById, uploadUrl } = args;
    const promise = new Promise((resolve) => resolve());

    if (!bundle?.thirdStepError) {
        dispatch(
            updateExtendedSelectedIPoint({
                data: {
                    isFetching: false,
                    isError: false,
                    message: megacountProgressMessages.thirdStepEnabled,
                },
                iPointId,
            }),
        );
        return promise;
    }

    if (!bundle?.firstStepError && bundle?.thirdStepError && bundle?.isFetched) {
        const newConfiguration = generateMegacountUploadUpdatePayload({
            companyUploadServer: bundle?.megacountData?.companyUploadServer,
            uploadKey: accessKey,
            uploadUrl,
        });

        const updateServerResponse = await updateMegacountUploadServer({
            sensor: sensorsById[bundle.id],
            payloadBody: newConfiguration,
            showNotifications: false,
            currentBoundle: bundle,
            iPointId,
            dispatch,
        });

        if (!updateServerResponse?.error) {
            dispatch(
                updateExtendedSelectedIPoint({
                    data: {
                        isFetching: false,
                        isError: false,
                        message: 'Success',
                    },
                    iPointId,
                }),
            );
        } else {
            dispatch(
                updateExtendedSelectedIPoint({
                    data: {
                        message: updateServerResponse?.error || '',
                        isFetching: false,
                        isError: true,
                    },
                    iPointId,
                }),
            );
        }
        return promise;
    }
    dispatch(
        updateExtendedSelectedIPoint({
            data: {
                isFetching: false,
                isError: true,
                message: 'Something went wrong!',
            },
            iPointId,
        }),
    );

    return promise;
};

export default processMegacountBatchServerUpdate;
