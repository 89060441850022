import { services } from '../../constants/services';

/**
 * Функция для обновления хостов на выбранные хосты
 * @param {object} locales  локали
 * @param {array} selectedHosts массив выбранных хостов
 * @returns объект хостов с дополнительным полем checked
 */
const updateHostsObject = (locales, selectedHosts) => {
    const selectedHostsKeys = Object.keys(selectedHosts);
    const newHostsObject = {};

    services.forEach((service) => {
        newHostsObject[service] = {
            checked: false,
            url: locales.services[service],
        };
    });

    if (selectedHostsKeys.length > 0) {
        selectedHostsKeys.forEach((element) => {
            newHostsObject[element] = selectedHosts[element];
        });
        return newHostsObject;
    }
    return newHostsObject;
};

export default updateHostsObject;
