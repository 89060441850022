import {
    getCompanyName,
    getCompanyUploadUrlPart,
} from '../../../../constants/constants';

/**
 * Функция для парсинга ответа от сенсора
 * @param {string} data xml данные с сенсора
 * @returns объект с информацией о выгрузке сенсора или ошибку
 */
const processRowHttpHosts = (data) => {
    let dataUploadUrl = '';
    const oParser = new DOMParser();
    const xmlResponse = oParser.parseFromString(data, 'text/xml');
    const httpHosts = xmlResponse.querySelectorAll('HttpHostNotification');
    for (let host of httpHosts) {
        const hostName = host.querySelector('hostName');
        if (
            hostName &&
            hostName.textContent.includes(getCompanyUploadUrlPart())
        ) {
            const url = host.querySelector('url');
            if (url) {
                dataUploadUrl = `http://${hostName.textContent}${url.textContent}`;
            }
        }
    }

    if (dataUploadUrl) {
        return { dataUploadUrl };
    }
    return { error: `No ${getCompanyName()} url` };
};

export default processRowHttpHosts;
