import { memo, ReactNode, useContext, useEffect, useState } from 'react';
import { IExtendedPlTask } from '../../interfaces';
import FilterInput from '../FilterInput/FilterInput';
import { TasksMonitoringContext } from '../TasksMonitoring/TasksMonitoring.context';
import { FilterWrapper, ProjectLocationsWrapper } from './ProjectLocations.styles';
import { cloneDeep } from 'lodash';
import List from '../List/List';
import { projectLocataionsColumns } from './ProjectLocations.columns';
import { IColumn } from '@fluentui/react';
import SensorsTasksCount from './components/SensorsTasksCount/SensorsTasksCount';
import TimeIntervalSuccessTasks from '../TimeIntervalSuccessTasks/TimeIntervalSuccessTasks';

/**
 * Компонент для отображения секции с проектными локациями
 */
const ProjectLocations = memo(() => {
    const { extendedPlTasksByProjectId, selectedProjectId, extendedSensorsByPlId, storeSelectedPlIdTrans } =
        useContext(TasksMonitoringContext);
    const [projectLocations, setProjectLocations] = useState<IExtendedPlTask[]>([]);
    const [filteredProjectLocations, setFilteredProjectLocations] = useState<IExtendedPlTask[]>([]);

    useEffect(() => {
        selectedProjectId &&
            setProjectLocations(
                Object.keys(extendedPlTasksByProjectId[selectedProjectId]).map(
                    (plId) => extendedPlTasksByProjectId[selectedProjectId][+plId],
                ),
            );
    }, [extendedPlTasksByProjectId, selectedProjectId]);

    useEffect(() => {
        projectLocations.length && setFilteredProjectLocations(cloneDeep(projectLocations));
    }, [projectLocations]);

    const updateFilteredProjectLocations = (filteredArray: object[]) => {
        setFilteredProjectLocations(filteredArray as IExtendedPlTask[]);
    };

    const onRowClick = (item: IExtendedPlTask) => {
        storeSelectedPlIdTrans && storeSelectedPlIdTrans(item.plId);
    };

    const onRenderItemColumn = (item: IExtendedPlTask, index?: number, column?: IColumn): ReactNode => {
        if (!column?.fieldName) return null;
        const fieldContent = item[column.fieldName as keyof IExtendedPlTask];
        switch (column.key) {
            case 'sensorsTasksCount':
                return <SensorsTasksCount sensorsTasks={extendedSensorsByPlId[item.plId]} />;

            case 'timeIntervalSuccessTasks':
                return (
                    <TimeIntervalSuccessTasks
                        processedTasks={item.timeIntervalProcessedTasks}
                        successTasks={item.timeIntervalSuccessTasks}
                    />
                );
            default:
                return <span>{fieldContent as string}</span>;
        }
    };

    return (
        <ProjectLocationsWrapper>
            <FilterWrapper>
                <FilterInput
                    inputArray={projectLocations}
                    matchFields={['name', 'plId']}
                    updateFilteredArray={updateFilteredProjectLocations}
                />
            </FilterWrapper>
            <List
                onRowClick={onRowClick}
                items={filteredProjectLocations}
                columns={projectLocataionsColumns}
                availableSortKeys={[
                    'name',
                    'plId',
                    'timeIntervalTasks',
                    'timeIntervalSuccessTasks',
                    'timeIntervalProcessedTasks',
                ]}
                onRenderItemColumn={onRenderItemColumn}
            />
        </ProjectLocationsWrapper>
    );
});

export default ProjectLocations;
