import { FC } from 'react';
import { ISensorsTasksCountProps } from './SensorsTasksCount.interfaces';
import CalloutByIconButton from '../../../CalloutByIconButton/CalloutByIconButton';
import StackHorizontal from '../../../StackHorizontal/StackHorizontal';
import { SensorDataText } from './SensorsTasksCount.styles';

/**
 * Комопнент для отображения количества сенсоров на конкретной локации
 * @param sensorsTasks объект со всеми сенсорами по данной локации
 */
export const SensorsTasksCount: FC<ISensorsTasksCountProps> = ({ sensorsTasks }) => {
    return (
        <CalloutByIconButton
            text={String(Object.keys(sensorsTasks).length)}
            iconName={'ComplianceAudit'}
            toolTipText={'Show / hide sensors'}
        >
            {Object.keys(sensorsTasks).map((key, index) => (
                <StackHorizontal key={index + Math.random()}>
                    <div>
                        <SensorDataText>Ip: </SensorDataText>
                        {sensorsTasks[key].ip}
                    </div>
                    <div>
                        <SensorDataText>Port: </SensorDataText>
                        {sensorsTasks[key].port}
                    </div>
                    <div>
                        <SensorDataText>Type: </SensorDataText>
                        {sensorsTasks[key].type}
                    </div>
                    <div>
                        <SensorDataText>Tasks: </SensorDataText>
                        {sensorsTasks[key].timeIntervalTasks}
                    </div>
                    <div>
                        <SensorDataText>Processed tasks: </SensorDataText>
                        {sensorsTasks[key].timeIntervalProcessedTasks}
                    </div>
                    <div>
                        <SensorDataText>Success tasks: </SensorDataText>
                        {sensorsTasks[key].timeIntervalSuccessTasks}
                    </div>
                </StackHorizontal>
            ))}
        </CalloutByIconButton>
    );
};

export default SensorsTasksCount;
