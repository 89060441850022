import styled from 'styled-components/macro';

export const CellWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
`;

export const Wrapper = styled.div`
    position: relative;
    height: 60vh;
    width: 80vw;
`;
