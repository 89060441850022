import { cloneDeep } from 'lodash';
import * as Papa from 'papaparse';

/**
 * Функция обработки CSV данных
 * @param {any} CSV csv данные
 * @param {string} delimiter разделитель
 */
const parseCSV = async (CSV, delimiter = ';') => {
    const errors = [];
    let dateFields = false;
    let parsedData = null;

    const parseResult = await new Promise((resolve) => {
        Papa.parse(CSV, {
            header: true,
            skipEmptyLines: true,
            delimiter,
            complete: (results, _) => {
                resolve(results);
            },
        });
    });

    if (parseResult.errors.length > 0) {
        parseResult.errors.forEach((element) => {
            errors.push({ ...element, elementData: cloneDeep(parseResult.data[element.row]) });
        });
    }

    dateFields =
        JSON.stringify(parseResult.meta.fields).includes('date_from') &&
        JSON.stringify(parseResult.meta.fields).includes('date_to');

    if (parseResult.data.length > 0) parsedData = parseResult.data;
    return { errors, dateFields, parsedData };
};

export default parseCSV;
