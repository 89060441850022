/**
 * Функция для получения информации о всех полами
 * @param {string} config конфигурация сенсора
 * @returns массив со всеми полами
 */
const getFloorsInfo = ({ config }) => {
    const finalArray = [];
    const oParser = new DOMParser();
    const xmlResponse = oParser.parseFromString(config, 'text/xml');
    const floorsArray = xmlResponse.querySelectorAll('floormasking > movingmask');

    for (let floor of floorsArray) {
        const attributes = floor.attributes;
        const marker = attributes.name.textContent;
        const floorCoordinates = [];
        const pointsArray = floor.querySelectorAll('point');

        for (let point of pointsArray) {
            floorCoordinates.push([+point.attributes.x.textContent, +point.attributes.y.textContent]);
        }
        finalArray.push({ marker, coordinates: floorCoordinates });
    }

    return finalArray;
};

export default getFloorsInfo;
