import { Link } from '@fluentui/react';
import { useContext, useRef } from 'react';
import { InstallationPointsContext } from '../../../../../installationPoints';
import { PasswordTextArea } from './address.styles';

/**
 * Компонент отображения ссылки на привязанный сенсор в колонке
 * @param {object} iPoint объект точки установки
 */
const Address = ({ iPoint }) => {
    const { availableBundlesByIPointId } = useContext(InstallationPointsContext);
    const passwordTextArea = useRef();

    return (
        <>
            <PasswordTextArea ref={passwordTextArea} />
            {availableBundlesByIPointId[iPoint.id] && (
                <Link
                    href={`http://${availableBundlesByIPointId[iPoint.id].ip}:${
                        availableBundlesByIPointId[iPoint.id].port
                    }`}
                    underline
                >{`${availableBundlesByIPointId[iPoint.id].ip}:${availableBundlesByIPointId[iPoint.id].port}`}</Link>
            )}
        </>
    );
};

export default Address;
