import { FC, useEffect, useState } from 'react';
import { IListProps, IListItem } from './List.interfaces';
import {
  ListWrapper,
  evenRowCustomStyles,
  rowCustomStyles,
} from './List.styles';
import {
  DetailsList,
  ScrollablePane,
  ScrollbarVisibility,
  IColumn,
  IDetailsRowProps,
  DetailsRow,
  Sticky,
  IDetailsHeaderProps,
  StickyPositionType,
} from '@fluentui/react';

/**
 * Компонент для генерации списков
 */
const List: FC<IListProps> = ({ onRowClick, ...props }) => {
  const [sortedItems, setSortedItems] = useState<IListItem[]>(props.items);
  const [columns, setColumns] = useState<IColumn[]>(props.columns);

  useEffect(() => {
    setSortedItems(props.items);
  }, [props.items]);

  const rowClickHandler = (item: IListItem) => () => {
    onRowClick && onRowClick(item);
  };

  const onRenderRow = (props: IDetailsRowProps | undefined) => {
    let isEven = false;
    if (props && props.itemIndex % 2 === 0) isEven = true;
    if (props) {
      return (
        <div onClick={rowClickHandler(props.item)}>
          <DetailsRow
            {...props}
            styles={isEven ? evenRowCustomStyles : rowCustomStyles}
          />
        </div>
      );
    }
    return (
      <DetailsRow
        {...(props as unknown as IDetailsRowProps)}
        styles={isEven ? evenRowCustomStyles : rowCustomStyles}
      />
    );
  };

  const onRenderDetailsHeader = (
    headerProps: IDetailsHeaderProps | undefined,
    defaultRender:
      | ((props?: IDetailsHeaderProps | undefined) => JSX.Element | null)
      | undefined
  ) => {
    if (!defaultRender) return null;

    return (
      <Sticky
        stickyPosition={StickyPositionType.Header}
        isScrollSynced={true}
        stickyBackgroundColor='transparent'
      >
        {defaultRender(headerProps)}
      </Sticky>
    );
  };

  const onColumnHeaderClick = (
    _: React.MouseEvent<HTMLElement> | undefined,
    column: IColumn | undefined
  ): void => {
    if (!column || !props.availableSortKeys.includes(column.key)) return;
    let isSortedDescending = column.isSortedDescending;

    // If we've sorted this column, flip it.
    if (column.isSorted) {
      isSortedDescending = !isSortedDescending;
    }

    // Sort the items.
    const items = copyAndSort(
      sortedItems,
      column.fieldName!,
      isSortedDescending
    );

    // Reset the items and columns to match the state.
    setSortedItems(items);
    setColumns(
      columns.map((col) => {
        col.isSorted = col.key === column.key;
        if (col.isSorted) {
          col.isSortedDescending = isSortedDescending;
        }
        return col;
      })
    );
  };

  function copyAndSort<T>(
    items: T[],
    columnKey: string,
    isSortedDescending?: boolean
  ): T[] {
    const key = columnKey as keyof T;
    return items
      .slice(0)
      .sort((a: T, b: T) =>
        (isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1
      );
  }

  return (
    <ListWrapper>
      <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
        <DetailsList
          onRenderDetailsHeader={onRenderDetailsHeader}
          onColumnHeaderClick={onColumnHeaderClick}
          onRenderItemColumn={props.onRenderItemColumn}
          onRenderRow={onRenderRow}
          items={sortedItems}
          columns={columns}
          selectionMode={0}
        />
      </ScrollablePane>
    </ListWrapper>
  );
};

export default List;
