export const columnsForParseErrorsModal = [
    {
        key: 'data',
        name: 'Data',
        fieldName: 'data',
        isResizable: true,
        minWidth: 250,
        maxWidth: 500,
    },
    {
        key: 'message',
        name: 'Error message',
        fieldName: 'message',
        isResizable: true,
        minWidth: 250,
        maxWidth: 500,
    },
    {
        key: 'row',
        name: 'Row number',
        fieldName: 'row',
        isResizable: true,
        minWidth: 100,
        maxWidth: 100,
    },
];
