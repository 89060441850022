import { xovisGetRequest } from '../../../../../api/xovis/xovis.api';

/**
 * Получение информации о версии датчика и его серийном номере
 * @param {object} sensor объект сенсора
 * @param {number} timeout таймаут
 * @returns Объект с ошибкой или объект с информацией о версии датчика и его серийный номер
 */
const getXovisInfoV5 = async (args) => {
    const { sensor, timeout } = args;

    const info = await xovisGetRequest({
        url: `http://${sensor.ip}:${sensor.port}/api/v5/device/info`,
        username: sensor.username,
        password: sensor.password,
        timeout,
    });

    if (!info.error && info) {
        return {
            serialNumber: info.serial,
            version: info.fw_version,
        };
    }
    return {
        error: 'Ann error accrued while getting info',
    };
};

export default getXovisInfoV5;
