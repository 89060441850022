/**
 * Функция для парсинга ответа от сенсора
 * @param {string} data xml данные с сенсора
 * @returns объект с информацией о канале сенсора или ошибку
 */
const processRowChannels = (data) => {
    let channelId = '';
    let channelName = '';
    const oParser = new DOMParser();
    const xmlResponse = oParser.parseFromString(data, 'text/xml');
    const inputChannels = xmlResponse.querySelectorAll('VideoInputChannel');
    for (let channel of inputChannels) {
        const id = channel.querySelector('id');
        const name = channel.querySelector('name');
        if (id) channelId = id.textContent;
        if (name) channelName = name.textContent;
    }

    if (channelId) {
        return { id: channelId, name: channelName || channelId };
    }

    return { error: 'No data about channel' };
};

export default processRowChannels;
