import { cloneDeep } from 'lodash';
import tdWithDateFieldsRequestHelper from './tdWithDateFieldsRequestHelper';

/**
 * Функция для обработки ошибочных интервалов датчика TD
 * @param {object} sensor объект сенсора
 * @param {array} failedIntervals все ошибочные интервалы
 * @param {number} timeout таймаут
 * @returns возвращает обновленный объект сенсора
 */
const processTdFailedIntervals = async (options) => {
    const { sensor, failedIntervals, timeout } = options;
    let sensorCopy = cloneDeep(sensor);

    for (let interval of failedIntervals) {
        const data = await tdWithDateFieldsRequestHelper({
            dateFromInMillis: interval.dateFrom,
            dateToInMillis: interval.dateTo,
            sensor: sensorCopy,
            timeout,
        });

        if (!data.error) {
            sensorCopy = { ...data.sensor };
            sensorCopy.orderCount -= 1;
        } else {
            sensorCopy = { ...data.sensor };
            sensorCopy.errors.push(data.error);
        }
    }

    return { sensor: { ...sensorCopy } };
};

export default processTdFailedIntervals;
