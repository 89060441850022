/**
 * Функция для конвертации ip в число
 * @param {string} ip
 * @returns number
 */
const convertIpToNumber = (ip) => {
    return Number(
        ip
            .split('.')
            .map((num) => `000${num}`.slice(-3))
            .join(''),
    );
};

export default convertIpToNumber;
