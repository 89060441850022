import { getCompanyName } from '../../constants/constants';

export const brickstreamProgressMessages = {
    firstStepEnabled: 'Sensor is online',
    firstStepError: 'Sensor is offline',
    secondStepEnabled:
        'The counting lines on the sensor coincide with the counting lines in the map service',
    secondStepError:
        'The names of the counting lines on the sensor do not match the names from the map service',
    secondStepNotCountLines: 'There are no counting lines on this sensor',
    thirdStepEnabled: 'The url for uploading data from the sensor is relevant',
    thirdStepErrorWithNoUrl: `There is no ${getCompanyName()} url in the sensor configuration`,
    thirdStepErrorWithNoMatchUrls: `The actual ${getCompanyName()} url does not match the url in the sensor configuration`,
    thirdStepNoDataUplaodInterval:
        'Can not process sensor data upload interval',
    thirdStepNoDataUplaodGranularity:
        'Can not process sensor data upload granularity',
    thirdStepInvalidDataUploadInterval: 'Invalid data upload interval',
    thirdStepInvalidDataUploadGranularity: 'Invalid data upload granularity',
};
