import { cloneDeep } from 'lodash';

const refreshSelectedSensors = (sensors, selectedSensors) => {
    const copy = cloneDeep(sensors);
    const selectedCopy = cloneDeep(selectedSensors);

    selectedSensors.forEach((sensor) => {
        copy[sensor.id] = {
            ...copy[sensor.id],
            screenInfoFetching: true,
            screensInfo: [],
            screensFetchError: '',
        };

        const index = selectedCopy.findIndex((element) => element.id === sensor.id);
        const item = selectedCopy.find((element) => element.id === sensor.id);
        selectedCopy.splice(index, 1);
        selectedCopy.push({
            ...item,
            screenInfoFetching: true,
            screensInfo: [],
            screensFetchError: '',
        });
    });

    return { copy, selectedCopy };
};

export default refreshSelectedSensors;
