/**
 * Функция для получения информации о всех исключениях
 * @param {string} config конфигурация сенсора
 * @returns массив со всеми исключениями
 */
const getExceptionsInfo = ({ config }) => {
    const finalArray = [];
    const oParser = new DOMParser();
    const xmlResponse = oParser.parseFromString(config, 'text/xml');
    const exceptionsArray = xmlResponse.querySelectorAll('exclusion-masking > movingmask');

    for (let exception of exceptionsArray) {
        const attributes = exception.attributes;
        const marker = attributes.name.textContent;
        const exceptionCoordinates = [];
        const pointsArray = exception.querySelectorAll('point');

        for (let point of pointsArray) {
            exceptionCoordinates.push([+point.attributes.x.textContent, +point.attributes.y.textContent]);
        }
        finalArray.push({ marker, coordinates: exceptionCoordinates });
    }

    return finalArray;
};

export default getExceptionsInfo;
