import { ScrollablePane } from '@fluentui/react';
import styled from 'styled-components/macro';
export const ScreensRow = styled.div`
    height: 100%;
    width: 100%;
`;

export const ScreensWrapper = styled.div`
    position: relative;
    height: ${(props) => props.size + 'px'};
    width: 100%;
`;

export const ScrollablePaneForScreens = styled(ScrollablePane)`
    overflow-y: hidden;
    div {
        display: flex;
        align-self: center;
    }
`;

export const ScreenWrapper = styled.div`
    margin-right: 10px;
`;
