import { cloneDeep, isNumber } from 'lodash';
import { sensorTypes } from '../../../constants/sensorTypes';
import portionDataGet from '../../../utils/portionDataGet';
import checkErrorInXovisResponse from '../../../utils/xovisHelpers/checkErrorInXovisResponse';
import generateVivotekDataForUploadRequest from './vivotek/generateVivotekDataForUploadRequest';
import portionDataPost from '../../../utils/portionDataPost';
import generateTdDataForUploadRequest from './td/generateTdDataForUploadRequest';
import generateXovisDataForUploadRequestV5 from './xovis/v5Methods/generateXovisDataForUploadRequestV5';
import getResendDataStatus from './xovis/v5Methods/getResendDataStatus';
import { getCompanyName } from '../../../constants/constants';

/**
 * Функция для обработки одной итерации из переданного периуда
 * @param {array} selectedSensorsCopy массив выбранных сенсоров
 * @param {object} sensorsForManagementById объект сенсоров, гдю ключ это id сенсора из csv (генерируется на фронте)
 * @param {number} dateToInMillis date to в милисекундах
 * @param {number} dateFromInMillis date from в милисекундах
 * @param {number} timeout таймаут
 * @returns измененный объект сенсоров
 */
const portionUploadingOrder = async (options) => {
    const { selectedSensorsCopy, sensorsForManagementById, dateToInMillis, dateFromInMillis, timeout } = options;
    const copy = cloneDeep(sensorsForManagementById);
    const dataForGet = [];
    const dataForGetVivotekUploadData = [];
    const dataForPost = [];
    const PORTION_SIZE = 10;

    selectedSensorsCopy.forEach((element) => {
        const sensor = cloneDeep(sensorsForManagementById[element.id]);
        sensor.dataFetching = false;

        if (sensor.layers.passWays?.length || sensor.multiSensorLayers?.passWays?.length) {
            switch (sensor.type) {
                case sensorTypes.XOVIS:
                    const version = Number(sensor.xovisData?.version?.split('.')[0]);

                    if (isFinite(version)) {
                        if (version < 5) {
                            const agentId = Number(sensor.xovisData?.data?.config?.companyDatapushAgent?.id);
                            if (isFinite(agentId)) {
                                const data = {
                                    url: `http://${sensor.ip}:${sensor.port}/api/data-push/manual?passwd=${sensor.pass}&id=${agentId}&from=${dateFromInMillis}&to=${dateToInMillis}`,
                                    initialData: sensor,
                                    user: sensor.user || 'admin',
                                    pass: sensor.pass,
                                    extraArgs: {
                                        dateInterval: {
                                            dateFrom: dateFromInMillis,
                                            dateTo: dateToInMillis,
                                        },
                                    },
                                };

                                dataForGet.push(data);
                            } else {
                                copy[sensor.id] = {
                                    ...sensor,
                                    errors: [sensor.errors, `No ${getCompanyName()} url`],
                                };
                            }
                        } else if (version >= 5) {
                            const data = generateXovisDataForUploadRequestV5({
                                sensor,
                                dateFromInMillis,
                                dateToInMillis,
                            });

                            dataForPost.push(data);
                        }
                    } else {
                        copy[sensor.id] = {
                            ...sensor,
                            errors: [sensor.errors, 'Sensor is offline'],
                        };
                    }

                    break;

                case sensorTypes.VIVOTEK:
                    if (sensor.vivotekDataUploadUrl) {
                        const data = generateVivotekDataForUploadRequest({
                            dateFromInMillis,
                            dateToInMillis,
                            sensor,
                        });
                        dataForGetVivotekUploadData.push(data);
                    } else {
                        copy[sensor.id] = {
                            ...sensor,
                            errors: [...sensor.errors, `No ${getCompanyName()} url`],
                        };
                    }
                    break;

                case sensorTypes.TD:
                    if (isNumber(sensor.tdData?.serviceConfig?.companyServerId)) {
                        const tdDataForPost = generateTdDataForUploadRequest({
                            dateFromInMillis,
                            dateToInMillis,
                            sensor,
                        });
                        dataForPost.push(tdDataForPost);
                    } else {
                        copy[sensor.id] = {
                            ...sensor,
                            errors: [...sensor.errors, `No ${getCompanyName} url`],
                        };
                    }
                    break;

                case sensorTypes.HIKVISION:
                    copy[sensor.id] = { ...sensor };
                    break;
                case sensorTypes.MEGACOUNT:
                    copy[sensor.id] = { ...sensor };
                    break;

                default:
                    copy[sensor.id] = {
                        ...sensor,
                        errors: [...sensor.errors, 'Invalid type'],
                    };
                    break;
            }
        } else {
            copy[sensor.id] = {
                ...sensor,
                errors: [...sensor.errors, 'No count lines'],
            };
        }
    });

    const getResult = await portionDataGet({
        portionSize: PORTION_SIZE,
        dataArray: dataForGet,
        timeout,
        isAuth: true,
    });

    const vivotekDataUploadResponse = await portionDataGet({
        portionSize: PORTION_SIZE,
        dataArray: dataForGetVivotekUploadData,
        timeout,
        isAuth: true,
    });

    vivotekDataUploadResponse.forEach((element) => {
        const sensor = cloneDeep(element.initialData);
        if (!element.error) {
            const data = {
                url: sensor.vivotekDataUploadUrl,
                dataForPost: element.response,
                initialData: sensor,
                user: sensor.user || 'admin',
                pass: sensor.pass,
                extraArgs: element.extraArgs,
                headers: { 'Content-Type': 'text/xml' },
            };
            dataForPost.push(data);
        } else {
            sensor.orderCount += 1;
            sensor.failedIntervals.push(element.extraArgs.dateInterval);
            copy[sensor.id] = {
                ...sensor,
                errors: [...sensor.errors, element.error],
            };
        }
    });

    const postResult = await portionDataPost({
        portionSize: PORTION_SIZE,
        dataArray: dataForPost,
        timeout,
        isAuth: true,
    });

    const finalResult = getResult.concat(postResult);

    for (let element of finalResult) {
        const sensor = cloneDeep(element.initialData);
        sensor.orderCount += 1;
        if (!element.error) {
            switch (sensor.type) {
                case sensorTypes.XOVIS:
                    const version = Number(sensor.xovisData?.version?.split('.')[0]);

                    let error = '';

                    if (isFinite(version)) {
                        if (version < 5) {
                            error = checkErrorInXovisResponse(element.response);
                            if (!error) {
                                sensor.successOrderCount += 1;
                            } else {
                                sensor.failedIntervals.push(element.extraArgs.dateInterval);
                            }
                        } else if (version >= 5) {
                            const status = await getResendDataStatus({
                                sensor,
                            });

                            if (status.status) {
                                sensor.successOrderCount += 1;
                            } else {
                                error = status.error;
                                sensor.failedIntervals.push(element.extraArgs.dateInterval);
                            }
                        }
                    }

                    copy[sensor.id] = {
                        ...sensor,
                        errors: [...sensor.errors, error],
                    };
                    break;

                case sensorTypes.VIVOTEK:
                    sensor.successOrderCount += 1;
                    copy[sensor.id] = { ...sensor };
                    break;

                case sensorTypes.TD:
                    let tdError = '';
                    if (element.response.status === 0) {
                        sensor.successOrderCount += 1;
                    } else {
                        tdError = 'Something went wrong';
                        sensor.failedIntervals.push(element.extraArgs.dateInterval);
                    }
                    copy[sensor.id] = {
                        ...sensor,
                        errors: [...sensor.errors, tdError],
                    };

                    break;

                default:
                    copy[sensor.id] = {
                        ...sensor,
                        errors: [...sensor.errors, 'Invalid type'],
                    };
                    break;
            }
        } else {
            sensor.failedIntervals.push(element.extraArgs.dateInterval);
            copy[sensor.id] = {
                ...sensor,
                errors: [...sensor.errors, element.error],
            };
        }
    }

    return copy;
};

export default portionUploadingOrder;
