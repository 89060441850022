import axios from 'axios';
import { tdGetRequest } from './td.api';
import getTdStayZoneBySlug from './getTdStayZoneBySlug';

/**
 * Функция для получения информации о зонах пребывания
 * @param {string} ip IP датчика
 * @param {number} port Порт датчика
 * @param {number} timeout Таймаут запроса
 * @returns Promise с данными о зонах входы и выхода
 */
const getTdStayStatistics = async (args) => {
    const { ip, port, timeout } = args;

    const stayStatistics = await tdGetRequest({
        url: `http://${ip}:${port}/passenger_stay_statistics`,
        timeout,
    });

    if (!stayStatistics.error) {
        const oParser = new DOMParser();
        const stayStatisticsHTML = oParser.parseFromString(
            stayStatistics,
            'text/html',
        );
        const stayAreas = stayStatisticsHTML.querySelectorAll(
            '#zone_list_body > tr',
        );

        if (stayAreas?.length) {
            const promises = [];
            stayAreas.forEach((area) => {
                const isActive = area.querySelector('.light-green');
                if (isActive) {
                    /** Поиск slug для зоны, чтобы получить координаты и тд */
                    const zoneSlug = area
                        .querySelector('a')
                        ?.href?.split('=')?.[1];

                    if (zoneSlug) {
                        promises.push(
                            getTdStayZoneBySlug({
                                timeout,
                                slug: zoneSlug,
                                port,
                                ip,
                            }),
                        );
                    }
                }
            });

            const data = await axios.all(promises).then((responses) => {
                const zones = [];
                responses.forEach((response) => {
                    if (!response.error) {
                        zones.push(...response);
                    }
                });
                return zones;
            });
            return data;
        }
        return {
            error: 'Unable to get sensor stay statistics data',
        };
    }
    return {
        error: 'Unable to get sensor stay statistics data',
    };
};

export default getTdStayStatistics;
