import axios from 'axios';

/**
 * Вспомогательная функция для получения серийного номера от сенсора, при пакетной загрузке данных
 * @param {object} singleDataPackage элемент из csv файла или csv строки
 */
const getVivotekSerialNumberForTable = async (options) => {
    const { singleDataPackage } = options;

    let serialNumber = '';
    let notEqualSerialNumber = false;

    return axios
        .get(`http://${singleDataPackage.ip}:${singleDataPackage.port}/VCA/Camera/Profile`, {
            timeout: 5000,
            auth: {
                username: singleDataPackage.user,
                password: singleDataPackage.pass,
            },
        })
        .then((res) => res.data)
        .then((vivotekInfo) => {
            serialNumber = vivotekInfo.Data.Mac;
            if (singleDataPackage.serial_number && serialNumber.toLowerCase() !== singleDataPackage.serial_number.toLowerCase()) notEqualSerialNumber = true;
            return {
                id: singleDataPackage.id,
                serialNumber,
                notEqualSerialNumber,
            };
        })
        .catch((error) => ({ id: singleDataPackage.id, error }));
};

export default getVivotekSerialNumberForTable;
