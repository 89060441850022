import {
    DetailsList,
    TextField,
    Selection,
    Spinner,
    SpinnerSize,
    TooltipHost,
    IconButton,
    Sticky,
    StickyPositionType,
} from '@fluentui/react';
import { cloneDeep } from 'lodash';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SensorsManagementContext } from '../sensorsManagement';
import {
    ManagementListWrapper,
    filterStyles,
    FilterWrapper,
    statusSpinnerStyles,
    StatusWrapper,
    StatusIcon,
    CopyTextArea,
} from './senorsManagementList.styles';
import useColumnsForList from '../tools/useColumnsForList';
import copySelectedSensors from '../tools/copySelectedSensors';
import { useHotkeys } from 'react-hotkeys-hook';
import OnlineStatus from './onlineStatus';
import ListContainer from '../../../commonComponents/listContainer';
import ErrorsIndication from './errorsIndication';

/**
 * Компонент для отображения списка переданных датчиков из csv
 */
const SenorsManagementList = () => {
    const {
        storeSelectedSensorsTrans,
        uploadingOrderFetching,
        columnsForList,
        storeColumnForSortTrans,
        selectedSensors,
        sensorsForManagementById,
    } = useContext(SensorsManagementContext);
    const [columnForSort, setColumnForSort] = useState(null);
    const [filteredItems, setFilteredItems] = useState([]);
    const [columns, setColumns] = useState([]);
    const [filter, setFilter] = useState('');
    const [copyCount, setCopyCount] = useState(0);
    const { t } = useTranslation();
    const copyRef = useRef();
    useColumnsForList();
    useHotkeys('shift+c', () => setCopyCount((prev) => prev + 1));

    useEffect(() => {
        if (selectedSensors.length > 0) {
            const copyValue = copySelectedSensors(selectedSensors);
            copyRef.current.value = copyValue;
            copyRef.current.focus();
            copyRef.current.select();
            try {
                document.execCommand('copy');
            } catch (error) {
                console.log(error);
            }
        }
        // eslint-disable-next-line
    }, [copyCount]);

    useEffect(() => {
        if (filter) {
            setFilteredItems(
                Object.values(sensorsForManagementById).filter(
                    (element) =>
                        element.ip.includes(filter) ||
                        String(element.port).includes(filter) ||
                        element.type.includes(filter),
                ),
            );
        } else {
            setFilteredItems(cloneDeep(Object.values(sensorsForManagementById)));
        }
    }, [sensorsForManagementById, uploadingOrderFetching, columnsForList, filter]);

    useEffect(() => {
        const columns = columnsForList.map((element) => {
            if (element['onColumnClick']) {
                return {
                    ...element,
                    onColumnClick: columnClickHandler,
                };
            } else {
                return { ...element };
            }
        });

        setColumns(columns);
    }, [columnsForList]);

    useEffect(() => {
        if (columnForSort) {
            const copy = columnForSort;
            copy['onColumnClick'] = true;
            storeColumnForSortTrans(copy);
        }
        // eslint-disable-next-line
    }, [columnForSort]);

    const selection = new Selection({ onSelectionChanged: () => storeSelectedSensorsTrans(selection.getSelection()) });
    const columnClickHandler = (_, column) => setColumnForSort(column);

    const filterHandler = useCallback((_, value) => {
        setFilter(value || '');
        // eslint-disable-next-line
    }, []);

    const copyHandler = () => setCopyCount((prev) => prev + 1);

    const renderItemColumn = (item, _, column) => {
        const fieldContent = item[column.fieldName];
        const errors = <ErrorsIndication errors={item.errors} />; //git
        switch (column.key) {
            case 'sensorIp':
                return <div>{item.ip}</div>;
            case 'managementSType':
                return <div>{item.type}</div>;
            case 'dataUploadStatuStatus':
                if (item.dataFetching) {
                    return <Spinner styles={statusSpinnerStyles} size={SpinnerSize.large} />;
                }

                if (item.orderCount >= 1 && item.orderCount !== item.successOrderCount) {
                    return (
                        <div>
                            <StatusWrapper>
                                <StatusIcon iconName="WarningSolid" color="#ffb900" />
                                <div>Not all requests are successful</div>
                            </StatusWrapper>
                            {errors}
                        </div>
                    );
                }

                if (item.orderCount >= 1 && item.orderCount === item.successOrderCount) {
                    return (
                        <StatusWrapper>
                            <StatusIcon iconName="CheckboxCompositeReversed" color="green" />
                            <div>Success</div>
                        </StatusWrapper>
                    );
                }
                if (item.errors.length > 0) {
                    return <>{errors}</>;
                }

                break;

            case 'screenStatus': {
                if (item.dataFetching) {
                    return <Spinner styles={statusSpinnerStyles} size={SpinnerSize.large} />;
                }

                if (item.errors.length > 0) {
                    return <>{errors}</>;
                }

                if (item.screen || item.successScreenPost)
                    return (
                        <StatusWrapper>
                            <StatusIcon iconName="CheckboxCompositeReversed" color="green" />
                            <div>Success</div>
                        </StatusWrapper>
                    );

                return;
            }
            case 'connectStatus': {
                return <OnlineStatus sensor={item} />;
            }
            case 'percentage':
                if (item.orderCount === 0 || item.dataFetching) return;
                return <div>{`${((item.successOrderCount / item.orderCount) * 100).toFixed(3)} %`}</div>;
            default:
                return <div>{fieldContent}</div>;
        }
    };

    const onRenderDetailsHeader = (headerProps, defaultRender) => {
        if (!defaultRender) return null;
        return (
            <Sticky
                stickyPosition={StickyPositionType.Header}
                isScrollSynced={true}
                stickyBackgroundColor="transparent"
            >
                <div>{defaultRender(headerProps)}</div>
            </Sticky>
        );
    };

    return (
        <ManagementListWrapper>
            <CopyTextArea ref={copyRef} />
            <FilterWrapper>
                <TextField styles={filterStyles} onChange={filterHandler} placeholder={t('Filter')} value={filter} />
                <TooltipHost content="Copy selected (Shifth + C)">
                    <IconButton
                        onClick={copyHandler}
                        iconProps={{ iconName: 'Copy' }}
                        disabled={selectedSensors.length === 0}
                    />
                </TooltipHost>
            </FilterWrapper>
            <ListContainer>
                <DetailsList
                    selection={selection}
                    items={filteredItems}
                    columns={columns}
                    onRenderDetailsHeader={onRenderDetailsHeader}
                    onRenderItemColumn={renderItemColumn}
                />
            </ListContainer>
        </ManagementListWrapper>
    );
};

export default SenorsManagementList;
