/**
 * размеры различных элементов
 */
export const sizes = {
    HEADER_HEIGHT: 50,
    FLOOR_SIDE_PANEL: 40,
    IPOINTS_LIST_TOP_MARGIN: 110,
    SENSORS_MANAGEMENT_TOP_ITEMS: 290,
    SENSOR_SCREENS_HEADER: 75,
};
