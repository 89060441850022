const getXovisLayersV5 = (args) => {
    const {
        singlesensorSceneImageMetadata,
        multisensorSceneImageMetadata,
        singlesensorViewImageMetadata,
        singlesensorSceneGeometries,
        multisensorSceneGeometries,
        singlesensorSceneMasks,
        multisensorSceneMasks,
        singlesensorViewMasks,
    } = args;

    const FLOOR_LAYER_TYPE = 'VISIBLE_FLOOR';
    const MASK_LAYER_TYPE = 'TABOO';
    const PASSWAY_LAYER_TYPE = 'LINE';
    const ZONE_LAYER_TYPE = 'ZONE';
    const EXCEPTION_LAYER_TYPE = 'EXCLUSION';

    const layers = {
        passWays: [],
        zones: [],
        floors: [],
        exceptions: [],
        masks: [],
    };

    const multiSensorLayers = {
        passWays: [],
        zones: [],
        floors: [],
        exceptions: [],
        masks: [],
    };

    singlesensorSceneGeometries?.forEach((layer) => {
        if (layer.type === PASSWAY_LAYER_TYPE) {
            const transformedLayer = layer_transform({
                layer,
                coordinatesKey: 'geometry',
                imageMetadata: singlesensorSceneImageMetadata?.image_metadata,
            });

            if (transformedLayer) {
                layers.passWays.push(transformedLayer);
            }
        }

        if (layer.type === ZONE_LAYER_TYPE) {
            const transformedLayer = layer_transform({
                layer,
                coordinatesKey: 'geometry',
                imageMetadata: singlesensorSceneImageMetadata?.image_metadata,
            });
            if (transformedLayer) {
                layers.zones.push(transformedLayer);
            }
        }
    });

    multisensorSceneGeometries?.forEach((layer) => {
        if (layer.type === PASSWAY_LAYER_TYPE) {
            const transformedLayer = layer_transform({
                layer,
                coordinatesKey: 'geometry',
                imageMetadata: multisensorSceneImageMetadata?.image_metadata,
            });

            if (transformedLayer) {
                multiSensorLayers.passWays.push(transformedLayer);
            }
        }

        if (layer.type === ZONE_LAYER_TYPE) {
            const transformedLayer = layer_transform({
                layer,
                coordinatesKey: 'geometry',
                imageMetadata: multisensorSceneImageMetadata?.image_metadata,
            });
            if (transformedLayer) {
                multiSensorLayers.zones.push(transformedLayer);
            }
        }
    });

    singlesensorSceneMasks?.forEach((layer) => {
        if (layer.type === EXCEPTION_LAYER_TYPE) {
            const transformedLayer = layer_transform({
                layer,
                coordinatesKey: 'polygon',
                imageMetadata: singlesensorSceneImageMetadata?.image_metadata,
            });

            if (transformedLayer) {
                layers.exceptions.push(transformedLayer);
            }
        }
    });
    multisensorSceneMasks?.forEach((layer) => {
        if (layer.type === EXCEPTION_LAYER_TYPE) {
            const transformedLayer = layer_transform({
                layer,
                coordinatesKey: 'polygon',
                imageMetadata: multisensorSceneImageMetadata?.image_metadata,
            });

            if (transformedLayer) {
                layers.exceptions.push(transformedLayer);
            }
        }
    });

    singlesensorViewMasks?.forEach((layer) => {
        if (layer.type === FLOOR_LAYER_TYPE) {
            const transformedLayer = layer_transform({
                layer,
                coordinatesKey: 'polygon',
                imageMetadata: singlesensorViewImageMetadata?.image_metadata,
            });

            if (transformedLayer) {
                layers.floors.push(transformedLayer);
            }
        }

        if (layer.type === MASK_LAYER_TYPE) {
            const transformedLayer = layer_transform({
                layer,
                coordinatesKey: 'polygon',
                imageMetadata: singlesensorViewImageMetadata?.image_metadata,
            });
            if (transformedLayer) {
                layers.masks.push(transformedLayer);
            }
        }
    });

    return { layers, multiSensorLayers };
};

export default getXovisLayersV5;

const layer_transform = (args) => {
    const { layer, coordinatesKey, imageMetadata } = args;

    if (imageMetadata && layer[coordinatesKey]) {
        const marker = layer.name || `${layer.type} ${layer.id}`;

        const coordinates = layer[coordinatesKey]?.map((point) => {
            const linear = imageMetadata.ref_to_pixel.linear;
            const translation = imageMetadata.ref_to_pixel.translation;
            return [
                linear[0][0] * point[0] +
                    linear[0][1] * point[1] +
                    translation[0],
                linear[1][0] * point[0] +
                    linear[1][1] * point[1] +
                    translation[1],
            ];
        });

        return {
            marker,
            coordinates,
        };
    }
    return null;
};
