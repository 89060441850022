import styled from 'styled-components/macro';

export const SensorAttributeName = styled.span`
    margin-right: 2px;
    font-weight: bold;
`;

export const TasksTitleWrapper = styled.div`
    display: flex;
`;

export const WrappedItem = styled.div`
    flex: 0 0 50%;
    box-sizing: border-box;
    padding: ${(props: { isProcessed: boolean }) => (props.isProcessed ? '10px 0 10px 10px' : '10px 10px 10px 0')};
`;
export const TitleText = styled.div`
    font-weight: bold;
`;

export const TasksContainer = styled.div`
    flex-grow: 1;
    overflow: auto;
`;
