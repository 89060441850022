import { DateTime } from 'luxon';

/**
 * Функция для форматирования JS даты
 * @returns Отформатированную строку даты
 */
const dateFromater = (date?: Date): string => {
    return !date ? '' : DateTime.fromJSDate(date).toFormat('yyyy-MM-dd');
};

export default dateFromater;
