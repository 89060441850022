import { PrimaryButton } from '@fluentui/react';
import { useContext, useMemo } from 'react';
import SingleUrl from './components/singleUrl';
import { ChangeUrlTitle, ButtonWrapper } from './changeUrlsModal.styles';
import { ModalContext } from '../modalCreator';
import updateHostsObject from '../../../utils/urls/updateHostsObject';

/**
 * модалка для отображения всех урлов
 * @param {fucntion} closeModal функция для закрытия модалки
 */
const ChangeUrlsModal = ({ closeModal }) => {
    const { storeHosts, selectedHosts, setHostsTrans, locales } =
        useContext(ModalContext);

    const urlsArray = useMemo(
        () =>
            Object.keys(storeHosts).map((element, index) => (
                <SingleUrl
                    key={index - Math.random()}
                    urlName={element}
                    urlInfo={storeHosts[element]}
                />
            )),
        [storeHosts],
    );

    const updateUrls = () => {
        if (locales) {
            const newHosts = updateHostsObject(locales, selectedHosts);
            setHostsTrans(newHosts);
            localStorage.setItem('hosts', JSON.stringify(newHosts));
            closeModal();
        }
    };

    return (
        <>
            <ChangeUrlTitle>Change backend urls</ChangeUrlTitle>
            <div>{urlsArray}</div>
            <ButtonWrapper>
                <PrimaryButton text="Ok" onClick={updateUrls} />
            </ButtonWrapper>
        </>
    );
};

export default ChangeUrlsModal;
