/**
 * Функция для парсинга ответа от сенсора
 * @param {string} data xml данные с сенсора
 * @returns объект с информацией о времени на датчике или ошибку
 */
const processRowTime = (data) => {
    const oParser = new DOMParser();
    const xmlResponse = oParser.parseFromString(data, 'text/xml');
    const localTime = xmlResponse.querySelector('localTime').textContent;
    const timeZone = xmlResponse.querySelector('timeZone').textContent;
    if (localTime) {
        return { localTime, timeZone };
    }

    return { error: 'Invalid local time' };
};

export default processRowTime;
