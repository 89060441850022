import styled from 'styled-components/macro';

export const ScreenDataWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
`;

export const DateText = styled.div`
    position: absolute;
    top: 20px;
    left: 20px;
    color: white;
    background-color: rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(0, 0, 0, 0.4);
    font-size: 16px !important;
    padding: 5px;
`;

export const StyledScreen = styled.img`
    height: ${(props) => props.size - 25 + 'px'};
    width: auto;
`;
