import styled from 'styled-components';

export const ErrorsText = styled.span`
    font-size: 12px;
    color: red;
    text-decoration: underline;
    cursor: pointer;
`;

export const Wrapper = styled.div`
    margin-top: 5px;
    display: flex;
    align-items: center;
`;

export const calloutStyles = {
    root: {
        padding: '10px',
    },
};
