import getNewDataPushServer from '../../../../utils/vivotekHelpers/getNewDataPushServer';
import { vivotekProgressMessages } from '../../../../utils/vivotekHelpers/vivotekProgressMessages';
import { updateExtendedSelectedIPoint } from '../../../instalationPoints/installationPointsReducer';
import updateVivotekDataPushServer from './updateVivotekDataPushServer';

/**
 * Функция для работы с состоянием конкретной связки, получением новой выгрузки
 * на датчике и обновлением выгрузки для датчика
 * @param {function} dispatch функция для обновления
 * @param {object} bundle текущая актуальная связь точки установки и датчика
 * @param {number} iPointId ID точки установки
 * @param {string} accessKey ключ доступа для локации / проекта
 * @param {object} sensorsById объект расширенных данных о датчиках
 * @param {*} uploadUrl объект с информацией по выгрузке
 * @returns promise
 */
const processVivotekBatchUrlUpdate = (args) => {
    const { dispatch, bundle, iPointId, accessKey, sensorsById, uploadUrl } = args;

    const promise = new Promise((resolve) => resolve());

    dispatch(
        updateExtendedSelectedIPoint({
            data: { isFetching: true, isError: false, message: '' },
            iPointId,
        }),
    );

    if (!bundle.thirdStepError) {
        dispatch(
            updateExtendedSelectedIPoint({
                data: {
                    isFetching: false,
                    isError: false,
                    message: vivotekProgressMessages.thirdStepEnabled,
                },
                iPointId,
            }),
        );
        return promise;
    }

    if (bundle.thirdStepError && !bundle.firstStepError && bundle?.isFetched) {
        const dataPushServer = getNewDataPushServer({ accessKey, uploadUrl });
        return updateVivotekDataPushServer({
            sensor: sensorsById[bundle.id],
            payloadBody: dataPushServer,
            showNotifications: false,
            currentBundle: bundle,
            dispatch,
            iPointId,
        }).then((data) => {
            if (!data?.error) {
                dispatch(
                    updateExtendedSelectedIPoint({
                        data: {
                            isFetching: false,
                            isError: false,
                            message: 'Success',
                        },
                        iPointId,
                    }),
                );
            } else {
                dispatch(
                    updateExtendedSelectedIPoint({
                        data: {
                            message: JSON.stringify(data?.error || 'Something went wrong'),
                            isFetching: false,
                            isError: true,
                        },
                        iPointId,
                    }),
                );
            }
        });
    }

    dispatch(
        updateExtendedSelectedIPoint({
            data: {
                isFetching: false,
                isError: true,
                message: 'Something went wrong!',
            },
            iPointId,
        }),
    );

    return promise;
};

export default processVivotekBatchUrlUpdate;
