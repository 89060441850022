import { ScrollablePane, ScrollbarVisibility } from '@fluentui/react';
import { ListWrapper } from './listContainer.styles';

/**
 * Контейнерная компонента для списков DetailsList
 */
const ListContainer = ({ children }) => {
    return (
        <ListWrapper>
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>{children}</ScrollablePane>
        </ListWrapper>
    );
};
export default ListContainer;
