import { megacountPostRequest } from '../../../../api/megacount/megacount.api';
import {
    showErrorNotification,
    showSuccessNotification,
} from '../../../../generalReducer';
import { updateAvailableBoundleByIPointId } from '../../../instalationPoints/installationPointsReducer';
import {
    setSensorConfigurationUpdated,
    updateSingleSensor,
} from '../../countingSensorsReducer';
import getMegacountSensorData from './getMegacountSensorData';

/**
 * Вспомогательная функция для обновления конфигурации Megacount сенсора
 * @param {number} iPointId id точки установки
 * @param {object} sensor объект выбранного сенсора
 * @param {object} currentBoundle объект связки
 * @param {function} dispatch диспатч в стейт
 * @param {object} payloadBody новая конфигурация сенсора
 * @param {boolean} showNotifications флаг, отвечающий за отображение оповещений
 */
const updateMegacountUploadServer = async (args) => {
    const {
        iPointId,
        sensor,
        currentBoundle,
        dispatch,
        payloadBody,
        showNotifications = true,
    } = args;
    const errorMessage = 'Something went wrong while update upload server';

    const data = await megacountPostRequest({
        url: `http://${sensor.ip}:${sensor.port}/upload`,
        payload: payloadBody,
        username: sensor.username,
        password: sensor.password,
    });

    if (!data.error && data?.upload?.post?.status === 'ok') {
        await new Promise((resolve) => setTimeout(resolve, 1500));
        const megacountData = await getMegacountSensorData(sensor);

        dispatch(
            updateAvailableBoundleByIPointId({
                iPointId,
                value: {
                    ...currentBoundle,
                    updateConfigurationFetching: false,
                },
            }),
        );

        if (!megacountData.firstStepError) {
            dispatch(
                updateSingleSensor({
                    id: sensor.id,
                    sensor: { ...sensor, ...megacountData },
                    increaseFetchedSensors: false,
                }),
            );
        } else {
            dispatch(
                updateSingleSensor({
                    id: sensor.id,
                    sensor: {
                        ...sensor,
                        ...megacountData,
                        thirdStepError: 'GET request error',
                    },
                    increaseFetchedSensors: false,
                }),
            );
        }
        showNotifications &&
            dispatch(
                showSuccessNotification({
                    show: true,
                    message: 'Configuration updated!',
                }),
            );
        dispatch(setSensorConfigurationUpdated(true));
    } else {
        showNotifications &&
            dispatch(
                showErrorNotification({
                    show: true,
                    message: errorMessage,
                }),
            );
        return {
            error: errorMessage,
        };
    }
};

export default updateMegacountUploadServer;
