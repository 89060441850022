import { MessageBarType, ChoiceGroup, DefaultButton } from '@fluentui/react';
import { useMemo } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import {
    SerialNumber,
    SerialNumberWarningTitle,
    StyledMessageBar,
    ButtonWrapper,
} from './serialNumberWarningModal.styles';
import { useSelector, useDispatch } from 'react-redux';
import { cloneDeep } from 'lodash';
import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { finalSensorCreateThunk } from '../../countingSensors/countingSensorsReducer';

/**
 * Модальное окно для отображения предупреждения о серийном номере. Либо датчик ответил и серийники не совпадат или датчик не отвечает
 */
const SerialNumberWarningModal = memo(() => {
    const {
        warningInformation: { sensorSerialNumber, inputSerialNumber, message, isSensorOnline, postPayload, type },
    } = useSelector((state) => state.serialNumberWarningModalReducer);
    const [sensorForPost, setSensorForPost] = useState(null);
    const [selectedKey, setSelectedKey] = useState({ key: 'B', text: sensorSerialNumber });
    const dispatch = useDispatch();
    const nav = useNavigate();

    useEffect(() => {
        if (postPayload) {
            setSensorForPost(postPayload);
        }
    }, [postPayload]);

    useEffect(() => {
        if (sensorSerialNumber) setSelectedKey({ key: 'B', text: sensorSerialNumber });
    }, [sensorSerialNumber]);

    useEffect(() => {
        if (selectedKey.text) {
            setSensorForPost((prevState) => {
                const newState = cloneDeep(prevState);
                newState['serial_number'] = selectedKey.text;
                return newState;
            });
        }
    }, [selectedKey]);

    const options = useMemo(() => {
        return [
            { key: 'A', text: inputSerialNumber },
            { key: 'B', text: sensorSerialNumber },
        ];
    }, [inputSerialNumber, sensorSerialNumber]);

    const choiceHandler = (_, option) => setSelectedKey(option);

    const createHandler = () => {
        if (sensorForPost) {
            dispatch(finalSensorCreateThunk(sensorForPost, type, nav));
        }
    };

    return (
        <>
            <SerialNumberWarningTitle>Serial number warning</SerialNumberWarningTitle>
            <StyledMessageBar messageBarType={MessageBarType.warning}>{message}</StyledMessageBar>
            {isSensorOnline ? (
                <>
                    <SerialNumber>
                        <span>Serial number from input: </span>
                        {inputSerialNumber}
                    </SerialNumber>
                    <SerialNumber>
                        <span>Serial number from sensor confoguration: </span>
                        {sensorSerialNumber}
                    </SerialNumber>

                    <ChoiceGroup
                        selectedKey={selectedKey.key}
                        options={options}
                        disabled={true}
                        onChange={choiceHandler}
                        label="Choose which serial number you want to create the sensor:"
                    />
                </>
            ) : (
                <>
                    <SerialNumber>
                        <span>Serial number from input: </span>
                        {inputSerialNumber}
                    </SerialNumber>
                    <div>Create a sensor with this serial number?</div>
                </>
            )}

            <ButtonWrapper>
                <DefaultButton onClick={createHandler}>Create sensor</DefaultButton>
            </ButtonWrapper>
        </>
    );
});
export default SerialNumberWarningModal;
