import { Icon } from '@fluentui/react';
import styled from 'styled-components/macro';

export const CustomTextArea = styled.textarea`
    position: fixed;
    top: 0;
    left: 0;
`;

export const stylesForButton = {
    root: {
        width: '105px',
    },
};

export const stylesForToolTip = {
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: '5px',
    },
};

export const StyledIcon = styled(Icon)`
    font-size: 20px;
`;
