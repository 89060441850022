export const projectsListColumns = [
    {
        key: 'name',
        name: 'Name',
        fieldName: 'name',
        isResizable: true,
        minWidth: 150,
        maxWidth: 400,
        isSorted: true,
        isSortedDescending: false,
    },
    {
        key: 'id',
        name: 'Id',
        fieldName: 'id',
        isResizable: true,
        minWidth: 50,
        maxWidth: 75,
    },

    {
        key: 'date_from',
        name: 'Date from',
        fieldName: 'date_from',
        isResizable: true,
        minWidth: 100,
        maxWidth: 150,
    },
    {
        key: 'date_to',
        name: 'Date to',
        fieldName: 'date_to',
        isResizable: true,
        minWidth: 100,
        maxWidth: 150,
    },
];
