import { createSlice } from '@reduxjs/toolkit';
import { getRequest } from '../../api/api';
import { setShowSpinner, showErrorNotification } from '../../generalReducer';
import { resetInstalationPointsReducer } from '../instalationPoints/installationPointsReducer';
import { resetIntersectionsReducer } from '../intersectionsForDisplay/intersectionsForDisplayReducer';
import { resetProjectLocationsReducer } from '../ProjectLocations/ProjectLocations.reducer';

const initialState = {
    projects: [],
    selectedProjectId: null,
};

const projectsReducer = createSlice({
    name: 'projectsReducer',
    initialState,
    reducers: {
        // Запись в стейт всех проектов
        storeProjects: (state, action) => {
            state.projects = action.payload;
        },

        // Запись в стейт id выбранного проекта
        storeSelectedProjectId: (state, action) => {
            state.selectedProjectId = action.payload;
        },

        // Обнуление редьюсера
        resetProjectsReducer: () => initialState,
    },
});

export const { storeProjects, storeSelectedProjectId, resetProjectsReducer, test } = projectsReducer.actions;

/**
 * Thunk. Функция для получения всех начальных данных, необходимых для работы компонента
 */
export const getInitialData = () => async (dispatch, getState) => {
    const { token, storeUrls } = getState().generalReducer;
    if (!token || !storeUrls) return;
    dispatch(resetProjectsReducer());
    dispatch(resetIntersectionsReducer());
    dispatch(resetInstalationPointsReducer());
    dispatch(resetProjectLocationsReducer());
    dispatch(setShowSpinner(true));
    await dispatch(getProjects());
    dispatch(setShowSpinner(false));
};

/**
 * Thunk. Делает запрос на бэк, получает данные и записывает их в стейт
 */
export const getProjects = () => async (dispatch, getState) => {
    const {
        token,
        storeUrls: { PROJECT_URL },
    } = getState().generalReducer;

    const data = await getRequest(PROJECT_URL.url, token);

    if (!data.error) {
        dispatch(storeProjects(data));
    } else {
        dispatch(
            showErrorNotification({
                show: true,
                message: `Get projects error: ${JSON.stringify(data?.error?.response?.data || 'Some error')}`,
            })
        );
    }
};

export default projectsReducer.reducer;
