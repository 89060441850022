/**
 * Функция для равномерного разделения числа. (15 = [4, 4, 4, 3])
 * @param {number} number Число которое нужно равномерно разбить
 * @param {number} divider количество частей, на которое нужно назбить число
 * @returns массив чисел, которые в сумме дают изначальное число
 */
const evenNumberDivision = (number, divider) => {
    const result = [];
    let remainder = number % divider;
    const initialValue = Math.floor(number / divider);

    for (let i = 0; i < divider; i++) {
        if (remainder > 0) {
            result.push(initialValue + 1);
            remainder -= 1;
        } else {
            result.push(initialValue);
        }
    }

    return result;
};

export default evenNumberDivision;
