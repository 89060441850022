import { getSensorUploadUrls } from '../../../constants/sensorUploadUrls';
import { CompanyInfo } from '../company-info.base';

export class DefaultCompanyInfo extends CompanyInfo {
    #COMPANY_NAMES_MAP = {
        focustech: 'Focus Technologies',
        focus: 'Focus Technologies',
        mallsense: 'Mallsense',
    };

    #COMPANY_UPLOAD_URL_PARTS_MAP = {
        focustech: 'focustech',
        focus: 'focustech',
        mallsense: 'mallsense',
    };

    /**
     *
     * @param {{sensors: {[sensorType: string]: {protocol: string; host: string; port: string | number}}} uploadUrls
     */
    constructor(uploadUrls = getSensorUploadUrls()) {
        super();
        this.domain = this.getDomain();
        this.uploadUrls = uploadUrls;
    }

    /**
     * Метод для получения названия компании
     * @returns {string}
     */
    getCompanyName() {
        return this.#COMPANY_NAMES_MAP[this.domain] ?? this.#COMPANY_NAMES_MAP.focustech;
    }

    /**
     * Метод для получение части URL выгрузки с датчика
     * @returns {string}
     */
    getCompanyUploadUrlPart() {
        return this.#COMPANY_UPLOAD_URL_PARTS_MAP[this.domain] ?? this.#COMPANY_UPLOAD_URL_PARTS_MAP.focustech;
    }

    /**
     * Метод для получения текущего домена
     */
    getDomain() {
        let currentDomain = window.location.host.split('.').slice(1).join('.');

        if (window.location.host === 'localhost:3000') {
            currentDomain = 'focustech.xyz';
        }
        if (window.location.host === 'localhost:3333') {
            currentDomain = 'mallsense.ae';
        }

        return currentDomain;
    }

    getSensorUploadUrls() {
        return this.uploadUrls;
    }
}
