import { PrimaryButton, Spinner, SpinnerSize, MessageBar, MessageBarType } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import dateFormater from '../utils/dateHandlers/dateFormater';
import { SpinnerWrapper, StyledDatePicker, WrappedItem, Wrapper } from './reexportTaskManagement.styles';

/**
 * Компонент для отображения кнопок управления реекспортом
 */
const ReexportTaskManagement = ({
    token,
    reexportFetching,
    reexportTasksError,
    reexportTasksDateFrom,
    reexportTasksDateTo,
    reciveReexportTasksThunkTrans,
    storeReexportTasksDateToTrans,
    storeReexportTasksDateFromTrans,
}) => {
    const { t } = useTranslation();

    const reciveHandler = () => reciveReexportTasksThunkTrans();

    const datePickersHandler = (value, type) => {
        const date = dateFormater(value);
        switch (type) {
            case 'from':
                storeReexportTasksDateFromTrans(date);
                break;
            case 'to':
                storeReexportTasksDateToTrans(date);
                break;

            default:
                break;
        }
    };

    return (
        <Wrapper>
            <WrappedItem>
                <StyledDatePicker
                    disabled={!token || reexportFetching}
                    label={t('Date from')}
                    formatDate={(date) => dateFormater(date)}
                    value={new Date(reexportTasksDateFrom)}
                    onSelectDate={(date) => datePickersHandler(date, 'from')}
                />
            </WrappedItem>
            <WrappedItem>
                <StyledDatePicker
                    disabled={!token || reexportFetching}
                    label={t('Date to')}
                    formatDate={(date) => dateFormater(date)}
                    value={new Date(reexportTasksDateTo)}
                    onSelectDate={(date) => datePickersHandler(date, 'to')}
                />
            </WrappedItem>

            <WrappedItem>
                <PrimaryButton
                    disabled={!token || reexportFetching}
                    text={t('Recive reexport tsaks')}
                    onClick={reciveHandler}
                />
            </WrappedItem>
            {reexportFetching && (
                <WrappedItem>
                    <SpinnerWrapper>
                        <Spinner size={SpinnerSize.large} />
                    </SpinnerWrapper>
                </WrappedItem>
            )}

            {reexportTasksError && (
                <WrappedItem>
                    <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
                        {reexportTasksError}
                    </MessageBar>
                </WrappedItem>
            )}
        </Wrapper>
    );
};

export default ReexportTaskManagement;
