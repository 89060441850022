import axios from 'axios';
import getMegacountNetworkSettings from './getMegacountNetworkSettings';
import getMegacountUploadServers from './getMegacountUploadServers';
import getMegacountDetection from './getMegacountDetection';
import getMegacountAccountSettings from './getMegacountAccountSettings';
import getMegacountCalibration from './getMegacountCalibration';

/**
 * Promise. Функция для получения информация о датчике MEGACOUNT
 * @param {object} sensor объект сенсора
 * @param {number} timeout таймаут запроса
 * @returns возвращает обновленный объект сенсора с дополнительными полями
 */
const getMegacountSensorData = (sensor, timeout) => {
    const networkSettings = getMegacountNetworkSettings({
        username: sensor.user,
        password: sensor.pass,
        port: sensor.port,
        ip: sensor.ip,
        timeout,
    });

    const uploadServers = getMegacountUploadServers({
        username: sensor.user,
        password: sensor.pass,
        port: sensor.port,
        ip: sensor.ip,
        timeout,
    });

    const detection = getMegacountDetection({
        ip: sensor.ip,
        port: sensor.port,
        username: sensor.user,
        password: sensor.pass,
        timeout,
    });

    const calibration = getMegacountCalibration({
        ip: sensor.ip,
        port: sensor.port,
        username: sensor.user,
        password: sensor.pass,
        timeout,
    });

    const accountSettings = getMegacountAccountSettings({
        ip: sensor.ip,
        port: sensor.port,
        username: sensor.user,
        password: sensor.pass,
        timeout,
    });

    return axios
        .all([
            networkSettings,
            uploadServers,
            detection,
            accountSettings,
            calibration,
        ])
        .then(
            axios.spread(
                (
                    networkSettings,
                    uploadServers,
                    detection,
                    accountSettings,
                    calibration,
                ) => {
                    let error = '';
                    const errors = [];

                    let megacountData = {
                        networkSettings: {},
                        uploadServers: [],
                        companyUploadServer: null,
                        accountSettings: {},
                        calibrationData: {},
                        layers: {
                            exceptions: [],
                            floors: [],
                            masks: [],
                            passWays: [],
                            zones: [],
                        },
                    };

                    if (!networkSettings.error) {
                        megacountData = { ...megacountData, networkSettings };
                    } else {
                        error = networkSettings.error;
                        errors.push(error);
                    }

                    if (!uploadServers.error) {
                        megacountData = { ...megacountData, ...uploadServers };
                    } else {
                        error = uploadServers.error;
                        errors.push(error);
                    }

                    if (!detection.error) {
                        megacountData = {
                            ...megacountData,
                            layers: {
                                ...megacountData.layers,
                                ...detection.layers,
                            },
                        };
                    } else {
                        error = detection.error;
                        errors.push(error);
                    }

                    if (!accountSettings.error) {
                        megacountData = {
                            ...megacountData,
                            ...accountSettings,
                        };
                    } else {
                        error = accountSettings.error;
                        errors.push(error);
                    }
                    if (!calibration.error) {
                        megacountData = {
                            ...megacountData,
                            calibrationData: calibration.calibrationData,
                            layers: {
                                ...megacountData.layers,
                                floors: calibration.floors,
                            },
                        };
                    } else {
                        error = calibration.error;
                        errors.push(error);
                    }

                    return {
                        sensor: { ...sensor, errors },
                        megacountData,
                        error,
                    };
                },
            ),
        )
        .catch((_) => ({ error: 'Sensor is offline', sensor }));
};

export default getMegacountSensorData;
