import { isNumber } from 'lodash';
import { getCompanyName } from '../../constants/constants';
/**
 * Функция для создания новой конфигурации сенсора
 * @param {object} sensor Объект сенсора
 * @param {string} accessKey Ключ доступа
 * @param {object | undefined} uploadUrl Объект с данными по выгрузке
 * @returns строку с новой конфигурацией сенсора
 */
const getNewXovisConfiguration = (options) => {
    const { sensor, accessKey, uploadUrl } = options;
    const oParser = new DOMParser();
    const oSerializer = new XMLSerializer();

    const version = Number(sensor.xovisData?.version.split('.')[0]);

    const CORRECT_URL = `${uploadUrl?.protocol}://${uploadUrl?.host}${
        uploadUrl?.port ? `:${uploadUrl.port}` : ''
    }/fpc/v1/${sensor.sensor_type}/${accessKey}`;

    if (version < 5) {
        /**
         * Кейс когда выгрузка уже есть
         */
        if (sensor.xovisData?.data?.config?.companyDatapushAgent && sensor.xovisData?.data?.config?.rawConfig) {
            const newConfig = sensor.xovisData.data.config.rawConfig.replace(
                `<url>${sensor.xovisData.data.config.companyDatapushAgent.url}</url>`,
                `<url>${CORRECT_URL}</url>`,
            );

            const arrayBuffer = stringToArrayBuffer(newConfig);
            const uint8Array = new Uint8Array(arrayBuffer);
            const base64EncodedConfig = btoa(String.fromCharCode.apply(null, uint8Array));
            return base64EncodedConfig;
        }

        /**
         * Кейс когда выгрузки нет
         */
        if (
            sensor.xovisData?.data?.config?.rawConfig &&
            isNumber(sensor.xovisData?.data?.config?.emptyDatapushAgentId)
        ) {
            const newConfiguration = oParser.parseFromString(sensor.xovisData.data.config.rawConfig, 'text/xml');
            const datapush = newConfiguration.querySelector('datapush');
            const agent = document.createElement('agent');
            agent.innerHTML = `
            <interval>FIFTEEN_MINUTES</interval>
            <granularity>ONE_MINUTE</granularity>
            <format>xml_v1</format>
            <connector type="${uploadUrl?.protocol ?? 'http'}">
                <url>${CORRECT_URL}</url>
            </connector>
            `;
            agent.setAttribute('id', sensor.xovisData.data.config.emptyDatapushAgentId);
            agent.setAttribute('type', 'countdata');
            datapush?.appendChild(agent);

            const newConfig = oSerializer
                .serializeToString(newConfiguration)
                .replace('xmlns="http://www.w3.org/1999/xhtml"', '');

            const arrayBuffer = stringToArrayBuffer(newConfig);
            const uint8Array = new Uint8Array(arrayBuffer);
            const base64EncodedConfig = btoa(String.fromCharCode.apply(null, uint8Array));

            return base64EncodedConfig;
        }
    } else if (version >= 5) {
        const connectionPayload = {
            config: {
                auth_method: 'NONE',
                chunked_transfer_enabled: false,
                connection_timeout_s: 10,
                custom_header_fields: [
                    {
                        name: 'MAC',
                        value: sensor.xovisData?.serialNumber || '',
                    },
                    {
                        name: 'User-Agent',
                        value: 'xovis/pc-sensor',
                    },
                    {
                        name: 'charset',
                        value: 'UTF-8',
                    },
                ],
                ignore_proxy: false,
                port: uploadUrl?.port ?? 443,
                ssl_enable: true,
                uri: `${CORRECT_URL}`,
            },
            name: `${getCompanyName()} connection`,
            protocol: 'HTTP',
        };

        const agentPayload = {
            name: `${getCompanyName()} Line Count Agent`,
            expanded: true,
            isEditing: true,
            type: 'LOGICS',
            enabled: true,
            config: {
                scheduler: {
                    type: 'INTERVAL',
                    retry: {
                        mode: 'INCREASING_DELAY_EXPONENTIAL',
                        max_number: 12,
                        reset_on_next_push_schedule: true,
                        delay_start_min: 2,
                        delay_start_max: 2,
                        delay_interval_min: 5,
                        delay_interval_max: 5,
                        delay_increase_const: 1,
                        delay_increase_factor: 2,
                    },
                    interval: 'FIFTEEN_MINUTES',
                },
                data: {
                    format: {
                        pretty: false,
                        time: 'RFC3339',
                        type: 'JSON',
                        version: '5.0',
                    },
                    meta_data_package_full: true,
                    meta_data_sensor_full: true,
                    resolution: 'ONE_MINUTE',
                },
                filters: {
                    included_logics: sensor.xovisData?.v5Data?.sceneGeometries?.map((item) => item.id) ?? [],
                },
            },
            connection: null,
        };
        /**
         * Кейс когда есть агент (если есть агент то и соединение тоже есть)
         */
        if (sensor.xovisData?.v5Data?.companyAgent && sensor.xovisData?.v5Data?.companyConnection) {
            return {
                method: 'PUT',
                agentPayload: {
                    ...agentPayload,
                    connection: sensor.xovisData.v5Data.companyConnection.id,
                },
                connectionPayload,
            };
        }

        /**
         * Кейс когда есть соединение но нет агента
         */
        if (sensor.xovisData?.v5Data?.companyConnection) {
            return {
                method: 'POST',
                connectionPayload: null,
                agentPayload: {
                    ...agentPayload,
                    connection: sensor.xovisData.v5Data.companyConnection.id,
                },
            };
        }

        /**
         * Кейс когда ничего нет
         */
        return {
            method: 'POST',
            agentPayload,
            connectionPayload,
        };
    }

    return null;
};
export default getNewXovisConfiguration;

function stringToArrayBuffer(str) {
    const encoder = new TextEncoder();
    return encoder.encode(str).buffer;
}
