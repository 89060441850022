import useImage from 'use-image';
import { Image } from 'react-konva';
import { useEffect } from 'react';

/**
 * Компонент для рисования картинок на конвасе
 * @param {string} imageString  ссылка на изображение (src)
 * @param {number} width  ширина картинки
 * @param {number} height  высота картинки
 * @param {function} setImageLoading  функция, для изменения флага, отвечающего за загрузку изображения
 */
const ImageForKonva = ({ imageString, width, height, setImageLoading }) => {
    const [image, status] = useImage(imageString);

    useEffect(() => {
        if (setImageLoading) {
            setImageLoading(true);
            if (status === 'loaded') {
                setImageLoading(false);
            }
        }
        //eslint-disable-next-line
    }, [status, imageString]);

    return <Image width={width ? width : null} height={height ? height : null} image={image} />;
};

export default ImageForKonva;
