import { IColumn } from '@fluentui/react';

export const projectLocataionsColumns: IColumn[] = [
    {
        key: 'name',
        name: 'Pl name',
        fieldName: 'name',
        isSorted: true,
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        isSortedDescending: false,
    },
    {
        key: 'plId',
        name: 'Pl id',
        fieldName: 'plId',
        isResizable: true,
        minWidth: 100,
        maxWidth: 100,
    },
    {
        key: 'sensorsTasksCount',
        name: 'Sensors tasks count',
        fieldName: 'sensorsTasksCount',
        isResizable: true,
        minWidth: 150,
        maxWidth: 100,
    },
    {
        key: 'timeIntervalTasks',
        name: 'Time interval tasks',
        fieldName: 'timeIntervalTasks',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
    },
    {
        key: 'timeIntervalProcessedTasks',
        name: 'TI processed tasks',
        fieldName: 'timeIntervalProcessedTasks',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
    },
    {
        key: 'timeIntervalSuccessTasks',
        name: 'TI success tasks',
        fieldName: 'timeIntervalSuccessTasks',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
    },
];
