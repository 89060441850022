import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isOpen: false,
    warningInformation: {},
};

const serialNumberWarningModalReducer = createSlice({
    name: 'serialNumberWarningModalReducer',
    initialState,
    reducers: {
        // Изменение флага октрытия модалки
        toggleOpen: (state, action) => {
            state.isOpen = action.payload;
        },

        // Запись в сетйт информации для модалки
        storeWarningInformation: (state, action) => {
            state.warningInformation = action.payload;
        },

        // Обнулление стейта
        reset: () => initialState,
    },
});

export const { reset, storeWarningInformation, toggleOpen } = serialNumberWarningModalReducer.actions;

export default serialNumberWarningModalReducer.reducer;
