import { DateTime } from 'luxon';
import evenNumberDivision from '../evenNumberDivision';
import { intervalTypes } from './constants/intervalTypes';
import { postHikvisionUploadDataPattern } from './hikvisionPayloadPatterns';
import hikvisionTimeIntervalHandler from './hikvisionTimeIntervalHandler';

/**
 * Функция для формирования пакетов для post запросов на лямбду (для сохранения новой выгрузки с датчиков)
 * @param {array} data массив данных для формирования пакетов  выгрузки
 * @returns массив данных для пакетной загрузки
 */
const hikvisionUploadDataHandler = async (data) => {
    const PORTION_SIZE = 10;

    const result = [];

    const subarray = []; //массив в который будет выведен результат.
    for (let i = 0; i < Math.ceil(data.length / PORTION_SIZE); i++) {
        subarray[i] = data.slice(i * PORTION_SIZE, i * PORTION_SIZE + PORTION_SIZE);
    }

    for (let array of subarray) {
        const promises = [];
        array.forEach((element) => {
            const dateFrom = DateTime.fromMillis(element.dateFromInMillis)
                .toFormat('yyyy-MM-dd HH:mm:ss')
                .replace(' ', 'T');
            const dateTo = DateTime.fromMillis(element.dateToInMillis)
                .toFormat('yyyy-MM-dd HH:mm:ss')
                .replace(' ', 'T');
            const promise = hikvisionTimeIntervalHandler({
                sensor: element.sensor,
                dateFrom,
                dateTo,
                timeout: element.timeout,
            });

            promises.push(promise);
        });

        await Promise.all(promises).then((responses) => {
            responses.forEach((response) => {
                const { sensor, dateFrom, dateTo, dataForUpload, error, minutesInInterval } = response;
                let start = DateTime.fromISO(dateFrom);
                const end = DateTime.fromISO(dateTo);

                const dateInterval = {
                    dateFrom: start.toMillis(),
                    dateTo: start.plus({ minutes: 15 }).toMillis(),
                };

                if (!error && minutesInInterval) {
                    while (start < end) {
                        // eslint-disable-next-line
                        const value = dataForUpload.find((element) => {
                            const dateFrom = DateTime.fromISO(element.startTime);
                            const dateTo = DateTime.fromISO(element.endTime);
                            if (start >= dateFrom && start <= dateTo) {
                                return true;
                            }
                            return false;
                        });

                        if (value) {
                            /**
                             * Так как у датчиков hikvision в зависимости от версии
                             * может меняться ответ, на запрос по подсчету людей, поэтому нужно
                             * в словаре intervalTypes записаны известные случаи ответа датчика.
                             * В зависимости от типа ответа датчика (по часам или по 15 минут) осуществляется
                             * дробление интервала (1 час = 4 по 15, 15 мин = 1 по 15).
                             */
                            const zone = DateTime.fromISO(sensor.hikvisionData.localTime, { setZone: true }).toFormat(
                                'ZZ',
                            );
                            const enter = evenNumberDivision(
                                value.enter,
                                intervalTypes[minutesInInterval].dividerValue,
                            );
                            const exit = evenNumberDivision(value.exit, intervalTypes[minutesInInterval].dividerValue);
                            const pass = evenNumberDivision(value.pass, intervalTypes[minutesInInterval].dividerValue);
                            let date = DateTime.fromISO(value.startTime);
                            for (let i = 0; i < intervalTypes[minutesInInterval].dividerValue; i++) {
                                const dataForPost = postHikvisionUploadDataPattern
                                    .replace(
                                        '<deviceName></deviceName>',
                                        `<deviceName>${sensor.hikvisionData.deviceInfo.deviceName}</deviceName>`,
                                    )
                                    .replace(
                                        '<model></model>',
                                        `<model>${sensor.hikvisionData.deviceInfo.model}</model>`,
                                    )
                                    .replace(
                                        '<serialNumber></serialNumber>',
                                        `<serialNumber>${sensor.hikvisionData.deviceInfo.serialNumber}</serialNumber>`,
                                    )
                                    .replace(
                                        '<ipAddress></ipAddress>',
                                        `<ipAddress>${sensor.hikvisionData.ipAddress}</ipAddress>`,
                                    )
                                    .replace(
                                        '<macAddress></macAddress>',
                                        `<macAddress>${sensor.hikvisionData.deviceInfo.macAddress}</macAddress>`,
                                    )
                                    .replace(
                                        '<channelID></channelID>',
                                        `<channelID>${sensor.hikvisionData.channelData.id}</channelID>`,
                                    )
                                    .replace(
                                        '<dateTime></dateTime>',
                                        `<dateTime>${sensor.hikvisionData.localTime}</dateTime>`,
                                    )
                                    .replace(
                                        '<channelName></channelName>',
                                        `<channelName>${sensor.hikvisionData.channelData.name}</channelName>`,
                                    )
                                    .replace(
                                        '<startTime></startTime>',
                                        `<startTime>${date.toFormat("yyyy-MM-dd'T'HH:mm:ss") + zone}</startTime>`,
                                    )
                                    .replace(
                                        '<endTime></endTime>',
                                        `<endTime>${
                                            date.plus({ minutes: 15 }).toFormat("yyyy-MM-dd'T'HH:mm:ss") + zone
                                        }</endTime>`,
                                    )
                                    .replace('<enter></enter>', `<enter>${enter[i]}</enter>`)
                                    .replace('<exit></exit>', `<exit>${exit[i]}</exit>`)
                                    .replace('<pass></pass>', `<pass>${pass[i]}</pass>`);

                                const dataForPortionPost = {
                                    url: sensor.hikvisionData.dataUploadUrl,
                                    initialData: sensor,
                                    dataForPost,
                                    user: sensor.user || 'admin',
                                    pass: sensor.pass,
                                    headers: { 'Content-Type': 'text/xml' },
                                    extraArgs: {
                                        dateInterval: {
                                            dateFrom: date.toMillis(),
                                            dateTo: date.plus({ minutes: 15 }).toMillis(),
                                        },
                                    },
                                };
                                result.push(dataForPortionPost);
                                date = date.plus({ minutes: 15 });
                            }
                        } else {
                            result.push({
                                initialData: sensor,
                                extraArgs: { dateInterval },
                                error: 'Time interval error',
                            });
                        }

                        start = start.plus(intervalTypes[minutesInInterval].timeStep);
                    }
                } else {
                    result.push({
                        initialData: sensor,
                        extraArgs: { dateInterval },
                        error,
                    });
                }
            });
        });
    }

    return result;
};

export default hikvisionUploadDataHandler;
