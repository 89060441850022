import { Label, Tag, Text } from 'react-konva';
import scaleTransform from '../../../utils/locationDisplay/scaleTransform';

/**
 * Компонент для отрисвоки лебла для пересечений
 * @param {number} x координата x для лейбла
 * @param {number} y координата y для лейбла
 * @param {string} text текст для лейбла
 * @param {number} stageScale скейл канваса
 */
const IntersectionsLabel = ({ x, y, text, stageScale = 1 }) => {
    return (
        <Label x={x} y={y} opacity={0.75}>
            <Tag
                fill={'black'}
                pointerDirection={'down'}
                pointerWidth={scaleTransform(10, stageScale)}
                pointerHeight={scaleTransform(10, stageScale)}
                lineJoin={'round'}
                shadowColor={'black'}
                shadowBlur={2}
                shadowOffsetX={2}
                shadowOffsetY={2}
                shadowOpacity={0.5}
            />
            <Text text={text} fontSize={scaleTransform(14, stageScale)} lineHeight={1.2} padding={scaleTransform(5, stageScale)} fill={'white'} />
        </Label>
    );
};

export default IntersectionsLabel;
