import { memo } from 'react';
import { Provider } from 'react-redux';
import TasksMonitoring from './components/TasksMonitoring/TasksMonitoring';
import { ITasksMonitoringProps } from './components/TasksMonitoring/TasksMonitoring.interfaces';
import { store } from './store';

// eslint-disable-next-line
export default memo(({ xToken }: ITasksMonitoringProps) => {
    return (
        <Provider store={store}>
            <TasksMonitoring xToken={xToken} />
        </Provider>
    );
});
