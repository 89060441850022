import styled from "styled-components/macro";
import { getTheme, mergeStyleSets } from "@fluentui/react/lib/Styling";

const theme = getTheme();

const {
  spacing: { s2 },
} = theme;

export const Wrapper = styled.div`
  width: 35vh;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 10px;
`;

export const ContentWrapper = styled.div`
  flex-grow: 1;
`;

export const Title = styled.div`
  font-size: 20px;
  margin-bottom: 20px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 20px;
`;

export const contentStyles = (topColor = theme.palette.themePrimary) =>
  mergeStyleSets({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      minHeight: "unset",
    },
    header: [
      theme.fonts.large,
      {
        flex: "1 1 auto",
        borderTop: `${s2} solid ${topColor}`,
        color: theme.palette.neutralPrimary,
        display: "flex",
        alignItems: "center",
        padding: "12px 12px 14px 24px",
      },
    ],
    body: {
      flex: "4 4 auto",
      padding: "0 24px 24px 24px",
      overflowY: "hidden",
      selectors: {
        p: { margin: "14px 0" },
        "p:first-child": { marginTop: 0 },
        "p:last-child": { marginBottom: 0 },
      },
    },
  });
