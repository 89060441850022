import axios from 'axios';
import { hikvisionGetRequest, hikvisionPutRequest } from '../../../../api/hikvision/hikvision.api';
import { showErrorNotification, showSuccessNotification } from '../../../../generalReducer';
import getInfoForHikvisionBoundle from '../../../../utils/hikvisionHelpers/getInfoForHikvisionBoundle';
import { updateAvailableBoundleByIPointId } from '../../../instalationPoints/installationPointsReducer';
import { setSensorConfigurationUpdated, updateSingleSensor } from '../../countingSensorsReducer';
import processRowChannels from './processRowChannels';

/**
 * Вспомогательная функция для обновления конфигурации HIKVISION
 * @param {object} sensor объект выбранного сенсора
 * @param {function} dispatch диспатч в стейт
 * @param {string} payloadBody тело запроса
 * @param {object} currentBoundle объект текущей связки
 * @param {number} iPointId id точки установки
 * @param {boolean} showNotifications флаг, отвечающий за отображение оповещений
 */
const updateHikvisionConfiguration = async (options) => {
    const { sensor, dispatch, payloadBody, currentBoundle, iPointId, showNotifications = true } = options;

    const response = await hikvisionPutRequest(
        `http://${sensor.ip}:${sensor.port}/ISAPI/Event/notification/httpHosts/${
            sensor.hikvisionDataUploadId ? sensor.hikvisionDataUploadId : sensor.hikvisionEmptyDataUploadId
        }`,
        payloadBody,
        sensor.username,
        sensor.password
    );
    dispatch(
        updateAvailableBoundleByIPointId({
            iPointId,
            value: { ...currentBoundle, updateConfigurationFetching: false },
        })
    );

    if (!response.error) {
        dispatch(setSensorConfigurationUpdated(true));
        showNotifications && dispatch(showSuccessNotification({ show: true, message: 'Сonfiguration updated!' }));
        const response = await hikvisionGetRequest(
            `http://${sensor.ip}:${sensor.port}/ISAPI/System/Video/inputs/channels `,
            sensor.username,
            sensor.password
        )
            .then((channelsResponse) => {
                const channelData = processRowChannels(channelsResponse);

                if (!channelData.error) {
                    const promise1 = hikvisionGetRequest(
                        `http://${sensor.ip}:${sensor.port}/ISAPI/Event/notification/httpHosts`,
                        sensor.username,
                        sensor.password
                    );

                    const promise2 = hikvisionGetRequest(
                        `http://${sensor.ip}:${sensor.port}/ISAPI/System/Video/inputs/channels/${channelData.id}/counting`,
                        sensor.username,
                        sensor.password
                    );

                    return axios
                        .all([promise1, promise2])
                        .then((responses) => {
                            const hikvisionDataUploadInfo = responses[0];
                            const countingInfo = responses[1];
                            const res = getInfoForHikvisionBoundle({ hikvisionDataUploadInfo, countingInfo });
                            return { ...sensor, isConfigurationFetching: false, firstStepError: '', ...res };
                        })
                        .catch(() => ({ error: true }));
                } else {
                    return { error: true };
                }
            })
            .catch(() => ({ error: true }));

        if (!response.error) {
            dispatch(
                updateSingleSensor({
                    id: sensor.id,
                    sensor: { ...sensor, ...response },
                    increaseFetchedSensors: false,
                })
            );
        } else {
            dispatch(
                updateSingleSensor({
                    id: sensor.id,
                    sensor: { ...sensor, isConfigurationFetching: false, firstStepError: 'GET request error' },
                })
            );
        }
    } else {
        showNotifications && dispatch(showErrorNotification({ show: true, message: 'Something went wrong' }));
        dispatch(
            updateAvailableBoundleByIPointId({
                iPointId,
                value: { ...currentBoundle, updateConfigurationFetching: false },
            })
        );

        return { error: JSON.stringify(response?.error) || 'Something went wrong' };
    }
};

export default updateHikvisionConfiguration;
