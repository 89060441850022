import { brickstreamProgressMessages } from '../../../../utils/brickstreamHelpers/brickstreamProgressMessages';
import { updateExtendedSelectedIPoint } from '../../../instalationPoints/installationPointsReducer';
import updateConfigWebParams from './updateConfigWebParams';

/**
 * Функция для работы с состоянием конкретной связки, получением новой выгрузки
 * на датчике и обновлением выгрузки для датчика
 * @param {function} dispatch функция для обновления
 * @param {object} bundle текущая актуальная связь точки установки и датчика
 * @param {number} iPointId ID точки установки
 * @param {string} accessKey ключ доступа для локации / проекта
 * @param {object} sensorsById объект расширенных данных о датчиках
 * @param {*} sensorUploadUrlsBySensorType объект с информацией по выгрузке для всех датчиков
 * @returns promise
 */
const processBrickstreamBatchUrlUpdate = (args) => {
    const { dispatch, bundle, iPointId, accessKey, sensorsById, sensorUploadUrlsBySensorType } = args;
    const promise = new Promise((resolve) => resolve());

    dispatch(
        updateExtendedSelectedIPoint({
            data: { isFetching: true, isError: false, message: '' },
            iPointId,
        }),
    );

    if (!bundle?.thirdStepError) {
        dispatch(
            updateExtendedSelectedIPoint({
                data: {
                    isFetching: false,
                    isError: false,
                    message: brickstreamProgressMessages.thirdStepEnabled,
                },
                iPointId,
            }),
        );
        return promise;
    }

    if (
        bundle?.isFetched &&
        bundle?.thirdStepError &&
        !bundle?.firstStepError &&
        (bundle?.sensorData?.cfg?.dataPushServers?.companyDataPushServer ||
            bundle?.sensorData?.cfg?.dataPushServers?.dataPushServers.some((server) => server.isEmpty))
    ) {
        return updateConfigWebParams({
            sensor: sensorsById[bundle.id],
            showNotifications: false,
            currentBundle: bundle,
            dispatch,
            iPointId,
            accessKey,
            sensorUploadUrlsBySensorType,
        }).then((data) => {
            if (!data?.error) {
                dispatch(
                    updateExtendedSelectedIPoint({
                        data: {
                            isFetching: false,
                            isError: false,
                            message: 'Success',
                        },
                        iPointId,
                    }),
                );
            } else {
                dispatch(
                    updateExtendedSelectedIPoint({
                        data: {
                            message: JSON.stringify(data?.error || 'Something went wrong'),
                            isFetching: false,
                            isError: true,
                        },
                        iPointId,
                    }),
                );
            }
        });
    }

    dispatch(
        updateExtendedSelectedIPoint({
            data: {
                isFetching: false,
                isError: true,
                message: 'Something went wrong!',
            },
            iPointId,
        }),
    );

    return promise;
};

export default processBrickstreamBatchUrlUpdate;
