export const columnsForEqualModal = [
    {
        key: 'ip',
        name: 'Ip',
        fieldName: 'ip',
        isResizable: true,
        minWidth: 250,
        maxWidth: 250,
    },
    {
        key: 'port',
        name: 'Port',
        fieldName: 'port',
        isResizable: true,
        minWidth: 250,
        maxWidth: 250,
    },
    {
        key: 'serialNumberFromSensor',
        name: 'Serial number from sensor',
        fieldName: 'serialNumberFromSensor',
        isResizable: true,
        minWidth: 250,
        maxWidth: 250,
    },
    {
        key: 'serial_number',
        name: 'Serial number form csv',
        fieldName: 'serial_number',
        isResizable: true,
        minWidth: 250,
        maxWidth: 250,
    },
];
