/**
 * Функция для проверки наличия ошибки в ответе датчика
 * @param {string} xovisResponse
 * @returns текст ошибки
 */
const checkErrorInXovisResponse = (xovisResponse) => {
    let error = '';
    const oParser = new DOMParser();
    const xmlResponse = oParser.parseFromString(xovisResponse, 'text/xml');
    const errorTagsArray = xmlResponse.getElementsByTagName('ns2:error-message');
    if (errorTagsArray.length > 0) {
        error = errorTagsArray[0].textContent;
    } else if (!xovisResponse) {
        error = 'Empty response';
    }
    return error;
};

export default checkErrorInXovisResponse;
