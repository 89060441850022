import axios from 'axios';
import { Sensor } from '../sensor.base';
import { Brickstream3300Sensor } from './brickstream-3300';
import { DateTime } from 'luxon';

/**
 * @typedef {object} IError
 * @property {unknown | undefined} error Ошибка
 * @property {boolean} isError Флаг ошибки
 * @property {string} message Текст ошибки
 */

/**
 * @typedef {object} ILayer
 * @property {string} marker
 * @property {number[][]} coordinates
 */

/**
 * @typedef {object} ILayers
 * @property {ILayer[]} passWays
 * @property {ILayer[]} zones
 * @property {ILayer[]} floors
 * @property {ILayer[]} exceptions
 * @property {ILayer[]} masks
 */

/**
 *@typedef {object} IDataPushServerInfo
 * @property {string} index Index сервера (может быть 0 или 1)
 * @property {string} host
 * @property {string} url
 * @property {string} port
 * @property {string} pushGranularity
 * @property {string} pushInterval
 * @property {string} encryptData
 * @property {boolean} isEmpty
 */

/**
 * @typedef {object} ISrc
 * @property {string} config
 * @property {string} releaseInfo
 *
 *
 * @typedef {object} ICfg
 * @property {{[x: string]: string}} processedConfig
 * @property {{[x: string]: string}} processedReleaseInfo
 * @property {{dataPushServers: IDataPushServerInfo[], companyDataPushServer: IDataPushServerInfo}} dataPushServers
 * @property {ILayers} layers
 *
 *
 * @typedef {object} ISensorData
 * @property {string[]} errors
 * @property {string} sensorType
 * @property {ISrc} src
 * @property {ICfg} src
 *
 */

export class BrickstreamSensor extends Sensor {
    VERSION = 2300;
    #LINE_TYPES_MAP = {
        0: 'passway_in',
        1: 'passway_out',
        2: 'passway_exclude',
    };

    #UPLOAD_URLS_NUMBER_BY_DATAPUSH_INDEX = {
        0: 3,
        1: 4,
    };

    #EMPTY_DATA_PUSH_SERVER_PAYLOAD = {
        PARAM: '',
        tab_index: '',
        SMTP_DELIVERY_HOUR: '',
        SMTP_DELIVERY_MIN: '',
        SMTP_DELIVERY_SEC: '',
        FTP_DELIVERY_HOUR: '',
        FTP_DELIVERY_MIN: '',
        FTP_DELIVERY_SEC: '',
        DELVRY_SCHEDULE0: '',
        DELVRY_WINDOW0: '',
        DELVRY_SCHEDULE1: '',
        DELVRY_WINDOW1: '',
        SMTP_DELIVERY_ALLOWED: '',
        RT_DELIVERY_ALLOWED: '',
        ALERT_DELIVERY_ALLOWED: '',
        ALERT_DIO_ALLOWED: '',
        FTP_DELIVERY_ALLOWED: '',
        FLOW_ALLOWED: '',
        BASIC_SSL_ENABLED: '',
        NETWORK_STARTUP: '',
        CAM_HOST_NAME: '',
        IP_ADDRESS: '',
        SUBNET_MASK: '',
        DEFAULT_GATEWAY: '',
        DNS_SERVER: '',
        HTTP_SERVER_PORT: '',
        HTTPS_SERVER_PORT: '',
        SITE_ID: '',
        SITE_NAME: '',
        DIVISION_ID: '',
        DEVICE_ID: '',
        DEVICE_NAME: '',
        XML_SCHEMA: '',
        DATE: '',
        TIME: '',
        TIMEZONE: '',
        TIMESYNC_PROTO: '',
        TIME_SERVER_ADDRESS: '',
        TIME_SERVER_PORT: '',
        LOGGER_IP_ADDRESS: '',
        LOGGER_PORT: '',
        LOGGER_URL: '',
        LOGGER_USE_SSL_TLS: '',
        LOGGER_SNI: '',
        PROXY_IP_ADDRESS: '',
        PROXY_PORT: '',
        PROXY_CONNECT_FREQUENCY: '',
        HTTPPROXY_IP_ADDRESS: '',
        HTTPPROXY_PORT: '',
        HTTPPROXY_AUTH_ID: '',
        HTTPPROXY_AUTH_PASSWORD: '',
        DELVRY_SYS_IP_ADDRESS0: '',
        DELVRY_SYS_PORT0: '',
        DELVRY_SYS_URL0: '',
        DELVRY_AGGREGATION_INTERVAL0: '',
        DSCH0: '',
        DELVRY_USE_SSL_TLS0: '',
        DELVRY_SNI0: '',
        USE_DELVRY_SYS1: '',
        USE_DELVRY_SYS0: '',
        DELVRY_SYS_IP_ADDRESS1: '',
        DELVRY_SYS_PORT1: '',
        DELVRY_SYS_URL1: '',
        DSCH1: '',
        DELVRY_USE_SSL_TLS1: '',
        DELVRY_SNI1: '',
        RT_SYS_IP_ADDRESS: '',
        RT_SYS_PORT: '',
        RT_SYS_URL: '',
        RT_FREQUENCY: '',
        RT_DELIVERY_PROTO: '',
        RT_SKIP_INACTIVITY: '',
        ALERT_SYS_IP_ADDRESS: '',
        ALERT_SYS_PORT: '',
        ALERT_SYS_URL: '',
        ALERT_INTER_DELAY_TIME: '',
        ALERT_DELIVER_COUNTS: '',
        ALERT_DELIVERY_PROTO: '',
        ALERT_DIO_STYLE0: '',
        ALERT_DIO_PULSELEN0: '',
        ALERT_DIO_DELAYLEN0: '',
        ALERT_DIO_ZONE_ID0: '',
        ALERT_DIO_STYLE1: '',
        ALERT_DIO_PULSELEN1: '',
        ALERT_DIO_DELAYLEN1: '',
        ALERT_DIO_ZONE_ID1: '',
        ALERT_DIO_STYLE2: '',
        ALERT_DIO_PULSELEN2: '',
        ALERT_DIO_DELAYLEN2: '',
        ALERT_DIO_ZONE_ID2: '',
        SMTP_RECIPIENT_ID_0: '',
        SMTP_FROM_ID: '',
        SMTP_HOST: '',
        SMTP_PORT: '',
        SMTP_AUTH_ID: '',
        SMTP_AUTH_PASSWORD: '',
        SMTP_AGGREGATION_INTERVAL: '',
        SMTP_DELIVERY_TIME: '',
        FLOW_IP: '',
        FLOW_PORT: '',
        FLOW_URL: '',
        FLOW_SCHED: '',
        FLOW_SSL_TLS: '',
        FLOW_SNI: '',
        PLINK_PORT: '',
        XMIT_IMMD: '',
        IMMD_SDATE: '',
        IMMD_STIME: '',
        IMMD_EDATE: '',
        IMMD_ETIME: '',
    };

    /**
     * Метод для получения конфигурации датчика
     * @returns {Promise<string | IError>}
     */
    async getConfig() {
        const response = await this.#getRequest({
            url: `http://${this.ip}:${this.port}/getConfigExportFile.cgi`,
        });

        if (response.isError) {
            this.errors.push(response.message);
        }

        return response;
    }

    /**
     * Метод для преобразования строчного конфига датчика в объект
     * @param {string} config Конфигурация датчика
     * @returns {{[x: string]: string}}
     */
    processConfig(config) {
        return config.split('&').reduce((acc, param) => {
            const [key, value] = param.split('=');
            acc[key] = value;
            return acc;
        }, {});
    }

    /**
     * Метод для получения серверов выгрузки
     * @param {{[x: string]: string}} processedConfig Преобразованный конфиг сервера
     * @returns {{dataPushServers: IDataPushServerInfo[], companyDataPushServer: IDataPushServerInfo}}
     */
    getDataPushServersInfo(processedConfig) {
        const dataPushServers = Array(2)
            .fill(null)
            .map((_, index) => {
                const host = processedConfig[`DELVRY_SNI${index}`];
                const ip = processedConfig[`DELVRY_SYS_IP_ADDRESS${index}`];
                return {
                    index,
                    host,
                    ip,
                    url: processedConfig[`DELVRY_SYS_URL${index}`],
                    port: processedConfig[`DELVRY_SYS_PORT${index}`],
                    pushGranularity: processedConfig[`DELVRY_AGGREGATION_INTERVAL${index}`],
                    pushInterval: processedConfig[`DELVRY_SCHEDULE${index}`],
                    encryptData: processedConfig[`DELVRY_USE_SSL_TLS${index}`],
                    isEmpty: (host === '0.0.0.0' || host === '') && (ip === '0.0.0.0' || ip === ''),
                };
            });
        const companyDataPushServer =
            dataPushServers.find((server) => {
                return (
                    server.ip?.includes(this.companyInfo.getCompanyUploadUrlPart()) ||
                    server.host?.includes(this.companyInfo.getCompanyUploadUrlPart())
                );
            }) ?? null;
        return {
            dataPushServers,
            companyDataPushServer,
        };
    }

    /**
     * Метод для получения всех слоев датчика
     * @param {{[x: string]: string}} processedConfig Преобразованный конфиг сервера
     * @returns {ILayers}
     */
    getLayers(processedConfig) {
        const { passWays, exceptions } = this.#getPasswaysAndExceptions(processedConfig);
        const floors = this.#getFloors(processedConfig);

        return { passWays, zones: [], floors, exceptions, masks: [] };
    }

    /**
     * Метод для получения разметки пола
     * @param {{[x: string]: string}} processedConfig Преобразованный конфиг сервера
     * @returns {ILayer[]}
     */
    #getFloors(processedConfig) {
        const floors = [];
        Array(Number(processedConfig['NUM_OF_QUEUES'] ?? 0))
            .fill(null)
            .forEach((_, index) => {
                const zoneIndex = processedConfig[`QUEUE_ZONE_${index}`];
                const zoneName = processedConfig[`ZONE_NAME_${zoneIndex}`];

                const marker = `${zoneName}_floor_${index}`;
                const coordinates = [];

                Array(Number(processedConfig[`QUEUE_NUM_OF_POINTS_${index}`] ?? 0))
                    .fill(null)
                    .forEach((_, index) => {
                        const queueIndex = index < 10 ? `0${index}` : index;
                        coordinates.push([
                            this.#getLayerCoordinate(processedConfig, `QUEUE_X_${queueIndex}`),
                            this.#getLayerCoordinate(processedConfig, `QUEUE_Y_${queueIndex}`),
                        ]);
                    });

                floors.push({ marker, coordinates });
            });
        return floors;
    }

    /**
     * Метод для получения проходов и исключений
     * @param {{[x: string]: string}} processedConfig Преобразованный конфиг сервера
     * @returns {{passWays: ILayer[], exceptions: ILayer[]}}
     */
    #getPasswaysAndExceptions(processedConfig) {
        const passWays = [];
        const exceptions = [];
        Array(Number(processedConfig['NUM_OF_LINES'] ?? 0))
            .fill(null)
            .forEach((_, index) => {
                const lineType = processedConfig[`LINE_TYPE_${index}`];
                const lineTypePostfix = this.#LINE_TYPES_MAP[lineType] ?? '';

                const zoneIndex = processedConfig[`LINE_ZONE_${index}`];
                const zoneName = processedConfig[`ZONE_NAME_${zoneIndex}`];

                const marker = `${zoneName}_${lineTypePostfix}_${index}`;
                const coordinates = [
                    [
                        this.#getLayerCoordinate(processedConfig, `X1_${index}`),
                        this.#getLayerCoordinate(processedConfig, `Y1_${index}`),
                    ],
                    [
                        this.#getLayerCoordinate(processedConfig, `X2_${index}`),
                        this.#getLayerCoordinate(processedConfig, `Y2_${index}`),
                    ],
                ];

                if (lineTypePostfix === this.#LINE_TYPES_MAP[2]) {
                    exceptions.push({ marker, coordinates });
                } else {
                    passWays.push({ marker, coordinates });
                }
            });

        return { passWays, exceptions };
    }

    /**
     * Метод для получения координаты в цифровом формате
     * @param {{[x: string]: string}} processedConfig Преобразованный конфиг сервера
     * @param {string} key ключ для объекта конфига
     * @returns {number}
     */
    #getLayerCoordinate(processedConfig, key) {
        return Number(processedConfig[key]);
    }

    /**
     * Метод для получения информации о выпуске датчика
     * @returns {Promise<string | IError>}
     */
    async getReleaseInfo() {
        const response = await this.#getRequest({
            url: `http://${this.ip}:${this.port}/help.html`,
        });

        if (response.isError) {
            this.errors.push(response.message);
        }

        return response;
    }

    /**
     * Метод для преобразования строчной информации о выпуске датчика в объект
     * @param {string} releaseInfo Информация о выпуске датчика (HTML string)
     * @returns {{[x: string]: string}}
     */
    processReleaseInfo(releaseInfo) {
        const oParser = new DOMParser();
        const document = oParser.parseFromString(releaseInfo, 'text/xml');
        const inputs = document.querySelectorAll('input');
        const result = {};

        inputs.forEach((input) => {
            if (input.id) {
                result[input.id] = input.value;
            }
        });

        return result;
    }

    /**
     * Метод для обновления выгрузки на датчике
     */
    async updateDataPushServer(sensorData, dataPushServerUrl = '') {
        if (sensorData?.version === 3300) {
            const instance3300 = new Brickstream3300Sensor(
                this.ip,
                this.port,
                this.sensorType,
                this.username,
                this.password,
                this.companyInfo,
                this.REQUEST_TIMEOUT_IN_SECONDS,
            );
            return instance3300.updateDataPushServer(
                sensorData?.src?.dataPushServers,
                sensorData?.cfg?.dataPushServers,
                dataPushServerUrl,
            );
        }
        const payload = this.#generateUpdateDataPushServerPayload(sensorData.cfg, dataPushServerUrl);

        const response = await this.#postRequest({
            url: `http://${this.ip}:${this.port}/configWebParams.cgi`,
            data: payload,
        });

        if (response.isError || response.includes('id="error"')) {
            this.errors.push(response.message);
        }

        if (response.includes('id="error"')) {
            return {
                isError: true,
                message: 'Ann error accrued while update data push server',
            };
        }
        return response;
    }

    /**
     * Метод для генерации тела запроса для обновления выгрузки на датчике
     * @param {ICfg} cfg
     * @param {string} dataPushServerUrl
     * @returns {URLSearchParams}
     */
    #generateUpdateDataPushServerPayload(cfg, dataPushServerUrl) {
        let payload = {};

        Object.keys(this.#EMPTY_DATA_PUSH_SERVER_PAYLOAD).forEach((key) => {
            switch (key) {
                case 'PARAM':
                    payload[key] = 'basic';
                    break;
                case 'tab_index':
                    payload[key] = '6';
                    break;

                default:
                    payload[key] = cfg.processedConfig[key] ?? '';
                    break;
            }
        });

        if (cfg.dataPushServers.companyDataPushServer) {
            const { index } = cfg.dataPushServers.companyDataPushServer;

            payload = {
                ...payload,
                ...this.#generateCorrectDataPushServerData(index, dataPushServerUrl),
            };
        } else {
            const emptyDataPushServer = cfg.dataPushServers.dataPushServers.find((server) => server.isEmpty);

            if (emptyDataPushServer) {
                payload = {
                    ...payload,
                    ...this.#generateCorrectDataPushServerData(emptyDataPushServer.index, dataPushServerUrl),
                };
            }
        }
        return new URLSearchParams(payload).toString();
    }

    /**
     * Метод для генерации правильно выгрузки на датчике
     * @param {number} index Индекс сервера
     * @param {string} dataPushServerUrl
     * @returns {{[x: string]: string}}
     */
    #generateCorrectDataPushServerData(index, dataPushServerUrl) {
        const correctDataPushServer = this.companyInfo.getSensorUploadUrls()?.sensors?.brickstream ?? {};
        console.log(correctDataPushServer);
        return {
            [`DELVRY_SNI${index}`]: correctDataPushServer.host ?? '',
            [`DELVRY_SYS_IP_ADDRESS${index}`]: correctDataPushServer.host ?? '',
            [`DELVRY_SYS_URL${index}`]: dataPushServerUrl,
            [`DELVRY_SYS_PORT${index}`]: correctDataPushServer.port ?? '',
            [`DELVRY_AGGREGATION_INTERVAL${index}`]: this.CORRECT_DATA_PUSH_SERVER_TIMINGS.pushGranularity,
            [`DELVRY_SCHEDULE${index}`]: this.CORRECT_DATA_PUSH_SERVER_TIMINGS.pushInterval,
            [`DSCH${index}`]: this.CORRECT_DATA_PUSH_SERVER_TIMINGS.pushInterval,
            [`DELVRY_USE_SSL_TLS${index}`]: '1',
            [`USE_DELVRY_SYS${index}`]: '1',
        };
    }

    #getSensorData2300(config) {
        return Promise.all([this.getReleaseInfo()])
            .then(([releaseInfo]) => {
                if (config.isError || releaseInfo.isError) {
                    this.errors.push('Get sensor data error');

                    return {
                        isError: true,
                        message: 'Ann error accrued while getting data',
                    };
                }

                const processedConfig = this.processConfig(config);
                const processedReleaseInfo = this.processReleaseInfo(releaseInfo);
                return {
                    version: this.VERSION,
                    errors: this.errors,
                    sensorType: this.sensorType,
                    src: { config, releaseInfo },
                    cfg: {
                        processedConfig,
                        processedReleaseInfo,
                        dataPushServers: this.getDataPushServersInfo(processedConfig),
                        layers: this.getLayers(processedConfig),
                    },
                };
            })
            .catch((error) => {
                this.errors.push('Get sensor data error');
                return {
                    isError: true,
                    message: 'Ann error accrued while getting data',
                    error,
                };
            });
    }

    /**
     * Метод для получения данных датчика для работы с табличками
     * точек установки и тд
     * @return {Promise<ISensorData | IError>}
     */
    async getSensorData() {
        const { instance3300, identification, config } = await this.#getSensorVersion();
        if (!identification.isError) {
            return instance3300.getSensorData(identification);
        }

        if (!config.error) {
            return this.#getSensorData2300(config);
        }
        this.errors.push('Get sensor data error');
        return {
            isError: true,
            message: 'Ann error accrued while getting data',
        };
    }

    /**
     * Метод для проверки связи с датчиком
     */
    async #connectionTest2300() {
        const ITERATIONS_COUNT = 3;
        let orderCount = 0;
        let successOrderCount = 0;

        for (let i = 0; i < ITERATIONS_COUNT; i++) {
            orderCount += 1;
            const response = await this.getConfig();

            if (!response.isError) {
                successOrderCount += 1;
            }
            await new Promise((resolve) => setTimeout(resolve, 2000));
        }

        return { orderCount, successOrderCount, errors: this.errors };
    }

    /**
     * Метод для проверки связи с датчиком
     */
    async connectionTest() {
        const { instance3300, identification, config } = await this.#getSensorVersion();
        if (!identification.isError) {
            return instance3300.connectionTest();
        }

        if (!config.error) {
            return this.#connectionTest2300();
        }
        this.errors.push('Sensor is offline');
        return {
            orderCount: 1,
            successOrderCount: 0,
        };
    }

    /**
     * Метод для переотправки данных с датчика
     * @param {string} dateFrom ISO format date
     * @param {string} dateTo ISO format date
     */
    async #resendData2300(dateFrom, dateTo, config) {
        const dateFromDateTime = DateTime.fromISO(dateFrom);
        const dateToDateTime = DateTime.fromISO(dateTo);

        let orderCount = 1;
        let successOrderCount = 0;

        if (!dateFromDateTime.isValid || !dateToDateTime.isValid) {
            this.errors.push('Invalid dates');
        }

        const processedConfig = this.processConfig(config);
        const dataPushServers = this.getDataPushServersInfo(processedConfig);
        const layers = this.getLayers(processedConfig);

        if (!layers.passWays.length) {
            this.errors.push('Number of passways is 0');
        }
        if (
            !dataPushServers.companyDataPushServer ||
            !this.#UPLOAD_URLS_NUMBER_BY_DATAPUSH_INDEX[dataPushServers.companyDataPushServer.index]
        ) {
            this.errors.push('No company datapush server');
        }

        if (!this.errors.length) {
            const payload = {
                IMMD_SDATE: dateFromDateTime.toFormat('MM/dd/yyyy'),
                IMMD_STIME: dateFromDateTime.toFormat('HH:mm:ss'),
                IMMD_EDATE: dateToDateTime.toFormat('MM/dd/yyyy'),
                IMMD_ETIME: dateToDateTime.toFormat('HH:mm:ss'),
                XMIT_IMMD: this.#UPLOAD_URLS_NUMBER_BY_DATAPUSH_INDEX[dataPushServers.companyDataPushServer.index],
            };

            const response = await this.#postRequest({
                url: `http://${this.ip}:${this.port}/requestXmit.cgi`,
                data: new URLSearchParams(payload).toString(),
            });

            if (response.isError || response.includes('id="error"')) {
                this.errors.push('Ann error accrued while resend data');
            } else {
                successOrderCount += 1;
            }
        }

        return {
            errors: this.errors,
            orderCount,
            successOrderCount,
            failedIntervals: successOrderCount < 1 ? [{ dateFrom, dateTo }] : [],
        };
    }

    /**
     * Метод для переотправки данных с датчика
     * @param {string} dateFrom ISO format date
     * @param {string} dateTo ISO format date
     */
    async resendData(dateFrom, dateTo) {
        const { instance3300, identification, config } = await this.#getSensorVersion();

        if (!identification.isError) {
            return instance3300.resendData(dateFrom, dateTo, identification);
        }

        if (!config.isError) {
            return this.#resendData2300(dateFrom, dateTo, config);
        }
        this.errors.push('Sensor is offline');
        return {
            orderCount: 1,
            successOrderCount: 0,
            errors: this.errors,
        };
    }

    async registerClient() {
        const STREAM_ID = '2';
        const IMAGE_NAME = '1';
        const payload = `uid=${this.#generateGUID()}&image${STREAM_ID}=${IMAGE_NAME}`;

        const response = await this.#postRequest({
            url: `http://${this.ip}:${this.port}/registerClient.cgi`,
            data: payload,
            headers: { 'Content-Type': 'text/plain' },
        });

        if (response.isError) {
            this.errors.push('Ann error accrued while register client');
        }

        return response;
    }

    async getScreen() {
        const registerClientResponse = await this.registerClient();

        if (registerClientResponse.isError) {
            return registerClientResponse;
        }
        await new Promise((resolve) => setTimeout(resolve, 2000));

        const response = await this.#getRequest({
            url: `http://${this.ip}:${this.port}/leftImage.jpg`,
            responseType: 'blob',
        });

        if (response.isError) {
            this.errors.push('Ann error accrued while getting screen');
            return response;
        }

        let parsedScreen = await new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(response);
            reader.onloadend = () => resolve({ screen: reader.result });
            reader.onerror = () => resolve({ error: 'Parse screen error' });
        });

        if (parsedScreen.error) {
            this.errors.push(parsedScreen.error);
            return {
                isError: true,
                message: parsedScreen.error,
            };
        }

        return {
            screen: parsedScreen.screen,
        };
    }

    async #sendScreen2300(url, config) {
        await Promise.all([this.getReleaseInfo(), this.getScreen()]).then(async ([releaseInfo, screenResponse]) => {
            if (releaseInfo.isError) {
                this.errors.push(releaseInfo.message);
            }

            if (screenResponse.isError) {
                this.errors.push(screenResponse.message);
            }

            if (!this.errors.length) {
                const processedConfig = this.processConfig(config);
                const processedReleaseInfo = this.processReleaseInfo(releaseInfo);
                const layers = this.getLayers(processedConfig);

                const payload = {
                    sensor_data: {
                        serial_number: processedReleaseInfo.MAC_ADDRESS,
                        sensor_type: this.sensorType,
                        date_from: DateTime.now()
                            .toUTC()
                            .set({ milliseconds: 0 })
                            .toISO({ suppressMilliseconds: true }),
                        layers: {
                            pass_ways: layers.passWays,
                            zones: layers.zones,
                            floors: layers.floors,
                            exceptions: layers.exceptions,
                            masks: layers.masks,
                        },
                    },
                    screen: screenResponse.screen,
                };

                const response = await this.#postRequest({
                    url: `${url}?serial_number=${processedReleaseInfo.MAC_ADDRESS}&sensor_type=${this.sensorType}`,
                    data: payload,
                    headers: { 'Content-type': 'application/json;charset=UTF-8' },
                });

                if (response.isError) {
                    this.errors.push('Post to lambda error');
                }
            }
        });

        return {
            errors: this.errors,
        };
    }

    /**
     * Метод для отправки скриншота с датчика
     * @param {string} url URL на который нужно сделать POST для сохранения скриншота
     */
    async sendScreen(url) {
        const { instance3300, identification, config } = await this.#getSensorVersion();

        if (!identification.isError) {
            return instance3300.sendScreen(url, identification);
        }

        if (!config.isError) {
            return this.#sendScreen2300(url, config);
        }
        this.errors.push('Sensor is offline');
        return { errors: this.errors };
    }

    #generateGUID() {
        const S4 = () => (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
        return (S4() + S4() + S4() + '4' + S4().substr(0, 3)).toLowerCase();
    }

    /**
     * Метод для определения версии датчика, путем запроса
     */
    async #getSensorVersion() {
        const instance3300 = new Brickstream3300Sensor(
            this.ip,
            this.port,
            this.sensorType,
            this.username,
            this.password,
            this.companyInfo,
            this.REQUEST_TIMEOUT_IN_SECONDS,
        );

        return Promise.all([instance3300.getIdentification(), this.getConfig()]).then(([identification, config]) => {
            return { identification, config, instance3300 };
        });
    }

    /**
     * GET запрос
     * @typedef {object} IGetArgs
     * @property {string} url URL для запроса
     * @property {string} responseType Тип ответа
     *
     * @param {IGetArgs} args аргументы
     *
     * @returns {Promise<unknown> | Promise<IError>}
     */
    async #getRequest(args) {
        try {
            const data = await axios
                .get(args.url, {
                    timeout: this.REQUEST_TIMEOUT_IN_SECONDS * 1000,
                    auth: {
                        username: this.username,
                        password: this.password,
                    },
                    responseType: args.responseType,
                })
                .then((response) => response.data);
            return data;
        } catch (error) {
            return { isError: true, message: 'Ann error accrued while getting data', error };
        }
    }
    /**
     * POST запрос
     * @typedef {object} IPostArgs
     * @property {string} url URL для запроса
     * @property {unknown} data Данные для POST запроса
     * @property {object} headers Данные для POST запроса
     *
     * @param {IPostArgs} args аргументы
     *
     * @returns {Promise<unknown> | Promise<IError>}
     */
    async #postRequest(args) {
        try {
            const data = await axios
                .post(args.url, args.data, {
                    timeout: this.REQUEST_TIMEOUT_IN_SECONDS * 1000,
                    headers: args.headers,
                    auth: {
                        username: this.username,
                        password: this.password,
                    },
                })
                .then((response) => response.data);
            return data;
        } catch (error) {
            return { isError: true, message: 'Ann error accrued while post data', error };
        }
    }
}
