/**
 * Функция для формирования объекта из массива
 * @param {string} key ключ по которобу будет собираться объект
 * @param {array} array массив из которого будет собираться объект
 * @returns объект, собранный из массива по переданному ключу
 */
const createObjectFromArray = (options) => {
    const { key, array } = options;

    const objectFromArray = array.reduce((acc, value) => {
        acc[value[key]] = value;
        return acc;
    }, {});

    return objectFromArray;
};

export default createObjectFromArray;
