import { TooltipHost } from '@fluentui/react';
import { brickstreamProgressMessages } from '../../../../../../../../utils/brickstreamHelpers/brickstreamProgressMessages';
import { Circle, CircleWrapper, ColumnWrapper } from '../../instalationPointsList.styles';

/**
 *  Компонент для отрисовки прогресс бара для датчика BRICKSTREAM
 * @param {object} selectedSensor объект выбранного сенсора
 */
const BrickstreamProgressColumn = ({ selectedSensor }) => {
    return (
        <ColumnWrapper>
            <CircleWrapper>
                <TooltipHost
                    content={
                        selectedSensor
                            ? !selectedSensor.firstStepError
                                ? brickstreamProgressMessages.firstStepEnabled
                                : brickstreamProgressMessages.firstStepError
                            : null
                    }
                >
                    <Circle newColor={selectedSensor ? (!selectedSensor.firstStepError ? 'green' : 'red') : 'grey'}>
                        1
                    </Circle>
                </TooltipHost>
            </CircleWrapper>
            <CircleWrapper>
                <TooltipHost
                    content={
                        selectedSensor
                            ? selectedSensor.sensorData?.cfg?.layers?.passWays?.length === 0
                                ? brickstreamProgressMessages.secondStepNotCountLines
                                : selectedSensor.secondStepError
                                ? selectedSensor.secondStepError
                                : brickstreamProgressMessages.secondStepEnabled
                            : null
                    }
                >
                    <Circle
                        newColor={
                            selectedSensor
                                ? selectedSensor.sensorData?.cfg?.layers?.passWays?.length === 0
                                    ? 'grey'
                                    : !selectedSensor.secondStepError
                                    ? 'green'
                                    : 'red'
                                : 'grey'
                        }
                    >
                        2
                    </Circle>
                </TooltipHost>
            </CircleWrapper>
            <CircleWrapper>
                <TooltipHost
                    content={
                        selectedSensor
                            ? !selectedSensor.thirdStepError
                                ? brickstreamProgressMessages.thirdStepEnabled
                                : selectedSensor.thirdStepError
                            : null
                    }
                >
                    <Circle newColor={selectedSensor ? (!selectedSensor.thirdStepError ? 'green' : 'red') : 'grey'}>
                        3
                    </Circle>
                </TooltipHost>
            </CircleWrapper>
        </ColumnWrapper>
    );
};

export default BrickstreamProgressColumn;
