import { PivotItem } from '@fluentui/react';
import { memo } from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { InstallationPointsContext } from '../../installationPoints';
import InstalationPointsList from './components/instalationPointsList/instalationPointsList';
import SensorsList from './components/sensorsList/sensorsList';
import {
    ListsWrapper,
    pivotStyles,
    SplittedIPointsListWrapper,
    SplittedSensorsList,
    StyledPivot,
} from './floorInformation.styles';

/**
 * Обертка для таблиц, где отображаются точки установки в связке с сенсорами
 * и доступные сенсоры
 */
const FloorInformation = memo(() => {
    const { instalationPointsOnCurrentFloor } = useContext(InstallationPointsContext);
    const { t } = useTranslation();

    return (
        <ListsWrapper>
            {instalationPointsOnCurrentFloor.length === 0 ? (
                <div>No installation points</div>
            ) : (
                <StyledPivot style={{ height: '100%' }} styles={pivotStyles}>
                    <PivotItem style={{ height: '100%' }} headerText={t('Installation points')}>
                        <SplittedIPointsListWrapper>
                            <InstalationPointsList />
                        </SplittedIPointsListWrapper>
                    </PivotItem>
                    <PivotItem style={{ height: '100%' }} headerText={t('Sensors')}>
                        <SplittedSensorsList>
                            <SensorsList />
                        </SplittedSensorsList>
                    </PivotItem>
                </StyledPivot>
            )}
        </ListsWrapper>
    );
});

export default FloorInformation;
