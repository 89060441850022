import {
    DefaultButton,
    DetailsList,
    DetailsRow,
    PrimaryButton,
    Selection,
    Spinner,
    SpinnerSize,
    Sticky,
    StickyPositionType,
    Toggle,
    TooltipHost,
} from '@fluentui/react';
import { memo, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalCreator from '../../../../../modals/modalCreator';
import BoundleInfoModal from '../../../../../modals/boundleInfoModal/boundleInfoModal';
import { InstallationPointsContext } from '../../../../installationPoints';
import ProgressColumn from './components/progressColumn';
import SensorsColumn from './components/sensorsColumn';
import UploadUpdate from './components/uploadUpdate';
import {
    ColumnWrapper,
    CustomIPointIcon,
    IPointsTitle,
    rowStyles,
    selectedRowStyles,
    StyledFilter,
    IPointsHeaderWrapper,
    ListWrapper,
    NumberOfLines,
    followIPointStyles,
    FollowIPointLabel,
    SelectedIPointsButtonsWrapper,
    RelationsWrapper,
    RelationTypeWrapper,
} from './instalationPointsList.styles';
import columnSortHandler from '../../../../../../utils/detailsListHelpers/columnSortHandler';
import PassWayAndIPointRealationTooltip from './components/passWayAndIPointRealationTooltip copy';
import Dependencies from './components/dependencies';
import Address from './components/address';
import { iPointsListColumns } from './columns/iPointsListColumns';
import { cloneDeep } from 'lodash';
import { sensorTypes } from '../../../../../../constants/sensorTypes';
import ListContainer from '../../../../../../commonComponents/listContainer';
import UrlBatchUpdateModal from './components/BatchUrlUpdateModal/BatchUrlUpdateModal';
import UpdateSensorsUploadUrlsModal from './components/UpdateSensorsUploadUrlsModal/UpdateSensorsUploadUrlsModal';

/**
 * Таблица со точками для установки сенсоров
 */
const InstalationPointsList = memo(() => {
    const {
        passWaysAndIPointsRelationsByIPointMarker,
        updateAvailableBoundleByIPointIdTrans,
        updateSensorConfigurationThunkTrans,
        storeSelectedInstallationPointsTrans,
        storeIPointForBoundleInfoModalTrans,
        setSelectedInstalationPointTrans,
        instalationPointsOnCurrentFloor,
        isSensorConfigurationUpdated,
        resetBoundleInfoReducerTrans,
        locationIdFromMapServiceLink,
        extendedSelectedIPointsById,
        availableBundlesByIPointId,
        iPointForBoundleInfoModal,
        selectedInstalationPoint,
        batchSensorsUpdateTrans,
        toggleFollowIPointTrans,
        isRealationsFetching,
        isSensorsFetching,
        selectedFloor,
        followIPoint,
        slavesByIp,
        accessKey,
        ...context
    } = useContext(InstallationPointsContext);

    const [isUploadUrlsModalOpen, setUploadUrlsModalOpen] = useState(false);
    const [columnForSort, setColumnForSort] = useState(null);
    const [filteredItems, setFilteredItems] = useState([]);
    const [columns, setColumns] = useState([]);
    const [filter, setFilter] = useState('');
    const [open, setOpen] = useState(false);

    const listRef = useRef();

    const { t } = useTranslation();

    useEffect(() => {
        setColumnForSort(null);
    }, [selectedFloor]);

    useEffect(() => {
        if (listRef.current && selectedInstalationPoint && followIPoint) {
            const rowIndex = filteredItems.findIndex((item) => item.id === selectedInstalationPoint.id);

            if (rowIndex) {
                listRef.current.focusIndex(rowIndex);
            }
        }
    }, [listRef, selectedInstalationPoint, filteredItems, followIPoint]);

    useEffect(() => {
        setFilter('');
        setColumns(iPointsListColumns);
    }, []);

    useEffect(() => {
        if (iPointForBoundleInfoModal) handleOpen();
    }, [iPointForBoundleInfoModal]);

    /** Фильтрация списка точек установки */
    useEffect(() => {
        let finalArray = cloneDeep(instalationPointsOnCurrentFloor);

        if (columnForSort) {
            const { newItems, newColumns } = columnSortHandler({
                boundles: availableBundlesByIPointId,
                allColumns: iPointsListColumns,
                slavesByIp: slavesByIp,
                listItems: finalArray,
                column: columnForSort,
            });
            finalArray = newItems;
            setColumns(newColumns);
        }

        if (filter) {
            finalArray = finalArray.filter((element) => {
                const filterValue = filter.toLowerCase();
                return (
                    element.floor === selectedFloor &&
                    (element.marker.toLowerCase().includes(filterValue) ||
                        `${availableBundlesByIPointId[element.id]?.ip}:${
                            availableBundlesByIPointId[element.id]?.port
                        }`.includes(filterValue) ||
                        Object.values(
                            context.sensorRelationsById[availableBundlesByIPointId[element.id]?.id] || {},
                        )?.some((relations) =>
                            relations?.some((relation) => relation.marker_and_name.includes(filter)),
                        ))
                );
            });
        }
        setFilteredItems(finalArray);
    }, [
        passWaysAndIPointsRelationsByIPointMarker,
        instalationPointsOnCurrentFloor,
        isSensorConfigurationUpdated,
        availableBundlesByIPointId,
        selectedInstalationPoint,
        selectedFloor,
        columnForSort,
        slavesByIp,
        filter,
        context.sensorRelationsById,
    ]);

    const selection = useMemo(() => {
        return new Selection({
            onSelectionChanged: () => storeSelectedInstallationPointsTrans(selection.getSelection()),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onRowClickHandler = (item) => {
        setSelectedInstalationPointTrans(item);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        storeIPointForBoundleInfoModalTrans(null);
        resetBoundleInfoReducerTrans();
    };

    const filterHandler = (_, newValue) => {
        setFilter(newValue);
    };

    const followIPointHandler = (_, value) => {
        toggleFollowIPointTrans(value);
    };

    const onBatchSensorsUpdate = () => {
        batchSensorsUpdateTrans();
    };

    const closeBatchUrlUpdateModal = () => {
        context.toggleBatchUrlUpdateModalStatusTrans({ show: false });
    };

    const openBatchUrlUpdateModal = () => {
        context.toggleBatchUrlUpdateModalStatusTrans({ show: true });
    };

    const onLoadSelectedSensorsData = () => {
        context.getSensorsDataThunkTrans();
    };

    const onColumnClick = (_, column) => {
        const allowedKeys = ['marker', 'sensorAddress', 'boundle', 'progress', 'sensorMAC', 'type'];
        if (!column || !allowedKeys.includes(column.key)) return;
        setColumnForSort(column);
    };

    const renderItemColumn = (item, _, column) => {
        const fieldContent = item[column.fieldName];

        switch (column.key) {
            case 'boundle':
                return (
                    <ColumnWrapper>
                        <SensorsColumn iPoint={item} isSensorsFetched={!isSensorsFetching} />
                    </ColumnWrapper>
                );
            case 'active':
                return (
                    <ColumnWrapper>
                        <CustomIPointIcon
                            newColor={item.active ? 'blue' : 'red'}
                            iconName={item.active ? 'BoxCheckmarkSolid' : 'BoxMultiplySolid'}
                        />
                    </ColumnWrapper>
                );
            case 'type': {
                if (isSensorsFetching) {
                    return (
                        <ColumnWrapper>
                            <Spinner size={SpinnerSize.large} />
                        </ColumnWrapper>
                    );
                }
                if (availableBundlesByIPointId[item.id]?.isMultiSensor) {
                    return <ColumnWrapper>Master</ColumnWrapper>;
                }

                if (slavesByIp[availableBundlesByIPointId[item.id]?.ip]) {
                    return <ColumnWrapper>Slave</ColumnWrapper>;
                }
                return null;
            }
            case 'numberOfLines': {
                let numberOfLines = '';
                if (isSensorsFetching) {
                    return (
                        <ColumnWrapper>
                            <Spinner size={SpinnerSize.large} />
                        </ColumnWrapper>
                    );
                }
                if (availableBundlesByIPointId[item.id]) {
                    switch (availableBundlesByIPointId[item.id].sensor_type) {
                        case sensorTypes.XOVIS:
                            const version = Number(
                                availableBundlesByIPointId[item.id].xovisData?.version?.split('.')?.[0],
                            );

                            if (version < 5) {
                                numberOfLines =
                                    availableBundlesByIPointId[item.id].xovisData?.data?.config?.passWayNames?.length;
                            } else if (version >= 5) {
                                numberOfLines =
                                    availableBundlesByIPointId[item.id].xovisData?.v5Data?.sceneGeometries?.length;
                            }
                            break;
                        case sensorTypes.VIVOTEK:
                            numberOfLines =
                                availableBundlesByIPointId[item.id].vivotekCountLinesByLineName &&
                                Object.keys(availableBundlesByIPointId[item.id].vivotekCountLinesByLineName).length;
                            break;
                        case sensorTypes.BRICKSTREAM:
                            numberOfLines =
                                availableBundlesByIPointId[item.id].sensorData?.cfg?.layers?.passWays?.length;
                            break;
                        case sensorTypes.TD:
                            numberOfLines = availableBundlesByIPointId[item.id].tdData?.layers?.pass_ways?.length;
                            break;

                        case sensorTypes.MEGACOUNT:
                            numberOfLines = availableBundlesByIPointId[item.id].megacountData?.layers?.passWays?.length;
                            break;

                        default:
                            break;
                    }
                }

                return (
                    <ColumnWrapper>
                        <NumberOfLines>{numberOfLines}</NumberOfLines>
                    </ColumnWrapper>
                );
            }
            case 'dependencies': {
                if (isSensorsFetching) {
                    return (
                        <ColumnWrapper>
                            <Spinner size={SpinnerSize.large} />
                        </ColumnWrapper>
                    );
                }
                return (
                    <ColumnWrapper>
                        <Dependencies iPoint={item} />
                    </ColumnWrapper>
                );
            }
            case 'sensorAddress': {
                return (
                    <ColumnWrapper>
                        <Address iPoint={item} />
                    </ColumnWrapper>
                );
            }
            case 'sensorRelations':
                if (context.plStructureFetching) {
                    return (
                        <ColumnWrapper>
                            <Spinner sise={SpinnerSize.large} />
                        </ColumnWrapper>
                    );
                }

                const sensorId = availableBundlesByIPointId[item.id]?.id;

                return (
                    <ColumnWrapper>
                        <RelationsWrapper>
                            {Object.entries(context.sensorRelationsById[sensorId || ''] || {})?.map(([key, value]) => {
                                const relationsText = value.reduce((acc, relation) => {
                                    if (!acc) acc += relation.marker_and_name;
                                    else acc += `, ${relation.marker_and_name}`;
                                    return acc;
                                }, '');
                                return (
                                    <RelationTypeWrapper key={key}>
                                        <span>{key}:&nbsp;</span>
                                        <div>{relationsText}</div>
                                    </RelationTypeWrapper>
                                );
                            })}
                        </RelationsWrapper>
                    </ColumnWrapper>
                );
            case 'sensorMAC': {
                return (
                    <ColumnWrapper>
                        {availableBundlesByIPointId[item.id] && availableBundlesByIPointId[item.id].serial_number}
                    </ColumnWrapper>
                );
            }
            case 'sensorCountLines': {
                const interactions = passWaysAndIPointsRelationsByIPointMarker?.find(
                    (element) => element.floor === selectedFloor,
                )?.interactions[item.marker];
                const tooltipProps = {
                    onRenderContent: () => {
                        return (
                            <>
                                {interactions?.map((element, i) => (
                                    <PassWayAndIPointRealationTooltip
                                        key={i}
                                        countNumber={i + 1}
                                        relationInfo={element.info}
                                    />
                                ))}
                            </>
                        );
                    },
                };
                return (
                    <ColumnWrapper>
                        {isRealationsFetching ? (
                            <Spinner size={SpinnerSize.large} />
                        ) : interactions ? (
                            <TooltipHost tooltipProps={tooltipProps}>
                                <CustomIPointIcon size={25} iconName={'Relationship'} />
                            </TooltipHost>
                        ) : (
                            <div>no relations</div>
                        )}
                    </ColumnWrapper>
                );
            }
            case 'progress':
                if (isSensorsFetching) {
                    return (
                        <ColumnWrapper>
                            <Spinner size={SpinnerSize.large} />
                        </ColumnWrapper>
                    );
                }
                return (
                    <>
                        {availableBundlesByIPointId[item.id]?.isFetching ? (
                            <ColumnWrapper>
                                <Spinner size={SpinnerSize.large} />
                            </ColumnWrapper>
                        ) : (
                            <ProgressColumn selectedSensor={availableBundlesByIPointId[item.id]} />
                        )}
                    </>
                );

            // case 'testDataPush':
            //     if (isSensorsFetching) {
            //         return (
            //             <ColumnWrapper>
            //                 <Spinner size={SpinnerSize.large} />
            //             </ColumnWrapper>
            //         );
            //     }
            //     return (
            //         <TestDataPush
            //             selectedSensor={availableBundlesByIPointId[item.id]}
            //             iPoint={item}
            //             showErrorNotificationTrans={showErrorNotificationTrans}
            //             showSuccessNotificationTrans={showSuccessNotificationTrans}
            //             updateAvailableBoundleByIPointIdTrans={updateAvailableBoundleByIPointIdTrans}
            //             availableBundlesByIPointId={availableBundlesByIPointId}
            //         />
            //     );

            case 'update':
                if (isSensorsFetching) {
                    return (
                        <ColumnWrapper>
                            <Spinner size={SpinnerSize.large} />
                        </ColumnWrapper>
                    );
                }
                return (
                    <UploadUpdate
                        selectedSensor={availableBundlesByIPointId[item.id]}
                        iPoint={item}
                        uploadKey={accessKey}
                        updateSensorConfigurationThunkTrans={updateSensorConfigurationThunkTrans}
                        locationIdFromMapServiceLink={locationIdFromMapServiceLink}
                        updateAvailableBoundleByIPointIdTrans={updateAvailableBoundleByIPointIdTrans}
                        availableBundlesByIPointId={availableBundlesByIPointId}
                    />
                );

            default:
                return <ColumnWrapper>{fieldContent}</ColumnWrapper>;
        }
    };

    const renderRowHandler = (props) => {
        let flag = false;

        if (props.item.id === selectedInstalationPoint?.id) {
            flag = true;
        }

        return (
            <div>
                <DetailsRow {...props} styles={flag ? selectedRowStyles : rowStyles} />
            </div>
        );
    };

    const onRenderDetailsHeader = (headerProps, defaultRender) => {
        if (!defaultRender) return null;
        return (
            <Sticky
                stickyPosition={StickyPositionType.Header}
                isScrollSynced={true}
                stickyBackgroundColor="transparent"
            >
                <div>{defaultRender(headerProps)}</div>
            </Sticky>
        );
    };

    return (
        <ListWrapper>
            <ModalCreator
                title={t('Batch status')}
                isModalOpen={context.batchUrlUploadModalStatus.show}
                closeModal={closeBatchUrlUpdateModal}
            >
                <UrlBatchUpdateModal />
            </ModalCreator>
            <ModalCreator isModalOpen={open} closeModal={handleClose}>
                <BoundleInfoModal />
            </ModalCreator>
            <ModalCreator
                title={'Updating sensor uploading url'}
                isModalOpen={isUploadUrlsModalOpen}
                closeModal={() => setUploadUrlsModalOpen(false)}
            >
                <UpdateSensorsUploadUrlsModal />
            </ModalCreator>
            <IPointsHeaderWrapper>
                <IPointsTitle>
                    {t('Installation points')} ({filteredItems.length})
                </IPointsTitle>
                <StyledFilter placeholder={t('Filter')} onChange={filterHandler} value={filter} />
                <FollowIPointLabel>{t('Follow IPoint')}</FollowIPointLabel>
                <Toggle checked={followIPoint} onChange={followIPointHandler} styles={followIPointStyles} />
                <PrimaryButton onClick={() => setUploadUrlsModalOpen(true)}>{t('Change upload urls')}</PrimaryButton>
                {Boolean(context.selectedInstallationPoints.length) && (
                    <SelectedIPointsButtonsWrapper>
                        <PrimaryButton onClick={onBatchSensorsUpdate} text={t('Batch update')} />
                        <PrimaryButton onClick={onLoadSelectedSensorsData} text={t('Load selected sensors data')} />
                    </SelectedIPointsButtonsWrapper>
                )}

                {Boolean(Object.keys(extendedSelectedIPointsById).length) && (
                    <DefaultButton onClick={openBatchUrlUpdateModal} text={t('Batch status')} />
                )}
            </IPointsHeaderWrapper>
            <ListContainer>
                <DetailsList
                    onRenderDetailsHeader={onRenderDetailsHeader}
                    selectionMode={
                        Object.values(context.sensorsById).some((sensor) => Boolean(sensor.isConfigurationFetching)) ||
                        !Object.keys(availableBundlesByIPointId || {}).length
                            ? 0
                            : 2
                    }
                    onRenderItemColumn={renderItemColumn}
                    onColumnHeaderClick={onColumnClick}
                    onItemInvoked={onRowClickHandler}
                    onRenderRow={renderRowHandler}
                    selection={selection}
                    items={filteredItems}
                    columns={columns}
                    componentRef={listRef}
                />
            </ListContainer>
        </ListWrapper>
    );
});

export default InstalationPointsList;
