export const sensorsListColumns = [
    {
        key: 'sensorIp',
        name: 'Ip',
        fieldName: 'sensorIp',
        isResizable: true,
        minWidth: 100,
        maxWidth: 100,
        isSorted: true,
        isSortedDescending: false,
    },
    {
        key: 'id',
        name: 'Id',
        fieldName: 'id',
        isResizable: true,
        minWidth: 50,
        maxWidth: 50,
    },
    {
        key: 'port',
        name: 'Port',
        fieldName: 'port',
        isResizable: true,
        minWidth: 50,
        maxWidth: 50,
    },
    {
        key: 'serial_number',
        name: 'Serial number',
        fieldName: 'serial_number',
        isResizable: true,
        minWidth: 100,
        maxWidth: 150,
    },
    {
        key: 'sensor_type',
        name: 'Type',
        fieldName: 'sensor_type',
        isResizable: true,
        minWidth: 50,
        maxWidth: 75,
    },
    {
        key: 'active',
        name: 'Active',
        fieldName: 'active',
        isResizable: true,
        minWidth: 50,
        maxWidth: 50,
    },
    {
        key: 'online',
        name: 'Online',
        fieldName: 'online',
        isResizable: true,
        minWidth: 50,
        maxWidth: 50,
    },
    {
        key: 'update',
        name: 'Update',
        fieldName: 'update',
        isResizable: true,
        minWidth: 75,
        maxWidth: 75,
    },
];
