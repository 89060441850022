import { cloneDeep } from 'lodash';
import { sensorTypes } from '../../../../constants/sensorTypes';
import getHikvisionScreen from '../hikvision/getHikvisionScreen';
import getVivotekScreen from '../vivotek/getVivotekScreen';
import getXovisScreen from '../xovis/getXovisScreen';
import getTdScreen from '../td/getTdScreen';
import getMegacountScreen from '../megacount/getMegacountScreen';

/**
 * Функция для получения скриншотов с датчиков
 * @param {object} sensorsForManagementById объект всех сенсоров, где ключ это id сенсора
 * @param {array} selectedSensors массив выбранных сенсоров
 * @param {number} timeout таймаут
 * @returns обновленный объект сенсоров, где есть скрин
 */
const getScreensFromSensors = async (options) => {
    const { selectedSensors, sensorsForManagementById, timeout } = options;
    const copy = cloneDeep(sensorsForManagementById);
    const screenPromises = [];

    selectedSensors.forEach((element) => {
        const sensor = copy[element.id];
        if (sensor.errors.length === 0) {
            switch (sensor.type) {
                case sensorTypes.XOVIS:
                    screenPromises.push(getXovisScreen(sensor, timeout));
                    break;
                case sensorTypes.VIVOTEK:
                    screenPromises.push(getVivotekScreen(sensor, timeout));
                    break;

                case sensorTypes.HIKVISION:
                    screenPromises.push(getHikvisionScreen(sensor, timeout));
                    break;

                case sensorTypes.TD:
                    screenPromises.push(getTdScreen(sensor, timeout));
                    break;

                case sensorTypes.MEGACOUNT: {
                    screenPromises.push(
                        getMegacountScreen({
                            ip: sensor.ip,
                            port: sensor.port,
                        }).then((response) => ({ ...response, sensor })),
                    );
                    break;
                }

                default:
                    screenPromises.push(
                        new Promise((resolve) =>
                            resolve({
                                error: 'Invalid sensor type',
                                sensor: { ...sensor },
                            }),
                        ),
                    );
                    break;
            }
        } else {
            screenPromises.push(
                new Promise((resolve) =>
                    resolve({
                        error: 'Get screen error',
                        sensor: { ...sensor },
                    }),
                ),
            );
        }
    });

    // Получение скринов сенсоров
    await Promise.all(screenPromises).then((responses) => {
        responses.forEach((response) => {
            if (!response.error) {
                switch (response.sensor.type) {
                    case sensorTypes.XOVIS:
                        copy[response.sensor.id] = {
                            ...response.sensor,
                            screen: response.screen,
                            multiSensorScreen: response.multiSensorScreen,
                        };
                        break;
                    case sensorTypes.VIVOTEK:
                        copy[response.sensor.id] = {
                            ...response.sensor,
                            screenScale: {
                                x: response.dimensions.width / 10000,
                                y: response.dimensions.height / 10000,
                            },
                            screen: response.screen,
                        };
                        break;

                    case sensorTypes.HIKVISION:
                        copy[response.sensor.id] = {
                            ...response.sensor,
                            screenScale: {
                                x: response.dimensions.width / response.sensor.hikvisionData.normalizedScreenSize.width,
                                y:
                                    response.dimensions.height /
                                    response.sensor.hikvisionData.normalizedScreenSize.height,
                            },
                            screen: response.screen,
                        };
                        break;

                    case sensorTypes.TD:
                        copy[response.sensor.id] = {
                            ...response.sensor,
                            screen: response.screen,
                        };
                        break;

                    case sensorTypes.MEGACOUNT: {
                        copy[response.sensor.id] = {
                            ...response.sensor,
                            screen: response.screen,
                            screenScale: response.screenScale,
                        };
                        break;
                    }

                    default:
                        break;
                }
            } else {
                copy[response.sensor.id] = {
                    ...response.sensor,
                    errors: [...response.sensor.errors, response.error],
                    dataFetching: false,
                };
            }
        });
    });

    return copy;
};

export default getScreensFromSensors;
