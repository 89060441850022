import axios from 'axios';

/**
 * GET запрос для получения данных от вивотек
 * @param {string} url адрес для запроса
 * @param {string} username имя пользователя
 * @param {string} password пароль
 * @param {number} timeout таймаут
 * @returns data || error
 */
export const vivotekGetRequest = (options) => {
    const { url, username, password, timeout } = options;
    return axios
        .get(url, {
            timeout,
            auth: {
                username,
                password,
            },
        })
        .then((response) => response.data)
        .then((data) => ({ data }))
        .catch((error) => ({ error: error.message }));
};
