import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { postRequest } from './api';
import { urls } from './constants/urls';
import { ITasksMonitoringState, IPlTask, IProject, IExtendedPlTask, IExtendedSensorsByPlId } from './interfaces';
import { AppThunk } from './store';
import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';
import updateProjectTimeIntervalTasks from './tools/reducerHelpers/updateProjectTimeIntervalTasks';

const initialState: ITasksMonitoringState = {
    token: '',
    tasksFetching: false,
    plTasksInitialData: [],
    projectTasksByProjectId: {},
    tasksFetchingError: '',
    selectedProjectId: null,
    extendedPlTasksByProjectId: {},
    extendedSensorsByPlId: {},
    selectedPlId: null,
    tasksDateFrom: DateTime.now().minus({ days: 1 }).toFormat('yyyy-MM-dd'),
    tasksDateTo: DateTime.now().toFormat('yyyy-MM-dd'),
};

export const TasksMonitoringReducer = createSlice({
    name: 'TasksMonitoringReducer',
    initialState,
    reducers: {
        /**
         * Запись токена в стор
         */
        storeToken: (state, action: PayloadAction<string>) => {
            if (action.payload) state.token = action.payload;
        },
        /**
         * Изменение флага полуения задач
         */
        toggleTasksFetching: (state, action: PayloadAction<boolean>) => {
            state.tasksFetching = action.payload;
        },
        /**
         * Запись данных полученных от лямбды аггрегации таксов
         */
        storePlTasksInitialData: (state, action: PayloadAction<IPlTask[]>) => {
            state.plTasksInitialData = action.payload;
        },
        /**
         * Запись данных о тасках
         */
        storeProjectsTasks: (state, action: PayloadAction<IPlTask[]>) => {
            const projectTasksByProjectId = action.payload.reduce((acc, value) => {
                if (value.pl_info) {
                    const projectId = value.pl_info.project_id;
                    let item: IProject = {
                        projectId,
                        projectName: value.pl_info.project_name,
                        plTasksByPlId: {},
                        plTasksCount: 0,
                        timeIntervalTasks: 0,
                        timeIntervalProcessedTasks: 0,
                        timeIntervalSuccessTasks: 0,
                    };

                    if (acc[projectId]) {
                        if (acc[projectId].plTasksByPlId[value.pl_id]) {
                            item = { ...acc[projectId] };
                            item.plTasksByPlId[value.pl_id].push(value);
                            updateProjectTimeIntervalTasks(item, value);
                        } else {
                            item = { ...acc[projectId] };
                            item.plTasksByPlId[value.pl_id] = [value];
                            item.plTasksCount += 1;
                            updateProjectTimeIntervalTasks(item, value);
                        }
                        acc[projectId] = item;
                    } else {
                        item.plTasksByPlId = {
                            [value.pl_id]: [value],
                        };
                        item.plTasksCount += 1;
                        updateProjectTimeIntervalTasks(item, value);

                        acc[projectId] = item;
                    }
                }

                return acc;
            }, {} as { [x: number]: IProject });

            state.projectTasksByProjectId = projectTasksByProjectId;
        },
        /**
         * Запись ошибки, которая была получена при получении задач ре-экспорта
         */
        storeTasksfetchingError: (state, action: PayloadAction<string>) => {
            state.tasksFetchingError = action.payload;
        },
        /**
         * Запись id выбранного проекта
         */
        storeSelectedProjectId: (state, action: PayloadAction<number | null>) => {
            state.selectedProjectId = action.payload;
        },
        /**
         * Запись расширенных таксов по проектным локациям сагрегированных по id проекта
         */
        storeExtendedPlTasksByProjectId: (state) => {
            const projectTasksByProjectId = cloneDeep(state.projectTasksByProjectId);
            const result: { [x: number]: { [x: number]: IExtendedPlTask } } = {};
            Object.keys(projectTasksByProjectId).forEach((projectId) => {
                const plTasksByPlId: { [x: number]: IPlTask[] } = projectTasksByProjectId[+projectId].plTasksByPlId;
                const plTasksByPlIdKeys: string[] = Object.keys(plTasksByPlId);

                plTasksByPlIdKeys.forEach((plId) => {
                    const aggregatedPlTask: IExtendedPlTask = {
                        plId: plTasksByPlId[+plId][0].pl_id,
                        name: plTasksByPlId[+plId][0].pl_info?.name,
                        dates: [],
                        uploadingProgressLogUrls: {},
                        plInfo: plTasksByPlId[+plId][0].pl_info,
                        processedTasks: [],
                        sensorTasks: [],
                        timeIntervalTasks: 0,
                        timeIntervalProcessedTasks: 0,
                        timeIntervalSuccessTasks: 0,
                    };
                    plTasksByPlId[+plId].forEach((plTask) => {
                        aggregatedPlTask.dates.push(plTask.date);
                        aggregatedPlTask.uploadingProgressLogUrls[plTask.date] = plTask.uploading_progress_log_url;
                        plTask.processed_tasks && aggregatedPlTask.processedTasks.push(...plTask.processed_tasks);

                        aggregatedPlTask.sensorTasks.push(...plTask.sensor_tasks);
                    });

                    aggregatedPlTask.timeIntervalTasks = aggregatedPlTask.sensorTasks.length;
                    aggregatedPlTask.timeIntervalProcessedTasks = aggregatedPlTask.processedTasks.length;
                    aggregatedPlTask.timeIntervalSuccessTasks = aggregatedPlTask.processedTasks.filter(
                        (task) => task.status === 'success',
                    ).length;

                    result[+projectId] = {
                        ...result[+projectId],
                        [aggregatedPlTask.plId]: aggregatedPlTask,
                    };
                });
            });

            state.extendedPlTasksByProjectId = result;
        },
        /**
         * Запись информации о сенсорах, сашшрегированных по id проектной локации
         */
        storeExtendedSensorsByPlId: (state) => {
            const projectTasksByProjectId = cloneDeep(state.projectTasksByProjectId);
            let result = {} as IExtendedSensorsByPlId;
            Object.keys(projectTasksByProjectId).forEach((projectId) => {
                const plTasksByPlId: { [x: number]: IPlTask[] } = projectTasksByProjectId[+projectId].plTasksByPlId;
                const plTasksByPlIdKeys: string[] = Object.keys(plTasksByPlId);
                plTasksByPlIdKeys.forEach((plId) => {
                    const plSensorsByPlId = {} as IExtendedSensorsByPlId;
                    plTasksByPlId[+plId].forEach((plTask) => {
                        plTask.sensor_tasks.forEach((sensorTask) => {
                            const { ip, port, type, pass, user, date_from, date_to } = sensorTask;
                            const sensorKey = `${ip}:${port}_${type}_${pass}`;
                            const currentProcessedTask = plTask.processed_tasks?.find((processedTask) => {
                                if (
                                    ip === processedTask.ip &&
                                    port === processedTask.port &&
                                    pass === processedTask.pass &&
                                    date_from === processedTask.date_from &&
                                    date_to === processedTask.date_to
                                ) {
                                    return true;
                                }
                                return false;
                            });

                            if (plSensorsByPlId[+plId] && plSensorsByPlId[+plId][sensorKey]) {
                                plSensorsByPlId[+plId][sensorKey].tasks.push(sensorTask);
                                plSensorsByPlId[+plId][sensorKey].timeIntervalTasks += 1;
                                if (currentProcessedTask) {
                                    plSensorsByPlId[+plId][sensorKey].timeIntervalProcessedTasks += 1;
                                    plSensorsByPlId[+plId][sensorKey].processedTasks.push(currentProcessedTask);
                                    if (currentProcessedTask.status === 'success') {
                                        plSensorsByPlId[+plId][sensorKey].timeIntervalSuccessTasks += 1;
                                        plSensorsByPlId[+plId][sensorKey].successTasks.push(currentProcessedTask);
                                    }
                                }
                            } else {
                                plSensorsByPlId[+plId] = {
                                    ...plSensorsByPlId[+plId],
                                    [sensorKey]: {
                                        ip,
                                        port,
                                        type,
                                        pass,
                                        user,
                                        timeIntervalTasks: 1,
                                        timeIntervalProcessedTasks: 0,
                                        timeIntervalSuccessTasks: 0,
                                        successTasks: [],
                                        tasks: [sensorTask],
                                        processedTasks: [],
                                    },
                                };
                                if (currentProcessedTask) {
                                    plSensorsByPlId[+plId][sensorKey].timeIntervalProcessedTasks = 1;
                                    plSensorsByPlId[+plId][sensorKey].processedTasks.push(currentProcessedTask);
                                    if (currentProcessedTask.status === 'success') {
                                        plSensorsByPlId[+plId][sensorKey].timeIntervalSuccessTasks = 1;
                                        plSensorsByPlId[+plId][sensorKey].successTasks.push(currentProcessedTask);
                                    }
                                }
                            }
                        });
                    });
                    result = {
                        ...result,
                        ...plSensorsByPlId,
                    };
                });
            });
            state.extendedSensorsByPlId = result;
        },
        /**
         * Запись id выбранной проектной локации
         */
        storeSelectedPlId: (state, action: PayloadAction<number | null>) => {
            state.selectedPlId = action.payload;
        },
        /**
         * Запись даты от которой получить задачи
         */
        storeTasksDateFrom: (state, action: PayloadAction<string>) => {
            state.tasksDateFrom = action.payload;
        },
        /**
         * Запись даты по которую получить задачи
         */
        storeTasksDateTo: (state, action: PayloadAction<string>) => {
            state.tasksDateTo = action.payload;
        },
        /**
         * Обнуляет редьюсер
         */
        resetTasksMonitoringReducer: (state) => {
            const token = cloneDeep(state.token);

            return { ...initialState, token };
        },
    },
});

export const {
    storeToken,
    toggleTasksFetching,
    storePlTasksInitialData,
    storeTasksfetchingError,
    storeProjectsTasks,
    storeSelectedProjectId,
    storeExtendedPlTasksByProjectId,
    resetTasksMonitoringReducer,
    storeExtendedSensorsByPlId,
    storeSelectedPlId,
    storeTasksDateFrom,
    storeTasksDateTo,
} = TasksMonitoringReducer.actions;

export default TasksMonitoringReducer.reducer;

/**
 * Thunk. Получает данные по ре-экспорту от лямбды
 */
export const fetchReexportTasks = (): AppThunk => async (dispatch, getState) => {
    const { token, tasksDateFrom, tasksDateTo, tasksFetchingError } = getState().TasksMonitoringReducer;

    if (!token) return;

    tasksFetchingError && dispatch(storeTasksfetchingError(''));

    if (new Date(tasksDateFrom) > new Date(tasksDateTo)) {
        dispatch(storeTasksfetchingError('date_from must be <= date_to'));
        return;
    }
    const payload = JSON.stringify({
        date_from: tasksDateFrom,
        date_to: tasksDateTo,
        x_token: token,
    });

    dispatch(toggleTasksFetching(true));
    const response = await postRequest({ url: urls['fpc-dm-aggregate-recovery-exports-tasks'], payload });
    dispatch(toggleTasksFetching(false));

    if (!response.error) {
        dispatch(storePlTasksInitialData(response));
        dispatch(storeProjectsTasks(response));
    } else {
        dispatch(storeTasksfetchingError(JSON.stringify(response.error)));
    }
};
