import axios from 'axios';
import { xovisProgressMessages } from '../../../../utils/xovisHelpers/xovisProgressMessages';
import getXovisInfo from './underV5Methods/getXovisInfo';
import getXovisInfoV5 from './v5Methods/getXovisInfoV5';
import getXovisData from './underV5Methods/getXovisData';
import getXovisDataV5 from './v5Methods/getXovisDataV5';
/**
 * Функция для создания промиса при получении серийников от датчиков
 * @param {object} sensor объект сенсора
 * @returns промис
 */
const getXovisSensorData = (sensor, uploadUrl) => {
    const errorResponse = {
        ...sensor,
        firstStepError: xovisProgressMessages.firstStepError,
        thirdStepError: xovisProgressMessages.firstStepError,
        isConfigurationFetching: false,
    };

    const info = getXovisInfo({ sensor });
    const infoV5 = getXovisInfoV5({ sensor });

    return axios
        .all([info, infoV5])
        .then(
            axios.spread(async (info, infoV5) => {
                if (info.error && infoV5.error) return { ...errorResponse };

                const xovisData = {
                    data: {},
                    v5Data: {},
                    version: null,
                    serialNumber: null,
                };

                let isMultiSensor = false;
                let multiSensorSlaves = [];

                if (!info.error) {
                    xovisData.version = info.version;
                    xovisData.serialNumber = info.serialNumber;
                }

                if (!infoV5.error) {
                    xovisData.version = infoV5.version;
                    xovisData.serialNumber = infoV5.serialNumber;
                }

                const version = Number(xovisData.version?.split('.')[0]);
                if (version < 5) {
                    const data = await getXovisData({ sensor, uploadUrl });

                    if (!data.error) {
                        xovisData.data = { ...data };
                        isMultiSensor = data.config?.isMultiSensor || false;
                        multiSensorSlaves = data.config?.multiSensorSlaves || [];
                    }
                } else if (version >= 5) {
                    const data = await getXovisDataV5({ sensor, uploadUrl });
                    if (!data.error) {
                        xovisData.v5Data = { ...data };
                        isMultiSensor = data.isMultiSensor || false;
                        multiSensorSlaves = data.multisensorSlaves.slaveIps || [];
                    }
                }
                return {
                    ...sensor,
                    isConfigurationFetching: false,
                    xovisData,
                    isMultiSensor,
                    multiSensorSlaves,
                };
            }),
        )
        .catch((_) => {
            return { ...errorResponse };
        });
};

export default getXovisSensorData;
