import { useEffect, useMemo, useState } from 'react';
import { Arrow, Group, Line } from 'react-konva';
import findLabelCoordinates from '../../../utils/findLabelCoordinates';
import { getArrowEndPoint } from '../../../utils/locationDisplay/getArrowEndPoint';
import scaleTransform from '../../../utils/locationDisplay/scaleTransform';
import ObjectLabel from '../../floorPlan/components/objectLabel';

/**
 * Компонент для отрисовки одного прохода
 * @param {array} coordinates массив координат
 * @param {number} passMargin ширина прохода в метрах
 * @param {number} planScale размер плана в масштабе писель на метр
 * @param {number} stageScale скейл канваса
 * @param {boolean} showPassWayLabel показать/скрыть лейбел для проходов
 * @param {boolean} reverse флаг отвечающий за направление прохода
 * @param {boolean} showPassWayLayer показать/скрыть слой
 * @param {string} marker маркер данного прохода
 */
const SinglePassWay = ({
    coordinates,
    passMargin = 0.4,
    planScale,
    stageScale = 1,
    showPassWayLabel = false,
    reverse = false,
    showPassWayLayer = true,
    marker = 'passWay',
}) => {
    const [points, setPoints] = useState([]);
    const [strokeWidth, setStrokeWidth] = useState(4);

    useEffect(() => (planScale ? setStrokeWidth(passMargin * planScale) : setStrokeWidth(4)), [passMargin, planScale]);

    useEffect(() => {
        const pointsArray = [];
        coordinates.forEach((element) => {
            pointsArray.push(...element);
        });
        setPoints(pointsArray);
    }, [coordinates]);

    const arrows = useMemo(() => {
        const result = [];
        coordinates.forEach((point, i, arr) => {
            if (i === 0) return;
            const center = [(point[0] + arr[i - 1][0]) / 2, (point[1] + arr[i - 1][1]) / 2];
            const arrowEndPoint = getArrowEndPoint(point, i, arr, stageScale, reverse);
            result.push(
                <Arrow
                    key={`${i}--${point[0]}`}
                    points={[center[0], center[1], arrowEndPoint[0], arrowEndPoint[1]]}
                    stroke='red'
                    strokeWidth={scaleTransform(3, stageScale)}
                    pointerWidth={scaleTransform(8, stageScale)}
                    pointerLength={scaleTransform(16, stageScale)}
                />,
            );
        });
        return result;
    }, [coordinates, stageScale, reverse]);

    const labelCoords = useMemo(() => findLabelCoordinates(coordinates), [coordinates]);
    return (
        <>
            {showPassWayLayer && (
                <Group>
                    <Line
                        points={points}
                        stroke={'rgba(255, 0, 0, 0.4)'}
                        strokeWidth={strokeWidth}
                        lineJoin={'round'}
                        lineCap={'round'}
                    />
                    {arrows}

                    {showPassWayLabel && (
                        <ObjectLabel
                            x={labelCoords.x}
                            y={labelCoords.y}
                            marker={marker}
                            stageScale={stageScale}
                            opacity={1}
                        />
                    )}
                </Group>
            )}
        </>
    );
};

export default SinglePassWay;
