import styled from 'styled-components/macro';

export const DatePickersWrapper = styled.div`
    display: flex;
`;

export const MainWrapper = styled.div`
    padding: 10px;
    display: flex;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;
    > div {
        height: 100%;
        display: flex;
        flex-direction: column;
    }
`;

export const pivotItemContainerStyles = {
    itemContainer: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        selectors: {
            '>div': {
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                marginTop: '10px',
            },
        },
    },
};
