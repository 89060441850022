import { DateTime } from 'luxon';

/**
 * Функция для генерации данных для пакетного запроса
 * @param {number} dateFromInMillis date_from
 * @param {number} dateToInMillis date_to
 * @param {object} sensor объект сенсора
 * @return объект с данными для пакетного запроса
 */
const generateXovisDataForUploadRequestV5 = (args) => {
    const { sensor, dateFromInMillis, dateToInMillis } = args;

    const utcDateFrom = DateTime.fromMillis(dateFromInMillis).toUTC().toISO();
    const utcDateTo = DateTime.fromMillis(dateToInMillis).toUTC().toISO();

    const dataForPost = {
        type: 'TIME_RANGE',
        time_from: utcDateFrom,
        time_to: utcDateTo,
    };
    const data = {
        url: `http://${sensor.ip}:${sensor.port}/api/v5/${
            sensor.isMultiSensor ? 'multisensor' : 'singlesensor'
        }/data/push/agents/${sensor.xovisData?.v5Data?.companyAgent?.id}/trigger`,
        initialData: sensor,
        dataForPost,
        extraArgs: {
            dateInterval: {
                dateFrom: dateFromInMillis,
                dateTo: dateToInMillis,
            },
        },
        isAuth: true,
        user: sensor.user,
        pass: sensor.pass,
    };

    return data;
};

export default generateXovisDataForUploadRequestV5;
