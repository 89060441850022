/**
 * словарик для перевода
 */
const translations = {
    hello: 'привет',
    Projects: 'Проекты',
    'Project Locations': 'Локации проекта',
    'Instalation points': 'Точки установки',
    Screenshots: 'Скриншоты',
    Sensors: 'Датчики',
    Filter: 'Фильтр',
    'Add sensor': 'Добавить сенсор',
    'Batch upload': 'Пакетная загрузка',
    'IP address': 'IP адресс',
    'Copy link': 'Скопировать',
    Update: 'Обновить',
    'Choose datetime': 'Выбор даты/времени',
    'Load selected sensors data': 'Загрузить данные для выбранных датчиков',
};

export default translations;
