export const screenUploadColumns = [
    {
        key: 'sensorIp',
        name: 'Ip',
        fieldName: 'sensorIp',
        minWidth: 100,
        maxWidth: 100,
        isSorted: true,
        isSortedDescending: false,
        onColumnClick: true,
    },
    {
        key: 'port',
        name: 'Port',
        fieldName: 'port',
        minWidth: 60,
        maxWidth: 60,
        onColumnClick: true,
    },
    {
        key: 'managementSType',
        name: 'Type',
        fieldName: 'managementSType',
        minWidth: 100,
        maxWidth: 100,
        onColumnClick: true,
    },
    {
        key: 'pass',
        name: 'Password',
        fieldName: 'pass',
        minWidth: 100,
        maxWidth: 100,
        onColumnClick: true,
    },
    {
        key: 'user',
        name: 'Username',
        fieldName: 'user',
        minWidth: 100,
        maxWidth: 100,
        onColumnClick: true,
    },
    {
        key: 'screenStatus',
        name: 'Status',
        fieldName: 'screenStatus',
        minWidth: 200,
        maxWidth: 200,
        onColumnClick: true,
    },
];
