import { xovisPostRequest } from '../../../../../api/xovis/xovis.api';

/**
 * Создание агента выгрузки
 * @param {object} sensor объект сенсора
 * @param {object} payload
 * @returns Объект с ошибкой или нового агента
 */
const postXovisAgentV5 = async ({ sensor, payload }) => {
    const newAgent = await xovisPostRequest({
        url: `http://${sensor.ip}:${sensor.port}/api/v5/${
            sensor.isMultiSensor ? 'multisensor' : 'singlesensor'
        }/data/push/agents`,
        username: sensor.username,
        password: sensor.password,
        body: payload,
    });

    if (!newAgent.error) {
        return newAgent;
    }

    return { error: 'Ann error accrued while post new agent' };
};

export default postXovisAgentV5;
