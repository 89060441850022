import { isNumber } from 'lodash';
import { tdProgressMessages } from '../../../../utils/tdSensorHelpers/tdProgressMessages';

/**
 * Вспомогательная функция для создания связки с сенсоров TD
 * @param {object} iPoint объект точки установки
 * @param {object} sensor объект сенсора
 * @param {object} currentBoundle объект текущей связки
 * @param {string} accessKey ключ для выгрузки данных
 */
const getBundleForTdHelper = (options) => {
    const { iPoint, sensor, currentBoundle, accessKey, uploadUrl } = options;

    let thirdStepError = '';
    let secondStepError = '';

    /**
     * Проверка 3 шага валидации
     */
    if (isNumber(sensor.tdData?.serviceConfig?.companyServerId)) {
        const companyServerId = sensor.tdData.serviceConfig.companyServerId;
        const companyServer = sensor.tdData.serviceConfig.reportServersById?.[companyServerId];

        const companyServerSettings = sensor.tdData.serviceConfig.companyServerUploadSettings;
        const CORRECT_URL = `${uploadUrl?.protocol}://${uploadUrl?.host}/fpc/v1/${sensor.sensor_type}/${accessKey}`;
        const sensorUrl = `${companyServer?.reportServerAddress}${companyServer?.reportServerDirectory}`;

        if (!companyServer?.isActive) {
            thirdStepError = tdProgressMessages.thirdStepInactive;
        }
        if (CORRECT_URL !== sensorUrl) {
            thirdStepError = tdProgressMessages.thirdStepErrorWithNoMatchUrls;
        }

        if (companyServerSettings?.deliveryProtocol !== 'HTTPS') {
            thirdStepError += ` ${tdProgressMessages.thirdStepErrorWithDeliveryProtocol}`;
        }

        if (companyServerSettings?.recordCycle !== '1') {
            thirdStepError += ` ${tdProgressMessages.thirdStepInvalidRecordCycle}`;
        }

        if (companyServerSettings?.uploadCycle !== '15') {
            thirdStepError += ` ${tdProgressMessages.thirdStepInvalidUploadCycle}`;
        }
    } else {
        if (sensor.firstStepError) {
            thirdStepError = tdProgressMessages.firstStepError;
        } else {
            thirdStepError = tdProgressMessages.thirdStepErrorNoCompanyUrl;
        }
    }

    /**
     * Проверка второго шага валидации
     */
    if (sensor.tdData?.layers?.pass_ways?.length) {
        let matchingPoints = 0;
        sensor.tdData.layers.pass_ways.forEach((element) => {
            Object.keys(iPoint.passPoints).forEach((point) => {
                if (iPoint.passPoints?.[point]?.lineName === element.marker) {
                    matchingPoints++;
                }
            });
        });

        if (!(matchingPoints >= Object.keys(iPoint.passPoints).length)) {
            secondStepError = tdProgressMessages.secondStepError;
        }
    } else {
        secondStepError = tdProgressMessages.secondStepNoCountLines;
    }

    return {
        iPointId: iPoint.id,
        value: {
            ...sensor,
            isFetching: sensor.isConfigurationFetching,
            current: true,
            boundleId: currentBoundle.id,
            thirdStepError,
            secondStepError,
        },
    };
};

export default getBundleForTdHelper;
