import csvDataWithDateFieldsHandler from './csvDataWithDateFieldsHandler';
import portionSensorDataUpload from './portionSensorDataUpload';

/**
 * Распределяющая функция в зависимости от наличия дат в csv данных
 * @param {boolean} isCSVDataIncludesDateFields флаг, отвечающий за наличие дат в csv данных
 * @param {object} sensorsForManagementByIdCopy объект сенсоров, гдю ключ это id сенсора из csv (генерируется на фронте)
 * @param {number} portionSize размер порции для выгрузки с датчика
 * @param {object} selectedSensorsCopyByIpPort выбранные сенсоры, где ключ это адресс сенсора, а его значение это массив всех сенсоров с таким ip и port
 * @param {number} dateToInMillis date to в милисекундах
 * @param {number} dateFromInMillis date from в милисекундах
 * @param {array} selectedSensorsCopy массив выбранных сенсоров
 * @param {number} timeout таймаут
 * @returns promise
 */
const portionHandler = async (options) => {
    const {
        isCSVDataIncludesDateFields,
        sensorsForManagementByIdCopy,
        portionSize,
        selectedSensorsCopyByIpPort,
        dateToInMillis,
        dateFromInMillis,
        selectedSensorsCopy,
        timeout,
    } = options;
    if (isCSVDataIncludesDateFields && !(dateToInMillis && dateFromInMillis)) {
        return await csvDataWithDateFieldsHandler({
            sensorsForManagementById: sensorsForManagementByIdCopy,
            portionSize,
            selectedSensorsCopyByIpPort,
            timeout,
        });
    } else {
        return await portionSensorDataUpload({
            dateToInMillis,
            dateFromInMillis,
            portionSize,
            selectedSensorsCopy,
            sensorsForManagementById: sensorsForManagementByIdCopy,
            timeout,
        });
    }
};

export default portionHandler;
