import idGenerator from '../idGenerator';

/**
 * Функция для объеденения данных из csv строки и из csv файла. Так же функция сравнивает элементы из csv данных с
 * ошибками, если элемент совпабает с ошибками, то он не включается в конечный результат
 * @param {array} fileData данные из csv файла
 * @param {array} stringData данные из csv строки
 * @param {array} errorsArray массив с ошибками при парсинге csv источников
 * @returns Возвращает объект объеденных данных, где ключ это id каждого пакета
 */
const fileAndStringDataConsolidation = (fileData, stringData, errorsArray) => {
    const combinedData = [];
    const stringFromErrors = JSON.stringify(errorsArray);

    if (!fileData && !stringData) return null;

    if (fileData) {
        fileData.forEach((element) => {
            if (!stringFromErrors.includes(JSON.stringify(element)) && element.ip && element.port && element.type && element.pl_id) combinedData.push({ ...element });
        });
    }

    if (stringData) {
        stringData.forEach((element) => {
            if (!stringFromErrors.includes(JSON.stringify(element)) && element.ip && element.port && element.type && element.pl_id) combinedData.push({ ...element });
        });
    }

    const initialData = Array.from(new Set(combinedData.map((item) => JSON.stringify(item)))).map((element) => JSON.parse(element));

    const extendedData = initialData.reduce((acc, element) => {
        const id = idGenerator();
        acc[id] = {
            id,
            ...element,
            type: element.type.toLowerCase(),
            serialNumberFromSensor: '',
            sensorDataFetching: true,
            sensorDataFetchingError: '',
            postSensorFetching: false,
            postBoundleFetching: false,
            postSensorError: '',
            postBoundleError: '',
        };
        return acc;
    }, {});

    return { initialData, extendedData };
};

export default fileAndStringDataConsolidation;
