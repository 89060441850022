import { cloneDeep } from 'lodash';
import { SENSOR_CLASSES_BY_TYPE } from '../../../../constants/sensorTypes';
import { DefaultCompanyInfo } from '../../../../shared/company-info';

/**
 * Функция для обработки ошибочных интервалов brickstream
 * @param {object} sensor объект сенсора
 * @param {array} failedIntervals все ошибочные интерваалы
 * @param {number} timeout таймаут
 * @returns возвращает обновленный объеккт сенсора
 */
const processBrickstreamFailedIntervals = async (options) => {
    const { failedIntervals, sensor, timeout } = options;
    let sensorCopy = cloneDeep(sensor);

    for (let interval of failedIntervals) {
        const instance = new SENSOR_CLASSES_BY_TYPE[sensor.type](
            sensor.ip,
            sensor.port,
            sensor.type,
            sensor.user,
            sensor.pass,
            new DefaultCompanyInfo(),
            timeout / 1000,
        );
        const result = await instance?.resendData(interval.dateFrom, interval.dateTo);
        sensorCopy.successOrderCount += result.successOrderCount;
        sensorCopy.errors.push(...result.errors);
    }
    return { sensor: { ...sensorCopy } };
};

export default processBrickstreamFailedIntervals;
