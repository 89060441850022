import { TooltipHost } from '@fluentui/react';
import { hikvisionProgressMessages } from '../../../../../../../../utils/hikvisionHelpers/hikvisionProgressMessages';
import { Circle, CircleWrapper, ColumnWrapper } from '../../instalationPointsList.styles';

/**
 *  Компонент для отрисовки прогресс бара для датчика HIKVISION
 * @param {object} selectedSensor объект выбранного сенсора
 */
const HikvisionProgressColumn = ({ selectedSensor }) => {
    return (
        <>
            <ColumnWrapper>
                <CircleWrapper>
                    <TooltipHost
                        content={
                            selectedSensor
                                ? !selectedSensor.firstStepError
                                    ? hikvisionProgressMessages.firstStepEnabled
                                    : selectedSensor.firstStepError
                                : null
                        }
                    >
                        <Circle
                            newColor={
                                selectedSensor
                                    ? selectedSensor.isFetching
                                        ? 'grey'
                                        : !selectedSensor.firstStepError
                                        ? 'green'
                                        : 'red'
                                    : 'grey'
                            }
                        >
                            1
                        </Circle>
                    </TooltipHost>
                </CircleWrapper>
                <CircleWrapper>
                    <TooltipHost
                        content={
                            selectedSensor
                                ? !selectedSensor.thirdStepError
                                    ? hikvisionProgressMessages.thirdStepEnabled
                                    : selectedSensor.thirdStepError
                                : null
                        }
                    >
                        <Circle newColor={selectedSensor ? (!selectedSensor.thirdStepError ? 'green' : 'red') : 'grey'}>
                            2
                        </Circle>
                    </TooltipHost>
                </CircleWrapper>
            </ColumnWrapper>
        </>
    );
};

export default HikvisionProgressColumn;
