import getMegacountNetworkSettings from '../../../components/countingSensors/tools/megacount/getMegacountNetworkSettings';

/**
 * Вспомогательная функция для получения серийного номера от сенсора, при пакетной загрузке данных
 * @param {object} singleDataPackage элемент из csv файла или csv строки
 * @returns object с ошибкой или с сериным номером и флагом о различии серийников
 */
const getMegacountSerialNumberForTable = async (options) => {
    const { singleDataPackage } = options;
    let notEqualSerialNumber = false;
    let serialNumber = '';

    const networkSettings = await getMegacountNetworkSettings({
        ip: singleDataPackage.ip,
        port: singleDataPackage.port,
        username: singleDataPackage.user,
        password: singleDataPackage.pass,
    });

    if (!networkSettings.error) {
        serialNumber = networkSettings.current.mac;

        if (
            singleDataPackage.serial_number &&
            serialNumber.toLowerCase() !==
                singleDataPackage.serial_number.toLowerCase()
        ) {
            notEqualSerialNumber = true;
        }

        return {
            id: singleDataPackage.id,
            serialNumber,
            notEqualSerialNumber,
        };
    }
    return { id: singleDataPackage.id, error: networkSettings.error };
};

export default getMegacountSerialNumberForTable;
