import { rstatPostRequest } from '../../../api/rstat/rstat.api';
import getRstatMac from '../../rstatHelpers/getRstatMac';

/**
 * Вспомогательная функция для получения серийного номера от сенсора, при пакетной загрузке данных
 * @param {object} singleDataPackage элемент из csv файла или csv строки
 */
const getRstatSerialNumberForTable = async (options) => {
    const { singleDataPackage } = options;
    let serialNumber = '';
    let notEqualSerialNumber = false;

    return rstatPostRequest({
        url: `http://${singleDataPackage.ip}:${singleDataPackage.port}/sys_inf.php`,
        payload: JSON.stringify({
            login: singleDataPackage.user,
            password: singleDataPackage.pass,
        }),
    }).then((res) => {
        if (!res.error) {
            serialNumber = getRstatMac(res);
            if (
                singleDataPackage.serial_number &&
                serialNumber.toLowerCase() !==
                    singleDataPackage.serial_number.toLowerCase()
            )
                notEqualSerialNumber = true;
            return {
                id: singleDataPackage.id,
                serialNumber,
                notEqualSerialNumber,
            };
        } else {
            return {
                id: singleDataPackage.id,
                error: res.error,
            };
        }
    });
};

export default getRstatSerialNumberForTable;
