import { cloneDeep } from 'lodash';
import { Fragment } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { Circle, Group, Line } from 'react-konva';
import scaleTransform from '../../../utils/locationDisplay/scaleTransform';
import ObjectLabel from '../../floorPlan/components/objectLabel';
/**
 * Компонент для отрисовки сенсора на конвасе
 * @param {array} centerPoint координаты центрального круга сенсора
 * @param {array} passPoints массив "усов" сенсора
 * @param {array} coordinates массив координат, для отображения на канвасе
 * @param {number} stageScale скейл канваса
 * @param {object} selectedInstalationPoint объект выбранного сенсора
 * @param {function} setSelectedInstalationPointTrans функция для изменения выбранного сенсора
 * @param {string} id id сенсора
 * @param {string} marker маркер сенсора
 * @param {boolean} showLabel флаг, чтобы показывать/скрывать лебел
 * @param {null || string} followedBy показывает является ли этот сенсор "рабом" мультисенсора
 * @param {function} setInstalationPointsForCanvasTrans  функция для записи сенсоров на выбранном этаже
 * @param {array} instalationPointsForCanvas  массив всех сенсоров на выбраном этаже
 * @param {number} planScale  разрешение плана локации размера пиксель/метр
 * @param {object} iPoint  сама точка установки
 */
const SingleSensor = ({
    centerPoint,
    passPoints,
    stageScale,
    selectedInstalationPoint,
    setSelectedInstalationPointTrans,
    id,
    marker,
    showIPointLabel,
    followedBy,
    setInstalationPointsForCanvasTrans,
    instalationPointsForCanvas,
    planScale,
    iPoint,
}) => {
    const [opacity, setOpacity] = useState(0.5);
    useEffect(() => {
        if (selectedInstalationPoint?.id === id) {
            setOpacity(1);
            const copyArray = instalationPointsForCanvas.slice();
            const index = copyArray.findIndex((element) => element.id === id);
            if (index !== copyArray.length - 1) {
                copyArray.splice(index, 1);
                copyArray.push(instalationPointsForCanvas[index]);

                setInstalationPointsForCanvasTrans(copyArray);
            }
        } else {
            setOpacity(0.5);
        }
    }, [selectedInstalationPoint, id, instalationPointsForCanvas, setInstalationPointsForCanvasTrans]);

    const sensorClickHandler = () => {
        const sensor = cloneDeep(iPoint);
        setSelectedInstalationPointTrans(sensor);
    };

    const passEnd = useMemo(() => {
        return Object.keys(passPoints).map((key) => {
            const point = passPoints[key];
            return (
                <Fragment key={key}>
                    <Line
                        points={[
                            centerPoint.coordinates[0],
                            centerPoint.coordinates[1],
                            point.geometry.coordinates[0],
                            point.geometry.coordinates[1],
                        ]}
                        stroke={opacity === 1 ? 'black' : '#464646'}
                        strokeWidth={2}
                    />
                    <Circle
                        x={point.geometry.coordinates[0]}
                        y={point.geometry.coordinates[1]}
                        radius={0.07 * planScale}
                        fill={opacity === 1 ? 'black' : '#464646'}
                    />
                </Fragment>
            );
        });
    }, [centerPoint, passPoints, planScale, opacity]);

    const slaveLeash = useMemo(() => {
        if (followedBy === null) {
            return null;
        } else {
            const masterCoords = instalationPointsForCanvas?.filter((item) => item.marker === followedBy)[0]
                ?.centerPoint.coordinates;
            return masterCoords ? (
                <Line
                    points={[centerPoint.coordinates[0], centerPoint.coordinates[1], masterCoords[0], masterCoords[1]]}
                    stroke='#aaa'
                    strokeWidth={1}
                    dash={[5, 5]}
                />
            ) : null;
        }
        // eslint-disable-next-line
    }, [centerPoint, followedBy]);

    const centralCircle = useMemo(() => {
        return (
            <>
                <Circle
                    x={centerPoint.coordinates[0]}
                    y={centerPoint.coordinates[1]}
                    radius={scaleTransform(10, stageScale)}
                    fill={opacity === 1 ? (followedBy ? '#00bcf2' : '#ffa500') : followedBy ? '#b2eeff' : '#af7100'}
                    strokeWidth={scaleTransform(2, stageScale)}
                />
                <Circle
                    x={centerPoint.coordinates[0] + scaleTransform(5, stageScale)}
                    y={centerPoint.coordinates[1]}
                    radius={scaleTransform(3, stageScale)}
                    fill={'white'}
                    strokeWidth={scaleTransform(1, stageScale)}
                />
                <Circle
                    x={centerPoint.coordinates[0] - scaleTransform(5, stageScale)}
                    y={centerPoint.coordinates[1]}
                    radius={scaleTransform(3, stageScale)}
                    fill={'white'}
                    strokeWidth={scaleTransform(1, stageScale)}
                />
            </>
        );
        // eslint-disable-next-line
    }, [stageScale, opacity, centerPoint]);
    return (
        <Group
            onMouseEnter={(e) => {
                const container = e.target.getStage().container();
                container.style.cursor = 'pointer';
            }}
            onMouseLeave={(e) => {
                const container = e.target.getStage().container();
                container.style.cursor = 'default';
            }}
            onClick={sensorClickHandler}
        >
            <Group>
                {slaveLeash}
                {passEnd}
            </Group>
            {centralCircle}
            {showIPointLabel && (
                <ObjectLabel
                    opacity={opacity}
                    x={centerPoint.coordinates[0]}
                    y={centerPoint.coordinates[1]}
                    stageScale={stageScale}
                    marker={marker}
                />
            )}
        </Group>
    );
};

export default SingleSensor;
