import styled from 'styled-components/macro';
import { DatePicker } from '@fluentui/react';

export const Wrapper = styled.div`
    display: flex;
    align-items: flex-end;
`;

export const WrappedItem = styled.div`
    margin-right: 10px;
`;

export const StyledDatePicker = styled(DatePicker)`
    div {
        div {
            div {
                width: 170px;
            }
            span {
                div {
                    margin-top: 0px;
                }
            }
        }
    }
`;

export const StatusWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const StatusText = styled.div`
    margin-left: ${(props) => (props.isCallout ? '0px' : '10px')};
    margin-top: ${(props) => (props.isCallout ? '10px' : '0px')};
    :first-child {
        margin-top: ${(props) => props.isCallout && '0px'};
    }
    display: flex;
`;

export const SpinnerWrapper = styled.div`
    margin-left: 10px;
`;

export const ReexportText = styled.div`
    span {
        font-weight: 500;
        margin-right: 5px;
    }
`;

export const iconButtonStyles = {
    marginLeft: '10px',
};

export const reexportCalloutStyles = {
    root: {
        padding: '10px',
    },
};
