import { DateTime } from 'luxon';

/**
 * Функция для поиска связки, временной интервал который, наклоадывается на реальное время
 * @param {array} boundlesByIPointId массив всех связок, которые есть у этой точки установки
 * @returns возвращает объект с связкой, которая пересекается с реальным временем
 */
const findBoundleByDate = (options) => {
    const { boundlesByIPointId } = options;
    const dateNow = DateTime.now().toISO();
    const dateNowInSeconds = Date.parse(dateNow);
    let boundle = null;
    boundlesByIPointId.forEach((element) => {
        if (boundle) return;

        const elementDateFromInSeconds = Date.parse(element.date_from);
        const elementDateToInSeconds = Date.parse(element.date_to);

        if (
            elementDateToInSeconds &&
            dateNowInSeconds >= elementDateFromInSeconds &&
            dateNowInSeconds <= elementDateToInSeconds
        ) {
            boundle = element;
        } else if (!elementDateToInSeconds && dateNowInSeconds >= elementDateFromInSeconds) {
            boundle = element;
        }
    });
    return boundle;
};

export default findBoundleByDate;
