import { useContext, useEffect, useState } from 'react';
import { FloorSidePanelContext } from '../floorsSidePanel';
import { useId, useBoolean } from '@fluentui/react-hooks';
import {
    CustomOptionIcon,
    IconsWrapper,
    OptionWrapper,
    OptionTitle,
    OptionWindowTitle,
    CalloutContentWrapper,
    PlansListItemWrapper,
    PlansListItem,
} from './windowWithOptions.styles';
import { Callout, Icon, List } from '@fluentui/react';
import { DateTime } from 'luxon';
import { cloneDeep } from 'lodash';

/**
 * Выезжающее окно, где можно показать/скрыть слои и показать/скрыть лейблы
 * @param {function} openIntersectionModalHandler открывает модалку с пересечениями
 */
const WindowWithOptions = ({ openIntersectionModalHandler }) => {
    const {
        selectedFloor,
        currentFloorInfo,
        plansByFloorNumber,
        setShowPassWayLabelTrans,
        setShowIPointLabelTrans,
        setShowPassWayLayerTrans,
        setShowIPointLayerTrans,
        setCurrentFloorInfoTrans,
        setShowIntersectionsLayerTrans,
    } = useContext(FloorSidePanelContext);
    const [showPassWayLabel, setShowPassWayLabel] = useState(false);
    const [showIPointLabel, setShowIPointLabel] = useState(false);
    const [showPassWayLayer, setShowPassWayLayer] = useState(true);
    const [showIPointsLayer, setShowIPointsLayer] = useState(true);
    const [showIntersectionsLayer, setShowIntersectionsLayer] = useState(true);
    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    const [leasingPlans, setLeasingPlans] = useState([]);
    const leasingCalloutId = useId('leasingCalloutId');

    useEffect(() => {
        setLeasingPlans(cloneDeep(plansByFloorNumber[selectedFloor]));
    }, [selectedFloor, currentFloorInfo, plansByFloorNumber]);

    useEffect(() => {
        setShowPassWayLabelTrans(showPassWayLabel);
        // eslint-disable-next-line
    }, [showPassWayLabel]);

    useEffect(() => {
        setShowIPointLabelTrans(showIPointLabel);
        // eslint-disable-next-line
    }, [showIPointLabel]);

    useEffect(() => {
        setShowPassWayLayerTrans(showPassWayLayer);
        // eslint-disable-next-line
    }, [showPassWayLayer]);

    useEffect(() => {
        setShowIPointLayerTrans(showIPointsLayer);
        // eslint-disable-next-line
    }, [showIPointsLayer]);

    useEffect(() => {
        setShowIntersectionsLayerTrans(showIntersectionsLayer);
        // eslint-disable-next-line
    }, [showIntersectionsLayer]);

    const onLeasingPlanClick = (value) => () => setCurrentFloorInfoTrans(value);
    const passWayLabelHandler = () => setShowPassWayLabel((prevState) => !prevState);
    const iPointLabelHandler = () => setShowIPointLabel((prevState) => !prevState);
    const passWayLayerHandler = () => setShowPassWayLayer((prevState) => !prevState);
    const iPointLayerHandler = () => setShowIPointsLayer((prevState) => !prevState);
    const intersectionsLayerHandler = () => setShowIntersectionsLayer((prevState) => !prevState);

    const onRenderCell = (item) => {
        const payload = {
            id: item.id,
            image: item.image,
            scale: item.scale,
            plan2geo: item.plan2geo,
        };
        return (
            <PlansListItemWrapper onClick={onLeasingPlanClick(payload)}>
                <PlansListItem>{DateTime.fromISO(item.active_from).toFormat('yyyy-MM-dd HH:mm:ss')}</PlansListItem>
                {currentFloorInfo.id === item.id && (
                    <PlansListItem>
                        <Icon styles={{ root: { color: 'green' } }} iconName='CheckboxComposite' />
                    </PlansListItem>
                )}
            </PlansListItemWrapper>
        );
    };

    return (
        <>
            <OptionWindowTitle>Options</OptionWindowTitle>
            <OptionWrapper>
                <OptionTitle>IPoints</OptionTitle>
                <IconsWrapper>
                    <CustomOptionIcon onClick={iPointLayerHandler} iconName={'RedEye'} />
                    <CustomOptionIcon onClick={iPointLabelHandler} iconName={'CommentSolid'} />
                </IconsWrapper>
            </OptionWrapper>
            <OptionWrapper>
                <OptionTitle>Pass ways</OptionTitle>
                <IconsWrapper>
                    <CustomOptionIcon onClick={passWayLayerHandler} iconName={'RedEye'} />
                    <CustomOptionIcon onClick={passWayLabelHandler} iconName={'CommentSolid'} />
                </IconsWrapper>
            </OptionWrapper>
            <OptionWrapper>
                <OptionTitle>Pass ways & pass points intersections </OptionTitle>
                <IconsWrapper>
                    <CustomOptionIcon onClick={intersectionsLayerHandler} iconName={'RedEye'} />
                    <CustomOptionIcon onClick={openIntersectionModalHandler} iconName={'Table'} />
                </IconsWrapper>
            </OptionWrapper>
            <OptionWrapper>
                <OptionTitle>Change leasing plan </OptionTitle>
                <IconsWrapper>
                    <CustomOptionIcon onClick={toggleIsCalloutVisible} id={leasingCalloutId} iconName={'RedEye'} />
                </IconsWrapper>
            </OptionWrapper>
            {isCalloutVisible && (
                <Callout target={`#${leasingCalloutId}`} onDismiss={toggleIsCalloutVisible}>
                    <CalloutContentWrapper>
                        <List items={leasingPlans} onRenderCell={onRenderCell} />
                    </CalloutContentWrapper>
                </Callout>
            )}
        </>
    );
};

export default WindowWithOptions;
