/**
 * Функция для парсинга ответа от сенсора
 * @param {string} data xml данные с сенсора
 * @returns объект с информацией о размере скрина и разметку
 */
const processRowCountingData = (data) => {
    const passWays = [];
    const floors = [];
    const zones = [];
    const exceptions = [];
    const masks = [];
    const oParser = new DOMParser();
    const xmlResponse = oParser.parseFromString(data, 'text/xml');
    const width = +xmlResponse.querySelector('normalizedScreenWidth').textContent;
    const height = +xmlResponse.querySelector('normalizedScreenHeight').textContent;
    const countingLineItems = xmlResponse.querySelectorAll('CountingLineItem');
    const countingAreaCoords = xmlResponse.querySelectorAll('CountingArea > RegionCoordinatesList > RegionCoordinates');
    const zoneAreaCoords = xmlResponse.querySelectorAll('CalibRegion > RegionCoordinatesList > RegionCoordinates');

    // Получение всех линий подсчета
    for (let countingLine of countingLineItems) {
        const coordinates = [];
        const marker = 'line' + countingLine.querySelector('id').textContent;
        const rowCoordinates = countingLine.querySelectorAll('Coordinates');

        for (let item of rowCoordinates) {
            const x = +item.querySelector('positionX').textContent;
            const y = +item.querySelector('positionY').textContent;
            coordinates.push([x, height - y]);
        }

        passWays.push({ marker, coordinates });
    }

    // Получение координат зоны подсчета (обозначаем это как пол)
    const parsedCountingAreaCoords = [];
    for (let coordsItem of countingAreaCoords) {
        const x = +coordsItem.querySelector('positionX').textContent;
        const y = +coordsItem.querySelector('positionY').textContent;
        parsedCountingAreaCoords.push([x, height - y]);
    }
    floors.push({ marker: 'floor_1', coordinates: parsedCountingAreaCoords });

    // Получение координат зоны подсчета
    const parsedZoneCoords = [];
    for (let coordsItem of zoneAreaCoords) {
        const x = +coordsItem.querySelector('positionX').textContent;
        const y = +coordsItem.querySelector('positionY').textContent;
        parsedZoneCoords.push([x, height - y]);
    }
    zones.push({ marker: 'zone_1', coordinates: parsedZoneCoords });

    return { normalizedScreenSize: { width, height }, layers: { passWays, floors, exceptions, masks, zones } };
};

export default processRowCountingData;
