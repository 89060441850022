import { cloneDeep } from 'lodash';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    storeExtendedDataFromCSVSources,
    storePossibleToCloseModal,
} from '../../components/modals/batchDownload/batchDownloadReducer';
import { sensorTypes } from '../../constants/sensorTypes';
import postBrickstreamSensorForBatchDownload from '../batchDownloadHelper/brickstream/postBrickstreamSensorForBatchDownload';
import postDilaxSensorForBatchDownload from '../batchDownloadHelper/dilax/postDilaxSensorForBatchDownload';
import postHikvisionSensorForBatchDownload from '../batchDownloadHelper/hikvision/postHikvisionSensorForBatchDownload';
import postIPointHelper from '../batchDownloadHelper/postIPointHelper';
import postRstatSensorForBatchDownload from '../batchDownloadHelper/rstat/postRstatSensorForBatchDownload';
import postVivotekSensorForBatchDownload from '../batchDownloadHelper/vivotek/postVivotekSensorForBatchDownload';
import postXovisSensorForBatchDownload from '../batchDownloadHelper/xovis/postXovisSensorForBatchDownload';
import postTdSensorForBatchDownload from '../batchDownloadHelper/td/postTdSensorForBatchDownload';
import postMegacountSensorForBatchDownload from '../batchDownloadHelper/megacount/postMegacountSensorForBatchDownload';

/**
 * Кастомный хук для для создания сенсоров и связок с точками установок. Обрабатывает по 3 промиса
 */
const useBatchUpload = () => {
    const { startBatchUpload, extendedDataFromCSVSourcesById } = useSelector(
        (state) => state.batchDownloadReducer,
    );
    const { sensors } = useSelector((state) => state.countingSensorsReducer);
    const {
        instalationPoints,
        sensorsAndPointsBounles,
        selectedLocationTimeZone,
    } = useSelector((state) => state.instalationPointsReducer);
    const { token, storeUrls } = useSelector((state) => state.generalReducer);
    const { projectLocationsById } = useSelector(
        (state) => state.projectLocationsReducer,
    );
    const dispatch = useDispatch();

    useEffect(() => {
        if (startBatchUpload) {
            dispatch(storePossibleToCloseModal(false));
            const extendedCopy = cloneDeep(extendedDataFromCSVSourcesById);
            Object.keys(extendedCopy).forEach((id) => {
                const element = extendedCopy[id];

                const iPointFlag =
                    element.ipoint_marker && element.ipoint_date_from;
                extendedCopy[id] = {
                    ...element,
                    postSensorFetching: true,
                    postBoundleFetching: iPointFlag || false,
                    postBoundleError: iPointFlag || 'Not provided',
                };
            });
            dispatch(storeExtendedDataFromCSVSources(extendedCopy));
        }
        const asyncFun = async () => {
            try {
                if (startBatchUpload) {
                    const extendedCopy = cloneDeep(
                        extendedDataFromCSVSourcesById,
                    );

                    let size = 3; //размер подмассива
                    let count = 0;
                    let subarray = []; //массив в который будет выведен результат.
                    for (
                        let i = 0;
                        i < Math.ceil(Object.keys(extendedCopy).length / size);
                        i++
                    ) {
                        subarray[i] = Object.keys(extendedCopy).slice(
                            i * size,
                            i * size + size,
                        );
                    }

                    // Проход по массивам из элементов
                    for (let array of subarray) {
                        let postPromises = [];
                        let existingSensorBoundlePromises = [];
                        let boundlesPromises = [];
                        array.forEach((id) => {
                            const element = extendedCopy[id];
                            const sNumber =
                                element.serialNumberFromSensor ||
                                element.serial_number ||
                                null;

                            if (sNumber && element.ip && element.port) {
                                const alreadyExists = sensors.find(
                                    (sensor) =>
                                        sensor.serial_number.toLowerCase() ===
                                        sNumber.toLowerCase(),
                                );

                                // Если сенсор уже существует, пытаемся созадть только точку установки
                                if (alreadyExists) {
                                    extendedCopy[element.id] = {
                                        ...extendedCopy[element.id],
                                        postSensorFetching: false,
                                        postBoundleFetching: true,
                                        postSensorError:
                                            'Sensor already exists',
                                    };

                                    if (
                                        element.ipoint_marker &&
                                        element.ipoint_date_from
                                    ) {
                                        const iPoint = instalationPoints.find(
                                            (iPoint) =>
                                                iPoint.marker ===
                                                element.ipoint_marker,
                                        );

                                        if (iPoint) {
                                            const data = postIPointHelper({
                                                dateFrom:
                                                    element.ipoint_date_from,
                                                dateTo: element.ipoint_date_to,
                                                selectedLocationTimeZone,
                                                sensorId: alreadyExists.id,
                                                iPointId: iPoint.id,
                                                sensorsAndPointsBounles,
                                                frontId: element.id,
                                                storeUrls,
                                                token,
                                            });

                                            if (!data.error) {
                                                existingSensorBoundlePromises.push(
                                                    data,
                                                );
                                            } else {
                                                extendedCopy[element.id] = {
                                                    ...extendedCopy[element.id],
                                                    postBoundleError:
                                                        data.error,
                                                    postBoundleFetching: false,
                                                };
                                            }
                                        } else {
                                            extendedCopy[element.id] = {
                                                ...extendedCopy[element.id],
                                                postBoundleError:
                                                    'Invalid marker',
                                                postBoundleFetching: false,
                                            };
                                        }
                                    } else {
                                        extendedCopy[element.id] = {
                                            ...extendedCopy[element.id],
                                            postBoundleError:
                                                'No data to post iPoint',
                                            postBoundleFetching: false,
                                        };
                                    }
                                    // Если сенсора нет, то созадем новый, а потом точку устанвоки
                                } else {
                                    if (projectLocationsById[element.pl_id]) {
                                        const sensorData = {
                                            serial_number: sNumber,
                                            ip: element.ip,
                                            project_location_id: Number(
                                                element.pl_id,
                                            ),
                                            port: +element.port,
                                            is_active: true,
                                            username: element.user,
                                            password: element.pass,
                                        };
                                        switch (element.type) {
                                            case sensorTypes.XOVIS:
                                                postPromises.push(
                                                    postXovisSensorForBatchDownload(
                                                        {
                                                            body: sensorData,
                                                            element,
                                                            token,
                                                            storeUrls,
                                                        },
                                                    ),
                                                );
                                                break;
                                            case sensorTypes.BRICKSTREAM:
                                                postPromises.push(
                                                    postBrickstreamSensorForBatchDownload(
                                                        {
                                                            body: sensorData,
                                                            element,
                                                            token,
                                                            storeUrls,
                                                        },
                                                    ),
                                                );
                                                break;
                                            case sensorTypes.VIVOTEK:
                                                postPromises.push(
                                                    postVivotekSensorForBatchDownload(
                                                        {
                                                            body: sensorData,
                                                            element,
                                                            token,
                                                            storeUrls,
                                                        },
                                                    ),
                                                );
                                                break;

                                            case sensorTypes.HIKVISION:
                                                postPromises.push(
                                                    postHikvisionSensorForBatchDownload(
                                                        {
                                                            body: sensorData,
                                                            element,
                                                            token,
                                                            storeUrls,
                                                        },
                                                    ),
                                                );
                                                break;

                                            case sensorTypes.RSTAT:
                                                postPromises.push(
                                                    postRstatSensorForBatchDownload(
                                                        {
                                                            body: sensorData,
                                                            element,
                                                            token,
                                                            storeUrls,
                                                        },
                                                    ),
                                                );
                                                break;

                                            case sensorTypes.DILAX:
                                                postPromises.push(
                                                    postDilaxSensorForBatchDownload(
                                                        {
                                                            body: sensorData,
                                                            element,
                                                            token,
                                                            storeUrls,
                                                        },
                                                    ),
                                                );
                                                break;

                                            case sensorTypes.TD:
                                                postPromises.push(
                                                    postTdSensorForBatchDownload(
                                                        {
                                                            body: sensorData,
                                                            element,
                                                            token,
                                                            storeUrls,
                                                        },
                                                    ),
                                                );
                                                break;

                                            case sensorTypes.MEGACOUNT:
                                                postPromises.push(
                                                    postMegacountSensorForBatchDownload(
                                                        {
                                                            body: sensorData,
                                                            element,
                                                            token,
                                                            storeUrls,
                                                        },
                                                    ),
                                                );
                                                break;

                                            default:
                                                postPromises.push(
                                                    new Promise((resolve) =>
                                                        resolve({
                                                            frontId: element.id,
                                                            error: 'Invalid sensor type',
                                                        }),
                                                    ),
                                                );
                                                break;
                                        }
                                    } else {
                                        postPromises.push(
                                            new Promise((resolve) =>
                                                resolve({
                                                    frontId: element.id,
                                                    error: 'Invalid pl_id',
                                                }),
                                            ),
                                        );
                                    }
                                }
                            } else {
                                postPromises.push(
                                    new Promise((resolve) =>
                                        resolve({
                                            frontId: element.id,
                                            error: 'Not all required data entered (SNumber, ip, port, pass)',
                                        }),
                                    ),
                                );
                            }
                        });

                        // Обработка промисов для точек установок, когда датчики уже существуют
                        await Promise.all(existingSensorBoundlePromises).then(
                            (responses) => {
                                if (responses.length > 0) {
                                    responses.forEach((data) => {
                                        extendedCopy[data.frontId] = {
                                            ...extendedCopy[data.frontId],
                                            postBoundleFetching: false,
                                        };
                                        if (data.error) {
                                            const allMessages =
                                                data.error?.response?.data ||
                                                null;
                                            const errorsKeys = allMessages
                                                ? Object.keys(allMessages)
                                                : null;
                                            const message = errorsKeys
                                                ? allMessages[errorsKeys[0]]
                                                : null;
                                            extendedCopy[data.frontId] = {
                                                ...extendedCopy[data.frontId],
                                                postBoundleError:
                                                    message ||
                                                    data.error.message ||
                                                    'Post boundle error',
                                            };
                                        }
                                    });
                                }
                            },
                        );

                        // Обработка промисов для пост датчиков, если они заресолвились, то пытаемся создать связи с ними
                        await Promise.all(postPromises).then((responses) => {
                            if (responses.length > 0) {
                                responses.forEach((data) => {
                                    extendedCopy[data.frontId] = {
                                        ...extendedCopy[data.frontId],
                                        postSensorFetching: false,
                                    };

                                    if (!data.error) {
                                        const element =
                                            extendedCopy[data.frontId];
                                        if (
                                            element.ipoint_marker &&
                                            element.ipoint_date_from
                                        ) {
                                            const iPoint =
                                                instalationPoints.find(
                                                    (iPoint) =>
                                                        iPoint.marker ===
                                                        element.ipoint_marker,
                                                );

                                            if (iPoint) {
                                                const iPointRes =
                                                    postIPointHelper({
                                                        dateFrom:
                                                            element.ipoint_date_from,
                                                        dateTo: element.ipoint_date_to,
                                                        selectedLocationTimeZone,
                                                        sensorId: data.id,
                                                        iPointId: iPoint.id,
                                                        sensorsAndPointsBounles,
                                                        frontId: element.id,
                                                        storeUrls,
                                                        token,
                                                    });

                                                if (!iPointRes.error) {
                                                    boundlesPromises.push(
                                                        iPointRes,
                                                    );
                                                } else {
                                                    extendedCopy[element.id] = {
                                                        ...extendedCopy[
                                                            element.id
                                                        ],
                                                        postBoundleFetching: false,
                                                        postBoundleError:
                                                            iPointRes.error,
                                                    };
                                                }
                                            } else {
                                                extendedCopy[element.id] = {
                                                    ...extendedCopy[element.id],
                                                    postBoundleFetching: false,
                                                    postBoundleError:
                                                        'Invalid iPoint marker',
                                                };
                                            }
                                        } else {
                                            extendedCopy[data.frontId] = {
                                                ...extendedCopy[data.frontId],
                                                postBoundleFetching: false,
                                                postBoundleError:
                                                    'No data to post iPoint',
                                            };
                                        }
                                    } else {
                                        let message = null;
                                        const allMessages =
                                            data.error?.response?.data || null;
                                        const errorsKeys = allMessages
                                            ? Object.keys(allMessages)
                                            : null;
                                        if (
                                            data.error === 'Invalid pl_id' ||
                                            data.error ===
                                                'Not all required data entered (SNumber, ip, port, pass)'
                                        ) {
                                            message = data.error;
                                        } else {
                                            message = errorsKeys
                                                ? allMessages[errorsKeys[0]]
                                                : null;
                                        }

                                        extendedCopy[data.frontId] = {
                                            ...extendedCopy[data.frontId],
                                            postBoundleFetching: false,
                                            postSensorError:
                                                message || 'Post sensor error',
                                            postBoundleError:
                                                message || 'Post sensor error',
                                        };
                                    }
                                });
                            }
                        });

                        // обработка промисов для создания связок, после добавления сенсоров
                        await Promise.all(boundlesPromises).then(
                            (responses) => {
                                if (responses.length > 0) {
                                    responses.forEach((data) => {
                                        extendedCopy[data.frontId] = {
                                            ...extendedCopy[data.frontId],
                                            postBoundleFetching: false,
                                        };
                                        if (data.error) {
                                            const allMessages =
                                                data.error?.response?.data ||
                                                null;
                                            const errorsKeys = allMessages
                                                ? Object.keys(allMessages)
                                                : null;
                                            const message = errorsKeys
                                                ? allMessages[errorsKeys[0]]
                                                : null;
                                            extendedCopy[data.frontId] = {
                                                ...extendedCopy[data.frontId],
                                                postBoundleError:
                                                    message ||
                                                    data.error.message ||
                                                    'Post boundle error',
                                            };
                                        }
                                    });
                                }
                            },
                        );

                        count += 1;
                        if (count === subarray.length) {
                            dispatch(storePossibleToCloseModal(true));
                            dispatch(
                                storeExtendedDataFromCSVSources(extendedCopy),
                            );
                        }
                    }
                }
            } catch (error) {
                console.log('>>>Batch error', error);
            }
        };

        asyncFun();
        // eslint-disable-next-line
    }, [startBatchUpload]);
};

export default useBatchUpload;
