import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Header from './components/header/header';
import { Route, Routes } from 'react-router';
import { AppWrapper, ContentWrapper, MessageBarWrapper } from './app.styles';
import Error from './components/error/error';
import ModalCreator from './components/modals/modalCreator';
import ChangeUrlsModal from './components/modals/cnahgeUrlsModal/changeUrlsModal';
import auth from './utils/login/auth';
import getCurrent from './utils/login/getCurrent';
import {
    showErrorNotification,
    storeCurrent,
    storeDataForOverwritten,
    storeEqualConflict,
} from './generalReducer';
import { useHotkeys } from 'react-hotkeys-hook';
import InstalationPoints from './components/instalationPoints/installationPoints';
import LoaderSpinner from './components/loaderSpinner/loaderSpinner';
import { MessageBar, MessageBarType } from '@fluentui/react';
import ScreenshotsPage from './components/screenshotsPage/screenshotsPage';
import 'react-datasheet/lib/react-datasheet.css';
import EqualConflict from './components/modals/equalConflict/equalConflict';
import useWindowSize from './utils/customHooks/useWindowSize';
import SensorsManagement from './components/sensorsManagement/sensorsManagement';
import TasksMonitoring from './components/tasksMonitoring/index';
import Projects from './components/Projects/Projects';
import TokenExpiration from './components/TokenExpiration/TokenExpiration';
import ProjectLocations from './components/ProjectLocations/ProjectLocations';
import getProjectIdFromUrl from './utils/getProjectIdFromUrl';
import { storeSelectedProjectId } from './components/Projects/Projects.reducer';
import {
    getPLSyncInfo,
    storeSelectedPLId,
} from './components/ProjectLocations/ProjectLocations.reducer';
import getProjectLocationIdFromUrl from './utils/getProjectLocationIdFromUrl';
import useUrls from './hooks/useUrls';

/**
 *
 * Контейнер всего приложения
 */
const App = memo(() => {
    const {
        token,
        language,
        storeUrls,
        showSpinner,
        successNotification,
        errorNotification,
        equalConflict,
    } = useSelector((state) => state.generalReducer);
    const { selectedProjectId } = useSelector((state) => state.projectsReducer);
    const { selectedPLId } = useSelector(
        (state) => state.projectLocationsReducer,
    );
    const dispatch = useDispatch();
    const { i18n } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isConflictModalOpen, setConflictModalOpen] = useState(false);
    useHotkeys('shift+d+o', () => setIsModalOpen(true));
    useWindowSize();
    useUrls();

    useEffect(() => {
        if (storeUrls.AUTH_URL) {
            auth(dispatch, storeUrls, token);
        }
        // eslint-disable-next-line
    }, [token, storeUrls]);

    useEffect(() => {
        if (storeUrls.CURRENT_URL && token) {
            getCurrent(dispatch, storeUrls, token).then((response) => {
                if (!response.error) {
                    dispatch(storeCurrent(response));
                } else {
                    localStorage.removeItem('xtoken');
                    window.location.reload();
                    dispatch(
                        showErrorNotification({
                            show: true,
                            message: `GET CURRENT error: ${JSON.stringify(
                                response?.error?.response?.data || 'Some error',
                            )}`,
                        }),
                    );
                }
            });
        }
        // eslint-disable-next-line
    }, [token, storeUrls]);

    useEffect(() => {
        i18n.changeLanguage(language);
    }, [language, i18n]);

    useEffect(() => {
        if (equalConflict) setConflictModalOpen(true);
    }, [equalConflict]);

    useEffect(() => {
        const projectId = getProjectIdFromUrl();
        if (projectId !== null && !selectedProjectId)
            dispatch(storeSelectedProjectId(projectId));
        // eslint-disable-next-line
    }, [selectedProjectId]);

    useEffect(() => {
        if (token && storeUrls && !selectedPLId) {
            const projectLocationId = getProjectLocationIdFromUrl();
            if (projectLocationId !== null) {
                dispatch(storeSelectedPLId(projectLocationId));
                dispatch(getPLSyncInfo());
            }
        }

        // eslint-disable-next-line
    }, [selectedPLId, token, storeUrls]);

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const closeConflictModal = () => {
        setConflictModalOpen(false);
        dispatch(storeEqualConflict(false));
        dispatch(storeDataForOverwritten(null));
    };

    return (
        <>
            <TokenExpiration />
            {showSpinner && <LoaderSpinner />}

            <ModalCreator isModalOpen={isModalOpen} closeModal={closeModal}>
                <ChangeUrlsModal closeModal={closeModal} />
            </ModalCreator>
            <ModalCreator
                borderTop={'4px solid red'}
                isModalOpen={isConflictModalOpen}
                closeModal={closeConflictModal}
            >
                <EqualConflict closeConflictModal={closeConflictModal} />
            </ModalCreator>
            <AppWrapper>
                <Header />
                <ContentWrapper>
                    <Routes>
                        <Route path="/" element={<Projects />} />
                        <Route
                            path="/projects/:projectId"
                            element={<ProjectLocations />}
                        />
                        <Route
                            path="/projects/:projectId/project-locations/:locationId/installation-points"
                            element={<InstalationPoints />}
                        />
                        <Route
                            path="/projects/:projectId/project-locations/:locationId/sensors-screenshots"
                            element={<ScreenshotsPage />}
                        />
                        <Route
                            path="/sensors-management"
                            element={<SensorsManagement />}
                        />
                        <Route
                            path="/tasks-monitoring"
                            element={<TasksMonitoring xToken={token} />}
                        />
                        <Route element={<Error />} />
                    </Routes>
                </ContentWrapper>

                {successNotification.show ? (
                    <MessageBarWrapper>
                        <MessageBar
                            messageBarType={MessageBarType.success}
                            isMultiline={false}
                        >
                            {successNotification.message ? (
                                <>{successNotification.message}</>
                            ) : (
                                <>Success</>
                            )}
                        </MessageBar>
                    </MessageBarWrapper>
                ) : null}
                {errorNotification.show ? (
                    <MessageBarWrapper>
                        <MessageBar
                            messageBarType={MessageBarType.error}
                            isMultiline={false}
                        >
                            {errorNotification.message ? (
                                <>{errorNotification.message}</>
                            ) : (
                                <>Error</>
                            )}
                        </MessageBar>
                    </MessageBarWrapper>
                ) : null}
            </AppWrapper>
        </>
    );
});

export default App;
