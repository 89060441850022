import axios from 'axios';
import { rstatPostRequest } from '../../../../api/rstat/rstat.api';
import getRstatMac from '../../../../utils/rstatHelpers/getRstatMac';
import { rstatProgressMessages } from '../../../../utils/rstatHelpers/rstatProgressMessages';

/**
 * Promise. Функция для получения дополнительной информация о дптчике rstat
 * @param {object} sensor объект сенсора
 * @returns возвращает обновленный объект сенсора с дополнительными полями
 */
const getRstatSensorData = (sensor) => {
    const errorResponse = {
        ...sensor,
        isConfigurationFetching: false,
        firstStepError: rstatProgressMessages.firstStepError,
    };

    const sensorInfo = rstatPostRequest({
        url: `http://${sensor.ip}:${sensor.port}/sys_inf.php`,
        payload: JSON.stringify({
            login: sensor.user,
            password: sensor.pass,
        }),
    }).then((response) => ({ data: { ...response }, type: 'sensorInfo' }));

    return axios
        .all([sensorInfo])
        .then((responses) => {
            const rstatData = {
                serialNumberFromSensor: '',
            };
            let firstStepError = '';
            responses.forEach((response) => {
                switch (response.type) {
                    case 'sensorInfo':
                        if (!response.data.error) {
                            rstatData.serialNumberFromSensor = getRstatMac(response.data);
                        } else {
                            firstStepError = rstatProgressMessages.firstStepError;
                        }
                        break;

                    default:
                        break;
                }
            });
            return {
                ...sensor,
                isConfigurationFetching: false,
                firstStepError,
                rstatData,
            };
        })
        .catch((_) => ({ ...errorResponse }));
};

export default getRstatSensorData;
