import { FC, memo, useEffect, useState } from 'react';
import { Pivot, PivotItem } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import {
    fetchReexportTasks,
    storeExtendedPlTasksByProjectId,
    storeExtendedSensorsByPlId,
    storeSelectedPlId,
    storeSelectedProjectId,
    storeTasksDateFrom,
    storeTasksDateTo,
    storeToken,
} from '../../reducer';
import { ITasksMonitoringProps } from './TasksMonitoring.interfaces';
import { MainWrapper, pivotItemContainerStyles } from './TasksMonitoring.styles';
import { useAppSelector } from '../../reduxHooks/reduxHooks';
import { TasksMonitoringContext } from './TasksMonitoring.context';
import { pivotItems } from './constants/pivotItems';
import Projects from '../Projects/Projects';
import ProjectLocations from '../ProjectLocations/ProjectLocations';
import PlSensors from '../PlSensors/PlSensors';
import { useAppDispatch } from '../../store';

/**
 * Компонент для мониторинга задач ре-экспорта
 */
const TasksMonitoring: FC<ITasksMonitoringProps> = memo(({ xToken }) => {
    const {
        extendedPlTasksByProjectId,
        projectTasksByProjectId,
        extendedSensorsByPlId,
        plTasksInitialData,
        tasksFetchingError,
        selectedProjectId,
        tasksDateFrom,
        tasksFetching,
        selectedPlId,
        tasksDateTo,
        token,
    } = useAppSelector((state) => state.TasksMonitoringReducer);
    const [selectedKey, setSelectedKey] = useState(pivotItems.projects.itemKey);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(storeToken(xToken));
        // eslint-disable-next-line
    }, [xToken]);

    useEffect(() => {
        dispatch(storeExtendedPlTasksByProjectId());
        dispatch(storeExtendedSensorsByPlId());
        // eslint-disable-next-line
    }, [projectTasksByProjectId]);

    useEffect(() => {
        selectedProjectId && setSelectedKey(pivotItems.projectLocations.itemKey);
    }, [selectedProjectId]);

    useEffect(() => {
        selectedPlId && setSelectedKey(pivotItems.plSensors.itemKey);
    }, [selectedPlId]);

    const storeSelectedProjectIdTrans = (value: number | null) => dispatch(storeSelectedProjectId(value));

    const storeSelectedPlIdTrans = (value: number | null) => dispatch(storeSelectedPlId(value));

    const storeTasksDateFromTrans = (value: string) => dispatch(storeTasksDateFrom(value));

    const storeTasksDateToTrans = (value: string) => dispatch(storeTasksDateTo(value));

    const fetchReexportTasksTrans = () => dispatch(fetchReexportTasks());

    const onPivotClick = (item?: PivotItem, _?: React.MouseEvent<HTMLElement>) => {
        if (item) {
            setSelectedKey(item.props.itemKey as string);
            switch (item.props.headerText) {
                case pivotItems.projects.headerText:
                    dispatch(storeSelectedProjectId(null));
                    dispatch(storeSelectedPlId(null));
                    break;

                case pivotItems.projectLocations.headerText:
                    dispatch(storeSelectedPlId(null));
                    break;

                default:
                    break;
            }
        }
    };

    const tasksMonitoringContextValue = {
        storeSelectedProjectIdTrans,
        extendedPlTasksByProjectId,
        fetchReexportTasksTrans,
        projectTasksByProjectId,
        storeTasksDateFromTrans,
        storeSelectedPlIdTrans,
        storeTasksDateToTrans,
        extendedSensorsByPlId,
        plTasksInitialData,
        tasksFetchingError,
        selectedProjectId,
        tasksDateFrom,
        tasksFetching,
        selectedPlId,
        tasksDateTo,
        token,
    };

    return (
        <TasksMonitoringContext.Provider value={tasksMonitoringContextValue}>
            <MainWrapper>
                <Pivot
                    onLinkClick={onPivotClick}
                    linkFormat="tabs"
                    selectedKey={selectedKey}
                    styles={pivotItemContainerStyles}
                >
                    <PivotItem itemKey={pivotItems.projects.itemKey} headerText={t(pivotItems.projects.headerText)}>
                        <Projects />
                    </PivotItem>
                    {selectedProjectId && (
                        <PivotItem
                            itemKey={pivotItems.projectLocations.itemKey}
                            headerText={t(pivotItems.projectLocations.headerText)}
                        >
                            <ProjectLocations />
                        </PivotItem>
                    )}

                    {selectedPlId && (
                        <PivotItem
                            itemKey={pivotItems.plSensors.itemKey}
                            headerText={t(pivotItems.plSensors.headerText)}
                        >
                            <PlSensors />
                        </PivotItem>
                    )}
                </Pivot>
            </MainWrapper>
        </TasksMonitoringContext.Provider>
    );
});

export default TasksMonitoring;
