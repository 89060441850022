import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    :first-child {
        margin-top: 0px;
    }
`;

export const ItemIndex = styled.div`
    font-weight: 600;
    margin-right: 5px;
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const InformationWrapper = styled.div`
    white-space: nowrap;
    span {
        font-weight: 600;
    }
`;
