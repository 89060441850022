/**
 * Кастомный хук подготовки данных для пакетного обновления выгрузки
 * на выбранных датчиках
 */
const usePrepareDataForBatchUpdate = () => {
    /**
     * Получение расширенной структуры для выбранных точек установки, для
     * сенсоров которых необходимо обновить выгрузку
     */
    // useEffect(() => {
    //     const extendedDataByIPointId = Object.entries(selectedInstallationPointsById).reduce(
    //         (acc, [iPointId, iPoint]) => {
    //             const bundle = availableBundlesByIPointId[iPointId];
    //             const sensor = initialSensorsById[bundle?.id];
    //             if (bundle && sensor)
    //                 acc[iPointId] = {
    //                     isFetching: true,
    //                     isError: false,
    //                     message: '',
    //                     iPoint,
    //                     sensor,
    //                     bundle,
    //                 };
    //             return acc;
    //         },
    //         {}
    //     );
    //     dispatch(storeExtendedSelectedIPointsById(extendedDataByIPointId));
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [selectedInstallationPointsById]);
};

export default usePrepareDataForBatchUpdate;
