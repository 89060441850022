import { DateTime } from 'luxon';

/**
 * Функция для генерации данных для пакетного запроса
 * @param {number} dateFromInMillis date_from
 * @param {number} dateToInMillis date_to
 * @param {object} sensor объект сенсора
 * @return объект с данными для пакетного запроса
 */
const generateTdDataForUploadRequest = (options) => {
    const { dateFromInMillis, dateToInMillis, sensor } = options;
    const convertedDateFrom = DateTime.fromMillis(dateFromInMillis)
        .toFormat("yyyy-MM-dd'_'HH-mm-ss")
        .replaceAll('-', '');
    const convertedDateTo = DateTime.fromMillis(dateToInMillis)
        .toFormat("yyyy-MM-dd'_'HH-mm-ss")
        .replaceAll('-', '');

    const payload = {
        backupperiod: 1,
        periodstart: convertedDateFrom,
        periodstop: convertedDateTo,
        serverid: sensor.tdData?.serviceConfig?.companyServerId,
    };

    const dataForPost = new URLSearchParams(Object.entries(payload)).toString();

    const data = {
        url: `http://${sensor.ip}:${sensor.port}/upload_setting`,
        initialData: sensor,
        dataForPost,
        extraArgs: {
            dateInterval: {
                dateFrom: dateFromInMillis,
                dateTo: dateToInMillis,
            },
        },
    };

    return data;
};

export default generateTdDataForUploadRequest;
