import { TextField } from '@fluentui/react';
import { FC, memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IFilterInputProps } from './FilterInput.interfaces';
import { filterListOfObjects } from './tools/filterListOfObjects';

/**
 * Компонент инпута с возможностью фильтрации
 */
const FilterInput: FC<IFilterInputProps> = memo(
    ({
        disabled = false,
        value = '',
        inputArray,
        changeValue,
        updateFilteredArray,
        matchFields,
        isOutsideControlled = false,
    }) => {
        const [filterValue, setFilterValue] = useState(value);
        const { t } = useTranslation();

        useEffect(() => {
            if (inputArray.length && !isOutsideControlled) {
                const filteredArray = filterListOfObjects(inputArray, matchFields, filterValue);
                updateFilteredArray && updateFilteredArray(filteredArray);
            }
            // eslint-disable-next-line
        }, [filterValue]);

        const onChange = useCallback(
            (_: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                if (isOutsideControlled) {
                    changeValue && changeValue(newValue || '');
                } else {
                    setFilterValue(newValue || '');
                }
            },
            // eslint-disable-next-line
            [],
        );

        return (
            <TextField
                placeholder={t('Filter')}
                disabled={disabled}
                value={isOutsideControlled ? value : filterValue}
                onChange={onChange}
            />
        );
    },
);
export default FilterInput;
