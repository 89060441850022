import { DatePicker, PrimaryButton, MessageBar, MessageBarType, SpinnerSize, Spinner } from '@fluentui/react';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import StackHorizontal from '../StackHorizontal/StackHorizontal';
import { TasksMonitoringContext } from '../TasksMonitoring/TasksMonitoring.context';
import dateFromater from '../../tools/dateFormater';
import { datePickerHiddenStatusMessage, SpinnerWrapper } from './Projects.styles';
import ProjectsList from './components/ProjectsList/ProjectsList';

/**
 * Компонент для отрисовки секции с проектами
 */
const Projects = () => {
    const {
        tasksFetching,
        fetchReexportTasksTrans,
        tasksFetchingError,
        token,
        plTasksInitialData,
        tasksDateFrom,
        tasksDateTo,
        storeTasksDateFromTrans,
        storeTasksDateToTrans,
    } = useContext(TasksMonitoringContext);
    const { t } = useTranslation();

    useEffect(() => {
        !plTasksInitialData.length && fetchReexportTasksTrans && fetchReexportTasksTrans();
        // eslint-disable-next-line
    }, [token]);

    const reciveTasks = () => {
        fetchReexportTasksTrans && fetchReexportTasksTrans();
    };

    const onSelectDateFrom = (date: Date) => storeTasksDateFromTrans && storeTasksDateFromTrans(dateFromater(date));

    const onSelectDateTo = (date: Date) => storeTasksDateToTrans && storeTasksDateToTrans(dateFromater(date));

    return (
        <>
            <StackHorizontal stackAlign='end'>
                <DatePicker
                    value={new Date(tasksDateFrom)}
                    label={t('Date from')}
                    disabled={tasksFetching}
                    formatDate={dateFromater}
                    styles={datePickerHiddenStatusMessage}
                    onSelectDate={onSelectDateFrom as (date?: Date | null | undefined) => void}
                />
                <DatePicker
                    value={new Date(tasksDateTo)}
                    label={t('Date to')}
                    disabled={tasksFetching}
                    formatDate={dateFromater}
                    styles={datePickerHiddenStatusMessage}
                    onSelectDate={onSelectDateTo as (date?: Date | null | undefined) => void}
                />
                <PrimaryButton disabled={tasksFetching} onClick={reciveTasks} text={t('Recive tasks')} />
                {tasksFetchingError
                    ? ((<MessageBar messageBarType={MessageBarType.error}>{tasksFetchingError}</MessageBar>) as any)
                    : null}
            </StackHorizontal>
            {tasksFetching ? (
                <SpinnerWrapper>
                    <Spinner labelPosition='top' size={SpinnerSize.large} label={t('Loading...')} />
                </SpinnerWrapper>
            ) : (
                <ProjectsList />
            )}
        </>
    );
};

export default Projects;
