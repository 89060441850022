/**
 * Функция для получения информации о всех зонах
 * @param {string} config конфигурация сенсора
 * @param {object} multiSensorInfo информация о мультисенсоре
 * @returns массив со всеми зонами
 */
const getZonesInfo = (args) => {
    const { config, multiSensorInfo = null } = args;
    const finalArray = [];
    const oParser = new DOMParser();
    const xmlResponse = oParser.parseFromString(config, 'text/xml');
    const cntzonesArray = xmlResponse.querySelectorAll('cntzone');
    const multiSensorData = xmlResponse.querySelector('settings > multisensor > counting')?.innerHTML;

    for (let zone of cntzonesArray) {
        const attributes = zone.attributes;
        const marker = attributes.name.textContent;
        const pointsArray = zone.querySelectorAll('point');

        if (multiSensorInfo) {
            if (
                multiSensorData &&
                multiSensorData.includes(marker) &&
                multiSensorInfo.scaleX &&
                multiSensorInfo.scaleY &&
                multiSensorInfo.xStartCoordinate &&
                multiSensorInfo.yStartCoordinate
            ) {
                const result = getZone(marker, pointsArray, multiSensorInfo);
                finalArray.push(result);
            }
        } else {
            if (!multiSensorData?.includes(marker)) {
                const result = getZone(marker, pointsArray);
                finalArray.push(result);
            }
        }
    }

    return finalArray;
};

export default getZonesInfo;

const getZone = (marker, points, multiSensorInfo = null) => {
    const zoneCoordinates = [];
    for (let point of points) {
        let xCoordinate = +point.attributes.x.textContent;
        let yCoordinate = +point.attributes.y.textContent;

        if (multiSensorInfo) {
            xCoordinate = xCoordinate / multiSensorInfo.scaleX + multiSensorInfo.xStartCoordinate;
            yCoordinate = yCoordinate / multiSensorInfo.scaleY + multiSensorInfo.yStartCoordinate;
        }

        zoneCoordinates.push([xCoordinate, yCoordinate]);
    }

    return { marker, coordinates: zoneCoordinates };
};
