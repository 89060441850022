import { Stage, Layer } from 'react-konva';
import { memo, useContext, useEffect, useState } from 'react';
import { ImageLoadingWrapper, LocationDispalyWrapper } from './locationDisplay.styles';
import ImageForKonva from '../../imageForKonva/imageForKonva';
import { sizes } from '../../../constants/sizes';
import { handleWheelScale } from '../../../utils/locationDisplay/handleWheelScale';
import { fitStage } from '../../../utils/locationDisplay/fitStage';
import CountingSensors from '../../countingSensors/countingSensors';
import { Provider } from 'react-redux';
import { store } from '../../../store';
import PassWays from '../../passWays/passWays';
import geoMatrixConverter from '../../../utils/geoMatrixConverter';
import IntersectionsForDisplay from '../../intersectionsForDisplay/intersectionsForDispaly';
import { FloorPlanContext } from '../floorPlan';
import { Spinner, SpinnerSize } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
/**
 * Обертка для канваса с изображением схемы локации и слоев  на этой схеме
 */
const LocationDispaly = memo(() => {
    const [image, setImage] = useState('');
    const [imageOffset, setImageOffset] = useState([0, 0]);
    const [imageScale, setImageScale] = useState(1);
    const [imageLoading, setImageLoading] = useState(false);
    const [scale, setScale] = useState({
        stageScale: 1,
        stageX: 0,
        stageY: 0,
    });
    const {
        currentFloorInfo,
        splitWidth,
        setSelectedInstalationPointTrans,
        showPassWayLayer,
        showIPointLayer,
        mainLocationsByFloor,
        showIntersectionsLayer,
        selectedFloor,
        windowSize,
        selectedInstalationPoint,
        followIPoint,
    } = useContext(FloorPlanContext);
    const { t } = useTranslation();

    useEffect(() => setImage(currentFloorInfo.image), [currentFloorInfo]);

    useEffect(() => {
        if (selectedInstalationPoint && followIPoint) {
            const newScale = 2;

            const x =
                -selectedInstalationPoint.centerPoint?.coordinates[0] * newScale +
                (splitWidth - sizes.FLOOR_SIDE_PANEL) / 2;
            const y =
                -selectedInstalationPoint.centerPoint?.coordinates[1] * newScale +
                (windowSize.height - sizes.HEADER_HEIGHT) / 2;

            setScale({
                stageScale: newScale,
                stageX: x,
                stageY: y,
            });
        }
    }, [selectedInstalationPoint, imageScale, splitWidth, windowSize, followIPoint]);

    useEffect(() => {
        if (mainLocationsByFloor && mainLocationsByFloor[selectedFloor]?.scale && currentFloorInfo?.scale) {
            const res = mainLocationsByFloor[selectedFloor]?.scale / currentFloorInfo?.scale;
            setImageScale(res);
            const vector = geoMatrixConverter(0, 0, currentFloorInfo.plan2geo);
            if (vector && vector[0]) {
                const res = geoMatrixConverter(vector[0], vector[1], mainLocationsByFloor[selectedFloor].geo2plan);
                setImageOffset(res);
            }
        }
    }, [mainLocationsByFloor, currentFloorInfo, selectedFloor]);

    const onStageWheel = (e) => {
        e.evt.preventDefault();
        setScale(handleWheelScale(e));
    };

    const onStageDblClick = (e) => {
        e.evt.preventDefault();
        const stageScale = fitStage(e, splitWidth - sizes.FLOOR_SIDE_PANEL) / imageScale;
        setScale({
            stageScale,
            stageX: 0,
            stageY: 0,
        });
    };

    const onStageClick = (e) => {
        const emptySpace = e.target === e.target.getStage();
        if (emptySpace) {
            setSelectedInstalationPointTrans(null);
        }
    };

    const onStageDragStart = (e) => {
        const container = e.target.getStage().container();
        container.style.cursor = 'grabbing';
    };

    const onStageDragEnd = (e) => {
        const container = e.target.getStage().container();
        container.style.cursor = 'default';
    };

    const onImageLayerClick = () => setSelectedInstalationPointTrans(null);

    return (
        <LocationDispalyWrapper>
            {imageLoading && (
                <ImageLoadingWrapper>
                    <Spinner label={t('Image loading...')} labelPosition='top' size={SpinnerSize.large} />
                </ImageLoadingWrapper>
            )}

            <Stage
                draggable
                onDragStart={onStageDragStart}
                onDragEnd={onStageDragEnd}
                width={splitWidth - sizes.FLOOR_SIDE_PANEL}
                height={windowSize.height - sizes.HEADER_HEIGHT}
                onWheel={onStageWheel}
                onDblClick={onStageDblClick}
                scaleX={scale.stageScale}
                scaleY={scale.stageScale}
                onClick={onStageClick}
                x={scale.stageX}
                y={scale.stageY}
            >
                <Provider store={store}>
                    <Layer
                        x={imageOffset[0]}
                        y={imageOffset[1]}
                        scaleX={imageScale}
                        scaleY={imageScale}
                        onClick={onImageLayerClick}
                        id='imageLayer'
                    >
                        <ImageForKonva setImageLoading={setImageLoading} imageString={image} />
                    </Layer>

                    {showPassWayLayer && (
                        <Layer>
                            <PassWays stageScale={scale.stageScale} />
                        </Layer>
                    )}
                    {showIPointLayer && (
                        <Layer>
                            <CountingSensors stageScale={scale.stageScale} />
                        </Layer>
                    )}
                    {showIntersectionsLayer && (
                        <Layer>
                            <IntersectionsForDisplay stageScale={scale.stageScale} />
                        </Layer>
                    )}
                </Provider>
            </Stage>
        </LocationDispalyWrapper>
    );
});

export default LocationDispaly;
