import { xovisGetRequest } from '../../../../../api/xovis/xovis.api';
import { getCompanyUploadUrlPart } from '../../../../../constants/constants';
import getValidIdForSensor from '../../../../../utils/updateSensor/getValidIdForSensor';

/**
 * Получение конфигурации датчика
 * @param {object} sensor Объект сенсора
 * @returns объект с ошибкой или объект с конфигурацией датчика
 */
const getXovisConfig = async (args) => {
    const MAX_AGENTS_FOR_XOVIS = 8;
    const { sensor, uploadUrl } = args;

    const config = await xovisGetRequest({
        url: `http://${sensor.ip}:${sensor.port}/api/config`,
        username: sensor.username,
        password: sensor.password,
    });

    if (!config.error) {
        const oParser = new DOMParser();
        const configXML = oParser.parseFromString(config, 'text/xml');

        const multiSensorSlaves = [];
        const passWayNames = [];
        let companyDatapushAgent = null;
        let emptyDatapushAgentId = null;
        let isMultiSensor = false;

        const datapushAgents = configXML.querySelectorAll('datapush > agent');

        const isMulti = configXML.querySelector('settings > multisensor > enable');

        const slaves = configXML.querySelectorAll('settings > multisensor > slaves > slave');

        const countLines = configXML.querySelectorAll('cntline');

        if (isMulti?.textContent === 'true') {
            isMultiSensor = true;
        }

        countLines?.forEach((countLine) => {
            if (countLine.attributes.hasOwnProperty('name')) {
                const name = countLine.attributes.name.value;
                passWayNames.push(name);
            }
        });

        slaves?.forEach((slave) => {
            const slaveIp = slave.querySelector('ip');
            if (slaveIp) {
                multiSensorSlaves.push(slaveIp.textContent);
            }
        });

        datapushAgents.forEach((agent) => {
            const connectorUrl = agent.querySelector('connector[type="http"] > url')?.textContent || '';

            if (connectorUrl.includes(uploadUrl?.host ?? getCompanyUploadUrlPart())) {
                companyDatapushAgent = {
                    id: Number(agent.attributes.id?.value),
                    url: connectorUrl,
                    interval: agent.querySelector('interval')?.textContent,
                    granularity: agent.querySelector('granularity')?.textContent,
                };
            }
        });

        const freeId = getValidIdForSensor(datapushAgents);
        if (freeId.length > 0 && freeId[0] < MAX_AGENTS_FOR_XOVIS) {
            emptyDatapushAgentId = freeId[0];
        }

        return {
            companyDatapushAgent,
            emptyDatapushAgentId,
            multiSensorSlaves,
            isMultiSensor,
            passWayNames,
            rawConfig: config,
        };
    }

    return { error: 'Ann error accrued while getting config' };
};

export default getXovisConfig;
