import { tdGetRequest } from './td.api';
import getLayerCoords from './tools/getLayerCoords';

/**
 * Получение информации о зоне пребывания
 * @param {string} ip IP датчика
 * @param {number} port Порт датчика
 * @param {string} slug Slug зоны
 * @param {number} timeout Таймаут запроса
 * @returns Promise с ошибкой или информацией о зоне
 */
const getTdStayZoneBySlug = async (args) => {
    const { ip, port, slug, timeout } = args;

    const stayZone = await tdGetRequest({
        url: `http://${ip}:${port}/view_zone_vfr?zone=${slug}`,
        timeout,
    });

    if (!stayZone.error) {
        const oParser = new DOMParser();
        const stayZoneHTML = oParser.parseFromString(stayZone, 'text/html');

        const zoneName = stayZoneHTML.querySelector('#Zonename')?.value;

        const zoneLocationCoords = getLayerCoords(stayZoneHTML, 'Area_text');

        if (zoneName && zoneLocationCoords) {
            return [{ marker: `${zoneName}`, coordinates: zoneLocationCoords }];
        } else {
            return {
                error: 'An error occurred while getting stay zone data.',
            };
        }
    }

    return {
        error: 'An error occurred while getting stay zone data.',
    };
};

export default getTdStayZoneBySlug;
