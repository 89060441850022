import { cloneDeep } from 'lodash';

/**
 * Функция для получения новой конфигурации сервисов
 * @param {number | null} availableServerIdToUpdate  ID Доступного для обновления сервера компании
 * @param {object} rawServiceConfig Конфигурация сервисов
 * @param {string | null} accessKey ключ для выгрузки
 * @returns Новую конфигурацию сервисов
 */
const getDataForReportServerUpdate = (availableServerIdToUpdate, rawServiceConfig, accessKey, uploadUrl) => {
    if (availableServerIdToUpdate && accessKey) {
        const configCopy = cloneDeep(rawServiceConfig);
        const prefix = availableServerIdToUpdate === 2 ? '2' : '';

        configCopy[`serverip${prefix}`] = uploadUrl?.host;
        configCopy[`serverport${prefix}`] = 443;
        configCopy[`path${prefix}`] = `/fpc/v1/td/${accessKey}`;
        configCopy[`Domainname${prefix}`] = 1;

        if (availableServerIdToUpdate === 2) {
            configCopy[`doubleIP`] = 1;
        }

        return configCopy;
    }
    return null;
};

export default getDataForReportServerUpdate;
