import { xovisGetRequest } from '../../../../../api/xovis/xovis.api';

/**
 * Получение информации линиях logics push (по ним происходит отправка данных)
 * @param {object} sensor объект сенсора
 * @param {boolean} isMultisensor мульти сенсор или нет
 * @returns Объект с ошибкой или объект с информацией о линиях
 */
const getXovisTemplatesV5 = async (args) => {
    const { sensor, isMultisensor } = args;

    const data = await xovisGetRequest({
        url: `http://${sensor.ip}:${sensor.port}/api/v5/${
            isMultisensor ? 'multisensor' : 'singlesensor'
        }/analysis/logics/templates`,
        username: sensor.username,
        password: sensor.password,
    });

    if (!data.error && data) {
        return {
            ...data,
        };
    }
    return {
        error: 'Ann error accrued while getting info',
    };
};

export default getXovisTemplatesV5;
