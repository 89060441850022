import axios from 'axios';
import { cloneDeep } from 'lodash';
import generateVivotekDataForUploadRequest from './generateVivotekDataForUploadRequest';
import { getCompanyName } from '../../../../constants/constants';

/**
 * Функция для получения данных с датчика и отправки из на бэк
 * @param {object} sensor объект сенсора
 * @param {number} dateFromInMillis date_from
 * @param {number} dateToInMillis date_to
 * @param {number} timeout таймаут
 * @returns ответ в формате {sensor, error}
 */
const vivotekWithDateFieldsRequestHelper = async (options) => {
    const { sensor, dateFromInMillis, dateToInMillis, timeout } = options;

    const sensorCopy = cloneDeep(sensor);

    const dateInterval = { dateFrom: dateFromInMillis, dateTo: dateToInMillis };

    if (sensorCopy.vivotekDataUploadUrl) {
        sensorCopy.orderCount += 1;
        const dataForGet = generateVivotekDataForUploadRequest({
            dateFromInMillis,
            dateToInMillis,
            sensor: sensorCopy,
        });
        const dataFromSensor = await axios
            .get(dataForGet.url, {
                timeout,
                auth: {
                    username: sensorCopy.user || 'admin',
                    password: sensorCopy.pass,
                },
            })
            .then((response) => response.data)
            .then((data) => ({
                sensor: { ...sensorCopy },
                dataFromVivotek: data,
            }))
            .catch((error) => ({
                sensor: { ...sensorCopy },
                error: error.message || 'Get data error',
            }));

        if (!dataFromSensor.error) {
            const postToLambdaRes = await axios
                .post(
                    sensorCopy.vivotekDataUploadUrl,
                    dataFromSensor.dataFromVivotek,
                    {
                        timeout,
                        auth: {
                            username: sensorCopy.user || 'admin',
                            password: sensorCopy.pass,
                        },
                    },
                )
                .then((response) => response.data)
                .then(() => ({ sensor: { ...sensorCopy } }))
                .catch((error) => ({
                    sensor: { ...sensorCopy },
                    error: error.message || 'Post to lambda failed',
                }));

            if (!postToLambdaRes.error) {
                sensorCopy.successOrderCount += 1;
                return { sensor: { ...sensorCopy } };
            } else {
                sensorCopy.failedIntervals.push(dateInterval);
                return {
                    sensor: { ...sensorCopy },
                    error: postToLambdaRes.error,
                };
            }
        } else {
            sensorCopy.failedIntervals.push(dateInterval);
            return { sensor: { ...sensorCopy }, error: dataFromSensor.error };
        }
    } else {
        return {
            sensor: { ...sensorCopy },
            error: `No ${getCompanyName()} url`,
        };
    }
};

export default vivotekWithDateFieldsRequestHelper;
