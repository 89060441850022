import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    showFloorsLabel: false,
    showFloorsLayer: true,
    showZonesLabel: false,
    showZonesLayer: true,
    showExceptionsLabel: false,
    showExceptionsLayer: true,
    showMasksLabel: false,
    showMasksLayer: true,
    showPassWayLabel: false,
    showPassWayLayer: true,
};

const screenshotViewReducer = createSlice({
    name: 'screenshotViewReducer',
    initialState,
    reducers: {
        // Показать/скрыть лейбел для полов
        setShowFloorsLabel: (state, action) => {
            state.showFloorsLabel = action.payload;
        },

        // Показать/скрыть слой с полами
        setShowFloorsLayer: (state, action) => {
            state.showFloorsLayer = action.payload;
        },

        // Показать/скрыть лейбел для зон
        setShowZonesLabel: (state, action) => {
            state.showZonesLabel = action.payload;
        },

        // Показать/скрыть лейбел для зон
        setShowZonesLayer: (state, action) => {
            state.showZonesLayer = action.payload;
        },
        // Показать/скрыть лейбел для исключений
        setShowExceptionsLabel: (state, action) => {
            state.showExceptionsLabel = action.payload;
        },

        // Показать/скрыть лейбел для исключений
        setShowExceptionsLayer: (state, action) => {
            state.showExceptionsLayer = action.payload;
        },
        // Показать/скрыть лейбел для масок
        setShowMasksLabel: (state, action) => {
            state.showMasksLabel = action.payload;
        },

        // Показать/скрыть лейбел для масок
        setShowMasksLayer: (state, action) => {
            state.showMasksLayer = action.payload;
        },
        // Показать/скрыть лейбел для проходов
        setShowPassWayLabel: (state, action) => {
            state.showPassWayLabel = action.payload;
        },

        // Показать/скрыть лейбел для проходов
        setShowPassWayLayer: (state, action) => {
            state.showPassWayLayer = action.payload;
        },

        resetScreenshotViewState: () => initialState,
    },
});

export const {
    setShowFloorsLabel,
    setShowFloorsLayer,
    setShowZonesLabel,
    setShowZonesLayer,
    setShowExceptionsLabel,
    setShowExceptionsLayer,
    setShowMasksLabel,
    setShowMasksLayer,
    setShowPassWayLabel,
    setShowPassWayLayer,
    resetScreenshotViewState,
} = screenshotViewReducer.actions;

export default screenshotViewReducer.reducer;
