/**
 *
 * @param {{date_from: string; date_to: string}[]} array Массивы любых объектов у которых есть date_from и date_to
 * @returns
 */
const getExtremeDates = (array) => {
    if (array.length) {
        let minDate = array[0].date_from;
        let maxDate = array[0].date_to;
        array.forEach((element) => {
            if (new Date(element.date_to) > new Date(maxDate)) {
                maxDate = element.date_to;
            }
            if (new Date(element.date_from) < new Date(minDate)) {
                minDate = element.date_from;
            }
        });

        return [minDate, maxDate];
    }
    return [];
};

export default getExtremeDates;
