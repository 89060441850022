/**
 * Функция для обрабокт всех полей с одого правилла на Vivotek
 * @param {array} data массив всех полей
 * @param {string} marker название правила
 * @returns массив правил
 */
const parseVivotekFieldCoords = (data, marker) => {
    const result = [];
    data.forEach((coords, i) => {
        let markerValue = '';
        if (i === 0) {
            markerValue = marker;
        } else {
            markerValue = marker + i;
        }
        const parsedCoords = [];
        coords.forEach((element) => {
            parsedCoords.push([element.x, element.y]);
        });
        result.push({ marker: markerValue, coordinates: parsedCoords });
    });

    return result;
};

export default parseVivotekFieldCoords;
