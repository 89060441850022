import axios from 'axios';

/**
 * Post запрос к датчику TD
 * @param {string} url Урл на который нужно сделать запрос
 * @param {object} payload Урл на который нужно сделать запрос
 * @param {string} username Логин датчика
 * @param {string} password Пароль на датчике
 * @param {number} timeout timeout
 * @returns Данные или объект с ошибкой error
 */
export const megacountPostRequest = async (args) => {
    const { url, payload, username, password, timeout = 25000 } = args;
    try {
        const data = await axios
            .post(url, payload, {
                timeout,
                auth: {
                    username: username || 'admin',
                    password,
                },
            })
            .then((response) => response.data);
        return data;
    } catch (error) {
        return {
            error,
        };
    }
};

/**
 * GET запрос к датчику
 * @param {string} url Урл на который нужно сделать запрос
 * @returns Данные или объект с ошибкой error
 */
export const megacountGetRequest = async (args) => {
    const { url } = args;
    try {
        const data = await axios
            .get(url, { timeout: 15000 })
            .then((response) => {
                return response.data;
            });

        return data;
    } catch (error) {
        return { error };
    }
};
