import styled from 'styled-components/macro';

export const datePickerHiddenStatusMessage = {
    statusMessage: {
        display: 'none',
    },
};

export const SpinnerWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
`;
