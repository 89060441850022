import { cloneDeep } from 'lodash';
/**
 * Функция для изменения координат слоев, в зависимости от скейла скрина
 * @param {object} sensor объект сенсора
 * @returns измененный объект сенсора
 */
const changeCoordinatesByScale = (sensor) => {
    const sensorCopy = cloneDeep(sensor);
    const scaledLayers = {};

    Object.keys(sensorCopy.layers).forEach((key) => {
        scaledLayers[key] = [];
        sensorCopy.layers[key].forEach((element) => {
            const scaledCoords = [];
            element.coordinates.forEach((coord) => {
                scaledCoords.push([coord[0] * sensor.screenScale.x, coord[1] * sensor.screenScale.y]);
            });
            scaledLayers[key].push({ ...element, coordinates: scaledCoords });
        });
    });

    sensorCopy.layers = scaledLayers;

    return sensorCopy;
};

export default changeCoordinatesByScale;
