import { services } from '../../constants/services';

/**
 * Функция для получения объекта хостов, учитывая данные из localStorage
 * @param {object} locales локали
 * @returns объект с хостами
 */
const createHostsObject = (locales) => {
    const newHostsObject = {};
    const localStorageUrls = localStorage.getItem('hosts');

    services.forEach((service) => {
        newHostsObject[service] = {
            checked: false,
            url: locales.services[service],
        };
    });

    if (!localStorageUrls) {
        localStorage.setItem('hosts', JSON.stringify(newHostsObject));
        return newHostsObject;
    }

    if (localStorageUrls.includes('true')) {
        const localStorageUrlsObject = JSON.parse(localStorageUrls);

        Object.keys(localStorageUrlsObject).forEach((element) => {
            if (localStorageUrlsObject[element].checked) {
                newHostsObject[element] = localStorageUrlsObject[element];
            }
        });
        return newHostsObject;
    }
    return newHostsObject;
};

export default createHostsObject;
