import axios from 'axios';
import getXovisScreenV5 from './getXovisScreenV5';

const errorMessage = 'Ann error accrued while getting screens';

/**
 * Получение скриншотов от датчика
 * @param {object} sensor  Объект датчика
 * @param {number} timeout  timeout
 * @returns Объект с ошибкой или скрины
 */
const getScreensV5 = (args) => {
    const { sensor, timeout } = args;

    const requests = [
        getXovisScreenV5({ sensor, isMultisensor: false, timeout }),
    ];

    if (sensor.isMultiSensor) {
        requests.push(
            getXovisScreenV5({ sensor, isMultisensor: true, timeout }),
        );
    }

    return axios
        .all(requests)
        .then(
            axios.spread((single, multi) => {
                if (sensor.isMultiSensor) {
                    if (!single.error && !multi.error) {
                        return {
                            screen: single.screen,
                            multiSensorScreen: multi.screen,
                        };
                    }
                } else {
                    if (!single.error) {
                        return {
                            screen: single.screen,
                            multiSensorScreen: '',
                        };
                    }
                }
                return {
                    error: errorMessage,
                };
            }),
        )
        .catch(() => {
            return {
                error: errorMessage,
            };
        });
};

export default getScreensV5;
