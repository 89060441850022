import { xovisGetRequest } from '../../../../../api/xovis/xovis.api';

const getXovisMultisensorSlavesV5 = async (args) => {
    const { sensor } = args;

    const multisensorSlaves = await xovisGetRequest({
        url: `http://${sensor.ip}:${sensor.port}/api/v5/multisensor/sensors`,
        username: sensor.username,
        password: sensor.password,
    });

    if (!multisensorSlaves.error) {
        const slaveIps = (multisensorSlaves.sensors || []).map(
            (element) => element.ip_address,
        );

        return {
            slaves: multisensorSlaves.sensors || [],
            slaveIps,
        };
    }

    return {
        error: 'Ann error accrued while getting multisensor slaves',
    };
};

export default getXovisMultisensorSlavesV5;
