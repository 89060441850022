import { createContext, memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getInstalationPointsThunk,
    getLocationInfoThunk,
    getSensorsAndPointsBoundlesThunk,
    resetAvailableBoundles,
    updateAvailableBoundleByIPointId,
    updateAvailableBoundleByIPointIdThunk,
    setBatchDataProcessing,
    setSelectedInstalationPoint,
    setSensorsAndPointsBoundles,
    storeIPointForBoundleInfoModal,
    toggleFollowIPoint,
    getPlStructureThunk,
    storeInitialLocationProps,
    storeSelectedInstallationPoints,
    toggleBatchUrlUpdateModalStatus,
    storeSensorRelationsById,
} from './installationPointsReducer';
import { gutterStyles, StyledSplit, TableWrapper, LocationWarningMessage } from './installationPoints.styles';
import FloorInformation from './components/floorInformation/floorInformation';
import {
    updateSensorConfigurationThunk,
    getSensorsThunk,
    setSensorConfigurationUpdated,
    batchSensorsUpdate,
    getSensorsDataThunk,
} from '../countingSensors/countingSensorsReducer';
import { useNavigate } from 'react-router';
import { showErrorNotification, showSuccessNotification } from '../../generalReducer';
import { getAllIntersectionsThunk } from '../intersectionsForDisplay/intersectionsForDisplayReducer';
import { resetBoundleInfoReducer } from '../modals/boundleInfoModal/boundleInfoModalReducer';
import { resetBatchStore } from '../modals/batchDownload/batchDownloadReducer';
import FloorPlan from '../floorPlan/floorPlan';
import { toggleCreateSensorModalOpen } from '../modals/modalCreatorReducer';
import { getProjectLocations } from '../ProjectLocations/ProjectLocations.reducer';
import usePrepareDataForBatchUpdate from './hooks/usePrepareDataForBatchUpdate';
import useSensorRelationsById from '../../hooks/useSensorRelationsById';

/**
 * Компонент для отрисовки информации о локации
 */
export const InstallationPointsContext = createContext({});
const InstalationPoints = memo(() => {
    const {
        instalationPoints,
        instalationPointsOnCurrentFloor,
        selectedInstalationPoint,
        locationInfo,
        currentFloorInfo,
        selectedFloor,
        showIPointLayer,
        locationId,
        sensorsAndPointsBounles,
        locationIdFromMapServiceLink,
        mapServiceLink,
        accessKey,
        mainLocation,
        sensorsAndPointsBounlesBySensorId,
        instalationPointsById,
        availableBundlesByIPointId,
        isBoundlesFetching,
        isBatchDataProcessing,
        mainLocationsByFloor,
        iPointForBoundleInfoModal,
        followIPoint,
        plStructure,
        selectedInstallationPoints,
        extendedSelectedIPointsById,
        batchUrlUploadModalStatus,
        sensorRelationsById,
    } = useSelector((state) => state.instalationPointsReducer);
    const { storeUrls, token, storeHosts, windowSize } = useSelector((state) => state.generalReducer);
    const {
        sensors,
        sensorsConfigurations,
        sensorsById,
        isSensorConfigurationUpdated,
        isSensorsFetching,
        slavesByIp,
        shouldStoreSensors,
        sensorUploadUrlsBySensorType,
    } = useSelector((state) => state.countingSensorsReducer);
    const {
        showIntersectionsLayer,
        passWaysAndIPointsRelations,
        allIntersections,
        isRealationsFetching,
        passWaysAndIPointsRelationsByIPointMarker,
    } = useSelector((state) => state.intersectionsForDisplayReducer);
    const { selectedPLData, projectLocations } = useSelector((state) => state.projectLocationsReducer);
    const { passWaysOnCurrentFloor, showPassWayLayer } = useSelector((state) => state.passWaysReducer);
    const { isItPossibleToCloseModal, startBatchUpload } = useSelector((state) => state.batchDownloadReducer);
    const { createSensorModalOpen } = useSelector((state) => state.modalCreatorReducer);
    const [splitWidth, setSplitWidth] = useState(windowSize.width * 0.5);
    const [planWidthInPercents, setPlanWidthInPercents] = useState(50);
    const { selectedProjectId } = useSelector((state) => state.projectsReducer);

    usePrepareDataForBatchUpdate();

    const sensorRelations = useSensorRelationsById({});

    const dispatch = useDispatch();
    const nav = useNavigate();

    useEffect(() => {
        const getObjectName = (marker) => {
            const dataObject = plStructure?.core?.elements_admin_data_objects?.find((item) => item.marker === marker);
            return dataObject?.name || null;
        };
        dispatch(
            storeSensorRelationsById(
                Object.entries(sensorRelations).reduce((acc, [sensorId, relationsByType]) => {
                    acc[sensorId] = {
                        ...Object.entries(relationsByType).reduce((acc, [objectType, relations]) => {
                            acc[objectType] = relations.map((relation) => {
                                const name = getObjectName(relation.dataobj_marker);
                                const fullText = name
                                    ? `${relation.dataobj_marker} (${name})`
                                    : relation.dataobj_marker;
                                return { ...relation, marker_and_name: fullText };
                            });
                            return acc;
                        }, {}),
                    };
                    return acc;
                }, {}),
            ),
        );
    }, [dispatch, sensorRelations, plStructure]);

    useEffect(() => {
        selectedPLData && dispatch(storeInitialLocationProps(selectedPLData));
        // eslint-disable-next-line
    }, [selectedPLData]);

    useEffect(() => {
        token &&
            storeUrls.PROJECT_LOCATIONS_URL?.url &&
            selectedProjectId &&
            !projectLocations.length &&
            dispatch(getProjectLocations());
        // eslint-disable-next-line
    }, [selectedProjectId, projectLocations, storeUrls.PROJECT_LOCATIONS_URL, token]);

    useEffect(() => {
        if (locationId && token && !plStructure) {
            dispatch(getPlStructureThunk());
        }
        // eslint-disable-next-line
    }, [locationId, token]);

    useEffect(() => {
        setSplitWidth((windowSize.width * planWidthInPercents) / 100);
    }, [windowSize, planWidthInPercents]);

    useEffect(() => {
        if (token && storeUrls.LOCATION_INFO_URL.url && mapServiceLink && locationId && locationInfo.length === 0) {
            dispatch(getLocationInfoThunk({ mapServiceLink, token, nav }));
        }
        // eslint-disable-next-line
    }, [mapServiceLink, token, storeUrls, locationId]);

    useEffect(() => {
        if (locationInfo.length > 0 && instalationPoints.length === 0) {
            dispatch(
                getInstalationPointsThunk({
                    url: `${storeUrls.INSTALATION_POINTS.url}?project_location_id=${locationId}`,
                    token,
                    mapServiceLink,
                    nav,
                }),
            );
        }
        // eslint-disable-next-line
    }, [locationInfo]);

    useEffect(() => {
        if (instalationPoints.length > 0 && sensors.length === 0 && shouldStoreSensors) {
            dispatch(getSensorsThunk(storeUrls, token, nav, locationId));
        }
        // eslint-disable-next-line
    }, [instalationPoints, shouldStoreSensors]);

    useEffect(() => {
        if (instalationPoints.length > 0 && sensorsAndPointsBounles.length === 0 && !isBoundlesFetching) {
            dispatch(getSensorsAndPointsBoundlesThunk());
        }
        // eslint-disable-next-line
    }, [instalationPoints]);

    useEffect(() => {
        if (
            (instalationPoints.length > 0 && sensors.length > 0 && sensorsAndPointsBounles.length > 0) ||
            isSensorConfigurationUpdated
        ) {
            dispatch(
                updateAvailableBoundleByIPointIdThunk({
                    instalationPoints,
                    sensorsAndPointsBounles,
                    sensorsById,
                    accessKey,
                    isSensorConfigurationUpdated,
                }),
            );
        }

        // eslint-disable-next-line
    }, [
        instalationPoints,
        sensorsById,
        sensorsAndPointsBounles,
        isSensorConfigurationUpdated,
        sensors,
        sensorUploadUrlsBySensorType,
    ]);

    useEffect(() => {
        if (
            locationId &&
            token &&
            Object.keys(storeHosts).length > 0 &&
            Object.keys(storeUrls).length > 0 &&
            allIntersections.length === 0
        ) {
            dispatch(getAllIntersectionsThunk());
        }
        // eslint-disable-next-line
    }, [locationId, token, storeHosts, storeUrls]);

    const splitDragEnd = (sizes) => {
        setPlanWidthInPercents(sizes[0]);
    };

    const setSelectedInstalationPointTrans = (selectedInstalationPoint) => {
        dispatch(setSelectedInstalationPoint(selectedInstalationPoint));
    };

    const updateSensorConfigurationThunkTrans = (options) => {
        const { data, sensorType, iPointId, availableBundlesByIPointId, sensorId } = options;
        dispatch(
            updateSensorConfigurationThunk({
                data,
                sensorType,
                iPointId,
                availableBundlesByIPointId,
                sensorId,
                sensorsById,
            }),
        );
    };

    const toggleCreateSensorModalOpenTrans = (value) => () => {
        dispatch(toggleCreateSensorModalOpen(value));
    };

    const showErrorNotificationTrans = (message) => {
        dispatch(showErrorNotification({ show: true, message }));
    };

    const getSensorsAndPointsBoundlesThunkTrans = () => {
        dispatch(getSensorsAndPointsBoundlesThunk());
    };

    const showSuccessNotificationTrans = (message) => {
        dispatch(showSuccessNotification({ show: true, message }));
    };

    const setSensorConfigurationUpdatedTrans = (value) => {
        dispatch(setSensorConfigurationUpdated(value));
    };

    const updateAvailableBoundleByIPointIdTrans = (options) => {
        //const {iPointId, value} = options
        dispatch(updateAvailableBoundleByIPointId(options));
    };

    const getSensorsThunkTrans = () => {
        dispatch(getSensorsThunk(storeUrls, token, nav, locationId));
    };

    const setSensorsAndPointsBoundlesTrans = (data) => {
        dispatch(setSensorsAndPointsBoundles(data));
    };

    const resetAvailableBoundlesTrans = () => {
        dispatch(resetAvailableBoundles());
    };

    const setBatchDataProcessingTrans = (data) => {
        dispatch(setBatchDataProcessing(data));
    };

    const storeIPointForBoundleInfoModalTrans = (iPoint) => {
        dispatch(storeIPointForBoundleInfoModal(iPoint));
    };

    const resetBoundleInfoReducerTrans = () => {
        dispatch(resetBoundleInfoReducer());
    };

    const resetBatchStoreTrans = () => {
        dispatch(resetBatchStore());
    };

    const toggleFollowIPointTrans = (value) => {
        dispatch(toggleFollowIPoint(value));
    };

    const storeSelectedInstallationPointsTrans = (value) => {
        dispatch(storeSelectedInstallationPoints(value));
    };

    const batchSensorsUpdateTrans = () => {
        dispatch(batchSensorsUpdate());
    };

    const toggleBatchUrlUpdateModalStatusTrans = (value) => {
        dispatch(toggleBatchUrlUpdateModalStatus(value));
    };

    const getSensorsDataThunkTrans = () => {
        dispatch(getSensorsDataThunk());
    };

    const gutterStyle = () => {
        return gutterStyles;
    };

    const InstalationPointsContextValue = {
        batchUrlUploadModalStatus,
        startBatchUpload,
        iPointForBoundleInfoModal,
        locationInfo,
        selectedFloor,
        splitWidth,
        sensorsConfigurations,
        sensors,
        accessKey,
        windowSize,
        locationIdFromMapServiceLink,
        instalationPointsOnCurrentFloor,
        selectedInstalationPoint,
        showIPointLayer,
        passWaysOnCurrentFloor,
        locationId,
        isItPossibleToCloseModal,
        currentFloorInfo,
        instalationPoints,
        sensorsById,
        isSensorsFetching,
        isSensorConfigurationUpdated,
        showPassWayLayer,
        allIntersections,
        isRealationsFetching,
        passWaysAndIPointsRelationsByIPointMarker,
        mainLocation,
        sensorsAndPointsBounles,
        sensorsAndPointsBounlesBySensorId,
        instalationPointsById,
        showIntersectionsLayer,
        availableBundlesByIPointId,
        isBatchDataProcessing,
        slavesByIp,
        mainLocationsByFloor,
        selectedInstallationPoints,
        extendedSelectedIPointsById,
        sensorRelationsById,
        sensorUploadUrlsBySensorType,
        setSensorsAndPointsBoundlesTrans,
        updateAvailableBoundleByIPointIdTrans,
        storeSelectedInstallationPointsTrans,
        showErrorNotificationTrans,
        showSuccessNotificationTrans,
        setSelectedInstalationPointTrans,
        updateSensorConfigurationThunkTrans,
        setSensorConfigurationUpdatedTrans,
        toggleBatchUrlUpdateModalStatusTrans,
        getSensorsThunkTrans,
        resetAvailableBoundlesTrans,
        setBatchDataProcessingTrans,
        storeIPointForBoundleInfoModalTrans,
        resetBoundleInfoReducerTrans,
        batchSensorsUpdateTrans,
        resetBatchStoreTrans,
        passWaysAndIPointsRelations,
        toggleFollowIPointTrans,
        followIPoint,
        getSensorsAndPointsBoundlesThunkTrans,
        toggleCreateSensorModalOpenTrans,
        getSensorsDataThunkTrans,
        createSensorModalOpen,
    };

    return (
        <InstallationPointsContext.Provider value={InstalationPointsContextValue}>
            {mapServiceLink ? (
                <StyledSplit onDragEnd={splitDragEnd} gutterStyle={gutterStyle}>
                    <FloorPlan splitWidth={splitWidth} />
                    <TableWrapper>
                        <FloorInformation />
                    </TableWrapper>
                </StyledSplit>
            ) : (
                <LocationWarningMessage>Location not selected</LocationWarningMessage>
            )}
        </InstallationPointsContext.Provider>
    );
});

export default InstalationPoints;
