/**
 * Функция для генерации рандомных id
 * @param {number} n кол-во символов в id
 * @returns string
 */
const idGenerator = (n = 8) => {
    const getRandomInt = (min, max) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min)) + min;
    };
    const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    return new Array(n)
        .fill(1)
        .map(() => chars[getRandomInt(0, chars.length)])
        .join('');
};

export default idGenerator;
