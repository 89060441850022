import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    setInstalationPointsOnCurrentFloor,
    setSelectedInstalationPoint,
} from '../../instalationPoints/installationPointsReducer';
import { setPassWaysOnCurrentFloor } from '../../passWays/passWaysReducer';

// Кастомный хук для обновления прорходов и точек установок на выбранном этаже
const useFloorChange = () => {
    const { selectedFloor, instalationPoints } = useSelector((state) => state.instalationPointsReducer);
    const { passWays } = useSelector((state) => state.passWaysReducer);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setInstalationPointsOnCurrentFloor([]));
        dispatch(setSelectedInstalationPoint(null));
        dispatch(setPassWaysOnCurrentFloor([]));
        if (instalationPoints.length > 0) {
            const currentFloorIPoints = instalationPoints.filter((element) => element.floor === selectedFloor);
            if (currentFloorIPoints.length > 0) {
                dispatch(setInstalationPointsOnCurrentFloor(currentFloorIPoints));
            }
        }

        if (passWays.length > 0) {
            const currentFloorPassWays = passWays.filter((element) => element.floor === selectedFloor)[0]?.data;

            if (currentFloorPassWays && currentFloorPassWays.length > 0) {
                dispatch(setPassWaysOnCurrentFloor(currentFloorPassWays));
            }
        }
        // eslint-disable-next-line
    }, [selectedFloor, instalationPoints, passWays]);
};

export default useFloorChange;
