import { DateTime } from 'luxon';
import numDaysBetweenDates from '../numDaysBetweenDates';
import { hikvisionPostRequest } from './api';
import { intervalTypes } from './constants/intervalTypes';
import { getUploadDataPattern } from './hikvisionPayloadPatterns';
import processRowUploadData from './processRowUploadData';

/**
 * Функция для получения данных за весь указанный период
 * @param {object} sensor объект сенсора
 * @param {string} dateFrom
 * @param {string} dateTo
 * @param {number} timeout таймаут
 * @returns возвращает данные, необходимые для создания выгрузки с датчика (временныой интервал , количесвто людей)
 */
const hikvisionTimeIntervalHandler = async (options) => {
    const { sensor, dateFrom, dateTo, timeout } = options;
    let dataForUpload = [];
    const daysCount = numDaysBetweenDates(dateFrom, dateTo);
    let start = DateTime.fromISO(dateFrom).startOf('day');
    let error = '';
    let minutesInInterval = null;

    for (let i = 0; i < daysCount; i++) {
        const payloadDateFrom = DateTime.fromISO(start).toFormat('yyyy-MM-dd HH:mm:ss').replace(' ', 'T');
        const payloadDateTo = DateTime.fromISO(start)
            .plus({ minutes: 15 })
            .toFormat('yyyy-MM-dd HH:mm:ss')
            .replace(' ', 'T');

        const payload = getUploadDataPattern
            .replace('<startTime></startTime>', `<startTime>${payloadDateFrom}</startTime>`)
            .replace('<endTime></endTime>', `<endTime>${payloadDateTo}</endTime>`);
        const hikvisionUploadData = await hikvisionPostRequest({
            url: `http://${sensor.ip}:${sensor.port}/ISAPI/System/Video/inputs/channels/${sensor.hikvisionData.channelData.id}/counting/search`,
            username: sensor.user,
            password: sensor.pass,
            timeout: timeout,
            payload,
            headers: { 'Content-Type': 'text/xml' },
        });

        if (!hikvisionUploadData.error) {
            const result = processRowUploadData(hikvisionUploadData.data);
            minutesInInterval = result.minutesInInterval;
            if (intervalTypes[minutesInInterval]) {
                dataForUpload = dataForUpload.concat(result.dataForUpload);
            } else {
                error = error = `Unkown data interval: ${minutesInInterval}`;
            }
        } else {
            error = `Can't get data from ${payloadDateFrom} to ${payloadDateTo}. Error: ${hikvisionUploadData.error}`;
        }
        start = start.plus({ days: 1 });
    }

    return { dataForUpload, sensor, dateFrom, dateTo, error, minutesInInterval };
};

export default hikvisionTimeIntervalHandler;
