import axios from 'axios';

/**
 * Post запрос к датчику TD
 * @param {string} url Урл на который нужно сделать запрос
 * @param {object} payload Урл на который нужно сделать запрос
 * @returns Данные или объект с ошибкой error
 */
export const tdPostRequest = async (args) => {
    const { url, payload } = args;
    try {
        const data = await axios
            .post(url, payload, {
                timeout: 25000,
            })
            .then((response) => response.data);
        return data;
    } catch (error) {
        return {
            error,
        };
    }
};

/**
 * GET запрос к датчику
 * @param {string} url Урл на который нужно сделать запрос
 * @returns Данные или объект с ошибкой error
 */
export const tdGetRequest = async (args) => {
    const { url } = args;
    try {
        const data = await axios
            .get(url, { timeout: 15000 })
            .then((response) => {
                return response.data;
            });

        return data;
    } catch (error) {
        return { error };
    }
};
