import { megacountPostRequest } from './megacount.api';

/**
 * Получения данных калибровки (сюда входит пол)
 * @param {string} ip IP датчика
 * @param {number} port Порт датчика
 * @param {string} username логин датчика
 * @param {string} password пароль датчика датчика
 * @param {number} timeout timeout на запрос
 * @returns Promise результатом которого является объект с данными о калибровки или объект с ошибкой
 */
const getMegacountCalibration = async (args) => {
    const { ip, port, username, password, timeout } = args;

    const calibration = await megacountPostRequest({
        url: `http://${ip}:${port}/calibration`,
        payload: {
            calibration: {
                get: ['counter_installation', 'counter_3d', 'camera_setup'],
            },
        },
        username,
        password,
        timeout,
    });

    if (!calibration.error && calibration.calibration.get) {
        const calibrationData = calibration.calibration.get;
        const floors = [];

        const coordinates = [];
        (calibrationData.counter_installation?.area || []).forEach((point) => {
            coordinates.push([point.x, point.y]);
        });

        if (coordinates.length) {
            floors.push({ marker: 'Floor', coordinates });
        }

        return { calibrationData: calibrationData, floors };
    } else {
        return {
            error: 'Unable to get sensor network settings',
        };
    }
};

export default getMegacountCalibration;
