import { cloneDeep } from 'lodash';
import getExtremeDates from '../getExtremeDates';
import { DateTime } from 'luxon';
import uploadMegacountData from './uploadMegacountData';

/**
 * Функция для нахождения минимального и максимального значения даты на переданных сенсорах. По этой дате и делается единственный запрос.
 * @param {array} sensorsArray массив одинаковых сенсоров, но с разной датой
 * @param {object} sensorsForManagementById объект всех сенсоров
 * @param {number} timeout таймаут
 * @returns массив с обновленными сенсорами
 */
const megacountWithDateFieldsRequestHelper = async (args) => {
    const { sensorsArray, sensorsForManagementById, timeout } = args;
    const firstSensor = sensorsForManagementById[sensorsArray[0].id];
    const result = [];
    let error = '';

    const [minDate, maxDate] = getExtremeDates(sensorsArray);

    const dateInterval = {
        dateFrom: DateTime.fromISO(minDate).toMillis(),
        dateTo: DateTime.fromISO(maxDate).toMillis(),
    };

    if (firstSensor?.layers?.passWays?.length) {
        const uploadResponse = await uploadMegacountData({
            companyUploadServer: firstSensor.megacountData?.companyUploadServer,
            accountSettings: firstSensor.megacountData?.accountSettings,
            username: firstSensor.user,
            password: firstSensor.pass,
            port: firstSensor.port,
            ip: firstSensor.ip,
            dateFrom: minDate,
            dateTo: maxDate,
            timeout,
        });

        if (uploadResponse.error) {
            error = uploadResponse.error;
        }
    } else {
        error = 'No count lines';
    }

    if (!error) {
        sensorsArray.forEach((element) => {
            const sensor = cloneDeep(sensorsForManagementById[element.id]);
            sensor.orderCount = 1;
            sensor.successOrderCount = 1;
            result.push({ sensor: { ...sensor } });
        });
    } else {
        sensorsArray.forEach((element) => {
            const sensor = cloneDeep(sensorsForManagementById[element.id]);
            sensor.orderCount = 1;
            sensor.failedIntervals.push(dateInterval);
            result.push({ sensor: { ...sensor }, error });
        });
    }

    return result;
};

export default megacountWithDateFieldsRequestHelper;
