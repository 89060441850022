import { orderBy } from 'lodash';
import convertIpToNumber from '../../../utils/detailsListHelpers/convertIpToNumber';

/**
 * Функция для сортировки и изменения иконки сортировки у выбранной колонки
 */
export const sortScreensList = (args) => {
    const { columns, columnToSort, items, sensorRelationsBySensorId } = args;

    const sortDirectionMap = {
        true: 'desc',
        false: 'asc',
    };

    const _columns = columns.map((column) => {
        if (column.key === columnToSort.key) {
            return { ...column, isSorted: true, isSortedDescending: !columnToSort.isSortedDescending };
        }
        return { ...column, isSorted: false, isSortedDescending: true };
    });

    const _items = orderBy(
        items,
        (item) => {
            switch (columnToSort.key) {
                case 'sensorAddress':
                    return convertIpToNumber(item.ip);
                case 'sensorRelations':
                    return Boolean(sensorRelationsBySensorId?.[item.id]);
                case 'screens':
                    return Boolean(item.screensInfo?.length);
                case 'date_from':
                    return Boolean(item.screenInfoByDateFrom);
                case 'date_to':
                    return Boolean(item.screenInfoByDateTo);

                default:
                    return item[columnToSort.key];
            }
        },
        sortDirectionMap[columnToSort.isSortedDescending],
    );

    return { columns: _columns, items: _items };
};
