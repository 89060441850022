import { cloneDeep } from 'lodash';
import { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import { IntersectTitle, CustomReactDataSheet, IntersectModalWrapper, IntersectTableWrapper, CustomCell } from './passPointsAndPassWaysIntersect.styled';

/**
 * Модальное окно для отображения пересечений между усами датичика и проходов
 */
const PassPointsAndPassWaysIntersect = memo(() => {
    const { passWaysAndIPointsRelations } = useSelector((state) => state.intersectionsForDisplayReducer);
    const { selectedFloor } = useSelector((state) => state.instalationPointsReducer);
    const [isMouseDown, setIsMouseDown] = useState(false);
    const [selected, setSelected] = useState({ start: null, end: null });

    const intersectionsArray = [
        [
            { readOnly: true, value: 'i_point_marker' },
            { readOnly: true, value: 'line_name' },
            { readOnly: true, value: 'pass_way_marker' },
            { readOnly: true, value: 'pass_way_name' },
        ],
    ];

    passWaysAndIPointsRelations
        .find((element) => element.floor === selectedFloor)
        ?.interactions?.forEach((element) => {
            const arrayForTable = [{ value: element.info.pccMarker }, { value: element.info.lineName }, { value: element.info.passWayMarker }, { value: element.info.passWayName }];
            intersectionsArray.push(arrayForTable);
        });

    const onMouseUp = (value) => () => setIsMouseDown(value);

    const onMouseOver = (props) => (e) => {
        if (isMouseDown) {
            setSelected((prevState) => {
                const newState = cloneDeep(prevState);
                newState['end'] = { i: props.row, j: props.col };
                return newState;
            });
            props.onMouseOver(e);
        }
    };

    const onMouseDown = (props) => (e) => {
        setIsMouseDown(true);
        setSelected({ start: { i: props.row, j: props.col }, end: { i: props.row, j: props.col } });
        props.onMouseDown(e);
    };

    const cellRenderer = (props) => {
        return (
            <CustomCell onMouseDown={onMouseDown(props)} onMouseUp={onMouseUp(false)} onMouseMove={onMouseOver(props)} className={props.className}>
                {props.children}
            </CustomCell>
        );
    };

    const wrapperClickHandler = () => {
        setSelected({ start: null, end: null });
    };
    return (
        <IntersectModalWrapper onClick={wrapperClickHandler}>
            <IntersectTitle>Pass points and Pass ways intersections</IntersectTitle>
            <IntersectTableWrapper>
                <CustomReactDataSheet selected={selected} cellRenderer={cellRenderer} data={intersectionsArray} valueRenderer={(cell) => cell.value} />
            </IntersectTableWrapper>
        </IntersectModalWrapper>
    );
});

export default PassPointsAndPassWaysIntersect;
