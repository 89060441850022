import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    createSensorModalOpen: false,
};

const modalCreatorReducer = createSlice({
    name: 'modalCreatorReducer',
    initialState,
    reducers: {
        // ИЗменение флага для открытия модалки с созданием сенсоров
        toggleCreateSensorModalOpen: (state, action) => {
            state.createSensorModalOpen = action.payload;
        },

        // Обнулление стейта
        resetModalCreatorState: () => initialState,
    },
});

export const { resetModalCreatorState, toggleCreateSensorModalOpen } = modalCreatorReducer.actions;

export default modalCreatorReducer.reducer;
