import { isNumber } from 'lodash';
import { xovisProgressMessages } from '../../../../utils/xovisHelpers/xovisProgressMessages';
import { updateExtendedSelectedIPoint } from '../../../instalationPoints/installationPointsReducer';
import getNewXovisConfiguration from '../../../../utils/xovisHelpers/getNewSensorConfiguration';
import updateXovisSensorConfigurationHelper from './updateXovisSensorConfigurationHelper';

/**
 * Функция для работы с состоянием конкретной связки, получением новой выгрузки
 * на датчике и обновлением выгрузки для датчика
 * @param {*} dispatch функция для обновления
 * @param {*} bundle текущая актуальная связь точки установки и датчика
 * @param {*} iPointId ID точки установки
 * @param {*} accessKey ключ доступа для локации / проекта
 * @param {*} sensorsById объект расширенных данных о датчиках
 * @param {*} uploadUrl объект с информацией по выгрузке
 * @returns promise
 */
const processXovisBatchUrlUpdate = (args) => {
    const { dispatch, bundle, iPointId, accessKey, sensorsById, uploadUrl } = args;

    const promise = new Promise((resolve) => resolve());

    const version = Number(bundle.xovisData?.version.split('.')[0]);

    dispatch(
        updateExtendedSelectedIPoint({
            data: { isFetching: true, isError: false, message: '' },
            iPointId,
        }),
    );

    if (!bundle?.thirdStepError) {
        dispatch(
            updateExtendedSelectedIPoint({
                data: {
                    isFetching: false,
                    isError: false,
                    message: xovisProgressMessages.thirdStepEnabled,
                },
                iPointId,
            }),
        );
        return promise;
    }

    let condition = false;

    if (isFinite(version)) {
        if (version < 5) {
            if (
                !bundle?.firstStepError &&
                bundle?.thirdStepError &&
                (bundle?.xovisData?.data?.config?.companyDatapushServer?.url ||
                    isNumber(bundle?.xovisData?.data?.config?.emptyDatapushAgentId))
            ) {
                condition = true;
            }
        } else if (version >= 5) {
            if (!bundle?.firstStepError && bundle?.thirdStepError) {
                condition = true;
            }
        }
    }

    if (condition) {
        const newConfiguration = getNewXovisConfiguration({
            sensor: bundle,
            accessKey,
            uploadUrl,
        });

        if (newConfiguration) {
            return updateXovisSensorConfigurationHelper({
                sensor: sensorsById[bundle.id],
                payloadBody: newConfiguration,
                showNotifications: false,
                currentBoundle: bundle,
                iPointId,
                dispatch,
                uploadUrl,
            }).then((data) => {
                if (!data?.error) {
                    dispatch(
                        updateExtendedSelectedIPoint({
                            data: {
                                isFetching: false,
                                isError: false,
                                message: 'Success',
                            },
                            iPointId,
                        }),
                    );
                } else {
                    dispatch(
                        updateExtendedSelectedIPoint({
                            data: {
                                message: JSON.stringify(data?.error || 'Something went wrong'),
                                isFetching: false,
                                isError: true,
                            },
                            iPointId,
                        }),
                    );
                }
            });
        } else {
            dispatch(
                updateExtendedSelectedIPoint({
                    data: {
                        isFetching: false,
                        isError: true,
                        message: 'Something went wrong!',
                    },
                    iPointId,
                }),
            );
        }
        return promise;
    }

    dispatch(
        updateExtendedSelectedIPoint({
            data: {
                isFetching: false,
                isError: true,
                message: 'Something went wrong!',
            },
            iPointId,
        }),
    );

    return promise;
};

export default processXovisBatchUrlUpdate;
