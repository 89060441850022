import {
    DefaultButton,
    DetailsList,
    DetailsRow,
    Spinner,
    SpinnerSize,
    Sticky,
    StickyPositionType,
} from '@fluentui/react';
import { cloneDeep } from 'lodash';
import { memo, useContext, useEffect } from 'react';
import { useState } from 'react';
import BatchDownload from '../../../../../modals/batchDownload/batchDownload';
import CreateSensorModal from '../../../../../modals/createSensorModal/createSensorModal';
import ModalCreator from '../../../../../modals/modalCreator';
import UpdateSensorModal from '../../../../../modals/updateSensorModal/updateSensorModal';
import {
    ActiveIcon,
    AddSensorsWrapper,
    ColumnWrapper,
    CustomButton,
    ListWrapper,
    SensorsHeaderWrapper,
    SensorTitle,
    serialNumberErrorRow,
    StyledFilter,
} from './sensorsList.styles';
import { InstallationPointsContext } from '../../../../installationPoints';
import { useTranslation } from 'react-i18next';
import columnSortHandler from '../../../../../../utils/detailsListHelpers/columnSortHandler';
import { sensorsListColumns } from './columns/sensorsListColumns';
import ListContainer from '../../../../../../commonComponents/listContainer';

/**
 * Компонент для отрисовки списка сенсоров
 */
const SensorsList = memo(() => {
    const {
        getSensorsThunkTrans,
        isSensorsFetching,
        setBatchDataProcessingTrans,
        resetBatchStoreTrans,
        isItPossibleToCloseModal,
        startBatchUpload,
        sensorsById,
        sensors,
        getSensorsAndPointsBoundlesThunkTrans,
        toggleCreateSensorModalOpenTrans,
        createSensorModalOpen,
    } = useContext(InstallationPointsContext);
    const [isBatchDownloadModalOpen, setBatchDownloadModalOpen] =
        useState(false);
    const [isUpdateSensorModalOpen, setUpdateSensorModalOpen] = useState(false);
    const [sensorToUpdate, setSensorToUpdate] = useState({});
    const [filteredItems, setFilteredItems] = useState('');
    const [filter, setFilter] = useState('');
    const [columns, setColumns] = useState([]);
    const [columnForSort, setColumnForSort] = useState(null);
    const [possibleToSort, setPossibleToSort] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        if (!isSensorsFetching) setPossibleToSort(true);
    }, [isSensorsFetching]);

    useEffect(() => {
        setFilter('');
        setColumns(sensorsListColumns);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (columnForSort && possibleToSort) {
            const { newItems, newColumns } = columnSortHandler({
                allColumns: columns,
                column: columnForSort,
                listItems: filteredItems,
            });
            setColumns(newColumns);
            setFilteredItems(newItems);
        }
        // eslint-disable-next-line
    }, [columnForSort]);

    useEffect(() => {
        setFilteredItems(
            Object.keys(sensorsById).map((element) => sensorsById[element]),
        );
    }, [sensors, sensorsById, isSensorsFetching]);

    const openUpdateSensorModal = (sensor) => () => {
        const sensorCopy = cloneDeep(sensor);
        setSensorToUpdate(sensorCopy);
        setUpdateSensorModalOpen(true);
    };

    const columnClickHandler = (_, column) => setColumnForSort(column);
    const closeUpdateSensorModal = () => setUpdateSensorModalOpen(false);

    const openBatchDownloadModal = () => setBatchDownloadModalOpen(true);
    const closeBatchDownloadModal = () => {
        if (isItPossibleToCloseModal) {
            setBatchDownloadModalOpen(false);
            resetBatchStoreTrans();
            if (startBatchUpload) {
                getSensorsThunkTrans();
                setBatchDataProcessingTrans(false);
                getSensorsAndPointsBoundlesThunkTrans();
            }
        }
    };

    const filterHandler = (_, newValue) => {
        setFilter(newValue);
        setFilteredItems(() => {
            if (newValue) {
                return Object.keys(sensorsById)
                    .map((element) => sensorsById[element])
                    .filter(
                        (element) =>
                            element.sensor_type.includes(newValue) ||
                            element.ip.includes(newValue) ||
                            element.id.toString().includes(newValue) ||
                            element.serial_number.includes(newValue) ||
                            element.port.toString().includes(newValue),
                    );
            } else {
                return Object.keys(sensorsById).map(
                    (element) => sensorsById[element],
                );
            }
        });
    };

    const renderItemColumn = (item, _, column) => {
        const fieldContent = item[column.fieldName];

        switch (column.key) {
            case 'active':
                return (
                    <ColumnWrapper>
                        <ActiveIcon
                            iconName={
                                item.is_active
                                    ? 'BoxCheckmarkSolid'
                                    : 'BoxMultiplySolid'
                            }
                            newColor={item.is_active ? 'blue' : 'red'}
                        />
                    </ColumnWrapper>
                );
            case 'sensorIp':
                return <ColumnWrapper>{item.ip}</ColumnWrapper>;
            case 'online':
                return (
                    <ColumnWrapper>
                        {item.isConfigurationFetching ? (
                            <Spinner size={SpinnerSize.large} />
                        ) : (
                            <ActiveIcon
                                iconName={
                                    !item.firstStepError
                                        ? 'BoxCheckmarkSolid'
                                        : 'BoxMultiplySolid'
                                }
                                newColor={
                                    !item.firstStepError && item.isFetched
                                        ? 'green'
                                        : 'red'
                                }
                            />
                        )}
                    </ColumnWrapper>
                );
            case 'update':
                return (
                    //disabled={item.serialNumberError ? false : true}
                    <DefaultButton onClick={openUpdateSensorModal(item)}>
                        Update
                    </DefaultButton>
                );
            default:
                return <ColumnWrapper>{fieldContent}</ColumnWrapper>;
        }
    };

    const renderRowHandler = (props) => (
        <DetailsRow
            {...props}
            styles={props.item.serialNumberError ? serialNumberErrorRow : null}
        />
    );

    const onRenderDetailsHeader = (headerProps, defaultRender) => {
        if (!defaultRender) return null;
        return (
            <Sticky
                stickyPosition={StickyPositionType.Header}
                isScrollSynced={true}
                stickyBackgroundColor="transparent"
            >
                <div>{defaultRender(headerProps)}</div>
            </Sticky>
        );
    };

    return (
        <ListWrapper>
            <ModalCreator
                isModalOpen={createSensorModalOpen}
                closeModal={toggleCreateSensorModalOpenTrans(false)}
            >
                <CreateSensorModal />
            </ModalCreator>
            <ModalCreator
                isModalOpen={isBatchDownloadModalOpen}
                closeModal={closeBatchDownloadModal}
            >
                <BatchDownload />
            </ModalCreator>
            <ModalCreator
                isModalOpen={isUpdateSensorModalOpen}
                closeModal={closeUpdateSensorModal}
            >
                <UpdateSensorModal
                    sensorToUpdate={sensorToUpdate}
                    closeUpdateSensorModal={closeUpdateSensorModal}
                />
            </ModalCreator>

            {sensors.length > 0 ? (
                <>
                    <SensorsHeaderWrapper>
                        <SensorTitle>
                            {t('Sensors')} ({filteredItems.length})
                        </SensorTitle>
                        <StyledFilter
                            placeholder={t('Filter')}
                            disabled={isSensorsFetching}
                            value={filter}
                            onChange={filterHandler}
                        />
                    </SensorsHeaderWrapper>
                    <ListContainer>
                        <DetailsList
                            onRenderDetailsHeader={onRenderDetailsHeader}
                            onColumnHeaderClick={columnClickHandler}
                            onRenderRow={renderRowHandler}
                            items={filteredItems}
                            columns={columns}
                            onRenderItemColumn={renderItemColumn}
                            selectionMode={0}
                        />
                    </ListContainer>
                </>
            ) : (
                <div>No sensors</div>
            )}
            <AddSensorsWrapper>
                <CustomButton
                    onClick={toggleCreateSensorModalOpenTrans(true)}
                    text={t('Add sensor')}
                />
                <CustomButton
                    onClick={openBatchDownloadModal}
                    text={t('Batch upload')}
                />
            </AddSensorsWrapper>
        </ListWrapper>
    );
});

export default SensorsList;
