/**
 * Функция для получения id проекта из урла
 * @returns id проекта
 */
const getProjectIdFromUrl = () => {
    let projectId = null;
    const url = window.location.href;
    const regexp = /projects\/\d+/gm;
    const result = url.match(regexp);
    if (result && result.length) {
        projectId = +result[0].split('/')[1];
    }
    return projectId;
};

export default getProjectIdFromUrl;
