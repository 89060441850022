import getXovisConfig from './getXovisConfig';

/**
 * Получение всех необходимых для работы данных для датчика
 * @param {object} sensor объект сенсора
 * @returns объект с ошибкой или объект с данными датчика
 */
const getXovisData = async (args) => {
    const { sensor, uploadUrl } = args;

    const config = await getXovisConfig({ sensor, uploadUrl });

    if (!config.error) {
        return {
            config,
        };
    }

    return { error: 'Ann error accrued while getting data' };
};

export default getXovisData;
