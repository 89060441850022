import axios from 'axios';
import getXovisConnectionsV5 from './getXovisConnectionsV5';
import getXovisAgentsV5 from './getXovisAgentsV5';
import getXovisSceneGeometriesV5 from './getXovisSceneGeometriesV5';
import getXovisMultisensorInfoV5 from './getXovisMultisensorInfoV5';
import getXovisMultisensorSlavesV5 from './getXovisMultisensorSlavesV5';
import getXovisTemplatesV5 from './getXovisTemplatesV5';

/**
 * Получение данных для датчика
 * @param {object} sensor объект сенсора
 * @returns объект с ошибкой или объект с данными
 */
const getXovisDataV5 = async (args) => {
    const AGENT_TYPE = 'LOGICS';
    const { sensor, uploadUrl } = args;

    const multisensorInfo = await getXovisMultisensorInfoV5({ sensor });

    if (multisensorInfo.error) {
        return { error: 'Ann error accrued while getting data' };
    }

    const connections = getXovisConnectionsV5({ sensor, isMultisensor: multisensorInfo.isMultiSensor, uploadUrl });
    const agents = getXovisAgentsV5({ sensor, isMultisensor: multisensorInfo.isMultiSensor });
    const sceneGeometries = getXovisSceneGeometriesV5({ sensor, isMultisensor: multisensorInfo.isMultiSensor });
    const multisensorSlaves = getXovisMultisensorSlavesV5({ sensor });
    const templates = getXovisTemplatesV5({ sensor, isMultisensor: multisensorInfo.isMultiSensor });

    return axios
        .all([connections, agents, sceneGeometries, multisensorSlaves, templates])
        .then(
            axios.spread((connections, agents, sceneGeometries, multisensorSlaves, templates) => {
                const result = {
                    connections: [],
                    companyConnection: null,
                    agents: [],
                    companyAgent: null,
                    sceneGeometries: {},
                    isMultiSensor: false,
                    multisensorSlaves: {},
                    templates: {},
                };

                if (!connections.error) {
                    result.connections = connections.connections;
                    result.companyConnection = connections.companyConnection;
                }

                if (!agents.error) {
                    const companyAgent =
                        agents.agents.find(
                            (agent) =>
                                result.companyConnection &&
                                result.companyConnection.id === agent.connection &&
                                agent.type === AGENT_TYPE,
                        ) || null;
                    result.agents = agents.agents;
                    result.companyAgent = companyAgent;
                }

                if (!sceneGeometries.error) {
                    result.sceneGeometries = sceneGeometries;
                }

                if (!multisensorInfo.error) {
                    result.isMultiSensor = multisensorInfo.isMultiSensor;
                }

                if (!multisensorSlaves.error) {
                    result.multisensorSlaves.slaves = multisensorSlaves.slaves;
                    result.multisensorSlaves.slaveIps = multisensorSlaves.slaveIps;
                }

                if (!templates.error) {
                    result.templates = templates;
                }

                return result;
            }),
        )
        .catch((_) => {
            return { error: 'Ann error accrued while getting data' };
        });
};

export default getXovisDataV5;
