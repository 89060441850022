import { sensorTypes } from '../../constants/sensorTypes';

/**
 * Функция для подсчетат ошибок у сенсоров
 * @param {object} sensor объект сенсора
 * @returns количество ошибок у сенсора
 */
const findErrorsNumber = (sensor) => {
    if (sensor) {
        let errorsNumber = 0;
        switch (sensor.sensor_type) {
            case sensorTypes.XOVIS:
                if (sensor.firstStepError) errorsNumber += 1;
                if (sensor.secondStepError) errorsNumber += 1;
                if (sensor.thirdStepError) errorsNumber += 1;
                break;
            case sensorTypes.BRICKSTREAM:
                if (sensor.firstStepError) errorsNumber += 1;
                break;
            case sensorTypes.VIVOTEK:
                if (sensor.firstStepError) errorsNumber += 1;
                if (sensor.vivotekCountLinesError) errorsNumber += 1;
                if (sensor.vivotekDataUploadError) errorsNumber += 1;
                break;
            case sensorTypes.HIKVISION:
                if (sensor.firstStepError) errorsNumber += 1;
                if (sensor.hikvisionDataUploadError) errorsNumber += 1;
                break;

            default:
                break;
        }

        return errorsNumber;
    } else {
        return 1;
    }
};

export default findErrorsNumber;
