import { Modal } from '@fluentui/react';
import { createContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import {
    overWriteThunk,
    setHosts,
    setSelectedHosts,
    setSelectedUrls,
    setUrls,
} from '../../generalReducer';
import {
    createNewSensorThunk,
    updateSensorThunk,
} from '../countingSensors/countingSensorsReducer';
import {
    addNewSensorAndPointBoundle,
    createNewSensorAndPointBoundle,
    deleteSensorAndPointBoundle,
    getSensorsAndPointsBoundlesThunk,
    setBatchDataProcessing,
    updateSensorAndPointBoundle,
} from '../instalationPoints/installationPointsReducer';
import { ContentWrapper, CustomIconButton, Title } from './modalCreator.styles';
import { resetModalCreatorState } from './modalCreatorReducer';
import { toggleOpen } from './serialNumberWarningModal/serialNumberWarningModalReducer';

/**
 * Компонент для создания модалок
 * @param {Component} children jsx элемент
 * @param {boolean} isModalOpen открыта\закрыта модалка
 * @param {function} closeModal функция для закрытиия модалки
 */
export const ModalContext = createContext({});
const ModalCreator = ({
    children,
    isModalOpen,
    closeModal,
    borderTop = null,
    minWidth = null,
    title = '',
}) => {
    const { initialSensorsById } = useSelector(
        (state) => state.countingSensorsReducer,
    );
    const {
        locationId,
        sensorsAndPointsBounles,
        instalationPoints,
        instalationPointsOnCurrentFloor,
        currentFloorInfo,
        availableBundlesByIPointId,
        selectedLocationTimeZone,
    } = useSelector((state) => state.instalationPointsReducer);
    const { passPointsAndPassWaysIntersections } = useSelector(
        (state) => state.intersectionsForDisplayReducer,
    );
    const { passWaysOnCurrentFloor } = useSelector(
        (state) => state.passWaysReducer,
    );
    const {
        storeUrls,
        token,
        selectedUrls,
        storeHosts,
        selectedHosts,
        equalConflict,
        dataForOverwritten,
        locales,
    } = useSelector((state) => state.generalReducer);
    const { batchInfo, batchInfoById } = useSelector(
        (state) => state.modalCreatorReducer,
    );
    const { isHistoryFetching, screenshotsHistory, screenshotsFetchingError } =
        useSelector((state) => state.screenshotsPageReducer);
    const { isOpen } = useSelector(
        (state) => state.serialNumberWarningModalReducer,
    );

    const dispatch = useDispatch();
    const nav = useNavigate();

    const setUrlsTrans = (urls) => {
        dispatch(setUrls(urls));
    };

    const setSelectedUrlsTrans = (selectedUrl) => {
        dispatch(setSelectedUrls(selectedUrl));
    };

    const setHostsTrans = (hosts) => {
        dispatch(setHosts(hosts));
    };

    const setSelectedHostsTrans = (selectedHost) => {
        dispatch(setSelectedHosts(selectedHost));
    };

    const createNewSensorThunkTrans = (formValues) => {
        dispatch(createNewSensorThunk(formValues, nav));
    };

    const createNewSensorAndPointBoundleTrans = (body, setError) =>
        dispatch(
            createNewSensorAndPointBoundle({
                url: storeUrls.SENSORS_TO_IPOINTS.url,
                token,
                body,
                sensorsAndPointsBounles,
                setError,
            }),
        );

    const getSensorsAndPointsBoundlesThunkTrans = () =>
        dispatch(
            getSensorsAndPointsBoundlesThunk(
                storeUrls.SENSORS_TO_IPOINTS.url,
                token,
            ),
        );

    const updateSensorAndPointBoundleTrans = (
        body,
        id,
        setBoundlesDates,
        iPointId,
        currentBoundle,
    ) => {
        dispatch(
            updateSensorAndPointBoundle({
                storeUrls,
                token,
                body,
                setBoundlesDates,
                id,
                sensorsAndPointsBounles,
                iPointId,
                currentBoundle,
                getSensorsAndPointsBoundlesThunkTrans,
            }),
        );
    };

    const deleteSensorAndPointBoundleTrans = (id, setBoundlesDates) => {
        dispatch(
            deleteSensorAndPointBoundle({
                token,
                url: `${storeUrls.SENSORS_TO_IPOINTS.url}${id}/`,
                id,
                setBoundlesDates,
                getSensorsAndPointsBoundlesThunkTrans,
            }),
        );
    };

    const addNewSensorAndPointBoundleTrans = (boundle) => {
        dispatch(addNewSensorAndPointBoundle(boundle));
    };

    const updateSensorThunkTrans = (
        ip,
        port,
        sensorId,
        password,
        login,
        type,
    ) => {
        dispatch(
            updateSensorThunk({
                ip,
                port,
                sensorId,
                nav,
                password,
                username: login,
                type,
            }),
        );
    };

    const setBatchDataProcessingTrans = (data) => {
        dispatch(setBatchDataProcessing(data));
    };

    const overWriteThunkTrans = (options) => {
        const { url, requestType, actionAfterOverwrite, body, additionalData } =
            options;
        dispatch(
            overWriteThunk({
                url,
                requestType,
                actionAfterOverwrite,
                token,
                body,
                storeUrls,
                nav,
                additionalData,
            }),
        );
    };

    const resetModalCreatorStateTrans = () =>
        dispatch(resetModalCreatorState());

    const toggleOpenTrans = (value) => () => {
        dispatch(toggleOpen(value));
    };

    const modalContextValue = {
        storeUrls,
        locationId,
        token,

        locales,
        sensorsAndPointsBounles,
        instalationPoints,
        batchInfo,
        batchInfoById,
        selectedUrls,
        storeHosts,
        instalationPointsOnCurrentFloor,
        passWaysOnCurrentFloor,
        currentFloorInfo,
        passPointsAndPassWaysIntersections,
        selectedHosts,
        availableBundlesByIPointId,
        isHistoryFetching,
        screenshotsHistory,
        screenshotsFetchingError,
        equalConflict,
        dataForOverwritten,
        initialSensorsById,
        setUrlsTrans,
        setSelectedUrlsTrans,
        createNewSensorThunkTrans,
        createNewSensorAndPointBoundleTrans,
        updateSensorAndPointBoundleTrans,
        getSensorsAndPointsBoundlesThunkTrans,
        addNewSensorAndPointBoundleTrans,
        setSelectedHostsTrans,
        setHostsTrans,
        deleteSensorAndPointBoundleTrans,
        updateSensorThunkTrans,
        resetModalCreatorStateTrans,
        setBatchDataProcessingTrans,
        overWriteThunkTrans,
        selectedLocationTimeZone,
        toggleOpenTrans,
        isOpen,
    };

    return (
        <ModalContext.Provider value={modalContextValue}>
            <Modal
                styles={{
                    main: {
                        minHeight: '100px',
                        minWidth: `${minWidth ? minWidth : '300px'}`,
                    },
                }}
                isOpen={isModalOpen}
                onDismiss={closeModal}
                isBlocking={false}
            >
                <ContentWrapper borderTop={borderTop}>
                    {title && <Title>{title}</Title>}
                    <CustomIconButton
                        iconProps={{ iconName: 'Cancel' }}
                        onClick={closeModal}
                    />
                    {children}
                </ContentWrapper>
            </Modal>
        </ModalContext.Provider>
    );
};

export default ModalCreator;
