import { useBoolean, useId } from '@fluentui/react-hooks';
import { FC, memo } from 'react';
import { WrappedItem, Wrapper, CalloutContentWrapper } from './CalloutByIconButton.styles';
import { ICalloutByIconButtonProps } from './CalloutByIconButton.interfaces';
import { Callout, IconButton, TooltipHost } from '@fluentui/react';
import { useTranslation } from 'react-i18next';

/**
 * Комопнент для отображения текста с икнкой, при нажатии на которую происходит показ Callout
 * @param text текст, которы отобразиться слева от иконки
 * @param iconName название иконки из fluent UI
 * @param toolTipText надпись над иконкой
 * @param children Сожержимое Callout
 */
const CalloutByIconButton: FC<ICalloutByIconButtonProps> = memo(({ text, iconName, toolTipText, children }) => {
    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    const { t } = useTranslation();
    const calloutId = useId();

    const showContent = (e: MouseEvent) => {
        e.stopPropagation();
        toggleIsCalloutVisible();
    };

    return (
        <Wrapper>
            <WrappedItem>{text}</WrappedItem>
            <WrappedItem>
                <TooltipHost content={t(toolTipText)}>
                    <IconButton
                        id={calloutId}
                        onClick={(e) => showContent(e as unknown as MouseEvent)}
                        iconProps={{
                            iconName,
                        }}
                    />
                </TooltipHost>
            </WrappedItem>
            {isCalloutVisible && (
                <Callout onDismiss={toggleIsCalloutVisible} target={`#${calloutId}`}>
                    <CalloutContentWrapper>{children}</CalloutContentWrapper>
                </Callout>
            )}
        </Wrapper>
    );
});

export default CalloutByIconButton;
