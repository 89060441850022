import axios from 'axios';
import { getHeaders } from '../utils/getHeaders';
/**
 * гет запрос на сервер
 * @param {string} url url для запроса
 * @param {string} token токен для запроса
 * @returns
 */
export const getRequest = async (url, token) => {
    try {
        const headers = getHeaders(token);
        const data = await axios
            .get(url, {
                headers,
            })
            .then((response) => response.data);
        return data;
    } catch (error) {
        return {
            // error: error.response.data,
            error,
        };
    }
};

/**
 * post запрос на сервер
 * @param {string} url url на который нужно сделать запрос
 * @param {string} token токен для заголовков
 * @param {object} body данные, которые нужно отправить на сервер
 * @returns error || data
 */
export const postRequest = async (url, token = '', body) => {
    try {
        const headers = getHeaders(token);
        const data = await axios
            .post(url, body, {
                headers,
            })
            .then((resposne) => resposne.data);
        return data;
    } catch (error) {
        return {
            error,
        };
    }
};

/**
 * patch запрос на сервер
 * @param {string} url url на который нужно сделать запрос
 * @param {string} token токен для заголовков
 * @param {object} body данные, которые нужно отправить на сервер
 * @returns error || data
 */
export const patchRequest = async (url, token = '', body) => {
    try {
        const headers = getHeaders(token);
        const data = await axios.patch(url, body, {
            headers,
        });
        return data;
    } catch (error) {
        return {
            error,
        };
    }
};

/**
 * put запрос на сервер
 * @param {string} url url на который нужно сделать запрос
 * @param {string} token токен для заголовков
 * @param {object} body данные, которые нужно отправить на сервер
 * @returns error || data
 */
export const putRequest = async (url, token = '', body) => {
    try {
        const headers = getHeaders(token);
        const data = await axios.put(url, body, {
            headers,
        });
        return data;
    } catch (error) {
        return {
            error,
        };
    }
};
/**
 * delete запрос на сервер
 * @param {string} url url на который нужно сделать запрос
 * @param {string} token токен для заголовков
 * @returns error || data
 */
export const deleteRequest = async (url, token) => {
    try {
        const data = await axios.delete(url, {
            headers: getHeaders(token),
        });
        return data;
    } catch (error) {
        return {
            error,
        };
    }
};

/**
 * POST  пост запрос на лямбду для создания скрина в сторедже
 * @param {string} url url на который нужно сделать запрос
 * @param {file} body сам скриншот
 * @returns error || data
 */
export const postScreenshotRequest = async (url, body) => {
    try {
        const data = await axios({
            method: 'POST',
            url,
            data: body,
            headers: {
                ...getHeaders(),
                'Content-Type': 'multipart/form-data',
            },
        }).then((response) => response.data);

        return data;
    } catch (error) {
        return { error };
    }
};

/**
 * Функция для нескольких post запросов на бэк
 * @param {promise} requestOne первый запрос
 * @param {promise} requestTwo второй запрос
 * @param {promise} requestThree третий запрос
 * @returns error || data
 */
export const multiplePostRequest = async (requestOne, requestTwo, requestThree) => {
    try {
        const data = await axios.all([requestOne, requestTwo, requestThree]).then(
            axios.spread((...responses) => {
                const responseOne = responses[0]?.data;
                const responseTwo = responses[1]?.data;
                const responseThree = responses[2]?.data;
                return { responseOne, responseTwo, responseThree };
            }),
        );

        return data;
    } catch (error) {
        return { error };
    }
};

/**
 * Функция для получения скринов от лямбды
 * @param {string} url url на который нужно сделать запрос
 * @returns error || data
 */
export const getScreenshotsRequest = async (url) => {
    try {
        const data = await axios({
            method: 'GET',
            url,
            headers: getHeaders(),
        }).then((response) => response.data);

        return data;
    } catch (error) {
        return { error };
    }
};
