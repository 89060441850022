import parseVivotekFieldCoords from './parseVivotekFieldCoords';
import parseVivotekLineCoords from './parseVivotekLineCoords';

/**
 * Функция для получения разметки из слоев датчика Vivotek
 * @param {object} layers объект со всеми слоями датчика
 * @returns объект с разметкой
 */
const processVivotekLayers = (layers) => {
    let passWays = [];
    const zones = [];
    let floors = [];
    const exceptions = [];
    const masks = [];

    Object.keys(layers).forEach((key) => {
        switch (key) {
            case 'Counting': {
                Object.keys(layers[key]).forEach((marker) => {
                    const parsedLine = parseVivotekLineCoords(layers[key][marker].Line, marker);
                    passWays = passWays.concat(parsedLine);
                });
                break;
            }

            case 'FlowPathCounting': {
                Object.keys(layers[key]).forEach((marker) => {
                    const parsedLine = parseVivotekLineCoords(layers[key][marker].Line, marker);
                    passWays = passWays.concat(parsedLine);
                });
                break;
            }

            case 'PasserbyCounting': {
                Object.keys(layers[key]).forEach((marker) => {
                    const parsedLine = parseVivotekLineCoords(layers[key][marker].Line, marker);
                    passWays = passWays.concat(parsedLine);
                    const parsedFields = parseVivotekFieldCoords(layers[key][marker].Field, marker + '_field');
                    floors = floors.concat(parsedFields);
                });
                break;
            }

            case 'ZoneDetection': {
                Object.keys(layers[key]).forEach((marker) => {
                    const parsedFields = parseVivotekFieldCoords(layers[key][marker].Field, marker + '_field');
                    floors = floors.concat(parsedFields);
                });
                break;
            }

            case 'QueueAnalysis': {
                Object.keys(layers[key]).forEach((marker) => {
                    const parsedLine = parseVivotekLineCoords(layers[key][marker].Line, marker);
                    passWays = passWays.concat(parsedLine);
                    const parsedFields = parseVivotekFieldCoords(layers[key][marker].Field, marker + '_field');
                    floors = floors.concat(parsedFields);
                });
                break;
            }

            default:
                break;
        }
    });

    return { passWays, zones, floors, exceptions, masks };
};

export default processVivotekLayers;
