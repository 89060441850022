import { vivotekGetRequest } from './api';

/**
 * Функцуия для получения mac для датчика vivotek
 * @param {object} sensor объект сенсора
 * @param {number} timeout таймаут
 * @returns объект с серийником или с ошибкой
 */
const getVivotekSerialNumber = async (sensor, timeout) => {
    const response = await vivotekGetRequest({
        url: `http://${sensor.ip}:${sensor.port}/VCA/Camera/Profile`,
        username: sensor.user || 'admin',
        password: sensor.pass,
        timeout,
    });

    if (!response.error) {
        return { serialNumber: response.data.Data.Mac, sensor: { ...sensor } };
    }

    return { error: response.error, sensor: { ...sensor } };
};

export default getVivotekSerialNumber;
