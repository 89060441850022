import { memo } from 'react';
import { createContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { setSelectedInstalationPoint, toggleFollowIPoint } from '../instalationPoints/installationPointsReducer';
import { getScreensInfoThunk, updateSelectedSensors } from '../screenshotsPage/screenshotsPageReducer';
import FloorSidePanel from '../sidePanel/floorsSidePanel';
import LocationDispaly from './components/locationDisplay';
import { FloorInformationWrapper } from './floorPlan.styles';
import IPointScreensHistory from './components/iPointsScreensHistory';

/**
 * Обертка для всей секции с отображением локации
 */
export const FloorPlanContext = createContext({});
const FloorPlan = memo(({ splitWidth }) => {
    const {
        currentFloorInfo,
        mainLocationsByFloor,
        selectedFloor,
        showIPointLayer,
        selectedInstalationPoint,
        followIPoint,
        plStructureFetching,
        sensor2IPointByIPointMarker,
        selectedLocationTimeZone,
        plStructure,
    } = useSelector((state) => state.instalationPointsReducer);
    const { showIntersectionsLayer } = useSelector((state) => state.intersectionsForDisplayReducer);
    const { windowSize } = useSelector((state) => state.generalReducer);
    const { showPassWayLayer } = useSelector((state) => state.passWaysReducer);
    const { extendedSensorsById, screensDateFrom, screensDateTo } = useSelector(
        (state) => state.screenshotsPageReducer,
    );

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(toggleFollowIPoint(false));
        // eslint-disable-next-line
    }, []);

    const setSelectedInstalationPointTrans = (value) => dispatch(setSelectedInstalationPoint(value));

    const updateSelectedSensorsTrans = (value) => dispatch(updateSelectedSensors(value));

    const getScreensInfoThunkTrans = (value) => dispatch(getScreensInfoThunk(value));

    const contextValue = {
        followIPoint,
        windowSize,
        splitWidth,
        selectedFloor,
        showIPointLayer,
        currentFloorInfo,
        showPassWayLayer,
        plStructureFetching,
        plStructure,
        extendedSensorsById,
        mainLocationsByFloor,
        screensDateFrom,
        screensDateTo,
        showIntersectionsLayer,
        getScreensInfoThunkTrans,
        selectedLocationTimeZone,
        selectedInstalationPoint,
        updateSelectedSensorsTrans,
        sensor2IPointByIPointMarker,
        setSelectedInstalationPointTrans,
    };

    return (
        <FloorPlanContext.Provider value={contextValue}>
            <FloorInformationWrapper>
                <FloorSidePanel />
                <LocationDispaly />
                <IPointScreensHistory />
            </FloorInformationWrapper>
        </FloorPlanContext.Provider>
    );
});

export default FloorPlan;
