import styled from 'styled-components/macro';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

export const InfoText = styled.div`
    display: flex;
    span {
        white-space: nowrap;
        font-weight: bold;
    }
`;

export const ScreenWrapper = styled.div`
    margin-top: 10px;
    overflow: hidden;
`;

export const ShowScreenButtonWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const ErrorMessage = styled.div`
    font-size: 12px;
    color: red;
    margin-left: 10px;
`;
