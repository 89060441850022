/**
 * Функция для объединения csv данных и удаления не нужных строк
 * @param {array} fileData данные из csv файла
 * @param {array} stringData данные из csv строки
 * @param {array} JsonData данные из csv json
 * @param {array} errorsArray массив с ошибками при парсинге csv источников
 * @param {boolean} dateFields флаг, отвечающий за наличие полей дат
 * @returns Объедененные данные из файла и из строки
 */
const csvDataConsolidation = (fileData, stringData, JsonData, errorsArray, dateFields) => {
    const combinedData = [];
    const stringFromErrors = JSON.stringify(errorsArray);

    if (!fileData && !stringData && !JsonData) return null;

    if (fileData) {
        fileData.forEach((element) => {
            if (
                !stringFromErrors.includes(JSON.stringify(element)) &&
                element.ip &&
                element.port &&
                element.type &&
                (dateFields ? element.date_to && element.date_from : true)
            )
                combinedData.push({ ...element });
        });
    }

    if (stringData) {
        stringData.forEach((element) => {
            if (
                !stringFromErrors.includes(JSON.stringify(element)) &&
                element.ip &&
                element.port &&
                element.type &&
                (dateFields ? element.date_to && element.date_from : true)
            )
                combinedData.push({ ...element });
        });
    }

    if (JsonData) {
        JsonData.forEach((element) => {
            if (
                !stringFromErrors.includes(JSON.stringify(element)) &&
                element.ip &&
                element.port &&
                element.type &&
                (dateFields ? element.date_to && element.date_from : true)
            )
                combinedData.push({ ...element });
        });
    }

    return Array.from(new Set(combinedData.map((item) => JSON.stringify(item)))).map((element) => JSON.parse(element));
};

export default csvDataConsolidation;
