export const pivotItems = {
    projects: {
        itemKey: '0',
        headerText: 'Projects',
    },
    projectLocations: {
        itemKey: '1',
        headerText: 'Project locations',
    },
    plSensors: {
        itemKey: '2',
        headerText: 'Pl tasks',
    },
};
