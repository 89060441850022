import axios, { AxiosError } from 'axios';
import { headerNames } from './constants/headers';
import { IRequest } from './interfaces';
import { getHeaders } from '../../utils/getHeaders';

/**
 * Функция для POST запроса на бэк
 * @param {string} url куда сделать запроса
 * @param {any} payload тело запроса
 * @param {string} token токен для авторизации
 * @param {object} headers объект с хеддерами
 * @param {any} additionalParams дополнительные параметры
 * @returns ответ или объект с ошибкой
 */
export const postRequest = async (args: IRequest) => {
    const { url, payload, token, headers = {} } = args;
    try {
        if (token) headers[headerNames.TOKEN] = token;
        const result = await axios.post(url, payload, { headers: { ...getHeaders(), ...headers } });
        return result.data;
    } catch (error) {
        const e = error as AxiosError;
        return { error: e.response?.data || 'Something went wrong' };
    }
};
