import { TextField } from '@fluentui/react';
import styled from 'styled-components/macro';

export const CustomTextField = styled(TextField)`
    width: 200px;
`;

export const ProjectsWrapper = styled.div`
    padding: 5px 15px;
    min-height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
`;
