import { getCompanyName } from '../../constants/constants';
import { vivotekDataUploadCorrectValues } from './vivotekDataUploadCorrectValues';

/**
 * Функция для получения новых настроек для сервера отправки данных
 * на датчике VIVOTEK
 * @param {string} accessKey ключ для выгрузки
 * @param {object | undefined} uploadUrl Объект с данными по выгрузке
 * @returns  объект конфигурации
 */
const getNewDataPushServer = ({ accessKey, uploadUrl }) => {
    return {
        aggregation: vivotekDataUploadCorrectValues.dataUploadGranularity,
        camerastatus: 0,
        fileformat: '',
        format: 'xml',
        lite: 0,
        localtime: 1,
        name: getCompanyName(),
        passivemode: 1,
        port: 443,
        recipient: '',
        ruleid: 0,
        schedule: vivotekDataUploadCorrectValues.dataUploadInterval,
        sdcyclic: 0,
        sender: '',
        servertypeselector: uploadUrl?.protocol,
        sftpfingerprint: '',
        sslmode: 0,
        status: 'success',
        type: 'counting',
        uri: `/fpc/v1/vivotek/${accessKey}`,
        url: uploadUrl?.host,
        usr: '',
    };
};

export default getNewDataPushServer;
