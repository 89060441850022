import { useContext } from 'react';
import { HeaderContext } from '../header';
import { Wrapper } from './language.styles';

/**
 * компонент для отображения языка
 */
const Language = () => {
    const { language, setLanguageTrans } = useContext(HeaderContext);

    const languageHandler = () => {
        setLanguageTrans();
    };

    return <Wrapper onClick={languageHandler}>{language}</Wrapper>;
};

export default Language;
