import { DefaultButton, Icon, TextField } from '@fluentui/react';
import styled from 'styled-components/macro';

export const SensorsColumnModalTitle = styled.div`
    font-size: 16px;
    text-align: center;
    font-weight: 600;
    margin-bottom: 10px;
    min-width: 500px;
`;

export const CustomSensorsTextField = styled(TextField)`
    margin-top: 15px;
    width: 200px;
`;

export const MessageWrapper = styled.div`
    font-size: 16px;
    margin-top: 15px;
    color: rgb(164, 38, 44);
`;

export const BoundleColumnWrapper = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
`;

export const MainIcon = styled(Icon)`
    font-size: 20px;
    color: ${(props) => props.newColor};
`;

export const CustomButton = styled(DefaultButton)`
    background-color: #db0707;
    color: #ffffff;
    width: 60px;
    :hover {
        background-color: #c90a0a;
        color: #ffffff;
    }
`;

export const StyledDefaultButton = styled(DefaultButton)`
    margin-left: 5px;
    width: 60px;
`;

export const BoundlesWrapper = styled.div`
    overflow: auto;
    max-height: 300px;
`;

export const StyledIcon = styled(Icon)`
    font-size: 25px;
    cursor: pointer;
    margin-left: 10px;
`;

export const styledToolTip = {
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
};

export const ListTitle = styled.div`
    font-size: 16px;
    font-weight: 600;
    margin-top: 5px;
`;

export const DatesWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

export const stylesForTimeField = {
    root: {
        marginLeft: '10px',
        width: '75px',
    },
};
