import { xovisGetRequest } from '../../../../../api/xovis/xovis.api';
import checkErrorInXovisResponse from '../../../../../utils/xovisHelpers/checkErrorInXovisResponse';

/**
 * Регенерация скриншота
 * @param {object} sensor Объект сенсора
 * @param {number} timeout timeout
 * @returns Объект с ошибкой или данные
 */
const regenerateXovisScreen = async (args) => {
    const { sensor, timeout } = args;

    const response = await xovisGetRequest({
        url: `http://${sensor.ip}:${sensor.port}/api/scene/background/regenerate?passwd=${sensor.password}`,
        username: sensor.username,
        password: sensor.password,
        timeout,
    });

    if (!response.error) {
        const error = checkErrorInXovisResponse(response);

        if (!error) return response;

        return { error };
    }

    return { error: ' Ann error accrued while regenerate screen' };
};

export default regenerateXovisScreen;
