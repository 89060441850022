import styled from 'styled-components/macro';

export const PlSensorsWrapper = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
`;

export const FilterWrapper = styled.div`
    width: 172px;
    margin-top: 10px;
`;
