import { FC } from 'react';
import StackHorizontal from '../../../../../StackHorizontal/StackHorizontal';
import { IPlTaskCountProps } from './PlTasksCount.interfaces';
import { PlDataText } from './PlTasksCount.styles';
import CalloutByIconButton from '../../../../../CalloutByIconButton/CalloutByIconButton';

/**
 * Компонет для отображения количества заданий по проектным локациям и контекстного меню с более
 * подробной информацией
 */
const PlTasksCount: FC<IPlTaskCountProps> = ({ plTasks }) => {
    return (
        <CalloutByIconButton
            text={String(Object.keys(plTasks).length)}
            iconName={'ComplianceAudit'}
            toolTipText={"Show / hide pl's"}
        >
            {Object.keys(plTasks).map((key, index) => (
                <StackHorizontal key={index + Math.random()}>
                    <div>
                        <PlDataText>Pl id: </PlDataText> {key}
                    </div>
                    <div>
                        <PlDataText>Pl Name: </PlDataText> {plTasks[+key][0].pl_info?.name}
                    </div>
                </StackHorizontal>
            ))}
        </CalloutByIconButton>
    );
};

export default PlTasksCount;
