export const getHeaders = (token, appKey = 'sensor/client') => {
    return token
        ? {
              'X-Token': token,
              'x-app-key ': appKey,
              'Content-type': 'application/json',
          }
        : {
              'x-app-key ': appKey,
              'Content-type': 'application/json',
          };
};
