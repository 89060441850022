import { Checkbox } from '@fluentui/react';
import styled from 'styled-components/macro';

export const SingleUrlWrapper = styled.div`
    width: 450px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const InputWrapper = styled.div`
    flex: 0 0 90%;
`;

export const CustomCheckBox = styled(Checkbox)`
    align-self: flex-end;
    margin-bottom: 5px;

    label {
        ::before {
            display: none;
        }
    }
`;

export const UrlName = styled.div`
    font-weight: 500;
    margin-bottom: 5px;
    color: ${(props) => (props.checked ? 'black' : 'grey')};
`;
