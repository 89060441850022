import { useEffect, useState } from 'react';
import { ListWrapper, evenRowCustomStyles, rowCustomStyles } from './List.styles';
import {
    DetailsList,
    ScrollablePane,
    ScrollbarVisibility,
    Sticky,
    StickyPositionType,
    DetailsRow,
} from '@fluentui/react';

/**
 * Компонент для генерации списков
 * @param {array} items элементы списка
 * @param {array} columns колонки списка
 * @param {array} availableSortKeys массив ключей, которые доступны к сортировке
 * @param {function} onRenderItemColumn функция, для отображения кастомных колонок
 * @param {function} onRowClick функция, для обработки нажатия на строку списка
 */
const List = ({ items, columns, availableSortKeys, onRenderItemColumn, onRowClick = null }) => {
    const [sortedItems, setSortedItems] = useState(items);
    const [listColumns, setListColumns] = useState(columns);

    useEffect(() => {
        setSortedItems(items);
    }, [items]);

    const rowClickHandler = (item) => () => {
        onRowClick && onRowClick(item);
    };

    const onRenderRow = (props) => {
        let isEven = false;
        if (props && props.itemIndex % 2 === 0) isEven = true;
        if (props) {
            return (
                <div onClick={rowClickHandler(props.item)}>
                    <DetailsRow {...props} styles={isEven ? evenRowCustomStyles : rowCustomStyles} />
                </div>
            );
        }
        return <DetailsRow {...props} styles={isEven ? evenRowCustomStyles : rowCustomStyles} />;
    };

    const onRenderDetailsHeader = (headerProps, defaultRender) => {
        if (!defaultRender) return null;
        return (
            <Sticky
                stickyPosition={StickyPositionType.Header}
                isScrollSynced={true}
                stickyBackgroundColor='transparent'
            >
                <div>{defaultRender(headerProps)}</div>
            </Sticky>
        );
    };

    const onColumnHeaderClick = (_, column) => {
        if (!column || !availableSortKeys.includes(column.key)) return;
        let isSortedDescending = column.isSortedDescending;

        // If we've sorted this column, flip it.
        if (column.isSorted) {
            isSortedDescending = !isSortedDescending;
        }

        // Sort the items.
        const items = copyAndSort(sortedItems, column.fieldName, isSortedDescending);

        // Reset the items and columns to match the state.
        setSortedItems(items);
        setListColumns(
            listColumns.map((col) => {
                col.isSorted = col.key === column.key;
                if (col.isSorted) {
                    col.isSortedDescending = isSortedDescending;
                }
                return col;
            }),
        );
    };

    function copyAndSort(items, columnKey, isSortedDescending) {
        const key = columnKey;
        return items.slice(0).sort((a, b) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    }

    return (
        <ListWrapper>
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                <DetailsList
                    onRenderDetailsHeader={onRenderDetailsHeader}
                    onColumnHeaderClick={onColumnHeaderClick}
                    onRenderItemColumn={onRenderItemColumn}
                    onRenderRow={onRenderRow}
                    items={sortedItems}
                    columns={listColumns}
                    selectionMode={0}
                />
            </ScrollablePane>
        </ListWrapper>
    );
};

export default List;
