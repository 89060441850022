import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    dateIntervalError: false,
};

const createSensorAndPointBoundleReducer = createSlice({
    name: 'createSensorAndPointBoundleReducer',
    initialState,
    reducers: {
        // Запись в стейт информации об ошибке во временном интервале
        storeDateIntervalError: (state, action) => {
            state.dateIntervalError = action.payload;
        },
    },
});

export const { storeDateIntervalError } = createSensorAndPointBoundleReducer.actions;
export default createSensorAndPointBoundleReducer.reducer;
