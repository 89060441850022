import styled from 'styled-components/macro';

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const WrappedItem = styled.div`
    display: flex;
    align-items: center;
    margin-left: 10px;
    :first-child {
        margin-left: 0px;
    }
`;
