import axios from 'axios';
import { BrickstreamSensor } from '../../../../shared/sensors';
import { DefaultCompanyInfo } from '../../../../shared/company-info';

/**
 * Функция для создания промиса при получении серийников от датчиков
 * @param {object} sensor объект сенсора
 * @returns Promise
 */
const getBrickstreamSensorData = (sensor) => {
    const errorResponse = {
        ...sensor,
        isConfigurationFetching: false,
        firstStepError: 'GET request error',
    };

    const instance = new BrickstreamSensor(
        sensor.ip,
        sensor.port,
        sensor.sensor_type,
        sensor.username,
        sensor.password,
        new DefaultCompanyInfo(),
    );

    return axios
        .all([instance.getSensorData()])
        .then(([sensorData]) => {
            if (sensorData.isError) {
                return { ...errorResponse };
            }
            return {
                ...sensor,
                isConfigurationFetching: false,
                serialNumberError:
                    sensor.serial_number.toLowerCase() !==
                    sensorData?.cfg?.processedReleaseInfo?.MAC_ADDRESS?.toLowerCase()
                        ? 'Serial numbers are not equal'
                        : '',
                sensorData,
            };
        })
        .catch(() => ({ ...errorResponse }));
};

export default getBrickstreamSensorData;
