import { TextField } from '@fluentui/react';
import { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { filterListOfObjects } from './tools/filterListOfObjects';

/**
 * Компонент инпута с возможностью фильтрации
 * @param {boolean} disabled флаг, отвечающий за активность инпута
 * @param {string} value начальное значение инпута
 * @param {array} inputArray элементы списка
 * @param {function} changeValue функция для изменения значения инпута, если компонент управляется извне
 * @param {function} updateFilteredArray функция для записи отфильтрованного списка
 * @param {array} matchFields поля, по которым фильтровать
 * @param {boolean} isOutsideControlled флаг, отвечающий за контроль извне
 */
const FilterInput = memo(
    ({
        disabled = false,
        value = '',
        inputArray,
        changeValue = null,
        updateFilteredArray = null,
        matchFields,
        isOutsideControlled = false,
    }) => {
        const [filterValue, setFilterValue] = useState(value);
        const { t } = useTranslation();

        useEffect(() => {
            if (inputArray.length && !isOutsideControlled) {
                const filteredArray = filterListOfObjects(inputArray, matchFields, filterValue);
                updateFilteredArray && updateFilteredArray(filteredArray);
            }
            // eslint-disable-next-line
        }, [filterValue]);

        const onChange = useCallback(
            (_, newValue) => {
                if (isOutsideControlled) {
                    changeValue && changeValue(newValue || '');
                } else {
                    setFilterValue(newValue || '');
                }
            },
            // eslint-disable-next-line
            [],
        );

        return (
            <TextField
                placeholder={t('Filter')}
                disabled={disabled}
                value={isOutsideControlled ? value : filterValue}
                onChange={onChange}
            />
        );
    },
);
export default FilterInput;
