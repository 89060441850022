import { TextField } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import checkIpValue from '../../../../utils/createSensor/checkIpValue';

/**
 * Компонент для отображения инпута с маской ip
 * @param {string} inputValue значение в инпуте
 * @param {function} setInputValue функция для обработки и зпаиси занчения из инпута при вводе
 * @param {boolean} inputError сообщает о тома, правильно ли введен адресс
 */
const MaskedIpInput = ({ inputValue, setInputValue, inputError }) => {
    const { t } = useTranslation();
    const beforeMaskedValueChangeHandler = (newState, oldState) => {
        let value = newState.value;
        const oldValue = oldState.value;
        let selection = newState.selection;
        let cursorPosition = selection ? selection.start : null;
        const result = checkIpValue(value);
        if (!result) {
            value = value.trim();
            // try to add . before the last char to see if it is valid ip address
            const newValue = value.substring(0, value.length - 1) + '.' + value.substring(value.length - 1);
            if (checkIpValue(newValue)) {
                cursorPosition++;
                selection = { start: cursorPosition, end: cursorPosition };
                value = newValue;
            } else {
                value = oldValue;
            }
        }

        return {
            value,
            selection,
        };
    };

    return (
        <InputMask
            formatChars={{
                9: '[0-9.]',
            }}
            mask='999999999999999'
            maskChar={null}
            onChange={setInputValue}
            placeholder={'___.___.___.___'}
            alwaysShowMask={false}
            value={inputValue}
            beforeMaskedValueChange={beforeMaskedValueChangeHandler}
        >
            {(inputProps) => <TextField errorMessage={inputError ? 'Incorrect ip' : null} label={t('IP address')} {...inputProps} title={t('IP address')} />}
        </InputMask>
    );
};

export default MaskedIpInput;
