import { DatePicker, Dropdown } from '@fluentui/react';
import styled from 'styled-components/macro';

export const portionTypeDropdownStyles = {
    root: {
        width: '150px',
        marginRight: '15px',
        position: 'relative',
    },
};

export const DataUploadPickersWrapper = styled.div`
    display: flex;
`;

export const DatetimePickersWrapper = styled.div`
    display: flex;
    margin-right: 15px;
`;

export const stylesForTimeField = {
    root: {
        marginLeft: '10px',
        width: '75px',
    },
};

export const StyledPortionTypeDropdown = styled(Dropdown)`
    ::before {
        content: 'Portion type';
        position: absolute;
        top: -24px;
        color: rgb(50, 49, 48);
        font-weight: 600;
        font-size: 14px;
    }
`;

export const StyledDateFrom = styled(DatePicker)`
    position: relative;
    div {
        div {
            span {
                div {
                    margin-top: 0px;
                }
            }
        }
    }
    ::before {
        content: 'Datetime from';
        position: absolute;
        top: -24px;
        color: rgb(50, 49, 48);
        font-weight: 600;
        font-size: 14px;
    }
`;

export const datePickersStyles = {
    root: {
        selectors: {
            input: {
                display: 'flex',
            },
        },
    },
};

export const StyledDateTo = styled(DatePicker)`
    position: relative;
    div {
        div {
            span {
                div {
                    margin-top: 0px;
                }
            }
        }
    }
    ::before {
        content: 'Datetime to';
        position: absolute;
        top: -24px;
        color: rgb(50, 49, 48);
        font-weight: 600;
        font-size: 14px;
    }
`;

export const RemoveIconStyles = {
    root: {
        fontSize: '25px',
        alignSelf: 'center',
        cursor: 'pointer',
        marginLeft: '10px',
        color: 'rgb(96, 94, 92)',
    },
};
