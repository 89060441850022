import styled from 'styled-components/macro';
import Split from 'react-split';
import { DatePicker } from '@fluentui/react';

export const gutterStyles = {
    width: '10px',
    'background-color': '#eee',
    'background-repeat': 'no-repeat',
    'background-position': '50%',
    'background-image': `url(
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg=='
    )`,
    '    cursor': 'col-resize',
};

export const StyledSplit = styled(Split)`
    display: flex;
    flex-direction: row;
    height: 100%;
`;

export const ScreenshotsLocationDispalyWrapper = styled.div`
    overflow: hidden;
`;

export const ScreenshotsSensorsListWrapper = styled.div`
    padding: 5px;
`;

export const DatePickersWrapper = styled.div`
    display: flex;
    align-items: flex-end;
`;

export const startButtonStyles = {
    root: {
        width: '170px',
    },
};

export const ListContainer = styled.div`
    padding: 0px 10px 10px 10px;
    box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const datePickersStyles = {
    root: {
        width: '170px',
        marginLeft: '10px',
    },
};

export const StyledDatePicker = styled(DatePicker)`
    div {
        div {
            span {
                div {
                    margin-top: 0px;
                }
            }
        }
    }
`;

export const switchStyles = {
    root: {
        marginBottom: '0px',
    },
};

export const optionIconStyles = { icon: { fontSize: '25px', color: '#0078d4' }, root: { marginLeft: '10px' } };

export const calloutStyles = {
    root: {
        padding: '10px',
    },
};

export const optionsDropdownStyles = {
    root: {
        width: '170px',
    },
};

export const screenSizeDropdownStyles = {
    root: {
        width: '170px',
    },
};

export const filterStyles = {
    root: {
        marginLeft: '10px',
        width: '170px',
    },
};
