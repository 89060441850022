import axios from 'axios';
import { cloneDeep } from 'lodash';
import { getHeaders } from './getHeaders';

/**
 * функция для загрузки по порциям
 * @param {number} portionSize размер порции
 * @param {array} dataArray массив с данными {url: string, initialData: any}
 * @param {number} timeout таймаут
 * @param {boolean} isAuth таймаут
 * @returns массив ответов
 */
const portionDataGet = async (options) => {
    const { portionSize, dataArray, timeout, isAuth = false } = options;
    const dataArrayCopy = cloneDeep(dataArray);

    const result = [];

    const subarray = []; //массив в который будет выведен результат.
    for (let i = 0; i < Math.ceil(dataArrayCopy.length / portionSize); i++) {
        subarray[i] = dataArrayCopy.slice(i * portionSize, i * portionSize + portionSize);
    }

    for (let array of subarray) {
        const promises = [];
        array.forEach((element) => {
            const promise = axios
                .get(element.url, {
                    timeout,
                    auth: isAuth && {
                        username: element.user,
                        password: element.pass,
                    },
                    headers: { ...getHeaders() },
                })
                .then((res) => res.data)
                .then((data) => ({
                    response: data,
                    initialData: { ...element.initialData },
                    extraArgs: element.extraArgs,
                }))
                .catch((error) => ({
                    error: error.message || 'Post error',
                    initialData: { ...element.initialData },
                    extraArgs: element.extraArgs,
                }));
            promises.push(promise);
        });

        await Promise.all(promises).then((responses) => {
            responses.forEach((response) => {
                result.push(response);
            });
        });
    }

    return result;
};

export default portionDataGet;
