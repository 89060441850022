import { megacountPostRequest } from '../../../../api/megacount/megacount.api';
import { getCompanyUploadUrlPart } from '../../../../constants/constants';

/**
 * Получения всех серверов выгрузки
 * @param {string} ip IP датчика
 * @param {number} port Порт датчика
 * @param {string} username Порт датчика
 * @param {string} password Порт датчика
 * @returns Promise результатом которого является объект с данными о настойках сети или объект с ошибкой
 */
const getMegacountUploadServers = async (args) => {
    const { ip, port, username, password } = args;

    const uploadServersResponse = await megacountPostRequest({
        url: `http://${ip}:${port}/upload`,
        payload: {
            upload: {
                get: ['all'],
            },
        },
        username,
        password,
    });

    if (!uploadServersResponse.error) {
        const uploadServers = uploadServersResponse?.upload?.get || [];

        const companyUploadServer = uploadServers.find((server) =>
            (server?.protocol_settings?.server || '').includes(
                getCompanyUploadUrlPart(),
            ),
        );

        return {
            uploadServers,
            companyUploadServer: companyUploadServer || null,
        };
    } else {
        return {
            error: 'Unable to get sensor upload servers',
        };
    }
};

export default getMegacountUploadServers;
