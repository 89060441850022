import { DateTime } from 'luxon';
import uploadMegacountData from './uploadMegacountData';
import { cloneDeep } from 'lodash';

/**
 * Функция для обработки ошибочных интервалов датчика MEGACOUNT
 * @param {object} sensor объект сенсора
 * @param {array} failedIntervals все ошибочные интервалы
 * @param {number} timeout таймаут
 * @returns возвращает обновленный объект сенсора
 */
const processMegacountFailedIntervals = async (args) => {
    const { sensor, failedIntervals, timeout } = args;
    let sensorCopy = cloneDeep(sensor);
    for (let interval of failedIntervals) {
        const data = await uploadMegacountData({
            companyUploadServer: sensorCopy.megacountData?.companyUploadServer,
            accountSettings: sensorCopy.megacountData?.accountSettings,
            dateFrom: DateTime.fromMillis(interval.dateFrom).toISO(),
            dateTo: DateTime.fromMillis(interval.dateTo).toISO(),
            username: sensorCopy.user,
            password: sensorCopy.pass,
            port: sensorCopy.port,
            ip: sensorCopy.ip,
            timeout,
        });

        if (!data.error) {
            sensorCopy.orderCount = 1;
            sensorCopy.successOrderCount = 1;
        } else {
            sensorCopy.errors.push(data.error);
        }
    }

    return { sensor: { ...sensorCopy } };
};

export default processMegacountFailedIntervals;
