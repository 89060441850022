export const hikvisionNewDataUploadBody = `<HttpHostNotification version="2.0" xmlns="http://www.std-cgi.com/ver20/XMLSchema">
<id></id>
<url></url>
<protocolType>HTTP</protocolType>
<parameterFormatType>XML</parameterFormatType>
<addressingFormatType>hostname</addressingFormatType>
<hostName></hostName>
<portNo>80</portNo>
<userName></userName>
<httpAuthenticationMethod>none</httpAuthenticationMethod>
</HttpHostNotification>`;
