/**
 * Функция для получения строки для копирования из выбранных сенсоров
 * @param {array} sensors массив выбранных сенсоров
 * @returns строку для копирования
 */
const copySelectedSensors = (sensors) => {
    let result = '';
    sensors.forEach((element) => {
        result += `${element.ip}${element.port === '80' ? '' : ';' + element.port}\n`;
    });
    return result;
};

export default copySelectedSensors;
