import axios from 'axios';

/**
 * Вспомогательная функция для получения серийного номера от сенсора, при пакетной загрузке данныхъ
 * @param {object} singleDataPackage элемент из csv файла или csv строки
 */
const getBrickstreamSerialNumberForTable = async (options) => {
    const { singleDataPackage } = options;
    const oParser = new DOMParser();
    let serialNumber = '';
    let notEqualSerialNumber = false;

    return axios
        .get(`http://${singleDataPackage.ip}:${singleDataPackage.port}/help.html`, {
            timeout: 5000,
            auth: {
                username: singleDataPackage.user,
                password: singleDataPackage.pass,
            },
        })
        .then((res) => res.data)
        .then((data) => {
            const xmlResponse = oParser.parseFromString(data, 'text/xml');
            serialNumber = xmlResponse.querySelector('#MAC_ADDRESS').value.toLowerCase();
            if (singleDataPackage.serial_number && serialNumber !== singleDataPackage.serial_number.toLowerCase())
                notEqualSerialNumber = true;
            return {
                id: singleDataPackage.id,
                serialNumber,
                notEqualSerialNumber,
            };
        })
        .catch((error) => ({ id: singleDataPackage.id, error }));
};

export default getBrickstreamSerialNumberForTable;
