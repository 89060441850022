import { sensorTypes } from '../../../../constants/sensorTypes';
import { showErrorNotification, showSuccessNotification } from '../../../../generalReducer';
import { DefaultCompanyInfo } from '../../../../shared/company-info';
import { BrickstreamSensor } from '../../../../shared/sensors';
import { updateAvailableBoundleByIPointId } from '../../../instalationPoints/installationPointsReducer';
import { updateSingleSensor } from '../../countingSensorsReducer';
import getBrickstreamSensorData from './getBrickstreamSensorData';

/**
 * Изменение / Добавление выгрузки компании на датчик BRICKSTREAM
 * @param {object} sensor объект выбранного сенсора
 * @param {function} dispatch диспатч в стейт
 * @param {object} currentBundle объект текущей связки (активной)
 * @param {number} iPointId id точки установки
 * @param {boolean} showNotifications флаг, отвечающий за отображение оповещений
 * @param {*} sensorUploadUrlsBySensorType объект с информацией по выгрузке для всех датчиков
 */
const updateConfigWebParams = async ({
    dispatch,
    sensor,
    currentBundle,
    iPointId,
    showNotifications = true,
    accessKey = '',
    sensorUploadUrlsBySensorType,
}) => {
    const instance = new BrickstreamSensor(
        sensor.ip,
        sensor.port,
        sensor.sensor_type,
        sensor.username,
        sensor.password,
        new DefaultCompanyInfo({
            sensors: Object.values(sensorUploadUrlsBySensorType).reduce((acc, value) => {
                acc[value.sensorType] = value.uploadUrl;
                return acc;
            }, {}),
        }),
    );

    const response = await instance.updateDataPushServer(
        sensor.sensorData,
        `/fpc/v1/${sensorTypes.BRICKSTREAM}/${accessKey}`,
    );

    dispatch(
        updateAvailableBoundleByIPointId({
            iPointId,
            value: { ...currentBundle, updateConfigurationFetching: false },
        }),
    );

    if (!response.isError) {
        const res = await getBrickstreamSensorData(sensor);
        dispatch(updateSingleSensor({ id: sensor.id, sensor: { ...sensor, ...res }, increaseFetchedSensors: false }));
        showNotifications && dispatch(showSuccessNotification({ show: true, message: 'Configuration updated!' }));
    } else {
        showNotifications && dispatch(showErrorNotification({ show: true, message: 'Something went wrong' }));
        return { error: 'Something went wrong' };
    }
};

export default updateConfigWebParams;
