export class Sensor {
    REQUEST_TIMEOUT_IN_SECONDS = 15;

    CORRECT_DATA_PUSH_SERVER_TIMINGS = {
        pushGranularity: 1,
        pushInterval: 15,
    };

    errors = [];

    /**
     * Конструктор класса
     * @param {string} ip IP датчика
     * @param {number | string} port Port датчика
     * @param {string} sensorType Тип датчика
     * @param {string} username Логин для датчика
     * @param {string} password Пароль для датчика
     * @param {CompanyInfo} companyInfo класс для получения различной информации о компании
     * @param {number | undefined} requestTimeout Timeout для запросов
     */
    constructor(ip, port, sensorType, username, password, companyInfo, requestTimeout) {
        this.ip = ip;
        this.port = port;
        this.sensorType = sensorType;
        this.username = username;
        this.password = password;
        this.companyInfo = companyInfo;

        if (requestTimeout) {
            this.REQUEST_TIMEOUT_IN_SECONDS = requestTimeout;
        }
    }

    /**
     * Метод для получения данных датчика для работы с табличками
     * точек установки и тд
     */
    getSensorData() {
        throw Error('Not implemented');
    }

    /**
     * Метод для проверки связи с датчиком
     */
    connectionTest() {
        throw Error('Not implemented');
    }

    /**
     * Метод для переотправки данных с датчика
     * @param {string} dateFrom ISO format date
     * @param {string} dateTo ISO format date
     */
    resendData(dateFrom, dateTo) {
        throw Error('Not implemented');
    }

    /**
     * Метод для отправки скриншота с датчика
     * @param {string} url URL на который нужно сделать POST для сохранения скриншота
     */
    sendScreen(url) {
        throw Error('Not implemented');
    }
}
