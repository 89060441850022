import { getCompanyName } from '../../constants/constants';
import {
    MEGACOUNT_VALID_DATA_INTERVAL,
    MEGACOUNT_VALID_FILE_TYPE,
    MEGACOUNT_VALID_SEND_FREQ,
} from '../../constants/megacountSensor';
import { sensorTypes } from '../../constants/sensorTypes';
import idGenerator from '../idGenerator';

/**
 * Получение корректной выгрузки для датчика
 * @param {object | null} companyUploadServer данные сервера выгрузки с датчика
 * @param {string} uploadKey уникальный ключ локации
 * @returns тело запроса для обновления выгрузки
 */
const generateMegacountUploadUpdatePayload = (args) => {
    const { companyUploadServer, uploadKey, uploadUrl } = args;

    /**
     * Если есть выгрузка компании, то обновляем ее
     */
    if (companyUploadServer?.upload_id) {
        return getServerSettings({
            upload_id: companyUploadServer?.upload_id,
            uploadKey,
            uploadUrl,
        });
    } else {
        /**
         * Если выгрузки нет, то создаем новую
         */
        return getServerSettings({ uploadKey, uploadUrl });
    }
};

export default generateMegacountUploadUpdatePayload;

const getServerSettings = (args) => {
    const { uploadKey, upload_id = idGenerator(), uploadUrl } = args;
    return {
        upload: {
            post: {
                set_uploads: [
                    {
                        data_settings: {
                            data_interval: MEGACOUNT_VALID_DATA_INTERVAL,
                            device_id: '',
                            device_name: '',
                            division_id: '',
                            site_id: '',
                            site_name: '',
                        },
                        file_type: MEGACOUNT_VALID_FILE_TYPE,
                        format: '1.0',
                        protocol_settings: {
                            port: 443,
                            protocol: uploadUrl?.protocol,
                            server: uploadUrl?.host,
                            url: `/fpc/v1/${sensorTypes.MEGACOUNT}/${uploadKey}`,
                        },
                        send_freq: MEGACOUNT_VALID_SEND_FREQ,
                        type: 'counters',
                        upload_id: upload_id,
                        upload_name: getCompanyName(),
                        upload_type: 'xml',
                    },
                ],
            },
        },
    };
};
