import { DateTime } from 'luxon';

/**
 * Конвертирует дату к нужному формату
 * @param {object} date ээкземпляр класса Date
 * @returns возвращает дату вида: год-месяц-день
 */
const dateFormater = (date) => {
    return DateTime.fromISO(date.toISOString()).toFormat('yyyy-MM-dd');
};
export default dateFormater;
