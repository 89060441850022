import styled from 'styled-components/macro';
import ReactDataSheet from 'react-datasheet';

export const IntersectTitle = styled.div`
    font-size: 16px;
    text-align: center;
    font-weight: 600;
    width: 1000px;
`;

export const CustomReactDataSheet = styled(ReactDataSheet)`
    width: 1000px;
`;

export const IntersectModalWrapper = styled.div`
    height: 100%;
    max-height: 700px;
`;

export const IntersectTableWrapper = styled.div`
    margin-top: 20px;
    min-height: 150px;
    max-height: 600px;
    overflow: auto;
`;

export const CustomCell = styled.td`
    span {
        height: 50px;
        font-size: 14px;
        padding: 5px;
        display: flex !important;
        overflow: auto;
        align-items: center;
        justify-content: flex-start;
    }
`;
