import { xovisGetRequest } from '../../../../api/xovis/xovis.api';
import { sensorTypes } from '../../../../constants/sensorTypes';
import { hikvisionGetRequest } from '../hikvision/api';
import { megacountPostRequest } from '../megacount/megacount.api';
import { tdGetRequest } from '../td/td.api';
import { vivotekGetRequest } from '../vivotek/api';

const connectionTestHandler = async (args) => {
    const { sensor, timeout } = args;

    const ITERATIONS_COUNT = 3;
    let orderCount = 0;
    let successOrderCount = 0;
    const errors = [];

    for (let i = 0; i < ITERATIONS_COUNT; i++) {
        orderCount += 1;
        let result = null;
        switch (sensor.type) {
            case sensorTypes.XOVIS: {
                result = await xovisGetRequest({
                    url: `http://${sensor.ip}:${sensor.port}`,
                    password: sensor.pass,
                    username: sensor.user,
                    timeout,
                });
                break;
            }

            case sensorTypes.VIVOTEK: {
                result = await vivotekGetRequest({
                    url: `http://${sensor.ip}:${sensor.port}/VCA/Camera/Profile`,
                    username: sensor.user,
                    password: sensor.pass,
                    timeout,
                });
                break;
            }
            case sensorTypes.HIKVISION: {
                result = await hikvisionGetRequest({
                    url: `http://${sensor.ip}:${sensor.port}/ISAPI/System/deviceInfo`,
                    username: sensor.user,
                    password: sensor.pass,
                    timeout,
                });

                break;
            }
            case sensorTypes.TD: {
                result = await tdGetRequest({
                    url: `http://${sensor.ip}:${sensor.port}/network_config`,
                    username: sensor.user,
                    password: sensor.pass,
                    timeout,
                });

                break;
            }

            case sensorTypes.MEGACOUNT: {
                result = await megacountPostRequest({
                    url: `http://${sensor.ip}:${sensor.port}/settings`,
                    payload: {
                        settings: {
                            get: ['NetworkSettings'],
                        },
                    },
                    username: sensor.user,
                    password: sensor.pass,
                    timeout,
                });
                break;
            }

            default:
                console.log('connection test >>>>>>> Invalid sensor type', sensor, sensor.type);
                break;
        }
        if (!result.error) {
            successOrderCount += 1;
        } else {
            errors.push('Something went wrong');
        }
        await new Promise((resolve) => setTimeout(resolve, 2000));
    }

    return { orderCount, successOrderCount, errors };
};

export default connectionTestHandler;
