import getDataForReportServerUpdate from '../../../../utils/tdSensorHelpers/getDataForReportServerUpdate';
import { tdProgressMessages } from '../../../../utils/tdSensorHelpers/tdProgressMessages';
import { updateExtendedSelectedIPoint } from '../../../instalationPoints/installationPointsReducer';
import updateTdReportServer from './updateTdReportServer';

/**
 * Функция для работы с состоянием конкретной связки, получением новой выгрузки
 * на датчике и обновлением выгрузки для датчика
 * @param {*} dispatch функция для обновления
 * @param {*} bundle текущая актуальная связь точки установки и датчика
 * @param {*} iPointId ID точки установки
 * @param {*} accessKey ключ доступа для локации / проекта
 * @param {*} sensorsById объект расширенных данных о датчиках
 * @param {*} uploadUrl объект с информацией по выгрузке
 * @returns promise
 */
const processTdBatchUrlUpdate = async (args) => {
    const { dispatch, bundle, iPointId, accessKey, sensorsById, uploadUrl } = args;
    const promise = new Promise((resolve) => resolve());

    dispatch(
        updateExtendedSelectedIPoint({
            data: { isFetching: true, isError: false, message: '' },
            iPointId,
        }),
    );

    if (!bundle?.thirdStepError) {
        dispatch(
            updateExtendedSelectedIPoint({
                data: {
                    isFetching: false,
                    isError: false,
                    message: tdProgressMessages.thirdStepEnabled,
                },
                iPointId,
            }),
        );
        return promise;
    }

    if (!bundle?.firstStepError && bundle?.thirdStepError && bundle?.tdData?.serviceConfig?.availableServerIdToUpdate) {
        const newConfiguration = getDataForReportServerUpdate(
            bundle?.tdData?.serviceConfig?.availableServerIdToUpdate,
            bundle?.tdData?.serviceConfig?.rawServiceConfig,
            accessKey,
            uploadUrl,
        );

        if (newConfiguration) {
            return updateTdReportServer({
                sensor: sensorsById[bundle.id],
                payloadBody: newConfiguration,
                currentBoundle: bundle,
                iPointId,
                dispatch,
            }).then((data) => {
                if (!data?.error) {
                    dispatch(
                        updateExtendedSelectedIPoint({
                            data: {
                                isFetching: false,
                                isError: false,
                                message: 'Success',
                            },
                            iPointId,
                        }),
                    );
                } else {
                    dispatch(
                        updateExtendedSelectedIPoint({
                            data: {
                                message: JSON.stringify('Something went wrong while updating report server'),
                                isFetching: false,
                                isError: true,
                            },
                            iPointId,
                        }),
                    );
                }
            });
        } else {
            dispatch(
                updateExtendedSelectedIPoint({
                    data: {
                        isFetching: false,
                        isError: true,
                        message: 'Ann error accrued while getting new report server config',
                    },
                    iPointId,
                }),
            );
        }
        return promise;
    }

    dispatch(
        updateExtendedSelectedIPoint({
            data: {
                isFetching: false,
                isError: true,
                message: 'Something went wrong!',
            },
            iPointId,
        }),
    );

    return promise;
};

export default processTdBatchUrlUpdate;
