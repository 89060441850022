import axios from 'axios';

/**
 * Фунгкция для получения адресов для выгрузки с датчика
 * @param {object} sensor объект сенсора
 * @param {number} timeout таймаут
 * @returns массив со всемы адресами или ошибку
 */
const getVivotekUploadUrls = (sensor, timeout) => {
    return axios
        .get(`http://${sensor.ip}:${sensor.port}/VCA/Config/ReportPush`, {
            timeout,
            auth: {
                username: sensor.user,
                password: sensor.pass,
            },
        })
        .then((response) => response.data)
        .then((data) => ({ uploadUrls: data, sensor: { ...sensor } }))
        .catch((error) => ({ error: error.message, sensor: { ...sensor } }));
};

export default getVivotekUploadUrls;
