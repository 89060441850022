import styled from 'styled-components/macro';
import Split from 'react-split';
import { Pivot } from '@fluentui/react';

export const ListsWrapper = styled.div`
    height: 100%;
`;

export const StyledSplitForLists = styled(Split)`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

export const gutterStylesForLists = {
    width: '100%',
    height: '10px',
    'background-color': '#eee',
    'background-repeat': 'no-repeat',
    'background-position': '50%',
    'background-image': `url(
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAFCAMAAABl/6zIAAAABlBMVEUAAADMzMzIT8AyAAAAAXRSTlMAQObYZgAAABRJREFUeAFjYGRkwIMJSeMHlBkOABP7AEGzSuPKAAAAAElFTkSuQmCC'
    )`,
    cursor: 'col-resize',
};

export const SplittedIPointsListWrapper = styled.div`
    overflow: hidden;
    height: 100%;
`;

export const SplittedSensorsList = styled.div`
    overflow: hidden;
    height: 100%;
`;

export const pivotStyles = {
    height: '100%',
    itemContainer: {
        flexGrow: 1,
        padding: '10px',
    },
};

export const StyledPivot = styled(Pivot)`
    height: 100%;
    display: flex;
    flex-direction: column;
`;
