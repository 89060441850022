import { xovisGetRequest } from '../../../../../api/xovis/xovis.api';

/**
 * Получение геометрии
 * @param {object} sensor Объект сенсора
 * @param {boolean} isMultisensor мульти сенсор или нет
 * @returns объект с ошибкой или объект с геометрией
 */
const getXovisSceneGeometriesV5 = async (args) => {
    const { sensor, isMultisensor } = args;

    const sceneGeometries = await xovisGetRequest({
        url: `http://${sensor.ip}:${sensor.port}/api/v5/${
            isMultisensor ? 'multisensor' : 'singlesensor'
        }/scene/geometries`,
        username: sensor.username,
        password: sensor.password,
    });

    if (!sceneGeometries.error) {
        return sceneGeometries.geometries;
    }

    return { error: 'Ann error accrued while getting scene geometries' };
};

export default getXovisSceneGeometriesV5;
