import { getCompanyName } from '../../constants/constants';

export const tdProgressMessages = {
    firstStepEnabled: 'Sensor is online',
    firstStepError: 'Sensor is offline',
    secondStepEnabled:
        'The counting lines on the sensor coincide with the counting lines in the map service',
    secondStepError:
        'The names of the counting lines on the sensor do not match the names from the map service',
    secondStepNoCountLines: 'There are no counting lines on this sensor',
    thirdStepErrorNoCompanyUrl: 'There is no url of the company on the sensor',
    thirdStepErrorWithNoMatchUrls: `The actual ${getCompanyName()} url does not match the url in the sensor configuration`,
    thirdStepErrorWithDeliveryProtocol: 'Wrong delivery protocol',
    thirdStepInvalidRecordCycle: 'Invalid data record cycle',
    thirdStepInvalidUploadCycle: 'Invalid data upload cycle',
    thirdStepInactive: 'Report server is inactive!',
    thirdStepEnabled: 'The url for uploading data from the sensor is relevant',
};
