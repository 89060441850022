import { DefaultButton, Dropdown, PrimaryButton, TextField } from '@fluentui/react';
import { createContext, memo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    ButtonsWrapper,
    Container,
    FileName,
    buttonsStyles,
    multiLineTextFieldStyles,
    modeDropdownStyles,
    timeoutTextFieldStyles,
    ActionWrapperStyles,
    ModeSelectionsLabelStyles,
    StyledTimeoutTextField,
    TimeoutError,
} from './sensorsManagement.styles';
import {
    parseCSVDataThunk,
    processFailedIntervalsThunk,
    reciveReexportTasksThunk,
    startSensorsManagementThunk,
    storeColumnForSort,
    storeDateFrom,
    storeDateTo,
    storePortionType,
    storeSelectedMode,
    storeSelectedSensors,
    storeTimeFrom,
    storeTimeout,
    storeTimeTo,
    toggleTimeFieldsError,
    storeReexportTasksDateTo,
    storeReexportTasksDateFrom,
} from './sensorsManagementReducer';
import SenorsManagementList from './components/senorsManagementList';
import ModalCreator from '../modals/modalCreator';
import ParseErrorsModal from '../modals/parseErrorModal/parseErrorsModal';
import { sensorsManagementMode, sensorsManagementModeKeys } from './tools/dropdownOptions';
import DataUploadPickers from './components/dataUploadPickers';
import useTemporaryToken from './tools/useTemporaryToken';
import { useEffect } from 'react';
import ReexportTaskManagement from '../../commonComponents/reexportTaskManagement';
import useReexportTasksLog from './tools/useReexportTasksLog';

export const SensorsManagementContext = createContext({});
/**
 * Компонент для различных манипуляций с датчиками
 */
const SensorsManagement = memo(() => {
    const INIT_CSV_STRING = 'ip;port;type;pass;user;date_from;date_to';
    const MIN_TIMEOUT = 3;
    const MAX_TIMEOUT = 100;
    const {
        sensorsForManagementById,
        selectedSensors,
        parseErrors,
        portionType,
        uploadingOrderFetching,
        dataUploadFinished,
        selectedMode,
        columnsForList,
        timeout,
        isCSVDataIncludesDateFields,
        dateTo,
        dateFrom,
        timeTo,
        timeFrom,
        temporaryToken,
        reexportTasksDateFrom,
        reexportTasksDateTo,
        reexportFetching,
        reexportTasksCount,
        reexportTasks,
        reexportTasksError,
        reexportProcessedTasks,
    } = useSelector((state) => state.sensorsManegementReducer);
    const { windowSize } = useSelector((state) => state.generalReducer);
    const [showParseErrors, setShowParseErrors] = useState(false);
    const [CSVString, setCSVString] = useState(INIT_CSV_STRING);
    const [file, setFile] = useState(null);
    const [timeoutError, setTimeoutError] = useState(false);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const fileInput = useRef();
    useTemporaryToken();
    useReexportTasksLog();

    useEffect(() => {
        if (temporaryToken) dispatch(reciveReexportTasksThunk());
        // eslint-disable-next-line
    }, [temporaryToken]);

    const processFailedIntervalsThunkTrans = () => dispatch(processFailedIntervalsThunk());

    const toggleTimeFieldsErrorTrans = (value) => dispatch(toggleTimeFieldsError(value));

    const storeSelectedSensorsTrans = (value) => dispatch(storeSelectedSensors(value));

    const storeColumnForSortTrans = (value) => dispatch(storeColumnForSort(value));

    const showTableHandler = () => dispatch(parseCSVDataThunk(file, CSVString));

    const storePortionTypeTrans = (_, item) => dispatch(storePortionType(item));

    const modeDropdownHandler = (_, item) => dispatch(storeSelectedMode(item));

    const storeTimeFromTrans = (value) => dispatch(storeTimeFrom(value));

    const storeDateFromTrans = (value) => dispatch(storeDateFrom(value));

    const storeTimeToTrans = (value) => dispatch(storeTimeTo(value));

    const storeDateToTrans = (value) => dispatch(storeDateTo(value));

    const csvStringHandler = (_, value) => setCSVString(value || '');

    const onFileButtonClick = () => fileInput.current.click();

    const closeParseErrors = () => setShowParseErrors(false);

    const openParseErrors = () => setShowParseErrors(true);

    const reciveReexportTasksThunkTrans = () => dispatch(reciveReexportTasksThunk());

    const storeReexportTasksDateToTrans = (value) => dispatch(storeReexportTasksDateTo(value));

    const storeReexportTasksDateFromTrans = (value) => dispatch(storeReexportTasksDateFrom(value));

    const fileHandler = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setFile(e.target.files[0]);
        }
    };

    const timeoutHandler = (_, value) => {
        dispatch(storeTimeout(+value));
    };

    const timeoutValidator = (value) => {
        if (+value < MIN_TIMEOUT || +value > MAX_TIMEOUT) {
            setTimeoutError(true);
            return <TimeoutError>{`${MIN_TIMEOUT} <= ${t('Timeout')} <= ${MAX_TIMEOUT}`}</TimeoutError>;
        }
        setTimeoutError(false);
    };

    const showModeDropdown =
        file ||
        CSVString.replace(INIT_CSV_STRING, '') ||
        Object.keys(sensorsForManagementById).length > 0 ||
        reexportTasks.length > 0;
    const startHandler = () => dispatch(startSensorsManagementThunk());

    const contextValue = {
        dateTo,
        dateFrom,
        timeTo,
        timeFrom,
        portionType,
        selectedSensors,
        windowSize,
        selectedMode,
        columnsForList,
        isCSVDataIncludesDateFields,
        sensorsForManagementById,
        uploadingOrderFetching,
        storeSelectedSensorsTrans,
        storePortionTypeTrans,
        dataUploadFinished,
        storeColumnForSortTrans,
        storeDateFromTrans,
        storeDateToTrans,
        storeTimeToTrans,
        storeTimeFromTrans,
        toggleTimeFieldsErrorTrans,
        processFailedIntervalsThunkTrans,
        temporaryToken,
        reexportTasksDateFrom,
        reexportTasksDateTo,
        reexportFetching,
        reexportTasksCount,
        reexportTasks,
        reexportTasksError,
    };

    return (
        <SensorsManagementContext.Provider value={contextValue}>
            <ModalCreator isModalOpen={showParseErrors} closeModal={closeParseErrors}>
                <ParseErrorsModal parseErrors={parseErrors} />
            </ModalCreator>
            <Container>
                <ReexportTaskManagement
                    token={temporaryToken}
                    reexportFetching={reexportFetching}
                    reexportTasks={reexportTasks}
                    reexportTasksError={reexportTasksError}
                    reexportTasksDateFrom={reexportTasksDateFrom}
                    reexportTasksDateTo={reexportTasksDateTo}
                    reexportProcessedTasks={reexportProcessedTasks}
                    reciveReexportTasksThunkTrans={reciveReexportTasksThunkTrans}
                    storeReexportTasksDateToTrans={storeReexportTasksDateToTrans}
                    storeReexportTasksDateFromTrans={storeReexportTasksDateFromTrans}
                />
                <input type="file" ref={fileInput} accept=".csv" onChange={fileHandler} style={{ display: 'none' }} />
                <TextField
                    onChange={csvStringHandler}
                    value={CSVString}
                    styles={multiLineTextFieldStyles}
                    label="CSV string"
                    multiline
                    rows={3}
                />
                {file && (
                    <FileName>
                        <span>Selected file: </span>
                        {file.name}
                    </FileName>
                )}
                <ButtonsWrapper>
                    <DefaultButton styles={buttonsStyles} onClick={onFileButtonClick} text={t('Choose CSV file')} />
                    <PrimaryButton
                        styles={buttonsStyles}
                        onClick={showTableHandler}
                        text={t('Show list')}
                        disabled={!showModeDropdown || uploadingOrderFetching || dataUploadFinished}
                    />
                    {parseErrors.length > 0 && (
                        <DefaultButton onClick={openParseErrors} text={t('Show parse errors')} />
                    )}
                </ButtonsWrapper>
                {Object.keys(sensorsForManagementById).length > 0 && (
                    <>
                        <ModeSelectionsLabelStyles>{t('Mode selections')}</ModeSelectionsLabelStyles>
                        <ActionWrapperStyles>
                            <Dropdown
                                styles={modeDropdownStyles}
                                selectedKey={selectedMode ? selectedMode.key : undefined}
                                onChange={modeDropdownHandler}
                                placeholder={t('Select an option')}
                                options={sensorsManagementMode}
                            />
                            <PrimaryButton
                                onClick={startHandler}
                                disabled={selectedSensors.length === 0 || timeoutError}
                                styles={buttonsStyles}
                                text="Start"
                            />

                            <StyledTimeoutTextField
                                onChange={timeoutHandler}
                                value={String(timeout)}
                                styles={timeoutTextFieldStyles}
                                placeholder="Timeout"
                                type="number"
                                step={1}
                                onGetErrorMessage={timeoutValidator}
                            />

                            {showModeDropdown && selectedMode?.key === sensorsManagementModeKeys.DATA_UPLOAD && (
                                <DataUploadPickers />
                            )}
                        </ActionWrapperStyles>
                    </>
                )}

                {Object.keys(sensorsForManagementById).length > 0 && <SenorsManagementList />}
            </Container>
        </SensorsManagementContext.Provider>
    );
});

export default SensorsManagement;
