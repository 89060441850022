import styled from 'styled-components/macro';

export const ContentWrapper = styled.div`
    padding: 10px 15px;
    background-color: #004578;
    display: flex;
    box-sizing: border-box;
    min-height: ${(props) => props.customHeight + 'px'};
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 5;
`;

export const Logo = styled.img`
    width: 150px;
    cursor: pointer;
    margin-right: 20px;
`;

export const NavWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const LanguageWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const LocationName = styled.div`
    font-size: 16px;
    color: #ffff;
`;

export const signOutStyles = {
    root: {
        marginLeft: '10px',
        padding: '0px',
    },
    icon: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#ffff',
        selectors: {
            ':hover': {
                color: '#0078d4',
            },
        },
    },
};

export const CalloutContentWrapper = styled.div`
    padding: 10px;
`;

export const CalloutTextWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const CalloutTextItem = styled.div`
    margin-left: 10px;

    span {
        font-weight: 600;
    }

    :first-child {
        margin-left: 0px;
    }
`;

export const CustomTextArea = styled.textarea`
    position: absolute;
    top: -10000000%;
    left: -10000000%;
`;
