import { DateTime } from 'luxon';

/**
 * Функция для поиска полседней, актуальной версии
 * @param {array} data массив элементов, у которыъ есть поле date_to
 * @returns объект последней версии
 */
const findLatestVersion = (data) => {
    let flag = false;
    if (data.length > 0) {
    }
    const latestVersion = data.reduce((acc, value) => {
        if (flag) return acc;
        if (value.date_to === null) {
            flag = true;
            return value;
        }
        const accDateToInSeconds = DateTime.fromISO(acc.date_to);
        const valueDateToInSeconds = DateTime.fromISO(value.date_to);
        if (valueDateToInSeconds > accDateToInSeconds) return value;
        return acc;
    }, data[0]);
    return latestVersion;
};

export default findLatestVersion;
