import { xovisGetRequest } from '../../../../../api/xovis/xovis.api';
import { getCompanyUploadUrlPart } from '../../../../../constants/constants';

/**
 * Получение соединений датчика с серверами получения данных.
 * Нужно чтобы найти сервер выгрузки компании
 * @param {object} sensor объект сенсора
 * @param {number} timeout timeout
 * @param {boolean} isMultisensor флаг мультисенсора
 * @returns объект с ошибкой или объект с данными о серверах выгрузки
 */
const getXovisConnectionsV5 = async (args) => {
    const { sensor, timeout, isMultisensor } = args;

    const connections = await xovisGetRequest({
        url: `http://${sensor.ip}:${sensor.port}/api/v5/${
            isMultisensor ? 'multisensor' : 'singlesensor'
        }/data/push/connections`,
        username: sensor.username,
        password: sensor.password,
        timeout,
    });

    if (!connections.error) {
        const companyConnection =
            connections.connections?.find((element) => element?.config?.uri?.includes(getCompanyUploadUrlPart())) ||
            null;

        return {
            connections: connections.connections || [],
            companyConnection,
        };
    }

    return { error: 'Ann error accrued while getting connections' };
};

export default getXovisConnectionsV5;
