export const connectionTestColumns = [
    {
        key: 'sensorIp',
        name: 'Ip',
        fieldName: 'sensorIp',
        minWidth: 200,
        maxWidth: 200,
        isSorted: true,
        isSortedDescending: false,
        onColumnClick: true,
    },
    {
        key: 'port',
        name: 'Port',
        fieldName: 'port',
        minWidth: 200,
        maxWidth: 200,
        onColumnClick: true,
    },
    {
        key: 'managementSType',
        name: 'Type',
        fieldName: 'managementSType',
        minWidth: 200,
        maxWidth: 200,
        onColumnClick: true,
    },
    {
        key: 'pass',
        name: 'Password',
        fieldName: 'pass',
        minWidth: 200,
        maxWidth: 200,
        onColumnClick: true,
    },
    {
        key: 'user',
        name: 'Username',
        fieldName: 'user',
        minWidth: 200,
        maxWidth: 200,
        onColumnClick: true,
    },
    {
        key: 'connectStatus',
        name: 'Online',
        fieldName: 'connectStatus',
        minWidth: 200,
        maxWidth: 200,
        onColumnClick: true,
    },
    {
        key: 'percentage',
        name: 'Connection percentage',
        fieldName: 'percentage',
        minWidth: 200,
        maxWidth: 200,
        onColumnClick: true,
    },
];
