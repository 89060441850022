import { xovisGetRequest } from '../../../../../api/xovis/xovis.api';

const getXovisAgentsV5 = async (args) => {
    const { sensor, isMultisensor } = args;

    const agents = await xovisGetRequest({
        url: `http://${sensor.ip}:${sensor.port}/api/v5/${
            isMultisensor ? 'multisensor' : 'singlesensor'
        }/data/push/agents`,
        username: sensor.username,
        password: sensor.password,
    });

    if (!agents.error) {
        return {
            agents: agents.agents || [],
        };
    }

    return { error: 'Ann error accrued while getting agents' };
};

export default getXovisAgentsV5;
