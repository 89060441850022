import axios from 'axios';
import getXovisInfo from './underV5Methods/getXovisInfo';
import getXovisInfoV5 from './v5Methods/getXovisInfoV5';
import getXovisData from './underV5Methods/getXovisData';
import getXovisDataV5 from './v5Methods/getXovisDataV5';
/**
 * Функция для создания промиса при получении серийников от датчиков
 * @param {object} sensor объект сенсора
 * @param {number} timeout таймаут в миллисекундах
 * @returns промис
 */
const getXovisSensorData = (sensor, timeout = 10000) => {
    const info = getXovisInfo({ sensor, timeout });
    const infoV5 = getXovisInfoV5({ sensor, timeout });

    return axios
        .all([info, infoV5])
        .then(
            axios.spread(async (info, infoV5) => {
                if (info.error && infoV5.error)
                    return {
                        sensor: { ...sensor },
                        error: 'Ann error accrued while getting sensor info',
                    };

                const xovisData = {
                    data: {},
                    v5Data: {},
                    version: null,
                };

                let isMultiSensor = false;
                let serialNumber = '';
                let layers = {};
                let multiSensorLayers = {};

                if (!info.error) {
                    xovisData.version = info.version;
                    serialNumber = info.serialNumber;
                }

                if (!infoV5.error) {
                    xovisData.version = infoV5.version;
                    serialNumber = infoV5.serialNumber;
                }

                const version = Number(xovisData.version?.split('.')[0]);
                if (version < 5) {
                    const data = await getXovisData({ sensor, timeout });

                    if (!data.error) {
                        xovisData.data = { ...data };
                        isMultiSensor = data.config?.isMultiSensor || false;
                        layers = data.layers;
                        multiSensorLayers = data.multiSensorLayers;
                    }
                } else if (version >= 5) {
                    const data = await getXovisDataV5({ sensor, timeout });
                    if (!data.error) {
                        xovisData.v5Data = { ...data };
                        isMultiSensor = data.isMultiSensor || false;
                        layers = data.layers;
                        multiSensorLayers = data.multiSensorLayers;
                    }
                }
                return {
                    sensor: {
                        ...sensor,
                        xovisData,
                        serialNumber,
                        isMultiSensor,
                        layers,
                        multiSensorLayers,
                    },
                };
            }),
        )
        .catch((_) => {
            return {
                sensor: { ...sensor },
                error: 'Ann error accrued while getting sensor info',
            };
        });
};

export default getXovisSensorData;
