export const getUploadDataPattern = `<?xml version="1.0" encoding="utf-16"?>
<countingStatisticsDescription xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema">
    <reportType>daily</reportType>
    <timeSpanList>
        <timeSpan>
            <startTime></startTime>
            <endTime></endTime>
        </timeSpan>
    </timeSpanList>
</countingStatisticsDescription>`;

export const postHikvisionUploadDataPattern = `<?xml version="1.0" encoding="UTF-8"?>
<EventNotificationAlert version="1.0" xmlns="http://www.std-cgi.com/ver20/XMLSchema">
	<deviceName></deviceName>
	<model></model>
	<serialNumber></serialNumber>
	<ipAddress></ipAddress>
	<macAddress></macAddress>
	<channelID></channelID>
	<dateTime></dateTime>
	<eventType>PeopleCounting</eventType>
	<eventState>active</eventState>
	<eventDescription>peopleCounting alarm</eventDescription>
	<channelName></channelName>
	<peopleCounting>
		<statisticalMethods>timeRange</statisticalMethods>
		<TimeRange>
			<startTime></startTime>
			<endTime></endTime>
		</TimeRange>
		<enter></enter>
		<exit></exit>
		<pass></pass>
	</peopleCounting>
</EventNotificationAlert>`;
