import { xovisGetRequest } from '../../../../../api/xovis/xovis.api';

/**
 * получение информации из конфигурации мультисенсора
 * @param {object} sensor объект сенсора
 * @param {number} timeout timeout
 * @returns объект с ошибкой или объект с данными датчика
 */
const getXovisMultisensorData = async (args) => {
    const { sensor, timeout } = args;

    const multisensorData = await xovisGetRequest({
        url: `http://${sensor.ip}:${sensor.port}/api/multisensor/analytics`,
        timeout,
        username: sensor.username,
        password: sensor.password,
    });

    if (!multisensorData.error) {
        const oParser = new DOMParser();
        const multisensorDataXML = oParser.parseFromString(
            multisensorData,
            'text/xml',
        );

        let scaleX = null;
        let scaleY = null;
        let xStartCoordinate = null;
        let yStartCoordinate = null;

        const im2relRows = multisensorDataXML.querySelectorAll(
            'transformation > im2rel > row',
        );
        const rel2imRows = multisensorDataXML.querySelectorAll(
            'transformation > rel2im > row',
        );

        if (rel2imRows) {
            rel2imRows.forEach((element, index) => {
                if (index === 0) {
                    if (element.textContent) {
                        xStartCoordinate = +element.textContent.split(' ')[2];
                    }
                }

                if (index === 1) {
                    if (element.textContent) {
                        yStartCoordinate = +element.textContent.split(' ')[2];
                    }
                }
            });
        }

        if (im2relRows) {
            im2relRows.forEach((element, index) => {
                if (index === 0) {
                    if (element.textContent) {
                        scaleX = +element.textContent.split(' ')[0];
                    }
                }

                if (index === 1) {
                    if (element.textContent) {
                        scaleY = +element.textContent.split(' ')[1];
                    }
                }
            });
        }

        return {
            scaleX,
            scaleY,
            xStartCoordinate,
            yStartCoordinate,
            rawMultisensorData: multisensorData,
        };
    }

    return { error: 'Ann error accrued while getting multisensor info' };
};

export default getXovisMultisensorData;
