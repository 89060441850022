/**
 * Функция для парсинга ответа от сенсора
 * @param {string} data xml данные с сенсора
 * @returns объект с информацией о ip сенсора или ошибку
 */
const processRowNetwork = (data) => {
    const oParser = new DOMParser();
    const xmlResponse = oParser.parseFromString(data, 'text/xml');
    const ipAddress = xmlResponse.querySelector('IPAddress > ipAddress').textContent;
    if (ipAddress) {
        return { ipAddress };
    }
    return { error: 'Invalid ip address' };
};

export default processRowNetwork;
