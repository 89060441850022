export const columns = [
    {
        key: 'marker',
        name: 'Marker',
        fieldName: 'marker',
        isResizable: true,
        minWidth: 200,
        showSortIconWhenUnsorted: true,
    },
    {
        key: 'sensorAddress',
        name: 'Sensor address',
        fieldName: 'sensorAddress',
        isResizable: true,
        minWidth: 200,
        showSortIconWhenUnsorted: true,
    },
    {
        key: 'sensorType',
        name: 'Sensor type',
        fieldName: 'sensorType',
        isResizable: true,
        minWidth: 100,
        showSortIconWhenUnsorted: true,
    },
    {
        key: 'status',
        name: 'Status',
        fieldName: 'status',
        isResizable: true,
        minWidth: 100,
        showSortIconWhenUnsorted: true,
    },
    {
        key: 'message',
        name: 'Message',
        fieldName: 'message',
        isResizable: true,
        minWidth: 200,
        showSortIconWhenUnsorted: true,
    },
];
