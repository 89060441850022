import { DatePicker, MaskedTextField } from '@fluentui/react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {
    CreateBoundleButton,
    CreateBoundleTitle,
    InformationWrapper,
    InformationForBoundle,
    CreateBoundleButtonWrapper,
    BoundleErrorMessage,
    DatetimePickerText,
    stylesForDatePicker,
    DatetimePickersWrapper,
    stylesForTimePicker,
} from './createSensorAndPointBoundle.styles';
import { createNewSensorAndPointBoundle } from '../../instalationPoints/installationPointsReducer';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

/**
 * Компонент для создания связки между сенсором и точкой установки
 */
const CreateSensorAndPointBoundle = () => {
    const { sensorForBoundleCreate } = useSelector((state) => state.boundleInfoModalReducer);
    const { iPointForBoundleInfoModal } = useSelector((state) => state.instalationPointsReducer);
    const { dateIntervalError } = useSelector((state) => state.createSensorAndPointBoundleReducer);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [time, setTime] = useState('00:00:00');
    const [timeError, setTimeError] = useState(false);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const timeRegExp = /(?:[01]\d|2[0-3]):(?:[0-5]\d):(?:[0-5]\d)/;

    const timeFieldValidator = (value) => {
        if (!timeRegExp.test(value)) {
            setTimeError(true);
            return 'Wrong format';
        } else {
            setTimeError(false);
            return undefined;
        }
    };

    const timeHandler = (_, value) => setTime(value);

    const createBoundleHandler = () => {
        if (!timeError) {
            dispatch(
                createNewSensorAndPointBoundle({
                    sensorId: sensorForBoundleCreate.id,
                    iPointId: iPointForBoundleInfoModal.id,
                    date: selectedDate.toISOString(),
                    time,
                })
            );
        }
    };

    return (
        <>
            <CreateBoundleTitle>Create boundle</CreateBoundleTitle>
            <InformationWrapper>
                <InformationForBoundle>
                    <span>Sensor IP:</span> {sensorForBoundleCreate.ip}:{sensorForBoundleCreate.port}
                </InformationForBoundle>
                <InformationForBoundle>
                    <span>Sensor name:</span> {sensorForBoundleCreate.sensor_name}
                </InformationForBoundle>
                <InformationForBoundle>
                    <span>IPoint marker: </span>
                    {iPointForBoundleInfoModal.marker}
                </InformationForBoundle>
            </InformationWrapper>

            <DatetimePickerText>{t('Choose datetime')}</DatetimePickerText>
            <DatetimePickersWrapper>
                <DatePicker
                    value={selectedDate}
                    styles={stylesForDatePicker}
                    onGetErrorMessage={timeFieldValidator}
                    onSelectDate={setSelectedDate}
                    formatDate={(date) => DateTime.fromISO(date.toISOString()).toFormat('yyyy-MM-dd')}
                    placeholder='Select a date...'
                    ariaLabel='Select a date'
                />
                <MaskedTextField
                    styles={stylesForTimePicker}
                    onGetErrorMessage={timeFieldValidator}
                    value={time}
                    onChange={timeHandler}
                    validateOnLoad={false}
                    validateOnFocusOut={true}
                    mask='99:99:99'
                />
            </DatetimePickersWrapper>

            {dateIntervalError ? <BoundleErrorMessage>{t('Invalid date interval')}</BoundleErrorMessage> : null}
            <CreateBoundleButtonWrapper>
                <CreateBoundleButton disabled={timeError} onClick={createBoundleHandler}>
                    {t('Add boundle')}
                </CreateBoundleButton>
            </CreateBoundleButtonWrapper>
        </>
    );
};
export default CreateSensorAndPointBoundle;
