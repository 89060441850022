import processVivotekLayers from '../../commonFunctions/vivotek/processVivotekLayers';
import { getCompanyUploadUrlPart } from '../../constants/constants';
/**
 * Вспомогательная функция для получения информации для прогресс бара связки с датчика VIVOTEK
 * @param {object} vivotekDataUploadInfo объект всех выгрузок с сенсора VIVOTEK
 * @param {object} vivotekCountLines объект всех диний подсчета с сенсора VIVOTEK
 */
const getInfoForVivotekBoundle = (options) => {
    const { vivotekDataUploadInfo, vivotekCountLines } = options;
    let vivotekDataUploadUrl = '';
    let vivotekCountLinesByLineName = {};
    let dataUploadInterval = '';
    let dataUploadGranularity = '';
    let vivotekData = {
        reportPushServers: vivotekDataUploadInfo || [],
        focustechUploadUrlIndex: null,
    };

    const { passWays } = processVivotekLayers(vivotekCountLines);
    passWays.forEach(
        (element) => (vivotekCountLinesByLineName[element.marker] = element),
    );

    Object.keys(vivotekDataUploadInfo).forEach((key) => {
        if (
            vivotekDataUploadInfo[key].url.includes(getCompanyUploadUrlPart())
        ) {
            vivotekDataUploadUrl =
                vivotekDataUploadInfo[key].url + vivotekDataUploadInfo[key].uri;
            dataUploadInterval = vivotekDataUploadInfo[key].schedule;
            dataUploadGranularity = vivotekDataUploadInfo[key].aggregation;
            vivotekData.focustechUploadUrlIndex = key;
        }
    });

    return {
        vivotekDataUploadUrl,
        vivotekCountLinesByLineName,
        dataUploadInterval,
        dataUploadGranularity,
        vivotekData,
    };
};

export default getInfoForVivotekBoundle;
