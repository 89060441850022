import { tdGetRequest } from '../../../../api/td/td.api';

/**
 * Функция для получения данных выгрузке сервера
 * @param {string} ip IP датчика
 * @param {number} port PORT датчика
 * @param {number} serverId id сервера
 * @returns Promise c объектом данных о сервере выгрузке или объект с ошибкой
 */
const getTdServerUploadSettings = async (args) => {
    const { ip, port, serverId } = args;

    const settings = await tdGetRequest({
        url: `http://${ip}:${port}/upload_setting?serverid=${serverId}`,
    });

    if (!settings.error) {
        const oParser = new DOMParser();
        const settingsHTML = oParser.parseFromString(settings, 'text/html');

        const deliveryProtocol = settingsHTML.querySelector(
            '#uploadWay > option:checked',
        )?.id;
        const dataFormat = settingsHTML.querySelector(
            '#FileType > option:checked',
        )?.id;
        const recordCycle = settingsHTML.querySelector(
            '#countinterval > option:checked',
        )?.value;
        const recordCycleCountInterval = settingsHTML.querySelector(
            '#countintervalBy > option:checked',
        )?.id;
        const uploadCycle = settingsHTML.querySelector(
            '#uploadinterval > option:checked',
        )?.value;
        const uploadCycleCountInterval = settingsHTML.querySelector(
            '#uploadintervalBy > option:checked',
        )?.id;

        return {
            deliveryProtocol,
            dataFormat,
            recordCycle,
            recordCycleCountInterval,
            uploadCycle,
            uploadCycleCountInterval,
        };
    }

    return {
        error: `Unable to get upload settings for server with id ${serverId}`,
    };
};

export default getTdServerUploadSettings;
