/**
 * Функция для проверки отрезка времени. Проверяет не пересекается ли он с отрезками, которые есть в переданном массиве
 * @param {array} sensorsAndPointsBounles массив связок точек с сенсорами
 * @param {string} dateFrom время от которого была создана связка
 * @param {string} dateTo время, когда связка "закрыли"
 * @returns boolean
 */
const dateIntersection = (options) => {
    const { sensorsAndPointsBounles, dateFrom, dateTo } = options;
    const dateFromInSeconds = Date.parse(dateFrom);
    const dateToInSeconds = Date.parse(dateTo);
    let flag = true;
    let successArray = [];

    if (dateToInSeconds && dateFromInSeconds > dateToInSeconds) return true;

    if (sensorsAndPointsBounles.length === 0) flag = false;
    sensorsAndPointsBounles.forEach((element) => {
        const elementDateFromInSeconds = Date.parse(element.date_from);
        const elementDateToInSeconds = Date.parse(element.date_to);
        // if (element.date_to === null) flag = true;

        // if (dateToInSeconds) {
        //     if (flag && dateFromInSeconds < elementDateFromInSeconds && dateToInSeconds <= elementDateFromInSeconds) {
        //         flag = false;
        //         console.log('flag', flag, 'from', dateFrom, 'elfrom', element.date_from, 'to', dateTo, 'elfrom', element.date_from);
        //     }

        //     if (flag && dateFromInSeconds > elementDateFromInSeconds && dateFromInSeconds >= elementDateToInSeconds && dateToInSeconds >= elementDateToInSeconds) {
        //         flag = false;
        //         console.log('flag', flag, 'from', dateFrom, 'elfrom', element.date_from, 'from', dateFrom, 'elto', element.date_to);
        //     }
        // } else {
        //     console.log(2);
        //     if (flag && dateFromInSeconds >= elementDateToInSeconds) flag = false;
        // }
        if (dateToInSeconds) {
            if (!elementDateToInSeconds) {
                if (dateToInSeconds < elementDateFromInSeconds) flag = false;
            } else {
                if (dateFromInSeconds < elementDateFromInSeconds && dateToInSeconds < elementDateFromInSeconds) {
                    flag = false;
                } else if (dateFromInSeconds > elementDateFromInSeconds && dateFromInSeconds > elementDateToInSeconds && dateToInSeconds > elementDateToInSeconds) {
                    flag = false;
                } else {
                    flag = true;
                }
            }
        } else {
            if (!elementDateToInSeconds) {
                flag = true;
            } else {
                if (dateFromInSeconds > elementDateToInSeconds) flag = false;
            }
        }
        if (!flag) successArray.push('success');
    });

    if (successArray.length === sensorsAndPointsBounles.length) {
        return false;
    } else {
        return true;
    }
};

export default dateIntersection;
