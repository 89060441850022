import { DefaultButton, Spinner, SpinnerSize, TooltipHost } from '@fluentui/react';
import { useContext, useMemo, useRef } from 'react';
import { ColumnWrapper } from '../../instalationPointsList.styles';
import { CustomTextArea, StyledIcon, stylesForButton, stylesForToolTip } from './brickstreamUpdateConfiguration.styles';
import { useTranslation } from 'react-i18next';
import { InstallationPointsContext } from '../../../../../../installationPoints';

/**
 * Компонент для отрисовки колонки для обновления конфигурации датчика для BRICKSTREAM
 * @param {object} selectedSensor объект выбранного сенсора
 * @param {object} iPoint объект точки установки
 * @param {string} uploadKey ключ для выгрузки
 * @param {object} availableBundlesByIPointId объект связок, где ключ это id связки
 */
const BrickstreamUpdateConfiguration = ({ uploadKey, selectedSensor, availableBundlesByIPointId, iPoint }) => {
    const { updateSensorConfigurationThunkTrans, sensorUploadUrlsBySensorType } = useContext(InstallationPointsContext);
    const { t } = useTranslation();
    const copyRef = useRef();

    const uploadUrl = sensorUploadUrlsBySensorType[selectedSensor.sensor_type]?.uploadUrl;
    const CORRECT_URL = `${uploadUrl?.protocol}://${uploadUrl?.host}/fpc/v1/${selectedSensor.sensor_type}/${uploadKey}`;

    const bundleData = useMemo(() => {
        if (availableBundlesByIPointId?.[iPoint?.id]) {
            return availableBundlesByIPointId[iPoint.id];
        }
        return null;
    }, [availableBundlesByIPointId, iPoint]);

    const copyHandler = () => {
        copyRef.current.value = CORRECT_URL;
        copyRef.current.focus();
        copyRef.current.select();
        try {
            document.execCommand('copy');
        } catch (error) {
            console.log(error);
        }
    };

    const updateHandler = () => {
        updateSensorConfigurationThunkTrans({
            sensorType: selectedSensor.sensor_type,
            sensorId: selectedSensor.id,
            availableBundlesByIPointId,
            iPointId: iPoint.id,
            data: {},
        });
    };

    if (!uploadKey) {
        return (
            <ColumnWrapper>
                <>{t('No access key')}</>
            </ColumnWrapper>
        );
    }

    if (bundleData?.updateConfigurationFetching) {
        return (
            <ColumnWrapper>
                <Spinner size={SpinnerSize.large} />
            </ColumnWrapper>
        );
    }

    if (
        bundleData?.isFetched &&
        bundleData?.thirdStepError &&
        !bundleData?.firstStepError &&
        (bundleData?.sensorData?.cfg?.dataPushServers?.companyDataPushServer ||
            bundleData?.sensorData?.cfg?.dataPushServers?.dataPushServers.some((server) => server.isEmpty))
    ) {
        return (
            <ColumnWrapper>
                <CustomTextArea ref={copyRef} />
                <DefaultButton styles={stylesForButton} onClick={updateHandler}>
                    {t('Update')}
                </DefaultButton>
                <TooltipHost content={CORRECT_URL} styles={stylesForToolTip}>
                    <StyledIcon onClick={copyHandler} iconName="Copy" />
                </TooltipHost>
            </ColumnWrapper>
        );
    }

    return (
        <ColumnWrapper>
            <CustomTextArea ref={copyRef} />
            <TooltipHost content={CORRECT_URL}>
                <DefaultButton styles={stylesForButton} onClick={copyHandler}>
                    {t('Copy link')}
                </DefaultButton>
            </TooltipHost>
        </ColumnWrapper>
    );
};

export default BrickstreamUpdateConfiguration;
