import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    passWays: [],
    passWaysOnCurrentFloor: [],
    showPassWayLabel: false,
    showPassWayLayer: true,
};

const passWaysReducer = createSlice({
    name: 'passWaysReducer',
    initialState,
    reducers: {
        // Запись всех проходов в стейт
        setPassWays: (state, action) => {
            state.passWays = action.payload;
        },

        // Запись проходов для выбранного жтажа в стейт
        setPassWaysOnCurrentFloor: (state, action) => {
            state.passWaysOnCurrentFloor = action.payload;
        },

        // Показать/скрыть лебел с информацией
        setShowPassWayLabel: (state, action) => {
            state.showPassWayLabel = action.payload;
        },

        // Показать/скрыть слой прохода
        setShowPassWayLayer: (state, action) => {
            state.showPassWayLayer = action.payload;
        },
    },
});

export const { setPassWays, setPassWaysOnCurrentFloor, setShowPassWayLabel, setShowPassWayLayer } =
    passWaysReducer.actions;

export default passWaysReducer.reducer;
