import { isNull, isUndefined } from 'lodash';
import { megacountProgressMessages } from '../../../../utils/megacountHelpers/megacountProgressMessages';
import {
    MEGACOUNT_VALID_DATA_INTERVAL,
    MEGACOUNT_VALID_FILE_TYPE,
    MEGACOUNT_VALID_SEND_FREQ,
} from '../../../../constants/megacountSensor';

/**
 * Вспомогательная функция для создания связки с сенсором MEGACOUNT
 * @param {object} iPoint объект точки установки
 * @param {object} sensor объект сенсора
 * @param {object} currentBoundle объект текущей связки
 * @param {string} accessKey ключ для выгрузки данных
 */
const getBundleForMegacountHelper = (options) => {
    const { iPoint, sensor, currentBoundle, accessKey, uploadUrl } = options;

    let thirdStepError = '';
    let secondStepError = '';

    const companyServer = sensor?.megacountData?.companyUploadServer;

    /**
     * Проверка 3 шага валидации
     */
    if (!isNull(companyServer) && !isUndefined(companyServer)) {
        const companyServer = sensor.megacountData.companyUploadServer;
        const CORRECT_URL = `${uploadUrl?.protocol}://${uploadUrl?.host}/fpc/v1/${sensor.sensor_type}/${accessKey}`;
        const sensorUrl = `${companyServer?.protocol_settings?.protocol}://${companyServer?.protocol_settings?.server}${companyServer?.protocol_settings?.url}`;

        if (CORRECT_URL !== sensorUrl) {
            thirdStepError = megacountProgressMessages.thirdStepErrorWithNoMatchUrls;
        }

        if (companyServer?.protocol_settings?.protocol !== 'https') {
            thirdStepError = megacountProgressMessages.thirdStepErrorWithDeliveryProtocol;
        }

        if (companyServer?.data_settings?.data_interval !== MEGACOUNT_VALID_DATA_INTERVAL) {
            thirdStepError = megacountProgressMessages.thirdStepInvalidDataInterval;
        }

        if (companyServer?.send_freq !== MEGACOUNT_VALID_SEND_FREQ) {
            thirdStepError = megacountProgressMessages.thirdStepInvalidSendFreq;
        }

        if (companyServer?.file_type !== MEGACOUNT_VALID_FILE_TYPE) {
            thirdStepError = megacountProgressMessages.thirdStepInvalidFileType;
        }
    } else {
        if (sensor.firstStepError) {
            thirdStepError = megacountProgressMessages.firstStepError;
        } else {
            thirdStepError = megacountProgressMessages.thirdStepErrorNoCompanyServer;
        }
    }

    /**
     * Проверка второго шага валидации
     */
    if (sensor.megacountData?.sensors?.length) {
        let matchingPoints = 0;
        sensor.megacountData?.sensors?.forEach((sensor) => {
            Object.keys(iPoint.passPoints).forEach((point) => {
                if (iPoint.passPoints?.[point]?.lineName === sensor.name) {
                    matchingPoints++;
                }
            });
        });
        if (!(matchingPoints >= Object.keys(iPoint.passPoints).length)) {
            secondStepError = megacountProgressMessages.secondStepError;
        }
    } else {
        secondStepError = megacountProgressMessages.secondStepNoCountLines;
    }

    return {
        iPointId: iPoint.id,
        value: {
            ...sensor,
            isFetching: sensor.isConfigurationFetching,
            current: true,
            boundleId: currentBoundle.id,
            thirdStepError,
            secondStepError,
        },
    };
};

export default getBundleForMegacountHelper;
