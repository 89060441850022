import { DefaultButton } from '@fluentui/react';
import styled from 'styled-components/macro';

export const CreateBoundleTitle = styled.div`
    font-size: 16px;
    font-weight: 600;
    margin-top: 5px;
`;

export const CreateBoundleButton = styled(DefaultButton)`
    margin-top: 10px;
`;

export const InformationWrapper = styled.div`
    margin-top: 10px;
`;

export const InformationForBoundle = styled.div`
    color: rgba(0, 0, 0, 0.54);
    span {
        font-weight: 500;
        color: black;
    }
`;

export const CreateBoundleButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

export const BoundleErrorMessage = styled.div`
    margin-top: 5px;
    color: rgb(164, 38, 44);
    font-size: 12px;
`;

export const DatetimePickerText = styled.div`
    font-size: 14px;
    font-weight: 600;
    color: rgb(50, 49, 48);
`;

export const stylesForDatePicker = {
    root: {
        flex: '0 0 70%',
    },
};

export const stylesForTimePicker = {
    root: {
        flex: '0 0 25%',
    },
};

export const DatetimePickersWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
`;
