import { Dropdown } from '@fluentui/react';
import { useContext, useEffect, useState } from 'react';
import { InstallationPointsContext } from '../../../../../installationPoints';

/**
 * Компонент отображения зависимостей в списке
 * @param {object} iPoint объект точки установки
 */
const Dependencies = ({ iPoint }) => {
    const { slavesByIp, availableBundlesByIPointId } = useContext(InstallationPointsContext);
    const [dropdownOptions, setDropdownOptions] = useState([]);
    const [placeholder, setPlaceholder] = useState('');
    const [selectedItem, setSelectedItem] = useState(null);
    useEffect(() => {
        setPlaceholder('');
        setSelectedItem(null);
    }, [iPoint]);

    useEffect(() => {
        if (availableBundlesByIPointId[iPoint.id]?.isMultiSensor) {
            setPlaceholder('Master slaves');
            setSelectedItem({ key: 'masterSlaves', text: 'Master slaves' });
            const options = [];
            availableBundlesByIPointId[iPoint.id].multiSensorSlaves
                ?.filter((element) => element !== availableBundlesByIPointId[iPoint.id].ip)
                .forEach((element, i) => {
                    options.push({ key: `${i}. ${element}`, text: element });
                });
            setDropdownOptions(options);
        } else if (slavesByIp[availableBundlesByIPointId[iPoint.id]?.ip]) {
            setPlaceholder('Slave masters');
            setSelectedItem({ key: 'slaveMasters', text: 'Slave masters' });
            const options = [];
            slavesByIp[availableBundlesByIPointId[iPoint.id].ip].masters.forEach((element, i) => {
                options.push({ key: `${i}. ${element}`, text: element });
            });
            setDropdownOptions(options);
        }
    }, [iPoint, slavesByIp, availableBundlesByIPointId]);

    const dropdownHandler = (_, item) => window.open(`http://${item.text}`, '_blank').focus;

    return (
        <>
            {placeholder ? (
                <Dropdown
                    placeholder={placeholder}
                    onChange={dropdownHandler}
                    selectedKey={selectedItem ? selectedItem.key : undefined}
                    options={dropdownOptions}
                />
            ) : null}
        </>
    );
};

export default Dependencies;
