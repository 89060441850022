import { createSlice } from '@reduxjs/toolkit';
import idGenerator from '../../utils/idGenerator';
import axios from 'axios';
import findLatestVersion from './tools/findLatestVersion';
import { cloneDeep } from 'lodash';
import { realationsTypes } from '../../constants/realationsTypes';
import { getHeaders } from '../../utils/getHeaders';

const initialState = {
    showIntersectionsLayer: true,
    allIntersections: [],
    passWaysAndIPointsRelations: [],
    passWaysAndIPointsRelationsByIPointMarker: [],
    isRealationsFetching: false,
};

const intersectionsForDisplayReducer = createSlice({
    name: 'intersectionsForDisplayReducer',
    initialState,
    reducers: {
        // Показать / скрыть слой перечений pass point c pass way
        setShowIntersectionsLayer: (state, action) => {
            state.showIntersectionsLayer = action.payload;
        },

        // Изменить порядок массива пересечений, для корректного изображения на конвасе
        changeOrder: (state, action) => {
            const { floor, id } = action.payload;
            const passWaysAndIPointsRelations = cloneDeep(state.passWaysAndIPointsRelations);
            const floorElement = cloneDeep(passWaysAndIPointsRelations.find((element) => element.floor === floor));
            const floorIndex = passWaysAndIPointsRelations.findIndex((element) => element.floor === floor);

            if (floorElement && floorIndex >= 0) {
                passWaysAndIPointsRelations.splice(floorIndex, 1);
                const interactions = cloneDeep(floorElement.interactions);
                const interactionElement = interactions.find((element) => element.id === id);
                const interactionIndex = interactions.findIndex((element) => element.id === id);

                if (interactionElement && interactionIndex >= 0) {
                    interactions.splice(interactionIndex, 1);
                    interactions.push(interactionElement);
                    floorElement.interactions = interactions;
                }
                passWaysAndIPointsRelations.push(floorElement);
            }
            state.passWaysAndIPointsRelations = passWaysAndIPointsRelations;
        },

        // Запись в стейт информации о всех взаимосвязях
        storeAllIntersections: (state, action) => {
            const latestVersion = findLatestVersion(action.payload);
            const passWaysAndIPointsRelations = latestVersion.relations[realationsTypes.passways_pcc_tuple]?.map(
                (element) => {
                    const newInteractions = element.interactions?.map((element) => ({ id: idGenerator(), ...element }));
                    return { ...element, interactions: newInteractions };
                },
            );

            const newArray = [];

            latestVersion.relations[realationsTypes.passways_pcc_tuple]?.forEach((element) => {
                const interactions = element.interactions?.reduce((acc, value) => {
                    if (acc[value.info.pccMarker]) {
                        acc[value.info.pccMarker].push(value);
                    } else {
                        acc[value.info.pccMarker] = [{ ...value }];
                    }
                    return acc;
                }, {});
                newArray.push({ floor: element.floor, interactions });
            });

            state.passWaysAndIPointsRelationsByIPointMarker = newArray;
            state.passWaysAndIPointsRelations = passWaysAndIPointsRelations;
            state.allIntersections = latestVersion.relations;
        },

        // Изменение флага загрузки взаимосвязей
        toggleRelationsFetching: (state, action) => {
            state.isRealationsFetching = action.payload;
        },

        // Обнулление редьюсера
        resetIntersectionsReducer: () => initialState,
    },
});

export const {
    setShowIntersectionsLayer,
    changeOrder,
    storeAllIntersections,
    toggleRelationsFetching,
    resetIntersectionsReducer,
} = intersectionsForDisplayReducer.actions;

export const getAllIntersectionsThunk = () => async (dispatch, getState) => {
    const { token, storeHosts, storeUrls } = getState().generalReducer;
    dispatch(toggleRelationsFetching(true));
    const { locationId } = getState().instalationPointsReducer;
    const body = JSON.stringify({
        x_token: token,
        ml_id: null,
        pl_id: locationId,
        map_service_url: `https://${storeHosts['core/map-service-back']?.url}/`,
        admin_url: `https://${storeHosts['core/admin-service']?.url}/`,
        mode: 'front',
    });

    const data = await axios({
        method: 'POST',
        url: storeUrls.GET_RELATIONS_URL.url,
        data: body,
        headers: getHeaders(token),
    })
        .then((response) => response.data)
        .catch((error) => ({ error }));

    dispatch(toggleRelationsFetching(false));

    if (!data.error) {
        dispatch(storeAllIntersections(data));
    } else {
        console.log('>>> intersections', data?.error);
    }
};

export default intersectionsForDisplayReducer.reducer;
