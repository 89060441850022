import { cloneDeep, keyBy } from 'lodash';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import columnSortHandler from '../../../utils/detailsListHelpers/columnSortHandler';
import { storeColumnsForList, updateSensorsForManagement } from '../sensorsManagementReducer';
import { connectionTestColumns } from './columns/connectionTestColumns';
import { dataUploadColumns } from './columns/dataUploadColumns';
import { screenUploadColumns } from './columns/screenUploadColumns';
import { sensorsManagementModeKeys } from './dropdownOptions';

/**
 * кастомный хук для изменения колонок списка и сортировки списка
 */
const useColumnsForList = () => {
    const { selectedMode, columnForSort, columnsForList, sensorsForManagementById, sensorsForManagement } = useSelector(
        (state) => state.sensorsManegementReducer,
    );
    const dispatch = useDispatch();

    useEffect(() => {
        if (columnForSort) {
            const { newItems, newColumns } = columnSortHandler({
                allColumns: columnsForList,
                column: columnForSort,
                listItems: Object.values(sensorsForManagementById),
                sensorsForManagementById,
            });

            const newItemsById = newItems.reduce((acc, value) => {
                acc[value.id] = value;
                return acc;
            }, {});
            dispatch(storeColumnsForList(newColumns));
            dispatch(updateSensorsForManagement(newItemsById));
        }
        // eslint-disable-next-line
    }, [columnForSort]);

    useEffect(() => {
        dispatch(updateSensorsForManagement(keyBy(sensorsForManagement, 'id')));
        if (selectedMode) {
            switch (selectedMode.key) {
                case sensorsManagementModeKeys.CONNECTION_TEST:
                    dispatch(storeColumnsForList(cloneDeep(connectionTestColumns)));
                    break;
                case sensorsManagementModeKeys.DATA_UPLOAD:
                    dispatch(storeColumnsForList(cloneDeep(dataUploadColumns)));
                    break;

                case sensorsManagementModeKeys.SCREENS_UPLOAD:
                    dispatch(storeColumnsForList(cloneDeep(screenUploadColumns)));
                    break;
                default:
                    break;
            }
        }
        // eslint-disable-next-line
    }, [selectedMode]);
};

export default useColumnsForList;
