/**
 * Функция для получения id проектной локации из урла
 * @returns id проектной локации
 */
const getProjectLocationIdFromUrl = () => {
    let projectLocationId = null;
    const url = window.location.href;
    const regexp = /project-locations\/\d+/gm;
    const result = url.match(regexp);
    if (result && result.length) {
        projectLocationId = +result[0].split('/')[1];
    }
    return projectLocationId;
};

export default getProjectLocationIdFromUrl;
