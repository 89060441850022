import { isNumber } from 'lodash';
import { xovisProgressMessages } from '../../../../utils/xovisHelpers/xovisProgressMessages';
import { xovisDataUploadCorrectValues } from '../../../../utils/xovisHelpers/xovisDataUploadCorrectValues';

/**
 * Вспомогательная функция для создания связки с сенсоров XOVIS
 * @param {object} iPoint объект точки установки
 * @param {object} sensor объект сенсора
 * @param {object} currentBoundle объект текущей связки
 * @param {string} accessKey ключ для выгрузки данных
 * @param {object} uploadUrl актуальная выгрузка для датчика
 */
const setBoundleforXovisHelper = (options) => {
    const { iPoint, sensor, currentBoundle, accessKey, uploadUrl } = options;

    const CORRECT_URL = `${uploadUrl?.protocol}://${uploadUrl?.host}${
        uploadUrl?.port ? `:${uploadUrl.port}` : ''
    }/fpc/v1/${sensor.sensor_type}/${accessKey}`;

    let thirdStepError = '';
    let secondStepError = '';

    const version = Number(sensor.xovisData?.version?.split('.')?.[0]);
    if (isNumber(version) && sensor.isFetched && !sensor.firstStepError) {
        if (version < 5) {
            /** Проверка 3 шага */
            const datapushAgent = sensor.xovisData?.data?.config?.companyDatapushAgent;
            if (!datapushAgent) {
                thirdStepError = xovisProgressMessages.thirdStepErrorWithNoUrl;
            } else if (datapushAgent?.url !== CORRECT_URL) {
                thirdStepError = xovisProgressMessages.thirdStepErrorWithNoMatchUrls;
            }

            if (!datapushAgent?.interval) {
                thirdStepError = xovisProgressMessages.thirdStepNoDataUplaodInterval;
            } else if (datapushAgent?.interval !== xovisDataUploadCorrectValues.dataUploadInterval) {
                thirdStepError = xovisProgressMessages.thirdStepInvalidDataUploadInterval;
            }

            if (!datapushAgent?.granularity) {
                thirdStepError = xovisProgressMessages.thirdStepNoDataUplaodGranularity;
            } else if (datapushAgent?.granularity !== xovisDataUploadCorrectValues.dataUploadGranularity) {
                thirdStepError = xovisProgressMessages.thirdStepInvalidDataUploadGranularity;
            }

            /** Проверка 2 шага */
            if (sensor?.xovisData?.data?.config?.passWayNames?.length) {
                let matchingPoints = 0;
                sensor.xovisData.data.config.passWayNames.forEach((name) => {
                    Object.keys(iPoint.passPoints).forEach((point) => {
                        if (iPoint.passPoints?.[point]?.lineName === name) {
                            matchingPoints++;
                        }
                    });
                });

                if (!(matchingPoints >= Object.keys(iPoint.passPoints).length)) {
                    secondStepError = xovisProgressMessages.secondStepError;
                }
            } else {
                secondStepError = xovisProgressMessages.secondStepNotCountLines;
            }
        } else if (version >= 5) {
            /** Проверка 3 шага */
            const companyConnection = sensor.xovisData?.v5Data?.companyConnection;
            const companyAgent = sensor.xovisData?.v5Data?.companyAgent;
            const logics = sensor.xovisData?.v5Data?.templates?.logics;

            if (!companyConnection) {
                thirdStepError = xovisProgressMessages.thirdStepErrorWithNoUrl;
            } else if (companyConnection?.config?.uri !== CORRECT_URL) {
                thirdStepError = xovisProgressMessages.thirdStepErrorWithNoMatchUrls;
            }

            if (!companyAgent?.config?.data?.package_size) {
                thirdStepError = xovisProgressMessages.thirdStepNoDataUplaodInterval;
            } else if (companyAgent?.config?.data?.package_size !== 15) {
                thirdStepError = xovisProgressMessages.thirdStepInvalidDataUploadInterval;
            }
            if (!companyAgent?.config?.data?.resolution) {
                thirdStepError = xovisProgressMessages.thirdStepNoDataUplaodGranularity;
            } else if (companyAgent?.config?.data?.resolution !== xovisDataUploadCorrectValues.dataUploadGranularity) {
                thirdStepError = xovisProgressMessages.thirdStepInvalidDataUploadGranularity;
            }

            /** Проверка 2 шага */
            if (logics?.length) {
                let matchingPoints = 0;
                logics.forEach((logic) => {
                    Object.keys(iPoint.passPoints).forEach((point) => {
                        if (iPoint.passPoints?.[point]?.lineName === logic.name) {
                            matchingPoints++;
                        }
                    });
                });

                if (!(matchingPoints >= Object.keys(iPoint.passPoints).length)) {
                    secondStepError = xovisProgressMessages.secondStepError;
                }
            } else {
                secondStepError = xovisProgressMessages.secondStepNotCountLines;
            }
        }
    } else {
        thirdStepError = xovisProgressMessages.firstStepError;
        secondStepError = xovisProgressMessages.secondStepError;
    }

    return {
        iPointId: iPoint.id,
        value: {
            ...sensor,
            isFetching: sensor.isConfigurationFetching,
            current: true,
            boundleId: currentBoundle.id,
            testDataPushFetching: false,
            updateConfigurationFetching: false,
            secondStepError,
            thirdStepError,
        },
    };
};

export default setBoundleforXovisHelper;
