import styled from 'styled-components/macro';

export const Wrapper = styled.div`
    position: absolute;
    top: ${(props) => (props.show ? '10px' : '-110%')};
    right: 10px;
    background-color: #edebe9;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    z-index: 10;
    padding: 10px;
    max-width: 50vw;
    max-height: calc(90vh - 75px);
    transition: 0.4s all;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

export const Title = styled.div`
    color: rgb(0, 120, 212);
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    overflow: auto;
    margin-top: 10px;
`;

export const SpinnerWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100px;
`;
