import { createSlice } from '@reduxjs/toolkit';
import { deleteRequest, getRequest, patchRequest, postRequest } from '../../api/api';
import { passWaysNames } from '../../constants/passWaysNames';
import { instalationPointsNames } from '../../constants/instalationPointsNames';
import {
    setShowSpinner,
    showErrorNotification,
    showSuccessNotification,
    storeDataForOverwritten,
    storeEqualConflict,
} from '../../generalReducer';
import { setPassWays } from '../passWays/passWaysReducer';
import { setSensors, toggleShouldStoreSensors } from '../countingSensors/countingSensorsReducer';
import dateIntersection from '../../utils/dateHandlers/dateIntersection';
import { cloneDeep, isEqual } from 'lodash';
import createObjectFromArray from '../../utils/createObjectFromArray';
import findBoundleByDate from '../../utils/dateHandlers/findBoundleByDate';
import { sensorTypes } from '../../constants/sensorTypes';
import setBoundleforXovisHelper from './tools/xovis/setBoundleforXovisHelper';
import setBoundleForBrickstreamHelper from './tools/brickstream/setBoundleForBrickstreamHelper';
import setBoundleForVivotekHelper from './tools/vivotek/setBoundleForVivotekHelper';
import setBoundleForHikvisionHelper from './tools/hikvision/setBoundleForHikvisionHelper';
import { requestTypes } from '../../constants/requestTypes';
import { actionsAfterOverwrite } from '../../constants/actionsAfterOverwrite';
import { DateTime } from 'luxon';
import { updateExtendedBoundle } from '../modals/boundleInfoModal/boundleInfoModalReducer';
import { storeDateIntervalError } from '../modals/createSensorAndPointBoundle/createSensorAndPointBoundleReducer';
import setBoundleForRstatHelper from './tools/rstat/setBoundleForRstatHelper';
import setBoundleForDilaxHelper from './tools/dilax/setBoundleForDilaxHelper';
import getBundleForTdHelper from './tools/td/getBundleForTdHelper';
import getBundleForMegacountHelper from './tools/megacount/getBundleForMegacountHelper';

const initialState = {
    locationId: null,
    locationIdFromMapServiceLink: null,
    locationInfo: [],
    selectedLocationName: '',
    selectedLocationTimeZone: '',
    currentFloorInfo: {},
    selectedFloor: null,
    showIPointLabel: false,
    showIPointLayer: true,
    mapServiceLink: '',
    instalationPoints: [],
    instalationPointsById: [],
    instalationPointsOnCurrentFloor: [],
    instalationPointsForCanvas: [],
    selectedInstalationPoint: null,
    sensorsAndPointsBounles: [],
    sensorsAndPointsBounlesBySensorId: {},
    accessKey: '',
    mainLocationsByFloor: null,
    availableBundlesByIPointId: {},
    isBoundlesFetching: false,
    isBatchDataProcessing: false,
    iPointForBoundleInfoModal: null,
    followIPoint: false,
    plStructureFetching: false,
    plStructure: null,
    sensor2PassWayById: null,
    sensor2IPointByIPointMarker: null,
    plansInitialData: [],
    plansByFloorNumber: {},
    selectedInstallationPoints: [],
    selectedInstallationPointsById: {},
    extendedSelectedIPointsById: {},
    batchUrlUploadModalStatus: { show: false },
    sensorRelationsById: {},
};

const instalationPointsReducer = createSlice({
    name: 'instalationPointsReducer',
    initialState,
    reducers: {
        // Запсиь начальной информации о выбранно локации
        storeInitialLocationProps: (state, action) => {
            const { access_key, id, timezone, name, map_service_link } = action.payload;
            state.accessKey = access_key;
            state.locationId = id;
            state.selectedLocationTimeZone = timezone;
            state.selectedLocationName = name;
            state.mapServiceLink = map_service_link;
        },
        // запсиь в стейт информации о конкретной локации
        setLocationInfo: (state, action) => {
            const sortedFloors = action.payload.slice().sort((a, b) => Number(a.floor) - Number(b.floor));
            if (sortedFloors.length > 0) {
                const currentFloorInfo = {
                    id: sortedFloors[0].id,
                    image: sortedFloors[0].image,
                    scale: sortedFloors[0].scale,
                    plan2geo: sortedFloors[0].plan2geo,
                };
                state.selectedFloor = sortedFloors[0].floor;
                state.currentFloorInfo = currentFloorInfo;
            }
            state.locationInfo = action.payload;
        },

        /** Сохранение выбранных точек установок */
        storeSelectedInstallationPoints: (state, action) => {
            const byId = action.payload.reduce((acc, value) => {
                acc[value.id] = value;
                return acc;
            }, {});

            state.selectedInstallationPoints = action.payload;
            state.selectedInstallationPointsById = byId;
        },

        // Обработка данных из структуры проекйтной локации
        processPlStructure: (state, action) => {
            const { fpc } = action.payload;

            const sensor2PassWayById = fpc?.relations_sensor2passway?.reduce((acc, value) => {
                if (acc[value.sensor_id]) {
                    acc[value.sensor_id].push(value);
                } else {
                    acc[value.sensor_id] = [value];
                }

                return acc;
            }, {});

            const sensor2IPointByIPointMarker = fpc?.relations_sensor2pcipoint?.reduce((acc, value) => {
                if (acc[value.pc_ipoint_marker]) {
                    const currentBoundle = findBoundleByDate({
                        boundlesByIPointId: [acc[value.pc_ipoint_marker], value],
                    });
                    acc[value.pc_ipoint_marker] = currentBoundle;
                } else {
                    acc[value.pc_ipoint_marker] = value;
                }

                return acc;
            }, {});
            state.sensor2IPointByIPointMarker = sensor2IPointByIPointMarker;
            state.sensor2PassWayById = sensor2PassWayById;
        },

        // Изменение флага загрузки структуры проектной локации
        togglePlStructureFetching: (state, action) => {
            state.plStructureFetching = action.payload;
        },

        // Запись в стейт структуры всей проектной локации
        storePlStructure: (state, action) => {
            state.plStructure = action.payload;
        },

        // Запись в стейт информации о выбранной точке установки для модалки с информациоей о связках
        storeIPointForBoundleInfoModal: (state, action) => {
            state.iPointForBoundleInfoModal = cloneDeep(action.payload);
        },

        // Запись в стейт имени выбранной локации
        storeSelectedLocationName: (state, action) => {
            state.selectedLocationName = action.payload;
        },

        // запись в стейт id выбранной локации
        setLocationId: (state, action) => {
            state.locationId = action.payload;
        },

        // Запись в стейт информации о том, что грузятся связки
        setBoundlesFetching: (state, action) => {
            state.isBoundlesFetching = action.payload;
        },

        // запись в стейт выбранного этажа
        setSelectedFloor: (state, action) => {
            state.selectedFloor = action.payload;
        },

        // показать/скрыть лейбел с информацией
        setShowIPointLabel: (state, action) => {
            state.showIPointLabel = action.payload;
        },

        // запись в стейт информации о выбраном этаже
        setCurrentFloorInfo: (state, action) => {
            state.currentFloorInfo = action.payload;
        },
        // запись в стейт ссылку на локацию из мап сервиса
        setMapServiceLink: (state, action) => {
            state.locationIdFromMapServiceLink = action.payload.split('location_id=')[1];
            state.mapServiceLink = action.payload;
        },
        // Запись всех точек установки в стейт
        setInstalationPoints: (state, action) => {
            const objectByIPointId = action.payload.reduce((acc, value) => {
                acc[value.id] = value;
                return acc;
            }, {});
            state.instalationPoints = action.payload;
            state.instalationPointsById = objectByIPointId;
        },
        // Запись точек устанвоки для конкретного этажа
        setInstalationPointsOnCurrentFloor: (state, action) => {
            state.instalationPointsOnCurrentFloor = action.payload;
            state.instalationPointsForCanvas = action.payload;
        },

        // Устанвока флага, который сообщает, произошел пост пакетов или нет
        setBatchDataProcessing: (state, action) => {
            state.isBatchDataProcessing = action.payload;
        },

        // Запись в стейт информации о выбраном сенсоре
        setSelectedInstalationPoint: (state, action) => {
            state.selectedInstalationPoint = action.payload;
        },

        // Запись в стейт массива сенсоров дял канваса
        setInstalationPointsForCanvas: (state, action) => {
            state.instalationPointsForCanvas = action.payload;
        },

        // Запись в стейт информации о связке сенсор-точка установки
        setSensorsAndPointsBoundles: (state, action) => {
            const objectBySensorId = createObjectFromArray({
                key: 'sensor_id',
                array: action.payload,
            });
            state.sensorsAndPointsBounles = action.payload;
            state.sensorsAndPointsBounlesBySensorId = objectBySensorId;
        },

        // Добавление новой связки в стейт
        addNewSensorAndPointBoundle: (state, action) => {
            const boundlesCopy = cloneDeep(state.sensorsAndPointsBounles);
            boundlesCopy.push(action.payload);

            const objectBySensorId = createObjectFromArray({
                key: 'sensor_id',
                array: boundlesCopy,
            });

            state.sensorsAndPointsBounles = boundlesCopy;
            state.sensorsAndPointsBounlesBySensorId = objectBySensorId;
        },

        // запись в стейт актуальных связок
        storeAvailableBoundlesByIPointId: (state, action) => {
            state.availableBundlesByIPointId = action.payload;
        },

        // Запись в стейт информации о валидных связках. Объект в с ключом по id точки устанвоки
        updateAvailableBoundleByIPointId: (state, action) => {
            state.availableBundlesByIPointId[action.payload.iPointId] = action.payload.value;
        },

        // Обнуление валидных связок
        resetAvailableBoundles: (state) => {
            state.availableBundlesByIPointId = initialState.availableBundlesByIPointId;
        },

        // Показать/скрыть точки установки
        setShowIPointLayer: (state, action) => {
            state.showIPointLayer = action.payload;
        },

        // Запись в стейт ключа доступа
        setAccessKey: (state, action) => {
            state.accessKey = action.payload;
        },

        // Запись в стейт информации о всех главных локациях
        setMainLocationsByFloor: (state, action) => {
            state.mainLocationsByFloor = action.payload;
        },

        //Запись в стейт тайм зоны выбранной локации
        storeSelectedLocationTimeZone: (state, action) => {
            state.selectedLocationTimeZone = action.payload;
        },

        // Изменение флага, отвечающего за отслеживания точки установки
        toggleFollowIPoint: (state, action) => {
            state.followIPoint = action.payload;
        },

        // Запись планов, полученных с бэка
        storePlansInitialData: (state, action) => {
            state.plansInitialData = action.payload;
        },

        // Запись информации о планах в виде объекта, где ключ это номер этажа, а значение это массив планов
        storePlansByFloorNumber: (state, action) => {
            state.plansByFloorNumber = action.payload.reduce((acc, value) => {
                if (acc[value.floor]) {
                    acc[value.floor].push(value);
                } else {
                    acc[value.floor] = [value];
                }
                return acc;
            }, {});
        },

        /** Сохранение расширенной структуры для выбранных точек установки */
        storeExtendedSelectedIPointsById: (state, action) => {
            state.extendedSelectedIPointsById = action.payload;
        },

        /** Обновление расширенной структуры */
        updateExtendedSelectedIPoint: (state, action) => {
            const { iPointId, data } = action.payload;

            const prevValue = cloneDeep(state.extendedSelectedIPointsById[iPointId]);

            state.extendedSelectedIPointsById[iPointId] = {
                ...prevValue,
                ...data,
            };
        },

        /** Изменение статуса модального окна с процессом обновления выгрузок на датчиках */
        toggleBatchUrlUpdateModalStatus: (state, action) => {
            state.batchUrlUploadModalStatus = action.payload;
        },

        // Запись связей датчика со всеми сущностями
        storeSensorRelationsById: (state, action) => {
            state.sensorRelationsById = action.payload;
        },

        // Обнуление стейт
        resetInstalationPointsReducer: () => initialState,
    },
});

export const {
    setInstalationPointsForCanvas,
    setSelectedInstalationPoint,
    setInstalationPointsOnCurrentFloor,
    setInstalationPoints,
    setLocationInfo,
    setLocationId,
    setSelectedFloor,
    setShowIPointLabel,
    setCurrentFloorInfo,
    setMapServiceLink,
    resetInstalationPointsReducer,
    setSensorsAndPointsBoundles,
    setShowIPointLayer,
    setAccessKey,
    setMainLocation,
    addNewSensorAndPointBoundle,
    updateAvailableBoundleByIPointId,
    setBoundlesFetching,
    resetAvailableBoundles,
    setBatchDataProcessing,
    setMainLocationsByFloor,
    storeSelectedLocationTimeZone,
    storeIPointForBoundleInfoModal,
    toggleFollowIPoint,
    storeSelectedLocationName,
    togglePlStructureFetching,
    storePlStructure,
    processPlStructure,
    storeAvailableBoundlesByIPointId,
    storeInitialLocationProps,
    storePlansInitialData,
    storePlansByFloorNumber,
    storeSelectedInstallationPoints,
    storeExtendedSelectedIPointsById,
    toggleBatchUrlUpdateModalStatus,
    updateExtendedSelectedIPoint,
    storeSensorRelationsById,
} = instalationPointsReducer.actions;

/**
 * Thunk. Асинхронное получение информации для выбранной локации
 * @param {number} mapServiceLink ссылка для получения информации о локации
 * @param {string} token токен для запросов (указывается в хедерах)
 * @param {object} nav для редиректа
 */
export const getLocationInfoThunk = (options) => async (dispatch) => {
    const { mapServiceLink, token, nav } = options;
    dispatch(setShowSpinner(true));
    dispatch(setLocationInfo([]));
    const data = await getRequest(mapServiceLink.replace('layers', 'plans'), token);
    if (!data.error) {
        dispatch(storePlansInitialData(data));
        dispatch(storePlansByFloorNumber(data));
        let floors = [];
        const mainLocationsByFloor = {};
        data.forEach((element) => {
            if (element.is_main) mainLocationsByFloor[element.floor] = element;
            const floor = floors.find((floorItem) => floorItem.floor === element.floor);
            const index = floors.findIndex((floorItem) => floorItem.floor === element.floor);
            if (floor) {
                if (Date.parse(element.active_from) > Date.parse(floor.active_from)) {
                    floors.splice(index, 1);
                    floors.push(element);
                }
            } else {
                floors.push(element);
            }
        });
        dispatch(setMainLocationsByFloor(mainLocationsByFloor));
        dispatch(setLocationInfo(floors));
    } else {
        dispatch(setShowSpinner(false));
        dispatch(showErrorNotification({ show: true, message: 'Server error' }));
        nav('/');
    }
};

/**
 *
 * @param {number} mapServiceLink ссылка для получения информации о локации
 * @param {string} token токен для запросов (указывается в хедерах)
 * @param {string} url объект со всеми урлами
 * @param {object} history для редиректа
 */
export const getInstalationPointsThunk = (options) => async (dispatch) => {
    const { mapServiceLink, token, url, history } = options;
    dispatch(setSensors([]));
    dispatch(setShowSpinner(true));
    const data = await getRequest(url, token);
    if (!data.error) {
        const instalationPointsByMarker = data.reduce((acc, value) => {
            acc[value.marker] = value;
            return acc;
        }, {});
        dispatch(
            getLocationLayersThunk({
                mapServiceLink,
                token,
                instalationPointsByMarker,
                history,
            }),
        );
    } else {
        dispatch(setShowSpinner(false));
        if (data.error.response.data?.detail) {
            dispatch(
                showErrorNotification({
                    show: true,
                    message: `GET I-Points error : ${data.error.response.data.detail}`,
                }),
            );
        } else {
            dispatch(
                showErrorNotification({
                    show: true,
                    message: 'GET I-Points error',
                }),
            );
        }
        history.push('/');
    }
};

/**
 * Thunk. Асинхронное получение слоев для выбранной локации
 * @param {number} mapServiceLink ссылка для получения информации о локации
 * @param {string} token токен для запросов (указывается в хедерах)
 * @param {object} instalationPointsByMarker объект точек установкки, где ключ - это маркер
 * @param {object} history для редиректа
 */
export const getLocationLayersThunk = (options) => async (dispatch) => {
    const { mapServiceLink, token, instalationPointsByMarker, history } = options;
    dispatch(setShowSpinner(true));
    const data = await getRequest(mapServiceLink, token);

    if (!data.error) {
        dispatch(setPassWays(data.filter((element) => element.layer_type === passWaysNames.PASS_WAYS_TYPE)));
        const instalationPoints = [];
        data.forEach((element) => {
            if (element.layer_type === instalationPointsNames.INSTALATION_POINT_TYPE) {
                if (element.data) {
                    element.data.forEach((point) => {
                        if (instalationPointsByMarker[point.marker]) {
                            instalationPoints.push({
                                ...instalationPointsByMarker[point.marker],
                                centerPoint: point.centerPoint,
                                passPoints: point.passPoints,
                                followedBy: point.followedBy,
                                floor: element.floor,
                            });
                        }
                    });
                }
            }
        });
        dispatch(setInstalationPoints(instalationPoints));
        dispatch(setShowSpinner(false));
    } else {
        dispatch(setShowSpinner(false));
        dispatch(
            showErrorNotification({
                show: true,
                message: 'GET location layers error',
            }),
        );
        history.push('/');
    }
};

/**
 * Thunk. Получение связок сенсор-точка устанвоки от бэки и запись их в стейт
 */
export const getSensorsAndPointsBoundlesThunk = () => async (dispatch, getState) => {
    const { storeUrls, token } = getState().generalReducer;
    const { locationId } = getState().instalationPointsReducer;
    dispatch(setShowSpinner(true));
    dispatch(setBoundlesFetching(true));
    const data = await getRequest(`${storeUrls.SENSORS_TO_IPOINTS.url}?project_location_id=${locationId}`, token);
    dispatch(setBoundlesFetching(false));
    dispatch(setShowSpinner(false));
    dispatch(toggleShouldStoreSensors(true));
    if (!data.error) {
        dispatch(setSensorsAndPointsBoundles(data));
    } else {
        dispatch(
            showErrorNotification({
                show: true,
                message: 'GET sensors and IPoints boundles error',
            }),
        );
    }
};

/**
 * Thunk. Создает новую связку сенсора и точки установки
 */
export const createNewSensorAndPointBoundle = (options) => async (dispatch, getState) => {
    const { storeUrls, token } = getState().generalReducer;
    const { selectedLocationTimeZone } = getState().instalationPointsReducer;
    const { boundlesForSelectedIPoint } = getState().boundleInfoModalReducer;
    const { sensorId, iPointId, date, time } = options;
    const formatedDate = DateTime.fromISO(date).toFormat('yyyy-MM-dd');

    const date_from = DateTime.fromISO(`${formatedDate}T${time}`, {
        zone: selectedLocationTimeZone,
    })
        .set({ milliseconds: 0 })
        .toISO({ suppressMilliseconds: true });

    const isDateIntesection = dateIntersection({
        sensorsAndPointsBounles: boundlesForSelectedIPoint,
        dateFrom: date_from,
        dateTo: null,
    });

    if (isDateIntesection) {
        dispatch(storeDateIntervalError(true));
        setTimeout(() => dispatch(storeDateIntervalError(false)), 2500);
    } else {
        const postPayload = {
            sensor_id: sensorId,
            installation_point_id: iPointId,
            date_from,
            date_to: null,
        };
        dispatch(setShowSpinner(true));
        const data = await postRequest(storeUrls.SENSORS_TO_IPOINTS.url, token, postPayload);
        dispatch(setShowSpinner(false));
        if (!data.error) {
            dispatch(getSensorsAndPointsBoundlesThunk(storeUrls.SENSORS_TO_IPOINTS.url, token));
            dispatch(
                showSuccessNotification({
                    show: true,
                    message: 'The bundle has been successfully created',
                }),
            );
        } else {
            const errorsArray = Object.keys(data.error.response.data);
            if (errorsArray.length > 0) {
                dispatch(
                    showErrorNotification({
                        show: true,
                        message: data.error.response.data[errorsArray[0]][0],
                    }),
                );
            } else {
                dispatch(
                    showErrorNotification({
                        show: true,
                        message: 'POST error',
                    }),
                );
            }
        }
    }
};

/**
 * Thunk. Функция для обновления связки точки и сенсора. Проверяет на валидность переданный временной интервал,
 * если он подходит, то обновляет выбранную связку
 * @param {object} body тело запроса
 * @param {number} boundleId id связки
 * @param {number} iPointId id точки установки
 * @param {object} currentBoundle объект выбранной точки установки
 */
export const updateSensorAndPointBoundle = (options) => async (dispatch, getState) => {
    const { storeUrls, token } = getState().generalReducer;
    const { selectedLocationTimeZone } = getState().instalationPointsReducer;
    const { boundlesForSelectedIPoint } = getState().boundleInfoModalReducer;
    const { body, boundleId, currentBoundle } = options;

    dispatch(
        updateExtendedBoundle({
            boundleId,
            value: true,
            key: 'updateBoundleFetching',
        }),
    );

    const date_from = DateTime.fromISO(`${body.date_from}T${body.time_from}`, { zone: selectedLocationTimeZone })
        .set({ milliseconds: 0 })
        .toISO({ suppressMilliseconds: true });
    const date_to = body.date_to
        ? DateTime.fromISO(`${body.date_to}T${body.time_to}`, {
              zone: selectedLocationTimeZone,
          })
              .set({ milliseconds: 0 })
              .toISO({ suppressMilliseconds: true })
        : null;

    const bodyForPathRequest = {
        date_from,
        date_to,
    };

    const isDateIntesection = dateIntersection({
        sensorsAndPointsBounles: boundlesForSelectedIPoint.filter((element) => element.id !== boundleId),
        dateFrom: date_from,
        dateTo: date_to,
    });

    const boundleFromBack = await getRequest(`${storeUrls.SENSORS_TO_IPOINTS.url}${boundleId}/`, token);

    if (!boundleFromBack.error) {
        const objectsEqual = isEqual(boundleFromBack, currentBoundle);

        if (objectsEqual) {
            if (isDateIntesection) {
                dispatch(
                    updateExtendedBoundle({
                        boundleId,
                        value: false,
                        key: 'updateBoundleFetching',
                    }),
                );
                dispatch(
                    updateExtendedBoundle({
                        boundleId,
                        value: 'Invalid date interval',
                        key: 'updateBoundleError',
                    }),
                );

                setTimeout(() => {
                    dispatch(
                        updateExtendedBoundle({
                            boundleId,
                            value: '',
                            key: 'updateBoundleError',
                        }),
                    );
                }, 2500);
            } else {
                const data = await patchRequest(
                    `${storeUrls.SENSORS_TO_IPOINTS.url}${boundleId}/`,
                    token,
                    bodyForPathRequest,
                );
                dispatch(
                    updateExtendedBoundle({
                        boundleId,
                        value: false,
                        key: 'updateBoundleFetching',
                    }),
                );

                if (!data.error) {
                    dispatch(getSensorsAndPointsBoundlesThunk(storeUrls.SENSORS_TO_IPOINTS.url, token));
                } else {
                    dispatch(
                        updateExtendedBoundle({
                            boundleId,
                            value: 'Update error',
                            key: 'updateBoundleError',
                        }),
                    );

                    setTimeout(() => {
                        dispatch(
                            updateExtendedBoundle({
                                boundleId,
                                value: '',
                                key: 'updateBoundleError',
                            }),
                        );
                    }, 2500);
                }
            }
        } else {
            dispatch(storeEqualConflict(true));
            dispatch(
                storeDataForOverwritten({
                    url: `${storeUrls.SENSORS_TO_IPOINTS.url}${boundleId}/`,
                    body: bodyForPathRequest,
                    requestType: requestTypes.PATCH,
                    warningMessage: 'You are trying to update a bundle, but the data on this client is not up to date',
                    actionAfterOverwrite: actionsAfterOverwrite.UPDATE_BUNDLES,
                    additionalData: {},
                }),
            );
        }
    } else {
        dispatch(
            updateExtendedBoundle({
                boundleId,
                value: false,
                key: 'updateBoundleFetching',
            }),
        );
        dispatch(
            showErrorNotification({
                show: true,
                message: 'Get boundle error',
            }),
        );
    }
};

/**
 * Thunk. Служит для получения всех активынх связок, формирует объект из этих связок, где
 * ключом является id точки установки
 * @param {array} instalationPoints массив всех точек установки
 * @param {array} sensorsAndPointsBounles массив всех связок
 * @param {object} sensorsById объект всех сенсоров, где ключ это id сенсора
 * @param {string} accessKey ключ для выгрузки данных
 * @param {boolean} isSensorConfigurationUpdated флаг, следящий за обновлением конфигурации сенсора
 */
export const updateAvailableBoundleByIPointIdThunk = (options) => async (dispatch, getState) => {
    const { instalationPoints, sensorsAndPointsBounles, sensorsById, accessKey, isSensorConfigurationUpdated } =
        options;
    const { sensorUploadUrlsBySensorType } = getState().countingSensorsReducer;

    const boundlesForUpdate = {};

    instalationPoints.forEach((iPoint) => {
        const boundlesForCurrentIpointId = sensorsAndPointsBounles.filter(
            (boundle) => boundle.installation_point_id === iPoint.id,
        );
        const currentBoundle = findBoundleByDate({
            boundlesByIPointId: boundlesForCurrentIpointId,
        });
        if (currentBoundle) {
            const sensor = sensorsById[currentBoundle.sensor_id];
            if (sensor || (isSensorConfigurationUpdated && sensor)) {
                switch (sensor.sensor_type) {
                    case sensorTypes.XOVIS: {
                        const data = setBoundleforXovisHelper({
                            iPoint,
                            sensor,
                            currentBoundle,
                            accessKey,
                            uploadUrl: sensorUploadUrlsBySensorType[sensor.sensor_type]?.uploadUrl,
                        });
                        boundlesForUpdate[data.iPointId] = data.value;
                        break;
                    }

                    case sensorTypes.BRICKSTREAM: {
                        const data = setBoundleForBrickstreamHelper({
                            iPoint,
                            sensor,
                            currentBoundle,
                            accessKey,
                            uploadUrl: sensorUploadUrlsBySensorType[sensor.sensor_type]?.uploadUrl,
                        });
                        boundlesForUpdate[data.iPointId] = data.value;
                        break;
                    }

                    case sensorTypes.VIVOTEK: {
                        const data = setBoundleForVivotekHelper({
                            iPoint,
                            sensor,
                            currentBoundle,
                            accessKey,
                            uploadUrl: sensorUploadUrlsBySensorType[sensor.sensor_type]?.uploadUrl,
                        });
                        boundlesForUpdate[data.iPointId] = data.value;
                        break;
                    }

                    case sensorTypes.HIKVISION: {
                        const data = setBoundleForHikvisionHelper({
                            sensor,
                            accessKey,
                            iPoint,
                            currentBoundle,
                            uploadUrl: sensorUploadUrlsBySensorType[sensor.sensor_type]?.uploadUrl,
                        });
                        boundlesForUpdate[data.iPointId] = data.value;
                        break;
                    }

                    case sensorTypes.RSTAT: {
                        const data = setBoundleForRstatHelper({
                            sensor,
                            accessKey,
                            iPoint,
                            currentBoundle,
                            uploadUrl: sensorUploadUrlsBySensorType[sensor.sensor_type]?.uploadUrl,
                        });
                        boundlesForUpdate[data.iPointId] = data.value;
                        break;
                    }

                    case sensorTypes.DILAX: {
                        const data = setBoundleForDilaxHelper({
                            sensor,
                            accessKey,
                            iPoint,
                            currentBoundle,
                            uploadUrl: sensorUploadUrlsBySensorType[sensor.sensor_type]?.uploadUrl,
                        });
                        boundlesForUpdate[data.iPointId] = data.value;
                        break;
                    }

                    case sensorTypes.TD: {
                        const data = getBundleForTdHelper({
                            sensor,
                            accessKey,
                            iPoint,
                            currentBoundle,
                            uploadUrl: sensorUploadUrlsBySensorType[sensor.sensor_type]?.uploadUrl,
                        });
                        boundlesForUpdate[data.iPointId] = data.value;
                        break;
                    }

                    case sensorTypes.MEGACOUNT: {
                        const data = getBundleForMegacountHelper({
                            sensor,
                            accessKey,
                            iPoint,
                            currentBoundle,
                            uploadUrl: sensorUploadUrlsBySensorType[sensor.sensor_type]?.uploadUrl,
                        });
                        boundlesForUpdate[data.iPointId] = data.value;
                        break;
                    }

                    default:
                        break;
                }
            }
        }
    });

    dispatch(storeAvailableBoundlesByIPointId(boundlesForUpdate));
};

/**
 * Thunk. для удаления связки
 * @param {number} boundleId id связки
 */
export const deleteSensorAndPointBoundle = (options) => async (dispatch, getState) => {
    const { storeUrls, token } = getState().generalReducer;
    const { boundleId } = options;

    dispatch(
        updateExtendedBoundle({
            boundleId,
            value: true,
            key: 'removeBoundleFetching',
        }),
    );
    const data = await deleteRequest(`${storeUrls.SENSORS_TO_IPOINTS.url}${boundleId}/`, token);
    dispatch(
        updateExtendedBoundle({
            boundleId,
            value: false,
            key: 'removeBoundleFetching',
        }),
    );

    if (!data.error) {
        dispatch(getSensorsAndPointsBoundlesThunk(storeUrls.SENSORS_TO_IPOINTS.url, token));
    } else {
        dispatch(
            updateExtendedBoundle({
                boundleId,
                value: 'Remove error',
                key: 'removeBoundleError',
            }),
        );
    }
};

/**
 * Thunk для получения структуры проектной локации
 */
export const getPlStructureThunk = () => async (dispatch, getState) => {
    const { storeUrls, token } = getState().generalReducer;
    const { locationId } = getState().instalationPointsReducer;
    dispatch(togglePlStructureFetching(true));
    const payload = {
        auth: {
            xtoken: token,
        },
        queries: [
            {
                key: 'core/elements_admin_data_objects',
                item: `pl_structure/pl${locationId}/core/elements_admin_data_objects.json`,
            },
            {
                key: 'core/elements_geo_objects',
                item: `pl_structure/pl${locationId}/core/elements_geo_objects.json`,
            },
            {
                key: 'core/elements_ms_data_objects',
                item: `pl_structure/pl${locationId}/core/elements_ms_data_objects.json`,
            },
            {
                key: 'core/relations_dataobj2floor',
                item: `pl_structure/pl${locationId}/core/relations_dataobj2floor.json`,
            },
            {
                key: 'core/relations_passway2dataobj',
                item: `pl_structure/pl${locationId}/core/relations_passway2dataobj.json`,
            },
            {
                key: 'core/relations_place2zone',
                item: `pl_structure/pl${locationId}/core/relations_place2zone.json`,
            },
            {
                key: 'core/relations_tenant2place',
                item: `pl_structure/pl${locationId}/core/relations_tenant2place.json`,
            },
            {
                key: 'core/relations_tenant2zone',
                item: `pl_structure/pl${locationId}/core/relations_tenant2zone.json`,
            },
            {
                key: 'core/relations_tenant2floor',
                item: `pl_structure/pl${locationId}/core/relations_tenant2floor.json`,
            },
            {
                key: 'core/relations_tenant2location',
                item: `pl_structure/pl${locationId}/core/relations_tenant2location.json`,
            },
            {
                key: 'fpc/elements_pc_ipoints',
                item: `pl_structure/pl${locationId}/fpc/elements_pc_ipoints.json`,
            },
            {
                key: 'fpc/elements_pc_sensors',
                item: `pl_structure/pl${locationId}/fpc/elements_pc_sensors.json`,
            },
            {
                key: 'fpc/relations_sensor2pcipoint',
                item: `pl_structure/pl${locationId}/fpc/relations_sensor2pcipoint.json`,
            },
            {
                key: 'fpc/relations_pcipoint2passway',
                item: `pl_structure/pl${locationId}/fpc/relations_pcipoint2passway.json`,
            },
            {
                key: 'fpc/relations_sensor2passway',
                item: `pl_structure/pl${locationId}/fpc/relations_sensor2passway.json`,
            },
            {
                key: 'fpc/relations_sensor2dataobj',
                item: `pl_structure/pl${locationId}/fpc/relations_sensor2dataobj.json`,
            },
        ],
    };

    const data = await postRequest(`${storeUrls.GET_PL_CACHED_STRUCTURE.url}`, token, payload).then((data) => {
        return Object.entries(data).reduce((acc, [key, value]) => {
            const splittedKey = key.split('/');
            const branch = splittedKey[0];
            const tableName = splittedKey[1];

            acc[branch] = {
                ...(acc[branch] || {}),
                [tableName]: value,
            };
            return acc;
        }, {});
    });
    dispatch(togglePlStructureFetching(false));

    if (!data.error) {
        dispatch(processPlStructure(data));
        dispatch(storePlStructure(data));
    } else {
        console.log('pl structure error', data.error);
    }
};

export default instalationPointsReducer.reducer;
