/**
 * функция для удаления подстрок из строки
 * @param {string} string строка из которой нужно удалить
 * @param {array} params массив параметров, которые нужно удалить из строки
 * @returns new string
 */
const deleteParamsFromString = (string, params) => {
    let result = '';
    const splittedHref = string.split('?');

    const addressString = splittedHref[0];
    const queryString = splittedHref[1];
    if (!queryString || !params || params.length === 0) return string;

    const filteredParams = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&').filter((item) => {
        let result = true;
        params.forEach((param) => {
            if (item.includes(param)) {
                result = false;
            }
        });
        return result;
    });
    result = `${addressString}?${filteredParams.join('&')}`;
    return result;
};

export default deleteParamsFromString;
