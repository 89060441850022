import { MessageBar, MessageBarType } from '@fluentui/react';
import { FC } from 'react';
import { ISingleTaskProps } from './SingleTask.interfaces';
import { TasksWrapper, WrappedItem, TaskAttributeName, AttributeWrapper, messageBarStyles } from './SingleTask.styles';

/**
 * Компонент для отображения одной задачки (15 минут) и статуса ее выполнения
 * @param task сам таск
 * @param processedTask результат выполнения таска
 */
const SingleTask: FC<ISingleTaskProps> = ({ task, processedTask }) => {
    return (
        <TasksWrapper>
            <WrappedItem isProcessed={false}>
                <AttributeWrapper>
                    <TaskAttributeName>Date from:</TaskAttributeName>
                    {task.date_from}
                </AttributeWrapper>
                <AttributeWrapper>
                    <TaskAttributeName>Date to:</TaskAttributeName>
                    {task.date_to}
                </AttributeWrapper>
            </WrappedItem>
            <WrappedItem isProcessed={true}>
                {processedTask && (
                    <>
                        <AttributeWrapper>
                            <TaskAttributeName>Date from:</TaskAttributeName>
                            {processedTask.date_from}
                        </AttributeWrapper>
                        <AttributeWrapper>
                            <TaskAttributeName>Date to:</TaskAttributeName>
                            {processedTask.date_to}
                        </AttributeWrapper>
                        <AttributeWrapper>
                            <TaskAttributeName>Status:</TaskAttributeName>
                            <MessageBar
                                styles={messageBarStyles}
                                messageBarType={
                                    processedTask.status === 'success' ? MessageBarType.success : MessageBarType.error
                                }
                            >
                                {processedTask.status}
                            </MessageBar>
                        </AttributeWrapper>
                        {processedTask.status === 'failure' && (
                            <AttributeWrapper>
                                <TaskAttributeName>Errors:</TaskAttributeName>
                                <div>
                                    {processedTask.error_log.map((error, index) => (
                                        <MessageBar
                                            key={index + Math.random()}
                                            styles={messageBarStyles}
                                            isMultiline={true}
                                            messageBarType={MessageBarType.error}
                                        >
                                            {error}
                                        </MessageBar>
                                    ))}
                                </div>
                            </AttributeWrapper>
                        )}
                    </>
                )}
            </WrappedItem>
        </TasksWrapper>
    );
};

export default SingleTask;
