import { useContext, useState } from 'react';
import {
    CreateSensorButton,
    CreateSensorTitle,
    IpPortWrapper,
    StyledTextField,
    CreateSensorButtonWrapper,
} from './createSensorModal.styles';
import MaskedIpInput from './components/maskedIpInput';
import { Dropdown, TextField } from '@fluentui/react';
import { sensorTypes } from '../../../constants/sensorTypes';
import dropDownOptionsGenerator from '../../../utils/createSensor/dropDownOptionsGenerator';
import ModalCreator, { ModalContext } from '../modalCreator';
import SerialNumberWarningModal from '../serialNumberWarningModal/serialNumberWarningModal';
import { useTranslation } from 'react-i18next';

/**
 * Компонент для отрисовки модалки для создания сенсора
 */
const CreateSensorModal = () => {
    const { createNewSensorThunkTrans, isOpen, toggleOpenTrans } = useContext(ModalContext);
    const [ip, setIp] = useState('');
    const [selectedType, setSelectedType] = useState(null);
    const [password, setPassword] = useState('');
    const [port, setPort] = useState('80');
    const [login, setLogin] = useState('');
    const [portError, setPortError] = useState(false);
    const [ipError, setIpError] = useState(false);
    const [selectError, setSelectError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [serialNumber, setSerialNumber] = useState('');
    const [serialNumberError, setSerialNumberError] = useState(false);
    const { t } = useTranslation();

    const inputHandler = (e) => {
        setIpError(false);
        setIp(e.target.value);
    };

    const selectHandler = (_, item) => {
        setSelectError(false);
        setSelectedType(item);
    };

    const ipRegExp =
        /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    // eslint-disable-next-line
    const serialNumberRegExp = /^[0-9A-f]{1,2}([\.:-])(?:[0-9A-f]{1,2}\1){4}[0-9A-f]{1,2}$/;
    const numberRegExp = /^\d*$/;

    const createSensorHandler = () => {
        if (
            ipRegExp.test(ip) &&
            selectedType &&
            password &&
            port &&
            (serialNumberRegExp.test(serialNumber) || !serialNumber)
        ) {
            createNewSensorThunkTrans({
                ip,
                port,
                type: selectedType.text,
                password,
                login,
                serialNumber: serialNumber.toLowerCase(),
            });
        } else {
            if (!ipRegExp.test(ip)) setIpError(true);
            if (!selectedType) setSelectError(true);
            if (!password) setPasswordError(true);
            if (!port) setPortError(true);
            if (!serialNumberRegExp.test(serialNumber)) setSerialNumberError(true);
        }
    };

    const loginHandler = (_, newValue) => setLogin(newValue);

    const serialNumberHandler = (_, newValue) => {
        setSerialNumberError(false);
        setSerialNumber(newValue);
    };

    const passwordHandler = (_, newValue) => {
        setPasswordError(false);
        setPassword(newValue);
    };

    const portHandler = (_, newValue) => {
        setPortError(false);
        if (numberRegExp.test(newValue)) setPort(String(newValue));
    };

    return (
        <>
            <ModalCreator isModalOpen={isOpen} closeModal={toggleOpenTrans(false)}>
                <SerialNumberWarningModal />
            </ModalCreator>
            <CreateSensorTitle>{t('Add sensor')}</CreateSensorTitle>
            <IpPortWrapper>
                <MaskedIpInput inputError={ipError} inputValue={ip} setInputValue={inputHandler} />
                <StyledTextField
                    width={100}
                    errorMessage={portError ? 'Required' : null}
                    value={port}
                    onChange={portHandler}
                    label='Port'
                    title='Port'
                />
            </IpPortWrapper>

            <TextField
                value={serialNumber}
                placeholder={'__:__:__:__:__:__'}
                errorMessage={serialNumberError ? 'Wrong serial number' : null}
                onChange={serialNumberHandler}
                label={'Serial number (optional)'}
            />
            <Dropdown
                selectedKey={selectedType ? selectedType.key : undefined}
                errorMessage={selectError ? 'Select type' : null}
                onChange={selectHandler}
                label='Sensor type'
                options={dropDownOptionsGenerator(sensorTypes)}
            />

            <TextField value={login} onChange={loginHandler} label='Login (optional)' title='Login' />
            <TextField
                value={password}
                errorMessage={passwordError ? 'This field is required' : null}
                onChange={passwordHandler}
                label='Password'
                type='password'
                title='Password'
            />

            <CreateSensorButtonWrapper>
                <CreateSensorButton onClick={createSensorHandler}>Add</CreateSensorButton>
            </CreateSensorButtonWrapper>
        </>
    );
};

export default CreateSensorModal;
