import { Link } from '@fluentui/react';
import { FC, useMemo } from 'react';
import StackHorizontal from '../../../StackHorizontal/StackHorizontal';
import SingleTask from './componnents/SingleTask';
import { ICurrentSensorTasksProps } from './CurrentSensorsTasks.interfaces';
import {
    SensorAttributeName,
    WrappedItem,
    TasksTitleWrapper,
    TitleText,
    TasksContainer,
} from './CurrentSensorsTasks.styles';

/**
 * Компонент для отображения информации по конкретному сенсору
 * @param sensorData информация и сенсоре
 */
const CurrentSensorTasks: FC<ICurrentSensorTasksProps> = ({ sensorData }) => {
    const tasks = useMemo(() => {
        if (!sensorData) return [];
        return sensorData.tasks.map((task, index) => {
            const processedTask = sensorData.processedTasks.find(
                (processedTask) => processedTask.date_from === task.date_from && processedTask.date_to === task.date_to,
            );

            return <SingleTask key={index + Math.random()} task={task} processedTask={processedTask} />;
        });
    }, [sensorData]);

    if (!sensorData) return null;

    return (
        <>
            <StackHorizontal>
                <div>
                    <SensorAttributeName>Ip:</SensorAttributeName>{' '}
                    <Link href={`http://${sensorData.ip}:${sensorData.port}`}>{sensorData.ip}</Link>
                </div>
                <div>
                    <SensorAttributeName>Port:</SensorAttributeName> {sensorData.port}
                </div>
                <div>
                    <SensorAttributeName>Type:</SensorAttributeName> {sensorData.type}
                </div>
                <div>
                    <SensorAttributeName>Username:</SensorAttributeName> {sensorData.user}
                </div>
                <div>
                    <SensorAttributeName>Password:</SensorAttributeName> {sensorData.pass}
                </div>
            </StackHorizontal>
            <TasksTitleWrapper>
                <WrappedItem isProcessed={false}>
                    <TitleText>Task</TitleText>
                </WrappedItem>
                <WrappedItem isProcessed={true}>
                    <TitleText>Processed task</TitleText>
                </WrappedItem>
            </TasksTitleWrapper>
            <TasksContainer>{tasks}</TasksContainer>
        </>
    );
};

export default CurrentSensorTasks;
