import { createSlice } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';
import { showErrorNotification } from '../../generalReducer';
import portionDataGet from '../../utils/portionDataGet';
import refreshSelectedSensors from './tools/refreshSelectedSensors';

const initialState = {
    screensDateFrom: DateTime.now().minus({ days: 2 }).toFormat('yyyy-MM-dd'),
    screensDateTo: DateTime.now().toFormat('yyyy-MM-dd'),
    selectedSensors: [],
    extendedSensorsById: {},
    extendedSensors: [],
    showScreensByDate: false,
    isScreensFetched: false,
    columns: [],
    screenSize: { key: 500, text: 'Large' },
    columnsToHide: [],
    columnToSort: null,
    filteredItemsForScreensList: [],
    sensorRelationsById: {},
};

const screenshotsPageReducer = createSlice({
    name: 'screenshotsPageReducer',
    initialState,
    reducers: {
        // Запись выбрранных сенсоров в сетйт
        storeSelectedSensors: (state, action) => {
            state.selectedSensors = action.payload;
        },

        // Обновление выбранных сенсоров
        updateSelectedSensors: (state, action) => {
            state.selectedSensors = action.payload;
        },

        // Запись в стейт колонок, которые необходимо скрывать
        storeColumnsToHide: (state, action) => {
            state.columnsToHide = action.payload;
        },

        // Запись в стейт колонки, которую нужно отсортировать
        storeColumnToSort: (state, action) => {
            state.columnToSort = action.payload;
        },

        // Запись в стейт отфильтрованных значений для списка со скриншотами
        storeFilteredItemsForScreensList: (state, action) => {
            state.filteredItemsForScreensList = action.payload;
        },

        // Запись в стейт date_from для скринов
        storeScreensDateFrom: (state, action) => {
            state.screensDateFrom = action.payload;
        },

        // Запись в стейт date_to для скринов
        storeScreensDateTo: (state, action) => {
            state.screensDateTo = action.payload;
        },

        // Записьв стейт расширенную версию сенсоров
        storeExtendedSensors: (state, action) => {
            const extendedSensors = Object.keys(action.payload).map((element) => {
                const sensor = {
                    ...action.payload[element],
                    screenInfoFetching: false,
                    screensInfo: [],
                    screensFetchError: '',
                    screenInfoByDateFrom: '',
                    screenInfoByDateTo: '',
                };
                return sensor;
            });
            const extendedSensorsById = extendedSensors.reduce((acc, value) => {
                acc[value.id] = value;
                return acc;
            }, {});

            state.extendedSensors = extendedSensors;
            state.extendedSensorsById = extendedSensorsById;
        },

        // Обновление расширенных сенсоров
        updateExtendedSensors: (state, action) => {
            const extendedSensors = Object.keys(action.payload).map((element) => cloneDeep(action.payload[element]));
            state.extendedSensors = extendedSensors;
            state.extendedSensorsById = action.payload;
        },

        // Изменение флага для отобрадения списака с date_from и date_to
        toggleShowScreensByDate: (state, action) => {
            state.showScreensByDate = action.payload;
        },

        // Запись колонок для списка
        storeColumns: (state, action) => {
            state.columns = action.payload;
        },

        // Изменение флага, отвеяающего за получение скринов
        toggleScreensFetched: (state, action) => {
            state.isScreensFetched = action.payload;
        },

        // Запись нового размера скрина
        storeScreenSize: (state, action) => {
            state.screenSize = action.payload;
        },

        // Запись связей датчика со всеми сущностями
        storeSensorRelationsById: (state, action) => {
            state.sensorRelationsById = action.payload;
        },

        // Обнуление редьюсера
        resetScreenshotsReducer: () => initialState,
    },
});

export const {
    resetScreenshotsReducer,
    storeSelectedSensors,
    storeScreensDateFrom,
    storeScreensDateTo,
    storeExtendedSensors,
    updateExtendedSensors,
    toggleShowScreensByDate,
    storeColumns,
    toggleScreensFetched,
    storeScreenSize,
    storeColumnsToHide,
    storeColumnToSort,
    updateSelectedSensors,
    storeFilteredItemsForScreensList,

    storeSensorRelationsById,
} = screenshotsPageReducer.actions;

/**
 * Thunk.Для получения скринов выбранных сенсоров за переданный период
 */
export const getScreensInfoThunk = () => async (dispatch, getState) => {
    const { screensDateFrom, screensDateTo, selectedSensors, extendedSensorsById } = getState().screenshotsPageReducer;
    const URL_FOR_GET = 'https://functions.yandexcloud.net/d4e9fbbs6uacir2i83ki';
    const PORTION_SIZE = 10;
    dispatch(toggleScreensFetched(false));
    dispatch(toggleShowScreensByDate(false));
    if (DateTime.fromISO(screensDateFrom) < DateTime.fromISO(screensDateTo)) {
        const { copy, selectedCopy } = refreshSelectedSensors(extendedSensorsById, selectedSensors);
        dispatch(updateExtendedSensors(copy));

        const extendedSensorsByIdCopy = cloneDeep(copy);

        const dataForGet = [];
        selectedCopy.forEach((element) => {
            const sensor = extendedSensorsByIdCopy[element.id];
            const data = {
                url: `${URL_FOR_GET}?serial_number=${sensor.serial_number}&sensor_type=${sensor.sensor_type}&date_from=${screensDateFrom}&date_to=${screensDateTo}`,
                initialData: sensor,
            };
            dataForGet.push(data);
        });

        const result = await portionDataGet({
            portionSize: PORTION_SIZE,
            dataArray: dataForGet,
            timeout: 15000,
        });

        for (let element of result) {
            const sensor = cloneDeep(element.initialData);
            sensor.screenInfoFetching = false;
            if (!element.error) {
                if (element.response.data.length === 0) {
                    extendedSensorsByIdCopy[sensor.id] = {
                        ...sensor,
                        screensFetchError: 'No data about this sensor',
                    };
                } else {
                    extendedSensorsByIdCopy[sensor.id] = {
                        ...sensor,
                        screensInfo: element.response.data,
                    };
                }
                //test
            } else {
                extendedSensorsByIdCopy[sensor.id] = {
                    ...sensor,
                    screensFetchError: element.error,
                };
            }
        }
        dispatch(toggleScreensFetched(true));
        dispatch(updateExtendedSensors(extendedSensorsByIdCopy));
    } else {
        dispatch(
            showErrorNotification({
                show: true,
                message: 'Invalid date interval',
            }),
        );
    }
};

export default screenshotsPageReducer.reducer;
