import { DateTime } from 'luxon';
import { sensorTypes } from '../../../constants/sensorTypes';
import { cloneDeep, isNumber } from 'lodash';
import portionDataGet from '../../../utils/portionDataGet';
import checkErrorInXovisResponse from '../../../utils/xovisHelpers/checkErrorInXovisResponse';
import portionDataPost from '../../../utils/portionDataPost';
import generateVivotekDataForUploadRequest from './vivotek/generateVivotekDataForUploadRequest';
import hikvisionUploadDataHandler from './hikvision/hikvisionUploadDataHandler';
import generateTdDataForUploadRequest from './td/generateTdDataForUploadRequest';
import generateXovisDataForUploadRequestV5 from './xovis/v5Methods/generateXovisDataForUploadRequestV5';
import { generateMegacountUploadPayload } from './megacount/uploadMegacountData';
import { getCompanyName } from '../../../constants/constants';

/**
 * Функция для обработки дефолтного промежутка времени
 * @param {array} selectedSensorsCopy выбранные датчики
 * @param {object} sensorsForManagementById все сенсоры
 * @param {number} dateToInMillis date_to
 * @param {number} dateFromInMillis date_from
 * @param {boolean} isCSVDataIncludesDateFields флаг, отвечающий за наличие встроенных временных интервалов
 * @param {number} timeout таймаут
 * @returns возвращает обновленный объект всех сенсоров
 */
const defaultUploadingOrder = async (options) => {
    const {
        selectedSensorsCopy,
        sensorsForManagementById,
        dateToInMillis,
        dateFromInMillis,
        isCSVDataIncludesDateFields,
        timeout,
    } = options;
    const copy = cloneDeep(sensorsForManagementById);
    const dataForGet = [];
    const dataForGetVivotekUploadData = [];
    const dataForGetHikvisionUploadData = [];
    const dataForPost = [];
    const PORTION_SIZE = 10;

    selectedSensorsCopy.forEach((element) => {
        const sensor = cloneDeep(sensorsForManagementById[element.id]);
        sensor.dataFetching = false;
        let dateTo = '';
        let dateFrom = '';
        if (isCSVDataIncludesDateFields && !(dateToInMillis && dateFromInMillis)) {
            dateTo = DateTime.fromISO(element.date_to).toMillis();
            dateFrom = DateTime.fromISO(element.date_from).toMillis();
        } else {
            dateTo = dateToInMillis;
            dateFrom = dateFromInMillis;
        }

        if (sensor.errors.length === 0) {
            if (sensor.layers.passWays?.length || sensor.multiSensorLayers?.passWays?.length) {
                switch (sensor.type) {
                    case sensorTypes.XOVIS: {
                        const version = Number(sensor.xovisData?.version?.split('.')[0]);

                        if (isFinite(version)) {
                            if (version < 5) {
                                const agentId = Number(sensor.xovisData?.data?.config?.companyDatapushAgent?.id);
                                if (isFinite(agentId)) {
                                    const data = {
                                        url: `http://${sensor.ip}:${sensor.port}/api/data-push/manual?passwd=${sensor.pass}&id=${agentId}&from=${dateFrom}&to=${dateTo}`,
                                        initialData: sensor,
                                        user: sensor.user || 'admin',
                                        pass: sensor.pass,
                                        extraArgs: {
                                            dateInterval: {
                                                dateFrom: dateFrom,
                                                dateTo: dateTo,
                                            },
                                        },
                                    };

                                    dataForGet.push(data);
                                } else {
                                    copy[sensor.id] = {
                                        ...sensor,
                                        errors: [sensor.errors, `No ${getCompanyName()} url`],
                                    };
                                }
                            } else if (version >= 5) {
                                const data = generateXovisDataForUploadRequestV5({
                                    sensor,
                                    dateFromInMillis: dateFrom,
                                    dateToInMillis: dateTo,
                                });

                                dataForPost.push(data);
                            }
                        } else {
                            copy[sensor.id] = {
                                ...sensor,
                                errors: [sensor.errors, 'Sensor is offline'],
                            };
                        }
                        break;
                    }

                    case sensorTypes.VIVOTEK:
                        if (sensor.vivotekDataUploadUrl) {
                            const data = generateVivotekDataForUploadRequest({
                                dateFromInMillis: dateFrom,
                                dateToInMillis: dateTo,
                                sensor,
                            });
                            dataForGetVivotekUploadData.push(data);
                        } else {
                            copy[sensor.id] = {
                                ...sensor,
                                errors: [...sensor.errors, `No ${getCompanyName()} url`],
                            };
                        }
                        break;

                    case sensorTypes.HIKVISION: {
                        dataForGetHikvisionUploadData.push({
                            sensor,
                            dateFromInMillis: dateFrom,
                            dateToInMillis: dateTo,
                            timeout,
                        });

                        break;
                    }

                    case sensorTypes.TD: {
                        if (isNumber(sensor.tdData?.serviceConfig?.companyServerId)) {
                            const tdDataForPost = generateTdDataForUploadRequest({
                                dateFromInMillis,
                                dateToInMillis,
                                sensor,
                            });
                            dataForPost.push(tdDataForPost);
                        } else {
                            copy[sensor.id] = {
                                ...sensor,
                                errors: [...sensor.errors, `No ${getCompanyName()} url`],
                            };
                        }
                        break;
                    }

                    case sensorTypes.MEGACOUNT: {
                        if (sensor.layers.passWays.length) {
                            const postDataElement = {
                                url: `http://${sensor.ip}:${sensor.port}/upload`,
                                dataForPost: generateMegacountUploadPayload({
                                    companyUploadServer: sensor.megacountData?.companyUploadServer,
                                    accountSettings: sensor.megacountData?.accountSettings,
                                    dateFrom: DateTime.fromMillis(dateFrom).toISO(),
                                    dateTo: DateTime.fromMillis(dateTo).toISO(),
                                }),
                                initialData: sensor,
                                user: sensor.user || 'admin',
                                pass: sensor.pass,
                                extraArgs: {
                                    dateInterval: {
                                        dateFrom: dateFromInMillis,
                                        dateTo: dateToInMillis,
                                    },
                                },
                            };
                            dataForPost.push(postDataElement);
                        } else {
                            copy[sensor.id] = {
                                ...sensor,
                                errors: [...sensor.errors, 'No count lines'],
                            };
                        }
                        break;
                    }

                    default:
                        copy[sensor.id] = {
                            ...sensor,
                            errors: [...sensor.errors, 'Invalid type'],
                        };
                        break;
                }
            } else {
                copy[sensor.id] = {
                    ...sensor,
                    errors: [...sensor.errors, 'No count lines'],
                };
            }
        } else {
            copy[sensor.id] = { ...sensor };
        }
    });

    const getResult = await portionDataGet({
        portionSize: PORTION_SIZE,
        dataArray: dataForGet,
        timeout,
        isAuth: true,
    });

    const hikvisionGetUploadDataRes = await hikvisionUploadDataHandler(dataForGetHikvisionUploadData);

    hikvisionGetUploadDataRes.forEach((element) => {
        const sensor = cloneDeep(element.initialData);
        if (!element.error) {
            dataForPost.push(element);
        } else {
            sensor.orderCount += 1 + copy[sensor.id].orderCount;
            sensor.failedIntervals = [element.extraArgs.dateInterval].concat(copy[sensor.id].failedIntervals);
            copy[sensor.id] = {
                ...sensor,
                errors: [...sensor.errors, element.error],
            };
        }
    });

    const vivotekDataUploadResponse = await portionDataGet({
        portionSize: PORTION_SIZE,
        dataArray: dataForGetVivotekUploadData,
        timeout,
        isAuth: true,
    });

    vivotekDataUploadResponse.forEach((element) => {
        const sensor = cloneDeep(element.initialData);
        if (!element.error) {
            const data = {
                url: sensor.vivotekDataUploadUrl,
                dataForPost: element.response,
                initialData: sensor,
                user: sensor.user || 'admin',
                pass: sensor.pass,
                extraArgs: element.extraArgs,
                headers: { 'Content-Type': 'text/xml' },
            };
            dataForPost.push(data);
        } else {
            sensor.orderCount += 1;
            sensor.failedIntervals.push(element.extraArgs.dateInterval);
            copy[sensor.id] = {
                ...sensor,
                errors: [...sensor.errors, element.error],
            };
        }
    });

    const postResult = await portionDataPost({
        portionSize: PORTION_SIZE,
        dataArray: dataForPost,
        timeout,
        isAuth: true,
    });
    const finalResult = getResult.concat(postResult);

    finalResult.forEach((element) => {
        const sensor = cloneDeep(element.initialData);
        if (!element.error) {
            switch (sensor.type) {
                case sensorTypes.XOVIS:
                    sensor.orderCount = 1;
                    const error = checkErrorInXovisResponse(element.response);
                    if (!error) {
                        sensor.successOrderCount = 1;
                    } else {
                        sensor.failedIntervals.push(element.extraArgs.dateInterval);
                    }
                    copy[sensor.id] = {
                        ...sensor,
                        errors: [...sensor.errors, error],
                    };
                    break;
                case sensorTypes.VIVOTEK:
                    sensor.orderCount = 1;
                    sensor.successOrderCount = 1;
                    copy[sensor.id] = { ...sensor };
                    break;
                case sensorTypes.HIKVISION:
                    sensor.orderCount += 1 + copy[sensor.id].orderCount;
                    sensor.successOrderCount += 1 + copy[sensor.id].successOrderCount;
                    sensor.failedIntervals = cloneDeep(copy[sensor.id].failedIntervals);
                    copy[sensor.id] = { ...sensor };
                    break;

                case sensorTypes.TD:
                    let tdError = '';
                    sensor.orderCount = 1;
                    if (element.response.status === 0) {
                        sensor.successOrderCount = 1;
                    } else {
                        tdError = 'Something went wrong';
                        sensor.failedIntervals.push(element.extraArgs.dateInterval);
                    }
                    copy[sensor.id] = {
                        ...sensor,
                        errors: [...sensor.errors, tdError],
                    };

                    break;
                case sensorTypes.MEGACOUNT: {
                    sensor.orderCount = 1;
                    sensor.successOrderCount = 1;
                    copy[sensor.id] = {
                        ...sensor,
                    };
                    break;
                }

                default:
                    copy[sensor.id] = {
                        ...sensor,
                        errors: [...sensor.errors, 'Invalid type'],
                    };
                    break;
            }
        } else {
            switch (sensor.type) {
                case sensorTypes.MEGACOUNT: {
                    sensor.orderCount = 1;
                    copy[sensor.id] = {
                        ...sensor,
                        failedIntervals: [element.extraArgs.dateInterval],
                        errors: [...sensor.errors, element.error],
                    };
                    break;
                }

                default: {
                    sensor.orderCount += 1 + copy[sensor.id].orderCount;
                    sensor.successOrderCount = copy[sensor.id].successOrderCount;
                    sensor.failedIntervals = [element.extraArgs.dateInterval].concat(copy[sensor.id].failedIntervals);
                    copy[sensor.id] = {
                        ...sensor,
                        errors: [...sensor.errors, element.error],
                    };
                    break;
                }
            }
        }
    });

    return copy;
};

export default defaultUploadingOrder;
