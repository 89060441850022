/**
 * Нахождение позиции для маркера.
 * @param coords Координаты узлов прохода
 * @returns Объект с координатами для размещения маркера.
 */

const findLabelCoordinates = (coords) => {
    if (coords.length % 2 === 0) {
        const first = coords[coords.length / 2 - 1];
        const second = coords[coords.length / 2];
        return { x: (first[0] + second[0]) / 2, y: (first[1] + second[1]) / 2 };
    } else {
        const center = coords[Math.floor(coords.length / 2)];
        return { x: center[0], y: center[1] };
    }
};

export default findLabelCoordinates;
