import { cloneDeep } from 'lodash';
import hikvisionUploadDataHandler from './hikvisionUploadDataHandler';
import portionDataPost from '../../../../utils/portionDataPost';

/**
 * Функция для обработки неуспешныъ временных интервалов, по которым необходима догрузка данных
 * @param {object} sensor объект сенсора
 * @param {array} failedIntervals интервалы, по которым нужна догрузка данных
 * @param {number} timeout таймаут
 * @returns обновленный сенсор
 */
const processHikvisionfailedIntervals = async (options) => {
    const { sensor, failedIntervals, timeout } = options;
    const PORTION_SIZE = 10;
    const sensorCopy = cloneDeep(sensor);
    let max = failedIntervals[0].dateTo;
    let min = failedIntervals[0].dateFrom;
    const dataForPost = [];

    // iterate over array values and update min & max
    failedIntervals.forEach((element) => {
        max = element.dateTo > max ? element.dateTo : max;
        min = element.dateFrom < min ? element.dateFrom : min;
    });

    const hikvisionGetUploadDataRes = await hikvisionUploadDataHandler([
        { sensor: sensorCopy, dateFromInMillis: min, dateToInMillis: max, timeout },
    ]);

    failedIntervals.forEach((failedInterval) => {
        const value = hikvisionGetUploadDataRes.find((element) => {
            if (
                failedInterval.dateFrom >= element.extraArgs.dateInterval.dateFrom &&
                failedInterval.dateFrom < element.extraArgs.dateInterval.dateTo
            ) {
                return true;
            }
            return false;
        });

        if (value) {
            dataForPost.push(value);
        } else {
            sensorCopy.failedInterval?.push(failedInterval);
            sensorCopy.errors.push('Data upload error');
        }
    });
    const response = await portionDataPost({
        portionSize: PORTION_SIZE,
        dataArray: dataForPost,
        timeout,
        isAuth: true,
    });

    response.forEach((element) => {
        if (!element.error) {
            sensorCopy.successOrderCount += 1;
        } else {
            sensorCopy.errors.push(element.error);
            sensorCopy.failedIntervals.push(element.extraArgs.dateInterval);
        }
    });

    return { sensor: { ...sensorCopy } };
};

export default processHikvisionfailedIntervals;
