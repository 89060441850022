import { cloneDeep } from 'lodash';
import { sensorTypes } from '../../../constants/sensorTypes';
import processBrickstreamFailedIntervals from './brickstream/processBrickstreamFailedIntervals';
import processHikvisionfailedIntervals from './hikvision/processHikvisionfailedIntervals';
import processVivotekFailedIntervals from './vivotek/processVivotekFailedIntervals';
import processXovisFailedIntervals from './xovis/processXovisFailedIntervals';
import processTdFailedIntervals from './td/processTdFailedIntervals';
import processMegacountFailedIntervals from './megacount/processMegacountFailedIntervals';

/**
 * Функция для выгрузки данных по зафейленным интервалам у выбранных сенсоров
 * @param {object} sensorsForManagementById объект всех сенсоров
 * @param {array} selectedSensors выбранные сенсоры
 * @param {number} timeout таймаут
 * @returns обновленный объект сенсоров
 */
const processFailedIntervals = async (options) => {
    const PORTION_SIZE = 5;
    const { sensorsForManagementById, selectedSensors, timeout } = options;
    const sensorsForManagementByIdCopy = cloneDeep(sensorsForManagementById);

    const subarray = []; //массив в который будет выведен результат.
    for (let i = 0; i < Math.ceil(selectedSensors.length / PORTION_SIZE); i++) {
        subarray[i] = selectedSensors.slice(
            i * PORTION_SIZE,
            i * PORTION_SIZE + PORTION_SIZE,
        );
    }

    for (let array of subarray) {
        const promises = [];
        array.forEach((element) => {
            const sensor = cloneDeep(sensorsForManagementByIdCopy[element.id]);
            const failedIntervals = cloneDeep(sensor.failedIntervals);
            sensor.failedIntervals = [];
            if (failedIntervals.length > 0) {
                switch (sensor.type) {
                    case sensorTypes.XOVIS: {
                        const result = processXovisFailedIntervals({
                            failedIntervals,
                            sensor,
                            timeout,
                        });
                        promises.push(result);
                        break;
                    }

                    case sensorTypes.VIVOTEK: {
                        const result = processVivotekFailedIntervals({
                            sensor,
                            failedIntervals,
                            timeout,
                        });
                        promises.push(result);
                        break;
                    }

                    case sensorTypes.BRICKSTREAM: {
                        const result = processBrickstreamFailedIntervals({
                            sensor,
                            failedIntervals,
                            timeout,
                        });
                        promises.push(result);
                        break;
                    }
                    case sensorTypes.HIKVISION: {
                        const result = processHikvisionfailedIntervals({
                            sensor,
                            failedIntervals,
                            timeout,
                        });
                        promises.push(result);
                        break;
                    }

                    case sensorTypes.TD: {
                        const result = processTdFailedIntervals({
                            timeout,
                            failedIntervals,
                            sensor,
                        });
                        promises.push(result);
                        break;
                    }

                    case sensorTypes.MEGACOUNT: {
                        const result = processMegacountFailedIntervals({
                            timeout,
                            failedIntervals,
                            sensor,
                        });
                        promises.push(result);
                        break;
                    }

                    default:
                        promises.push(
                            new Promise((resolve) =>
                                resolve({ sensor, error: 'Invalid type' }),
                            ),
                        );
                        break;
                }
            } else {
                promises.push(
                    new Promise((resolve) =>
                        resolve({ sensor, error: 'No failed intervals' }),
                    ),
                );
            }
        });

        await Promise.all(promises).then((responses) => {
            responses.forEach((response) => {
                const sensor = cloneDeep(response.sensor);
                sensor.dataFetching = false;
                if (!response.error) {
                    sensorsForManagementByIdCopy[sensor.id] = { ...sensor };
                } else {
                    sensorsForManagementByIdCopy[sensor.id] = {
                        ...sensor,
                        errors: [...sensor.errors, response.error],
                    };
                }
            });
        });
    }

    return sensorsForManagementByIdCopy;
};

export default processFailedIntervals;
