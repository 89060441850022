import { sensorTypes } from '../../../../../../../constants/sensorTypes';
import BrickstreamUpdateConfiguration from './brickstream/brickstreamUpdateConfiguration';
import XovisUpdateConfiguration from './xovis/xovisUpdateConfiguration';
import VivotekUpdateConfiguration from './vivotek/vivotekUpdateConfiguration';
import HikvisionUpdateConfiguration from './hikvision/hikvisionUpdateConfiguration';
import TdUpdateConfiguration from './td/tdUpdateConfiguration';
import MegacountUpdateConfiguration from './megacount/MegacountUpdateConfiguration/MegacountUpdateConfiguration';

/**
 * Компонент для отрисовки колонки с обновление конфигурации сенсора
 * @param {object} selectedSensor объект выбранного сенсора
 * @param {object} iPoint объект точки установки
 * @param {function} updateSensorConfigurationThunkTrans функция для обновления конфинурации сенсора
 * @param {number} locationIdFromMapServiceLink id локации из мап сервиса
 * @param {function} updateAvailableBoundleByIPointIdTrans функция для создания\обновления связки
 * @param {object} availableBundlesByIPointId объект связок, где ключ это id связки
 * @param {string} uploadKey ключ для обновления выгрузки
 */
const UploadUpdate = ({
    selectedSensor,
    iPoint,
    availableBundlesByIPointId,
    uploadKey,
}) => {
    try {
        switch (selectedSensor.sensor_type) {
            case sensorTypes.XOVIS:
                return (
                    <XovisUpdateConfiguration
                        availableBundlesByIPointId={availableBundlesByIPointId}
                        selectedSensor={selectedSensor}
                        uploadKey={uploadKey}
                        iPoint={iPoint}
                    />
                );

            case sensorTypes.BRICKSTREAM:
                return (
                    <BrickstreamUpdateConfiguration
                        availableBundlesByIPointId={availableBundlesByIPointId}
                        selectedSensor={selectedSensor}
                        uploadKey={uploadKey}
                        iPoint={iPoint}
                    />
                );

            case sensorTypes.VIVOTEK:
                return (
                    <VivotekUpdateConfiguration
                        availableBundlesByIPointId={availableBundlesByIPointId}
                        selectedSensor={selectedSensor}
                        uploadKey={uploadKey}
                        iPoint={iPoint}
                    />
                );

            case sensorTypes.HIKVISION:
                return (
                    <HikvisionUpdateConfiguration
                        availableBundlesByIPointId={availableBundlesByIPointId}
                        selectedSensor={selectedSensor}
                        uploadKey={uploadKey}
                        iPoint={iPoint}
                    />
                );

            case sensorTypes.TD:
                return (
                    <TdUpdateConfiguration
                        availableBundlesByIPointId={availableBundlesByIPointId}
                        selectedSensor={selectedSensor}
                        uploadKey={uploadKey}
                        iPoint={iPoint}
                    />
                );

            case sensorTypes.MEGACOUNT:
                return (
                    <MegacountUpdateConfiguration
                        availableBundlesByIPointId={availableBundlesByIPointId}
                        selectedSensor={selectedSensor}
                        uploadKey={uploadKey}
                        iPoint={iPoint}
                    />
                );

            default:
                return null;
        }
    } catch (_) {
        return null;
    }
};

export default UploadUpdate;
