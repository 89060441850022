import getVivotekCountLinesInfo from './getVivotekCountLinesInfo';
import getVivotekUploadUrls from './getVivotekUploadUrls';
import processVivotekLayers from '../../../../commonFunctions/vivotek/processVivotekLayers';
import { getCompanyUploadUrlPart } from '../../../../constants/constants';

/**
 * Функция для создания промиса с конфигом сенсора
 * @param {object} sensor объект сенсора
 * @param {number} timeout таймаут
 * @returns данные для сенсора
 */
const getVivotekConfig = async (sensor, timeout) => {
    const layers = await getVivotekCountLinesInfo(sensor, timeout);
    let error = '';
    let vivotekDataUploadUrl = '';
    let parsedLaeyrs;
    if (!layers.error) {
        parsedLaeyrs = processVivotekLayers(layers.layers);
        const urls = await getVivotekUploadUrls(sensor, timeout);
        if (!urls.error) {
            Object.keys(urls.uploadUrls).forEach((key) => {
                if (
                    urls.uploadUrls[key].url.includes(getCompanyUploadUrlPart())
                )
                    vivotekDataUploadUrl = `${urls.uploadUrls[key].servertypeselector}://${urls.uploadUrls[key].url}${urls.uploadUrls[key].uri}`;
            });
        } else {
            error = urls.error;
        }
    } else {
        error = layers.error;
    }

    if (!error) {
        return {
            layers: parsedLaeyrs,
            vivotekDataUploadUrl,
            sensor: { ...sensor },
        };
    }

    return { error, sensor: { ...sensor } };
};

export default getVivotekConfig;
