import { TextField } from '@fluentui/react';
import styled from 'styled-components/macro';

export const Container = styled.div`
    padding: 5px 15px;
    display: flex;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    margin-top: 5px;
`;

export const FileName = styled.div`
    font-size: 14px;
    color: rgba(0, 0, 0, 0.7);
    span {
        color: rgb(50, 49, 48);
        font-weight: 600;
    }
`;

export const buttonsStyles = {
    root: {
        width: '150px',
        marginRight: '15px',
    },
};

export const multiLineTextFieldStyles = {
    root: {
        width: '600px',
        marginBottom: '10px',
    },
};

export const modeDropdownStyles = {
    root: {
        width: '150px',
        marginRight: '15px',
        alignSelf: 'center',
    },
};

export const timeoutTextFieldStyles = {
    root: {
        width: '150px',
        marginRight: '15px',
        position: 'relative',
    },
};

export const ActionWrapperStyles = styled.div`
    display: flex;
    margin-top: 5px;
`;

export const ModeSelectionsLabelStyles = styled.div`
    margin-top: 10px;
    color: rgb(50, 49, 48);
    font-weight: 600;
    font-size: 14px;
`;

export const StyledTimeoutTextField = styled(TextField)`
    ::before {
        content: 'Timeout (s)';
        position: absolute;
        top: -24px;
        color: rgb(50, 49, 48);
        font-weight: 600;
        font-size: 14px;
    }
    span {
        div {
            animation-duration: 0s;
            padding: 0;
        }
    }
`;

export const TimeoutError = styled.div`
    position: absolute;
    width: 100%;
    bottom: -15px;
`;
