import { ErrorsText, Wrapper, calloutStyles } from './errorsIndication.styles';
import { StatusIcon } from './senorsManagementList.styles';
import { useBoolean, useId } from '@fluentui/react-hooks';
import { Callout } from '@fluentui/react';
import { memo } from 'react';

/**
 * Компонент для отображения ошибок для конкретного сенсора в списке.
 * @param {array} errors  массив ошибок
 */
const ErrorsIndication = memo(({ errors }) => {
    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    const calloutId = useId('error-callout');

    if (errors.length === 0) {
        return null;
    }

    try {
        const component = (
            <Wrapper>
                <StatusIcon iconName='BoxMultiplySolid' color='red' />
                <ErrorsText onClick={toggleIsCalloutVisible} id={calloutId}>{`Errors: ${errors.length}`}</ErrorsText>
                {isCalloutVisible && (
                    <Callout styles={calloutStyles} target={`#${calloutId}`} onDismiss={toggleIsCalloutVisible}>
                        {errors.map((error, i) => (
                            <p key={i + Math.random()}>{error}</p>
                        ))}
                    </Callout>
                )}
            </Wrapper>
        );
        return component;
    } catch (error) {
        console.log(`Callout error with id ${error}`);
    }
});

export default ErrorsIndication;
