import axios from 'axios';

/**
 * Вспомогательная функция для получения серийного номера от сенсора, при пакетной загрузке данных
 * @param {object} singleDataPackage элемент из csv файла или csv строки
 */
const getHikvisionSerialNumberForTable = async (options) => {
    const { singleDataPackage } = options;
    const oParser = new DOMParser();
    let serialNumber = '';
    let notEqualSerialNumber = false;

    return axios
        .get(`http://${singleDataPackage.ip}:${singleDataPackage.port}/ISAPI/System/Network/interfaces `, {
            timeout: 5000,
            auth: {
                username: singleDataPackage.user,
                password: singleDataPackage.pass,
            },
        })
        .then((res) => res.data)
        .then((hikvisionInformation) => {
            const xmlInformation = oParser.parseFromString(hikvisionInformation, 'text/xml');
            const serialNumbersArray = xmlInformation.getElementsByTagName('MACAddress');
            if (serialNumbersArray.length > 0) serialNumber = serialNumbersArray[0].textContent;
            if (singleDataPackage.serial_number && serialNumber.toLowerCase() !== singleDataPackage.serial_number.toLowerCase()) notEqualSerialNumber = true;
            return {
                id: singleDataPackage.id,
                serialNumber,
                notEqualSerialNumber,
            };
        })
        .catch((error) => ({ id: singleDataPackage.id, error }));
};

export default getHikvisionSerialNumberForTable;
