import getScreens from './underV5Methods/getScreens';
import getScreensV5 from './v5Methods/getScreensV5';
/**
 * Функция для получения скриншота от сенсора
 * @param {object} sensor объект сенсора
 * @param {number} timeout таймаут
 * @returns объект с сенсором и скриншотом
 */
const getXovisScreen = async (sensor, timeout) => {
    const version = Number(sensor.xovisData?.version?.split('.')[0]);

    if (isFinite) {
        let screen = '';
        let multiSensorScreen = '';
        let error = '';

        if (version < 5) {
            const screens = await getScreens({ sensor, timeout });

            if (!screens.error) {
                screen = screens.screen;
                multiSensorScreen = screens.multiSensorScreen;
            } else {
                error = screens.error;
            }
        } else if (version >= 5) {
            const screens = await getScreensV5({ sensor, timeout });

            if (!screens.error) {
                screen = screens.screen;
                multiSensorScreen = screens.multiSensorScreen;
            } else {
                error = screens.error;
            }
        }
        return { sensor, screen, multiSensorScreen, error };
    } else {
        return {
            sensor,
            error: 'Sensor is offline',
        };
    }
};

export default getXovisScreen;
