import { xovisPostRequest } from '../../../../../api/xovis/xovis.api';

/**
 * Создание connection для выгрузки
 * @param {object} sensor объект сенсора
 * @param {object} payload
 * @returns Объект с ошибкой или нового агента
 */
const postXovisConnectionV5 = async (args) => {
    const { sensor, payload } = args;

    const newConnection = await xovisPostRequest({
        url: `http://${sensor.ip}:${sensor.port}/api/v5/${
            sensor.isMultiSensor ? 'multisensor' : 'singlesensor'
        }/data/push/connections`,
        body: payload,
        username: sensor.username,
        password: sensor.password,
    });

    if (!newConnection.error) {
        return newConnection;
    }

    return {
        error: 'Ann error accrued while post new connection',
    };
};

export default postXovisConnectionV5;
