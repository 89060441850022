import styled from 'styled-components/macro';
import Split from 'react-split';

export const StyledSplit = styled(Split)`
    display: flex;
    flex-direction: row;
    height: 100%;
`;

export const FloorPlanWrapper = styled.div`
    overflow: hidden;
`;

export const TableWrapper = styled.div`
    overflow: hidden;
`;

export const gutterStyles = {
    width: '10px',
    'background-color': '#eee',
    'background-repeat': 'no-repeat',
    'background-position': '50%',
    'background-image': `url(
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg=='
    )`,
    '    cursor': 'col-resize',
};

export const LocationWarningMessage = styled.div`
    padding: 5px 15px;
`;
