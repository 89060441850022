import { xovisGetRequest } from '../../../../../api/xovis/xovis.api';

/**
 * Получение
 * @param {object} sensor объект датчика
 * @param {boolean} isMultisensor флаг мультисенсор или нет
 * @param {number} timeout timeout
 * @returns объект с ошибкой или данные
 */
const getXovisSceneImageMetadataV5 = async (args) => {
    const { sensor, isMultisensor, timeout } = args;

    const sceneMetadata = await xovisGetRequest({
        url: `http://${sensor.ip}:${sensor.port}/api/v5/${
            isMultisensor ? 'multisensor' : 'singlesensor'
        }/scene/images/metadata`,
        username: sensor.username,
        password: sensor.password,
        timeout,
    });

    if (!sceneMetadata.error && sceneMetadata.image_metadata) {
        return sceneMetadata;
    }

    return { error: 'Ann error accrued while getting scene image metadata' };
};

export default getXovisSceneImageMetadataV5;
