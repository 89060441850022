import { Icon } from '@fluentui/react';
import styled from 'styled-components/macro';

export const FloorSideBarWrapper = styled.div`
    height: 100%;
    position: relative;
    box-sizing: border-box;
    flex: ${(props) => `0 0 ${props.mySize + 'px'}`};
    background-color: #edebe9;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px 0 0 0;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    z-index: 11;
`;

export const MessageIconWrapper = styled.div`
    margin-top: 10px;
    cursor: pointer;
`;

export const CustomMessageIcon = styled(Icon)`
    font-size: 18px;
`;

export const CustomMapLayersIcon = styled(Icon)`
    font-size: 20px;
`;

export const MapLayersIconWrapper = styled.div`
    margin-top: 10px;
    cursor: pointer;
`;

export const WindowWithOptionsWrapper = styled.div`
    width: 200px;
    transition: 0.5s all;
    border-radius: 5px;
    min-height: 40px;
    background-color: #edebe9;
    position: absolute;
    top: ${(props) => (props.show ? '15px' : '-200%')};
    z-index: 10;
    left: 50px;
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.3);
`;
