import { Spinner } from '@fluentui/react';
import styled from 'styled-components/macro';

export const SpinnerWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.15);
    width: 100%;
    height: 100%;
    display: flex;
    z-index: 10000000;
    justify-content: center;
    align-items: center;
`;

export const CustomSpinner = styled(Spinner)`
    div {
        border-color: rgb(0, 120, 212) rgb(255 255 255) rgb(255 255 255);
        width: 38px;
        height: 38px;
    }
`;
