import { cloneDeep } from 'lodash';

/**
 * функция для обновления состояния выбранных сенсоров
 * @param {array} selectedSensors массив выбранных сенсоров
 * @param {object} sensorsForManagementById объект сенсоров, гдю ключ это id сенсора из csv (генерируется на фронте)
 * @param {boolean} fetching флаг отвечающий за показ крутилки
 * @return обновленные объекты
 */
const refreshSelectedSensors = (options) => {
    const { selectedSensors, sensorsForManagementById, fetching = true } = options;
    const copy = cloneDeep(sensorsForManagementById);
    const selectedCopy = cloneDeep(selectedSensors);
    selectedSensors.forEach((element) => {
        copy[element.id] = {
            ...element,
            dataFetching: fetching,
            errors: [],
            orderCount: 0,
            successOrderCount: 0,
            xovisData: {},
            failedIntervals: [],
            layers: {},
            serialNumber: '',
            screen: '',
            brickstreamUrlNumber: null,
            successScreenPost: false,
        };
        const index = selectedCopy.findIndex((sensor) => sensor.id === element.id);
        const item = cloneDeep(selectedCopy.find((sensor) => sensor.id === element.id));

        selectedCopy.splice(index, 1);
        selectedCopy.push({
            ...item,
            dataFetching: fetching,
            errors: [],
            orderCount: 0,
            successOrderCount: 0,
            xovisData: {},
            failedIntervals: [],
            layers: {},
            serialNumber: '',
            screen: '',
            brickstreamUrlNumber: null,
        });
    });

    return { copy, selectedCopy };
};

export default refreshSelectedSensors;
