import { brickstreamGetRequest } from '../../../api/brickstream/brickstream.api';
import { sensorTypes } from '../../../constants/sensorTypes';
import { vivotekGetRequest } from '../../../api/vivotek/vivotek.api';
import { hikvisionGetRequest } from '../../../api/hikvision/hikvision.api';
import { rstatPostRequest } from '../../../api/rstat/rstat.api';
import getRstatMac from '../../../utils/rstatHelpers/getRstatMac';
import getTdNetworkConfig from './td/getTdNetworkConfig';
import getXovisInfo from './xovis/underV5Methods/getXovisInfo';
import getXovisInfoV5 from './xovis/v5Methods/getXovisInfoV5';
import axios from 'axios';
import getMegacountNetworkSettings from './megacount/getMegacountNetworkSettings';

/**
 * Функция для получения серийника от датчика и проверки его на соответвствие серийнику из инпута
 * @param {object} formValues значения из формы
 * @param {string} locationId  id локации
 */
const checkSensorSerialNumber = async (formValues, locationId) => {
    const { login, password, port, ip, serialNumber, type } = formValues;
    const oParser = new DOMParser();
    let sensorSerialNumber = '';

    const postPayload = {
        serial_number: serialNumber,
        ip,
        project_location_id: +locationId,
        port: Number(port),
        is_active: true,
        username: login,
        password,
    };

    const errorResponse = {
        error: '',
        actions: {
            showErrorNotification: { show: false, message: '' },
            serialNumberModal: {
                open: false,
                warningInformation: {
                    message: '',
                    inputSerialNumber: serialNumber,
                    sensorSerialNumber: null,
                    isSensorOnline: false,
                    postPayload,
                    type,
                },
            },
        },
    };

    switch (type) {
        case sensorTypes.XOVIS: {
            const info = getXovisInfo({
                sensor: { ip, port, username: login, password },
            });
            const infoV5 = getXovisInfoV5({
                sensor: { ip, port, username: login, password },
            });

            await axios.all([info, infoV5]).then(
                axios.spread((info, infoV5) => {
                    if (!info.error) {
                        sensorSerialNumber = info.serialNumber;
                    }

                    if (!infoV5.error) {
                        sensorSerialNumber = infoV5.serialNumber;
                    }
                }),
            );
            break;
        }
        case sensorTypes.BRICKSTREAM: {
            const response = await brickstreamGetRequest(
                `http://${ip}:${port}/help.html`,
                login || 'admin',
                password,
            );
            if (!response.error) {
                const xmlResponse = oParser.parseFromString(
                    response,
                    'text/xml',
                );
                sensorSerialNumber = xmlResponse
                    .querySelector('#MAC_ADDRESS')
                    .value.toLowerCase();
            }
            break;
        }
        case sensorTypes.VIVOTEK: {
            const response = await vivotekGetRequest(
                `http://${ip}:${port}/VCA/Camera/Profile`,
                login || 'admin',
                password,
            );
            if (!response.error) {
                sensorSerialNumber = response.Data.Mac.toLowerCase();
            }
            break;
        }
        case sensorTypes.HIKVISION: {
            const response = await hikvisionGetRequest(
                `http://${ip}:${port}/ISAPI/System/Network/interfaces `,
                login || 'admin',
                password,
            );
            if (!response.error) {
                const xmlResponse = oParser.parseFromString(
                    response,
                    'text/xml',
                );
                sensorSerialNumber = xmlResponse
                    .querySelector('MACAddress')
                    .textContent.toLowerCase();
            }
            break;
        }
        case sensorTypes.RSTAT: {
            const response = await rstatPostRequest({
                url: `http://${ip}:${port}/sys_inf.php`,
                payload: JSON.stringify({
                    login,
                    password,
                }),
            });

            if (!response.error) {
                sensorSerialNumber = getRstatMac(response);
            }
            break;
        }

        case sensorTypes.DILAX: {
            // Получение мака
            break;
        }

        case sensorTypes.TD: {
            const networkConfig = await getTdNetworkConfig({ ip, port });

            if (!networkConfig.error) {
                sensorSerialNumber = networkConfig.deviceMac;
            }
            break;
        }

        case sensorTypes.MEGACOUNT: {
            const networkSettings = await getMegacountNetworkSettings({
                ip,
                port,
                username: login,
                password,
            });

            if (!networkSettings.error) {
                sensorSerialNumber = networkSettings.current.mac;
            }
            break;
        }

        default:
            console.log('>>>>>>>>mac invalid type');
            break;
    }

    if (sensorSerialNumber) {
        postPayload.serial_number = sensorSerialNumber;
        if (serialNumber && sensorSerialNumber !== serialNumber) {
            errorResponse.error = 'Serials do not match';
            errorResponse.actions.serialNumberModal.open = true;
            errorResponse.actions.serialNumberModal.warningInformation.message =
                'You entered the serial number of the sensor, but it does not match the serial number from the sensor configuration';
            errorResponse.actions.serialNumberModal.warningInformation.sensorSerialNumber =
                sensorSerialNumber;
            errorResponse.actions.serialNumberModal.warningInformation.isSensorOnline = true;
        } else {
            return { postPayload };
        }
    } else {
        if (serialNumber) {
            errorResponse.error = 'Sensor is offline';
            errorResponse.actions.serialNumberModal.open = true;
            errorResponse.actions.serialNumberModal.warningInformation.message =
                'You entered the serial number of the sensor, but according to ip: port, the sensor did not respond';
        } else {
            errorResponse.error = 'Sensor is offline';
            errorResponse.actions.showErrorNotification.show = true;
            errorResponse.actions.showErrorNotification.message =
                'Sensor is offline';
        }
    }

    return errorResponse;
};

export default checkSensorSerialNumber;
