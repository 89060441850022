import { DetailsList } from '@fluentui/react';
import { columnsForParseErrorsModal } from './columnsForParseErrorsModal';
import { ParseErrorsModalTitle } from './parseErrorsModal.styled';

const ParseErrorsModal = ({ parseErrors }) => {
    const renderItemColumn = (item, _, column) => {
        const fieldContent = item[column.fieldName];
        switch (column.key) {
            case 'data':
                return <div>{JSON.stringify(item.elementData)}</div>;
            case 'row':
                return <div>{item.row + 1}</div>;
            default:
                return <div>{fieldContent}</div>;
        }
    };
    return (
        <>
            <ParseErrorsModalTitle>Not equal serialNumbers</ParseErrorsModalTitle>
            <DetailsList columns={columnsForParseErrorsModal} onRenderItemColumn={renderItemColumn} items={parseErrors} selectionMode={0} />
        </>
    );
};

export default ParseErrorsModal;
