import { xovisGetRequest } from '../../../../../api/xovis/xovis.api';

/**
 * Проверка статуса отправки данных
 * @param {object} sensor объект датчика
 * @param {number} attempts кол-во попыток
 * @returns Объект с ошибкой или объект с статусом
 */
const getResendDataStatus = async (args) => {
    const { sensor, attempts = 0 } = args;

    if (attempts < 5) {
        const status = await xovisGetRequest({
            url: `http://${sensor.ip}:${sensor.port}/api/v5/${
                sensor.isMultiSensor ? 'multisensor' : 'singlesensor'
            }/data/push/agents/${sensor.xovisData?.v5Data?.companyAgent?.id}/trigger`,
            username: sensor.user,
            password: sensor.pass,
        });

        if (status.status !== 'IDLE') {
            await new Promise((resolve) => setTimeout(resolve, 1000));
            return getResendDataStatus({ sensor, attempts: attempts + 1 });
        }

        return { status };
    }

    return { error: 'Ann error accrued while getting resend data status' };
};

export default getResendDataStatus;
