import { TooltipHost } from '@fluentui/react';
import { rstatProgressMessages } from '../../../../../../../../utils/rstatHelpers/rstatProgressMessages';
import {
    ColumnWrapper,
    Circle,
    CircleWrapper,
} from '../../instalationPointsList.styles';

/**
 *  Компонент для отрисовки прогресс бара для датчика RSTAT
 * @param {object} selectedSensor объект выбранного сенсора
 */
const RstatProgressColumn = ({ selectedSensor }) => {
    return (
        <ColumnWrapper>
            <CircleWrapper>
                <TooltipHost
                    content={
                        selectedSensor
                            ? !selectedSensor.firstStepError
                                ? rstatProgressMessages.firstStepEnabled
                                : selectedSensor.firstStepError
                            : null
                    }
                >
                    <Circle
                        newColor={
                            selectedSensor
                                ? selectedSensor.isFetching
                                    ? 'grey'
                                    : !selectedSensor.firstStepError
                                    ? 'green'
                                    : 'red'
                                : 'grey'
                        }
                    >
                        1
                    </Circle>
                </TooltipHost>
            </CircleWrapper>
        </ColumnWrapper>
    );
};

export default RstatProgressColumn;
