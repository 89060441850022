import { DateTime } from 'luxon';
import { megacountPostRequest } from './megacount.api';

const DATE_FORMAT_MAP = {
    'dd.mm.yyyy': 'dd.MM.yyyy',
    'mm.dd.yyyy': 'MM.dd.yyyy',
    'yyyy.mm.dd': 'yyyy.MM.dd',
};

/**
 * Функция для перезапроса данных с датчика за переданный интервал времени
 * @param {undefined | object} companyUploadServer Сервер выгрузки компании
 * @param {undefined | object} accountSettings Настройки аккаунта пользователя
 * @param {string} username Логин датчика
 * @param {string} password Пароль датчика
 * @param {string} dateFrom Дата ОТ которой запросить данные (в формате ISO)
 * @param {number} timeout Timeout в миллисекундах
 * @param {string} dateTo Дата ДО которой запросить данные (в формате ISO)
 * @param {number | string} port Порт датчика
 * @param {string} ip IP Датчика
 * @returns Объект с ошибкой или объект со статусом ok
 */
const uploadMegacountData = async (args) => {
    const {
        companyUploadServer,
        accountSettings,
        username,
        password,
        dateFrom,
        timeout,
        dateTo,
        port,
        ip,
    } = args;

    if (
        companyUploadServer?.upload_id &&
        accountSettings?.formatDate &&
        DATE_FORMAT_MAP[accountSettings?.formatDate || '']
    ) {
        const uploadResponse = await megacountPostRequest({
            url: `http://${ip}:${port}/upload`,
            payload: generateMegacountUploadPayload({
                companyUploadServer,
                accountSettings,
                dateFrom,
                dateTo,
            }),
            username,
            password,
            timeout,
        });

        if (
            !uploadResponse.error &&
            uploadResponse?.upload?.post?.status === 'ok'
        ) {
            return uploadResponse;
        }
    }

    return {
        error: 'An error accrued while resend data',
    };
};

export default uploadMegacountData;

export const generateMegacountUploadPayload = (args) => {
    const { companyUploadServer, accountSettings, dateFrom, dateTo } = args;

    const dateFormat =
        DATE_FORMAT_MAP[accountSettings?.formatDate || 'yyyy.mm.dd'];
    const convertedDateFrom = DateTime.fromISO(dateFrom).toFormat(dateFormat);
    const convertedDateTo = DateTime.fromISO(dateTo).toFormat(dateFormat);

    return {
        upload: {
            post: {
                force_upload: [
                    {
                        id: companyUploadServer?.upload_id || '',
                        date: [convertedDateFrom, convertedDateTo],
                    },
                ],
            },
        },
    };
};
