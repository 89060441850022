import { Callout, Dropdown, IconButton, PrimaryButton, TextField, Toggle, TooltipHost } from '@fluentui/react';
import { createContext, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import dateFormater from '../../utils/dateHandlers/dateFormater';
import FloorPlan from '../floorPlan/floorPlan';
import SensorsScreensList from './components/sensorsScreensList';
import { useBoolean } from '@fluentui/react-hooks';
import {
    ListContainer,
    datePickersStyles,
    DatePickersWrapper,
    gutterStyles,
    startButtonStyles,
    StyledDatePicker,
    StyledSplit,
    switchStyles,
    optionIconStyles,
    calloutStyles,
    optionsDropdownStyles,
    screenSizeDropdownStyles,
    filterStyles,
} from './screenshotsPage.styles';
import {
    getScreensInfoThunk,
    storeColumns,
    storeExtendedSensors,
    storeScreensDateFrom,
    storeScreensDateTo,
    storeSelectedSensors,
    toggleScreensFetched,
    toggleShowScreensByDate,
    storeScreenSize,
    storeColumnsToHide,
    storeColumnToSort,
    storeFilteredItemsForScreensList,
    storeSensorRelationsById,
} from './screenshotsPageReducer';
import useColumnsForList from './tools/useColumnsForList';
import { hideColumnOptions, screenSizeOptions } from './tools/dropdownOptions';
import { cloneDeep, keyBy } from 'lodash';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useSensorRelationsById from '../../hooks/useSensorRelationsById';
import { sortScreensList } from './tools/sortScreensList';

/**
 * Компонент для отображения страницы со скринами по сенсорам локации
 */
export const ScreenshotsPageContext = createContext({});
const ScreenshotsPage = memo(() => {
    const {
        screensDateFrom,
        screensDateTo,
        selectedSensors,
        extendedSensorsById,
        extendedSensors,
        showScreensByDate,
        columns,
        isScreensFetched,
        screenSize,
        columnsToHide,
        filteredItemsForScreensList,
        columnToSort,
        sensorRelationsById,
    } = useSelector((state) => state.screenshotsPageReducer);
    const { mapServiceLink, selectedLocationTimeZone, plStructure, plStructureFetching, sensor2PassWayById } =
        useSelector((state) => state.instalationPointsReducer);
    const { windowSize } = useSelector((state) => state.generalReducer);
    const { sensorsById, sensors } = useSelector((state) => state.countingSensorsReducer);
    const [splitWidth, setSplitWidth] = useState(windowSize.width * 0.5);
    const [planWidthInPercents, setPlanWidthInPercents] = useState(50);
    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    const [filter, setFilter] = useState('');
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const params = useParams();
    const nav = useNavigate();
    useColumnsForList();
    const sensorRelations = useSensorRelationsById({ dateFrom: screensDateFrom, dateTo: screensDateTo });

    useEffect(() => {
        const getObjectName = (marker) => {
            const dataObject = plStructure?.core?.elements_admin_data_objects?.find((item) => item.marker === marker);
            return dataObject?.name || null;
        };
        dispatch(
            storeSensorRelationsById(
                Object.entries(sensorRelations).reduce((acc, [sensorId, relationsByType]) => {
                    acc[sensorId] = {
                        ...Object.entries(relationsByType).reduce((acc, [objectType, relations]) => {
                            acc[objectType] = relations.map((relation) => {
                                const name = getObjectName(relation.dataobj_marker);
                                const fullText = name
                                    ? `${relation.dataobj_marker} (${name})`
                                    : relation.dataobj_marker;
                                return { ...relation, marker_and_name: fullText };
                            });
                            return acc;
                        }, {}),
                    };
                    return acc;
                }, {}),
            ),
        );
    }, [dispatch, sensorRelations, plStructure]);

    useEffect(() => {
        if (!mapServiceLink && params.locationId && params.projectId) {
            nav(`/projects/${params.projectId}/project-locations/${params.locationId}/installation-points`);
        }
        // eslint-disable-next-line
    }, [params]);

    useEffect(() => {
        if ((filter || columnToSort) && extendedSensors) {
            let extendedSensorsCopy = cloneDeep(extendedSensors);
            if (columnToSort) {
                const sortResult = sortScreensList({
                    columns,
                    columnToSort,
                    items: extendedSensorsCopy,
                    sensorRelationsBySensorId: sensorRelations,
                });

                extendedSensorsCopy = sortResult.items;
                dispatch(storeColumns(sortResult.columns));
            }
            if (filter) {
                extendedSensorsCopy = extendedSensorsCopy.filter(
                    (element) =>
                        element.ip.includes(filter) ||
                        String(element.port).includes(filter) ||
                        element.serial_number.includes(filter) ||
                        element.sensor_type.includes(filter) ||
                        Object.values(sensorRelationsById[element.id] || {})?.some((relations) =>
                            relations?.some((relation) => relation.marker_and_name.includes(filter)),
                        ),
                );
            }
            dispatch(storeFilteredItemsForScreensList(extendedSensorsCopy));
        } else {
            dispatch(storeFilteredItemsForScreensList(cloneDeep(extendedSensors)));
        }
        // eslint-disable-next-line
    }, [
        filter,
        extendedSensors,
        screenSize,
        plStructureFetching,
        sensor2PassWayById,
        sensorRelationsById,
        columnToSort,
        sensorRelations,
    ]);

    useEffect(() => {
        dispatch(toggleScreensFetched(false));
        dispatch(toggleShowScreensByDate(false));
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (sensors.length) {
            dispatch(storeExtendedSensors(keyBy(sensors, 'id')));
        }
        // eslint-disable-next-line
    }, [sensors]);

    useEffect(() => {
        setSplitWidth((windowSize.width * planWidthInPercents) / 100);
    }, [windowSize, planWidthInPercents]);

    const datePickersHandler = (value, type) => {
        const date = dateFormater(value);
        switch (type) {
            case 'from':
                dispatch(storeScreensDateFrom(date));
                break;
            case 'to':
                dispatch(storeScreensDateTo(date));
                break;

            default:
                break;
        }
    };

    const filterHandler = useCallback((_, value) => {
        setFilter(value || '');
        // eslint-disable-next-line
    }, []);

    const columnsToHideHandler = (_, item) => {
        if (item) {
            dispatch(
                storeColumnsToHide(
                    item.selected ? [...columnsToHide, item.key] : columnsToHide.filter((key) => key !== item.key),
                ),
            );
        }
    };

    const screenSizeHandler = (_, item) => dispatch(storeScreenSize(item));

    const storeSelectedSensorsTrans = (value) => dispatch(storeSelectedSensors(value));

    const splitDragEnd = (sizes) => setPlanWidthInPercents(sizes[0]);

    const startHandler = () => dispatch(getScreensInfoThunk());

    const switchHandler = (_, checked) => dispatch(toggleShowScreensByDate(checked));

    const storeColumnsTrans = (value) => dispatch(storeColumns(value));

    const storeColumnToSortTrans = (value) => dispatch(storeColumnToSort(value));

    const gutterStyle = () => {
        return gutterStyles;
    };

    const contextValue = {
        columns,
        isScreensFetched,
        sensorsById,
        screensDateFrom,
        screensDateTo,
        storeSelectedSensorsTrans,
        extendedSensorsById,
        extendedSensors,
        storeColumnsTrans,
        screenSize,
        selectedLocationTimeZone,
        windowSize,
        plStructure,
        plStructureFetching,
        sensor2PassWayById,
        storeColumnToSortTrans,
        filteredItemsForScreensList,
        sensorRelationsById,
    };
    return (
        <ScreenshotsPageContext.Provider value={contextValue}>
            {mapServiceLink ? (
                <StyledSplit sizes={[50, 50]} onDragEnd={splitDragEnd} gutterStyle={gutterStyle}>
                    <FloorPlan splitWidth={splitWidth} />
                    <ListContainer>
                        <DatePickersWrapper>
                            <PrimaryButton
                                styles={startButtonStyles}
                                text={t('Start')}
                                onClick={startHandler}
                                disabled={selectedSensors.length === 0}
                            />
                            <TextField
                                styles={filterStyles}
                                value={filter}
                                onChange={filterHandler}
                                placeholder={t('Filter')}
                            />
                            <StyledDatePicker
                                value={new Date(screensDateFrom)}
                                onSelectDate={(date) => datePickersHandler(date, 'from')}
                                styles={datePickersStyles}
                                formatDate={(date) => dateFormater(date)}
                                label={t('Screens date from')}
                            />
                            <StyledDatePicker
                                value={new Date(screensDateTo)}
                                onSelectDate={(date) => datePickersHandler(date, 'to')}
                                styles={datePickersStyles}
                                formatDate={(date) => dateFormater(date)}
                                label={t('Screens date to')}
                            />

                            <TooltipHost content={'Options'}>
                                <IconButton
                                    id="callout-button"
                                    styles={optionIconStyles}
                                    iconProps={{ iconName: 'Settings' }}
                                    onClick={toggleIsCalloutVisible}
                                />
                            </TooltipHost>
                            {isCalloutVisible && (
                                <Callout
                                    styles={calloutStyles}
                                    target="#callout-button"
                                    onDismiss={toggleIsCalloutVisible}
                                >
                                    <Dropdown
                                        selectedKeys={columnsToHide}
                                        onChange={columnsToHideHandler}
                                        styles={optionsDropdownStyles}
                                        placeholder={t('Select a column')}
                                        label={t('Hide columns')}
                                        multiSelect
                                        options={hideColumnOptions}
                                    />
                                    <Dropdown
                                        selectedKey={screenSize ? screenSize.key : undefined}
                                        label={t('Screen size')}
                                        options={screenSizeOptions}
                                        onChange={screenSizeHandler}
                                        styles={screenSizeDropdownStyles}
                                    />
                                    <Toggle
                                        disabled={!isScreensFetched}
                                        checked={showScreensByDate}
                                        onChange={switchHandler}
                                        label={t('Show screens by date')}
                                        styles={switchStyles}
                                    />
                                </Callout>
                            )}
                        </DatePickersWrapper>
                        <SensorsScreensList />
                    </ListContainer>
                </StyledSplit>
            ) : (
                <div>{t('Location not selected')}</div>
            )}
        </ScreenshotsPageContext.Provider>
    );
});

export default ScreenshotsPage;
