import { TooltipHost } from '@fluentui/react';
import {
    ColumnWrapper,
    CircleWrapper,
    Circle,
} from '../../../instalationPointsList.styles';
import { megacountProgressMessages } from '../../../../../../../../../utils/megacountHelpers/megacountProgressMessages';

/**
 *  Компонент для отрисовки прогресс бара для датчика MEGACOUNT
 * @param {object} selectedSensor объект выбранного сенсора
 */
const MegacountProgressColumn = ({ selectedSensor }) => {
    return (
        <ColumnWrapper>
            <CircleWrapper>
                <TooltipHost
                    content={
                        selectedSensor
                            ? !selectedSensor.firstStepError
                                ? megacountProgressMessages.firstStepEnabled
                                : selectedSensor.firstStepError
                            : null
                    }
                >
                    <Circle
                        newColor={
                            selectedSensor
                                ? selectedSensor.isFetching
                                    ? 'grey'
                                    : !selectedSensor.firstStepError
                                    ? 'green'
                                    : 'red'
                                : 'grey'
                        }
                    >
                        1
                    </Circle>
                </TooltipHost>
            </CircleWrapper>
            <CircleWrapper>
                <TooltipHost
                    content={
                        selectedSensor
                            ? !selectedSensor.secondStepError
                                ? megacountProgressMessages.secondStepEnabled
                                : selectedSensor.secondStepError
                            : null
                    }
                >
                    <Circle
                        newColor={
                            selectedSensor
                                ? selectedSensor.isFetching
                                    ? 'grey'
                                    : !selectedSensor.secondStepError
                                    ? 'green'
                                    : 'red'
                                : 'grey'
                        }
                    >
                        2
                    </Circle>
                </TooltipHost>
            </CircleWrapper>
            <CircleWrapper>
                <TooltipHost
                    content={
                        selectedSensor
                            ? !selectedSensor.thirdStepError
                                ? megacountProgressMessages.thirdStepEnabled
                                : selectedSensor.thirdStepError
                            : null
                    }
                >
                    <Circle
                        newColor={
                            selectedSensor
                                ? selectedSensor.isFetching
                                    ? 'grey'
                                    : !selectedSensor.thirdStepError
                                    ? 'green'
                                    : 'red'
                                : 'grey'
                        }
                    >
                        3
                    </Circle>
                </TooltipHost>
            </CircleWrapper>
        </ColumnWrapper>
    );
};

export default MegacountProgressColumn;
