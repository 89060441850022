import { setToken } from '../../generalReducer';
import parseQuery from './parseQuery';
import deleteParamsFromString from './deleteParamsFromString';
/**
 * атентификация по токену
 * @param {function} dispatch функция для перезаписи заначений в сторе
 * @param {object} urls объект с урлами
 * @param {string} token токен для запросов
 */
const auth = (dispatch, urls, token) => {
    const query = parseQuery(window.location.search);
    if (token) {
        localStorage.setItem('xtoken', token);
    }
    const myToken = query.token ? query.token : localStorage.getItem('xtoken');

    if (!myToken || myToken === 'undefined') {
        window.location.replace(`${urls.AUTH_URL.url}?next=` + document.location.href, '_blank');
    }
    dispatch(setToken(myToken));

    window.history.pushState(null, null, deleteParamsFromString(window.location.href, ['token']));
};

export default auth;
