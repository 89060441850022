import styled from 'styled-components/macro';

export const ContentWrapper = styled.div`
    flex-grow: 1;
    background-color: rgb(250, 249, 248);
    overflow: hidden;
`;

export const AppWrapper = styled.div`
    height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;
`;
export const MessageBarWrapper = styled.div`
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 10000000;
    width: 20%;
`;
