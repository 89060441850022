import { xovisPutRequest } from '../../../../../api/xovis/xovis.api';

/**
 * Обновление agent для выгрузки
 * @param {object} sensor объект сенсора
 * @param {object} payload Тело запроса
 * @param {number} agentId Id agent
 * @returns Объект с ошибкой или нового агента
 */
const updateXovisAgentV5 = async (args) => {
    const { sensor, payload, agentId } = args;

    const updatedAgent = await xovisPutRequest({
        url: `http://${sensor.ip}:${sensor.port}/api/v5/${
            sensor.isMultiSensor ? 'multisensor' : 'singlesensor'
        }/data/push/agents/${agentId}`,
        body: payload,
        username: sensor.username,
        password: sensor.password,
    });

    if (!updatedAgent.error) {
        return updatedAgent;
    }

    return { error: 'Ann error accrued while update agent' };
};

export default updateXovisAgentV5;
