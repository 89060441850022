import axios from 'axios';
import getXovisInfo from '../../../components/countingSensors/tools/xovis/underV5Methods/getXovisInfo';
import getXovisInfoV5 from '../../../components/countingSensors/tools/xovis/v5Methods/getXovisInfoV5';

/**
 * Вспомогательная функция для получения серийного номера от сенсора, при пакетной загрузке данных
 * @param {object} singleDataPackage элемент из csv файла или csv строки
 * @returns object с ошибкой или с сериным номером и флагом о различии серийников
 */
const getXovisSerialNumberForTable = async (options) => {
    const { singleDataPackage } = options;
    let notEqualSerialNumber = false;
    let serialNumber = '';

    const info = getXovisInfo({
        sensor: {
            ip: singleDataPackage.ip,
            port: singleDataPackage.port,
            username: singleDataPackage.user,
            password: singleDataPackage.pass,
        },
    });
    const infoV5 = getXovisInfoV5({
        sensor: {
            ip: singleDataPackage.ip,
            port: singleDataPackage.port,
            username: singleDataPackage.user,
            password: singleDataPackage.pass,
        },
    });

    return axios
        .all([info, infoV5])
        .then(
            axios.spread((info, infoV5) => {
                if (!info.error) {
                    serialNumber = info.serialNumber;
                }

                if (!infoV5.error) {
                    serialNumber = infoV5.serialNumber;
                }

                if (
                    singleDataPackage.serial_number &&
                    serialNumber.toLowerCase() !==
                        singleDataPackage.serial_number.toLowerCase()
                ) {
                    notEqualSerialNumber = true;
                }

                return {
                    id: singleDataPackage.id,
                    serialNumber,
                    notEqualSerialNumber,
                };
            }),
        )
        .catch((error) => ({ id: singleDataPackage.id, error }));
};

export default getXovisSerialNumberForTable;
