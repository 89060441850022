import { ReactNode, useContext, useEffect, useState } from 'react';
import { TasksMonitoringContext } from '../TasksMonitoring/TasksMonitoring.context';
import { IExtendedSensor } from '../../interfaces';
import { cloneDeep } from 'lodash';
import { FilterWrapper, PlSensorsWrapper } from './PlSensors.styles.';
import FilterInput from '../FilterInput/FilterInput';
import List from '../List/List';
import { plSensorsColumns } from './PlSensors.columns';
import { IColumn } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import ModalCreator from '../ModalCreator/ModalCreator';
import { useTranslation } from 'react-i18next';
import CurrentSensorTasks from './components/CurrentSensorsTasks/CurrentSensorsTasks';
import TimeIntervalSuccessTasks from '../TimeIntervalSuccessTasks/TimeIntervalSuccessTasks';

/**
 * Компонент для отображения состояния задач реэкспорта для конкретной локации
 */
const PlSensors = () => {
    const { extendedSensorsByPlId, selectedPlId } = useContext(TasksMonitoringContext);
    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
    const [filteredPlSensors, setFilteredPlSensors] = useState<IExtendedSensor[]>([]);
    const [plSensors, setPlSensors] = useState<IExtendedSensor[]>([]);
    const [selectedSensor, setSelectedSensor] = useState<IExtendedSensor | null>(null);
    const { t } = useTranslation();

    useEffect(() => {
        selectedPlId &&
            setPlSensors(
                Object.keys(extendedSensorsByPlId[selectedPlId]).map(
                    (sensorKey) => extendedSensorsByPlId[selectedPlId][sensorKey],
                ),
            );
    }, [selectedPlId, extendedSensorsByPlId]);

    useEffect(() => {
        plSensors.length && setFilteredPlSensors(cloneDeep(plSensors));
    }, [plSensors]);

    const updateFilteredPlSensors = (plSensors: object[]) => {
        setFilteredPlSensors(plSensors as IExtendedSensor[]);
    };

    const onRowClick = (item: IExtendedSensor) => {
        showModal();
        setSelectedSensor(item);
    };

    const onRenderItemColumn = (item: IExtendedSensor, index?: number, column?: IColumn): ReactNode => {
        if (!column?.fieldName) return null;
        const fieldContent = item[column.fieldName as keyof IExtendedSensor];
        switch (column.key) {
            case 'timeIntervalSuccessTasks':
                return (
                    <TimeIntervalSuccessTasks
                        processedTasks={item.timeIntervalProcessedTasks}
                        successTasks={item.timeIntervalSuccessTasks}
                    />
                );
            default:
                return <span>{fieldContent as string}</span>;
        }
    };

    return (
        <PlSensorsWrapper>
            <ModalCreator isModalOpen={isModalOpen} hideModal={hideModal} title={t('Current sensor tasks')}>
                <CurrentSensorTasks sensorData={selectedSensor} />
            </ModalCreator>
            <FilterWrapper>
                <FilterInput
                    inputArray={plSensors}
                    updateFilteredArray={updateFilteredPlSensors}
                    matchFields={['ip', 'port', 'type', 'user', 'pass']}
                />
            </FilterWrapper>
            <List
                onRowClick={onRowClick}
                items={filteredPlSensors}
                columns={plSensorsColumns}
                onRenderItemColumn={onRenderItemColumn}
                availableSortKeys={[
                    'ip',
                    'port',
                    'type',
                    'user',
                    'pass',
                    'timeIntervalTasks',
                    'timeIntervalSuccessTasks',
                    'timeIntervalProcessedTasks',
                ]}
            />
        </PlSensorsWrapper>
    );
};

export default PlSensors;
