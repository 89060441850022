import { cloneDeep } from 'lodash';

/**
 * Функция для частичного обнуления состояния выбранных сенсоров для обработки зафеленных периодов выгрузки
 * @param {array} selectedSensors массив выбранных сенсоров
 * @param {object} sensorsForManagementById оьъект сенсоров
 * @returns возвращает обновленный объект сенсоров
 */
const processingSensorsRefresh = (options) => {
    const { selectedSensors, sensorsForManagementById } = options;
    const copy = cloneDeep(sensorsForManagementById);

    selectedSensors.forEach((element) => {
        copy[element.id] = {
            ...element,
            dataFetching: true,
            errors: [],
        };
    });

    return copy;
};

export default processingSensorsRefresh;
