import { DefaultButton, Icon, TextField } from '@fluentui/react';
import ReactDataSheet from 'react-datasheet';
import styled from 'styled-components/macro';

export const BatchDownloadTitle = styled.div`
    font-size: 16px;
    font-weight: 600;
    margin-right: 10px;
`;

export const BatchDownloadStyledTextfield = styled(TextField)`
    width: 1000px;
`;

export const ChooseFileWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-top: 10px;
`;

export const FileName = styled.div`
    margin-left: 10px;
`;

export const BatchDownloadWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 10px;
`;

export const BatchListWrapper = styled.div`
    max-height: 500px;
    overflow: auto;
`;

export const CustomCell = styled.td`
    span {
        height: 50px;
        font-size: 14px;
        position: relative;
        padding: 5px;
        display: flex !important;
        overflow: auto;
        align-items: center;
        justify-content: flex-start;
    }
`;

export const TableWrapper = styled.div`
    margin-top: 20px;
    min-height: 150px;
    max-height: 600px;
    overflow: auto;
`;

export const CustomReactDataSheet = styled(ReactDataSheet)`
    width: 70%;
`;

export const StyledDefaultButton = styled(DefaultButton)`
    margin-right: 5px;
    width: 130px;
`;

export const TitleWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const StyledIconForBatch = styled(Icon)`
    font-size: 20px;
    cursor: pointer;
`;

export const P = styled.p`
    word-wrap: break-word;
`;

export const errorsButtonStyles = {
    root: {
        width: '240px',
        marginRight: '5px',
        border: '1px solid red',
    },
};

export const batchButtonStyles = {
    root: {
        width: '130px',
    },
};
