import { Container, ContentWrapper, InformationWrapper, ItemIndex } from './passWayAndIPointRealationTooltip.styled';

/**
 * Компонент для отображения туллтипа для взаимосвязи прохода и точки установки
 * @param {object} relationInfo информация о взаимосвязи
 * @param {number} countNumber порядковый номер взаимосвязи
 */
const PassWayAndIPointRealationTooltip = ({ relationInfo, countNumber }) => {
    return (
        <Container>
            <ItemIndex>{countNumber}.</ItemIndex>
            <ContentWrapper>
                <InformationWrapper>
                    <span>line_name: </span>
                    {relationInfo.lineName}
                </InformationWrapper>
                <InformationWrapper>
                    <span>pass_way_marker: </span>
                    {relationInfo.passWayMarker}
                </InformationWrapper>
                <InformationWrapper>
                    <span>pass_way_name: </span>
                    {relationInfo.passWayName}
                </InformationWrapper>
            </ContentWrapper>
        </Container>
    );
};

export default PassWayAndIPointRealationTooltip;
