export const services = [
    'core/admin-service',
    'core/map-service-back',
    'core/map-service-front',
    'core/schema-service-back',
    'core/schema-service-front',
    'core/structure-service',
    'core/metrics-viewer',
    'fpc/sensor-service',
    'fpc/sensor-entrypoint',
    'fpc/metric-storage',
    'fsf/metric-storage',
    'app/app-backend',
    'app/client',
];
