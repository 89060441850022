import axios from 'axios';
import getImageDimensions from '../getImageDimensions';

/**
 * Функция для получения скриншота от датчика hikvision
 * @param {object} sensor объект сенсора
 * @param {number} timeout таймаут
 * @returns error || data object
 */
const getHikvisionScreen = (sensor, timeout) => {
    return axios
        .get(
            `http://${sensor.ip}:${sensor.port}/ISAPI/Streaming/channels/${sensor.hikvisionData.channelData.id}/picture?snapShotImageType=JPEG`,
            {
                timeout,
                auth: { username: sensor.user || 'admin', password: sensor.pass },
                responseType: 'blob',
            },
        )
        .then((response) => response.data)
        .then(
            (blob) =>
                new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(blob);
                    reader.onloadend = () => {
                        return getImageDimensions(reader.result).then((dimensions) =>
                            resolve({ screen: reader.result, dimensions, sensor: { ...sensor } }),
                        );
                    };
                    reader.onerror = () => reject({ error: 'Get screen error', sensor: { ...sensor } });
                }),
        )
        .catch((error) => ({ error: error.message, sensor: { ...sensor } }));
};

export default getHikvisionScreen;
