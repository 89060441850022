import axios from 'axios';
import getInfoForVivotekBoundle from '../../../../utils/vivotekHelpers/getInfoForVivotekBoundle';
import { vivotekProgressMessages } from '../../../../utils/vivotekHelpers/vivotekProgressMessages';
/**
 * Функция для создания промиса при получении серийников от датчиков
 * @param {object} sensor объект сенсора
 * @returns промис
 */
const getVivotekSensorData = (sensor) => {
    let portForRequest = 80;
    if (sensor.port) portForRequest = sensor.port;

    const errorResponse = {
        ...sensor,
        isConfigurationFetching: false,
        firstStepError: vivotekProgressMessages.firstStepError,
    };

    const promise1 = axios.get(`http://${sensor.ip}:${portForRequest}/VCA/Config/ReportPush`, {
        timeout: 15000,
        auth: {
            username: sensor.username,
            password: sensor.password,
        },
    });
    const promise2 = axios.get(`http://${sensor.ip}:${portForRequest}/VCA/Config/RE`, {
        timeout: 15000,
        auth: {
            username: sensor.username,
            password: sensor.password,
        },
    });

    return axios
        .all([promise1, promise2])
        .then((values) => {
            const vivotekDataUploadInfo = values[0].data;
            const vivotekCountLines = values[1].data;
            if (vivotekDataUploadInfo && vivotekCountLines) {
                const res = getInfoForVivotekBoundle({ vivotekDataUploadInfo, vivotekCountLines });
                return { ...sensor, isConfigurationFetching: false, firstStepError: '', ...res };
            } else {
                return { ...errorResponse };
            }
        })
        .catch(() => ({ ...errorResponse }));
};

export default getVivotekSensorData;
