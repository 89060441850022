import { tdPostRequest } from '../../../../api/td/td.api';
import {
    showErrorNotification,
    showSuccessNotification,
} from '../../../../generalReducer';
import { updateAvailableBoundleByIPointId } from '../../../instalationPoints/installationPointsReducer';
import {
    setSensorConfigurationUpdated,
    updateSingleSensor,
} from '../../countingSensorsReducer';
import getTdSensorData from './getTdSensorData';

/**
 * Вспомогательная функция для обновления конфигурации TD сенсора
 * @param {number} iPointId id точки установки
 * @param {object} sensor объект выбранного сенсора
 * @param {object} currentBoundle объект связки
 * @param {function} dispatch диспатч в стейт
 * @param {object} payloadBody новая конфигурация сенсора
 * @param {boolean} showNotifications флаг, отвечающий за отображение оповещений
 */
const updateTdReportServer = async (args) => {
    const {
        iPointId,
        sensor,
        currentBoundle,
        dispatch,
        payloadBody,
        showNotifications = true,
    } = args;

    const errorMessage = 'Something went wrong while update report service';

    const payload = new URLSearchParams(Object.entries(payloadBody)).toString();

    const data = await tdPostRequest({
        url: `http://${sensor.ip}:${sensor.port}/save_service_config`,
        payload,
    });

    dispatch(
        updateAvailableBoundleByIPointId({
            iPointId,
            value: { ...currentBoundle, updateConfigurationFetching: false },
        }),
    );

    if (!data.error) {
        if (data?.status === 0) {
            const tdData = await getTdSensorData(sensor);

            if (!tdData.firstStepError) {
                dispatch(
                    updateSingleSensor({
                        id: sensor.id,
                        sensor: { ...sensor, ...tdData },
                        increaseFetchedSensors: false,
                    }),
                );
            } else {
                dispatch(
                    updateSingleSensor({
                        id: sensor.id,
                        sensor: {
                            ...sensor,
                            ...tdData,
                            thirdStepError: 'GET request error',
                        },
                        increaseFetchedSensors: false,
                    }),
                );
            }
            showNotifications &&
                dispatch(
                    showSuccessNotification({
                        show: true,
                        message: 'Configuration updated!',
                    }),
                );
            dispatch(setSensorConfigurationUpdated(true));
        } else {
            showNotifications &&
                dispatch(
                    showErrorNotification({
                        show: true,
                        message: errorMessage,
                    }),
                );
            return {
                error: errorMessage,
            };
        }
    } else {
        showNotifications &&
            dispatch(
                showErrorNotification({
                    show: true,
                    message: errorMessage,
                }),
            );
        return {
            error: errorMessage,
        };
    }
};

export default updateTdReportServer;
