import styled from 'styled-components/macro';

export const BatchProgressWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    padding: 5px;
    width: 100%;
    max-width: 165px;
    height: 100%;
`;

export const BatchProgressCircle = styled.div`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: ${(props) => `2px solid ${props.color}`};
`;

export const ErrorMessage = styled.div`
    font-size: 12px;
    font-weight: 400;
    color: rgb(164, 38, 44);
    text-align: center;
`;

export const InfoMessage = styled.div`
    font-size: 12px;
    font-weight: 400;
    color: rgb(161, 159, 157);
`;
