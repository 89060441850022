import { xovisGetRequest } from '../../../../../api/xovis/xovis.api';

/**
 * Получение всех агентов
 * @param {object} sensor объект сенсора
 * @param {number} timeout таймаут
 * @param {boolean} isMultisensor флаг мультисенсора
 * @returns Объект с ошибкой или объект с агентами
 */
const getXovisAgentsV5 = async (args) => {
    const { sensor, timeout, isMultisensor } = args;

    const agents = await xovisGetRequest({
        url: `http://${sensor.ip}:${sensor.port}/api/v5/${
            isMultisensor ? 'multisensor' : 'singlesensor'
        }/data/push/agents`,
        username: sensor.username,
        password: sensor.password,
        timeout,
    });

    if (!agents.error) {
        return {
            agents: agents.agents || [],
        };
    }

    return { error: 'Ann error accrued while getting agents' };
};

export default getXovisAgentsV5;
