import getCurrentDomain from '../utils/urls/getCurrentDomain';

export const getCompanyUploadUrlPart = () => {
    const currentDomain = getCurrentDomain();

    let companyUrlPart = 'UNKNOWN HOST';

    switch (true) {
        case currentDomain.includes('focustech'):
            companyUrlPart = 'focustech';
            break;
        case currentDomain.includes('mallsense'):
            companyUrlPart = 'mallsense';
            break;

        default:
            break;
    }

    return companyUrlPart;
};

export const getCompanyName = () => {
    const currentDomain = getCurrentDomain();
    let name = 'UNKNOWN HOST';

    switch (true) {
        case currentDomain.includes('focustech'):
            name = 'Focus Technologies';
            break;
        case currentDomain.includes('mallsense'):
            name = 'Mallsense';
            break;

        default:
            break;
    }

    return name;
};
