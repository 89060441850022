import { megacountPostRequest } from './megacount.api';

/**
 * Получения пользовательских настроек датчика
 * @param {string} ip IP датчика
 * @param {number} port Порт датчика
 * @param {string} username логин датчика
 * @param {string} password пароль датчика датчика
 * @param {number} timeout timeout на запрос
 * @returns Promise результатом которого является объект с данными о настойках сети или объект с ошибкой
 */
const getMegacountAccountSettings = async (args) => {
    const { ip, port, username, password, timeout } = args;

    const accountSettings = await megacountPostRequest({
        url: `http://${ip}:${port}/settings`,
        payload: {
            settings: {
                get: ['AccountSettings'],
            },
        },
        username,
        password,
        timeout,
    });

    if (!accountSettings.error) {
        return {
            accountSettings:
                accountSettings?.settings?.get?.AccountSettings || {},
        };
    } else {
        return {
            error: 'Unable to get sensor account settings',
        };
    }
};

export default getMegacountAccountSettings;
