const getCurrentDomain = () => {
    let currentDomain = window.location.host.split('.').slice(1).join('.');

    if (window.location.host === 'localhost:3000') {
        currentDomain = 'focustech.xyz';
    }
    if (window.location.host === 'localhost:3333') {
        currentDomain = 'mallsense.ae';
    }

    return currentDomain;
};

export default getCurrentDomain;
