import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import createHostsObject from '../utils/urls/createHostsObject';
import { getLocales, setHosts, setUrls } from '../generalReducer';
import createUrlsObject from '../utils/urls/createUrlsObject';

/**
 * Кастомный хук для получения локалей и создания объектов hosts и urls
 */
const useUrls = () => {
    const { locales, storeHosts } = useSelector(
        (state) => state.generalReducer,
    );
    const dispatch = useDispatch();

    /**
     * Получение локалей
     */
    useEffect(() => {
        dispatch(getLocales());
    }, [dispatch]);

    /**
     * Создание hosts
     */
    useEffect(() => {
        if (locales) {
            dispatch(setHosts(createHostsObject(locales)));
        }
    }, [dispatch, locales]);

    /**
     * Создание urls
     */
    useEffect(() => {
        if (Object.keys(storeHosts).length) {
            dispatch(setUrls(createUrlsObject(storeHosts)));
        }
    }, [dispatch, storeHosts]);
};

export default useUrls;
