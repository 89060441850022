import {
    DetailsList,
    Link,
    Sticky,
    StickyPositionType,
    Selection,
    Spinner,
    SpinnerSize,
    DetailsRow,
} from '@fluentui/react';
import { memo, useContext } from 'react';
import { ScreenshotsPageContext } from '../screenshotsPage';
import {
    SensorsScreensContainer,
    ScreensRow,
    RelationsWrapper,
    RelationTypeWrapper,
} from './sensorsScreensList.styles';
import SingleScreen from '../../../commonComponents/singleScreen';
import ListContainer from '../../../commonComponents/listContainer';
import ScreensColumn from './ScreensColumn';

/**
 * Компонент для отображения сенсоров и их скринов
 */
const SensorsScreensList = memo(() => {
    const {
        storeSelectedSensorsTrans,
        columns,
        screenSize,
        selectedLocationTimeZone,
        plStructureFetching,
        storeColumnToSortTrans,
        filteredItemsForScreensList,
        sensorRelationsById,
    } = useContext(ScreenshotsPageContext);

    const selection = new Selection({
        onSelectionChanged: () => storeSelectedSensorsTrans(selection.getSelection()),
    });

    const renderItemColumn = (item, _, column) => {
        const fieldContent = item[column.fieldName];
        switch (column.key) {
            case 'sensorAddress':
                return (
                    <ScreensRow>
                        <Link href={`http://${item.ip}:${item.port}`}>{`${item.ip}:${item.port}`}</Link>
                    </ScreensRow>
                );

            case 'sensorRelations':
                if (plStructureFetching) {
                    return (
                        <ScreensRow>
                            <Spinner sise={SpinnerSize.large} />
                        </ScreensRow>
                    );
                }

                return (
                    <ScreensRow>
                        <RelationsWrapper>
                            {Object.entries(sensorRelationsById[item.id] || {})?.map(([key, value]) => {
                                const relationsText = value.reduce((acc, relation) => {
                                    if (!acc) acc += relation.marker_and_name;
                                    else acc += `, ${relation.marker_and_name}`;
                                    return acc;
                                }, '');
                                return (
                                    <RelationTypeWrapper key={key}>
                                        <span>{key}:&nbsp;</span>
                                        <div>{relationsText}</div>
                                    </RelationTypeWrapper>
                                );
                            })}
                        </RelationsWrapper>
                    </ScreensRow>
                );

            case 'screens':
                return (
                    <ScreensColumn
                        data={item}
                        screenSize={screenSize}
                        selectedLocationTimeZone={selectedLocationTimeZone}
                    />
                );

            case 'date_from':
                if (item.screenInfoFetching) {
                    return (
                        <ScreensRow>
                            <Spinner size={SpinnerSize.large} />
                        </ScreensRow>
                    );
                }

                if (item.screenInfoByDateFrom) {
                    return (
                        <ScreensRow>
                            <SingleScreen
                                screenInfo={item.screenInfoByDateFrom}
                                sensorType={item.sensor_type}
                                size={screenSize.key}
                                selectedLocationTimeZone={selectedLocationTimeZone}
                            />
                        </ScreensRow>
                    );
                } else {
                    return <div>No screen</div>;
                }

            case 'date_to':
                if (item.screenInfoFetching) {
                    return (
                        <ScreensRow>
                            <Spinner size={SpinnerSize.large} />
                        </ScreensRow>
                    );
                }

                if (item.screenInfoByDateTo) {
                    return (
                        <ScreensRow>
                            <SingleScreen
                                screenInfo={item.screenInfoByDateTo}
                                sensorType={item.sensor_type}
                                size={screenSize.key}
                                selectedLocationTimeZone={selectedLocationTimeZone}
                            />
                        </ScreensRow>
                    );
                } else {
                    return <div>No screen</div>;
                }

            default:
                return <ScreensRow>{fieldContent}</ScreensRow>;
        }
    };

    const renderRowHandler = (props) => {
        return (
            <div>
                <DetailsRow
                    {...props}
                    styles={{
                        root: {
                            cursor: 'pointer',
                            userSelect: 'auto',
                        },
                    }}
                />
            </div>
        );
    };

    const onRenderDetailsHeader = (headerProps, defaultRender) => {
        if (!defaultRender) return null;
        return (
            <Sticky
                stickyPosition={StickyPositionType.Header}
                isScrollSynced={true}
                stickyBackgroundColor="transparent"
            >
                <div>{defaultRender(headerProps)}</div>
            </Sticky>
        );
    };

    const onColumnClick = (_, column) => storeColumnToSortTrans(column);

    return (
        <SensorsScreensContainer>
            <ListContainer>
                <DetailsList
                    items={filteredItemsForScreensList}
                    onRenderItemColumn={renderItemColumn}
                    columns={columns}
                    onRenderDetailsHeader={onRenderDetailsHeader}
                    onColumnHeaderClick={onColumnClick}
                    onRenderRow={renderRowHandler}
                    selection={selection}
                />
            </ListContainer>
        </SensorsScreensContainer>
    );
});

export default SensorsScreensList;
