import { ScrollablePane } from '@fluentui/react';
import styled from 'styled-components/macro';
export const SensorsScreensContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-grow: 1;
`;

export const filterTextFieldStyles = {
    root: {
        width: '170px',
    },
};

export const FilterWrapper = styled.div`
    display: flex;
    align-items: flex-end;
`;

export const SensorTitle = styled.div`
    font-size: 16px;
    font-weight: 600;
    margin-right: 10px;
`;

export const ScreensWrapper = styled.div`
    position: relative;
    height: ${(props) => props.size + 'px'};
    width: 100%;
`;

export const ScreensRow = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
`;

export const ScrollablePaneForScreens = styled(ScrollablePane)`
    overflow-y: hidden;
    div {
        display: flex;
        align-self: center;
    }
`;

export const ScreenWrapper = styled.div`
    margin-right: 10px;
`;

export const RelationsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

export const RelationTypeWrapper = styled.div`
    white-space: normal;
    display: flex;
    span {
        display: inline-block;
        font-weight: 600;
        white-space: nowrap;
    }
    div {
        display: inline-block;
    }
`;
