import { IButtonStyles } from '@fluentui/react';
import styled from 'styled-components/macro';

export const Header = styled.div`
    flex: 1 1 auto;
    border-top: 4px solid rgb(0, 120, 212);
    color: rgb(50, 49, 48);
    display: flex;
    font-size: 24px;
    font-weight: 600;
    align-items: center;
    justify-content: space-between;
    padding: 12px 12px 14px 24px;
`;

export const iconButtonStyles: Partial<IButtonStyles> = {
    root: {
        color: 'rgb(50, 49, 48)',
        marginLeft: '10px',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: '#201f1e',
    },
};

export const Body = styled.div`
    padding: 0px 24px 24px 24px;
    max-height: 82vh;
    display: flex;
    flex-direction: column;
`;
