import axios from 'axios';

/**
 * GET запрос для получения данных от хиквижн
 * @param {string} url адрес для запроса
 * @param {string} username имя пользователя
 * @param {string} password пароль
 * @param {number} timeout таймаут
 * @returns data || error
 */
export const hikvisionGetRequest = (options) => {
    const { url, username, password, timeout } = options;
    return axios
        .get(url, {
            timeout,
            auth: {
                username,
                password,
            },
        })
        .then((response) => response.data)
        .then((data) => ({ data }))
        .catch((error) => ({ error: error.message }));
};

/**
 * POST запрос хиквижн
 * @param {string} url адрес для запроса
 * @param {string} username имя пользователя
 * @param {string} password пароль
 * @param {number} timeout таймаут
 * @param {object} payload тело запроса
 * @param {object} headers хеддеры запроса
 * @returns data || error
 */
export const hikvisionPostRequest = (options) => {
    const { url, username, password, timeout, payload, extraArgs, headers = {} } = options;
    return axios
        .post(url, payload, {
            timeout,
            auth: {
                username,
                password,
            },
            headers,
        })
        .then((response) => response.data)
        .then((data) => ({ data, extraArgs }))
        .catch((error) => ({ error: error.message, extraArgs }));
};
