import axios from 'axios';
import { DateTime } from 'luxon';
import dateIntersection from '../dateHandlers/dateIntersection';
import { getHeaders } from '../getHeaders';

/**
 * Вспомогательная функция для создания промиса по добавлению связки
 * @param {string} dateFrom дата от которой создать связку
 * @param {string} dateTo дата по которую данная связка активна
 * @param {string} selectedLocationTimeZone тайм зона с бэка
 * @param {number} sensorId id сенсора
 * @param {number} iPointId id точки установки
 * @param {array} sensorsAndPointsBounles массив всех точек установок
 * @param {object} storeUrls объект со всеми урлами
 * @param {string} token токен авторизации
 * @param {string} frontId id для фронта
 * @returns промис для создания связки
 */
const postIPointHelper = (options) => {
    const {
        dateFrom,
        dateTo,
        selectedLocationTimeZone,
        sensorId,
        iPointId,
        sensorsAndPointsBounles,
        storeUrls,
        token,
        frontId,
    } = options;

    const formatedDateFrom = DateTime.fromISO(dateFrom).toFormat('yyyy-MM-dd');
    const formatedDateTo = dateTo ? DateTime.fromISO(dateTo).toFormat('yyyy-MM-dd') : null;

    const date_from = DateTime.fromISO(`${formatedDateFrom}`, { zone: selectedLocationTimeZone }).toISO({
        suppressMilliseconds: true,
    });

    const date_to = dateTo
        ? DateTime.fromISO(`${formatedDateTo}`, { zone: selectedLocationTimeZone }).toISO({
              suppressMilliseconds: true,
          })
        : null;

    const payloadBody = {
        sensor_id: sensorId,
        installation_point_id: iPointId,
        date_from,
        date_to,
    };

    const isDateIntesection = dateIntersection({
        sensorsAndPointsBounles: sensorsAndPointsBounles.filter(
            (element) => element.installation_point_id === iPointId,
        ),
        dateFrom: date_from,
        dateTo: date_to,
    });

    if (isDateIntesection) {
        return { error: 'Invalid date interval' };
    } else {
        return axios
            .post(storeUrls.SENSORS_TO_IPOINTS.url, payloadBody, {
                timeout: 10000,
                headers: getHeaders(token),
            })
            .then((res) => res.data)
            .then((res) => ({ frontId, ...res }))
            .catch((error) => ({ frontId, error }));
    }
};

export default postIPointHelper;
