import getCurrentDomain from '../utils/urls/getCurrentDomain';
import { sensorTypes } from './sensorTypes';

export const getSensorUploadUrls = () => {
    const currentDomain = getCurrentDomain();

    let fpc = '';
    let fpct = '';
    let fpcn = '';
    let fpcng = '';

    switch (true) {
        case currentDomain.includes('focustech'):
            fpc = 'fpc-ru.focustech.xyz';
            fpct = 'fpct-ru.focustech.xyz';
            fpcn = 'fpcn-ru.focustech.xyz';
            fpcng = 'fpc-ng.focustech.xyz';
            break;
        case currentDomain.includes('mallsense'):
            fpc = 'fpc-in.mallsense.ae';
            fpct = 'fpc-in.mallsense.ae';
            fpcn = 'fpcn-in.mallsense.ae';
            fpcng = 'fpcn-in.mallsense.ae';
            break;

        default:
            break;
    }

    const megacount = {
        protocol: 'https',
        host: fpct,
    };
    const td = {
        protocol: 'https',
        host: fpct,
    };
    const xovis = {
        protocol: 'https',
        host: fpcng,
    };
    const vivotek = {
        protocol: 'https',
        host: fpcng,
    };
    const hikvision = {
        protocol: 'http',
        host: 'fpch-ng.focustech.xyz',
    };
    const brickstream = {
        protocol: 'https',
        host: 'fpct-ng.focustech.xyz',
        port: 7443,
    };

    return {
        fpc,
        fpct,
        fpcn,
        fpcng,
        fpcUrl: `https://${fpc}/fpc/v1`,
        fpctUrl: `https://${fpct}/fpc/v1`,
        fpcnUrl: `http://${fpcn}/fpc/v1`,
        fpcngUrl: `http://${fpcng}/fpc/v1`,
        sensors: {
            [sensorTypes.XOVIS]: xovis,
            [sensorTypes.VIVOTEK]: vivotek,
            [sensorTypes.HIKVISION]: hikvision,
            [sensorTypes.BRICKSTREAM]: brickstream,
            [sensorTypes.MEGACOUNT]: megacount,
            [sensorTypes.TD]: td,
        },
        default: {
            protocol: 'https',
            host: fpct,
        },
    };
};
