import { cloneDeep } from 'lodash';
import vivotekWithDateFieldsRequestHelper from './vivotekWithDateFieldsRequestHelper';
import { getCompanyName } from '../../../../constants/constants';

/**
 * Функция для обработки ошибочных интервалов вивотек
 * @param {object} sensor объект сенсора
 * @param {array} failedIntervals все ошибочные интерваалы
 * @param {number} timeout таймаут
 * @returns возвращает обновленный объеккт сенсора
 */
const processVivotekFailedIntervals = async (options) => {
    const { sensor, failedIntervals, timeout } = options;
    let sensorCopy = cloneDeep(sensor);

    for (let interval of failedIntervals) {
        const data = await vivotekWithDateFieldsRequestHelper({
            sensor: sensorCopy,
            dateFromInMillis: interval.dateFrom,
            dateToInMillis: interval.dateTo,
            timeout,
        });

        if (data.error !== `No ${getCompanyName()} url`) {
            sensorCopy = { ...data.sensor };

            sensorCopy.orderCount -= 1;
            if (data.error) {
                sensorCopy.errors.push(data.error);
            }
        } else {
            sensorCopy = { ...data.sensor };
            sensorCopy.errors.push(data.error);
        }
    }

    return { sensor: { ...sensorCopy } };
};

export default processVivotekFailedIntervals;
