import styled from 'styled-components/macro';

export const ListWrapper = styled.div`
    position: relative;
    margin-top: 10px;
    width: 100%;
    height: 100%;
    flex-grow: 1;
    .ms-DetailsHeader {
        padding: 0px;
    }
`;

export const rowCustomStyles = {
    root: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
    },
    cell: {
        fontSize: '14px',
        display: 'flex',
        alignItems: 'center',
        minHeight: '50px',
    },
};

export const evenRowCustomStyles = {
    root: {
        ...rowCustomStyles.root,
        backgroundColor: '#c7e0f4',
    },
    cell: {
        ...rowCustomStyles.cell,
    },
};
