/**
 * Функция для генерации массива опций для селекта
 * @param {object} sensorTypes объект с типами сенсоров
 * @returns массив с опциями для dropDown
 */
const dropDownOptionsGenerator = (sensorTypes) => {
    const options = Object.keys(sensorTypes).map((element) => ({
        key: element,
        text: sensorTypes[element],
    }));

    return options;
};

export default dropDownOptionsGenerator;
