import { memo } from 'react';

import { ScreenDataWrapper, DateText, StyledScreen } from './singleScreen.styles';
import { DateTime } from 'luxon';
import { sensorTypes } from '../constants/sensorTypes';

/**
 * Компонент для отображения одного скрина
 * @param {object} screenInfo объект с информацией о сенсоре
 * @param {number} size размер скрина
 * @param {string} selectedLocationTimeZone тайм зона локации
 * @param {string} sensorType тип датчика
 */
const SingleScreen = memo(({ screenInfo, size, selectedLocationTimeZone, sensorType }) => {
    return (
        <ScreenDataWrapper>
            <StyledScreen src={screenInfo.sensor_screen} alt="screen" size={size} />
            {!(sensorType === sensorTypes.HIKVISION) && (
                <DateText>
                    {DateTime.fromISO(screenInfo.sensor_info.date_from)
                        .setZone(selectedLocationTimeZone)
                        .toFormat('yyyy-MM-dd HH:mm:ss')}
                </DateText>
            )}
        </ScreenDataWrapper>
    );
});

export default SingleScreen;
