import { createContext } from 'react';
import { IProject, IPlTask, IExtendedPlTask, IExtendedSensorsByPlId } from '../../interfaces';

interface ITasksMonitoringContext {
    projectTasksByProjectId: { [x: number]: IProject };
    tasksFetching: boolean;
    storeSelectedProjectIdTrans?: (value: number | null) => void;
    fetchReexportTasksTrans?: () => void;
    tasksFetchingError: string;
    token: string;
    plTasksInitialData: IPlTask[];
    extendedPlTasksByProjectId: {
        [x: number]: {
            [x: number]: IExtendedPlTask;
        };
    };
    selectedProjectId: number | null;
    extendedSensorsByPlId: IExtendedSensorsByPlId;
    storeSelectedPlIdTrans?: (value: number | null) => void;
    selectedPlId: number | null;
    tasksDateFrom: string;
    tasksDateTo: string;
    storeTasksDateFromTrans?: (value: string) => void;
    storeTasksDateToTrans?: (value: string) => void;
}

export const TasksMonitoringContext = createContext<ITasksMonitoringContext>({
    projectTasksByProjectId: {},
    tasksFetching: false,
    tasksFetchingError: '',
    token: '',
    plTasksInitialData: [],
    extendedPlTasksByProjectId: {},
    selectedProjectId: null,
    extendedSensorsByPlId: {},
    selectedPlId: null,
    tasksDateFrom: '',
    tasksDateTo: '',
});
