import { createContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';
import focusLogo from '../../assets/img/focusLogo.svg';
import { sizes } from '../../constants/sizes';
import { setLanguage, signOutThunk } from '../../generalReducer';
import Language from './components/language';
import {
    ContentWrapper,
    LanguageWrapper,
    LocationName,
    Logo,
    NavWrapper,
    signOutStyles,
    CalloutContentWrapper,
    CalloutTextWrapper,
    CalloutTextItem,
    CustomTextArea,
} from './header.styles';
import styles from './navLink.module.css';
import { Callout, IconButton, Link, TooltipHost } from '@fluentui/react';
import { useId, useBoolean } from '@fluentui/react-hooks';
/**
 * компонент для отображения хедера
 */
export const HeaderContext = createContext({});

const Header = () => {
    const { language, token } = useSelector((state) => state.generalReducer);
    const { selectedProjectId } = useSelector((state) => state.projectsReducer);
    const { mapServiceLink, selectedLocationName, locationId } = useSelector((state) => state.instalationPointsReducer);
    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    const sensorsManagementCalloutId = useId('sensorsManagementCalloutId');
    const { t } = useTranslation();
    const copyRef = useRef();

    const dispatch = useDispatch();
    const nav = useNavigate();

    const setLanguageTrans = () => {
        dispatch(setLanguage());
    };

    const returnHome = () => {
        nav('/');
    };

    const signOutHandler = () => dispatch(signOutThunk());

    const onSensorManagementRightClick = (e) => {
        if (document.location.pathname !== '/sensors-management' && token) {
            e.preventDefault();
            toggleIsCalloutVisible();
        }
    };

    const copyPathHandler = () => {
        const host = window.location.origin;
        const copyValue = `${host}/sensors-management?token=${token}`;
        copyRef.current.value = copyValue;
        copyRef.current.focus();
        copyRef.current.select();
        try {
            document.execCommand('copy');
        } catch (error) {
            console.log(error);
        }
    };

    const headerContextValue = { language, setLanguageTrans };

    return (
        <HeaderContext.Provider value={headerContextValue}>
            <ContentWrapper customHeight={sizes.HEADER_HEIGHT}>
                <CustomTextArea ref={copyRef} />
                <NavWrapper>
                    <Logo onClick={returnHome} src={focusLogo} alt='logo' />
                    <NavLink exact className={styles.link} activeClassName={styles.link__active} to='/'>
                        {t('Projects')}
                    </NavLink>
                    {selectedProjectId ? (
                        <NavLink
                            exact
                            className={styles.link}
                            activeClassName={styles.link__active}
                            to={`/projects/${selectedProjectId}`}
                        >
                            {t('Project Locations')}
                        </NavLink>
                    ) : null}
                    {mapServiceLink ? (
                        <NavLink
                            exact
                            className={styles.link}
                            activeClassName={styles.link__active}
                            to={`/projects/${selectedProjectId}/project-locations/${locationId}/installation-points`}
                        >
                            {t('Installation points')}
                        </NavLink>
                    ) : null}
                    {mapServiceLink ? (
                        <NavLink
                            exact
                            className={styles.link}
                            activeClassName={styles.link__active}
                            to={`/projects/${selectedProjectId}/project-locations/${locationId}/sensors-screenshots`}
                        >
                            {t('Screenshots')}
                        </NavLink>
                    ) : null}
                </NavWrapper>
                <LocationName>{selectedLocationName}</LocationName>
                <LanguageWrapper>
                    <NavLink exact className={styles.link} activeClassName={styles.link__active} to='/tasks-monitoring'>
                        {t('Tasks monitoring')}
                    </NavLink>
                    <NavLink
                        onContextMenu={onSensorManagementRightClick}
                        id={sensorsManagementCalloutId}
                        exact
                        className={styles.link}
                        activeClassName={styles.link__active}
                        to='/sensors-management'
                    >
                        {t('Sensors management')}
                    </NavLink>
                    <Language />
                    <TooltipHost content={t('Sign out')}>
                        <IconButton
                            onClick={signOutHandler}
                            styles={signOutStyles}
                            iconProps={{ iconName: 'SignOut' }}
                        />
                    </TooltipHost>
                    {isCalloutVisible && (
                        <Callout onDismiss={toggleIsCalloutVisible} target={`#${sensorsManagementCalloutId}`}>
                            <CalloutContentWrapper>
                                <CalloutTextWrapper>
                                    <CalloutTextItem>
                                        <span>With token:</span>
                                    </CalloutTextItem>
                                    <CalloutTextItem>
                                        <Link onClick={copyPathHandler}>copy path</Link>
                                    </CalloutTextItem>
                                </CalloutTextWrapper>
                            </CalloutContentWrapper>
                        </Callout>
                    )}
                </LanguageWrapper>
            </ContentWrapper>
        </HeaderContext.Provider>
    );
};

export default Header;
