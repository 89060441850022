import { IProject, IPlTask } from '../../interfaces';

/**
 * Процдура для обновления проекта новыми даннми (задачами)
 * @param project объект проекта, который нужно изменить
 * @param value значение, данными которого нужно обновить проект
 */
const updateProjectTimeIntervalTasks = (project: IProject, value: IPlTask): void => {
    project.timeIntervalTasks += value.sensor_tasks.length;
    if (value.processed_tasks) {
        project.timeIntervalProcessedTasks += value.processed_tasks.length;
        project.timeIntervalSuccessTasks += value.processed_tasks.filter((task) => task.status === 'success').length;
    }
};

export default updateProjectTimeIntervalTasks;
