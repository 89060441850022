import { DateTime } from 'luxon';

/**
 *  Функция для получения кол-ва днй ммежду двумя датами
 * @param {string} startDate
 * @param {string} endDate
 * @returns кол-во дней
 */
const numDaysBetweenDates = (startDate, endDate) => {
    const start = DateTime.fromISO(startDate).startOf('day');
    const end = DateTime.fromISO(endDate).startOf('day').plus({ days: 1 });
    return end.diff(start, 'days').toObject().days;
};

export default numDaysBetweenDates;
