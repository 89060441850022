import { DefaultButton, Icon, MaskedTextField } from '@fluentui/react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import dateFormater from '../../../utils/dateHandlers/dateFormater';
import { SensorsManagementContext } from '../sensorsManagement';
import { portionDropdownOptions } from '../tools/dropdownOptions';
import {
    DataUploadPickersWrapper,
    datePickersStyles,
    DatetimePickersWrapper,
    portionTypeDropdownStyles,
    RemoveIconStyles,
    StyledDateFrom,
    StyledDateTo,
    StyledPortionTypeDropdown,
    stylesForTimeField,
} from './dataUploadPickers.styles';

/**
 * Компонент для отображения различных ипутов, селекторов для заказа выгрузки с датчиков
 */
const DataUploadPickers = () => {
    const {
        portionType,
        storePortionTypeTrans,
        dateTo,
        dateFrom,
        timeTo,
        timeFrom,
        storeDateFromTrans,
        storeDateToTrans,
        storeTimeToTrans,
        storeTimeFromTrans,
        toggleTimeFieldsErrorTrans,
        selectedSensors,
        processFailedIntervalsThunkTrans,
    } = useContext(SensorsManagementContext);
    const timeRegExp = /(?:[01]\d|2[0-3]):(?:[0-5]\d):(?:[0-5]\d)/;

    const { t } = useTranslation();

    const timeFieldValidator = (value) => {
        if (!timeRegExp.test(value)) {
            toggleTimeFieldsErrorTrans(true);
            return 'Wrong format';
        } else {
            toggleTimeFieldsErrorTrans(false);
            return undefined;
        }
    };

    const dateFromHandler = (value) => {
        const date = dateFormater(value);
        storeDateFromTrans(date);
    };

    const dateToHandler = (value) => {
        const date = dateFormater(value);
        storeDateToTrans(date);
    };

    const removeDateHandler = (type) => () => {
        switch (type) {
            case 'to':
                storeDateToTrans(undefined);
                break;
            case 'from':
                storeDateFromTrans(undefined);
                break;

            default:
                console.log('invalid type: ' + type);
                break;
        }
    };

    const timeToHandler = (_, value) => storeTimeToTrans(value);
    const timeFromHandler = (_, value) => storeTimeFromTrans(value);
    const processFailedIntervals = () => processFailedIntervalsThunkTrans();

    return (
        <DataUploadPickersWrapper>
            <StyledPortionTypeDropdown
                styles={portionTypeDropdownStyles}
                selectedKey={portionType ? portionType.key : undefined}
                onChange={storePortionTypeTrans}
                options={portionDropdownOptions}
            />

            <DatetimePickersWrapper>
                <StyledDateFrom
                    onSelectDate={dateFromHandler}
                    placeholder={t('Select a date...')}
                    value={dateFrom ? new Date(dateFrom) : undefined}
                    formatDate={(date) => dateFormater(date)}
                    styles={datePickersStyles}
                />
                <MaskedTextField
                    onGetErrorMessage={timeFieldValidator}
                    onChange={timeFromHandler}
                    mask='99:99:99'
                    styles={stylesForTimeField}
                    value={timeFrom}
                    disabled={!dateFrom}
                />
                <Icon onClick={removeDateHandler('from')} styles={RemoveIconStyles} iconName='RemoveEvent' />
            </DatetimePickersWrapper>
            <DatetimePickersWrapper>
                <StyledDateTo
                    styles={datePickersStyles}
                    onSelectDate={dateToHandler}
                    placeholder={t('Select a date...')}
                    value={dateTo ? new Date(dateTo) : undefined}
                    formatDate={(date) => dateFormater(date)}
                />
                <MaskedTextField
                    onGetErrorMessage={timeFieldValidator}
                    onChange={timeToHandler}
                    mask='99:99:99'
                    styles={stylesForTimeField}
                    value={timeTo}
                    disabled={!dateTo}
                />
                <Icon onClick={removeDateHandler('to')} styles={RemoveIconStyles} iconName='RemoveEvent' />
            </DatetimePickersWrapper>
            <DefaultButton
                onClick={processFailedIntervals}
                disabled={selectedSensors.length === 0}
                text={t('Process failed intervals')}
            />
        </DataUploadPickersWrapper>
    );
};

export default DataUploadPickers;
