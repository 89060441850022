import { xovisGetRequest } from '../../../../../api/xovis/xovis.api';

/**
 * Получение информации о мультисенсоре
 * @param {object} sensor объект сенсора
 * @returns объект с ошибкой или информацию о мультисенсоре
 */
const getXovisMultisensorInfoV5 = async (args) => {
    const { sensor } = args;

    const multisensorInfo = await xovisGetRequest({
        url: `http://${sensor.ip}:${sensor.port}/api/v5/multisensor`,
        username: sensor.username,
        password: sensor.password,
    });

    if (!multisensorInfo.error) {
        return {
            isMultiSensor: multisensorInfo.enabled || false,
        };
    }

    return { error: 'Ann error accrued while getting multisensor info' };
};

export default getXovisMultisensorInfoV5;
