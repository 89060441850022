import { vivotekPostRequest } from '../../../../api/vivotek/vivotek.api';
import {
    showErrorNotification,
    showSuccessNotification,
} from '../../../../generalReducer';
import { updateAvailableBoundleByIPointId } from '../../../instalationPoints/installationPointsReducer';
import { updateSingleSensor } from '../../countingSensorsReducer';
import getVivotekSensorData from './getVivotekSensorData';

/**
 * Добавление / изменение datapush сервера для датчика vivotek
 * @param {object} sensor объект выбранного сенсора
 * @param {function} dispatch диспатч в стейт
 * @param {string} payloadBody тело запроса
 * @param {object} currentBundle объект текущей связки (активной)
 * @param {number} iPointId id точки установки
 * @param {boolean} showNotifications флаг, отвечающий за отображение оповещений
 */
const updateVivotekDataPushServer = async ({
    dispatch,
    sensor,
    payloadBody,
    currentBundle,
    iPointId,
    showNotifications = true,
}) => {
    const uploadIndex =
        sensor.vivotekData?.focustechUploadUrlIndex ||
        Object.keys(sensor.vivotekData?.reportPushServers)?.length;

    const data = await vivotekPostRequest({
        url: `http://${sensor.ip}:${sensor.port}/VCA/Config/ReportPush?PUT&${uploadIndex}`,
        username: sensor.username,
        password: sensor.password,
        data: payloadBody,
    });

    dispatch(
        updateAvailableBoundleByIPointId({
            iPointId,
            value: { ...currentBundle, updateConfigurationFetching: false },
        }),
    );

    if (!data.error) {
        const res = await getVivotekSensorData(sensor);
        dispatch(
            updateSingleSensor({
                id: sensor.id,
                sensor: { ...sensor, ...res },
                increaseFetchedSensors: false,
            }),
        );
        showNotifications &&
            dispatch(
                showSuccessNotification({
                    show: true,
                    message: 'Configuration updated!',
                }),
            );
    } else {
        showNotifications &&
            dispatch(
                showErrorNotification({
                    show: true,
                    message: 'Something went wrong',
                }),
            );

        return { error: JSON.stringify(data?.error) || 'Something went wrong' };
    }
};

export default updateVivotekDataPushServer;
