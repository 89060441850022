import axios from 'axios';
import getTdNetworkConfig from './getTdNetworkConfig';
import getTdServiceConfig from './getTdServiceConfig';
import getTdLayers from './getTdLayers';

/**
 * Promise. Функция для получения информация о датчике td
 * @param {object} sensor объект сенсора
 * @param {number} timeout таймаут запроса
 * @param {boolean} getLayers флаг. отвечающий за получение слоев
 * @returns возвращает обновленный объект сенсора с дополнительными полями
 */
const getTdSensorData = (sensor, timeout, getLayers = false) => {
    const networkConfig = getTdNetworkConfig({
        ip: sensor.ip,
        port: sensor.port,
        timeout,
    });

    const serviceConfig = getTdServiceConfig({
        ip: sensor.ip,
        port: sensor.port,
        timeout,
    });

    const promises = [networkConfig, serviceConfig];

    if (getLayers) {
        promises.push(
            getTdLayers({ ip: sensor.ip, port: sensor.port, timeout }),
        );
    }

    return axios
        .all(promises)
        .then(
            axios.spread((networkConfig, serviceConfig, layers) => {
                let error = '';
                const tdData = {
                    networkConfig: {},
                    serviceConfig: {},
                    layers: {
                        passWays: [],
                        zones: [],
                        floors: [],
                        exceptions: [],
                        masks: [],
                    },
                };

                if (!networkConfig.error) {
                    tdData.networkConfig = networkConfig;
                } else {
                    error = 'An error accrued while getting network config';
                }

                if (!serviceConfig.error) {
                    tdData.serviceConfig = serviceConfig;
                } else {
                    error += ` ${(error =
                        'An error accrued while getting service config')}`;
                }

                if (!layers?.error) {
                    tdData.layers = { ...tdData.layers, ...layers };
                } else {
                    error += ` ${(error =
                        'An error accrued while getting TD layers')}`;
                }

                return {
                    sensor,
                    tdData,
                    error,
                };
            }),
        )
        .catch((_) => ({ error: 'Sensor is offline', sensor }));
};

export default getTdSensorData;
