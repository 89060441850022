import { chunk } from 'lodash';

/**
 * Функция для получения координат слоя
 * @param {html} htmlData Данные, которые нужно проанализировать и получить координаты элемента
 * @param {string} elementId Селектор элемента, у которого можно получить координаты
 * @returns null | [number, number][] None если не удалось получить координаты или двумерный массив с координатами
 */
const getLayerCoords = (htmlData, elementId) => {
    const coords = htmlData.querySelector(`#${elementId}`)?.value;

    if (coords) {
        const coordsArray = coords.split(' ').map((element) => Number(element));

        const result = chunk(coordsArray, 2);

        if (result?.length) {
            result.push(result[0]);
        }

        if (result && result.length) {
            return result;
        }
    }
    return null;
};

export default getLayerCoords;
