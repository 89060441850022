import { memo, useState } from 'react';
import FloorsButtons from './components/floorsButtons';
import {
    FloorSideBarWrapper,
    CustomMapLayersIcon,
    MapLayersIconWrapper,
    WindowWithOptionsWrapper,
} from './floorsSidePanel.styles';
import { sizes } from '../../constants/sizes';
import { createContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    setCurrentFloorInfo,
    setSelectedFloor,
    setShowIPointLabel,
    setShowIPointLayer,
} from '../instalationPoints/installationPointsReducer';
import WindowWithOptions from './components/windowWithOptions';
import { setShowPassWayLabel, setShowPassWayLayer } from '../passWays/passWaysReducer';
import ModalCreator from '../modals/modalCreator';
import PassPointsAndPassWaysIntersect from '../modals/passPointsAndPassWaysIntersect/passPointsAndPassWaysIntersect';
import { TooltipHost } from '@fluentui/react';
import { setShowIntersectionsLayer } from '../intersectionsForDisplay/intersectionsForDisplayReducer';
import useFloorChange from './tools/useFloorChange';
/**
 * Компонент для отрисовки бокового меню со стороны локации
 */
export const FloorSidePanelContext = createContext({});
const FloorSidePanel = memo(() => {
    const { locationInfo, selectedFloor, plansByFloorNumber, currentFloorInfo } = useSelector(
        (state) => state.instalationPointsReducer
    );
    const [showWindowWithOptions, setShowWindowWithOptions] = useState(false);
    const [openIntersectionsModal, setIntersectionsModalOpen] = useState(false);
    const dispatch = useDispatch();
    useFloorChange();

    const openIntersectionModalHandler = () => setIntersectionsModalOpen(true);
    const closeIntersectionModalHandler = () => setIntersectionsModalOpen(false);

    const mapLayersIconHandler = () => setShowWindowWithOptions((prevState) => !prevState);

    const setSelectedFloorTrans = (selectedFloor) => {
        dispatch(setSelectedFloor(selectedFloor));
    };

    const setCurrentFloorInfoTrans = (currentFloorInfo) => {
        dispatch(setCurrentFloorInfo(currentFloorInfo));
    };

    const setShowPassWayLabelTrans = (value) => {
        dispatch(setShowPassWayLabel(value));
    };

    const setShowIPointLabelTrans = (value) => {
        dispatch(setShowIPointLabel(value));
    };

    const setShowPassWayLayerTrans = (value) => {
        dispatch(setShowPassWayLayer(value));
    };

    const setShowIPointLayerTrans = (value) => {
        dispatch(setShowIPointLayer(value));
    };

    const setShowIntersectionsLayerTrans = (value) => {
        dispatch(setShowIntersectionsLayer(value));
    };

    const floorSidePanelContextValue = {
        locationInfo,
        selectedFloor,
        currentFloorInfo,
        plansByFloorNumber,
        setSelectedFloorTrans,
        setCurrentFloorInfoTrans,
        setShowPassWayLabelTrans,
        setShowIPointLabelTrans,
        setShowPassWayLayerTrans,
        setShowIPointLayerTrans,
        setShowIntersectionsLayerTrans,
    };

    return (
        <>
            <ModalCreator isModalOpen={openIntersectionsModal} closeModal={closeIntersectionModalHandler}>
                <PassPointsAndPassWaysIntersect />
            </ModalCreator>
            <FloorSidePanelContext.Provider value={floorSidePanelContextValue}>
                <FloorSideBarWrapper mySize={sizes.FLOOR_SIDE_PANEL}>
                    <FloorsButtons />
                    <TooltipHost content={'Options'}>
                        <MapLayersIconWrapper onClick={mapLayersIconHandler}>
                            <CustomMapLayersIcon iconName={'MapLayers'} />
                        </MapLayersIconWrapper>
                    </TooltipHost>
                    <WindowWithOptionsWrapper show={showWindowWithOptions}>
                        <WindowWithOptions openIntersectionModalHandler={openIntersectionModalHandler} />
                    </WindowWithOptionsWrapper>
                </FloorSideBarWrapper>
            </FloorSidePanelContext.Provider>
        </>
    );
});

export default FloorSidePanel;
