export const megacountProgressMessages = {
    firstStepEnabled: 'Sensor is online',
    firstStepError: 'Sensor is offline',
    thirdStepErrorNoCompanyServer:
        'There is no url of the company on the sensor',
    thirdStepErrorWithNoMatchUrls:
        'The actual company url does not match the url in the sensor configuration',
    thirdStepErrorWithDeliveryProtocol: 'Wrong delivery protocol',
    thirdStepInvalidDataInterval: 'Invalid data interval',
    thirdStepInvalidSendFreq: 'Invalid send freq',
    thirdStepEnabled: 'The url for uploading data from the sensor is relevant',
    thirdStepInvalidFileType: 'Invalid file type',
    secondStepNoCountLines: 'There are no counting lines on this sensor',
    secondStepEnabled: 'There are counting lines on the sensor',
    secondStepError:
        'The names of the counting lines on the sensor do not match the names from the map service',
};
