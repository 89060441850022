import { cloneDeep } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import FilterInput from '../../../../commonComponents/FilterInput/FilterInput';
import List from '../../../../commonComponents/List/List';
import { ProjectLocationsContext } from '../../ProjectLocations';
import { projectLocationsListColumns } from './ProjectLocationsList.columns';
import { FilterWrapper } from './ProjectLocationsList.styles';

/**
 * Компонент для отображения списка проектных локаций
 */
const ProjectLocationsList = () => {
    const { projectLocations, storeSelectedPLIdTrans, toggleRedirectingTrans } = useContext(ProjectLocationsContext);
    const [filteredProjectLocations, setFilteredProjectLocations] = useState([]);

    useEffect(() => {
        projectLocations.length && setFilteredProjectLocations(cloneDeep(projectLocations));
    }, [projectLocations]);

    const onRowClick = (row) => {
        storeSelectedPLIdTrans(row.id);
        toggleRedirectingTrans(true);
    };

    return (
        <>
            <FilterWrapper>
                <FilterInput
                    inputArray={projectLocations}
                    updateFilteredArray={setFilteredProjectLocations}
                    matchFields={['name', 'id', 'date_from', 'date_to', 'latitude', 'longitude']}
                />
            </FilterWrapper>
            <List
                onRowClick={onRowClick}
                items={filteredProjectLocations}
                columns={projectLocationsListColumns}
                availableSortKeys={['name', 'id', 'date_from', 'date_to', 'latitude', 'longitude']}
            />
        </>
    );
};

export default ProjectLocationsList;
