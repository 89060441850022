import { cloneDeep } from 'lodash';
import { sensorTypes } from '../../../constants/sensorTypes';
import getVivotekSerialNumber from './vivotek/getVivotekSerialNumber';

/**
 * Функция для получения серийников отвсех типов сенсоров
 * @param {object} sensorsForManagementById объект всех сенсоров, где ключ это id сенсора
 * @param {array} selectedSensors массив выбранных сенсоров
 * @param {number} timeoutInMillis таймаут
 * @returns обновленный объект сенсоров, где есть серийник
 */
const getSensorsSerialNumber = async (options) => {
    const { sensorsForManagementById, selectedSensors, timeout } = options;
    const copy = cloneDeep(sensorsForManagementById);
    const serialNumberPromises = [];

    selectedSensors.forEach((element) => {
        const sensor = copy[element.id];
        if (sensor.errors.length === 0) {
            switch (sensor.type) {
                case sensorTypes.XOVIS:
                    serialNumberPromises.push(new Promise((resolve) => resolve({ sensor: { ...sensor } })));
                    break;

                case sensorTypes.VIVOTEK:
                    serialNumberPromises.push(getVivotekSerialNumber(sensor, timeout));
                    break;

                case sensorTypes.HIKVISION:
                    serialNumberPromises.push(new Promise((resolve) => resolve({ sensor: { ...sensor } })));
                    break;

                case sensorTypes.TD:
                    serialNumberPromises.push(new Promise((resolve) => resolve({ sensor: { ...sensor } })));
                    break;

                case sensorTypes.MEGACOUNT:
                    serialNumberPromises.push(new Promise((resolve) => resolve({ sensor: { ...sensor } })));
                    break;

                default:
                    serialNumberPromises.push(
                        new Promise((resolve) =>
                            resolve({
                                error: 'Invalid sensor type',
                                sensor: { ...sensor },
                            }),
                        ),
                    );
                    break;
            }
        } else {
            serialNumberPromises.push(
                new Promise((resolve) =>
                    resolve({
                        error: 'Get sensor serial error',
                        sensor: { ...sensor },
                    }),
                ),
            );
        }
    });

    // Получение серийника сенсоров
    await Promise.all(serialNumberPromises).then((responses) => {
        responses.forEach((response) => {
            if (!response.error) {
                switch (response.sensor.type) {
                    case sensorTypes.XOVIS:
                        copy[response.sensor.id] = {
                            ...response.sensor,
                        };
                        break;
                    case sensorTypes.VIVOTEK:
                        copy[response.sensor.id] = {
                            ...response.sensor,
                            serialNumber: response.serialNumber,
                        };
                        break;
                    case sensorTypes.HIKVISION:
                        copy[response.sensor.id] = {
                            ...response.sensor,
                        };
                        break;

                    default:
                        break;
                }
            } else {
                copy[response.sensor.id] = {
                    ...response.sensor,
                    errors: [...response.sensor.errors, response.error],
                    dataFetching: false,
                };
            }
        });
    });

    return copy;
};

export default getSensorsSerialNumber;
