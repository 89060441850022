import { megacountPostRequest } from '../../../../api/megacount/megacount.api';
import { MEGACOUNT_LAYER_TYPES_MAP } from '../../../../constants/megacountSensor';

/**
 * Получения данных для линий подсчета, зон и исключений
 * @param {string} ip IP датчика
 * @param {number} port Порт датчика
 * @param {string} username логин датчика
 * @param {string} password пароль датчика датчика
 * @param {number} timeout timeout на запрос
 * @returns Promise результатом которого является объект с данными о настойках сети или объект с ошибкой
 */
const getMegacountDetection = async (args) => {
    const { ip, port, username, password, timeout } = args;

    const detection = await megacountPostRequest({
        url: `http://${ip}:${port}/detection`,
        payload: {
            detection: {
                get: ['all'],
            },
        },
        username,
        password,
        timeout,
    });

    if (!detection.error) {
        const layers = {
            exceptions: [],
            masks: [],
            passWays: [],
            zones: [],
        };

        const sensors = detection?.detection?.get?.sensors || [];
        const exclusionAreas = detection?.detection?.get?.exclusionArea || [];

        exclusionAreas.forEach((exclusionArea) => {
            if (exclusionArea.line) {
                const [type, data] = processLine(exclusionArea.line);

                if (type && data && layers[type]) {
                    layers[type].push(data);
                }
            }
        });

        sensors.forEach((sensor) => {
            (sensor.lines || []).forEach((line) => {
                const [type, data] = processLine(line, sensor.name || '');

                if (type && data && layers[type]) {
                    layers[type].push(data);
                }
            });
        });

        return {
            layers,
        };
    } else {
        return {
            error: 'Unable to get sensor detection data',
        };
    }
};

export default getMegacountDetection;

const processLine = (line, sensorName = '') => {
    const layerData = MEGACOUNT_LAYER_TYPES_MAP[line.type];

    if (layerData) {
        const marker = `${sensorName}_${layerData.name}_${line.id}`;
        const coordinates = [];
        (line.path?.segments || []).forEach((segment) => {
            segment.p.forEach((point) => {
                coordinates.push([point.x, point.y]);
            });
        });

        return [
            layerData.layer_type,
            { marker, coordinates: coordinates.toReversed() },
        ];
    }

    return [];
};
