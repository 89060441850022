import { DefaultButton, TextField } from '@fluentui/react';
import styled from 'styled-components/macro';

export const CreateSensorTitle = styled.div`
    font-size: 16px;
    font-weight: 600;
    text-align: center;
`;
export const StyledTextField = styled(TextField)`
    width: 100px;
    margin-left: 10px;
`;

export const IpPortWrapper = styled.div`
    display: flex;
    align-items: flex-start;
`;

export const CreateSensorButton = styled(DefaultButton)`
    margin-top: 10px;
`;

export const CreateSensorButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;
