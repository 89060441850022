import { tdGetRequest } from '../../../../api/td/td.api';
import getLayerCoords from './tools/getLayerCoords';

/**
 * Получение информации о зоне входа и выхода
 * @param {string} ip IP датчика
 * @param {number} port Порт датчика
 * @param {string} slug Slug зоны
 * @returns Promise с ошибкой или информацией о зоне
 */
const getTdFlowZoneBySlug = async (args) => {
    const { ip, port, slug } = args;

    const flowZone = await tdGetRequest({
        url: `http://${ip}:${port}/view_zone?zone=${slug}`,
    });

    if (!flowZone.error) {
        const oParser = new DOMParser();
        const flowZoneHTML = oParser.parseFromString(flowZone, 'text/html');

        const zoneName = flowZoneHTML.querySelector('#Zonename')?.value;

        const enterCoords = getLayerCoords(flowZoneHTML, 'enter_text');
        const exitCoords = getLayerCoords(flowZoneHTML, 'exit_text');

        if (enterCoords && exitCoords && zoneName) {
            return [
                { marker: `${zoneName}`, coordinates: enterCoords }, // в утилите есть постфикс _Enter
                { marker: `${zoneName}`, coordinates: exitCoords }, // в утилите есть постфикс _Exit
            ];
        } else {
            return {
                error: 'An error occurred while getting flow zone data.',
            };
        }
    }

    return {
        error: 'An error occurred while getting flow zone data.',
    };
};

export default getTdFlowZoneBySlug;
