import { CustomSpinner, SpinnerWrapper } from './loaderSpinner.styles';
/**
 * Спиннер (лоадер) для всего приложения
 */
const LoaderSpinner = () => {
    return (
        <SpinnerWrapper>
            <CustomSpinner />
        </SpinnerWrapper>
    );
};

export default LoaderSpinner;
