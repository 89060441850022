import getCountLinesInfo from './getCountLinesInfo';
import getExceptionsInfo from './getExceptionsInfo';
import getFloorsInfo from './getFloorsInfo';
import getMasksInfo from './getMasksInfo';
import getZonesInfo from './getZonesInfo';

const getXovisLayers = (args) => {
    const { config, multisensorData } = args;

    const layers = {
        passWays: getCountLinesInfo({ config: config.rawConfig }),
        zones: getZonesInfo({ config: config.rawConfig }),
        floors: getFloorsInfo({ config: config.rawConfig }),
        exceptions: getExceptionsInfo({ config: config.rawConfig }),
        masks: getMasksInfo({ config: config.rawConfig }),
    };

    const multiSensorLayers = {
        passWays: config.isMultiSensor
            ? getCountLinesInfo({
                  config: config.rawConfig,
                  multiSensorInfo: multisensorData,
              })
            : [],
        zones: config.isMultiSensor
            ? getZonesInfo({
                  config: config.rawConfig,
                  multiSensorInfo: multisensorData,
              })
            : [],
        floors: [],
        exceptions: [],
        masks: [],
    };

    return {
        layers,
        multiSensorLayers,
    };
};

export default getXovisLayers;
