import { TooltipHost } from '@fluentui/react';
import { vivotekProgressMessages } from '../../../../../../../../utils/vivotekHelpers/vivotekProgressMessages';
import { ColumnWrapper, CircleWrapper, Circle } from '../../instalationPointsList.styles';

/**
 *  Компонент для отрисовки прогресс бара для датчика VIVOTEK
 * @param {object} selectedSensor объект выбранного сенсора
 */
const VivotekProgressColumn = ({ selectedSensor }) => {
    return (
        <>
            <ColumnWrapper>
                <CircleWrapper>
                    <TooltipHost
                        content={
                            selectedSensor
                                ? !selectedSensor.firstStepError
                                    ? vivotekProgressMessages.firstStepEnabled
                                    : selectedSensor.firstStepError
                                : null
                        }
                    >
                        <Circle
                            newColor={
                                selectedSensor
                                    ? selectedSensor.isFetching
                                        ? 'grey'
                                        : !selectedSensor.firstStepError
                                        ? 'green'
                                        : 'red'
                                    : 'grey'
                            }
                        >
                            1
                        </Circle>
                    </TooltipHost>
                </CircleWrapper>
                <CircleWrapper>
                    <TooltipHost
                        content={
                            selectedSensor
                                ? !selectedSensor.vivotekCountLinesError
                                    ? vivotekProgressMessages.secondStepEnabled
                                    : selectedSensor.vivotekCountLinesError
                                : null
                        }
                    >
                        <Circle
                            newColor={
                                selectedSensor ? (!selectedSensor.vivotekCountLinesError ? 'green' : 'red') : 'grey'
                            }
                        >
                            2
                        </Circle>
                    </TooltipHost>
                </CircleWrapper>

                <CircleWrapper>
                    <TooltipHost
                        content={
                            selectedSensor
                                ? !selectedSensor.thirdStepError
                                    ? vivotekProgressMessages.thirdStepEnabled
                                    : selectedSensor.thirdStepError
                                : null
                        }
                    >
                        <Circle newColor={selectedSensor ? (!selectedSensor.thirdStepError ? 'green' : 'red') : 'grey'}>
                            3
                        </Circle>
                    </TooltipHost>
                </CircleWrapper>
            </ColumnWrapper>
        </>
    );
};

export default VivotekProgressColumn;
