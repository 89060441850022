import axios from 'axios';
import { hikvisionGetRequest } from '../../../../api/hikvision/hikvision.api';
import getInfoForHikvisionBoundle from '../../../../utils/hikvisionHelpers/getInfoForHikvisionBoundle';
import { hikvisionProgressMessages } from '../../../../utils/hikvisionHelpers/hikvisionProgressMessages';
import processRowChannels from './processRowChannels';
/**
 * Функция для создания промиса при получении серийников от датчиков
 * @param {object} sensor объект сенсора
 * @returns промис
 */
const getHikvisionSensorData = (sensor) => {
    let portForRequest = 80;
    if (sensor.port) portForRequest = sensor.port;

    const errorResponse = {
        ...sensor,
        isConfigurationFetching: false,
        firstStepError: hikvisionProgressMessages.firstStepError,
    };

    return hikvisionGetRequest(
        `http://${sensor.ip}:${sensor.port}/ISAPI/System/Video/inputs/channels `,
        sensor.username,
        sensor.password,
    )
        .then((channelsResponse) => {
            const channelData = processRowChannels(channelsResponse);

            if (!channelData.error) {
                const promise1 = hikvisionGetRequest(
                    `http://${sensor.ip}:${portForRequest}/ISAPI/Event/notification/httpHosts`,
                    sensor.username,
                    sensor.password,
                );

                const promise2 = hikvisionGetRequest(
                    `http://${sensor.ip}:${portForRequest}/ISAPI/System/Video/inputs/channels/${channelData.id}/counting`,
                    sensor.username,
                    sensor.password,
                );

                return axios.all([promise1, promise2]).then((responses) => {
                    const hikvisionDataUploadInfo = responses[0];
                    const countingInfo = responses[1];
                    const res = getInfoForHikvisionBoundle({ hikvisionDataUploadInfo, countingInfo });
                    return { ...sensor, isConfigurationFetching: false, firstStepError: '', ...res };
                });
            } else {
                return { ...errorResponse };
            }
        })
        .catch(() => ({ ...errorResponse }));
};

export default getHikvisionSensorData;
