import axios from 'axios';
import getXovisScreen from './getXovisScreen';
import regenerateXovisScreen from './regenerateXovisScreen';

const errorMessage = 'Ann error accrued while getting screens';

/**
 * Получение скриншотов от датчика
 * @param {object} sensor  Объект датчика
 * @param {number} timeout  timeout
 * @returns Объект с ошибкой или скрины
 */
const getScreens = async (args) => {
    const { sensor, timeout } = args;
    const regenerateResponse = await regenerateXovisScreen({ sensor, timeout });

    if (!regenerateResponse.error) {
        const response = await getScreenExecutor({ sensor, timeout });

        return response;
    }

    return { error: errorMessage };
};

export default getScreens;

const getScreenExecutor = async (args) => {
    const MAX_ATTEMPTS = 10;
    const { sensor, timeout, attempts = 0 } = args;

    const requests = [
        getXovisScreen({ sensor, isMultisensor: false, timeout }),
    ];

    if (sensor.isMultiSensor) {
        requests.push(getXovisScreen({ sensor, isMultisensor: true, timeout }));
    }

    const response = await axios
        .all(requests)
        .then(
            axios.spread((single, multi) => {
                if (sensor.isMultiSensor) {
                    if (!single.error && !multi.error) {
                        return {
                            screen: single.screen,
                            multiSensorScreen: multi.screen,
                        };
                    }
                } else {
                    if (!single.error) {
                        return {
                            screen: single.screen,
                            multiSensorScreen: '',
                        };
                    }
                }
                return {
                    error: errorMessage,
                };
            }),
        )
        .catch(() => {
            return {
                error: errorMessage,
            };
        });

    if (attempts > MAX_ATTEMPTS) {
        return { error: errorMessage };
    }

    if (response.error) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
        return getScreenExecutor({ sensor, timeout, attempts: attempts + 1 });
    }

    return response;
};
