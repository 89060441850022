export const portionDropdownKeys = {
    FIFTEEN_MINUTES: 'fifteenMinutes',
    HOUR_PORTION: 'hourPortion',
    DAY_PORTION: 'dayPortion',
    DEFAULT: 'default',
};

export const portionDropdownOptions = [
    { key: portionDropdownKeys.DEFAULT, text: 'Default' },
    { key: portionDropdownKeys.FIFTEEN_MINUTES, text: '15 min. portion' },
    { key: portionDropdownKeys.HOUR_PORTION, text: 'Hour portion' },
    { key: portionDropdownKeys.DAY_PORTION, text: 'Day portion' },
];

export const sensorsManagementModeKeys = {
    CONNECTION_TEST: 'connectionTest',
    DATA_UPLOAD: 'dataUpload',
    SCREENS_UPLOAD: 'screensUpload',
};

export const sensorsManagementMode = [
    { key: sensorsManagementModeKeys.CONNECTION_TEST, text: 'Connection test' },
    { key: sensorsManagementModeKeys.DATA_UPLOAD, text: 'Data upload' },
    { key: sensorsManagementModeKeys.SCREENS_UPLOAD, text: 'Screens upload' },
];
