import styled from 'styled-components/macro';
import { Icon } from '@fluentui/react';

export const ManagementListWrapper = styled.div`
    margin-top: 10px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
`;

export const filterStyles = {
    root: {
        width: '150px',
        marginRight: '15px',
    },
};

export const FilterWrapper = styled.div`
    display: flex;
    align-items: flex-end;
`;

export const dropdownStyles = {
    root: {
        width: '150px',
        marginRight: '15px',
    },
};

export const startButtonStyles = {
    root: {
        width: '150px',
    },
};

export const statusSpinnerStyles = {
    root: {
        width: '25px',
    },
};

export const StatusIcon = styled(Icon)`
    font-size: 20px;
    color: ${(props) => props.color};
    margin-right: 5px;
`;

export const StatusWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const CopyTextArea = styled.textarea`
    position: absolute;
    top: -10000000%;
    left: -10000000%;
`;
