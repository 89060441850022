/**
 * Вспомогательная функция для создания связки с сенсоров RSTAT
 * @param {object} sensor объект сенсора
 * @param {string} accessKey ключ для выгрузки данных
 * @param {object} iPoint объект точки установки
 * @param {object} currentBoundle объект текущей связки
 */
const setBoundleForDilaxHelper = (options) => {
    // eslint-disable-next-line
    const { sensor, accessKey, iPoint, currentBoundle } = options;

    return {
        iPointId: iPoint.id,
        value: {
            ...sensor,
            isFetching: sensor.isConfigurationFetching,
            current: true,
            boundleId: currentBoundle.id,
        },
    };
};

export default setBoundleForDilaxHelper;
