import axios from 'axios';
import getTdNetworkConfig from './getTdNetworkConfig';
import { tdProgressMessages } from '../../../../utils/tdSensorHelpers/tdProgressMessages';
import getTdServiceConfig from './getTdServiceConfig';
import getTdFlowStatistics from './getTdFlowStatistics';

/**
 * Promise. Функция для получения информация о датчике td
 * @param {object} sensor объект сенсора
 * @returns возвращает обновленный объект сенсора с дополнительными полями
 */
const getTdSensorData = (sensor) => {
    const errorResponse = {
        ...sensor,
        isConfigurationFetching: false,
        firstStepError: tdProgressMessages.firstStepError,
    };

    const networkConfig = getTdNetworkConfig({
        ip: sensor.ip,
        port: sensor.port,
    });

    const serviceConfig = getTdServiceConfig({
        ip: sensor.ip,
        port: sensor.port,
    });

    const flowStatistics = getTdFlowStatistics({
        ip: sensor.ip,
        port: sensor.port,
    });

    return axios
        .all([networkConfig, serviceConfig, flowStatistics])
        .then(
            axios.spread((networkConfig, serviceConfig, flowStatistics) => {
                let firstStepError = '';

                const tdData = {
                    networkConfig: {},
                    serviceConfig: {},
                    layers: {
                        pass_ways: [],
                    },
                };

                if (!networkConfig.error) {
                    tdData.networkConfig = networkConfig;
                } else {
                    firstStepError = tdProgressMessages.firstStepError;
                }

                if (!serviceConfig.error) {
                    tdData.serviceConfig = serviceConfig;
                }

                if (!flowStatistics.error) {
                    tdData.layers.pass_ways = flowStatistics;
                }

                return {
                    ...sensor,
                    isConfigurationFetching: false,
                    firstStepError,
                    tdData,
                };
            }),
        )
        .catch((_) => {
            return { ...errorResponse };
        });
};

export default getTdSensorData;
