import { TextField } from '@fluentui/react';
import { useContext, useEffect, useState } from 'react';
import { ModalContext } from '../../modalCreator';
import { SingleUrlWrapper, InputWrapper, CustomCheckBox, UrlName } from './singleUrl.styles';

/**
 * компонент для отрисовки информации об одеом урле в списке
 * @param {string} urlName название урла
 * @param {object} urlInfo объект с саими урлом, а флагом checked
 */
const SingleUrl = ({ urlName, urlInfo }) => {
    const [checked, setChecked] = useState(urlInfo.checked);
    const [fieldValue, setFieldValue] = useState(urlInfo.url);
    const { setSelectedHostsTrans } = useContext(ModalContext);

    useEffect(() => {
        setSelectedHostsTrans({ name: urlName, checked, url: fieldValue });
        // eslint-disable-next-line
    }, [checked, fieldValue, urlName]);

    const checkBoxHandler = () => setChecked((prevState) => !prevState);

    const fieldHandler = (_, newValue) => setFieldValue(newValue);

    return (
        <SingleUrlWrapper>
            <CustomCheckBox checked={checked} onChange={checkBoxHandler} />
            <InputWrapper>
                <UrlName checked={checked}>{urlName}</UrlName>
                <TextField disabled={!checked} value={fieldValue} onChange={fieldHandler} />
            </InputWrapper>
        </SingleUrlWrapper>
    );
};

export default SingleUrl;
