import { useState, useContext, useEffect, memo } from 'react';
import { ScreenWrapper, ShowScreenButtonWrapper, ErrorMessage, Wrapper, InfoText } from './iPointScreen.styles';
import { FloorPlanContext } from '../floorPlan';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '@fluentui/react';
import { DateTime } from 'luxon';
import SingleScreen from '../../../commonComponents/singleScreen';

/**
 * Компонент для отображения скриншота для выбранной точки установки
 */
const IPointScreen = memo(({ sensor2IPointRelation }) => {
    const {
        selectedInstalationPoint,
        extendedSensorsById,
        updateSelectedSensorsTrans,
        plStructureFetching,
        getScreensInfoThunkTrans,
        selectedLocationTimeZone,
    } = useContext(FloorPlanContext);
    const [screenFetching, setScreenFetching] = useState(false);
    const [screenInfo, setscreenInfo] = useState(null);
    const [screenError, setScreenError] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        setScreenFetching(false);
        setscreenInfo(null);
        setScreenError(null);

        const sensor = extendedSensorsById[sensor2IPointRelation.sensor_id];

        if (sensor?.screensFetchError) setScreenError(sensor.screensFetchError);

        if (sensor?.screenInfoFetching) setScreenFetching(true);

        if (sensor?.screensInfo.length > 0) {
            let max = sensor.screensInfo[0];
            sensor.screensInfo.forEach((element) => {
                const currentDateFrom = DateTime.fromISO(element.sensor_info.date_from);
                const maxDateFrom = DateTime.fromISO(max.sensor_info.date_from);
                if (currentDateFrom > maxDateFrom) max = element;
            });

            setscreenInfo(max);
        }
    }, [extendedSensorsById, sensor2IPointRelation]);

    const showScreenHandler = () => {
        const sensor = extendedSensorsById[sensor2IPointRelation.sensor_id];
        if (sensor) {
            setScreenError(false);
            updateSelectedSensorsTrans([sensor]);
            getScreensInfoThunkTrans();
        }
    };

    const dateTo = DateTime.fromISO(sensor2IPointRelation.date_to).setZone(selectedLocationTimeZone);

    const infoData = [
        { label: 'IPoint marker', text: selectedInstalationPoint?.marker || 'No data' },
        { label: 'Sensor IP', text: sensor2IPointRelation.sensor_ip },
        { label: 'Sensor serial', text: sensor2IPointRelation.sensor_serial },
        { label: 'Sensor type', text: sensor2IPointRelation.sensor_type },
        {
            label: 'Relation period',
            text: `${t('from')} ${DateTime.fromISO(sensor2IPointRelation.date_from)
                .setZone(selectedLocationTimeZone)
                .toFormat('yyyy-MM-dd HH:mm')}  ${t('to')} ${
                dateTo.year >= 2100 ? 'null' : dateTo.toFormat('yyyy-MM-dd HH:mm')
            }`,
        },
    ];

    return (
        <Wrapper>
            {infoData.map((item) => {
                return (
                    <InfoText key={item.label}>
                        <span>{t(item.label)}:&nbsp;</span>
                        <div>{item.text}</div>
                    </InfoText>
                );
            })}
            <ScreenWrapper>
                {screenInfo && (
                    <SingleScreen
                        screenInfo={screenInfo}
                        size={500}
                        selectedLocationTimeZone={selectedLocationTimeZone}
                        sensorType={screenInfo.sensor_info.sensor_type}
                    />
                )}
                {!screenInfo && (
                    <ShowScreenButtonWrapper>
                        <PrimaryButton
                            text={screenFetching ? t('Loading...') : t('Show screen')}
                            disabled={plStructureFetching || screenFetching}
                            onClick={showScreenHandler}
                        />
                        {screenError && <ErrorMessage>{screenError}</ErrorMessage>}
                    </ShowScreenButtonWrapper>
                )}
            </ScreenWrapper>
        </Wrapper>
    );
});

export default IPointScreen;
