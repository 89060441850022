import { memo } from 'react';
import { Group } from 'react-konva';
import { useDispatch, useSelector } from 'react-redux';
import SingleIntersection from './components/singleIntersection';
import { changeOrder } from './intersectionsForDisplayReducer';

/**
 * Компонент для отрисовки всех пересечений усов и проходов
 * @param {number} stageScale скейл конваса
 */
const IntersectionsForDisplay = memo(({ stageScale }) => {
    const { passWaysAndIPointsRelations } = useSelector((state) => state.intersectionsForDisplayReducer);
    const { selectedFloor } = useSelector((state) => state.instalationPointsReducer);
    const dispatch = useDispatch();

    const changeOrderTrans = (id) => dispatch(changeOrder({ id, floor: selectedFloor }));

    const intersections = passWaysAndIPointsRelations
        .find((element) => element.floor === selectedFloor)
        ?.interactions?.map((element) => (
            <SingleIntersection selectedFloor={selectedFloor} changeOrder={changeOrderTrans} key={element.id} stageScale={stageScale} intersectionInfo={element} />
        ));

    return <Group>{intersections}</Group>;
});

export default IntersectionsForDisplay;
