import { ErrorWrapper } from './error.styles';
/**
 * Компонент для отрисовки страницы с ошибкой
 * @returns
 */
const Error = () => {
    return <ErrorWrapper>Page not found</ErrorWrapper>;
};

export default Error;
