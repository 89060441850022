import { Icon, TextField } from '@fluentui/react';
import styled from 'styled-components/macro';

export const rowStyles = {
    root: {
        cursor: 'pointer',
        userSelect: 'auto',
    },
};

export const selectedRowStyles = {
    root: {
        cursor: 'pointer',
        background: 'rgb(188, 221, 246)',
        userSelect: 'auto',
    },
};

export const ColumnWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    position: relative;
`;

export const SelectWrapper = styled.div`
    width: 70%;
`;

/**
 * newColor = 'red' | 'green' | 'grey'
 */
export const Circle = styled.div`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    ${(p) => (p.newColor === 'green' ? `border: 2px solid ${p.newColor}` : null)};
    color: ${(p) => (p.newColor === 'green' ? 'black' : 'white')};
    background-color: ${(p) => (p.newColor === 'red' || p.newColor === 'grey' ? p.newColor : 'unset')};
    font-weight: bold;
    border: ${(p) => `2px solid ${p.newColor}`};
    font-size: 16px;

    display: flex;
    justify-content: center;
    align-items: center;
    :last-child {
        margin-right: 0px;
    }
`;

export const CircleWrapper = styled.div`
    margin-right: 5px;
    :last-child {
        margin-right: 0px;
    }
`;

export const CustomIPointIcon = styled(Icon)`
    font-size: ${({ size = 20 }) => size + 'px'};
    color: ${({ newColor = '#3b3a39;' }) => newColor};
`;

export const IPointsTitle = styled.div`
    font-size: 16px;
    font-weight: 600;
    margin-top: 5px;
`;

export const StyledFilter = styled(TextField)`
    width: 200px;
`;

export const IPointsHeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;
    flex-wrap: wrap;
`;

export const ListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

export const MultiAndSlaveWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

export const NumberOfLines = styled.div`
    font-size: 16px;
    font-weight: 500;
    text-align: center;
`;

export const followIPointStyles = {
    root: {
        marginBottom: '0px',
    },
};

export const FollowIPointLabel = styled.div``;

export const SelectedIPointsButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

export const RelationsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

export const RelationTypeWrapper = styled.div`
    white-space: normal;
    display: flex;
    span {
        display: inline-block;
        font-weight: 600;
        white-space: nowrap;
    }
    div {
        display: inline-block;
    }
`;
