import { PrimaryButton } from '@fluentui/react';
import styled from 'styled-components/macro';

export const ConflictModalTitle = styled.div`
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 15px;
    width: 1000px;
`;

export const ConflictModalWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const MessageBarWrapper = styled.div`
    flex-grow: 1;
`;

export const ConflictButtonsWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
`;

export const OverwriteButton = styled(PrimaryButton)`
    margin-left: 10px;
`;
