import { DefaultButton } from '@fluentui/react';
import { useContext } from 'react';
import { InstallationPointsContext } from '../../../../../installationPoints';

/**
 * Компонент для отрисовки информации об связки сенсор-точка установки
 * @param {object} iPoint объект, содержащий всю информацию об этой точке установки
 * @param {object} availableBundlesByIPointId объект всех активных связок, остортированных по  ключу iPointID
 * @param {array} sensors  массив всех сенсоров
 */
const SensorsColumn = ({ iPoint }) => {
    const { storeIPointForBoundleInfoModalTrans, availableBundlesByIPointId, sensors } =
        useContext(InstallationPointsContext);

    const buttonHandler = () => storeIPointForBoundleInfoModalTrans(iPoint);

    return (
        <>
            {sensors.length > 0 ? (
                <DefaultButton
                    styles={{
                        root: {
                            width: '200px',
                        },
                    }}
                    onClick={buttonHandler}
                >
                    {availableBundlesByIPointId[iPoint.id]
                        ? `${availableBundlesByIPointId[iPoint.id].id} / ${
                              availableBundlesByIPointId[iPoint.id].sensor_type
                          }`
                        : 'Not selected'}
                </DefaultButton>
            ) : (
                <div>No sensors</div>
            )}
        </>
    );
};
export default SensorsColumn;
