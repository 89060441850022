import { createSlice } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { getRequest, patchRequest } from './api/api';
import { getSensorsThunk } from './components/countingSensors/countingSensorsReducer';
import { getSensorsAndPointsBoundlesThunk } from './components/instalationPoints/installationPointsReducer';
import { actionsAfterOverwrite } from './constants/actionsAfterOverwrite';
import { requestTypes } from './constants/requestTypes';
import getCurrentDomain from './utils/urls/getCurrentDomain';
const initialState = {
    token: '',
    language: 'eng',
    storeUrls: {},
    storeHosts: {},
    selectedHosts: {},
    selectedUrls: {},
    showSpinner: false,
    successNotification: {},
    errorNotification: {},
    equalConflict: false,
    dataForOverwritten: null,
    current: null,
    windowSize: { width: 0, height: 0 },
    locales: null,
};

const generalReducer = createSlice({
    name: 'generalReducer',
    initialState,
    reducers: {
        // Сохранение локалей
        storeLocales: (state, action) => {
            state.locales = action.payload;
        },
        // запись в стейт токена для запросов на сервер
        setToken: (state, action) => {
            state.token = action.payload;
        },

        // изменение языка приложения
        setLanguage: (state) => {
            let newLanguage = '';
            state.language === 'eng'
                ? (newLanguage = 'ru')
                : (newLanguage = 'eng');
            state.language = newLanguage;
        },

        // запись в стейт всех доступных урлов
        setUrls: (state, action) => {
            state.storeUrls = action.payload;
        },

        // изменение флага конфликта
        storeEqualConflict: (state, action) => {
            state.equalConflict = action.payload;
        },

        storeDataForOverwritten: (state, action) => {
            state.dataForOverwritten = action.payload;
        },

        // запись в стейт выбранных урлов из модалки
        setSelectedUrls: (state, action) => {
            if (action.payload.checked) {
                state.selectedUrls = {
                    ...state.selectedUrls,
                    [action.payload.name]: {
                        checked: action.payload.checked,
                        url: action.payload.url,
                    },
                };
                return;
            }
            delete state.selectedUrls[action.payload.name];
        },

        // Запись в стейт всех хочтов
        setHosts: (state, action) => {
            state.storeHosts = action.payload;
        },

        // Запись в стейт выбранных хостов
        setSelectedHosts: (state, action) => {
            const selectedHostsCopy = cloneDeep(state.selectedHosts);

            if (action.payload.checked) {
                selectedHostsCopy[action.payload.name] = {
                    checked: action.payload.checked,
                    url: action.payload.url,
                };

                state.selectedHosts = selectedHostsCopy;
            } else {
                delete selectedHostsCopy[action.payload.name];
                state.selectedHosts = selectedHostsCopy;
            }
        },

        // показать / скрыть спинер (лоадер)
        setShowSpinner: (state, action) => {
            state.showSpinner = action.payload;
        },

        // показать / скрыть успешное оповещание
        setSuccessNotification: (state, action) => {
            state.successNotification = action.payload;
        },

        // показать / скрыть оповещание об ошибке
        setErrorNotification: (state, action) => {
            state.errorNotification = action.payload;
        },

        // запись в стейт информации о размерах окна пользователя
        storeWindowSize: (state, action) => {
            state.windowSize = action.payload;
        },

        storeCurrent: (state, action) => {
            state.current = action.payload;
        },
    },
});

export const {
    setToken,
    setLanguage,
    setUrls,
    setSelectedUrls,
    setShowSpinner,
    setSuccessNotification,
    setErrorNotification,
    setHosts,
    storeCurrent,
    setSelectedHosts,
    storeEqualConflict,
    storeDataForOverwritten,
    storeWindowSize,
    storeLocales,
} = generalReducer.actions;

// показывает успешную нотификацию
export const showSuccessNotification = (options) => (dispatch) => {
    dispatch(setSuccessNotification(options));
    setTimeout(() => {
        dispatch(setSuccessNotification({}));
    }, 3000);
};

// показывавет нотификацию с ошибкой
export const showErrorNotification = (options) => (dispatch) => {
    dispatch(setErrorNotification(options));
    setTimeout(() => {
        dispatch(setErrorNotification({}));
    }, 3000);
};

/**
 * Перезаписывает значение конфликующих объектов
 * @param {string} url урл по которому нужно сдлеать запрос для перезаписи
 * @param {string} requestType тип запроса
 * @param {string} actionAfterOverwrite действие после запроса
 * @param {string} token токен для запроса
 * @param {any} body тело запроса
 * @param {object} storeUrls объект со всеми урлами
 * @param {object} history объект для ридереккта
 * @param {object} additionalData дополнительная информация
 */
export const overWriteThunk = (options) => async (dispatch) => {
    const {
        url,
        requestType,
        actionAfterOverwrite,
        token,
        body,
        storeUrls,
        nav,
        additionalData,
    } = options;

    switch (requestType) {
        case requestTypes.PATCH: {
            const data = await patchRequest(url, token, body);
            if (!data.error) {
                switch (actionAfterOverwrite) {
                    case actionsAfterOverwrite.UPDATE_BUNDLES:
                        dispatch(
                            getSensorsAndPointsBoundlesThunk(
                                storeUrls.SENSORS_TO_IPOINTS.url,
                                token,
                            ),
                        );
                        break;
                    case actionsAfterOverwrite.UPDATE_SENSORS:
                        dispatch(
                            showSuccessNotification({
                                show: true,
                                message: 'Sensor updated',
                            }),
                        );
                        dispatch(
                            getSensorsThunk(
                                storeUrls,
                                token,
                                nav,
                                additionalData.locationId,
                            ),
                        );
                        break;
                    default:
                        break;
                }
            } else {
                dispatch(
                    showErrorNotification({
                        show: true,
                        message: 'Patch error',
                    }),
                );
            }
            break;
        }

        case requestTypes.POST: {
            break;
        }

        default:
            break;
    }
};

/**
 * Thunk. Для выхода из приложения
 */
export const signOutThunk = () => async (_, getState) => {
    const {
        storeUrls: { SIGN_OUT },
    } = getState().generalReducer;
    localStorage.removeItem('xtoken');
    window.location.replace(
        `${SIGN_OUT.url}?next=/admin/login/?next=/sso/ajax-token?next=` +
            window.location.href,
    );
};

/**
 * Thunk. Получение локалей
 */
export const getLocales = () => async (dispatch) => {
    let currentDomain = getCurrentDomain();

    dispatch(setShowSpinner(true));

    const response = await getRequest(
        `https://admin.${currentDomain}/locales?domain=${window.location.host}`,
    );
    dispatch(setShowSpinner(false));

    if (!response.error) {
        dispatch(storeLocales(response));
    } else {
        dispatch(
            showErrorNotification({
                show: true,
                message: 'Ann error accrued while getting locales',
            }),
        );
    }
};

export default generalReducer.reducer;
