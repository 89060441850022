import axios from 'axios';

/**
 * Функция для получения конфигурации сенсора
 * @param {string} url url по которому нужно обращатсья к сенсору
 * @param {string} username логин для доступа к датчику
 * @param {string} password пароль для доступа к датчику
 * @returns конфигурацию сенсора или ошибку
 */
export const vivotekGetRequest = async (url, username, password) => {
    try {
        const data = await axios
            .get(url, {
                timeout: 15000,
                auth: {
                    username,
                    password,
                },
            })
            .then((response) => response.data);
        return data;
    } catch (error) {
        return {
            error,
        };
    }
};

/**
 * Функция для POST запроса к датчику VIVOTEK
 * @param {string} url url для запроса к датчику
 * @param {string} username логин для доступа к датчику
 * @param {string} password пароль для доступа к датчику
 * @param {object} data тело запроса
 * @returns Ответ от датчика VIVOTEK
 */
export const vivotekPostRequest = async ({ url, username, password, data }) => {
    try {
        const response = await axios
            .post(url, data, {
                timeout: 15000,
                auth: { username, password },
            })
            .then((response) => response.data)
            .catch((error) => ({ error }));
        return response;
    } catch (error) {
        return {
            error,
        };
    }
};
