import { DefaultButton, TeachingBubble } from '@fluentui/react';
import { memo, useRef } from 'react';
import { useState } from 'react';
import {
    BatchDownloadStyledTextfield,
    BatchDownloadTitle,
    ChooseFileWrapper,
    FileName,
    BatchDownloadWrapper,
    CustomCell,
    TableWrapper,
    StyledDefaultButton,
    TitleWrapper,
    StyledIconForBatch,
    P,
    errorsButtonStyles,
    batchButtonStyles,
} from './batchDownload.styled';
import ReactDataSheet from 'react-datasheet';
import { cloneDeep } from 'lodash';
import BatchProgress from './components/batchProgress';
import { useDispatch } from 'react-redux';
import { CSVParserThunk, storeStartBatchUpload } from './batchDownloadReducer';
import { useSelector } from 'react-redux';
import useSerialNumberForBatchUpload from '../../../utils/customHooks/useSerialNumberForBatchUpload';
import useBatchUpload from '../../../utils/customHooks/useBatchUpload';
import ModalCreator from '../modalCreator';
import NotEqualSerialNumbers from '../notEqualSerialNumbers/notEqualSerialNumbers';
import ParseErrorsModal from '../parseErrorModal/parseErrorsModal';

/**
 * Модальное окно для пакетной загрузки сенсоров и связок с точками установок, путем парсинга csv строки или csv файла
 */
const BatchDownload = memo(() => {
    const { extendedDataFromCSVSourcesById, maxPackagesError, parseErrors, isAllSensorsFetched, startBatchUpload, notEqualSerialNumbers, isItPossibleToCloseModal } = useSelector(
        (state) => state.batchDownloadReducer,
    );
    const [CSVString, setCSVString] = useState('ip;port;type;user;pass;serial_number;pl_id;ipoint_marker;ipoint_date_from;ipoint_date_to');
    const [file, setFile] = useState(null);
    const [isMouseDown, setIsMouseDown] = useState(false);
    const [selected, setSelected] = useState({ start: null, end: null });
    const [isEqualModalOpen, setEqualModalOpen] = useState(false);
    const [isParseErrosModalOpen, setParseErrosModalOpen] = useState(false);
    const [showExample, setShowExample] = useState(false);
    const dispatch = useDispatch();
    useSerialNumberForBatchUpload();
    useBatchUpload();
    const fileInput = useRef();

    const openEqualModalHandler = () => setEqualModalOpen(true);
    const closeEqualModalHandler = () => setEqualModalOpen(false);

    const openParseErrorsModalHandler = () => setParseErrosModalOpen(true);
    const closeParseErrosModalHandler = () => setParseErrosModalOpen(false);

    const openExampleHandler = () => setShowExample(true);
    const closeExampleHandler = () => setShowExample(false);

    const CSVStringHandler = (_, newValue) => setCSVString(newValue);
    const styledFileHandler = () => fileInput.current.click();
    const showListHandler = () => dispatch(CSVParserThunk(file, CSVString));
    const onMouseUp = (value) => () => setIsMouseDown(value);
    const batchDownloadHandler = () => dispatch(storeStartBatchUpload(true));

    const initialTableState = [
        [
            { readOnly: true, value: 'ip' },
            { readOnly: true, value: 'port' },
            { readOnly: true, value: 'type' },
            { readOnly: true, value: 'user' },
            { readOnly: true, value: 'pass' },
            { readOnly: true, value: 'serial_number' },
            { readOnly: true, value: 'pl_id' },
            { readOnly: true, value: 'ipoint_marker' },
            { readOnly: true, value: 'ipoint_date_from' },
            { readOnly: true, value: 'ipoint_date_to' },
            { readOnly: true, value: 'is_online' },
            { readOnly: true, value: 'is_sensor_created' },
            { readOnly: true, value: 'is_bound_created' },
        ],
    ];

    if (extendedDataFromCSVSourcesById) {
        Object.keys(extendedDataFromCSVSourcesById).forEach((id) => {
            const element = extendedDataFromCSVSourcesById[id];

            const arrayForTable = [
                { value: element.ip },
                { value: element.port },
                { value: element.type },
                { value: element.user },
                { value: element.pass },
                { value: element.serial_number },
                { value: element.pl_id },
                { value: element.ipoint_marker },
                { value: element.ipoint_date_from },
                { value: element.ipoint_date_to },
                {
                    value: 'is_online',
                    component: <BatchProgress key={1} element={element} number={1} startBatchUpload={startBatchUpload} />,
                    forceComponent: true,
                },
                {
                    value: element.is_sensor_created,
                    component: <BatchProgress key={2} element={element} number={2} startBatchUpload={startBatchUpload} />,
                    forceComponent: true,
                },
                {
                    value: element.is_bound_created,
                    component: <BatchProgress key={3} element={element} number={3} startBatchUpload={startBatchUpload} />,
                    forceComponent: true,
                },
            ];
            initialTableState.push(arrayForTable);
        });
    }

    const fileHandler = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setFile(e.target.files[0]);
        }
    };

    const onMouseOver = (props) => (e) => {
        if (isMouseDown) {
            setSelected((prevState) => {
                const newState = cloneDeep(prevState);
                newState['end'] = { i: props.row, j: props.col };
                return newState;
            });
            props.onMouseOver(e);
        }
    };

    const onMouseDown = (props) => (e) => {
        setIsMouseDown(true);
        setSelected({ start: { i: props.row, j: props.col }, end: { i: props.row, j: props.col } });
        props.onMouseDown(e);
    };

    const cellRenderer = (props) => {
        return (
            <CustomCell onMouseDown={onMouseDown(props)} onMouseUp={onMouseUp(false)} onMouseMove={onMouseOver(props)} className={props.className}>
                {props.children}
            </CustomCell>
        );
    };

    return (
        <>
            <ModalCreator isModalOpen={isParseErrosModalOpen} closeModal={closeParseErrosModalHandler}>
                <ParseErrorsModal parseErrors={parseErrors} />
            </ModalCreator>
            <ModalCreator isModalOpen={isEqualModalOpen} closeModal={closeEqualModalHandler}>
                <NotEqualSerialNumbers notEqualSerialNumbers={notEqualSerialNumbers} />
            </ModalCreator>
            <TitleWrapper>
                <BatchDownloadTitle>Batch upload</BatchDownloadTitle>
                <StyledIconForBatch onMouseEnter={openExampleHandler} iconName={'Info'} id='example' />
            </TitleWrapper>
            {showExample && (
                <div onMouseLeave={closeExampleHandler}>
                    <TeachingBubble headline='CSV example' target='#example'>
                        <P>ip,port,type,user,pass,serial_number,pl_id,ipoint_marker,ipoint_date_from,ipoint_date_to</P>
                        <P>111.111.111.111;80;xovis;admin;admin;FF:FF:FF:01:02:11;1;ipoint_1;2021-09-01T00:00:00+03:00;2021-09-02T00:00:00+03:00</P>
                        <P>111.111.111.111;80;brickstream;admin;admin;FF:FF:FF:01:02:12;1;ipoint_2;2021-09-01T00:00:00+03:00;</P>
                        <P>111.111.111.111;80;vivotek;admin;admin;FF:FF:FF:01:02:13;1;;;</P>
                        <P>111.111.111.111;80;hikvision;admin;admin;;1;;;</P>
                    </TeachingBubble>
                </div>
            )}

            <BatchDownloadStyledTextfield value={CSVString} onChange={CSVStringHandler} label='CSV string' multiline rows={3} />
            <input type='file' name='image' ref={fileInput} accept='.csv' onChange={fileHandler} style={{ display: 'none' }} />
            <ChooseFileWrapper>
                <DefaultButton onClick={styledFileHandler}>Choose CSV file</DefaultButton>
                {file ? <FileName>{file.name}</FileName> : null}
            </ChooseFileWrapper>
            {initialTableState.length > 1 ? (
                <TableWrapper>
                    <ReactDataSheet selected={selected} cellRenderer={cellRenderer} data={initialTableState} valueRenderer={(cell) => cell.value} />
                </TableWrapper>
            ) : null}

            <BatchDownloadWrapper>
                {maxPackagesError && <div>Max pachages limit is 50</div>}
                {notEqualSerialNumbers.length > 0 && (
                    <DefaultButton styles={errorsButtonStyles} onClick={openEqualModalHandler}>
                        Show not equal serial numbers
                    </DefaultButton>
                )}
                {parseErrors.length > 0 && (
                    <DefaultButton styles={errorsButtonStyles} onClick={openParseErrorsModalHandler}>
                        Show parse errors
                    </DefaultButton>
                )}
                <StyledDefaultButton disabled={!isItPossibleToCloseModal || startBatchUpload} onClick={showListHandler}>
                    Show List
                </StyledDefaultButton>
                <DefaultButton styles={batchButtonStyles} disabled={!isAllSensorsFetched || startBatchUpload} onClick={batchDownloadHandler}>
                    Batch upload
                </DefaultButton>
            </BatchDownloadWrapper>
        </>
    );
});

export default BatchDownload;
