import { useContext, useEffect, useState } from 'react';
import { InstallationPointsContext } from '../../../../../../installationPoints';
import {
    DetailsList,
    MessageBar,
    MessageBarType,
    ScrollablePane,
    ScrollbarVisibility,
    Spinner,
    SpinnerSize,
    Sticky,
    StickyPositionType,
} from '@fluentui/react';
import { columns as _columns } from './constants/constants';
import { useTranslation } from 'react-i18next';
import { CellWrapper, Wrapper } from './BatchUrlUpdateModal.styles';
import sortHandler from './tools/sortHandler';

/**
 * Компонент для отображения тела модального окна со статусами
 * обновленных выгрузок сенсоров
 */
const BatchUrlUpdateModal = () => {
    const { extendedSelectedIPointsById } = useContext(InstallationPointsContext);

    const [sortedItems, setSortedItems] = useState([]);
    const [columns, setColumns] = useState(_columns);

    const { t } = useTranslation();

    useEffect(() => {
        if (extendedSelectedIPointsById && Object.values(extendedSelectedIPointsById).length) {
            setSortedItems(Object.values(extendedSelectedIPointsById));
        }
    }, [extendedSelectedIPointsById]);

    const onRenderItemColumn = (item, _, column) => {
        const fieldContent = item[column.fieldName];
        switch (column.key) {
            case 'marker':
                return <CellWrapper>{item.iPoint?.marker}</CellWrapper>;

            case 'sensorAddress':
                return (
                    <CellWrapper>
                        {item.sensor?.ip}:{item.sensor?.port}
                    </CellWrapper>
                );

            case 'sensorType':
                return <CellWrapper>{item.sensor?.sensor_type}</CellWrapper>;

            case 'status':
                if (item.isFetching) {
                    return (
                        <CellWrapper>
                            <Spinner size={SpinnerSize.medium} />
                        </CellWrapper>
                    );
                }

                if (item.isError) {
                    return (
                        <CellWrapper>
                            <MessageBar messageBarType={MessageBarType.error}>{t('Error')}</MessageBar>
                        </CellWrapper>
                    );
                }

                return (
                    <CellWrapper>
                        <MessageBar messageBarType={MessageBarType.success}>{t('Success')}</MessageBar>
                    </CellWrapper>
                );

            default:
                return <CellWrapper>{fieldContent}</CellWrapper>;
        }
    };

    const onRenderDetailsHeader = (headerProps, defaultRender) => {
        if (!defaultRender) return null;
        return (
            <Sticky
                stickyPosition={StickyPositionType.Header}
                stickyBackgroundColor="transparent"
                isScrollSynced={true}
            >
                <div>{defaultRender(headerProps)}</div>
            </Sticky>
        );
    };

    const onColumnHeaderClick = (_, column) => {
        if (column) {
            let isSortedDescending = Boolean(column.isSortedDescending);

            if (column.isSorted) {
                isSortedDescending = !isSortedDescending;
            }

            const result = sortHandler({ items: sortedItems, fieldName: column.fieldName, isSortedDescending });

            setSortedItems(result);
            setColumns(
                columns.map((item) => {
                    const isSorted = item.key === column.key;
                    return {
                        ...item,
                        isSorted,
                        isSortedDescending: isSorted ? isSortedDescending : item.isSortedDescending,
                    };
                }),
            );
        }
    };

    return (
        <Wrapper>
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                <DetailsList
                    items={sortedItems}
                    onRenderDetailsHeader={onRenderDetailsHeader}
                    onRenderItemColumn={onRenderItemColumn}
                    selectionMode={0}
                    columns={columns}
                    onColumnHeaderClick={onColumnHeaderClick}
                />
            </ScrollablePane>
        </Wrapper>
    );
};

export default BatchUrlUpdateModal;
