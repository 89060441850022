import { useDispatch, useSelector } from 'react-redux';
import { ButtonsWrapper, FieldsWrapper, ResetButton, Wrapper } from './UpdateSensorsUploadUrlsModal.styles';
import { sensorTypes } from '../../../../../../../../constants/sensorTypes';
import { PrimaryButton, TextField } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { AccordionItem } from '../../../../../../../Accordion';
import { useCallback, useEffect, useRef, useState } from 'react';
import { getSensorUploadUrls } from '../../../../../../../../constants/sensorUploadUrls';
import { updateSensorUploadUrlBySensorType } from '../../../../../../../countingSensors/countingSensorsReducer';
import { showSuccessNotification } from '../../../../../../../../generalReducer';

const SensorItem = ({ sensorType, defaultOpen }) => {
    const { sensorUploadUrlsBySensorType } = useSelector((state) => state.countingSensorsReducer);
    const [form, setForm] = useState({ protocol: '', host: '', port: '' });
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const ref = useRef();

    useEffect(() => {
        const uploadingUrlFromStore = sensorUploadUrlsBySensorType[sensorType];
        if (uploadingUrlFromStore) {
            setForm(uploadingUrlFromStore.uploadUrl);
        }
    }, [sensorType, sensorUploadUrlsBySensorType]);

    const onFieldChange = useCallback(
        (key) => (_, value) => {
            setForm((prevState) => ({ ...prevState, [key]: value }));
        },
        [],
    );

    const onReset = () => {
        const uploadUrls = getSensorUploadUrls();
        dispatch(
            updateSensorUploadUrlBySensorType({
                sensorType,
                uploadUrl: uploadUrls.sensors[sensorType] ?? uploadUrls.default,
            }),
        );
    };

    const onSubmit = () => {
        dispatch(
            showSuccessNotification({
                show: true,
                message: 'Configuration updated!',
            }),
        );
        dispatch(
            updateSensorUploadUrlBySensorType({
                sensorType,
                uploadUrl: form,
            }),
        );
    };

    return (
        <AccordionItem
            key={sensorType}
            title={`${t('Uploading for')} ${sensorType}`}
            bodyHeight={ref?.current?.clientHeight ?? 180}
            defaultOpen={defaultOpen}
        >
            <FieldsWrapper ref={ref}>
                <TextField
                    label={t('Protocol')}
                    value={form.protocol}
                    required={true}
                    onChange={onFieldChange('protocol')}
                />
                <TextField label={t('Host')} value={form.host} required={true} onChange={onFieldChange('host')} />
                <TextField label={t('Port')} value={form.port} onChange={onFieldChange('port')} />
                <ButtonsWrapper>
                    <ResetButton onClick={onReset}>{t('Reset')}</ResetButton>
                    <PrimaryButton onClick={onSubmit}>{t('Submit')}</PrimaryButton>
                </ButtonsWrapper>
            </FieldsWrapper>
        </AccordionItem>
    );
};

/**
 * Модальное окно для обновления ручек выгрузки на датчиках
 */
const UpdateSensorsUploadUrlsModal = () => {
    return (
        <Wrapper>
            {Object.values(sensorTypes).map((sensorType, index) => {
                return <SensorItem key={sensorType} sensorType={sensorType} defaultOpen={index === 0} />;
            })}
        </Wrapper>
    );
};

export default UpdateSensorsUploadUrlsModal;
