import { Spinner, SpinnerSize } from '@fluentui/react';
import { BatchProgressWrapper, BatchProgressCircle, ErrorMessage } from './batchProgress.styles';

/**
 * Прогресс бар в пакетной загрузке данных
 * @param {object} element один из элементов пакетной загрузки
 * @param {number} number номер в кружке
 * @param {boolean} startBatchUpload флаг, отвечающий за старт пакетной загрузки данных
 */
const BatchProgress = ({ element, number, startBatchUpload }) => {
    switch (number) {
        case 1:
            return (
                <BatchProgressWrapper>
                    {element.sensorDataFetching ? (
                        <Spinner size={SpinnerSize.medium} />
                    ) : (
                        <>
                            <BatchProgressCircle color={element['sensorDataFetchingError'] ? 'red' : 'green'}>1</BatchProgressCircle>
                            <ErrorMessage>{element['sensorDataFetchingError'] ? `${element['sensorDataFetchingError']}` : null}</ErrorMessage>
                        </>
                    )}
                </BatchProgressWrapper>
            );
        case 2:
            return (
                <BatchProgressWrapper>
                    {!startBatchUpload ? (
                        <BatchProgressCircle color={'grey'}>2</BatchProgressCircle>
                    ) : (
                        <>
                            {element.postSensorFetching ? (
                                <Spinner size={SpinnerSize.medium} />
                            ) : (
                                <>
                                    <BatchProgressCircle color={element['postSensorError'] ? 'red' : 'green'}>2</BatchProgressCircle>
                                    <ErrorMessage>{element['postSensorError'] ? `${element['postSensorError']}` : null}</ErrorMessage>
                                </>
                            )}
                        </>
                    )}
                </BatchProgressWrapper>
            );

        case 3:
            return (
                <BatchProgressWrapper>
                    {!startBatchUpload ? (
                        <BatchProgressCircle color={'grey'}>3</BatchProgressCircle>
                    ) : (
                        <>
                            {element.postBoundleFetching ? (
                                <Spinner size={SpinnerSize.medium} />
                            ) : (
                                <>
                                    <BatchProgressCircle color={element['postBoundleError'] ? 'red' : 'green'}>3</BatchProgressCircle>
                                    <ErrorMessage>{element['postBoundleError'] ? `${element['postBoundleError']}` : null}</ErrorMessage>
                                </>
                            )}
                        </>
                    )}
                </BatchProgressWrapper>
            );

        default:
            return null;
    }
};
export default BatchProgress;
