import { DefaultButton } from '@fluentui/react';
import styled from 'styled-components/macro';

export const Wrapper = styled.div`
    overflow: auto;
    max-height: 70vh;
    width: 40vw;
    padding: 16px;
`;

export const ResetButton = styled(DefaultButton)`
    justify-self: end;
`;

export const FieldsWrapper = styled.div`
    display: grid;
`;

export const ButtonsWrapper = styled.div`
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
`;
