export const hideColumnOptions = [
    { key: 'sensorAddress', text: 'Address' },
    { key: 'serial_number', text: 'Serial Number' },
    { key: 'sensor_type', text: 'Sensor Type' },
    { key: 'sensorRelations', text: 'Sensor Relations' },
];

export const screenSizeOptions = [
    { key: 500, text: 'Large' },
    { key: 350, text: 'Medium' },
    { key: 275, text: 'Small' },
];
