import { DefaultButton, Spinner, SpinnerSize, TooltipHost } from '@fluentui/react';
import { useCallback, useContext, useMemo, useRef } from 'react';
import { ColumnWrapper } from '../../instalationPointsList.styles';
import { CustomTextArea, StyledIcon, stylesForButton, stylesForToolTip } from './xovisUpdateConfiguration.styles';
import { useTranslation } from 'react-i18next';
import getNewXovisConfiguration from '../../../../../../../../utils/xovisHelpers/getNewSensorConfiguration';
import { InstallationPointsContext } from '../../../../../../installationPoints';
import { isNull, isNumber } from 'lodash';

/**
 * Компонент для отрисовки колонки с обновление для XOVIS
 * @param {object} selectedSensor объект выбранного сенсора
 * @param {object} iPoint объект точки установки
 * @param {string} accessKey ключ для выгрузки
 * @param {object} availableBundlesByIPointId объект связок, где ключ это id связки
 */
const XovisUpdateConfiguration = ({ iPoint, selectedSensor, uploadKey, availableBundlesByIPointId }) => {
    const { updateSensorConfigurationThunkTrans, sensorUploadUrlsBySensorType } = useContext(InstallationPointsContext);

    const copyRef = useRef();
    const { t } = useTranslation();

    const uploadUrl = sensorUploadUrlsBySensorType[selectedSensor.sensor_type]?.uploadUrl;
    const CORRECT_URL = `${uploadUrl?.protocol}://${uploadUrl?.host}/fpc/v1/${selectedSensor.sensor_type}/${uploadKey}`;

    const bundle = useMemo(() => {
        if (availableBundlesByIPointId?.[iPoint?.id]) {
            return availableBundlesByIPointId[iPoint.id];
        }
        return null;
    }, [availableBundlesByIPointId, iPoint]);

    const version = useMemo(() => {
        if (bundle) {
            return Number(bundle.xovisData?.version.split('.')[0]);
        }
        return null;
    }, [bundle]);

    const copyHandler = () => {
        if (copyRef?.current) {
            copyRef.current.value = CORRECT_URL;
            copyRef.current.focus();
            copyRef.current.select();
        }

        try {
            document.execCommand('copy');
        } catch (error) {
            console.log(error);
        }
    };

    const updateHandler = useCallback(() => {
        const newConfiguration = getNewXovisConfiguration({
            sensor: selectedSensor,
            accessKey: uploadKey,
            uploadUrl,
        });

        if (newConfiguration) {
            updateSensorConfigurationThunkTrans({
                data: newConfiguration,
                sensorType: selectedSensor.sensor_type,
                iPointId: iPoint.id,
                availableBundlesByIPointId,
                sensorId: selectedSensor.id,
            });
        }
    }, [
        selectedSensor,
        uploadKey,
        uploadUrl,
        updateSensorConfigurationThunkTrans,
        iPoint.id,
        availableBundlesByIPointId,
    ]);

    const updateButton = useMemo(() => {
        return (
            <ColumnWrapper>
                <CustomTextArea ref={copyRef} />
                <DefaultButton styles={stylesForButton} onClick={updateHandler}>
                    {t('Update')}
                </DefaultButton>
                <TooltipHost styles={stylesForToolTip} content={CORRECT_URL}>
                    <StyledIcon onClick={copyHandler} iconName="Copy" />
                </TooltipHost>
            </ColumnWrapper>
        );
        // eslint-disable-next-line
    }, [copyRef, selectedSensor.sensor_type, uploadKey, updateHandler, t]);

    const infoButton = useMemo(() => {
        return (
            <ColumnWrapper>
                <CustomTextArea ref={copyRef} />
                <TooltipHost content={CORRECT_URL}>
                    <DefaultButton styles={stylesForButton} onClick={copyHandler}>
                        {t('Copy link')}
                    </DefaultButton>
                </TooltipHost>
                <TooltipHost styles={stylesForToolTip} content={t('No free url for update')}>
                    <StyledIcon iconName="Info" />
                </TooltipHost>
            </ColumnWrapper>
        );
        // eslint-disable-next-line
    }, [selectedSensor.sensor_type, t, uploadKey, updateHandler]);

    if (!uploadKey) {
        return (
            <ColumnWrapper>
                <>{t('No access key')}</>
            </ColumnWrapper>
        );
    }

    if (bundle?.updateConfigurationFetching) {
        return (
            <ColumnWrapper>
                <Spinner size={SpinnerSize.large} />
            </ColumnWrapper>
        );
    }

    if (isFinite(version)) {
        if (version < 5) {
            if (
                !bundle?.firstStepError &&
                bundle?.thirdStepError &&
                (bundle?.xovisData?.data?.config?.companyDatapushServer?.url ||
                    isNumber(bundle?.xovisData?.data?.config?.emptyDatapushAgentId))
            )
                return updateButton;

            if (isNull(!bundle?.xovisData?.data?.config?.emptyDatapushAgentId)) {
                return infoButton;
            }
        } else if (version >= 5) {
            if (!bundle?.firstStepError && bundle?.thirdStepError) {
                return updateButton;
            }
        }
    }

    return (
        <ColumnWrapper>
            <CustomTextArea ref={copyRef} />
            <TooltipHost content={CORRECT_URL}>
                <DefaultButton styles={stylesForButton} onClick={copyHandler}>
                    {t('Copy link')}
                </DefaultButton>
            </TooltipHost>
        </ColumnWrapper>
    );
};

export default XovisUpdateConfiguration;
