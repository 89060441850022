import { TooltipHost } from '@fluentui/react';
import { xovisProgressMessages } from '../../../../../../../../utils/xovisHelpers/xovisProgressMessages';
import { ColumnWrapper, CircleWrapper, Circle } from '../../instalationPointsList.styles';

/**
 *  Компонент для отрисовки прогресс бара для датчика XOVIS
 * @param {object} selectedSensor объект выбранного сенсора
 */
const XovisProgressColumn = ({ selectedSensor }) => {
    return (
        <>
            <ColumnWrapper>
                <CircleWrapper>
                    <TooltipHost
                        content={
                            selectedSensor
                                ? !selectedSensor.firstStepError
                                    ? xovisProgressMessages.firstStepEnabled
                                    : selectedSensor.firstStepError
                                : null
                        }
                    >
                        <Circle
                            newColor={
                                selectedSensor
                                    ? selectedSensor.isFetching
                                        ? 'grey'
                                        : !selectedSensor.firstStepError
                                        ? 'green'
                                        : 'red'
                                    : 'grey'
                            }
                        >
                            1
                        </Circle>
                    </TooltipHost>
                </CircleWrapper>

                <CircleWrapper>
                    <TooltipHost
                        content={
                            selectedSensor
                                ? !selectedSensor.secondStepError
                                    ? xovisProgressMessages.secondStepEnabled
                                    : selectedSensor.secondStepError
                                : null
                        }
                    >
                        <Circle
                            newColor={
                                selectedSensor
                                    ? selectedSensor.isFetching
                                        ? 'grey'
                                        : !selectedSensor.secondStepError
                                        ? 'green'
                                        : 'red'
                                    : 'grey'
                            }
                        >
                            2
                        </Circle>
                    </TooltipHost>
                </CircleWrapper>
                <CircleWrapper>
                    <TooltipHost
                        content={
                            selectedSensor
                                ? !selectedSensor.thirdStepError
                                    ? xovisProgressMessages.thirdStepEnabled
                                    : selectedSensor.thirdStepError
                                : null
                        }
                    >
                        <Circle
                            newColor={
                                selectedSensor
                                    ? selectedSensor.isFetching
                                        ? 'grey'
                                        : !selectedSensor.thirdStepError
                                        ? 'green'
                                        : 'red'
                                    : 'grey'
                            }
                        >
                            3
                        </Circle>
                    </TooltipHost>
                </CircleWrapper>
            </ColumnWrapper>
        </>
    );
};

export default XovisProgressColumn;
