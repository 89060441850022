import { DefaultButton, PrimaryButton, Modal } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";

import { DateTime } from "luxon";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  ButtonsWrapper,
  ContentWrapper,
  Wrapper,
  Title,
  contentStyles,
} from "./styles";

const TokenExpiration: React.FC = () => {
  const [isModalOpen, { setTrue: openModal, setFalse: hideModal }] =
    useBoolean(false);

  const { current } = useSelector((state) => state.generalReducer);
  const { t } = useTranslation();

  useEffect(() => {
    const interval = setInterval(() => {
      if (!current?.token_expires) return;
      const now = DateTime.now();
      const diff = DateTime.fromISO(current?.token_expires).diff(now, [
        "minutes",
        "seconds",
      ]);
      const minutesToExpire = diff.toObject().minutes || 60;
      if (minutesToExpire < 15) {
        onRestart();
        // setModalStatus({ show: true, hardReset: true });
      } else if (minutesToExpire < 60) {
        if (!isModalOpen) {
          openModal();
        }
      }
    }, 600000);

    return () => clearTimeout(interval);
  }, [current?.token_expires, isModalOpen, openModal]);

  const onRestart = () => {
    localStorage.removeItem("xtoken");
    window.location.reload();
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onDismiss={hideModal}
      isBlocking={false}
      containerClassName={contentStyles().container}
    >
      <Wrapper>
        <Title>{t("Session expiration")}</Title>
        <ContentWrapper>
          <div>
            {t("Your session will expire soon! You need to restart browser.")}
          </div>
        </ContentWrapper>
        <ButtonsWrapper>
          <DefaultButton onClick={hideModal}>{t("Cancel")}</DefaultButton>
          <PrimaryButton onClick={onRestart}>{t("Restart")}</PrimaryButton>
        </ButtonsWrapper>
      </Wrapper>
    </Modal>
  );
};

export default TokenExpiration;
