export const dataUploadColumns = [
    {
        key: 'sensorIp',
        name: 'Ip',
        fieldName: 'sensorIp',
        minWidth: 100,
        maxWidth: 100,
        isSorted: true,
        isSortedDescending: false,
        onColumnClick: true,
    },
    {
        key: 'port',
        name: 'Port',
        fieldName: 'port',
        minWidth: 60,
        maxWidth: 60,
        onColumnClick: true,
    },
    {
        key: 'managementSType',
        name: 'Type',
        fieldName: 'managementSType',
        minWidth: 100,
        maxWidth: 100,
        onColumnClick: true,
    },
    {
        key: 'pass',
        name: 'Password',
        fieldName: 'pass',
        minWidth: 100,
        maxWidth: 100,
        onColumnClick: true,
    },
    {
        key: 'user',
        name: 'Username',
        fieldName: 'user',
        minWidth: 100,
        maxWidth: 100,
        onColumnClick: true,
    },
    {
        key: 'plId',
        name: 'PL id',
        fieldName: 'plId',
        minWidth: 75,
        maxWidth: 75,
        onColumnClick: true,
    },
    {
        key: 'date_from',
        name: 'Date from',
        fieldName: 'date_from',
        minWidth: 200,
        maxWidth: 200,
    },
    {
        key: 'date_to',
        name: 'Date to',
        fieldName: 'date_to',
        minWidth: 200,
        maxWidth: 200,
    },
    {
        key: 'dataUploadStatuStatus',
        name: 'Status',
        fieldName: 'dataUploadStatuStatus',
        minWidth: 200,
        maxWidth: 200,
        onColumnClick: true,
    },
    {
        key: 'percentage',
        name: 'Uploading %',
        fieldName: 'percentage',
        minWidth: 100,
        maxWidth: 100,
        onColumnClick: true,
    },
];
