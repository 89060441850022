/**
 * Функция генерирует id, который еще не зайдествован агентом
 * @param {array} agents массив тэгов (агенты для выгрузки)
 * @returns массив свободных id для агентов
 */
const getValidIdForSensor = (agents) => {
    const initialArray = [];
    const finalArray = [];
    for (let element of agents) {
        if (element.attributes.hasOwnProperty('id')) {
            initialArray.push(element.attributes.id.value);
        }
    }
    const initialObject = initialArray.reduce((acc, value) => {
        acc[value] = true;
        return acc;
    }, {});

    for (let i = 0; i <= 100; i++) {
        if (!initialObject[i]) {
            finalArray.push(i);
        }
    }
    return finalArray;
};

export default getValidIdForSensor;
