/**
 * Функция для получения информации о всех масках
 * @param {string} config конфигурация сенсора
 * @returns массив со всеми масками
 */
const getMasksInfo = ({ config }) => {
    const finalArray = [];
    const oParser = new DOMParser();
    const xmlResponse = oParser.parseFromString(config, 'text/xml');
    const masksArray = xmlResponse.querySelectorAll('masking > movingmask');

    for (let mask of masksArray) {
        const attributes = mask.attributes;
        const marker = attributes.name.textContent;
        const maskCoordinates = [];
        const pointsArray = mask.querySelectorAll('point');

        for (let point of pointsArray) {
            maskCoordinates.push([+point.attributes.x.textContent, +point.attributes.y.textContent]);
        }

        finalArray.push({ marker, coordinates: maskCoordinates });
    }

    return finalArray;
};

export default getMasksInfo;
