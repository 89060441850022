import { createContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProjectLocationsList from './components/ProjectLocationsList/ProjectLocationsList';
import { getInitialData, getPLSyncInfo, storeSelectedPLId, toggleRedirecting } from './ProjectLocations.reducer';
import { ProjectLocationsWrapper } from './ProjectLocations.styles';
import { useNavigate } from 'react-router';

/**
 * Компонент для отображения проектных локаций
 */
export const ProjectLocationsContext = createContext();
const ProjectLocations = () => {
    const { token, storeUrls } = useSelector((state) => state.generalReducer);
    const { projectLocations, selectedPLId, selectedPLData, isRedirecting } = useSelector(
        (state) => state.projectLocationsReducer,
    );
    const dispatch = useDispatch();
    const nav = useNavigate();

    useEffect(() => {
        dispatch(getInitialData());
        // eslint-disable-next-line
    }, [token, storeUrls]);

    useEffect(() => {
        selectedPLId && isRedirecting && dispatch(getPLSyncInfo());
        // eslint-disable-next-line
    }, [selectedPLId]);

    useEffect(() => {
        if (selectedPLData && isRedirecting) {
            nav(`${window.location.pathname}/project-locations/${selectedPLId}/installation-points`);
            dispatch(toggleRedirecting(false));
        }
        // eslint-disable-next-line
    }, [selectedPLData]);

    const storeSelectedPLIdTrans = (id) => dispatch(storeSelectedPLId(id));

    const toggleRedirectingTrans = (value) => dispatch(toggleRedirecting(value));

    const contextValue = { projectLocations, storeSelectedPLIdTrans, toggleRedirectingTrans };

    return (
        <ProjectLocationsContext.Provider value={contextValue}>
            <ProjectLocationsWrapper>
                {projectLocations.length ? <ProjectLocationsList /> : null}
            </ProjectLocationsWrapper>
        </ProjectLocationsContext.Provider>
    );
};

export default ProjectLocations;
