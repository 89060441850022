/**
 * проверяет строку на соответстиве Ip структуре
 * @param {string} value значение из инпута
 * @returns true/false
 */
const checkIpValue = (value) => {
    const subips = value.split('.');
    if (subips.length > 4) {
        return false;
    }
    const invalidSubips = subips.filter((ip) => {
        ip = parseInt(ip);
        return ip < 0 || ip > 255;
    });
    if (invalidSubips.length !== 0) {
        return false;
    }
    let emptyIpCount = 0;
    subips.forEach((ip) => {
        if (ip === '') {
            emptyIpCount++;
        }
    });
    if (emptyIpCount > 1) {
        return false;
    }
    return true;
};

export default checkIpValue;
