import { IMessageBarStyles } from '@fluentui/react';
import styled from 'styled-components/macro';

export const TasksWrapper = styled.div`
    display: flex;
    border-bottom: 1px solid grey;
`;

export const WrappedItem = styled.div`
    flex: 0 0 50%;
    box-sizing: border-box;
    padding: ${(props: { isProcessed: boolean }) => (props.isProcessed ? '10px 0 10px 10px' : '10px 10px 10px 0')};
`;

export const TaskAttributeName = styled.span`
    font-weight: bold;
    margin-right: 5px;
`;

export const AttributeWrapper = styled.div`
    display: flex;
    margin-top: 5px;
`;

export const messageBarStyles: IMessageBarStyles = {
    root: {
        borderRadius: '5px',
        width: '100%',
        minHeight: '25px',
        marginTop: '5px',
        selectors: {
            '&:ferst-child': {
                marginTop: '0px',
            },
        },
    },
    iconContainer: {
        margin: '5px 5px',
    },
    text: {
        margin: '5px 5px',
    },
};
