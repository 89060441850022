export const columnsForList = [
    {
        key: 'ip',
        name: 'Ip',
        fieldName: 'ip',
        isResizable: false,
        minWidth: 300,
        maxWidth: 300,
    },
    { key: 'port', name: 'Port', fieldName: 'port', isResizable: false, minWidth: 100, maxWidth: 200 },
    {
        key: 'sensor_name',
        name: 'Sensor name',
        fieldName: 'sensor_name',
        isResizable: false,
        minWidth: 300,
        maxWidth: 300,
    },
    {
        key: 'sensor_type',
        name: 'Sensor type',
        fieldName: 'sensor_type',
        isResizable: false,
        minWidth: 300,
        maxWidth: 300,
    },
];

export const columnsForBoundlesList = [
    { key: 'currentBoundle', name: 'Current', fieldName: 'currentBoundle', isResizable: false, minWidth: 50, maxWidth: 50 },
    {
        key: 'iPointMarker',
        name: 'IPoint marker',
        fieldName: 'iPointMarker',
        isResizable: false,
        minWidth: 100,
        maxWidth: 100,
    },

    { key: 'sensorType', name: 'Sensor type', fieldName: 'sensorType', isResizable: false, minWidth: 100, maxWidth: 100 },
    { key: 'serialNumber', name: 'Serial number', fieldName: 'serialNumber', isResizable: false, minWidth: 130, maxWidth: 130 },
    { key: 'sensorId', name: 'Sensor id', fieldName: 'sensorId', isResizable: false, minWidth: 75, maxWidth: 75 },
    { key: 'sensorUrl', name: 'Sensor ip:port', fieldName: 'sensorUrl', isResizable: false, minWidth: 130, maxWidth: 130 },
    { key: 'date_from', name: 'Date from', fieldName: 'date_from', isResizable: false, minWidth: 250, maxWidth: 250 },
    { key: 'date_to', name: 'Date to', fieldName: 'date_to', isResizable: false, minWidth: 270, maxWidth: 270 },
    { key: 'update', name: 'Update', fieldName: 'update', isResizable: false, minWidth: 75, maxWidth: 75 },
    { key: 'remove', name: 'Remove', fieldName: 'remove', isResizable: false, minWidth: 75, maxWidth: 75 },
];
