/**
 *  Функция для получения разрещения изображения
 * @param {string} src изображение
 * @returns объект с разрешением изображениея
 */
const getImageDimensions = (src) => {
    return new Promise((resolve) => {
        let img = new Image();
        img.onload = () => resolve({ height: img.height, width: img.width });
        img.onerror = () => resolve({ height: 392, width: 640 });
        img.src = src;
    });
};

export default getImageDimensions;
