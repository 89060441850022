import { createSlice } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';

const initialState = {
    boundlesForSelectedIPoint: [],
    boundlesForSelectedIPointById: {},
    extendedBoundlesForSelectedIPointById: {},
    extendedBoundlesForSelectedIPoint: {},
    freeSensorsForBoundleCreate: [],
    sensorForBoundleCreate: null,
};

const boundleInfoModalReducer = createSlice({
    name: 'boundleInfoModalReducer',
    initialState,
    reducers: {
        // Запись в стейт связок для выбранной точки установки
        storeBoundlesForSelectedIPoint: (state, action) => {
            const boundles = action.payload.boundles.filter(
                (element) => element.installation_point_id === action.payload.iPointId,
            );
            const boundlesById = boundles.reduce((acc, value) => {
                acc[value.id] = { ...value };
                return acc;
            }, {});

            const extendedBoundles = [];

            const extendedBoundlesById = boundles.reduce((acc, element) => {
                const dt_from = DateTime.fromISO(element.date_from).setZone(action.payload.timezone);
                const dt_to = element.date_to
                    ? DateTime.fromISO(element.date_to).setZone(action.payload.timezone)
                    : null;

                const date_from = dt_from.toFormat('yyyy-MM-dd');
                const time_from = dt_from.toFormat('HH:mm:ss');

                const date_to = dt_to ? dt_to.toFormat('yyyy-MM-dd') : null;
                const time_to = dt_to ? dt_to.toFormat('HH:mm:ss') : '00:00:00';
                const value = {
                    ...element,
                    date_from,
                    time_from,
                    date_to,
                    time_to,
                    updateBoundleError: '',
                    time_to_error: false,
                    time_from_error: false,
                    updateBoundleFetching: false,
                    removeBoundleFetching: false,
                    removeBoundleError: '',
                };
                acc[element.id] = value;

                extendedBoundles.push({ ...value });

                return acc;
            }, {});

            state.extendedBoundlesForSelectedIPoint = extendedBoundles;
            state.boundlesForSelectedIPointById = boundlesById;
            state.extendedBoundlesForSelectedIPointById = extendedBoundlesById;
            state.boundlesForSelectedIPoint = boundles;
        },

        // Обновление расширенной связки
        updateExtendedBoundle: (state, action) => {
            const { boundleId, value, key } = action.payload;
            const extendedBoundlesByIdCopy = cloneDeep(state.extendedBoundlesForSelectedIPointById);
            extendedBoundlesByIdCopy[boundleId][key] = value;

            const extendedBoundles = Object.keys(extendedBoundlesByIdCopy).map(
                (element) => extendedBoundlesByIdCopy[element],
            );
            state.extendedBoundlesForSelectedIPoint = extendedBoundles;
            state.extendedBoundlesForSelectedIPointById = extendedBoundlesByIdCopy;
        },

        // Обнуление полей date_to и time_to
        resetDateToAndTimeTo: (state, action) => {
            const { boundleId } = action.payload;
            const extendedBoundlesByIdCopy = cloneDeep(state.extendedBoundlesForSelectedIPointById);

            extendedBoundlesByIdCopy[boundleId].date_to = null;
            extendedBoundlesByIdCopy[boundleId].time_to = '00:00:00';

            const extendedBoundles = Object.keys(extendedBoundlesByIdCopy).map(
                (element) => extendedBoundlesByIdCopy[element],
            );

            state.extendedBoundlesForSelectedIPoint = extendedBoundles;
            state.extendedBoundlesForSelectedIPointById = extendedBoundlesByIdCopy;
        },

        // Запсиь в стейт свободных сенсоров для связки
        storeFreeSensorsForBoundleCreate: (state, action) => {
            const { sensors, availableBundlesByIPointId } = action.payload;

            const availableSensors = sensors.filter((sensor) => {
                let flag = true;
                Object.keys(availableBundlesByIPointId).forEach((boundleId) => {
                    if (availableBundlesByIPointId[boundleId]?.id === sensor.id) flag = false;
                });
                return flag;
            });

            state.freeSensorsForBoundleCreate = availableSensors;
        },

        // Запись в стейт информации о сенсоре, с которым нужно создать новую связку
        storeSensorForBoundleCreate: (state, action) => {
            state.sensorForBoundleCreate = cloneDeep(action.payload);
        },

        // Обнуление редьюсера
        resetBoundleInfoReducer: () => initialState,
    },
});

export const {
    storeBoundlesForSelectedIPoint,
    updateExtendedBoundle,
    resetDateToAndTimeTo,
    storeFreeSensorsForBoundleCreate,
    storeSensorForBoundleCreate,
    resetBoundleInfoReducer,
} = boundleInfoModalReducer.actions;

export default boundleInfoModalReducer.reducer;
