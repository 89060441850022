import { BrickstreamSensor } from '../shared/sensors';

export const sensorTypes = {
    XOVIS: 'xovis',
    BRICKSTREAM: 'brickstream',
    VIVOTEK: 'vivotek',
    HIKVISION: 'hikvision',
    RSTAT: 'rstat',
    DILAX: 'dilax',
    TD: 'td',
    MEGACOUNT: 'megacount',
};

export const SENSOR_CLASSES_BY_TYPE = {
    [sensorTypes.BRICKSTREAM]: BrickstreamSensor,
};
