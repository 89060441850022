import { DateTime } from 'luxon';

/**
 * Функция для генерации данных для паектного запроса
 * @param {number} dateFromInMillis date_from
 * @param {number} dateToInMillis date_to
 * @param {object} sensor объект сенсора
 * @return объект с данными для пакетного запроса
 */
const generateVivotekDataForUploadRequest = (options) => {
    const { dateFromInMillis, dateToInMillis, sensor } = options;
    const convertedDateFrom = DateTime.fromMillis(dateFromInMillis).toFormat('yyyy-MM-dd HH:mm:ss').replace(' ', 'T');
    const convertedDateTo = DateTime.fromMillis(dateToInMillis).toFormat('yyyy-MM-dd HH:mm:ss').replace(' ', 'T');
    const data = {
        url: `http://${sensor.ip}:${sensor.port}/Stereo-Counting/cgi-bin/report_pull.cgi?starttime=${convertedDateFrom}&endtime=${convertedDateTo}&aggregation=60&format=xml&localtime=1`,
        initialData: sensor,
        user: sensor.user || 'admin',
        pass: sensor.pass,
        extraArgs: {
            dateInterval: { dateFrom: dateFromInMillis, dateTo: dateToInMillis },
        },
    };

    return data;
};

export default generateVivotekDataForUploadRequest;
