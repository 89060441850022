import { Icon } from '@fluentui/react';
import { FC, useEffect, useState } from 'react';
import { ITimeIntervalSuccessTasksProps } from './TimeIntervalSuccessTasks.interfaces';
import { Wrapper, WrappedItem } from './TimeIntervalSuccessTasks.styles';

const TimeIntervalSuccessTasks: FC<ITimeIntervalSuccessTasksProps> = ({ processedTasks, successTasks }) => {
    const [color, setColor] = useState('red');
    const [iconName, setIconName] = useState('ErrorBadge');

    useEffect(() => {
        if (processedTasks !== 0 && successTasks === processedTasks) {
            setColor('green');
            setIconName('CheckboxComposite');
        }

        if (successTasks !== processedTasks) {
            setColor('orange');
            setIconName('Warning');
        }

        if (successTasks === 0) {
            setColor('red');
            setIconName('ErrorBadge');
        }
    }, [processedTasks, successTasks]);

    return (
        <Wrapper>
            <WrappedItem>
                <Icon iconName={iconName} styles={{ root: { color, fontSize: '16px' } }} />
            </WrappedItem>
            <WrappedItem>{successTasks}</WrappedItem>
        </Wrapper>
    );
};

export default TimeIntervalSuccessTasks;
