import { xovisGetRequest } from '../../../../../api/xovis/xovis.api';

/**
 * Получение view masks
 * @param {object} sensor объект сенсора
 * @param {number} timeout timeout
 * @returns объект с ошибкой или данные
 */
const getXovisViewMasksV5 = async (args) => {
    const { sensor, timeout } = args;

    const viewMasks = await xovisGetRequest({
        url: `http://${sensor.ip}:${sensor.port}/api/v5/singlesensor/view/masks`,
        username: sensor.username,
        password: sensor.password,
        timeout,
    });

    if (!viewMasks.error) {
        return viewMasks.view_masks;
    }

    return {
        error: 'Ann error accrued while getting view masks',
    };
};

export default getXovisViewMasksV5;
