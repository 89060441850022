/**
 * Функция для парсинга ответа от сенсора
 * @param {string} data xml данные с сенсора
 * @returns объект с информацией о выгрузке с датчика
 */
const processRowUploadData = (data) => {
    const HOURS_IN_DAY = 24;
    const dataForUpload = [];
    const oParser = new DOMParser();
    const xmlResponse = oParser.parseFromString(data, 'text/xml');
    const matchElements = xmlResponse.querySelectorAll('matchElement');
    const numOfMatches = xmlResponse.querySelector('numOfMatches').textContent;
    const minutesInInterval = (HOURS_IN_DAY / +numOfMatches) * 60;
    for (let element of matchElements) {
        const startTime = element.querySelector('startTime').textContent;
        const endTime = element.querySelector('endTime').textContent;
        const enter = element.querySelector('enterCount').textContent;
        const exit = element.querySelector('exitCount').textContent;
        const pass = element.querySelector('peoplePassingCount').textContent;
        dataForUpload.push({ startTime, endTime, pass, enter, exit });
    }

    return { dataForUpload, minutesInInterval };
};

export default processRowUploadData;
