export const dateIntervalsColumns = [
    {
        key: 'sensorAddress',
        name: 'Address',
        fieldName: 'sensorAddress',
        minWidth: 200,
        maxWidth: 200,
        isSorted: true,
        isSortedDescending: false,
    },
    {
        key: 'serial_number',
        name: 'Serial number',
        fieldName: 'serial_number',
        minWidth: 200,
        maxWidth: 200,
    },
    {
        key: 'sensor_type',
        name: 'Sensor Type',
        fieldName: 'sensor_type',
        minWidth: 100,
        maxWidth: 100,
    },
    {
        key: 'sensorRelations',
        name: 'Sensor Relations',
        minWidth: 150,
        maxWidth: 150,
    },
    {
        key: 'screens',
        name: 'Screens',
        fieldName: 'screens',
        minWidth: 200,
        styles: {
            padding: '0px !importnat',
        },
    },
];
