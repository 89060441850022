import { IColumn } from '@fluentui/react';

export const plSensorsColumns: IColumn[] = [
    {
        key: 'ip',
        name: 'Ip',
        fieldName: 'ip',
        isSorted: true,
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
        isSortedDescending: false,
    },
    {
        key: 'port',
        name: 'Port',
        fieldName: 'port',
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
    },
    {
        key: 'type',
        name: 'Type',
        fieldName: 'type',
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
    },
    {
        key: 'user',
        name: 'Username',
        fieldName: 'user',
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
    },
    {
        key: 'pass',
        name: 'Password',
        fieldName: 'pass',
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
    },
    {
        key: 'timeIntervalTasks',
        name: 'Time interval tasks',
        fieldName: 'timeIntervalTasks',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
    },
    {
        key: 'timeIntervalProcessedTasks',
        name: 'TI processed tasks',
        fieldName: 'timeIntervalProcessedTasks',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
    },
    {
        key: 'timeIntervalSuccessTasks',
        name: 'TI success tasks',
        fieldName: 'timeIntervalSuccessTasks',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
    },
];
