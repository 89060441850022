import { Pivot, PivotItem, Spinner, SpinnerSize, ScrollbarVisibility } from '@fluentui/react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SingleScreen from '../../../commonComponents/singleScreen';
import { ScreensRow, ScreensWrapper, ScrollablePaneForScreens, ScreenWrapper } from './ScreensColumn.styles';

const pivotOptions = [
    { headerText: 'Single sensor screens', itemKey: 'single' },
    { headerText: 'Multi-sensor screens', itemKey: 'multi' },
];

/**
 * Компонент для отображения колонки скриншотов
 */
const ScreensColumn = ({ data, screenSize, selectedLocationTimeZone }) => {
    const [filteredScreens, setFilteredScreens] = useState([]);
    const [selectedKey, setSelectedKey] = useState('single');
    const { t } = useTranslation();

    /** Получение информации о типе датчика (мультисенсор или сингл) */
    const isMulti = useMemo(() => {
        return data.screensInfo.some((element) => element.sensor_info.is_multi);
    }, [data.screensInfo]);

    useEffect(() => {
        if (isMulti) {
            if (selectedKey === 'single') {
                setFilteredScreens(
                    data.screensInfo.filter((element) => element.sensor_screen && !element.sensor_info.is_multi),
                );
            }
            if (selectedKey === 'multi') {
                setFilteredScreens(
                    data.screensInfo.filter((element) => element.sensor_screen && element.sensor_info.is_multi),
                );
            }
        } else {
            setFilteredScreens(data.screensInfo.filter((element) => element.sensor_screen));
        }
    }, [data, isMulti, selectedKey]);

    if (data.screenInfoFetching) {
        return (
            <ScreensRow>
                <Spinner size={SpinnerSize.large} />
            </ScreensRow>
        );
    }

    if (data.screensFetchError) {
        return <ScreensRow>{data.screensFetchError}</ScreensRow>;
    }

    if (data.screensInfo.length === 0) return null;

    const handleLinkClick = (item) => {
        if (item) {
            setSelectedKey(item.props.itemKey);
        }
    };

    return (
        <ScreensRow>
            {isMulti && (
                <Pivot selectedKey={selectedKey} onLinkClick={handleLinkClick}>
                    {pivotOptions.map((element) => (
                        <PivotItem key={element.itemKey} headerText={t(element.headerText)} itemKey={element.itemKey} />
                    ))}
                </Pivot>
            )}

            <ScreensWrapper size={screenSize.key}>
                <ScrollablePaneForScreens scrollbarVisibility={ScrollbarVisibility.auto}>
                    {filteredScreens.map((element, i) => (
                        <ScreenWrapper key={i + Math.random()}>
                            <SingleScreen
                                screenInfo={element}
                                size={screenSize.key}
                                selectedLocationTimeZone={selectedLocationTimeZone}
                                sensorType={data.sensor_type}
                            />
                        </ScreenWrapper>
                    ))}
                </ScrollablePaneForScreens>
            </ScreensWrapper>
        </ScreensRow>
    );
};

export default ScreensColumn;
