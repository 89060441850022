import { createSlice } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import * as Papa from 'papaparse';
import fileAndStringDataConsolidation from '../../../utils/batchDownloadHelper/fileAndStringDataConsolidation';

const initialState = {
    initialDatafromCSVString: null,
    extendedDataFromCSVSourcesById: null,
    maxPackagesError: false,
    parseErrors: [],
    isAllSensorsFetched: false,
    isItPossibleToCloseModal: true,
    startBatchUpload: false,
    notEqualSerialNumbers: [],
};

const batchDownloadReducer = createSlice({
    name: 'batchDownloadReducer',
    initialState,
    reducers: {
        // Запись в стейт информации из csv источников
        storeInitialDataFromCSVSources: (state, action) => {
            state.initialDatafromCSVString = action.payload;
        },

        // Запись в стейт расширенной информации из csv источников
        storeExtendedDataFromCSVSources: (state, action) => {
            state.extendedDataFromCSVSourcesById = action.payload;
        },

        // Запсиь в стейт информации об ошибках при парсинге csv
        storeParseErrors: (state, action) => {
            state.parseErrors = action.payload;
        },

        // Обновление одного csv пакета
        updateSingleDataPackage: (state, action) => {
            const { data } = action.payload;
            state.extendedDataFromCSVSourcesById[data.id] = data;
        },

        // Запсиь в стейт информации об ошибки с максимальным числом пакетов
        storeMaxPackagesError: (state, action) => {
            state.maxPackagesError = action.payload;
        },

        // Измение флана, позволяющего закрывать это модальноек окно
        storePossibleToCloseModal: (state, action) => {
            state.isItPossibleToCloseModal = action.payload;
        },

        // Изменение флага, отвечающего за опрос всех сенсоров
        storeAllSensorsFetched: (state, action) => {
            state.isAllSensorsFetched = action.payload;
        },

        // Изменение флага для старта пакетной загрузки
        storeStartBatchUpload: (state, action) => {
            state.startBatchUpload = action.payload;
        },

        // Запись в стейт информации о новых неэквивалентных серийниках
        storeNotEqualSerailNumbers: (state, action) => {
            const arrayCopy = cloneDeep(state.notEqualSerialNumbers);
            arrayCopy.push(action.payload);
            state.notEqualSerialNumbers = arrayCopy;
        },

        // Обнуление не эквивалентных серийников
        resetNotEqualSerialNumber: (state) => {
            state.notEqualSerialNumbers = [];
        },

        // Обнуление стора
        resetBatchStore: () => initialState,
    },
});

export const {
    storeInitialDataFromCSVSources,
    storeParseErrors,
    updateSingleDataPackage,
    storeExtendedDataFromCSVSources,
    resetBatchStore,
    storeMaxPackagesError,
    storePossibleToCloseModal,
    storeAllSensorsFetched,
    storeStartBatchUpload,
    storeNotEqualSerailNumbers,
    resetNotEqualSerialNumber,
} = batchDownloadReducer.actions;

/**
 * Thunk для получения данных из файла
 * @param {file} CSVFile csv файл
 * @param {string} CSVString csv строка
 */
export const CSVParserThunk = (CSVFile, CSVString) => async (dispatch) => {
    dispatch(resetNotEqualSerialNumber());
    dispatch(storeParseErrors([]));
    let dataFromParsedCSVFile = null;
    let dataFromParsedCSVString = null;
    const errors = [];

    try {
        if (CSVFile) {
            const parseResult = await new Promise((resolve) => {
                Papa.parse(CSVFile, {
                    header: true,
                    skipEmptyLines: true,
                    complete: (results, _) => {
                        resolve(results);
                    },
                });
            });

            if (parseResult.errors.length > 0) {
                parseResult.errors.forEach((element) => {
                    errors.push({ ...element, elementData: cloneDeep(parseResult.data[element.row]) });
                });
            }

            if (parseResult.data.length > 0) dataFromParsedCSVFile = parseResult.data;
        }

        if (CSVString) {
            const parseResult = Papa.parse(CSVString, {
                header: true,
                skipEmptyLines: true,
            });

            if (parseResult.errors.length > 0) {
                parseResult.errors.forEach((element) => {
                    errors.push({ ...element, elementData: cloneDeep(parseResult.data[element.row]) });
                });
            }
            if (parseResult.data.length > 0) dataFromParsedCSVString = parseResult.data;
        }

        const finalParsedData = fileAndStringDataConsolidation(dataFromParsedCSVFile, dataFromParsedCSVString, errors);
        dispatch(storeParseErrors(errors));

        if (finalParsedData && finalParsedData.initialData.length > 0) {
            dispatch(storeExtendedDataFromCSVSources(finalParsedData.extendedData));
            dispatch(storeInitialDataFromCSVSources(finalParsedData.initialData));
        } else {
            dispatch(storePossibleToCloseModal(true));
        }
    } catch (error) {
        console.log('>>>>bacth parse error', error);
    }
};

export default batchDownloadReducer.reducer;
