import axios from 'axios';
import { Sensor } from '../sensor.base';
import { DateTime } from 'luxon';

export class Brickstream3300Sensor extends Sensor {
    VERSION = 3300;

    #UPLOAD_URLS_NUMBER_BY_DATAPUSH_INDEX = {
        1: 'batch1',
        2: 'batch2',
    };

    /**
     * Метод для получения информации о прошивке датчика
     */
    async getIdentification() {
        const response = await this.#getRequest({
            url: `http://${this.ip}:${this.port}/restapi/deviceSettings/GetIdentification`,
        });

        if (response.isError) {
            this.errors.push(response.message);
        }

        return response;
    }

    /**
     * Метод для получения информации о серверах выгрузки
     */
    async getDataPushServers() {
        const response = await this.#getRequest({
            url: `http://${this.ip}:${this.port}/restapi/dataDelivery/GetBatchSettings`,
        });

        if (response.isError) {
            this.errors.push(response.message);
        }

        return response;
    }

    /**
     * Метод для обработки серверов выгрузки
     */
    processDataPushServers(dataPushServers) {
        const { httpdeliveryinfo } = dataPushServers || {};
        const NUMBER_OF_SERVERS = 2;
        const _dataPushServers = [];
        let companyDataPushServer = null;
        if (httpdeliveryinfo) {
            for (let i = 1; i <= NUMBER_OF_SERVERS; i++) {
                const host = httpdeliveryinfo?.[`sniHostName${i}`];
                const ip = httpdeliveryinfo?.[`ipAddress${i}`];
                const server = {
                    index: i,
                    enabled: httpdeliveryinfo?.[`enabled${i}`],
                    host,
                    ip,
                    url: httpdeliveryinfo?.[`destination${i}`],
                    port: httpdeliveryinfo?.[`portNumber${i}`],
                    pushGranularity: httpdeliveryinfo?.[`aggregationLevel${i}`],
                    pushInterval: httpdeliveryinfo?.[`deliverySchedule${i}`],
                    encryptData: httpdeliveryinfo?.[`encrypt${i}`],
                    isEmpty: (host === '0.0.0.0' || host === '') && (ip === '0.0.0.0' || ip === ''),
                };

                if (
                    server.ip?.includes(this.companyInfo.getCompanyUploadUrlPart()) ||
                    server.host?.includes(this.companyInfo.getCompanyUploadUrlPart())
                ) {
                    companyDataPushServer = { ...server };
                }

                _dataPushServers.push(server);
            }
        }

        return { dataPushServers: _dataPushServers, companyDataPushServer };
    }

    /**
     * Метод для получения слоев с сервера (пол, линии подсчета, этаж)
     */
    async getCountZones() {
        const response = await this.#getRequest({
            url: `http://${this.ip}:${this.port}/restapi/zones/GetCountZones`,
        });

        if (response.isError) {
            this.errors.push(response.message);
        }

        return response;
    }

    /**
     * Метод для получения слоев из ответа от getCountZones
     */
    getLayers(countZonesResponse) {
        const { countZones } = countZonesResponse || {};
        const passWays = [];
        const exceptions = [];
        const floors = [];
        if (countZones?.zones?.length) {
            countZones.zones.forEach((zone) => {
                const zoneAlias = `${zone.name}${zone.id}`;
                const layers = this.#getPasswaysAndExceptions(zone.lines, zoneAlias);
                const _floors = this.#getFloors(zone.polygons, zoneAlias);
                passWays.push(...layers.passWays);
                exceptions.push(...layers.exceptions);
                floors.push(..._floors);
            });
        }

        return { passWays, zones: [], floors, exceptions, masks: [] };
    }

    /**
     * Метод для получения слоев проходов и исключений
     */
    #getPasswaysAndExceptions(lines, zoneAlias) {
        const passWays = [];
        const exceptions = [];
        if (Array.isArray(lines) && lines.length) {
            lines.forEach((line, index) => {
                const marker = `${zoneAlias}_${line.type}_${index}`;
                const coordinates = [
                    [Number(line.image.x1), Number(line.image.y1)],
                    [Number(line.image.x2), Number(line.image.y2)],
                ];

                const layer = { marker, coordinates };
                if (line.type === 'exclude') {
                    exceptions.push(layer);
                } else {
                    passWays.push(layer);
                }
            });
        }

        return { passWays, exceptions };
    }

    /**
     * Метод для получения слоев пола
     */
    #getFloors(polygons, zoneAlias) {
        const floors = [];

        if (Array.isArray(polygons) && polygons.length) {
            polygons.forEach((polygon, index) => {
                const marker = `${zoneAlias}_${polygon.type}_${index}`;
                const coordinates = polygon.image.map((coords) => [Number(coords.x), Number(coords.y)]);
                const layer = { marker, coordinates };
                floors.push(layer);
            });
        }

        return floors;
    }

    /**
     * Метод для обновления выгрузки на датчике
     */
    async updateDataPushServer(dataPushServersSRC, dataPushServersCFG, dataPushServerUrl = '') {
        const queryString = this.generateUpdateDataPushServerQueryString(
            dataPushServersSRC,
            dataPushServersCFG,
            dataPushServerUrl,
        );

        if (queryString !== null) {
            const response = await this.#getRequest({
                url: `http://${this.ip}:${this.port}/restapi/dataDelivery/UpdateBatchSettings?${queryString}`,
            });

            if (response.isError || response.status !== 'true') {
                this.errors.push('Ann error accrued while update data push server');
            }

            if (response.status !== 'true') {
                return {
                    isError: true,
                    message: 'Ann error accrued while update data push server',
                };
            }

            return response;
        }
        this.errors.push('Ann error accrued while update data push server');
        return {
            isError: true,
            message: 'Ann error accrued while update data push server',
        };
    }

    /**
     * Метод для получения query Параметров для обновления выгрузки на датчике
     */
    generateUpdateDataPushServerQueryString(dataPushServersSRC, dataPushServersCFG, dataPushServerUrl = '') {
        let index = null;
        if (dataPushServersCFG?.companyDataPushServer) {
            index = dataPushServersCFG.companyDataPushServer.index;
        } else {
            const emptyDataPushServer = dataPushServersCFG?.dataPushServers?.find((server) => server.isEmpty);

            if (emptyDataPushServer) {
                index = emptyDataPushServer.index;
            }
        }

        if (typeof index === 'number' && dataPushServersSRC.httpdeliveryinfo) {
            const correctDataPushServer = this.companyInfo.getSensorUploadUrls()?.sensors?.brickstream ?? {};

            const server = {
                [`enabled${index}`]: 'true',
                [`portNumber${index}`]: correctDataPushServer.port ?? '',
                [`ipAddress${index}`]: correctDataPushServer.host ?? '',
                [`sniHostName${index}`]: correctDataPushServer.host ?? '',
                [`destination${index}`]: dataPushServerUrl,
                [`deliverySchedule${index}`]: this.CORRECT_DATA_PUSH_SERVER_TIMINGS.pushInterval,
                [`aggregationLevel${index}`]: this.CORRECT_DATA_PUSH_SERVER_TIMINGS.pushGranularity,
                [`encrypt${index}`]: 'true',
                [`deliveryWindow${index}`]: '15',
            };

            const result = { ...dataPushServersSRC.httpdeliveryinfo, ...server };

            return Object.entries(result).reduce((acc, [key, value]) => {
                const str = `${key}=${value}`;
                if (acc) {
                    acc += `&${str}`;
                } else {
                    acc += str;
                }
                return acc;
            }, '');
        }

        return null;
    }

    /**
     * Метод для получения данных датчика для работы с табличками
     * точек установки и тд
     */
    async getSensorData(identification) {
        return Promise.all([this.getCountZones(), this.getDataPushServers()])
            .then(([countZones, dataPushServers]) => {
                if (countZones.isError || dataPushServers.isError) {
                    this.errors.push('Get sensor data error');

                    return {
                        isError: true,
                        message: 'Ann error accrued while getting data',
                    };
                }
                return {
                    version: this.VERSION,
                    errors: this.errors,
                    sensorType: this.sensorType,
                    src: { identification, countZones, dataPushServers },
                    cfg: {
                        dataPushServers: this.processDataPushServers(dataPushServers),
                        layers: this.getLayers(countZones),
                    },
                };
            })
            .catch((error) => {
                this.errors.push('Get sensor data error');
                return {
                    isError: true,
                    message: 'Ann error accrued while getting data',
                    error,
                };
            });
    }

    /**
     * Метод для проверки связи с датчиком
     */
    async connectionTest() {
        const ITERATIONS_COUNT = 3;
        let orderCount = 0;
        let successOrderCount = 0;

        for (let i = 0; i < ITERATIONS_COUNT; i++) {
            orderCount += 1;
            const response = await this.getIdentification();

            if (!response.isError) {
                successOrderCount += 1;
            }
            await new Promise((resolve) => setTimeout(resolve, 2000));
        }

        return { orderCount, successOrderCount, errors: this.errors };
    }

    /**
     * Метод для переотправки данных с датчика
     * @param {string} dateFrom ISO format date
     * @param {string} dateTo ISO format date
     */
    async resendData(dateFrom, dateTo) {
        const dateFromDateTime = DateTime.fromISO(dateFrom);
        const dateToDateTime = DateTime.fromISO(dateTo);

        let orderCount = 1;
        let successOrderCount = 0;

        if (!dateFromDateTime.isValid || !dateToDateTime.isValid) {
            this.errors.push('Invalid dates');
        }

        await Promise.all([this.getCountZones(), this.getDataPushServers()]).then(
            async ([countZonesResponse, dataPushServersResponse]) => {
                if (countZonesResponse.isError) {
                    this.errors.push('Ann error accrued while getting count zones');
                }
                if (dataPushServersResponse.isError) {
                    this.errors.push('Ann error accrued while getting data push servers');
                }

                if (!this.errors.length) {
                    const layers = this.getLayers(countZonesResponse);
                    const dataPushServers = this.processDataPushServers(dataPushServersResponse);

                    if (!layers.passWays.length) {
                        this.errors.push('Number of passways is 0');
                    }

                    if (
                        !dataPushServers.companyDataPushServer ||
                        !this.#UPLOAD_URLS_NUMBER_BY_DATAPUSH_INDEX[dataPushServers.companyDataPushServer.index]
                    ) {
                        this.errors.push('No company datapush server');
                    }

                    if (!this.errors.length) {
                        const payload = {
                            dataType:
                                this.#UPLOAD_URLS_NUMBER_BY_DATAPUSH_INDEX[dataPushServers.companyDataPushServer.index],
                            startDate: dateFromDateTime.toFormat('MM/dd/yyyy'),
                            startTime: dateFromDateTime.toFormat('HH:mm'),
                            endDate: dateToDateTime.toFormat('MM/dd/yyyy'),
                            endTime: dateToDateTime.toFormat('HH:mm:ss'),
                        };

                        const response = await this.#getRequest({
                            url: `http://${this.ip}:${this.port}/restapi/dataDelivery/SendNow?${new URLSearchParams(
                                payload,
                            ).toString()}`,
                        });

                        if (response.isError || response.status !== 'true') {
                            this.errors.push('Ann error accrued while resend data');
                        } else {
                            successOrderCount += 1;
                        }
                    }
                }
            },
        );

        return {
            errors: this.errors,
            orderCount,
            successOrderCount,
            failedIntervals: successOrderCount < 1 ? [{ dateFrom, dateTo }] : [],
        };
    }

    async getScreen() {
        const response = await this.#getRequest({
            url: `http://${this.ip}:${this.port}/rightimage.jpg`,
            responseType: 'blob',
        });

        if (response.isError) {
            this.errors.push('Ann error accrued while getting screen');
            return response;
        }

        if (response.isError) {
            this.errors.push('Ann error accrued while getting screen');
            return response;
        }

        let parsedScreen = await new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(response);
            reader.onloadend = () => resolve({ screen: reader.result });
            reader.onerror = () => resolve({ error: 'Parse screen error' });
        });

        if (parsedScreen.error) {
            this.errors.push(parsedScreen.error);
            return {
                isError: true,
                message: parsedScreen.error,
            };
        }

        return {
            screen: parsedScreen.screen,
        };
    }

    /**
     * Метод для отправки скриншота с датчика
     * @param {string} url URL на который нужно сделать POST для сохранения скриншота
     */
    async sendScreen(url, identification) {
        await Promise.all([this.getCountZones(), this.getScreen()]).then(
            async ([countZonesResponse, screenResponse]) => {
                if (countZonesResponse.isError) {
                    this.errors.push(countZonesResponse.message);
                }

                if (screenResponse.isError) {
                    this.errors.push(screenResponse.message);
                }

                if (!this.errors.length) {
                    const layers = this.getLayers(countZonesResponse);

                    const payload = {
                        sensor_data: {
                            serial_number: identification?.identification?.MAC,
                            sensor_type: this.sensorType,
                            date_from: DateTime.now()
                                .toUTC()
                                .set({ milliseconds: 0 })
                                .toISO({ suppressMilliseconds: true }),
                            layers: {
                                pass_ways: layers.passWays,
                                zones: layers.zones,
                                floors: layers.floors,
                                exceptions: layers.exceptions,
                                masks: layers.masks,
                            },
                        },
                        screen: screenResponse.screen,
                    };

                    const response = await this.#postRequest({
                        url: `${url}?serial_number=${identification?.identification?.MAC}&sensor_type=${this.sensorType}`,
                        data: payload,
                        headers: { 'Content-type': 'application/json;charset=UTF-8' },
                    });

                    if (response.isError) {
                        this.errors.push('Post to lambda error');
                    }
                }
            },
        );
        return {
            errors: this.errors,
        };
    }

    /**
     * GET запрос
     * @typedef {object} IGetArgs
     * @property {string} url URL для запроса
     * @property {string} responseType Тип ответа
     *
     * @param {IGetArgs} args аргументы
     *
     * @returns {Promise<unknown> | Promise<IError>}
     */
    async #getRequest(args) {
        try {
            const data = await axios
                .get(args.url, {
                    timeout: this.REQUEST_TIMEOUT_IN_SECONDS * 1000,
                    auth: {
                        username: this.username,
                        password: this.password,
                    },
                    responseType: args.responseType,
                })
                .then((response) => response.data);
            return data;
        } catch (error) {
            return { isError: true, message: 'Ann error accrued while getting data', error };
        }
    }
    /**
     * POST запрос
     * @typedef {object} IPostArgs
     * @property {string} url URL для запроса
     * @property {unknown} data Данные для POST запроса
     * @property {object} headers Данные для POST запроса
     *
     * @param {IPostArgs} args аргументы
     *
     * @returns {Promise<unknown> | Promise<IError>}
     */
    async #postRequest(args) {
        try {
            const data = await axios
                .post(args.url, args.data, {
                    timeout: this.REQUEST_TIMEOUT_IN_SECONDS * 1000,
                    headers: args.headers,
                    auth: {
                        username: this.username,
                        password: this.password,
                    },
                })
                .then((response) => response.data);
            return data;
        } catch (error) {
            return { isError: true, message: 'Ann error accrued while post data', error };
        }
    }
}
