import { cloneDeep } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import List from '../../../../commonComponents/List/List';
import { ProjectsContext } from '../../Projects';
import { projectsListColumns } from './ProjectsList.columns';
import FilterInput from '../../../../commonComponents/FilterInput/FilterInput';
import { FilterWrapper } from './ProjectsList.styles';
import { useNavigate } from 'react-router';

/**
 * Компонент для отрисовки списка всех проектов
 */
const ProjectsList = () => {
    const { storeSelectedProjectIdTrans, projects } = useContext(ProjectsContext);
    const [filteredProjects, setFilteredProjects] = useState([]);
    const nav = useNavigate();

    useEffect(() => {
        projects.length && setFilteredProjects(cloneDeep(projects));
    }, [projects]);

    const onRowClick = (row) => {
        storeSelectedProjectIdTrans(row.id);
        nav(`/projects/${row.id}`);
    };

    return (
        <>
            <FilterWrapper>
                <FilterInput
                    inputArray={projects}
                    updateFilteredArray={setFilteredProjects}
                    matchFields={['name', 'id', 'date_from', 'date_to']}
                />
            </FilterWrapper>
            <List
                onRowClick={onRowClick}
                items={filteredProjects}
                columns={projectsListColumns}
                availableSortKeys={['name', 'id', 'date_from', 'date_to']}
            />
        </>
    );
};
export default ProjectsList;
