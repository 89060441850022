import axios from 'axios';

/**
 * Функция для получения конфигурации сенсора
 * @param {string} url url по которому нужно обращатсья к сенсору
 * @param {string} login логин для доступа к датчику
 * @param {string} password пароль для доступа к датчику
 * @returns конфигурацию сенсора или ошибку
 */
export const hikvisionGetRequest = async (url, login, password) => {
    try {
        const data = await axios
            .get(url, {
                timeout: 15000,
                auth: {
                    username: login,
                    password: password,
                },
            })
            .then((resposne) => resposne.data);
        return data;
    } catch (error) {
        return {
            error,
        };
    }
};

/**
 * Post запрос на датчик
 * @param {string} url url по которому нужно обращатсья к сенсору
 * @param {any} body тело пост запроса
 * @param {string} login логин для доступа к датчику
 * @param {string} password пароль для доступа к датчику
 * @returns данные или ошибку
 */
export const hikvisionPostRequest = async (url, body, login, password) => {
    try {
        const data = await axios
            .post(url, body, {
                timeout: 15000,
                auth: {
                    username: login,
                    password: password,
                },
            })
            .then((resposne) => resposne.data);
        return data;
    } catch (error) {
        return {
            error,
        };
    }
};

/**
 * PUT запрос на датчик
 * @param {string} url url по которому нужно обращатсья к сенсору
 * @param {any} body тело пост запроса
 * @param {string} login логин для доступа к датчику
 * @param {string} password пароль для доступа к датчику
 * @returns данные или ошибку
 */
export const hikvisionPutRequest = async (url, body, login, password) => {
    try {
        const data = await axios
            .put(url, body, {
                timeout: 15000,
                auth: {
                    username: login,
                    password: password,
                },
            })
            .then((resposne) => resposne.data);
        return data;
    } catch (error) {
        return {
            error,
        };
    }
};
