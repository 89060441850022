import { Tag, Text, Label } from 'react-konva';
import scaleTransform from '../../../utils/locationDisplay/scaleTransform';

/**
 * Лейбел для сенсора
 * @param {number} x координа х для конваса
 * @param {number} y координата y для конваса
 * @param {string} marker название маркера
 * @param {number} stageScale скейл канваса
 * @param {number} opacity прозрачность
 */
const ObjectLabel = ({ x, y, marker, stageScale = 1.3, opacity }) => {
    const markerText = marker ? `${'Marker'}: ${marker}` : '';
    return (
        <Label opacity={opacity === 1 ? 0.75 : 0.5} x={x} y={y}>
            <Tag
                fill={'black'}
                pointerDirection={'down'}
                pointerWidth={scaleTransform(10, stageScale)}
                pointerHeight={scaleTransform(10, stageScale)}
                lineJoin={'round'}
                shadowColor={'black'}
                shadowBlur={2}
                shadowOffsetX={2}
                shadowOffsetY={2}
                shadowOpacity={0.5}
            />
            <Text
                text={markerText}
                fontSize={scaleTransform(14, stageScale)}
                lineHeight={1.2}
                padding={scaleTransform(5, stageScale)}
                fill={'white'}
            />
        </Label>
    );
};

export default ObjectLabel;
