import { cloneDeep } from 'lodash';
import copyAndSortListItems from './copyAndSortListItems';

/**
 * Функция для сортировки элементов списка
 * @param {array} allColumns массив всех колонок списка
 * @param {object} column выбранная колонка для сортировки
 * @param {array} listItems массив всех элементов списка
 * @returns новые колонки для списка и отсортированные элементы для списка
 */
const columnSortHandler = (args) => {
    const {
        allColumns,
        column,
        listItems,
        listUpdate = false,
        boundles = null,
        sensorsForManagementById = null,
        slavesByIp = null,
    } = args;

    const newColumns = cloneDeep(allColumns);
    const currentColumn = newColumns.find((element) => element.key === column.key);

    if (!listUpdate) {
        newColumns.forEach((element) => {
            if (element === currentColumn) {
                currentColumn.isSortedDescending = !column.isSortedDescending;
                currentColumn.isSorted = true;
            } else {
                element.isSorted = false;
                element.isSortedDescending = true;
            }
        });
    }

    const newItems = copyAndSortListItems({
        items: listItems,
        columnKey: currentColumn.fieldName,
        isSortedDescending: currentColumn.isSortedDescending,
        boundles,
        sensorsForManagementById,
        slavesByIp,
    });

    return {
        newItems,
        newColumns,
    };
};

export default columnSortHandler;
