import { xovisGetRequest } from '../../../../../api/xovis/xovis.api';
import checkErrorInXovisResponse from '../../../../../utils/xovisHelpers/checkErrorInXovisResponse';

/**
 * Повторная отправка данных с датчика
 * @param {object} sensor объект датчика
 * @param {number} timeout timeout
 * @param {number} dateFromInMillis дата от которой запросить данные
 * @param {number} dateToInMillis дата до которой запросить данные
 * @param {number} agentId Агент по которому запросить данные
 * @returns Объект с ошибкой или данные
 */
const resendXovisData = async (args) => {
    const { sensor, timeout, dateFromInMillis, dateToInMillis, agentId } = args;

    const resendResponse = await xovisGetRequest({
        url: `http://${sensor.ip}:${sensor.port}/api/data-push/manual?passwd=${sensor.password}&id=${agentId}&from=${dateFromInMillis}&to=${dateToInMillis}`,
        timeout,
        username: sensor.username,
        password: sensor.password,
    });

    if (!resendResponse.error) {
        const error = checkErrorInXovisResponse(resendResponse);

        if (!error) {
            return resendResponse;
        }

        return { error };
    }

    return { error: 'Ann error accrued while resend data' };
};

export default resendXovisData;
