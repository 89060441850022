import { tdGetRequest } from './td.api';

/**
 * Функция для получения скриншота от датчика hikvision
 * @param {object} sensor объект сенсора
 * @param {number} timeout таймаут
 * @returns error || data object
 */
const getTdScreen = async (sensor, timeout) => {
    const data = await tdGetRequest({
        url: `http://${sensor.ip}:${sensor.port}/video_feed/1`,
        responseType: 'blob',
        timeout,
    });

    if (!data.error) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(data);
            reader.onload = () => {
                resolve({ screen: reader.result, sensor: { ...sensor } });
            };
            reader.onerror = () =>
                reject({ error: 'Get screen error', sensor: { ...sensor } });
        });
    }
    return {
        error: 'An error accrued while getting TD screen',
        sensor: { ...sensor },
    };
};

export default getTdScreen;
