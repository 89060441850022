import { xovisGetRequest } from '../../../../../api/xovis/xovis.api';

/**
 * Получение информации о версии датчика и его серийном номере
 * @param {object} sensor объект сенсора
 * @param {number} timeout таймаут
 * @returns Объект с ошибкой или объект с информацией о версии датчика и его серийный номер
 */
const getXovisInfo = async (args) => {
    const { sensor, timeout } = args;

    const info = await xovisGetRequest({
        url: `http://${sensor.ip}:${sensor.port}/api/info`,
        username: sensor.username,
        password: sensor.password,
        timeout,
    });

    if (!info.error && info) {
        const oParser = new DOMParser();
        const infoXML = oParser.parseFromString(info, 'text/xml');

        const version =
            infoXML.querySelector('version[type="SW"]')?.textContent;
        const serialNumber =
            infoXML.querySelector('serial-number')?.textContent;

        return { version, serialNumber };
    }

    return { error: 'An error accrued while getting info' };
};

export default getXovisInfo;
