import { useState } from 'react';
import { AccordionBody, AccordionTitle, AccordionTitleWrapper, StyledIcon } from './AccordionItem.styles';

export const AccordionItem = ({ children, title, bodyHeight, defaultOpen = false }) => {
    const [open, setOpen] = useState(defaultOpen);
    return (
        <div>
            <AccordionTitleWrapper>
                <StyledIcon open={open} iconName="ChevronDown" />
                <AccordionTitle onClick={() => setOpen((prevState) => !prevState)}>{title}</AccordionTitle>
            </AccordionTitleWrapper>
            <AccordionBody open={open} bodyHeight={bodyHeight}>
                {children}
            </AccordionBody>
        </div>
    );
};
