import { cloneDeep } from 'lodash';
import xovisWithDateFieldsRequestHelper from './xovisWithDateFieldsRequestHelper';

/**
 * Функция для выгрузки данных с датчика за все зафейленные пироды
 * @param {array} failedIntervals массив зафейленных периодов
 * @param {object} sensor объект сенсора
 * @param {number} timeout таймаут
 * @returns обновленный объект с данными датчика
 */
const processXovisFailedIntervals = async (options) => {
    const { failedIntervals, sensor, timeout } = options;
    const sensorCopy = cloneDeep(sensor);

    for (let interval of failedIntervals) {
        const version = Number(sensor.xovisData?.version?.split('.')[0]);
        if (isFinite(version)) {
            const data = await xovisWithDateFieldsRequestHelper({
                sensor: sensorCopy,
                dateFromInMillis: interval.dateFrom,
                dateToInMillis: interval.dateTo,
                timeout,
            });

            if (!data.error) {
                sensorCopy.successOrderCount += 1;
            } else {
                sensorCopy.failedIntervals.push(interval);
                sensorCopy.errors.push(data.error);
            }
        } else {
            return {
                sensor: { ...sensorCopy },
                error: 'Sensor is offline',
            };
        }
    }

    return { sensor: sensorCopy };
};

export default processXovisFailedIntervals;
