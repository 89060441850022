import axios from 'axios';
import getTdFlowStatistics from './getTdFlowStatistics';
import getTdStayStatistics from './getTdStayStatistics';

/**
 * Функция для получения всех слоев для датчика TD
 * @param {string} ip IP датчика
 * @param {number} port Порт датчика
 * @param {number} timeout таймаут запроса
 * @returns объект со всеми слоями
 */
const getTdLayers = async (args) => {
    const { ip, port, timeout } = args;

    const flowStatistics = getTdFlowStatistics({
        ip: ip,
        port: port,
        timeout,
    });

    const stayStatistics = getTdStayStatistics({
        ip: ip,
        port: port,
        timeout,
    });

    return axios
        .all([flowStatistics, stayStatistics])
        .then(
            axios.spread((flowStatistics, stayStatistics) => {
                const layers = {
                    passWays: flowStatistics.error ? [] : flowStatistics,
                    zones: stayStatistics.error ? [] : stayStatistics,
                };

                return layers;
            }),
        )
        .catch((_) => {
            return { error: 'An error accrued while getting TD layers' };
        });
};

export default getTdLayers;
