import { cloneDeep } from 'lodash';
import portionDataPost from '../../../../utils/portionDataPost';
import hikvisionUploadDataHandler from './hikvisionUploadDataHandler';

/**
 * Функция для выгрузки данных с датчика, у которго был передан временной интервал
 * @param {object} sensor объект сенсора
 * @param {number} timeout таймаут
 * @param {string} dateFromInMillis date_from в милисекундах
 * @param {string} dateToInMillis date_to в милисекундах
 * @returns обновленный объект сенсора
 */
const processHikvisionWithDateFields = async (options) => {
    const { sensor, timeout, dateFromInMillis, dateToInMillis } = options;
    const PORTION_SIZE = 10;
    let sensorCopy = cloneDeep(sensor);
    let error = '';
    const dataForPost = [];
    if (sensorCopy.errors.length === 0) {
        if (!sensorCopy.layers.passWays.length !== 0) {
            const hikvisionGetUploadDataRes = await hikvisionUploadDataHandler([
                { sensor: sensorCopy, dateFromInMillis, dateToInMillis, timeout },
            ]);

            hikvisionGetUploadDataRes.forEach((element) => {
                const sensor = cloneDeep(element.initialData);
                if (!element.error) {
                    dataForPost.push(element);
                } else {
                    sensor.orderCount += 1 + sensorCopy.orderCount;
                    sensor.failedIntervals = [element.extraArgs.dateInterval].concat(sensorCopy.failedIntervals);
                    sensorCopy = { ...sensor, errors: [...sensor.errors, element.error] };
                }
            });

            const postResult = await portionDataPost({
                portionSize: PORTION_SIZE,
                dataArray: dataForPost,
                timeout,
                isAuth: true,
            });

            postResult.forEach((element) => {
                const sensor = cloneDeep(element.initialData);
                if (!element.error) {
                    sensor.orderCount += 1 + sensorCopy.orderCount;
                    sensor.successOrderCount += 1 + sensorCopy.successOrderCount;
                    sensor.failedIntervals = cloneDeep(sensorCopy.failedIntervals);
                    sensorCopy = { ...sensor };
                } else {
                    sensor.orderCount += 1 + sensorCopy.orderCount;
                    sensor.successOrderCount = sensorCopy.successOrderCount;
                    sensor.failedIntervals = [element.extraArgs.dateInterval].concat(sensorCopy.failedIntervals);
                    sensorCopy = { ...sensor, errors: [...sensor.errors, element.error] };
                }
            });
        } else {
            error = 'No count lines';
        }
    } else {
        error = 'Get count data error';
    }

    if (!error) {
        return { sensor: { ...sensorCopy } };
    }

    return { sensor: { ...sensorCopy }, error };
};

export default processHikvisionWithDateFields;
