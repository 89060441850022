import axios from 'axios';

/**
 * Post запрос к датчику TD
 * @param {string} url Урл на который нужно сделать запрос
 * @param {object} payload Урл на который нужно сделать запрос
 * @param {number} timeout Таймаут на запрос в миллисекундах
 * @returns Данные или объект с ошибкой error
 */
export const tdPostRequest = async (args) => {
    const { url, payload, timeout = 15000 } = args;
    try {
        const data = await axios
            .post(url, payload, {
                timeout,
            })
            .then((response) => response.data);
        return data;
    } catch (error) {
        return {
            error,
        };
    }
};

/**
 * GET запрос к датчику
 * @param {string} url Урл на который нужно сделать запрос
 * @param {string} username имя пользователя
 * @param {string} password пароль
 * @param {number} timeout Таймаут на запрос в миллисекундах
 * @param {string | undefined} responseType тип ответа
 * @returns Данные или объект с ошибкой error
 */
export const tdGetRequest = async (args) => {
    const { url, username = '', password = '', timeout = 15000, responseType } = args;
    try {
        const data = await axios
            .get(url, {
                timeout,
                auth: {
                    username,
                    password,
                },
                responseType,
            })
            .then((response) => response.data);

        return data;
    } catch (error) {
        return { error };
    }
};
