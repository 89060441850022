import { cloneDeep } from 'lodash';
import portionDataPost from '../../../../utils/portionDataPost';

/**
 * Функция для поста всех сенсоров в облако
 * @param {object} sensorsForManagementById объект сенсоров
 * @param {array} dataForPost данные для функции загрузки по порциям
 * @param {number} timeout таймаут
 * @returns обновленный объект с сенсорами
 */
const postScreensToLambda = async (options) => {
    const PORTION_SIZE = 3;
    const { sensorsForManagementById, dataForPost, timeout } = options;
    const copy = cloneDeep(sensorsForManagementById);

    const postResult = await portionDataPost({
        portionSize: PORTION_SIZE,
        dataArray: dataForPost,
        timeout,
        isAuth: true,
    });

    postResult.forEach((element) => {
        const sensor = cloneDeep(copy[element.initialData.id]);
        if (sensor) {
            sensor.dataFetching = false;
            if (element.error || element?.response?.error_message) {
                sensor.errors.push(
                    element?.response?.error_message || element.error,
                );
            }
            copy[sensor.id] = { ...sensor };
        }
    });

    return copy;
};

export default postScreensToLambda;
