import { DateTime } from 'luxon';

/**
 * Функция для генерации тела запроса для поста скрина
 * @param {object} sensor объект сенсора
 * @returns тела запроса для поста скрина
 */
const generateDataForScreenPost = (sensor) => {
    const URL_FOR_POST = 'https://functions.yandexcloud.net/d4e9fbbs6uacir2i83ki';

    const result = [];

    const initialData = {
        dataForPost: {},
        initialData: sensor,
        url: `${URL_FOR_POST}?serial_number=${sensor.serialNumber}&sensor_type=${sensor.type}`,
    };

    const singleData = {
        ...initialData,
        dataForPost: {
            sensor_data: {
                serial_number: sensor.serialNumber,
                sensor_type: sensor.type,
                date_from: DateTime.now().toUTC().set({ milliseconds: 0 }).toISO({ suppressMilliseconds: true }),
                layers: {
                    pass_ways: sensor.layers.passWays,
                    zones: sensor.layers.zones,
                    floors: sensor.layers.floors,
                    exceptions: sensor.layers.exceptions,
                    masks: sensor.layers.masks,
                },
            },
            screen: sensor.screen,
        },
    };
    result.push(singleData);

    if (sensor.isMultiSensor && sensor.multiSensorScreen) {
        const multiSensorData = {
            ...initialData,
            dataForPost: {
                sensor_data: {
                    is_multi: true,
                    serial_number: sensor.serialNumber,
                    sensor_type: sensor.type,
                    date_from: DateTime.now()
                        .toUTC()
                        .set({ milliseconds: 0 })
                        .plus({ seconds: 5 })
                        .toISO({ suppressMilliseconds: true }),
                    layers: {
                        pass_ways: sensor.multiSensorLayers.passWays,
                        zones: sensor.multiSensorLayers.zones,
                        floors: sensor.multiSensorLayers.floors,
                        exceptions: sensor.multiSensorLayers.exceptions,
                        masks: sensor.multiSensorLayers.masks,
                    },
                },
                screen: sensor.multiSensorScreen,
            },
        };
        result.push(multiSensorData);
    }
    return result;
};

export default generateDataForScreenPost;
