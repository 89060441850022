import styled from 'styled-components/macro';

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 60px;
`;

export const WrappedItem = styled.div`
    flex: 0 0 45%;
`;

export const CalloutContentWrapper = styled.div`
    padding: 10px;
`;
