/**
 * Функция для парсинга ответа от сенсора
 * @param {string} data xml данные с сенсора
 * @returns объект с информацией о самом сенсоре или ошибку
 */
const processRowDeviceInfo = (data) => {
    const oParser = new DOMParser();
    const xmlResponse = oParser.parseFromString(data, 'text/xml');
    const deviceName = xmlResponse.querySelector('deviceName').textContent;
    const model = xmlResponse.querySelector('model').textContent;
    const serialNumber = xmlResponse.querySelector('serialNumber').textContent;
    const macAddress = xmlResponse.querySelector('macAddress').textContent;
    if (deviceName && model && macAddress && serialNumber) {
        return { deviceName, model, serialNumber, macAddress };
    }
    return { error: 'Invalid device data' };
};

export default processRowDeviceInfo;
