import { DateTime } from 'luxon';
import { xovisPostRequest } from '../../../../../api/xovis/xovis.api';
import getResendDataStatus from './getResendDataStatus';

/**
 * Функция для генерации данных для пакетного запроса
 * @param {number} dateFromInMillis date_from
 * @param {number} dateToInMillis date_to
 * @param {object} sensor объект сенсора
 * @param {number} timeout timeout
 * @param {number} agentId id агента выгрузки
 * @return объект с данными для пакетного запроса
 */
const resendXovisDataV5 = async (args) => {
    const { sensor, dateFromInMillis, dateToInMillis, timeout, agentId } = args;

    const utcDateFrom = DateTime.fromMillis(dateFromInMillis).toUTC().toISO();
    const utcDateTo = DateTime.fromMillis(dateToInMillis).toUTC().toISO();

    const dataForPost = {
        type: 'TIME_RANGE',
        time_from: utcDateFrom,
        time_to: utcDateTo,
    };

    const resendResponse = await xovisPostRequest({
        url: `http://${sensor.ip}:${sensor.port}/api/v5/${
            sensor.isMultiSensor ? 'multisensor' : 'singlesensor'
        }/data/push/agents/${agentId}/trigger`,
        username: sensor.username,
        password: sensor.password,
        timeout,
        body: dataForPost,
    });

    if (!resendResponse.error) {
        const result = await getResendDataStatus({ sensor });
        return result;
    }

    return { error: 'Ann error accrued while resend data' };
};

export default resendXovisDataV5;
