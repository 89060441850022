import { cloneDeep, isNumber } from 'lodash';
import generateTdDataForUploadRequest from './generateTdDataForUploadRequest';
import { tdPostRequest } from './td.api';
import { getCompanyName } from '../../../../constants/constants';

/**
 * Функция для получения данных с датчика и отправки из на бэк
 * @param {object} sensor объект сенсора
 * @param {number} dateFromInMillis date_from
 * @param {number} dateToInMillis date_to
 * @param {number} timeout таймаут
 * @returns ответ в формате {sensor, error}
 */
const tdWithDateFieldsRequestHelper = async (args) => {
    const { sensor, dateFromInMillis, dateToInMillis, timeout } = args;

    const sensorCopy = cloneDeep(sensor);

    const dateInterval = { dateFrom: dateFromInMillis, dateTo: dateToInMillis };

    if (isNumber(sensor.tdData?.serviceConfig?.companyServerId)) {
        sensorCopy.orderCount += 1;
        const tdDataForPost = generateTdDataForUploadRequest({
            dateFromInMillis,
            dateToInMillis,
            sensor,
        });

        const result = await tdPostRequest({
            url: tdDataForPost.url,
            payload: tdDataForPost.dataForPost,
            timeout,
        });

        if (!result.error) {
            sensorCopy.successOrderCount += 1;
            return { sensor: { ...sensorCopy } };
        } else {
            sensorCopy.failedIntervals.push(dateInterval);
            return { sensor: { ...sensorCopy }, error: 'Reexport failed' };
        }
    } else {
        return {
            sensor: { ...sensorCopy },
            error: `No ${getCompanyName()} url`,
        };
    }
};

export default tdWithDateFieldsRequestHelper;
