import getNewHikvisionConfiguration from '../../../../utils/hikvisionHelpers/getNewHikvisionConfiguration';
import { hikvisionProgressMessages } from '../../../../utils/hikvisionHelpers/hikvisionProgressMessages';
import { updateExtendedSelectedIPoint } from '../../../instalationPoints/installationPointsReducer';
import updateHikvisionConfiguration from './updateHikvisionConfiguration';

/**
 * Функция для работы с состоянием конкретной связки, получением новой выгрузки
 * на датчике и обновлением выгрузки для датчика
 * @param {function} dispatch функция для обновления
 * @param {object} bundle текущая актуальная связь точки установки и датчика
 * @param {number} iPointId ID точки установки
 * @param {string} accessKey ключ доступа для локации / проекта
 * @param {object} sensorsById объект расширенных данных о датчиках
 * @param {*} uploadUrl объект с информацией по выгрузке
 * @returns promise
 */
const processHikvisionBatchUrlUpdate = (args) => {
    const { dispatch, bundle, iPointId, accessKey, sensorsById, uploadUrl } = args;
    const promise = new Promise((resolve) => resolve());

    dispatch(
        updateExtendedSelectedIPoint({
            data: { isFetching: true, isError: false, message: '' },
            iPointId,
        }),
    );

    if (!bundle?.thirdStepError) {
        dispatch(
            updateExtendedSelectedIPoint({
                data: { isFetching: false, isError: false, message: hikvisionProgressMessages.thirdStepEnabled },
                iPointId,
            }),
        );
        return promise;
    }

    if (bundle.hikvisionEmptyDataUploadId && !bundle.firstStepError && bundle.thirdStepError) {
        let newConfiguration;
        if (
            bundle.hikvisionDataUploadError === hikvisionProgressMessages.secondStepErrorWithNoUrl &&
            bundle.hikvisionEmptyDataUploadId
        ) {
            newConfiguration = getNewHikvisionConfiguration({
                newUpload: true,
                hikvisionEmptyDataUploadId: bundle.hikvisionEmptyDataUploadId,
                port: bundle.port,
                uploadKey: accessKey,
                uploadUrl,
            });
        } else {
            newConfiguration = getNewHikvisionConfiguration({
                hikvisionDataUploadBody: bundle.hikvisionDataUploadBody,
                hikvisionDataUploadFullUrl: bundle.hikvisionDataUploadFullUrl,
                hikVisionDataUploadHost: bundle.hikVisionDataUploadHost,
                hikvisionDataUploadUrl: bundle.hikvisionDataUploadUrl,
                uploadKey: accessKey,
                uploadUrl,
            });
        }

        return updateHikvisionConfiguration({
            sensor: sensorsById[bundle.id],
            payloadBody: newConfiguration,
            showNotifications: false,
            currentBoundle: bundle,
            dispatch,
            iPointId,
        }).then((data) => {
            if (!data?.error) {
                dispatch(
                    updateExtendedSelectedIPoint({
                        data: { isFetching: false, isError: false, message: 'Success' },
                        iPointId,
                    }),
                );
            } else {
                dispatch(
                    updateExtendedSelectedIPoint({
                        data: {
                            message: JSON.stringify(data?.error || 'Something went wrong'),
                            isFetching: false,
                            isError: true,
                        },
                        iPointId,
                    }),
                );
            }
        });
    }
    dispatch(
        updateExtendedSelectedIPoint({
            data: { isFetching: false, isError: true, message: 'Something went wrong!' },
            iPointId,
        }),
    );

    return promise;
};

export default processHikvisionBatchUrlUpdate;
