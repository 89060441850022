import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { storeWindowSize } from '../../generalReducer';

/**
 * Этот хук следит за изменением окна барузера и диспатчит его размеры в стор
 */
const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
        width: 0,
        height: 0,
    });

    const dispatch = useDispatch();

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        dispatch(storeWindowSize(windowSize));
        // eslint-disable-next-line
    }, [windowSize]);
};

export default useWindowSize;
