import styled from 'styled-components/macro';
import { Icon } from '@fluentui/react';

export const StyledIcon = styled(Icon)`
    font-size: 20px;
    color: ${(props) => props.color || 'grey'};
    margin-right: 5px;
`;

export const MainStatusWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const StatusText = styled.span`
    font-size: 12px;
`;
