import { DefaultButton, MessageBar, MessageBarType } from '@fluentui/react';
import { useContext } from 'react';
import { ModalContext } from '../modalCreator';
import { ConflictButtonsWrapper, ConflictModalTitle, ConflictModalWrapper, MessageBarWrapper, OverwriteButton } from './equalConflict.styles';

/**
 * Компонет для предупреждения пользователя о том, что данные на клиенте не актуальны
 * @param {function} closeConflictModal функция для закрытия модалки
 */
const EqualConflict = ({ closeConflictModal }) => {
    const { dataForOverwritten, overWriteThunkTrans } = useContext(ModalContext);

    const overwriteHandler = () => {
        overWriteThunkTrans({
            url: dataForOverwritten.url,
            requestType: dataForOverwritten.requestType,
            actionAfterOverwrite: dataForOverwritten.actionAfterOverwrite,
            body: dataForOverwritten.body,
            additionalData: dataForOverwritten.additionalData,
        });
        closeConflictModal();
    };

    try {
        return (
            <ConflictModalWrapper>
                <ConflictModalTitle>Equal conflict</ConflictModalTitle>
                <MessageBarWrapper>
                    <MessageBar messageBarType={MessageBarType.warning}>
                        <b>{dataForOverwritten.warningMessage}</b>
                    </MessageBar>
                </MessageBarWrapper>

                <ConflictButtonsWrapper>
                    <DefaultButton onClick={closeConflictModal}>Cancel</DefaultButton>
                    <OverwriteButton onClick={overwriteHandler}>Overwrite</OverwriteButton>
                </ConflictButtonsWrapper>
            </ConflictModalWrapper>
        );
    } catch (_) {
        return null;
    }
};

export default EqualConflict;
