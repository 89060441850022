import { IColumn } from '@fluentui/react';
import { cloneDeep } from 'lodash';
import { memo, useContext, useEffect, useState, ReactNode } from 'react';
import { IProject } from '../../../../interfaces';
import FilterInput from '../../../FilterInput/FilterInput';
import List from '../../../List/List';
import { TasksMonitoringContext } from '../../../TasksMonitoring/TasksMonitoring.context';
import TimeIntervalSuccessTasks from '../../../TimeIntervalSuccessTasks/TimeIntervalSuccessTasks';
import PlTasksCount from './components/PlTasksCount/PlTasksCount';
import { projectsListColumns } from './projectsList.columns';
import { FilterWrapper, ProjectsListWrapper } from './ProjectsList.styles';

/**
 * Компонент для отображения списка проектов, по которых есть задачи ре-экспорта
 */
const ProjectsList = memo(() => {
    const { projectTasksByProjectId, tasksFetching, storeSelectedProjectIdTrans } = useContext(TasksMonitoringContext);
    const [projects, setProjects] = useState<IProject[]>([]);
    const [filteredProjects, setFilteredProjects] = useState<IProject[]>([]);

    useEffect(() => {
        setProjects(Object.keys(projectTasksByProjectId).map((key) => projectTasksByProjectId[Number(key)]));
    }, [projectTasksByProjectId]);

    useEffect(() => {
        projects.length && setFilteredProjects(cloneDeep(projects));
    }, [projects]);

    const updateFilteredProjects = (filteredArray: object[]) => {
        setFilteredProjects(filteredArray as IProject[]);
    };

    const onRowClick = (item: IProject) => {
        storeSelectedProjectIdTrans && storeSelectedProjectIdTrans(item.projectId);
    };

    const onRenderItemColumn = (item: IProject, index?: number, column?: IColumn): ReactNode => {
        if (!column?.fieldName) return null;
        const fieldContent = item[column.fieldName as keyof IProject];
        switch (column.key) {
            case 'projectName':
                return <span>{(fieldContent as string) || '-'}</span>;

            case 'plTasksCount':
                return <PlTasksCount plTasks={item.plTasksByPlId} />;

            case 'timeIntervalSuccessTasks':
                return (
                    <TimeIntervalSuccessTasks
                        processedTasks={item.timeIntervalProcessedTasks}
                        successTasks={item.timeIntervalSuccessTasks}
                    />
                );

            default:
                return <span>{(fieldContent as string) || '-'}</span>;
        }
    };

    return (
        <ProjectsListWrapper>
            <FilterWrapper>
                <FilterInput
                    disabled={tasksFetching}
                    updateFilteredArray={updateFilteredProjects}
                    inputArray={projects}
                    matchFields={['projectId', 'projectName']}
                />
            </FilterWrapper>

            <List
                onRenderItemColumn={onRenderItemColumn}
                onRowClick={onRowClick}
                items={filteredProjects}
                columns={projectsListColumns}
                availableSortKeys={[
                    'projectId',
                    'projectName',
                    'plTasksCount',
                    'timeIntervalTasks',
                    'timeIntervalSuccessTasks',
                    'timeIntervalProcessedTasks',
                ]}
            />
        </ProjectsListWrapper>
    );
});

export default ProjectsList;
