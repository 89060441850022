import { IconButton, Modal } from '@fluentui/react';
import { FC } from 'react';
import { IModalCreatorProps } from './ModalCreator.interfaces';
import { Body, Header, iconButtonStyles } from './ModalCreator.styles';

/**
 * Компонент для создания модальных окон
 * @param isModalOpen флаг, указывающий на то закрыто окно или нет
 * @param hideModal функция для закрытия модалки
 * @param title заголовок модалки
 * @param children контент модалки
 */
const ModalCreator: FC<IModalCreatorProps> = ({ isModalOpen, hideModal, title, children }) => {
    return (
        <Modal isOpen={isModalOpen} onDismiss={hideModal}>
            <Header>
                <span>{title}</span>
                <IconButton styles={iconButtonStyles} onClick={hideModal} iconProps={{ iconName: 'Cancel' }} />
            </Header>
            <Body>{children}</Body>
        </Modal>
    );
};

export default ModalCreator;
