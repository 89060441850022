import { getSensorUploadUrls } from '../../constants/sensorUploadUrls';

export class CompanyInfo {
    /**
     * Метод для получения названия компании
     * @returns {string}
     */
    getCompanyName() {
        throw Error('Not implemented');
    }
    /**
     * Метод для получение части URL выгрузки с датчика
     * @returns {string}
     */
    getCompanyUploadUrlPart() {
        throw Error('Not implemented');
    }

    /**
     * Метод для получения всех URL выгрузок для датчиков
     * @returns
     */
    getSensorUploadUrls() {
        return getSensorUploadUrls();
    }
}
