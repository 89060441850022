import { cloneDeep } from 'lodash';
import { sensorTypes } from '../../../constants/sensorTypes';
import getHikvisionData from './hikvision/getHikvisionData';
import getVivotekConfig from './vivotek/getVivotekConfig';
import getTdSensorData from './td/getTdSensorData';
import getXovisSensorData from './xovis/getXovisSensorData';
import getMegacountSensorData from './megacount/getMegacountSensorData';

/**
 * Функция для получения конфига сенсоров
 * @param {object} sensorsForManagementById объект всех сенсоров, где ключ это id сенсора
 * @param {object} selectedSensorsCopyByIpPort объект выбранных сенсоров (без повторений)
 * @param {number} timeout таймаут
 * @return обновленный объект сенсоров, где есть конфиг
 */
const getSensorsConfig = async (options) => {
    const { sensorsForManagementById, timeout, selectedSensorsCopyByIpPort } = options;
    const copy = cloneDeep(sensorsForManagementById);

    const configPromises = [];

    Object.keys(selectedSensorsCopyByIpPort).forEach((key) => {
        const firstItem = selectedSensorsCopyByIpPort[key][0];
        switch (firstItem.type) {
            case sensorTypes.XOVIS:
                configPromises.push(
                    getXovisSensorData(
                        {
                            ...firstItem,
                            username: firstItem.user,
                            password: firstItem.pass,
                        },
                        timeout,
                    ),
                );
                break;

            case sensorTypes.VIVOTEK:
                const promise = getVivotekConfig(firstItem, timeout);
                configPromises.push(promise);
                break;

            case sensorTypes.HIKVISION: {
                const promise = getHikvisionData(firstItem, timeout);
                configPromises.push(promise);
                break;
            }

            case sensorTypes.TD: {
                const promise = getTdSensorData(firstItem, timeout, true);
                configPromises.push(promise);
                break;
            }

            case sensorTypes.MEGACOUNT: {
                const promise = getMegacountSensorData(firstItem, timeout);
                configPromises.push(promise);
                break;
            }

            default:
                configPromises.push(
                    new Promise((resolve) =>
                        resolve({
                            error: 'Invalid sensor type',
                            sensor: { ...firstItem },
                        }),
                    ),
                );
                break;
        }
    });

    // Получение конфига сенсоров
    await Promise.all(configPromises).then((responses) => {
        responses.forEach((response) => {
            if (!response.error) {
                switch (response.sensor.type) {
                    case sensorTypes.XOVIS:
                        selectedSensorsCopyByIpPort[
                            `${response.sensor.ip}:${response.sensor.port}_${response.sensor.type}`
                        ].forEach((element) => {
                            copy[element.id] = {
                                ...element,
                                ...response.sensor,
                            };
                        });

                        break;
                    case sensorTypes.VIVOTEK: {
                        selectedSensorsCopyByIpPort[
                            `${response.sensor.ip}:${response.sensor.port}_${response.sensor.type}`
                        ].forEach((element) => {
                            copy[element.id] = {
                                ...element,
                                vivotekDataUploadUrl: response.vivotekDataUploadUrl,
                                layers: response.layers,
                            };
                        });

                        break;
                    }

                    case sensorTypes.HIKVISION: {
                        selectedSensorsCopyByIpPort[
                            `${response.sensor.ip}:${response.sensor.port}_${response.sensor.type}`
                        ].forEach((element) => {
                            copy[element.id] = {
                                ...element,
                                hikvisionData: response.hikvisionData,
                                layers: response.layers,
                                serialNumber: response.hikvisionData.deviceInfo.macAddress,
                            };
                        });

                        break;
                    }

                    case sensorTypes.TD: {
                        selectedSensorsCopyByIpPort[
                            `${response.sensor.ip}:${response.sensor.port}_${response.sensor.type}`
                        ].forEach((element) => {
                            copy[element.id] = {
                                ...element,
                                serialNumber: response.tdData?.networkConfig?.deviceMac || '',
                                tdData: response.tdData,
                                layers: response.tdData?.layers || {},
                            };
                        });

                        break;
                    }

                    case sensorTypes.MEGACOUNT: {
                        selectedSensorsCopyByIpPort[
                            `${response.sensor.ip}:${response.sensor.port}_${response.sensor.type}`
                        ].forEach((element) => {
                            copy[element.id] = {
                                ...element,
                                serialNumber: response.megacountData?.networkSettings?.current?.mac || '',
                                megacountData: response.megacountData,
                                layers: response.megacountData?.layers || {},
                            };
                        });

                        break;
                    }

                    default:
                        selectedSensorsCopyByIpPort[
                            `${response.sensor.ip}:${response.sensor.port}_${response.sensor.type}`
                        ].forEach((element) => {
                            copy[element.id] = {
                                ...element,
                                errors: [...element.errors, 'Invalid sensor type'],
                            };
                        });

                        break;
                }
            } else {
                selectedSensorsCopyByIpPort[
                    `${response.sensor.ip}:${response.sensor.port}_${response.sensor.type}`
                ].forEach((element) => {
                    copy[element.id] = {
                        ...element,
                        errors: [...element.errors, response.error],
                        dataFetching: false,
                    };
                });
            }
        });
    });

    return copy;
};

export default getSensorsConfig;
