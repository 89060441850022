import { TextField } from '@fluentui/react';
import styled from 'styled-components/macro';

export const UpdateSensorModalTitle = styled.div`
    font-size: 16px;
    text-align: center;
    font-weight: 600;
    margin-bottom: 15px;
`;

export const FieldWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

export const Info = styled.div`
    span {
        font-weight: 600;
    }
`;

export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
`;

export const StyledPort = styled(TextField)`
    margin-left: 10px;
    width: 100px;
`;
