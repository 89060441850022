/**
 * Вспомогательная функция для получения серийного номера от сенсора, при пакетной загрузке данных
 * @param {object} singleDataPackage элемент из csv файла или csv строки
 */
const getDilaxSerialNumberForTable = async (options) => {
    const { singleDataPackage } = options;
    // let serialNumber = '';
    // let notEqualSerialNumber = false;

    return {
        id: singleDataPackage.id,
        error: 'Unable to get serial number',
    };
};

export default getDilaxSerialNumberForTable;
