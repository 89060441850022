import { configureStore } from '@reduxjs/toolkit';
import generalReducer from './generalReducer';
import instalationPointsReducer from './components/instalationPoints/installationPointsReducer';
import countingSensorsReducer from './components/countingSensors/countingSensorsReducer';
import passWaysReducer from './components/passWays/passWaysReducer';
import projectsReducer from './components/Projects/Projects.reducer';
import screenshotViewReducer from './components/modals/screenshotView/screenshotViewReducer';
import modalCreatorReducer from './components/modals/modalCreatorReducer';
import intersectionsForDisplayReducer from './components/intersectionsForDisplay/intersectionsForDisplayReducer';
import screenshotsPageReducer from './components/screenshotsPage/screenshotsPageReducer';
import boundleInfoModalReducer from './components/modals/boundleInfoModal/boundleInfoModalReducer';
import createSensorAndPointBoundleReducer from './components/modals/createSensorAndPointBoundle/createSensorAndPointBoundleReducer';
import batchDownloadReducer from './components/modals/batchDownload/batchDownloadReducer';
import sensorsManegementReducer from './components/sensorsManagement/sensorsManagementReducer';
import serialNumberWarningModalReducer from './components/modals/serialNumberWarningModal/serialNumberWarningModalReducer';
import projectLocationsReducer from './components/ProjectLocations/ProjectLocations.reducer';

export const store = configureStore({
    reducer: {
        generalReducer,
        instalationPointsReducer,
        countingSensorsReducer,
        passWaysReducer,
        projectsReducer,
        screenshotViewReducer,
        modalCreatorReducer,
        intersectionsForDisplayReducer,
        screenshotsPageReducer,
        boundleInfoModalReducer,
        createSensorAndPointBoundleReducer,
        batchDownloadReducer,
        sensorsManegementReducer,
        serialNumberWarningModalReducer,
        projectLocationsReducer,
    },
});
