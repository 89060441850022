import resendXovisData from './underV5Methods/resendXovisData';
import resendXovisDataV5 from './v5Methods/resendXovisDataV5';

/**
 * Функция для получения данных с датчика и отправки из на бэк
 * @param {object} sensor объект сенсора
 * @param {number} dateFromInMillis date_from
 * @param {number} dateToInMillis date_to
 * @param {number} timeout таймаут
 * @returns ответ в формате {sensor, error}
 */
const xovisWithDateFieldsRequestHelper = async (args) => {
    const { sensor, dateFromInMillis, dateToInMillis, timeout } = args;

    const version = Number(sensor.xovisData?.version?.split('.')[0]);

    if (version < 5) {
        const agentId = Number(
            sensor.xovisData?.data?.config?.companyDatapushAgent?.id,
        );
        if (isFinite(agentId)) {
            const response = await resendXovisData({
                sensor,
                timeout,
                dateFromInMillis,
                dateToInMillis,
                agentId,
            });

            return response;
        } else {
            return { error: 'No data about company agent id' };
        }
    } else if (version >= 5) {
        const response = await resendXovisDataV5({
            sensor,
            dateFromInMillis,
            dateToInMillis,
            timeout,
            agentId: sensor.xovisData?.v5Data?.companyAgent?.id,
        });
        return response;
    }

    return {
        error: 'Ann error accrued while processing version',
    };
};

export default xovisWithDateFieldsRequestHelper;
