import { createSlice } from '@reduxjs/toolkit';
import { setShowSpinner, showErrorNotification } from '../../generalReducer';
import { getRequest } from '../../api/api';
import { resetInstalationPointsReducer } from '../instalationPoints/installationPointsReducer';
import { resetIntersectionsReducer } from '../intersectionsForDisplay/intersectionsForDisplayReducer';

const initialState = {
    projectLocations: [],
    projectLocationsById: {},
    selectedPLId: null,
    selectedPLData: null,
    isRedirecting: false,
};

const projectLocationsReducer = createSlice({
    name: 'projectLocationsReducer',
    initialState,
    reducers: {
        // Запись полученный проектных локаций
        storeProjectLocations: (state, action) => {
            const projectLocationsById = action.payload.reduce((acc, value) => {
                acc[value.id] = value;
                return acc;
            }, {});
            state.projectLocationsById = projectLocationsById;
            state.projectLocations = action.payload;
        },
        // Запись в стейет id выбранной проектной локации
        storeSelectedPLId: (state, action) => {
            state.selectedPLId = action.payload;
        },
        // Запись в стейт информации о выбранной локации (если она синхронизирована)
        storeSelectedPLData: (state, action) => {
            state.selectedPLData = action.payload;
        },
        // Изменение флага редитректа на страницу точек установок
        toggleRedirecting: (state, action) => {
            state.isRedirecting = action.payload;
        },
        // Обнуление редьюсера
        resetProjectLocationsReducer: () => initialState,
    },
});

export const {
    resetProjectLocationsReducer,
    storeProjectLocations,
    storeSelectedPLId,
    storeSelectedPLData,
    toggleRedirecting,
} = projectLocationsReducer.actions;

/**
 * Thunk. Функция для получения всех начальных данных, необходимых для работы компонента
 */
export const getInitialData = () => async (dispatch, getState) => {
    const { token, storeUrls } = getState().generalReducer;
    if (!token || !storeUrls) return;
    dispatch(resetProjectLocationsReducer());
    dispatch(resetIntersectionsReducer());
    dispatch(resetInstalationPointsReducer());
    dispatch(setShowSpinner(true));
    await dispatch(getProjectLocations());
    dispatch(setShowSpinner(false));
};

/**
 * Thunk. Функция для получения проектных локаций для выбранного проетка
 */
export const getProjectLocations = () => async (dispatch, getState) => {
    const {
        storeUrls: { PROJECT_LOCATIONS_URL },
        token,
    } = getState().generalReducer;
    const { selectedProjectId } = getState().projectsReducer;

    const data = await getRequest(
        `${PROJECT_LOCATIONS_URL.url}/?project_id=${selectedProjectId}`,
        token,
    );

    if (!data.error) {
        dispatch(storeProjectLocations(data));
    } else {
        dispatch(
            showErrorNotification({
                show: true,
                message: `GET project locations error: ${JSON.stringify(
                    data?.error?.response?.data || 'Some error',
                )}`,
            }),
        );
    }
};

/**
 * Thunk. Функция для получения информации о синхронизации с map-service
 */
export const getPLSyncInfo = () => async (dispatch, getState) => {
    const {
        storeUrls: { PROJECT_LOCATIONS_URL },
        token,
    } = getState().generalReducer;
    const { selectedPLId } = getState().projectLocationsReducer;

    dispatch(setShowSpinner(true));
    const data = await getRequest(
        `${PROJECT_LOCATIONS_URL.url}/${selectedPLId}/`,
        token,
    );
    dispatch(setShowSpinner(false));

    if (!data.error) {
        if (data.is_sync) {
            dispatch(storeSelectedPLData(data));
        } else {
            dispatch(
                showErrorNotification({
                    show: true,
                    message: 'Sync error with map-service',
                }),
            );
        }
    } else {
        dispatch(
            showErrorNotification({
                show: true,
                message: `GET project location info error: ${JSON.stringify(
                    data?.error?.response?.data || 'Some error',
                )}`,
            }),
        );
    }
};

export default projectLocationsReducer.reducer;
