import { useTranslation } from 'react-i18next';
import { Wrapper, Title, ContentWrapper, SpinnerWrapper } from './iPointsScreensHistory.styles';
import { useContext, useMemo } from 'react';
import { FloorPlanContext } from '../floorPlan';
import { DateTime, Interval } from 'luxon';
import { useLocation } from 'react-router-dom';
import IPointScreen from './iPointScreen';
import { Spinner, SpinnerSize } from '@fluentui/react';

/**
 * Компонент для отображения всех связке с датчиками и их скриншотов за
 * выбранный период
 */
const IPointScreensHistory = () => {
    const { selectedInstalationPoint, plStructureFetching, plStructure, screensDateFrom, screensDateTo } =
        useContext(FloorPlanContext);

    const { t } = useTranslation();
    const location = useLocation();

    const sensor2IPointRelations = useMemo(() => {
        return plStructure?.fpc?.relations_sensor2pcipoint?.filter((relation) => {
            const relationInterval = Interval.fromDateTimes(
                DateTime.fromISO(relation.date_from),
                DateTime.fromISO(relation.date_to).plus({ days: 1 }),
            );

            return (
                relation.pc_ipoint_marker === selectedInstalationPoint?.marker &&
                (relationInterval.contains(DateTime.fromISO(screensDateFrom)) ||
                    relationInterval.contains(DateTime.fromISO(screensDateTo)))
            );
        });
    }, [plStructure, screensDateFrom, screensDateTo, selectedInstalationPoint]);

    return (
        <Wrapper show={Boolean(location.pathname.includes('screen') && selectedInstalationPoint)}>
            <Title>{`${t('Screens history for IPoint')}: ${selectedInstalationPoint?.marker}`}</Title>

            {plStructureFetching ? (
                <SpinnerWrapper>
                    <Spinner size={SpinnerSize.medium} label={t('Loading pl structure...')} labelPosition={'bottom'} />
                </SpinnerWrapper>
            ) : (
                <ContentWrapper>
                    {Boolean(sensor2IPointRelations?.length)
                        ? sensor2IPointRelations?.map((relation, index) => {
                              return (
                                  <IPointScreen
                                      key={relation.dataobj_marker + index}
                                      sensor2IPointRelation={relation}
                                  />
                              );
                          })
                        : t('No active relations')}
                </ContentWrapper>
            )}
        </Wrapper>
    );
};

export default IPointScreensHistory;
