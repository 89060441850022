import { cloneDeep } from 'lodash';
import { sensorTypes } from '../../../constants/sensorTypes';
import sensorsWithDateFieldsHandler from './sensorsWithDateFieldsHandler';
import megacountWithDateFieldsRequestHelper from './megacount/megacountWithDateFieldsRequestHelper';
/**
 * Обработчик массивов с повторами
 * @param {object} sensorsForManagementById объект сенсоров, гдю ключ это id сенсора из csv (генерируется на фронте)
 * @param {number} portionSize размер порции для выгрузки с датчика
 * @param {object} selectedSensorsCopyByIpPort выбранные сенсоры, где ключ это адресс сенсора, а его значение это массив всех сенсоров с таким ip и port
 * @param {number} timeout таймаут
 * @returns новый объект сенсоров
 */
const csvDataWithDateFieldsHandler = async (options) => {
    const { sensorsForManagementById, portionSize, selectedSensorsCopyByIpPort, timeout } = options;
    const sensorsForManagementByIdCopy = cloneDeep(sensorsForManagementById);
    const UPLOADING_PORTION_SIZE = 10;

    const subarray = []; //массив в который будет выведен результат.
    for (let i = 0; i < Math.ceil(Object.keys(selectedSensorsCopyByIpPort).length / UPLOADING_PORTION_SIZE); i++) {
        subarray[i] = Object.keys(selectedSensorsCopyByIpPort).slice(
            i * UPLOADING_PORTION_SIZE,
            i * UPLOADING_PORTION_SIZE + UPLOADING_PORTION_SIZE,
        );
    }

    for (let array of subarray) {
        const promises = [];
        array.forEach((element) => {
            const sensorsArray = selectedSensorsCopyByIpPort[element];

            switch (true) {
                case element.includes(sensorTypes.MEGACOUNT): {
                    const result = megacountWithDateFieldsRequestHelper({
                        sensorsForManagementById: sensorsForManagementByIdCopy,
                        sensorsArray,
                        timeout,
                    });
                    promises.push(result);
                    break;
                }

                default: {
                    const result = sensorsWithDateFieldsHandler(
                        sensorsArray,
                        sensorsForManagementByIdCopy,
                        portionSize,
                        timeout,
                    );
                    promises.push(result);
                    break;
                }
            }
        });

        await Promise.all(promises).then((responses) => {
            responses.forEach((response) => {
                response.forEach((element) => {
                    const result = cloneDeep(element.sensor);
                    result.dataFetching = false;
                    if (!element.error) {
                        sensorsForManagementByIdCopy[result.id] = { ...result };
                    } else {
                        sensorsForManagementByIdCopy[result.id] = {
                            ...result,
                            errors: [...result.errors, element.error],
                        };
                    }
                });
            });
        });
    }

    return sensorsForManagementByIdCopy;
};

export default csvDataWithDateFieldsHandler;
