import { sensorTypes } from '../../../../../../../constants/sensorTypes';
import XovisProgressColumn from './xovis/xovisProgressColumn';
import VivotekProgressColumn from './vivotek/vivotekProgressColumn';
import HikvisionProgressColumn from './hikvision/hikvisionProgressColumn';
import BrickstreamProgressColumn from './brickstream/brickstreamProgressColumn';
import RstatProgressColumn from './rstat/rstatProgressColumn';
import TdProgressColumn from './td/tdProgressColumn';
import MegacountProgressColumn from './megacount/MegacountProgressColumn/MegacountProgressColumn';

/**
 * Компонент для отисовки колонки с кружками статуса
 * @param {object} selectedSensor объект с информацией о выбранном сенморе
 */
const ProgressColumn = ({ selectedSensor }) => {
    if (!selectedSensor || !selectedSensor?.isFetched) return null;
    try {
        switch (selectedSensor.sensor_type) {
            case sensorTypes.XOVIS:
                return <XovisProgressColumn selectedSensor={selectedSensor} />;

            case sensorTypes.BRICKSTREAM:
                return (
                    <BrickstreamProgressColumn
                        selectedSensor={selectedSensor}
                    />
                );

            case sensorTypes.VIVOTEK:
                return (
                    <VivotekProgressColumn selectedSensor={selectedSensor} />
                );

            case sensorTypes.HIKVISION:
                return (
                    <HikvisionProgressColumn selectedSensor={selectedSensor} />
                );

            case sensorTypes.RSTAT:
                return <RstatProgressColumn selectedSensor={selectedSensor} />;

            case sensorTypes.TD:
                return <TdProgressColumn selectedSensor={selectedSensor} />;

            case sensorTypes.MEGACOUNT:
                return (
                    <MegacountProgressColumn selectedSensor={selectedSensor} />
                );

            default:
                return null;
        }
    } catch (_) {
        return null;
    }
};
export default ProgressColumn;
