import axios from 'axios';

/**
 * Функция для получения конфигурации сенсора
 * @param {string} url url по которому нужно обращаться к сенсору
 * @param {number} timeout Таймаут на запрос
 * @param {string} username логин датчика
 * @param {string} password пароль датчика
 * @returns конфигурацию сенсора или ошибку
 */
export const xovisGetRequest = async ({
    url,
    password = '',
    username = 'admin',
    timeout = 15000,
    responseType,
}) => {
    try {
        const data = await axios
            .get(url, {
                timeout,
                auth: {
                    username: username || 'admin',
                    password,
                },
                responseType,
            })
            .then((response) => response.data);

        return data;
    } catch (error) {
        return {
            error,
        };
    }
};

/**
 *
 * @param {string} url url по которому делается post запрос
 * @param {object} body данные, которые нужно отправить на сенсор
 * @param {string} username логин датчика
 * @param {string} password пароль датчика
 * @returns error message || success message
 */
export const xovisPostRequest = async ({
    url,
    body,
    username = 'admin',
    password = 'pass',
    headers = {},
    timeout = 25000,
}) => {
    try {
        const data = await axios
            .post(url, body, {
                timeout,
                headers,
                auth: {
                    username: username || 'admin',
                    password,
                },
            })
            .then((response) => response.data);
        return data;
    } catch (error) {
        return {
            error,
        };
    }
};

/**
 *
 * @param {string} url url по которому делается put запрос
 * @param {object} body данные, которые нужно отправить на сенсор
 * @param {string} username логин датчика
 * @param {string} password пароль датчика
 * @returns error message || success message
 */
export const xovisPutRequest = async ({
    url,
    body,
    username = 'admin',
    password = 'pass',
    headers = {},
    timeout = 25000,
}) => {
    try {
        const data = await axios.put(url, body, {
            timeout,
            headers,
            auth: {
                username: username || 'admin',
                password,
            },
        });
        return data;
    } catch (error) {
        return {
            error,
        };
    }
};
