import { DetailsList } from '@fluentui/react';
import { NotEqualSerialNumbersModalTitle } from './notEqualSerialNumbers.styles';
import { columnsForEqualModal } from './columnsForEqualModal';

/**
 * Модальное окно для отображения сериников, которые были получены от сенсора и переданы в csv, если они не совпадают
 * @param {array} notEqualSerialNumbers массив всех пакетов данных, у которых серийник различны
 */
const NotEqualSerialNumbers = ({ notEqualSerialNumbers }) => {
    return (
        <>
            <NotEqualSerialNumbersModalTitle>Not equal serialNumbers</NotEqualSerialNumbersModalTitle>
            <DetailsList columns={columnsForEqualModal} items={notEqualSerialNumbers} selectionMode={0} />
        </>
    );
};

export default NotEqualSerialNumbers;
