/**
 * получение всех query параметров в виде объекта, где ключ - это название параметра, а значение - это
 * значение query параметра
 * @param {string} queryString
 * @returns
 */
const parseQuery = (queryString) => {
    const query = {};
    const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (let i = 0; i < pairs.length; i++) {
        const pair = pairs[i].split('=');
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
};

export default parseQuery;
