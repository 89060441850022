import { IColumn } from '@fluentui/react';
export const projectsListColumns: IColumn[] = [
    {
        key: 'projectName',
        name: 'Project name',
        fieldName: 'projectName',
        isSorted: true,
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        isSortedDescending: false,
    },
    {
        key: 'projectId',
        name: 'Project id',
        fieldName: 'projectId',
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
    },
    {
        key: 'plTasksCount',
        name: 'Pl tasks count',
        fieldName: 'plTasksCount',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
    },
    {
        key: 'timeIntervalTasks',
        name: 'Time interval tasks',
        fieldName: 'timeIntervalTasks',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
    },
    {
        key: 'timeIntervalProcessedTasks',
        name: 'TI processed tasks',
        fieldName: 'timeIntervalProcessedTasks',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
    },
    {
        key: 'timeIntervalSuccessTasks',
        name: 'TI success tasks',
        fieldName: 'timeIntervalSuccessTasks',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
    },
];
