import axios from 'axios';
import getXovisConfig from './getXovisConfig';
import getXovisMultisensorData from './getXovisMultisensorData';
import getXovisLayers from './getXovisLayers';

/**
 * Получение всех необходимых для работы данных для датчика
 * @param {object} sensor объект сенсора
 * @param {number} timeout timeout
 * @returns объект с ошибкой или объект с данными датчика
 */
const getXovisData = async (args) => {
    const { sensor, timeout } = args;

    const config = getXovisConfig({ sensor, timeout });
    const multisensorData = getXovisMultisensorData({ sensor, timeout });

    return axios
        .all([config, multisensorData])
        .then(
            axios.spread((config, multisensorData) => {
                const data = {
                    config: {},
                    multisensorData: {},
                    layers: {},
                    multiSensorLayers: {},
                };

                if (!config.error && !multisensorData.error) {
                    const layersData = getXovisLayers({
                        config,
                        multisensorData,
                    });
                    data.layers = layersData.layers;
                    data.multiSensorLayers = layersData.multiSensorLayers;
                }
                if (!config.error) {
                    data.config = config;
                }
                if (!multisensorData.error) {
                    data.multisensorData = multisensorData;
                }

                return data;
            }),
        )
        .catch(() => {
            return { error: 'Ann error accrued while getting data' };
        });
};

export default getXovisData;
