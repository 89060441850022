import axios from 'axios';
import { xovisPostRequest } from '../../../../api/xovis/xovis.api';
import { showErrorNotification, showSuccessNotification } from '../../../../generalReducer';
import { updateAvailableBoundleByIPointId } from '../../../instalationPoints/installationPointsReducer';
import checkErrorInXovisResponse from '../../../../utils/xovisHelpers/checkErrorInXovisResponse';
import { setSensorConfigurationUpdated, updateSingleSensor } from '../../countingSensorsReducer';
import getXovisSensorData from './getXovisSensorData';
import updateXovisConnectionV5 from './v5Methods/updateXovisConnectionV5';
import updateXovisAgentV5 from './v5Methods/updateXovisAgentV5';
import postXovisAgentV5 from './v5Methods/postXovisAgentV5';
import postXovisConnectionV5 from './v5Methods/postXovisConnectionV5';

/**
 * Вспомогательная функция для обновления конфигурации Xovis сенсора
 * @param {number} iPointId id точки установки
 * @param {object} sensor объект выбранного сенсора
 * @param {object} currentBoundle объект связки
 * @param {function} dispatch диспатч в стейт
 * @param {object} payloadBody новая конфигурация сенсора
 * @param {boolean} showNotifications флаг, отвечающий за отображение оповещений
 */
const updateXovisSensorConfigurationHelper = async (options) => {
    const { iPointId, sensor, currentBoundle, dispatch, payloadBody, showNotifications = true, uploadUrl } = options;

    const version = Number(sensor.xovisData?.version.split('.')[0]);

    const errorResponse = { error: 'Something went wrong' };

    const successNotification = () => {
        showNotifications &&
            dispatch(
                showSuccessNotification({
                    show: true,
                    message: 'Configuration updated!',
                }),
            );
    };

    const errorNotification = (message = '') => {
        showNotifications &&
            dispatch(
                showErrorNotification({
                    show: true,
                    message: message || 'Something went wrong',
                }),
            );
    };

    const updateSensor = async (showNotify = false) => {
        const sensorWithUpdatedData = await getXovisSensorData(sensor, uploadUrl);
        dispatch(
            updateAvailableBoundleByIPointId({
                iPointId,
                value: {
                    ...currentBoundle,
                    updateConfigurationFetching: false,
                },
            }),
        );
        dispatch(setSensorConfigurationUpdated(true));

        if (showNotify) {
            if (sensorWithUpdatedData.firstStepError) {
                errorNotification();
            } else {
                successNotification();
            }
        }

        dispatch(
            updateSingleSensor({
                id: sensor.id,
                sensor: { ...sensorWithUpdatedData },
                increaseFetchedSensors: false,
            }),
        );
    };

    if (isFinite(version)) {
        if (version < 5) {
            const response = await xovisPostRequest({
                url: `http://${sensor.ip}:${sensor.port}/api/config/save?passwd=${sensor.password}&enc=b64`,
                body: payloadBody,
                username: sensor.username,
                password: sensor.password,
                headers: { 'Content-Type': 'text/xml' },
            });

            const error = checkErrorInXovisResponse(response);

            if (!response.error && !error) {
                await updateSensor();
                return;
            }

            errorNotification(error);
            await updateSensor(false);
            return errorResponse;
        } else if (version >= 5) {
            switch (payloadBody.method) {
                case 'PUT': {
                    const connectionResponse = updateXovisConnectionV5({
                        sensor,
                        payload: payloadBody.connectionPayload,
                        connectionId: sensor.xovisData?.v5Data?.companyConnection?.id,
                    });

                    const agentResponse = updateXovisAgentV5({
                        sensor,
                        payload: {
                            ...payloadBody.agentPayload,
                            connection: sensor.xovisData?.v5Data?.companyConnection?.id,
                        },
                        agentId: sensor.xovisData?.v5Data?.companyAgent?.id,
                    });

                    await axios
                        .all([connectionResponse, agentResponse])
                        .then(
                            axios.spread(async (connectionResponse, agentResponse) => {
                                if (connectionResponse.error || agentResponse.error) {
                                    errorNotification();
                                }

                                await updateSensor();
                            }),
                        )
                        .catch(() => {
                            errorNotification();
                        });

                    break;
                }

                case 'POST': {
                    /**
                     * Кейс когда есть соединение но нет агента
                     */
                    if (!payloadBody.connectionPayload && payloadBody.agentPayload) {
                        const agentResponse = await postXovisAgentV5({
                            sensor,
                            payload: {
                                ...payloadBody.agentPayload,
                                connection: sensor.xovisData?.v5Data?.companyConnection?.id,
                            },
                        });

                        if (!agentResponse.error) {
                            await updateSensor();
                            return;
                        }

                        errorNotification(agentResponse.error);
                        return errorResponse;
                    }

                    if (payloadBody.connectionPayload && payloadBody.agentPayload) {
                        const connectionResponse = await postXovisConnectionV5({
                            sensor,
                            payload: payloadBody.connectionPayload,
                        });

                        if (!connectionResponse.error) {
                            const agentResponse = await postXovisAgentV5({
                                sensor,
                                payload: {
                                    ...payloadBody.agentPayload,
                                    connection: connectionResponse.id,
                                },
                            });

                            if (agentResponse.error) {
                                errorNotification(agentResponse.error);
                            }
                            await updateSensor();

                            return;
                        }

                        errorNotification(connectionResponse.error);
                        return errorResponse;
                    }
                    break;
                }

                default:
                    break;
            }
        }
        return;
    }

    await updateSensor(false);
    errorNotification('Cant process version');
    return errorResponse;
};

export default updateXovisSensorConfigurationHelper;
