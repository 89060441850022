import { orderBy } from 'lodash';

/**
 * @typedef {object} IArgs
 * @property {Array<object>} items - Элементы, которые нужно отсортировать
 * @property {string} fieldName - Ключ объекта, по значения которого нужно отсортировать
 * @property {boolean} isSortedDescending - Флаг направления сортировки
 *
 * @param {IArgs} args
 */
const sortHandler = (args) => {
    const { items, fieldName, isSortedDescending } = args;

    return orderBy(
        items,
        (item) => {
            switch (fieldName) {
                case 'marker':
                    return item.iPoint?.marker;
                case 'sensorAddress':
                    return `${item.sensor?.ip}:${item.sensor?.port}`;
                case 'sensorType':
                    return item.sensor?.sensor_type;
                case 'status':
                    if (item.isFetching) return 'Loading';
                    if (item.isError) return 'Error';
                    return 'Success';

                default:
                    return item[fieldName];
            }
        },
        isSortedDescending ? 'desc' : 'asc',
    );
};

export default sortHandler;
