import { hikvisionGetRequest } from './api';
import processRowChannels from './processRowChannels';
import processRowCountingData from './processRowCountingData';
import processRowDeviceInfo from './processRowDeviceInfo';
import processRowHttpHosts from './processRowHttpHosts';
import processRowNetwork from './processRowNetwork';
import processRowTime from './processRowTime';

/**
 * Функция для получения необходимых данных из конфигурации сенсора
 * @param {object} sensor объект сенсора
 * @param {number} timeout таймаут
 * @returns ошибку или данные с датчика
 */
const getHikvisionData = async (sensor, timeout) => {
    let error = '';
    const hikvisionData = {};
    let layers = {};
    const channelsData = await hikvisionGetRequest({
        url: `http://${sensor.ip}:${sensor.port}/ISAPI/System/Video/inputs/channels`,
        username: sensor.user,
        password: sensor.pass,
        timeout,
    });
    if (!channelsData.error) {
        const channelData = processRowChannels(channelsData.data);
        if (!channelData.error) {
            hikvisionData['channelData'] = { ...channelData };
            const deviceInfoRowData = await hikvisionGetRequest({
                url: `http://${sensor.ip}:${sensor.port}/ISAPI/System/deviceInfo`,
                username: sensor.user,
                password: sensor.pass,
                timeout,
            });
            if (!deviceInfoRowData.error) {
                const deviceInfo = processRowDeviceInfo(deviceInfoRowData.data);
                if (!deviceInfo.error) {
                    hikvisionData['deviceInfo'] = { ...deviceInfo };
                    const networkRowData = await hikvisionGetRequest({
                        url: `http://${sensor.ip}:${sensor.port}/ISAPI/System/Network/interfaces`,
                        username: sensor.user,
                        password: sensor.pass,
                        timeout,
                    });
                    if (!networkRowData.error) {
                        const ipAddress = processRowNetwork(networkRowData.data);
                        if (!ipAddress.error) {
                            hikvisionData['ipAddress'] = ipAddress.ipAddress;
                            const timeRowData = await hikvisionGetRequest({
                                url: `http://${sensor.ip}:${sensor.port}/ISAPI/System/time`,
                                username: sensor.user,
                                password: sensor.pass,
                                timeout,
                            });
                            if (!timeRowData.error) {
                                const time = processRowTime(timeRowData.data);
                                if (!time.error) {
                                    hikvisionData['localTime'] = time.localTime;
                                    hikvisionData['timeZone'] = time.timeZone;
                                    const httpHostsRowData = await hikvisionGetRequest({
                                        url: `http://${sensor.ip}:${sensor.port}/ISAPI/Event/notification/httpHosts`,
                                        username: sensor.user,
                                        password: sensor.pass,
                                        timeout,
                                    });
                                    if (!httpHostsRowData.error) {
                                        const dataUploadUrl = processRowHttpHosts(httpHostsRowData.data);
                                        if (!dataUploadUrl.error) {
                                            hikvisionData['dataUploadUrl'] = dataUploadUrl.dataUploadUrl;

                                            const countingRowData = await hikvisionGetRequest({
                                                url: `http://${sensor.ip}:${sensor.port}/ISAPI/System/Video/inputs/channels/${hikvisionData.channelData.id}/counting`,
                                                username: sensor.user,
                                                password: sensor.pass,
                                                timeout,
                                            });

                                            if (!countingRowData.error) {
                                                const countingData = processRowCountingData(countingRowData.data);

                                                layers = countingData.layers;
                                                hikvisionData['normalizedScreenSize'] =
                                                    countingData.normalizedScreenSize;
                                            } else {
                                                error = countingRowData.error;
                                            }
                                        } else {
                                            error = dataUploadUrl.error;
                                        }
                                    } else {
                                        error = httpHostsRowData.error;
                                    }
                                } else {
                                    error = time.error;
                                }
                            } else {
                                error = timeRowData.error;
                            }
                        } else {
                            error = ipAddress.error;
                        }
                    } else {
                        error = networkRowData.error;
                    }
                } else {
                    error = deviceInfo.error;
                }
            } else {
                error = deviceInfoRowData.error;
            }
        } else {
            error = channelData.error;
        }
    } else {
        error = channelsData.error;
    }

    if (error) {
        return { error, sensor: { ...sensor } };
    }

    return { hikvisionData, sensor: { ...sensor }, layers };
};

export default getHikvisionData;
