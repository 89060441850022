import { getCompanyName } from '../../constants/constants';

export const hikvisionProgressMessages = {
    firstStepEnabled: 'Sensor is online',
    firstStepError: 'Sensor is offline',
    thirdStepEnabled: 'The url for uploading data from the sensor is relevant',
    thirdStepWithNoUrl: `There is no ${getCompanyName()} url in the sensor configuration`,
    thirdStepWithNoMatchUrls: `The actual ${getCompanyName()} url does not match the url in the sensor configuration`,
    thirdStepNoDataUplaodInterval:
        'Can not process sensor data upload interval',
    thirdStepInvalidDataUploadInterval: 'Invalid data upload interval',
};
