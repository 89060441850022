import { DateTime } from 'luxon';
import { sensorTypes } from '../../../constants/sensorTypes';
import processHikvisionWithDateFields from './hikvision/processHikvisionWithDateFields';
import sensorWithDateFieldsHandler from './sensorWithDateFieldsHandler';

/**
 * Функция для обработки каждого элемента из объекта с повторами
 * @param {array} sensorsArray массив повторяющихся сенсоров
 * @param {object} sensorsForManagementByIdCopy  объект сенсоров, гдю ключ это id сенсора из csv (генерируется на фронте)
 * @param {number} portionSize размер порции для выгрузки с датчика
 * @param {number} timeout таймаут
 * @returns промис с обработанным сенсором
 */
const sensorsWithDateFieldsHandler = async (sensorsArray, sensorsForManagementByIdCopy, portionSize, timeout) => {
    const result = [];
    for (let element of sensorsArray) {
        //const sensor = cloneDeep(sensorsForManagementByIdCopy[element.id]);
        const dateToInMillis = DateTime.fromISO(element.date_to).toMillis();
        const dateFromInMillis = DateTime.fromISO(element.date_from).toMillis();
        switch (element.type) {
            case sensorTypes.HIKVISION: {
                const data = await processHikvisionWithDateFields({
                    sensor: sensorsForManagementByIdCopy[element.id],
                    timeout,
                    dateFromInMillis,
                    dateToInMillis,
                });
                result.push(data);
                break;
            }

            default: {
                const data = await sensorWithDateFieldsHandler({
                    sensor: sensorsForManagementByIdCopy[element.id],
                    dateFromInMillis,
                    dateToInMillis,
                    portionSize,
                    timeout,
                });
                result.push(data);
                break;
            }
        }
    }
    return result;
};

export default sensorsWithDateFieldsHandler;
