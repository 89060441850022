import { createSlice } from '@reduxjs/toolkit';
import { chunk, cloneDeep, isEqual } from 'lodash';
import { getRequest, patchRequest, postRequest } from '../../api/api';
import { actionsAfterOverwrite } from '../../constants/actionsAfterOverwrite';
import { requestTypes } from '../../constants/requestTypes';
import { sensorTypes } from '../../constants/sensorTypes';
import {
    setShowSpinner,
    showErrorNotification,
    showSuccessNotification,
    storeDataForOverwritten,
    storeEqualConflict,
} from '../../generalReducer';
import createObjectFromArray from '../../utils/createObjectFromArray';
import {
    storeExtendedSelectedIPointsById,
    toggleBatchUrlUpdateModalStatus,
    updateAvailableBoundleByIPointId,
    updateExtendedSelectedIPoint,
} from '../instalationPoints/installationPointsReducer';
import { toggleCreateSensorModalOpen } from '../modals/modalCreatorReducer';
import {
    storeWarningInformation,
    toggleOpen,
} from '../modals/serialNumberWarningModal/serialNumberWarningModalReducer';
import getBrickstreamSensorData from './tools/brickstream/getBrickstreamSensorData';
import checkSensorSerialNumber from './tools/checkSensorSerialNumber';
import getDilaxSensorData from './tools/dilax/getDilaxSensorData';
import getHikvisionSensorData from './tools/hikvision/getHikvisionSensorData';
import updateHikvisionConfiguration from './tools/hikvision/updateHikvisionConfiguration';
import getRstatSensorData from './tools/rstat/getRstatSensorData';
import getVivotekSensorData from './tools/vivotek/getVivotekSensorData';
import getXovisSensorData from './tools/xovis/getXovisSensorData';
import updateXovisSensorConfigurationHelper from './tools/xovis/updateXovisSensorConfigurationHelper';
import updateVivotekDataPushServer from './tools/vivotek/updateVivotekDataPushServer';
import updateConfigWebParams from './tools/brickstream/updateConfigWebParams';
import processXovisBatchUrlUpdate from './tools/xovis/processXovisBatchUrlUpdate';
import processVivotekBatchUrlUpdate from './tools/vivotek/processVivotekBatchUrlUpdate';
import processBrickstreamBatchUrlUpdate from './tools/brickstream/processBrickstreamBatchUrlUpdate';
import processHikvisionBatchUrlUpdate from './tools/hikvision/processHikvisionBatchUrlUpdate';
import getTdSensorData from './tools/td/getTdSensorData';
import updateTdReportServer from './tools/td/updateTdReportServer';
import processTdBatchUrlUpdate from './tools/td/processTdBatchUrlUpdate';
import getMegacountSensorData from './tools/megacount/getMegacountSensorData';
import updateMegacountUploadServer from './tools/megacount/updateMegacountUploadServer';
import processMegacountBatchServerUpdate from './tools/megacount/processMegacountBatchServerUpdate';
import { getSensorUploadUrls } from '../../constants/sensorUploadUrls';

const initialState = {
    sensors: [],
    sensorsConfigurations: [],
    sensorsById: {},
    isSensorConfigurationUpdated: false,
    initialSensorsById: {},
    slavesByIp: {},
    isSensorsFetching: false,
    shouldStoreSensors: false,
    isBatchUrlUpdateFetching: false,
    sensorUploadUrlsBySensorType: Object.entries(sensorTypes).reduce((acc, [key, sensorType]) => {
        const uploadUrls = getSensorUploadUrls();
        const sensorUrl = uploadUrls.sensors[sensorType];
        acc[sensorType] = {
            sensorType: sensorType,
            uploadUrl: {
                protocol: sensorUrl?.protocol ?? 'https',
                host: sensorUrl?.host ?? uploadUrls.fpcng,
                port: sensorUrl?.port,
            },
        };
        return acc;
    }, {}),
};

const countingSensorsReducer = createSlice({
    name: 'countingSensorsReducer',
    initialState,
    reducers: {
        // Запись сенсоров в стейт
        setSensors: (state, action) => {
            const newSensorsArray = cloneDeep(action.payload);
            const sensorsObjectById = createObjectFromArray({
                key: 'id',
                array: newSensorsArray.map((element) => ({
                    ...element,
                    isFetched: false,
                })),
            });

            state.sensorsById = sensorsObjectById;
            state.sensors = newSensorsArray;
        },

        //Запись полученных сенсоров с сервера
        storeInitialSensorsById: (state, action) => {
            const initialSensorsById = createObjectFromArray({
                key: 'id',
                array: action.payload,
            });
            state.initialSensorsById = initialSensorsById;
        },

        // Обновление конкретного сенсора
        updateSingleSensor: (state, action) => {
            state.sensorsById[action.payload.id] = action.payload.sensor;
        },

        // Обновление нескольких датчиков
        updateSensorsById: (state, action) => {
            state.sensorsById = {
                ...state.sensorsById,
                ...action.payload,
            };
        },

        // Обновление массива конфинураций
        updateSensorsConfigurations: (state, action) => {
            const newConfigurations = state.sensorsConfigurations.filter(
                (element) => element.ip !== action.payload.ip && element.data !== action.payload.data,
            );
            newConfigurations.push(action.payload);
            state.sensorsConfigurations = newConfigurations;
        },

        // Зименение флага после ответа всех сенсоров
        toggleSensorsFetching: (state, action) => {
            state.isSensorsFetching = action.payload;
        },

        // Запись в стейт информации о slaves
        storeSlavesByIp: (state, action) => {
            state.slavesByIp = action.payload;
        },

        // Обновление дочерних датчиков (slave)
        updateSlavesByIp: (state, action) => {
            state.slavesByIp = {
                ...state.slavesByIp,
                ...action.payload,
            };
        },

        // Запсиь в стейт информации: была ли обновлена конфигурация сенсора
        setSensorConfigurationUpdated: (state, action) => {
            state.isSensorConfigurationUpdated = action.payload;
        },

        // Изменение флага для записи датчиков
        toggleShouldStoreSensors: (state, action) => {
            state.shouldStoreSensors = action.payload;
        },

        /** Изменение флага загрузки обновления выгрузки на выбранных датчиках */
        toggleBatchUrlUpdateFetching: (state, action) => {
            state.isBatchUrlUpdateFetching = action.payload;
        },

        /** Обновление url для выгрузки датчика */
        updateSensorUploadUrlBySensorType: (state, action) => {
            const { sensorType, uploadUrl } = action.payload;
            state.sensorUploadUrlsBySensorType[sensorType] = { sensorType, uploadUrl };
        },
    },
});

export const {
    setSensors,
    updateSensorsConfigurations,
    setSensorConfigurationUpdated,
    updateSingleSensor,
    toggleSensorsFetching,
    storeSlavesByIp,
    toggleShouldStoreSensors,
    storeInitialSensorsById,
    toggleBatchUrlUpdateFetching,
    updateSensorsById,
    updateSlavesByIp,
    updateSensorUploadUrlBySensorType,
} = countingSensorsReducer.actions;

/**
 * Thunk. Для получения сенсоров
 * @param {object} storeUrls объект со всеми урлами
 * @param {string} token токен для хедеров
 * @param {object} history для редиректа при ошибке
 * @param {number} locationId id локации
 */
export const getSensorsThunk = (storeUrls, token, nav, locationId) => async (dispatch) => {
    dispatch(setShowSpinner(true));
    const data = await getRequest(`${storeUrls.GET_SENSORS.url}?project_location_id=${locationId}`, token);
    dispatch(setShowSpinner(false));
    if (!data.error) {
        dispatch(storeInitialSensorsById(cloneDeep(data)));
        dispatch(setSensors(data));
    } else {
        dispatch(
            showErrorNotification({
                show: true,
                message: 'GET sensors error',
            }),
        );
        nav('/');
    }
};

/**
 * Thunk. Обрабатывает поля из формы. Получает серийники сенсоров.
 * @param {object} formValues значения из формы
 */
export const createNewSensorThunk = (formValues, nav) => async (dispatch, getState) => {
    const { type } = formValues;
    const { locationId } = getState().instalationPointsReducer;
    dispatch(setShowSpinner(true));

    const result = await checkSensorSerialNumber(formValues, locationId);

    if (!result.error) {
        dispatch(finalSensorCreateThunk(result.postPayload, type, nav));
    } else {
        dispatch(setShowSpinner(false));
        const {
            showErrorNotification: { show },
            serialNumberModal,
        } = result.actions;

        if (show) {
            dispatch(showErrorNotification(result.actions.showErrorNotification));
        }

        if (serialNumberModal.open) {
            dispatch(toggleOpen(true));
            dispatch(storeWarningInformation(serialNumberModal.warningInformation));
        }
    }
};

/**
 * Thunk. Для поста сенсора на бэк
 * @param {object} payload объект для поста на бэк
 * @param {string} type тип датчика
 */
export const finalSensorCreateThunk = (payload, type, nav) => async (dispatch, getState) => {
    const { token, storeUrls } = getState().generalReducer;
    const { locationId } = getState().instalationPointsReducer;
    dispatch(setShowSpinner(true));
    let url = '';

    switch (type) {
        case sensorTypes.XOVIS: {
            url = storeUrls.POST_XOVIS_SENSOR.url;
            break;
        }
        case sensorTypes.BRICKSTREAM: {
            url = storeUrls.POST_BRICKSTREAM_SENSOR.url;
            break;
        }
        case sensorTypes.VIVOTEK: {
            url = storeUrls.POST_VIVOTEK_SENSOR.url;
            break;
        }
        case sensorTypes.HIKVISION: {
            url = storeUrls.POST_HIKVISION_SENSOR.url;
            break;
        }
        case sensorTypes.RSTAT: {
            url = storeUrls.POST_RSTAT_SENSOR.url;
            break;
        }

        case sensorTypes.DILAX: {
            url = storeUrls.POST_DILAX_SENSOR.url;
            break;
        }
        case sensorTypes.TD: {
            url = storeUrls.POST_TD_SENSOR.url;
            break;
        }
        case sensorTypes.MEGACOUNT: {
            url = storeUrls.POST_MEGACOUNT_SENSOR.url;
            break;
        }
        default:
            dispatch(setShowSpinner(false));
            dispatch(
                showErrorNotification({
                    show: true,
                    message: 'Something went wrong',
                }),
            );
            return null;
    }

    const response = await postRequest(url, token, payload);
    dispatch(setShowSpinner(false));
    if (!response.error) {
        dispatch(toggleCreateSensorModalOpen(false));
        dispatch(toggleOpen(false));
        dispatch(getSensorsThunk(storeUrls, token, nav, locationId));
        dispatch(
            showSuccessNotification({
                show: true,
                message: 'Sensor added successfully',
            }),
        );
    } else {
        if (response.error.response?.data) {
            dispatch(
                showErrorNotification({
                    show: true,
                    message: JSON.stringify(response.error.response.data),
                }),
            );
        } else {
            dispatch(
                showErrorNotification({
                    show: true,
                    message: 'Server error',
                }),
            );
        }
    }
};

/**
 * Thunk. Отправляет post запрос на сенсор, и при положительном ответе обновляет прогресс бар
 * @param {string} xovisPostUrl url для запроса
 * @param {object} data новая конфигурация сенсора
 * @param {string} sensorType тип сенсора
 * @param {function} updateAvailableBoundleByIPointIdTrans функция для изменения стейта конкретной связки
 * @param {number} iPointId id точки установки
 * @param {object} availableBundlesByIPointId объект всех связок
 * @param {number} sensorId id выбранного сеносра
 * @param {object} sensorsById объект всех сенсоров, где ключ это id сенсора
 */
export const updateSensorConfigurationThunk = (options) => async (dispatch, getState) => {
    const { data, sensorType, iPointId, availableBundlesByIPointId, sensorId, sensorsById } = options;
    const { accessKey } = getState().instalationPointsReducer;
    const { sensorUploadUrlsBySensorType } = getState().countingSensorsReducer;

    const currentBoundle = cloneDeep(availableBundlesByIPointId[iPointId]);

    dispatch(
        updateAvailableBoundleByIPointId({
            iPointId,
            value: { ...currentBoundle, updateConfigurationFetching: true },
        }),
    );

    const uploadUrl = sensorUploadUrlsBySensorType[sensorType].uploadUrl;

    switch (sensorType) {
        case sensorTypes.XOVIS: {
            updateXovisSensorConfigurationHelper({
                iPointId,
                sensor: sensorsById[sensorId],
                currentBoundle,
                dispatch,
                payloadBody: data,
                uploadUrl,
            });

            break;
        }

        case sensorTypes.HIKVISION: {
            updateHikvisionConfiguration({
                sensor: sensorsById[sensorId],
                dispatch,
                payloadBody: data,
                currentBoundle,
                iPointId,
            });
            break;
        }

        case sensorTypes.VIVOTEK: {
            updateVivotekDataPushServer({
                sensor: sensorsById[sensorId],
                dispatch,
                payloadBody: data,
                currentBundle: currentBoundle,
                iPointId,
            });
            break;
        }
        case sensorTypes.BRICKSTREAM: {
            updateConfigWebParams({
                sensor: sensorsById[sensorId],
                dispatch,
                currentBundle: currentBoundle,
                iPointId,
                accessKey,
                sensorUploadUrlsBySensorType,
            });
            break;
        }

        case sensorTypes.TD: {
            updateTdReportServer({
                iPointId,
                sensor: sensorsById[sensorId],
                currentBoundle,
                dispatch,
                payloadBody: data,
            });
            break;
        }

        case sensorTypes.MEGACOUNT: {
            updateMegacountUploadServer({
                iPointId,
                sensor: sensorsById[sensorId],
                currentBoundle,
                dispatch,
                payloadBody: data,
            });
            break;
        }

        default: {
            dispatch(
                showErrorNotification({
                    show: true,
                    message: 'Unknown sensor type',
                }),
            );

            dispatch(
                updateAvailableBoundleByIPointId({
                    iPointId,
                    value: {
                        ...currentBoundle,
                        updateConfigurationFetching: false,
                    },
                }),
            );
            break;
        }
    }
};

/**
 * Thunk. Обновляет ip и port выбранного сенсора
 * @param {string} ip новый Ip сенсора
 * @param {string} port новый порт сенсора
 * @param {number} sensorId id сенсора
 * @param {object} history объект для манипуляции со страницами
 * @param {string} password пароль от сенсора
 * @param {string} username логин от сенсора
 * @param {string} type тип сенсора
 */
export const updateSensorThunk = (options) => async (dispatch, getState) => {
    const { storeUrls, token } = getState().generalReducer;
    const { locationId } = getState().instalationPointsReducer;
    const { initialSensorsById } = getState().countingSensorsReducer;
    const { ip, port, sensorId, nav, password, username, type } = options;

    let urlForRequest;
    let checkConflictUrl;

    switch (type) {
        case sensorTypes.XOVIS:
            urlForRequest = storeUrls.PATCH_XOVIS_SENSOR.url;
            checkConflictUrl = storeUrls.GET_XOVIS_SENSOR.url;
            break;
        case sensorTypes.BRICKSTREAM:
            urlForRequest = storeUrls.PATCH_BRICKSTREAM_SENSOR.url;
            checkConflictUrl = storeUrls.GET_BRICKSTREAM_SENSOR.url;
            break;
        case sensorTypes.VIVOTEK:
            urlForRequest = storeUrls.PATCH_VIVOTEK_SENSOR.url;
            checkConflictUrl = storeUrls.GET_VIVOTEK_SENSOR.url;
            break;
        case sensorTypes.HIKVISION:
            urlForRequest = storeUrls.PATCH_HIKVISION_SENSOR.url;
            checkConflictUrl = storeUrls.GET_HIKVISION_SENSOR.url;
            break;
        case sensorTypes.RSTAT:
            urlForRequest = storeUrls.PATCH_RSTAT_SENSOR.url;
            checkConflictUrl = storeUrls.GET_RSTAT_SENSOR.url;
            break;
        case sensorTypes.DILAX:
            urlForRequest = storeUrls.PATCH_DILAX_SENSOR.url;
            checkConflictUrl = storeUrls.GET_DILAX_SENSOR.url;
            break;

        case sensorTypes.TD:
            urlForRequest = storeUrls.PATCH_TD_SENSOR.url;
            checkConflictUrl = storeUrls.GET_TD_SENSOR.url;
            break;

        case sensorTypes.MEGACOUNT:
            urlForRequest = storeUrls.PATCH_MEGACOUNT_SENSOR.url;
            checkConflictUrl = storeUrls.GET_MEGACOUNT_SENSOR.url;
            break;

        default:
            break;
    }

    const sensor = await getRequest(`${checkConflictUrl}${sensorId}/`, token);

    if (!sensor.error) {
        const isSensorsEqual = isEqual(initialSensorsById[sensorId], sensor);

        if (isSensorsEqual) {
            dispatch(setShowSpinner(true));
            const data = await patchRequest(`${urlForRequest}${sensorId}/`, token, { ip, port, password, username });
            dispatch(setShowSpinner(false));

            if (!data.error) {
                dispatch(
                    showSuccessNotification({
                        show: true,
                        message: 'Sensor updated',
                    }),
                );
                dispatch(getSensorsThunk(storeUrls, token, nav, locationId));
            } else {
                dispatch(
                    showErrorNotification({
                        show: true,
                        message: 'Update sensor error',
                    }),
                );
            }
        } else {
            dispatch(storeEqualConflict(true));
            dispatch(
                storeDataForOverwritten({
                    url: `${urlForRequest}${sensorId}/`,
                    body: { ip, port, password, username },
                    requestType: requestTypes.PATCH,
                    warningMessage: 'You are trying to update a sensor, but the data on this client is not up to date',
                    actionAfterOverwrite: actionsAfterOverwrite.UPDATE_SENSORS,
                    additionalData: { locationId },
                }),
            );
        }
    } else {
        dispatch(showErrorNotification({ show: true, message: 'Get sensor error' }));
    }
};

/**
 * Thunk. Пакетное обновление выгрузки на датчиках
 */
export const batchSensorsUpdate = () => async (dispatch, getState) => {
    const { sensorsById, initialSensorsById, sensorUploadUrlsBySensorType } = getState().countingSensorsReducer;
    const { selectedInstallationPointsById, availableBundlesByIPointId, accessKey } =
        getState().instalationPointsReducer;

    const PARALLEL_REQUESTS_COUNT = 5;

    const extendedDataByIPointId = Object.entries(selectedInstallationPointsById).reduce((acc, [iPointId, iPoint]) => {
        const bundle = availableBundlesByIPointId[iPointId];
        const sensor = initialSensorsById[bundle?.id];

        if (bundle && sensor)
            acc[iPointId] = {
                isFetching: true,
                isError: false,
                message: '',
                iPoint,
                sensor,
                bundle,
            };

        return acc;
    }, {});
    dispatch(storeExtendedSelectedIPointsById(extendedDataByIPointId));

    dispatch(toggleBatchUrlUpdateModalStatus({ show: true }));
    dispatch(toggleBatchUrlUpdateFetching(true));

    for (const ids of chunk(Object.keys(selectedInstallationPointsById), PARALLEL_REQUESTS_COUNT)) {
        const promises = [];
        ids.forEach((id) => {
            const bundle = availableBundlesByIPointId[id];
            if (bundle && accessKey) {
                switch (bundle.sensor_type) {
                    case sensorTypes.XOVIS:
                        promises.push(
                            processXovisBatchUrlUpdate({
                                dispatch,
                                bundle,
                                iPointId: id,
                                accessKey,
                                sensorsById,
                                uploadUrl: sensorUploadUrlsBySensorType[bundle.sensor_type].uploadUrl,
                            }),
                        );
                        break;

                    case sensorTypes.VIVOTEK:
                        promises.push(
                            processVivotekBatchUrlUpdate({
                                dispatch,
                                bundle,
                                iPointId: id,
                                accessKey,
                                sensorsById,
                                uploadUrl: sensorUploadUrlsBySensorType[bundle.sensor_type].uploadUrl,
                            }),
                        );
                        break;

                    case sensorTypes.BRICKSTREAM:
                        promises.push(
                            processBrickstreamBatchUrlUpdate({
                                dispatch,
                                bundle,
                                iPointId: id,
                                accessKey,
                                sensorsById,
                                sensorUploadUrlsBySensorType,
                            }),
                        );
                        break;

                    case sensorTypes.HIKVISION:
                        promises.push(
                            processHikvisionBatchUrlUpdate({
                                dispatch,
                                bundle,
                                iPointId: id,
                                accessKey,
                                sensorsById,
                                uploadUrl: sensorUploadUrlsBySensorType[bundle.sensor_type].uploadUrl,
                            }),
                        );
                        break;

                    case sensorTypes.TD:
                        promises.push(
                            processTdBatchUrlUpdate({
                                dispatch,
                                bundle,
                                iPointId: id,
                                accessKey,
                                sensorsById,
                                uploadUrl: sensorUploadUrlsBySensorType[bundle.sensor_type].uploadUrl,
                            }),
                        );
                        break;

                    case sensorTypes.MEGACOUNT:
                        promises.push(
                            processMegacountBatchServerUpdate({
                                dispatch,
                                bundle,
                                iPointId: id,
                                accessKey,
                                sensorsById,
                                uploadUrl: sensorUploadUrlsBySensorType[bundle.sensor_type].uploadUrl,
                            }),
                        );
                        break;

                    default:
                        dispatch(
                            updateExtendedSelectedIPoint({
                                iPointId: id,
                                data: {
                                    message: `Unknown type of sensor ${bundle.sensor_type}`,
                                    isFetching: false,
                                    isError: true,
                                },
                            }),
                        );
                        break;
                }
            } else {
                // dispatch(
                //     updateExtendedSelectedIPoint({
                //         iPointId: id,
                //         data: {
                //             message: `No access key!`,
                //             isFetching: false,
                //             isError: true,
                //         },
                //     })
                // );
            }
        });

        await Promise.allSettled(promises);
        dispatch(toggleBatchUrlUpdateFetching(false));
    }
};

/**
 * Thunk. Получение данных для датчиков, по выбранных точкам установок
 */
export const getSensorsDataThunk = () => async (dispatch, getState) => {
    const { initialSensorsById, sensorUploadUrlsBySensorType } = getState().countingSensorsReducer;
    const { selectedInstallationPointsById, availableBundlesByIPointId, accessKey } =
        getState().instalationPointsReducer;

    const PARALLEL_REQUESTS_COUNT = Object.keys(selectedInstallationPointsById).length;

    for (const ids of chunk(Object.keys(selectedInstallationPointsById), PARALLEL_REQUESTS_COUNT)) {
        const promises = [];
        const extendedSensorsById = {};
        const slavesByIp = {};
        ids.forEach((id) => {
            const bundle = availableBundlesByIPointId[id];
            const uploadUrl = sensorUploadUrlsBySensorType[bundle.sensor_type].uploadUrl;
            if (bundle && accessKey) {
                dispatch(
                    updateSingleSensor({
                        id: bundle.id,
                        sensor: {
                            ...initialSensorsById[bundle.id],
                            isConfigurationFetching: true,
                            isFetched: false,
                        },
                    }),
                );
                switch (bundle.sensor_type) {
                    case sensorTypes.XOVIS:
                        promises.push(getXovisSensorData(initialSensorsById[bundle.id], uploadUrl));
                        break;
                    case sensorTypes.BRICKSTREAM:
                        promises.push(getBrickstreamSensorData(initialSensorsById[bundle.id], uploadUrl));
                        break;
                    case sensorTypes.VIVOTEK:
                        promises.push(getVivotekSensorData(initialSensorsById[bundle.id], uploadUrl));
                        break;
                    case sensorTypes.HIKVISION:
                        promises.push(getHikvisionSensorData(initialSensorsById[bundle.id], uploadUrl));
                        break;
                    case sensorTypes.RSTAT:
                        promises.push(getRstatSensorData(initialSensorsById[bundle.id], uploadUrl));
                        break;
                    case sensorTypes.DILAX:
                        promises.push(getDilaxSensorData(initialSensorsById[bundle.id], uploadUrl));
                        break;
                    case sensorTypes.TD:
                        promises.push(getTdSensorData(initialSensorsById[bundle.id], uploadUrl));
                        break;
                    case sensorTypes.MEGACOUNT:
                        promises.push(getMegacountSensorData(initialSensorsById[bundle.id], uploadUrl));
                        break;
                    default:
                        break;
                }
            }
        });
        await Promise.all(promises).then((responses) => {
            responses.forEach((element) => {
                extendedSensorsById[element.id] = {
                    ...element,
                    isFetched: true,
                };
                if (element.isMultiSensor) {
                    element.multiSensorSlaves.forEach((slave) => {
                        if (slavesByIp[slave]) {
                            const copy = cloneDeep(slavesByIp[slave]);
                            const masters = [...copy.masters, element.ip];
                            slavesByIp[slave] = { masters };
                        } else {
                            slavesByIp[slave] = { masters: [element.ip] };
                        }
                    });
                }
            });
        });
        dispatch(updateSensorsById(extendedSensorsById));
        dispatch(updateSlavesByIp(slavesByIp));
    }
};

export default countingSensorsReducer.reducer;
