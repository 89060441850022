import { isNull, isUndefined } from 'lodash';
import { tdGetRequest } from '../../../../api/td/td.api';
import getTdServerUploadSettings from './getTdServerUploadSettings';
import { getCompanyUploadUrlPart } from '../../../../constants/constants';

/**
 * Функция для получения конфигурации сервисов датчика (Конфигурация выгрузки)
 * @param {string} ip IP датчика
 * @param {number} port PORT датчика
 * @returns Promise c информацией о сервисах или объект с ошибкой
 */
const getTdServiceConfig = async (args) => {
    const { ip, port } = args;

    const config = await tdGetRequest({
        url: `http://${ip}:${port}/service_config`,
    });

    if (!config.error) {
        const oParser = new DOMParser();
        const serviceConfigHTML = oParser.parseFromString(config, 'text/html');

        const rawServiceConfig = getRawServiceConfig(serviceConfigHTML);

        const reportServersById = {
            1: {
                id: 1,
                ...getReportServerData(serviceConfigHTML),
            },
            2: {
                id: 2,
                ...getReportServerData(serviceConfigHTML, '2'),
            },
        };

        const companyServerId =
            Object.values(reportServersById).find((server) =>
                server.reportServerAddress.includes(getCompanyUploadUrlPart()),
            )?.id || null;

        let companyServerUploadSettings = {};

        const availableServerIdToUpdate =
            Object.values(reportServersById).find((element) => {
                return (
                    !isNull(element.reportServerAddress) &&
                    !isUndefined(element.reportServerAddress) &&
                    (element.reportServerAddress === '' ||
                        element.reportServerAddress?.includes(
                            getCompanyUploadUrlPart(),
                        ))
                );
            })?.id || null;

        if (companyServerId) {
            const settings = await getTdServerUploadSettings({
                serverId: companyServerId,
                port,
                ip,
            });

            if (!settings.error) {
                companyServerUploadSettings = settings;
            }
        }
        return {
            reportServersById,
            companyServerId,
            companyServerUploadSettings,
            rawServiceConfig,
            availableServerIdToUpdate,
        };
    }

    return {
        error: 'Unable to get sensor service config',
    };
};

export default getTdServiceConfig;

/**
 * Функция для получения данных о сервере выгрузки
 * @param {string} serviceConfigHTML Данные конфигурации, полученные от датчика
 * @param {string} serverNumber номер сервера выгрузки (может принимать значения '' и '2')
 */
const getReportServerData = (serviceConfigHTML, serverNumber = '') => {
    const reportServerAddress = serviceConfigHTML.querySelector(
        `#serverip${serverNumber}`,
    )?.value;
    const reportServerPort = serviceConfigHTML.querySelector(
        `#serverport${serverNumber}`,
    )?.value;
    const reportServerDirectory = serviceConfigHTML.querySelector(
        `#serverpath${serverNumber}`,
    )?.value;
    const isActive = serviceConfigHTML.querySelector(`#DoubleIP`)?.checked;

    return {
        reportServerAddress,
        reportServerPort,
        reportServerDirectory,
        isActive: serverNumber === '2' ? Boolean(isActive) : true,
    };
};

/**
 * Функция для получения данных конфигурации сервисов
 * @param {string} serviceConfigHTML Данные конфигурации, полученные от датчика
 */
const getRawServiceConfig = (serviceConfigHTML) => {
    /** IP или адрес первого сервера выгрузки */
    const serverip = serviceConfigHTML.querySelector('#serverip')?.value;
    /** Порт первого сервера выгрузки */
    const serverport = serviceConfigHTML.querySelector('#serverport')?.value;
    /** IP или адрес второго сервера выгрузки */
    const serverip2 = serviceConfigHTML.querySelector('#serverip2')?.value;
    /** Порт второго сервера выгрузки */
    const serverport2 = serviceConfigHTML.querySelector('#serverport2')?.value;
    /** Флаг, отвечающий за то активен ли второй сервер выгрузки или нет */
    const DoubleIP_checkbox =
        serviceConfigHTML.querySelector('#DoubleIP')?.checked;

    /** Протокол  сервера времени */
    const ServerProtocol = serviceConfigHTML.querySelector(
        '#ServerProtocolIndex',
    )?.value;
    /** IP или адрес сервера времени */
    const timeserverip =
        serviceConfigHTML.querySelector('#timeserverip')?.value;
    /** Порт сервера времени */
    const timeserverport =
        serviceConfigHTML.querySelector('#timeserverport')?.value;

    /** Таймзона сервера времени */
    const timezone = serviceConfigHTML.querySelector('#timezoneHidden')?.value;
    /** Флаг для сервера времени */
    const dst_checkbox = serviceConfigHTML.querySelector('#dst')?.checked;

    /** Путь для первого сервера выгрузки */
    const path = serviceConfigHTML.querySelector('#serverpath')?.value;
    /** Путь для второго сервера выгрузки */
    const path2 = serviceConfigHTML.querySelector('#serverpath2')?.value;

    /** IP или адрес сервера удаленного управления  */
    const ManagerServerIP =
        serviceConfigHTML.querySelector('#ManagerServerIP')?.value;
    /** Порт сервера удаленного управления  */
    const ManagerServerPort =
        serviceConfigHTML.querySelector('#ManagerServerPort')?.value;

    let dst = 0;
    if (dst_checkbox) dst = 1;

    let doubleIP = 0;
    if (DoubleIP_checkbox) doubleIP = 1;

    let Domainname = 0;
    if (!isIpValid(serverip)) Domainname = 1;

    let Domainname2 = 0;
    if (!isIpValid(serverip2) && doubleIP === 1) Domainname2 = 1;

    let Domainname3 = 0;
    if (!isIpValid(ManagerServerIP) && ManagerServerIP !== '') Domainname3 = 1;

    let Domainname4 = 0;
    if (!isIpValid(timeserverip) && ServerProtocol === '2') Domainname4 = 1;

    const result = {
        serverip,
        serverport,
        serverip2,
        serverport2,
        doubleIP,
        Domainname,
        Domainname2,
        ManagerServerIP,
        ManagerServerPort,
        Domainname3,
        path,
        path2,
        ServerProtocol,
        timeserverip,
        timeserverport,
        Domainname4,
        timezone,
        dst,
    };

    return result;
};

/**
 * Функция на проверку валидности IP (Взята из файлов датчика TD)
 * @param {string} value Значение для проверки на валидность IP
 * @returns true / false
 */
const isIpValid = (value) => {
    //ip
    const exp =
        /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/;

    const reg = value?.match(exp);
    if (!reg) {
        return false;
    } else {
        const IPArray = value.split('.');
        const ip4 = parseInt(IPArray[3]);
        if (isNaN(ip4)) {
            return false;
        }
        if (ip4 === 0 || ip4 === 255) {
            return false;
        }
        return true;
    }
};
