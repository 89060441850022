import { Group } from 'react-konva';
import { useDispatch, useSelector } from 'react-redux';
import {
    setSelectedInstalationPoint,
    setInstalationPointsForCanvas,
} from '../instalationPoints/installationPointsReducer';
import SingleSensor from './componetns/singleSensor';

/**
 * Обертка для сенсоров
 * @param {number} stageScale скейл канваса
 */
const CountingSensors = ({ stageScale }) => {
    const { instalationPointsForCanvas, selectedInstalationPoint, showIPointLabel } = useSelector(
        (state) => state.instalationPointsReducer
    );
    const { currentFloorInfo } = useSelector((state) => state.instalationPointsReducer);
    const dispatch = useDispatch();

    const setSelectedInstalationPointTrans = (sensor) => dispatch(setSelectedInstalationPoint(sensor));
    const setInstalationPointsForCanvasTrans = (value) => dispatch(setInstalationPointsForCanvas(value));
    const sensorsArray = instalationPointsForCanvas.map((element) => (
        <SingleSensor
            key={element.id}
            id={element.id}
            iPoint={element}
            selectedInstalationPoint={selectedInstalationPoint}
            marker={element.marker}
            instalationPointsForCanvas={instalationPointsForCanvas}
            setSelectedInstalationPointTrans={setSelectedInstalationPointTrans}
            stageScale={stageScale}
            planScale={currentFloorInfo.scale}
            showIPointLabel={showIPointLabel}
            followedBy={element.followedBy}
            setInstalationPointsForCanvasTrans={setInstalationPointsForCanvasTrans}
            centerPoint={element.centerPoint}
            passPoints={element.passPoints}
        />
    ));

    return <Group>{sensorsArray}</Group>;
};

export default CountingSensors;
