import { hikvisionDataUploadCorrectValues } from '../../../../utils/hikvisionHelpers/hikvisionDataUploadCorrectValues';
import { hikvisionProgressMessages } from '../../../../utils/hikvisionHelpers/hikvisionProgressMessages';

/**
 * Вспомогательная функция для создания связки с сенсоров HIKVISION
 * @param {object} sensor объект сенсора
 * @param {string} accessKey ключ для выгрузки данных
 * @param {object} iPoint объект точки установки
 * @param {object} currentBoundle объект текущей связки
 */
const setBoundleForHikvisionHelper = (options) => {
    const { sensor, accessKey, iPoint, currentBoundle, uploadUrl } = options;

    let thirdStepError = hikvisionProgressMessages.firstStepError;
    if (!sensor.firstStepError) {
        thirdStepError = '';
        if (!sensor.hikvisionDataUploadFullUrl) {
            thirdStepError = hikvisionProgressMessages.thirdStepErrorWithNoUrl;
        } else {
            const CORRECT_URL = `${uploadUrl?.host}/fpc/v1/${sensor.sensor_type}/${accessKey}`;
            if (sensor.hikvisionDataUploadFullUrl !== CORRECT_URL) {
                thirdStepError = hikvisionProgressMessages.thirdStepWithNoMatchUrls;
            }
        }

        if (!sensor.dataUploadInterval) {
            thirdStepError = hikvisionProgressMessages.thirdStepNoDataUplaodInterval;
        } else if (sensor.dataUploadInterval !== hikvisionDataUploadCorrectValues.dataUploadInterval) {
            thirdStepError = hikvisionProgressMessages.thirdStepInvalidDataUploadInterval;
        }
    }

    return {
        iPointId: iPoint.id,
        value: {
            ...sensor,
            isFetching: sensor.isConfigurationFetching,
            current: true,
            boundleId: currentBoundle.id,
            testDataPushFetching: false,
            updateConfigurationFetching: false,
            thirdStepError,
        },
    };
};

export default setBoundleForHikvisionHelper;
