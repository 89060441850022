export const MEGACOUNT_LAYER_TYPES_MAP = {
    1: {
        name: 'Mega line',
        layer_type: 'passWays',
        id: 'mega_line',
        colors: ['g', 'b'],
    },
    2: {
        name: 'Enter line',
        layer_type: 'passWays',
        id: 'enter_line',
    },
    3: {
        name: 'Exit line',
        layer_type: 'passWays',
        id: 'enter_line',
        colors: ['b'],
    },
    4: { name: 'Portal', layer_type: 'zones', id: 'portal' },
    6: {
        name: 'Intersection line',
        layer_type: 'passWays',
        id: 'intersection_line',
        colors: ['#52E4F7'],
        both_directions_arrow: true,
    },
    7: {
        name: 'Exclusion line',
        layer_type: 'exceptions',
        id: 'intersection_line',
    },
    9: {
        name: 'Exclusion area',
        layer_type: 'exceptions',
        id: 'intersection_line',
    },
};

export const MEGACOUNT_VALID_SEND_FREQ = 30;
export const MEGACOUNT_VALID_DATA_INTERVAL = 1;
export const MEGACOUNT_VALID_FILE_TYPE = 'hourly';
export const MEGACOUNT_DEFAULT_CANVAS_DIMENSIONS = { width: 960, height: 540 };
