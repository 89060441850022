import { DateTime, Interval } from 'luxon';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

/**
 * @typedef {object} IArgs
 * @property {string=} dateFrom
 * @property {string=} dateTo
 *
 * @param {IArgs} args Входные аргументы
 */
const useSensorRelationsById = (args) => {
    const { dateFrom, dateTo } = args;
    const { plStructure } = useSelector((state) => state.instalationPointsReducer);

    const [relationsById, setRelationsById] = useState({});

    useEffect(() => {
        if (plStructure?.fpc?.relations_sensor2dataobj?.length) {
            const myDateFrom = dateFrom ? DateTime.fromISO(dateFrom) : DateTime.now();
            const myDateTo = dateTo ? DateTime.fromISO(dateTo) : DateTime.now();
            const relationsById = plStructure.fpc.relations_sensor2dataobj.reduce((acc, relation) => {
                const relationInterval = Interval.fromDateTimes(
                    DateTime.fromISO(relation.date_from),
                    DateTime.fromISO(relation.date_to).plus({ days: 1 }),
                );

                if (!acc.hasOwnProperty(relation.sensor_id)) {
                    acc[relation.sensor_id] = {};
                }

                if (
                    relationInterval.contains(DateTime.fromISO(myDateFrom)) ||
                    relationInterval.contains(DateTime.fromISO(myDateTo))
                ) {
                    if (!acc[relation.sensor_id].hasOwnProperty(relation.dataobj_type)) {
                        acc[relation.sensor_id] = {
                            ...acc[relation.sensor_id],
                            [relation.dataobj_type]: [],
                        };
                    }

                    acc[relation.sensor_id][relation.dataobj_type].push(relation);
                }

                return acc;
            }, {});

            setRelationsById(relationsById);
        }
    }, [dateFrom, dateTo, plStructure]);
    return relationsById;
};

export default useSensorRelationsById;
