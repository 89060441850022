import { cloneDeep } from 'lodash';
import { sensorTypes } from '../../../constants/sensorTypes';
import vivotekWithDateFieldsRequestHelper from './vivotek/vivotekWithDateFieldsRequestHelper';
import tdWithDateFieldsRequestHelper from './td/tdWithDateFieldsRequestHelper';
import xovisWithDateFieldsRequestHelper from './xovis/xovisWithDateFieldsRequestHelper';
import { getCompanyName } from '../../../constants/constants';

/**
 * Рекурсивная функция для отправки данным по всем инетрвалам
 * @param {object} sensor объект сенсора
 * @param {number} dateFromInMillis дата from
 * @param {number} dateToInMillis дата to
 * @param {number} portionSize размер порции (1 час, 1 день, 15 минут)
 * @param {number} timeout таймаут
 * @returns Объект после всех итераций
 */
const sensorWithDateFieldsHandler = async (options) => {
    const { sensor, dateFromInMillis, dateToInMillis, portionSize, timeout } = options;
    const sensorCopy = cloneDeep(sensor);

    if (sensorCopy.layers.passWays?.length || sensor.multiSensorLayers?.passWays?.length) {
        // Выход из рекурсии
        if (dateFromInMillis + portionSize > dateToInMillis) {
            return { sensor: sensorCopy };
        }

        const dateInterval = {
            dateFrom: dateFromInMillis,
            dateTo: dateFromInMillis + portionSize,
        };

        switch (sensorCopy.type) {
            case sensorTypes.XOVIS: {
                const version = Number(sensor.xovisData?.version?.split('.')[0]);
                if (isFinite(version)) {
                    sensorCopy.orderCount += 1;
                    const data = await xovisWithDateFieldsRequestHelper({
                        sensor: sensorCopy,
                        dateFromInMillis,
                        dateToInMillis: dateFromInMillis + portionSize,
                        timeout,
                    });

                    if (!data.error) {
                        sensorCopy.successOrderCount += 1;
                    } else {
                        sensorCopy.failedIntervals.push(dateInterval);
                        sensorCopy.errors.push(data.error);
                    }
                    return await sensorWithDateFieldsHandler({
                        sensor: sensorCopy,
                        dateFromInMillis: dateFromInMillis + portionSize,
                        dateToInMillis,
                        portionSize,
                        timeout,
                    });
                } else {
                    return {
                        sensor: { ...sensorCopy },
                        error: 'Sensor is offline',
                    };
                }
            }

            case sensorTypes.VIVOTEK: {
                const data = await vivotekWithDateFieldsRequestHelper({
                    sensor: sensorCopy,
                    dateFromInMillis,
                    dateToInMillis: dateFromInMillis + portionSize,
                    timeout,
                });

                if (data.error !== `No ${getCompanyName()} url`) {
                    const sensorCopy = cloneDeep(data.sensor);
                    if (data.error) {
                        sensorCopy.errors.push(data.error);
                    }
                    return await sensorWithDateFieldsHandler({
                        sensor: sensorCopy,
                        dateFromInMillis: dateFromInMillis + portionSize,
                        dateToInMillis,
                        portionSize,
                        timeout,
                    });
                } else {
                    return {
                        sensor: { ...data.sensor },
                        error: data.error,
                    };
                }
            }

            case sensorTypes.TD: {
                const data = await tdWithDateFieldsRequestHelper({
                    sensor: sensorCopy,
                    dateFromInMillis,
                    dateToInMillis: dateFromInMillis + portionSize,
                    timeout,
                });

                if (data.error !== `No ${getCompanyName()} url`) {
                    const sensorCopy = cloneDeep(data.sensor);
                    if (data.error) {
                        sensorCopy.errors.push(data.error);
                    }
                    return await sensorWithDateFieldsHandler({
                        sensor: sensorCopy,
                        dateFromInMillis: dateFromInMillis + portionSize,
                        dateToInMillis,
                        portionSize,
                        timeout,
                    });
                } else {
                    return {
                        sensor: { ...data.sensor },
                        error: data.error,
                    };
                }
            }

            default:
                return { sensor: { ...sensorCopy }, error: 'Invalid type' };
        }
    } else {
        return { sensor: { ...sensorCopy }, error: 'No count lines' };
    }
};

export default sensorWithDateFieldsHandler;
